import { useState, useEffect, useCallback } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../utils/Api";
import Big from "../components/booking/Big";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import SchoolIcon from "@mui/icons-material/School";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CourseGradesDialog from "../components/courses/grades/CourseGradesDialogBase";
import Translate from "../utils/Translate";
import Preloader from "../components/Preloader";
import { hasAccess, hasSomeAccess, accessKeys } from "../utils/userAccess";
import { getLicenseRegistrationCourseTypes } from "../utils/externalUserHelper";
import BookingFlowDialogContainer from "../components/courses/bookings/BookingFlowDialogContainer";
import YesOrNoDialog from "../components/YesOrNoDialog";
import ActorPermitFlowDialogContainer from "../components/actor-permits/ActorPermitFlowDialogContainer";
import { PersonAddAlt1 } from "@mui/icons-material";
import ID06CompetencesDialogBase from "../components/id06/ID06CompetencesDialogBase";
import { hasIntegration, integrations } from "../utils/integrations";

//import SpeedMenu from '../components/SpeedMenu'

async function canCreateActorPermitForCourse(api, courseId) {
  try {
    var permitSkillGroups = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/for-permits?includeImageUrls=false`,
      false,
      "GET"
    );
    var courseSkillGroups = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/for-course?courseId=${courseId}&includeImageUrls=false`,
      false,
      "GET"
    );
    var permitSkillGroupIds = permitSkillGroups.map((sg) => sg.skillGroupId);
    return courseSkillGroups.some((sg) =>
      permitSkillGroupIds.includes(sg.skillGroupId)
    );
  } catch (error) {
    return false;
  }
}

const Bookings = (props) => {
  const bigScreen = useMediaQuery("(min-width:1200px)");
  const api = useApi();
  let courseId = useParams().id;
  if (props.id) {
    courseId = props.id;
  }
  const [showID06Dialog, setShowID06Dialog] = useState(
    window.location.hash.startsWith("#id06")
  );
  const [showID06OpenQuestion, setShowID06OpenQuestion] = useState(false);
  const [showDeleteCourseQuestion, setShowDeleteCourseQuestion] =
    useState(false);
  const [course, setCourse] = useState(false);
  const [stats, setStats] = useState(false);
  const [showGradesDialog, setShowGradesDialog] = useState(
    window.location.hash.startsWith("#grades")
  );
  const [licenseRegistrationCourseTypes, setLicenseRegistrationCourseTypes] =
    useState([]);
  const [isActorPermitCourse, setIsActorPermitCourse] = useState(false);
  const [hasActorPermit] = useState(hasAccess(accessKeys.actorPermit));

  const hasStandardOrAdvanced = hasSomeAccess(
    accessKeys.standardPackage,
    accessKeys.advancedPackage
  );
  const isSwedishCompany = hasAccess(accessKeys.swedishCompany);
  const navigate = useNavigate();

  const loadCourse = useCallback(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/stats`,
        false,
        "GET"
      )
      .then((data) => {
        if (data.error) {
          if (data.error === "refresh") {
            //console.log("Token must reload");
            ////console.log('LOCATION', location.pathname);
          } else {
            //console.log("Other error");
          }
        } else {
          setStats(data);
        }
      });
    //if (stats){
    ////console.log('STATDATA', stats);
    //}
    // ?dsupd=${Date.now() inlagd för att slippa cache problem, funkar inte med cache settings i Api.js, fungerar inte heller på stage
    //  `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/?dsupd=${Date.now()}`,
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          if (data.error) {
            if (data.error === "refresh") {
              //console.log("Token must reload");
            } else {
              //console.log("Other error");
            }
          } else {
            window.global.bookings = {};
            setCourse(data);
          }
        }
      });

    if (hasActorPermit) {
      canCreateActorPermitForCourse(api, courseId).then((canCreatePermit) =>
        setIsActorPermitCourse(!!canCreatePermit)
      );
    }

    async function loadLicenseRegistrationCourseTypes() {
      const courseTypes = await getLicenseRegistrationCourseTypes();
      setLicenseRegistrationCourseTypes(courseTypes);
    }
    loadLicenseRegistrationCourseTypes();
  }, [courseId, hasActorPermit, api]);

  useEffect(() => {
    loadCourse();
  }, [loadCourse]);

  function handleOpenGradesDialog() {
    window.location.hash = "#grades";
    setShowGradesDialog(true);
  }

  function handleCloseGradesDialog(orderedDigitalLicenses) {
    window.history.back();
    setShowGradesDialog(false);
    loadCourse();
    if (orderedDigitalLicenses && hasIntegration(integrations.ID06)) {
      setShowID06OpenQuestion(true);
    }
  }

  function handleOpenID06Dialog() {
    window.location.hash = "#id06";
    setShowID06OpenQuestion(false);
    setShowID06Dialog(true);
  }

  function handleCloseID06Dialog() {
    window.history.back();
    setShowID06Dialog(false);
  }

  const speedDialActions = [
    ...(isSwedishCompany && !hasStandardOrAdvanced
      ? [
          {
            hide: course?.numberOfParticipants < 1,
            icon: <ContactMailIcon />,
            text: Translate.get("OrderMultipleLicenses"),
            disabled:
              !course ||
              !licenseRegistrationCourseTypes.includes(
                course.licenseGroupDescription
              ),
            route: `/dashboard/licenseregistration?courseId=${courseId}`,
          },
        ]
      : []),
    ...(hasStandardOrAdvanced
      ? [
          {
            icon: <SchoolIcon />,
            text: Translate.get("GradeCourse"),
            disabled:
              course?.numberOfParticipants <= 0 ||
              !course.courseBookings?.some((x) =>
                x?.courseParticipants?.some(
                  (y) => y?.courseParticipantId !== -1
                )
              ),
            onClick: handleOpenGradesDialog,
          },
        ]
      : []),
    ...(hasIntegration(integrations.ID06)
      ? [
          {
            icon: <SchoolIcon />,
            text: Translate.get("ID06Name"),
            // Disabled until some student has received a digital license
            disabled:
              !course ||
              !course.courseBookings.some((cb) =>
                cb.courseParticipants.some(
                  (cp) => cp.actorLicense && cp.actorLicense.digitalLicenseId
                )
              ),
            onClick: handleOpenID06Dialog,
          },
        ]
      : []),
    ...(hasActorPermit
      ? [
          {
            icon: <ReceiptLongIcon />,
            text: Translate.get("CreateActorPermit"),
            disabled:
              !isActorPermitCourse ||
              !course?.courseBookings?.some((cb) =>
                cb.courseParticipants.some((cp) => !!cp.actorLicense)
              ),
            dialogHeader: Translate.get("CreateActorPermit"),
            component: (
              <ActorPermitFlowDialogContainer
                actorIds={course?.courseBookings?.flatMap((cb) =>
                  cb.courseParticipants
                    .filter((cp) => !!cp.actorLicense)
                    .map((cp) => cp.actorId)
                )}
              />
            ),
          },
        ]
      : []),
    {
      icon: <PersonAddAlt1 />,
      text: Translate.get("AddStudent"),
      component: (
        <BookingFlowDialogContainer
          courseId={courseId}
          bookings={course.courseBookings}
          isELearningCourse={course.isELearningCourse}
        />
      ),
    },
  ];

  async function handleDeleteCourse() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/delete`,
      { courseId: course.courseId },
      "POST"
    );
    if (response && response.isSuccessful) {
      navigate("/dashboard/courses");
    }
    setShowDeleteCourseQuestion(false);
  }

  if (course && stats) {
    return (
      <>
        <CourseGradesDialog
          courseId={courseId}
          open={showGradesDialog}
          onClose={handleCloseGradesDialog}
        />
        {showID06Dialog && (
          <ID06CompetencesDialogBase
            courseId={courseId}
            onClose={handleCloseID06Dialog}
          />
        )}
        {/*  {bigScreen ? ( */}
        <Big
          bigScreen={bigScreen}
          data={course}
          stats={stats}
          loadCourse={loadCourse}
          onDeleteCourse={() => setShowDeleteCourseQuestion(true)}
        />
        {/*  ) : (
          <Small
            data={course}
            stats={stats}
            loadCourse={loadCourse}
            onDeleteCourse={() => setShowDeleteCourseQuestion(true)}
          />
        )} */}
        <SpeedMenu content={speedDialActions} />
        <YesOrNoDialog
          open={showDeleteCourseQuestion}
          title={Translate.get("RemoveCourse")}
          text={Translate.get("DoUWantToContinueWithDeleteCourse")}
          onNo={() => setShowDeleteCourseQuestion(false)}
          onYes={handleDeleteCourse}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Delete")}
        />
        <YesOrNoDialog
          open={showID06OpenQuestion}
          title={Translate.get("ID06Name")}
          text={Translate.get("OpenID06AfterDigitalLicenseQuestion")}
          onNo={() => setShowID06OpenQuestion(false)}
          onYes={handleOpenID06Dialog}
          noText={Translate.get("No")}
          yesText={Translate.get("Yes")}
        />
      </>
    );
  }

  return (
    <>
      <Preloader invisible={true} />
    </>
  );
};

export default Bookings;
