import { useParams, useNavigate,useLocation } from "react-router-dom";
import { useEffect } from "react";

const Comp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  
  useEffect(() => {
    if (id) {
      const qString= searchParams.size ? "?" + searchParams : '';
      const path = decodeURIComponent(id + qString);
      navigate(path, { replace: true });
    }
  }, [navigate, id]);
  return <div>Redirect to path: {decodeURIComponent(id)} qstring: {searchParams}</div>;
};
export default Comp;
