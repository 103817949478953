import React, { useEffect, useState } from 'react';
import { useApi } from '../utils/Api';
import './stylesheet.css';
import Paper from '@mui/material/Paper';
import Preloader from '../components/Preloader';
import Translate from '../utils/Translate';
import { useMediaQuery } from "@mui/material";

function getPathParameter(index) {
  let str = window.location.href;
  let url = new URL(str);
  let path = url.pathname;
  return path.split("/")[index];
}

function getPathLength() {
  let str = window.location.href;
  let url = new URL(str);
  let path = url.pathname;
  return path.split("/").length;
}

export default function SelfReg(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(null);
  const [qr, setQr] = useState(null);
  const api = useApi();

  useEffect(() => {
    if (getPathLength() === 7) {
      getIdForSpecificStudent();
    } else {
      getId();
    }

  }, []);

  function getId() {
    setIsLoading(true)
    let courseId = getPathParameter(3);

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}self/id/${courseId}`,
      false,
      'GET',
    )
      .then((data) => {
        if (!data.error) {
          setId(data.id);
          setQr(data.qr)
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  function getIdForSpecificStudent() {
    setIsLoading(true)
    let courseId = getPathParameter(3);
    let courseBookingId = getPathParameter(4);
    let actorId = getPathParameter(5);

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}self/id/${courseId}/${courseBookingId}/${actorId}`,
      false,
      'GET',
    )
      .then((data) => {
        if (!data.error) {
          setId(data.id);
          setQr(data.qr)
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  if (!useMediaQuery("(max-width:900px)")) {
    return (

      <div className='selfRegContainer'>
        {isLoading && <Preloader />}

        <div className='selfRegCol1'>
          <p className='selfRegFontSize'>{Translate.get("ScanQrCode")}</p>
          <Paper elevation={3} sx={{ p: '20px', maxWidth: '80%', mt: 2 }}>
            {qr ? <img src={`data:image/png;base64,${qr}`} className="selfRegImage" alt="" /> : ''}
          </Paper>
        </div>

        <div className='selfRegCol2'>
          <div className='selfRegCol2Margin'>
            <p className='selfRegFontSize'>{Translate.get("AlternativeToQrCode")}: </p><br />
            <p className='selfRegFontSize'>{Translate.get("OpenBrowserNavigate")}:</p>
            <p className='selfRegFontSize'><b>www.eportcloud.com/self </b></p> <br />
            <p className='selfRegFontSize'>{Translate.get("SubmitCode")}:</p>
            <p className='selfRegCode'><b>{id}</b></p>
          </div>

        </div>
      </div>
    );
  } else {
    return (
      <div className='selfRegContainer'>
        {isLoading && <Preloader />}
      <div className='selfRegColMobile'>
        <p>{Translate.get("ScanQrCode")}</p>
        <Paper elevation={3} sx={{ p: '20px', maxWidth: '80%', mt: 2 }}>
          {qr ? <img src={`data:image/png;base64,${qr}`} className="selfRegImage" alt="" /> : ''}
        </Paper>

        <p style={{paddingTop: "2em"}}>{Translate.get("AlternativeToQrCode")}: </p><br />
        <p>{Translate.get("OpenBrowserNavigate")}:</p>
        <p><b>www.eportcloud.com/self </b></p> <br />
        <p>{Translate.get("SubmitCode")}:</p>
        <p><b>{id}</b></p>
        </div>
      </div>
    );
  }

}