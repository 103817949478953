import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import { useCallback } from "react";
import ButtonWithSpinner from "../../ButtonWithSpinner";

export default function AddOrEditSkillDialog({
  skillGroupId,
  existingSkill,
  onClose,
  onSaved,
}) {
  const [skill, setSkill] = useState(existingSkill ?? { skillGroupId });
  const [skillIcons, setSkillIcons] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(true); //!!existingSkill);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const api = useApi();

  useEffect(() => {
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}skills/icons`, false, "GET")
      .then((icons) => setSkillIcons(icons));
  }, [api]);

  async function handleSaveSkill() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skills`,
      skill,
      "POST"
    );

    if (response.isSuccessful) {
      await onSaved();
    }
  }

  return (
    <>
      {!isDataFetched && <Preloader />}
      {isDataFetched && (
        <Dialog
          open={true}
          onClose={onClose}
          className={"mediumCourseDialog"}
          //   PaperProps={{ sx: { maxWidth: { sm: "700px", xs: "343px" } } }}
        >
          <DialogTitle>
            {existingSkill
              ? Translate.get("EditSkill")
              : Translate.get("AddSkillGroupSkillLabel")}
          </DialogTitle>
          <DialogContent dividers={true} className="stdDialogContent">
            <Box className="stdForm">
              <Box>
                <FormControl>
                  <InputLabel id="icon-label">
                    {Translate.get("Icon")}
                  </InputLabel>
                  <Select
                    sx={{ minWidth: "146px", minHeight: "133px" }}
                    labelId="icon-label"
                    id="icon-select"
                    value={(skillIcons.length > 0 && skill.iconName) || ""}
                    label={Translate.get("Icon")}
                    onChange={(event) => {
                      setSkill((prev) => ({
                        ...prev,
                        iconName: event.target.value,
                        skillXaml: event.target.value.substring(
                          0,
                          event.target.value.lastIndexOf(".")
                        ),
                      }));
                    }}
                  >
                    {skillIcons.map((icon) => (
                      <MenuItem key={icon.name} value={icon.name}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap="10px"
                        >
                          <img
                            src={icon.url}
                            alt=""
                            width="100px"
                            height="100px"
                          />
                          <span>{icon.name}</span>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <TextField
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  value={skill.shortCode ?? ""}
                  onChange={(event, value) =>
                    setSkill((prev) => ({
                      ...prev,
                      shortCode: event.target.value,
                    }))
                  }
                  label={Translate.get("ShortCode")}
                  placeholder={Translate.get("ShortCode")}
                />
              </Box>

              <TextField
                inputProps={{ maxLength: 255 }}
                fullWidth
                value={skill.description ?? ""}
                onChange={(event, value) =>
                  setSkill((prev) => ({
                    ...prev,
                    description: event.target.value,
                  }))
                }
                label={Translate.get("Description")}
                placeholder={Translate.get("Description")}
              />

              <TextField
                inputProps={{ maxLength: 255 }}
                fullWidth
                value={skill.skillFreeText ?? ""}
                onChange={(event, value) =>
                  setSkill((prev) => ({
                    ...prev,
                    skillFreeText: event.target.value,
                  }))
                }
                label={Translate.get("FreeText")}
                placeholder={Translate.get("FreeText")}
                multiline
              />

              <TextField
                inputProps={{ maxLength: 255 }}
                fullWidth
                value={skill.skillFreeTextToo ?? ""}
                onChange={(event, value) =>
                  setSkill((prev) => ({
                    ...prev,
                    skillFreeTextToo: event.target.value,
                  }))
                }
                label={Translate.get("FreeTextToo")}
                placeholder={Translate.get("FreeTextToo")}
                multiline
              />

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!skill && skill.isTheory}
                      onChange={(event) =>
                        setSkill((prev) => ({
                          ...prev,
                          isTheory: event.target.checked,
                        }))
                      }
                    />
                  }
                  label={Translate.get("IsTheory")}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner onClick={handleSaveSkill}>
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
