import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

export default function ActorAutocompleteOption({
  actorName,
  orgNo,
  email,
  companies,
  addressStreet,
  addressZipCode,
  addressCity,
  externalActorNo
}) {
  const address = useMemo(() => {
    const zipAndCity = [addressZipCode, addressCity]
      .filter((a) => !!a)
      .join(" ");
    return [addressStreet, zipAndCity].filter((a) => !!a).join(", ");
  }, [addressStreet, addressZipCode, addressCity]);

  return (
    <Stack>
      {actorName && <Box>{actorName}</Box>}
      {orgNo && (
        <Typography
          noWrap
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {orgNo}
        </Typography>
      )}
      {externalActorNo && (
        <Typography
          noWrap
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {externalActorNo}
        </Typography>
      )}
      {email && (
        <Typography
          noWrap
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {email}
        </Typography>
      )}
      {companies &&
        companies.length > 0 &&
        companies.map((company, index) => (
          <Typography
            key={index}
            noWrap
            variant="body2"
            sx={{
              color: "text.secondary",
            }}
          >
            {company}
          </Typography>
        ))}
      {address && (
        <Typography
          noWrap
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {address}
        </Typography>
      )}
    </Stack>
  );
}
