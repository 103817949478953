import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Translate from "../../utils/Translate";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useApi } from "../../utils/Api";
import { useEffect } from "react";
import { formatDate } from '../../utils/dateTimeFormat';
import Preloader from "../Preloader";
import ManualAuthFileUpload from "./dialogs/ManualAuthFileUpload";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ManualAuthentication({
  loadCourse,
  courseParticipantId,
  existingManualAuths,
}) {
  const api = useApi();

  const [open, setOpen] = React.useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [text, setText] = useState("");
  const [existingRegDate, setExistingRegDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [authenticationId, setAuthenticationId] = useState(null);

  const [hasExistingManAuth, setHasExistingManAuth] = useState(false);

  const [uploadDocuments, setUploadDocuments] = useState(false);
  const [showDocumentUploadView, setShowDocumentUploadView] = useState(false);

  useEffect(() => {
    if (existingManualAuths != null && existingManualAuths.length > 0) {
      existingManualAuths.sort(
        (a, b) => new Date(b.regDate) - new Date(a.regDate)
      );
      let latestEntry = existingManualAuths[0];

      setHasExistingManAuth(true);
      setDocumentType(latestEntry.authenticationType);
      setReferenceId(latestEntry.referenceId);
      setText(latestEntry.text);
      setExistingRegDate(latestEntry.regDate);
      setFiles(latestEntry.blobDocuments);
      setAuthenticationId(latestEntry.authenticationId);
    }
  }, []);

  function handleChange(event) {
    setDocumentType(event.target.value);
  }

  function handleClose() {
    window.history.back();
    loadCourse();
    setOpen(false);
  }

  function handleReferenceIdChange(event) {
    if (event.target.value.length <= 20) {
      setReferenceId(event.target.value);
    }
  }

  function handleTextChange(event) {
    if (event.target.value.length <= 140) {
      setText(event.target.value);
    }
  }

  async function handleSave() {
    setIsLoading(true);

    let reqObj = {
      courseParticipantId: courseParticipantId,
      authenticationType: documentType,
      text: text,
      referenceId: referenceId,
    };

    try {
      let response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/participants/manualauth`,
        reqObj,
        "POST",
        false,
        true
      );
      if (response.isSuccessful && !uploadDocuments) {
        handleClose();
      } else if (response.isSuccessful && uploadDocuments) {
        setAuthenticationId(response.authenticationId);
        setShowDocumentUploadView(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    setIsLoading(true);

    let reqObj = {
      authenticationId: authenticationId,
    };

    try {
      let response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/participants/manualauth`,
        reqObj,
        "DELETE",
        false,
        true
      );
      if (response.isSuccessful) {
        handleClose();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  function handleCheckboxChange() {
    setUploadDocuments(!uploadDocuments);
  }

  if (isLoading) {
    return <Preloader />
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            padding: "2em",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ padding: 0 }}>
        {Translate.get("ManualAuth")}
      </DialogTitle>

      <Box>
        {!showDocumentUploadView &&
        <>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="document-select-label">{Translate.get("DocType")}</InputLabel>
          <Select
            labelId="document-select-label"
            id="document-select"
            value={documentType}
            onChange={handleChange}
            label={Translate.get("DocType")}
            disabled={hasExistingManAuth}
          >
            <MenuItem value={1}>{Translate.get("DriversLicense")}</MenuItem>
            <MenuItem value={2}>{Translate.get("IDCard")}</MenuItem>
            <MenuItem value={3}>{Translate.get("Passport")}</MenuItem>
            <MenuItem value={4}>{Translate.get("Other2")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          id="reference-id"
          label={Translate.get("ReferenceID")}
          value={referenceId}
          onChange={handleReferenceIdChange}
          inputProps={{ maxLength: 20 }}
          disabled={hasExistingManAuth}
        />
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          id="text-field"
          label={Translate.get("Text")}
          value={text}
          onChange={handleTextChange}
          inputProps={{ maxLength: 140 }}
          multiline
          rows={3}
          disabled={hasExistingManAuth}
        />

        {hasExistingManAuth && <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          id="text-field"
          label={Translate.get("Date")}
          value={formatDate(existingRegDate)}
          disabled={hasExistingManAuth}
        />}
        </>}

        {showDocumentUploadView && 
        <ManualAuthFileUpload 
        manualAuthFiles={files}
        manualAuthenticationId={authenticationId}
        isUploadAllowed={true}
        />}
      </Box>

      <ManualAuthFileUpload 
        manualAuthFiles={files}
        manualAuthenticationId={authenticationId}
        isUploadAllowed={false}
        />

      {(!hasExistingManAuth && !showDocumentUploadView) && <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} checked={uploadDocuments} />} label={Translate.get("UploadManAuthDocs")} />}

      {!showDocumentUploadView && <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ marginRight: "1em" }}
        >
          {Translate.get("Close")}
        </Button>

        {!hasExistingManAuth && (
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={documentType === ""}
          >
            {Translate.get("Save")}
          </Button>
        )}

        {hasExistingManAuth && (
          <Button
            variant="contained"
            onClick={handleDelete}
            disabled={documentType === ""}
          >
            {Translate.get("Delete")}
          </Button>
        )}
      </Box>}
    </Dialog>
  );
}
