import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import debounce from "lodash.debounce";
import YesOrNoDialog from "../YesOrNoDialog";
import BankIdAuthentication from "../bankId/BankIdAuthentication";
import { useSnackbar } from "notistack";

export default function UserDetailsCard({ profile }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [phone, setPhone] = useState({});
  const [address, setAddress] = useState({});
  const [ssn, setSsn] = useState(null);
  const [userHasBankIdLogin, setUserHasBankIdLogin] = useState(false);
  const [originalSsn, setOriginalSsn] = useState("");
  const [ssnError, setSsnError] = useState(false);
  const [ssnHelperText, setSsnHelperText] = useState("");
  const [confirmAuthenticateSsn, setConfirmAuthenticateSsn] = useState(false);
  const [showSsnBankIdAuthentication, setShowSsnBankIdAuthentication] =
    useState(false);
  const [confirmRemoveSsn, setConfirmRemoveSsn] = useState(false);
  const api = useApi();

  const saveProfileAddress = useMemo(
    () =>
      debounce(async (address) => {
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}profiles/address`,
          address,
          "POST"
        );
      }, 2000),
    [api]
  );

  const saveProfilePhone = useMemo(
    () =>
      debounce(async (phone) => {
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}profiles/phone`,
          phone,
          "POST"
        );
      }, 2000),
    [api]
  );

  const saveProfileSsn = useMemo(
    () =>
      async (ssn, syncAccountSsn = false, removeAccountSsn = false) => {
        const dto = {
          socialSecurityNumber: ssn,
          syncAccountPersonalNo: syncAccountSsn,
          removeAccountPersonalNo: removeAccountSsn,
        };
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}profiles/ssn`,
          dto,
          "POST"
        );
      },
    [api]
  );

  const ssnUpdateNeedsSign = useMemo(
    () => async (ssn) => {
      const dto = { socialSecurityNumber: ssn };
      return await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}profiles/ssn/update-check`,
        dto,
        "POST"
      );
    },
    [api]
  );

  useEffect(() => {
    setPhone(
      profile.actor?.mobilePhone ?? {
        actorPhoneId: 0,
        actorPhoneType: 2,
        actorId: profile.actor?.actorId,
      }
    );
    setAddress(
      profile.actor?.postalAddress ?? {
        actorAddressId: 0,
        actorAddressType: 3,
        actorId: profile.actor?.actorId,
        actorAddressStreet1: profile.name,
      }
    );
    setSsn(profile.actor?.orgNo);
    setOriginalSsn(profile.actor?.orgNo);
    setUserHasBankIdLogin(profile.bankIdLogin);
    if (profile.bankIdLogin) {
      setSsnHelperText(Translate.get("BankIdConnectedToLogin"));
    }
  }, [profile]);

  // Disabling fields if there is no actor, because the actor is what holds these values.
  // Most users should have a connected actor, but some might not, either by accident,
  // e.g. an ePortUser without actor, or b ydesign, e.g. an externalAgreementEPortUser.
  const UserDetailsTextFields = useCallback(
    ({ items }) => (
      <>
        {items.map(({ placeholder, value, ...rest }, idx) => (
          <Grid item key={idx} xs={12} sm={6}>
            <TextField
              fullWidth
              disabled={!profile.actor}
              value={value ?? ""}
              InputProps={{ spellCheck: false }}
              {...rest}
            />
          </Grid>
        ))}
      </>
    ),
    [profile]
  );

  function handlePhoneChange(event) {
    const newValue = event.target.value;
    const phoneCopy = { ...phone };
    phoneCopy["actorPhoneNumber"] = newValue;
    setPhone(phoneCopy);
    saveProfilePhone(phoneCopy);
  }

  function handleAddressChange(event, fieldName) {
    const newValue = event.target.value;
    const addressCopy = { ...address };
    addressCopy[fieldName] = newValue;
    setAddress(addressCopy);
    saveProfileAddress(addressCopy);
  }

  function handleSsnChange(event) {
    setSsn(event.target.value);
  }

  async function handleSsnBlur(event) {
    const newValue = event.target.value;
    setSsn(newValue);
    const resp = await ssnUpdateNeedsSign(newValue);
    setShowSsnBankIdAuthentication(false);
    setSsnError(false);
    if (ssnHelperText !== Translate.get("BankIdConnectedToLogin")) {
      setSsnHelperText("");
    }
    setConfirmRemoveSsn(false);
    setConfirmAuthenticateSsn(false);
    if (resp.updateWillRemoveBankIdLogin) {
      setConfirmRemoveSsn(true);
      return;
    }
    if (resp.updateNeedsBankIdAuthenticiation) {
      setConfirmAuthenticateSsn(true);
      return;
    }

    if (!resp.personalNoIsValid) {
      setSsnError(true);
      setSsnHelperText(Translate.get("InvalidOrgNo"));
      return;
    }

    if (resp.otherAccountHasPersonalNo) {
      setSsnHelperText(Translate.get("PersonalNoConnectedToOtherAccount"));
    }
    await saveProfileSsn(newValue, false, false);
  }

  function cancelConfirmSsn() {
    setConfirmAuthenticateSsn(false);
    setSsn(originalSsn);
  }

  async function justSaveSsnClick() {
    // If the user selects this option while having BankID login then we must deactivate it
    await saveProfileSsn(ssn, false, userHasBankIdLogin);
    setConfirmAuthenticateSsn(false);
  }

  function confirmSsnClick() {
    setShowSsnBankIdAuthentication(true);
    setConfirmAuthenticateSsn(false);
  }

  async function onConfirmRemoveSsn() {
    // The removeAccountSsn param is redundant in this case.
    // The syncAccountSsn param will already save an empty ssn.
    await saveProfileSsn(ssn, true, true);
    setConfirmRemoveSsn(false);
  }

  function onCancelRemoveSsn() {
    setSsn(originalSsn);
    setConfirmRemoveSsn(false);
  }

  async function onBankIdAuthenticationSuccess() {
    setUserHasBankIdLogin(true);
    setSsnHelperText(Translate.get("BankIdConnectedToLogin"));
    setShowSsnBankIdAuthentication(false);
  }

  async function onBankIdAuthenticationFailure(_, response) {
    const translationKey =
      response?.data?.errorMessageTranslationKey ?? "SomethingFailed";
    const errorMessage = enqueueSnackbar(Translate.get(translationKey), {
      variant: "error",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(errorMessage),
    });
    setShowSsnBankIdAuthentication(false);
  }

  return (
    <>
      <Card sx={{ paddingBottom: "10px" }}>
        <CardContent>
          <Grid container spacing={3.5}>
            <Grid item>
              <Typography variant="h4">
                {Translate.get("PersonalInfo")}
              </Typography>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Box width="100%" padding="14px" className="avatarInfo textbox">
                  <Typography>{profile.name}</Typography>
                  {profile.email && (
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {profile.email}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item container columnSpacing={2} rowSpacing={3}>
                <UserDetailsTextFields
                  items={[
                    {
                      value: ssn,
                      label: Translate.get("PersonalNo"),
                      onBlur: handleSsnBlur,
                      onChange: handleSsnChange,
                      error: ssnError,
                      helperText: ssnHelperText,
                    },
                    {
                      value: phone.actorPhoneNumber,
                      label: Translate.get("Phone"),
                      onChange: handlePhoneChange,
                    },
                    {
                      value: address.actorAddressStreet2,
                      label: Translate.get("Address"),
                      onChange: (event) =>
                        handleAddressChange(event, "actorAddressStreet2"),
                    },
                    {
                      value: address.actorAddressCity,
                      label: Translate.get("City"),
                      onChange: (event) =>
                        handleAddressChange(event, "actorAddressCity"),
                    },
                    {
                      value: address.actorAddressZipCode,
                      label: Translate.get("ZipCode"),
                      onChange: (event) =>
                        handleAddressChange(event, "actorAddressZipCode"),
                    },
                    {
                      value: address.actorAddressCountry,
                      label: Translate.get("Country"),
                      onChange: (event) =>
                        handleAddressChange(event, "actorAddressCountry"),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <YesOrNoDialog
        open={confirmRemoveSsn}
        title={Translate.get("RemoveBankIdLogIn")}
        onNo={onCancelRemoveSsn}
        onYes={onConfirmRemoveSsn}
        content={`${Translate.get(
          "RemoveBankIdLogInDescription"
        )}\n\n${Translate.get("DoYouWantToContinue")}`}
      />
      <Dialog open={showSsnBankIdAuthentication} onClose={cancelConfirmSsn}>
        <DialogTitle textAlign="center">
          {Translate.get("IdentifyUsingBankId")}
        </DialogTitle>
        <DialogContent>
          <BankIdAuthentication
            startAuthenticationUrl={`${process.env.REACT_APP_MAIN_URL}profiles/ssn/bankId/authenticate`}
            postData={{ personalNo: ssn }}
            onAuthenticationSuccess={onBankIdAuthenticationSuccess}
            onAuthenticationFailure={onBankIdAuthenticationFailure}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={confirmAuthenticateSsn} onClose={cancelConfirmSsn}>
        <DialogTitle>
          {Translate.get("ConnectPersonalNumberBankIdLoginTitle")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Translate.get("ConnectPersonalNumberBankIdLoginDescription")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelConfirmSsn}>{Translate.get("Cancel")}</Button>
          <Button onClick={justSaveSsnClick}>
            {Translate.get("SaveWithoutBankId")}
          </Button>
          <Button variant="contained" onClick={confirmSsnClick}>
            {Translate.get("AuthenticateForBankIDLogin")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
