export default function Backdrop(theme) {

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: [
            `rgba(0, 0, 0, 0.7)`,
            /*`rgb(22,28,36)`,
            `-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            `-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            `linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`*/
          ],
          '&.MuiBackdrop-invisible': {
            background: 'transparent'
          }
        }
      }
    }
  };
}
