import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';
import Translate from '../../../utils/Translate';
import { fNumber } from '../../../utils/formatNumber';
// ----------------------------------------------------------------------

const setChart = (data) => {
  //Mecka ev med riktiga indatan och få ut värden och datum

  return data;
}

export default function AppWebsiteVisits({data}) {
  //Data hämtas i src\pages\DashboardApp.js
  const cData=setChart(data);

  const chartOptions = merge(BaseOptionChart(), {
    theme: {
      palette: 'palette1' // upto palette10
    },
    //stroke: { width: [4, 4, 4] },
    //plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    ////fill: { type: ['solid', 'gradient', 'solid'] },
    stroke: {
      colors: ["transparent"],
      width: 5
    },
    plotOptions: {
      bar: { horizontal: false, borderRadius: 0,columnWidth: '100%'}
    },
    labels: cData.labels,
    xaxis: { type: 'datetime' },
    /* tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} licenser`;
          }
          return y;
        },
      },
    } */
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true,
    },
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
 
  });

  return (
    <Card>
      <CardHeader title={Translate.get('DistributionCoursesTime')} subheader="" />
      {/* <Box sx={{ p: 3, pb: 1 }} dir="ltr"> */}
      <Box sx={{ mx: 3,mb:2}} dir="ltr">
        <ReactApexChart
          type="bar"
          series={cData.series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
