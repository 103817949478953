import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import { useApi } from '../../utils/Api';
import useMediaQuery from '@mui/material/useMediaQuery';
import Translate from '../../utils/Translate';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Preloader from '../Preloader';
import YesOrNoDialog from "../YesOrNoDialog"
import { hasAccess, accessKeys } from '../../utils/userAccess';

export function Recall({eLearningType, ...props}) {
  const eLearningTypes = {
    0: 'Undefined',
    1: 'eTruck',
    2: 'eLift',
    3: 'eTruckRefresher',
  };
  const [data, setData] = useState('');
  const [checkedELearningLicenses, setCheckedELearningLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [recallButtonIsDisabled, setRecallButtonIsDisabled] = useState(true);
  const [successDialog, setSuccessDialog] = useState(false);
  const smallScreen = useMediaQuery('(max-width:700px)');
  const [open, setOpen] = useState(true);
  const [showRecallStudentLicenseWarning, setShowRecallStudentLicenseWarning] = useState(false);
  const api = useApi();

  var dataObj = [...data];
  var totalLicenses = dataObj.reduce(
    (count, current) => count + current.licenses.length,
    0,
  );
  var companyRows = [];

  function handleChange(event) {
    setQuery(event.target.value);
  }

  function check(event) {
    if (event.target.checked) {
      const licenses = [...checkedELearningLicenses, event.target.value];
      setCheckedELearningLicenses(licenses);
      setRecallButtonIsDisabled(licenses.length > 0 ? false : true);
    } else {
      const licenses = [...checkedELearningLicenses];
      const index = licenses.indexOf(event.target.value);
      licenses.splice(index, 1);

      setCheckedELearningLicenses(licenses);
      setRecallButtonIsDisabled(licenses.length > 0 ? false : true);
    }
  }

  useEffect(() => {
    const onHashChange = () => setOpen(window.location.hash === '#recall');
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  function handleOpen() {
    window.location.hash = '#recall';
  }

  function handleClose(event, reason) {
    if (reason ==='backdropClick'){
        return false;
      }
    props.switchMode('balance');
    window.history.back();
  }

  useEffect(() => {
    if (!open) {
      props.switchMode('balance', -1);
    }
  }, [open, props]);

  useEffect(() => {
    function recalculate() {
      setIsLoading(true);
  
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${eLearningType}/unused`,
        false,
        'GET',
      )
        .then((data) => {
          if (!data.error) {
            setData(data);
          }
        })
        .finally((f) => {
          setIsLoading(false);
        });
    }

    handleOpen();
    recalculate();
  }, [eLearningType, api]);

  function handleRecallStart() {
    // We will show a warning that course participants will be removed as well,
    // if any of the selected licenses are connected to those. But if the user
    // has the advanced package, we are assuming for now that they might use
    // their order data (e.g. ERP integration), so we don't update it in that
    // case, and hence don't need to show any warning, regardless of connections.
    const showWarning = !hasAccess(accessKeys.advancedPackage) && data
      .flatMap(d => d.licenses)
      .filter(lic => checkedELearningLicenses.includes(lic.eLearningLicenseId))
      .some(lic => !!lic.courseParticipantId);
    if (showWarning) {
      setShowRecallStudentLicenseWarning(true);
    }
    else {
      handleRecall();
    }
  }

  function handleRecall() {
    setIsLoading(true);

    const recallReq = {
      eLearningLicenseIds: checkedELearningLicenses,
    };

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/release`,
      recallReq,
      'POST',
      false,
      true,
    )
      .then((data) => {
        if (data.isSuccessful) {
          setCheckedELearningLicenses([]);
          setSuccessDialog(true);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  // Grupperat på företag
  for (let [key, value] of Object.entries(dataObj)) {
    // Licenserna för varje företag
    var rows = [];
    for (let [key2, value2] of Object.entries(value.licenses)) {
      const dateTime = new Date(value2.courseSubsStartBookedDate);
      const date = dateTime.toLocaleDateString();

      if (
        query === '' ||
        value.companyName.toLowerCase().includes(query.toLocaleLowerCase()) ||
        value2.eLearningLicenseId.toLowerCase().includes(query.toLowerCase()) ||
        value2.studentActorName?.toLowerCase().includes(query.toLowerCase())
      ) {
        rows.push({
          id: key + key2,
          eLearningLicenseId: value2.eLearningLicenseId,
          courseSubsStartBookedDate: date,
          studentActorName: value2.studentActorName,
        });
      }
    }
    if (rows.length > 0) {
      companyRows.push({ companyName: value.companyName, rows: rows });
    }
  }

  return (
    <div>
      <YesOrNoDialog
        open={showRecallStudentLicenseWarning}
        title={Translate.get("RecallStudentLicenseTitle")}
        text={Translate.get("RecallStudentLicenseInfo")}
        onNo={() => setShowRecallStudentLicenseWarning(false)}
        onYes={handleRecall}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Recall")}
      />
      <Dialog
        className="bigCourseDialog"
        open={open}
        onClose={handleClose}
        scroll="paper"
        /* PaperProps={{
          sx: {
            minHeight: '80vh',
            maxHeight: '80vh',
            maxWidth: '80vh',
            minWidth: smallScreen ? '90%' : '95vh',
          },
        }} */
      >
        {isLoading && <Preloader />}
        <DialogTitle>
          {' '}
          {Translate.get('Recall')} {eLearningTypes[eLearningType]}-
          {Translate.get('Passwords').toLowerCase()}
          <DialogContentText sx={{ color: '#212B36', marginTop: '16px' }}>
            {totalLicenses} {Translate.get('UnstartedLicensesFound')}
          </DialogContentText>
          <TextField
            fullWidth
            onChange={handleChange}
            name="query"
            value={query}
            margin="normal"
            label={Translate.get('Search')}
            type="search"
          />
        </DialogTitle>

        <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {getTable()}
        </DialogContent>
        <DialogActions>
          <Button name="balance" onClick={handleClose} variant="text">
            {Translate.get('Cancel')}
          </Button>
          <Button
            onClick={handleRecallStart}
            variant="contained"
            disabled={recallButtonIsDisabled}
          >
            {Translate.get('Recall')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get('PasswordsRecalled')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translate.get('PasswordsRecalledToBalance')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function getTable() {
    //if (smallScreen === false) {
      return (
        <TableContainer
          fullwidth="true"
          sx={{ minHeight: 520 }}
          //component={Paper}
        >
          <Table aria-label="simple table" size="160">
            <TableBody>
              {companyRows.map((companyRow, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell colSpan={4} sx={{ paddingLeft: '24px' }}>
                      <div className="smallHeader">
                        {companyRow.companyName} ({companyRow.rows.length})
                      </div>
                    </TableCell>
                  </TableRow>
                  {companyRow.rows.map((row, index2) => (
                    <TableRow
                      key={index2}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ paddingLeft: '24px' }}>
                        <div className="stdFlexLeft">
                          <Checkbox
                            onClick={check}
                            name={row.eLearningLicenseId}
                            value={row.eLearningLicenseId}
                            sx={{ padding: 0 }}
                          />
                        </div>
                      </TableCell>
                      {smallScreen && (
                        <TableCell colSpan={3}>
                          <div
                            className="stdFlex"
                            style={{ flexDirection: 'column' }}
                          >
                            <div className="stdFlexLeft">
                              <VpnKeyIcon />
                              {row.eLearningLicenseId}
                            </div>
                            <div className="stdFlexLeft">
                              <CalendarTodayIcon sx={{ mr: 1 }} />{' '}
                              {row.courseSubsStartBookedDate}
                            </div>
                            <div className="stdFlexLeft">
                              <PersonIcon />
                              {row.studentActorName ?? '---'}
                            </div>
                          </div>
                        </TableCell>
                      )}
                      {!smallScreen && (
                        <>
                          <TableCell>
                            <div className="stdFlexLeft">
                              <VpnKeyIcon />
                              {row.eLearningLicenseId}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="stdFlexLeft">
                              <CalendarTodayIcon sx={{ mr: 1 }} />{' '}
                              {row.courseSubsStartBookedDate}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="stdFlexLeft">
                              <PersonIcon />
                              {row.studentActorName ?? '---'}
                            </div>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    /* } else {
      return (
        <div>
          {companyRows.map((companyRow, index) => (
            <div key={index}>
              <h2 key={index}>{companyRow.companyName}</h2>
              {companyRow.rows.map((row, index2) => (
                <Card sx={{ mb: 1 }} key={index2}>
                  <CardContent>
                    <p>
                      <VpnKeyIcon sx={{ mr: 1 }} /> {row.eLearningLicenseId}
                    </p>
                    <p>
                      <CalendarTodayIcon sx={{ mr: 1 }} />{' '}
                      {row.courseSubsStartBookedDate}
                    </p>
                    <p className="studentRow">{row.studentActorName}</p>
                    <p className="studentRow">{companyRow.companyName}</p>
                    <Box display="flex" justifyContent="flex-end">
                      <Grid justify="flex-end">
                        <Checkbox
                          onClick={check}
                          name={row.eLearningLicenseId}
                          value={row.eLearningLicenseId}
                        />
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </div>
          ))}
        </div>
      );
    } */
  }
}
