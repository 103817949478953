import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import EmailValidatingTextField from "../../EmailValidatingTextField";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import YesOrNoDialog from "../../YesOrNoDialog";
import { actorSearch, actorPersonTypes } from "../../../utils/actorSearch";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from "@mui/icons-material/Clear";
import {
  hasAccess,
  hasSomeAccess,
  accessKeys,
} from "../../../utils/userAccess";

export default function StudentDetailsDialog({
  studentCount,
  onStudentCountChange,
  onCompleteBooking,
  isPrivateBooking,
  courseId,
  companyActorId,
}) {
  const [students, setStudents] = useState(
    [...Array(studentCount)].map((_) => ({ actorName: "", email: "" }))
  );
  const [isStudentEmailValid, setIsStudentEmailValid] = useState(
    [...Array(studentCount)].map((_) => true)
  );

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  async function getStudentActiveCourseParticipantId(actorId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/actor/${actorId}/has-active-bookings/`,
      false,
      "GET"
    );

    return response && response.length > 0 ? response[0] : null;
  }

  function compareStudent(a, b) {
    if (!companyActorId) {
      return 0;
    }

    const aIsConnectedToCompany = a.companies.some(
      (c) => c.actorId == companyActorId
    );
    const bIsConnectedToCompany = b.companies.some(
      (c) => c.actorId == companyActorId
    );
    if (aIsConnectedToCompany && !bIsConnectedToCompany) {
      return -1;
    } else if (bIsConnectedToCompany && !aIsConnectedToCompany) {
      return 1;
    }

    return 0;
  }

  async function studentSearch(searchText) {
    const searchResults = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName", "OrgNo"],
      50
    );
    if (searchResults) {
      let foundStudents = searchResults.map((student) => ({
        actorId: student.actorId,
        actorName: student.actorName,
        orgNo: student.orgNo,
        email: student.email,
        companies: student.parentActors
          ?.filter(
            (x, index, allParentActors) =>
              x.parentActor.actorId > 0 &&
              x.parentActor.actorId !== student.actorId &&
              allParentActors.findIndex(
                (y) => y.parentActor.actorId === x.parentActor.actorId
              ) === index
          )
          .map((x) => ({
            actorName: x.parentActor.actorName,
            actorId: x.parentActor.actorId,
          })),
      }));
      // Note that the students are already sorted based on search match. But here we want to prioritize
      // students connected to the same company. We haven't implemented support for weights in the search yet.
      // But this solution works well too.
      foundStudents.sort(compareStudent);
      return foundStudents;
    }
  }

  useEffect(() => {
    async function checkIfActorHasUser(actorId) {
      var hasUser = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/has-user`,
        false,
        "GET"
      );
      if (hasUser) {
        const studentIndex = students.findIndex((s) => s.actorId === actorId);
        if (studentIndex >= 0 && !("hasUser" in students[studentIndex])) {
          const studentCopy = { ...students[studentIndex], hasUser };
          const studentsCopy = [...students];
          studentsCopy[studentIndex] = studentCopy;
          setStudents(studentsCopy);
        }
      }
    }

    const studentsToCheck = students.filter(
      (s) => s.actorId && !("hasUser" in s)
    );
    for (const student of studentsToCheck) {
      checkIfActorHasUser(student.actorId);
    }
  }, [students, api]);

  async function handleStudentChange(student, studentIndex) {
    let studentCopy = student;
    const activeCourseParticipantId =
      student?.actorId &&
      (await getStudentActiveCourseParticipantId(student.actorId));
    if (activeCourseParticipantId) {
      studentCopy = { ...student, activeCourseParticipantId };
    }
    const studentsCopy = [...students];
    studentsCopy[studentIndex] = studentCopy ?? { actorName: "", email: "" };
    setStudents(studentsCopy);
  }

  function handleStudentEmailChange(email, studentIndex) {
    const studentsCopy = [...students];
    const studentCopy = { ...students[studentIndex], email };
    studentsCopy[studentIndex] = studentCopy;
    setStudents(studentsCopy);
  }

  function handleEmailIsValidChange(isValid, studentIndex) {
    const isStudentEmailValidCopy = [...isStudentEmailValid];
    isStudentEmailValidCopy[studentIndex] = isValid;
    setIsStudentEmailValid(isStudentEmailValidCopy);
  }

  function handleSetStudentCompleteAction(actorId, completeAction) {
    const studentIndex = students.findIndex((s) => s.actorId === actorId);
    const studentsCopy = [...students];
    const studentCopy = { ...students[studentIndex], completeAction };
    studentsCopy[studentIndex] = studentCopy;
    setStudents(studentsCopy);
  }

  function handleRemoveStudent(index) {
    setStudents((prev) => [...prev].toSpliced(index, 1));
    setIsStudentEmailValid((prev) => [...prev].toSpliced(index, 1));
    onStudentCountChange(studentCount - 1);
  }

  function handleAddStudent() {
    setStudents((prev) => [...prev, { actorName: "", email: "" }]);
    setIsStudentEmailValid((prev) => [...prev, true]);
    onStudentCountChange(studentCount + 1);
  }

  const title = `${Translate.get("EnterPersonalDetails")}${
    !isPrivateBooking ? ` (${Translate.get("Optional").toLowerCase()})` : ""
  }`;
  return (
    <>
      <DialogTitle>
        <div className="stdFlexBetween">
          <div>{title}</div>
          {hasSomeAccess(
            accessKeys.standardPackage,
            accessKeys.advancedPackage
          ) && (
            <Tooltip title={Translate.get("QrCodeTip")}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "700px" }}>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          {students.map((student, idx) => (
            <Fragment key={idx}>
              <Stack
                direction="row"
                className="stdFlexLeft"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <PeopleIcon sx={{ marginLeft: "9px", marginRight: "8px" }} />
                <Stack
                  direction={isSmallScreenOrGreater ? "row" : "column"}
                  spacing={1}
                  className="stdFlexLeft"
                  sx={{ justifyContent: "stretch", width: "100%" }}
                >
                  <AutocompleteSearchField
                    placeholder={Translate.get("NameFieldHelptext")}
                    label={Translate.get("Name")}
                    value={student}
                    onValueChange={(student) =>
                      handleStudentChange(student, idx)
                    }
                    getOptionLabel={(option) => option?.actorName ?? ""}
                    renderOption={(option) => (
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        orgNo={option?.orgNo}
                        email={option?.email}
                        companies={option?.companies.map((c) => c.actorName)}
                      />
                    )}
                    keyPropName="actorId"
                    requireSelection={true}
                    search={studentSearch}
                    createNewValue={(text) => ({
                      actorName: text,
                      email: student?.email,
                    })}
                    textFieldProps={{
                      required: isPrivateBooking || !!student?.email,
                    }}
                  />
                  <EmailValidatingTextField
                    fullWidth
                    disabled={student.hasUser}
                    autoComplete="off"
                    placeholder={Translate.get("Email")}
                    label={Translate.get("Email")}
                    value={student.email ?? ""}
                    onEmailChange={(newValue) =>
                      handleStudentEmailChange(newValue, idx)
                    }
                    onValidationChange={(isValid) =>
                      handleEmailIsValidChange(isValid, idx)
                    }
                  />
                </Stack>
                {studentCount > 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveStudent(idx)}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Stack>
            </Fragment>
          ))}
        </Stack>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          sx={{
            marginTop: "20px",
            width: isSmallScreenOrGreater ? "auto" : "100%",
          }}
          onClick={handleAddStudent}
        >
          {Translate.get("AddMoreStudents")}
        </Button>
        {studentCount > 1 && (
          <Typography
            variant="body2"
            sx={{
              margin: "33px 0 0",
              color: "text.secondary",
            }}
          >
            {`${Translate.get("NewStudentCount")}: ${studentCount}`}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            margin: "33px 0 0",
            color: "text.secondary",
          }}
        >
          {isPrivateBooking
            ? Translate.get("BookStudentMandatoryInfo")
            : Translate.get("BookStudentsOptionalInfo")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <Button
          onClick={() => onCompleteBooking(students)}
          variant="contained"
          disabled={
            isPrivateBooking
              ? !students[0].actorName
              : students.some(
                  (student) => !!student.email && !student.actorName
                ) || isStudentEmailValid.some((isValid) => !isValid)
          }
        >
          {studentCount > 1
            ? Translate.get("BookStudents")
            : Translate.get("BookStudent")}
        </Button>
      </DialogActions>
      <YesOrNoDialog
        open={students.some(
          (s) => s.activeCourseParticipantId && !s.completeAction
        )}
        title={Translate.get("MoveStudentTitle")}
        text={Translate.get("MoveStudentQuestion")}
        onNo={() => {
          const student = students.find(
            (s) => s.activeCourseParticipantId && !s.completeAction
          );
          handleSetStudentCompleteAction(student.actorId, "add");
        }}
        onYes={() => {
          const student = students.find(
            (s) => s.activeCourseParticipantId && !s.completeAction
          );
          handleSetStudentCompleteAction(student.actorId, "move");
        }}
        noText={Translate.get("MoveStudentNo")}
        yesText={Translate.get("MoveStudentYes")}
      />
    </>
  );
}
