import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import DeleteIcon from "@mui/icons-material/Delete";

export default function PrintTemplateTextBoxEditor({
  textBox,
  availableTextBoxes,
  onChange,
  onDelete,
  availableTextAlignments,
  availableTextColors,
  availableFontWeights,
  availableFonts,
}) {
  return (
    <Box
      border="1px solid rgba(145, 158, 171, 0.24)"
      borderRadius="8px"
      padding="8px"
      width="100%"
    >
      {!textBox && (
        <Grid
          container
          direction="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
          // Decided based on the size when there is a textBox
          sx={{ minWidth: "666px", height: "192px" }}
        >
          <Grid item>
            <Typography color="text.secondary" fontStyle="italic">
              {Translate.get("SelectTextFieldToEdit")}
            </Typography>
          </Grid>
        </Grid>
      )}
      {textBox && (
        <Grid
          container
          direction="column"
          width="100%"
          alignItems="stretch"
          gap="20px"
        >
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            width="100%"
            justifyContent="space-between"
            alignItems="stretch"
            gap="18px"
            margin="12px 0 0 5px"
          >
            <Grid item>{availableTextBoxes[textBox.name]?.displayName}</Grid>
            <Grid item marginTop="-8px" marginRight="5px">
              <Button
                endIcon={<DeleteIcon />}
                onClick={() => onDelete(textBox)}
              >
                {Translate.get("DeleteTextField")}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            width="100%"
            justifyContent="space-evenly"
            alignItems="stretch"
            gap="18px"
          >
            <Grid item>
              <TextField
                fullWidth
                disabled={!textBox}
                label={Translate.get("FontSize")}
                placeholder={Translate.get("FontSize")}
                value={textBox?.fontSize ?? ""}
                onChange={(ev) => onChange("fontSize", ev.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 6, // Just to avoid making it too small to see and select
                }}
                InputLabelProps={{
                  shrink: !!textBox?.fontSize,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                disabled={!textBox}
                label={Translate.get("TextWidth")}
                placeholder={Translate.get("TextWidth")}
                value={textBox?.textBoxWidth ?? ""}
                onChange={(ev) => onChange("textBoxWidth", ev.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 15, // Just to avoid making it too small to see and select
                }}
                InputLabelProps={{
                  shrink: !!textBox?.textBoxWidth,
                }}
              />
            </Grid>
            <Grid item width="210px">
              <FormControl fullWidth>
                <InputLabel id="text-alignment-select-label">
                  {Translate.get("TextAlignment")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="text-alignment-select-label"
                  id="text-alignment-select"
                  label={Translate.get("TextAlignment")}
                  disabled={!textBox || !availableTextAlignments}
                  value={textBox?.textAlignment ?? ""}
                  onChange={(ev) => onChange("textAlignment", ev.target.value)}
                >
                  {availableTextAlignments &&
                    availableTextAlignments.map((textAlignment) => (
                      <MenuItem value={textAlignment} key={textAlignment}>
                        {textAlignment}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            width="100%"
            justifyContent="space-evenly"
            alignItems="stretch"
            gap="18px"
          >
            <Grid item width="210px">
              <FormControl fullWidth>
                <InputLabel id="font-select-label">
                  {Translate.get("TypeOfFont")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="font-select-label"
                  id="font-select"
                  label={Translate.get("TypeOfFont")}
                  disabled={!textBox || !availableFonts}
                  value={textBox?.fontFamily ?? ""}
                  onChange={(ev) => onChange("fontFamily", ev.target.value)}
                >
                  {availableFonts &&
                    availableFonts.map((font) => (
                      <MenuItem value={font} key={font}>
                        {font}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item width="210px">
              <FormControl fullWidth>
                <InputLabel id="font-weights-select-label">
                  {Translate.get("FontWeights")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="font-weights-select-label"
                  id="font-weights-select"
                  label={Translate.get("FontWeights")}
                  disabled={!textBox || !availableFontWeights}
                  value={textBox?.fontWeights ?? ""}
                  onChange={(ev) => onChange("fontWeights", ev.target.value)}
                >
                  {availableFontWeights &&
                    availableFontWeights.map((fontWeight) => (
                      <MenuItem value={fontWeight} key={fontWeight}>
                        {fontWeight}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item width="210px">
              <FormControl fullWidth>
                <InputLabel id="text-color-select-label">
                  {Translate.get("ColorProfileForeground")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="text-color-select-label"
                  id="text-color-select"
                  label={Translate.get("ColorProfileForeground")}
                  disabled={!textBox || !availableTextColors}
                  value={textBox?.textColor ?? ""}
                  onChange={(ev) => onChange("textColor", ev.target.value)}
                >
                  {availableTextColors &&
                    availableTextColors.map((textColor) => (
                      <MenuItem value={textColor.value} key={textColor.value}>
                        {textColor.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
