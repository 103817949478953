import React from 'react';
import { Container } from '@mui/material';

export default function BankIdInstructions() {
  return (
    <Container
      maxWidth="md"
      style={{
        padding: '20px',
        fontSize: '1rem',
        lineHeight: '1.6',
        maxWidth: '90%',
        margin: '0 auto',
        marginTop: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            flex: '1',
            minWidth: '200px',
            paddingRight: '20px',
            textAlign: 'center',
          }}
        >
          <img
            src="/static/instructor_logo.png"
            alt="BankID Instructions"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>

        <div
          style={{
            flex: '2',
            minWidth: '300px',
          }}
        >
          <section>
            <h2 style={{ fontSize: '1.4rem', marginBottom: '10px' }}>
              Om BankID inte öppnar i Samsung Internet
            </h2>
            <p>Följ stegen nedan för att lösa problemet:</p>
            <ol style={{ paddingLeft: '1.2rem' }}>
              <li>Gå till inställningar på telefonen (Kugghjulet).</li>
              <li>Välj Appar och klicka på “Samsungs appinställningar”.</li>
              <li>Scrolla ner och klicka in på “Samsung internet”.</li>
              <li>I inställningarna för internet väljer du “Användbara Funktioner”.</li>
              <li>Dra reglaget för “Öppna applänkar i webbläsaren” till vänster så att det är avstängt.</li>
            </ol>
          </section>

          <section style={{ marginTop: '20px' }}>
            <h2 style={{ fontSize: '1.4rem', marginBottom: '10px' }}>
              Om BankID inte öppnar på Androidenhet
            </h2>
            <p>Följ stegen nedan för att lösa problemet:</p>
            <ol style={{ paddingLeft: '1.2rem' }}>
              <li>Öppna inställningar.</li>
              <li>Gå till Appar -{'>'} Välj standardappar -{'>'} Öppna länkar.</li>
              <li>Klicka på BankID i listan.</li>
              <li>Slå på valet ”Öppna länkar som stöds”.</li>
            </ol>
          </section>

          <section style={{ marginTop: '20px' }}>
            <h2 style={{ fontSize: '1.4rem', marginBottom: '10px' }}>
              Om BankID inte öppnar på Appleenhet
            </h2>
            <p>Följ stegen nedan för att lösa problemet om du inte har Safari som standardwebbläsare:</p>
            <ol style={{ paddingLeft: '1.2rem' }}>
              <li>Öppna inställningar.</li>
              <li>Gå till Safari -{'>'} Förvald app för webbläsare.</li>
              <li>Välj Safari.</li>
            </ol>
          </section>
        </div>
      </div>
      <style>
        {`
          @media (min-width: 768px) {
            .MuiContainer-root {
              margin-top: 80px;
            }
          }
        `}
      </style>
    </Container>
  );
}
