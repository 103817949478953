import { useState, useEffect } from "react";
import { isMobile } from "is-mobile";
import {
  DataGridPremium,
  useGridApiRef,
  gridPaginationModelSelector,
} from "@mui/x-data-grid-premium";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";

import BuildIcon from "@mui/icons-material/Build";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import MailIcon from "@mui/icons-material/Mail";
import PreviewIcon from "@mui/icons-material/Preview";

import { useNavigate } from "react-router-dom";
import Translate from "../../utils/Translate";
import { formatDateRange } from "../../utils/dateTimeFormat";
import Api, { useApi } from "../../utils/Api";
import DotMenu from "../DotMenu";
import YesOrNoDialog from "../YesOrNoDialog";
import { useSnackbar } from "notistack";
import download from "downloadjs";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { TableAvatar } from "../../utils/dataGrid";
import PermitCard from "../actor-permits/PermitCard";
import {
  permitStatusToSortableValue,
  renderPermitStatus,
  formatPermitStatus,
} from "../actor-permits/utils/permitStatus";
import { dataGridLang } from "../MemToolBar";
import { ToolBar } from "../ToolBar";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";

import { formatDateWithMonthOffSet } from "../../utils/dateTimeFormat";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConstructionIcon from "@mui/icons-material/Construction";

import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecyclingIcon from "@mui/icons-material/Recycling";
import ActorPermitFlowDialogContainer from "../actor-permits/ActorPermitFlowDialogContainer";

const getDotMenuActions = ({
  actorPermit,
  onInactivateActorPermit,
  onDeleteActorPermitPermit,
  onSendPermitSignReminder,
  handleMarkPermitAsReviewed,
}) => {
  const manualPermit = actorPermit.blobFile && true;

  const isSignedByBoth =
    actorPermit.isSignedByResponsible && actorPermit.isSignedByStudent;

  // körtillståndet är gilltigt ifall validTo är null eller har passerat
  const isActive =
    !actorPermit.validTo || new Date(actorPermit.validTo) > new Date();

  // för att kunna tas bort ska körtillståndet vara inaktivt och inte signerat av båda, manuella körtilstånd signeras inte
  const deletePossible = !isActive || (!manualPermit && !isSignedByBoth);

  const inactivatePossible = !deletePossible && isActive;

  const menudata = [
    {
      icon: <MailIcon />,
      disabled: isSignedByBoth,
      text: Translate.get("ResendSignarureRequest"),
      onClick: () =>
        onSendPermitSignReminder(
          actorPermit.actorPermitGroupId,
          actorPermit.actorPermitId
        ),
      hide: actorPermit.blobFile !== null,
    },
    {
      icon: <EventBusyIcon />,
      disabled: !inactivatePossible,
      text: Translate.get("InactivatePermit"),
      onClick: () => onInactivateActorPermit(actorPermit.actorPermitId),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeletePermit"),
      disabled: !deletePossible,
      onClick: () => onDeleteActorPermitPermit(actorPermit.actorPermitId),
    },
    {
      icon: <PreviewIcon />,
      text: Translate.get("MarkAsReviewed"),
      onClick: () => handleMarkPermitAsReviewed(actorPermit.actorPermitId),
    },
    {
      icon: <RecyclingIcon />,
      text: Translate.get("CreateFromThis"),
      component: (
        <ActorPermitFlowDialogContainer
          actorIds={[actorPermit?.actorId]}
          skipStep1={false}
          cloneFromActorPermit={actorPermit}
        />
      ),
      disabled: actorPermit?.blobFile,
    },
  ];
  return menudata;
};

const Permits = ({
  permits,
  smallScreen,
  actorName,
  actorOrgNo,
  cardView = true,
  hideFilter = true,
  defaultSort,
  defaultStdFilter,
  onReload,
}) => {
  const [actorPermitIdToInactivate, setActorPermitIdToInactivate] =
    useState(null);
  const [actorPermitIdToDelete, setActorPermitIdToDelete] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: cardView ? 5 : 100,
    page: 0,
  });
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //const initialState = getInitialState(defaultSort);
  const [loading, setLoading] = useState(false);

  const api = useApi();
  //Custom filter ------------------------------
  const [stdFilters, setStdFilters] = useState([
    "SelectFilter",
    "MyPermits",
    "PermitExpires",
    "PermitsNotFullySigned",
    "ReviewNeeded",
  ]);
  const [selectedStdFilter, setSelectedStdFilter] = useState("SelectFilter");
  const [filt, setFilt] = useState({
    items: [],
  });

  const [teacherActorIds, setTeacherActorIds] = useState([]);
  const [actorId, setActorId] = useState("");
  const [sortModel, setSortModel] = useState([
    {
      field: "validTime",
      sort: "desc",
    },
  ]);
  useEffect(() => {
    if (defaultStdFilter === "permitExpires") {
      setStdFilter("PermitExpires");
      setSelectedStdFilter("PermitExpires");
    } else if (defaultStdFilter === "reviewNeeded") {
      setSelectedStdFilter("ReviewNeeded");
      setStdFilter("ReviewNeeded");
    }
  }, []);

  useEffect(async () => {
    await loadData();
    getPagination(apiRef, "permits");
  }, [permits]);

  async function loadData() {
    const [filterData] = await Promise.all([
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/teachers/filter/actorids`,
        false,
        "GET"
      ),
    ]);

    if (filterData) {
      if (filterData.isSuccessful !== false) {
        setTeacherActorIds(filterData.teacherActorIds);
        if (filterData.teacherActorIds.length < 1) {
          setStdFilters(
            stdFilters.filter((i) => i !== "MyInstructorsCourseBookings")
          );
        }
        setActorId(filterData.actorId);
        if (!filterData.actorId) {
          setStdFilters(stdFilters.filter((i) => i !== "MyCourseBookings"));
        }
      }
    }
    //setDataFetched(true);
  }
  /*   function getInitialState(defaultSort) {
      //Är denna någon gång annat än null? Används inte i detta läge alls
      if (defaultSort === "statusAsc") {
        setSortModel([
          {
          field: "customPermitStatus",
          sort: "asc",
        }
        ]);
      }
     
    } */
  function handleStdFilterChange(event) {
    setSelectedStdFilter(event.target.value);
    setStdFilter(event.target.value);
  }

  function setStdFilter(filterName) {
    switch (filterName) {
      case "SelectFilter":
        setFilt({ items: [] });
        break;

      case "MyPermits":
        setFilt({
          ...filt,
          items: [
            {
              id: 1,
              field: "responsibleActorId",
              operator: "equals",
              value: actorId.toString(),
              filterName: "MyPermits",
            },
            {
              id: 2,
              field: "actorId",
              operator: "equals",
              value: actorId.toString(),
              filterName: "MyPermits",
            },
          ],
          logicOperator: "or",
        });
        break;
      case "PermitExpires":
        setSortModel([
          {
            field: "validTo",
            sort: "desc",
          },
        ]);
        setFilt({
          ...filt,
          items: [
            {
              id: 3,
              field: "fActorPermit",
              operator: "equals",
              value: "1",
              filterName: "ActorPermit",
            },
          ],
        });
        break;
      case "PermitsNotFullySigned":
        setFilt({
          ...filt,
          items: [
            {
              id: 4,
              field: "PermitNotSigned",
              operator: "equals",
              value: "1",
              filterName: "PermitNotSigned",
            },
          ],
        });
        break;

      case "ReviewNeeded":
        setFilt({
          ...filt,
          items: [
            {
              id: 5,
              field: "reviewIsDue",
              operator: "is",
              value: "true",
              filterName: "reviewIsDue",
            },
          ],
        });
        break;

      default:
        return;
    }
  }

  function clearFilters() {
    setFilt({ items: [] });
    setSelectedStdFilter("SelectFilter");
    clearQueryParameter("std-filter");
  }

  function clearQueryParameter(param) {
    let url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, "", url);
  }

  function handleFilterModelChange(newFilterModel, x) {
    if (x.reason === "restoreState") {
      return;
    }

    if (x.reason === "changeLogicOperator") {
      setFilt({
        ...filt,
        logicOperator: newFilterModel.logicOperator,
      });
      return;
    }

    const newFilt = {
      items: newFilterModel.items,
      logicOperator: newFilterModel.logicOperator,
    };
    setFilt(newFilt);

    var stdFilterExistsInFilters = newFilt.items.findIndex(
      (e) => e.filterName === selectedStdFilter
    );
    if (stdFilterExistsInFilters === -1) {
      setSelectedStdFilter("SelectFilter");
    }
  }
  //Custom filter end ------------------------------

  const localizedTextsMap = dataGridLang();

  async function handleReload() {
    if (onReload) {
      await onReload();
    } else {
      navigate(0);
    }
  }
  const downloadCustomPermit = (documentId) => {
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}permits/file/get/${documentId}`
      );
      (async () => {
        const res = await fetch(data.sasURI);
        const contentType = res.headers.get("Content-Type");
        const raw = await res.blob();
        download(raw, data.filename, contentType);
      })();
    })();
  };

  async function handleDownloadActorPermit(actorPermitId, actorName) {
    setLoading(actorPermitId);
    const blob = await Api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}permits/pdf/admin?actorPermitId=${actorPermitId}`,
      false,
      "GET"
    );
    const filename = `permit-${actorName.trim().replace(" ", "_")}.pdf`;
    download(blob, filename, blob.type);
    setLoading(false);
  }

  async function handleInactivateActorPermit() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/${actorPermitIdToInactivate}/inactivate`,
      true,
      "POST"
    );
    if (response && response.isSuccessful) {
      await handleReload();
    }
    setActorPermitIdToInactivate(null);
  }

  const navigateToActor = (actorId) => {
    setPagination(apiRef, "permits");
    sessionStorage.removeItem("paginationModel_courses");
    navigate(`/dashboard/person/${actorId}`, { replace: false });
  };

  async function handleDeleteActorPermit() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/${actorPermitIdToDelete}`,
      true,
      "DELETE"
    );
    if (response && response.isSuccessful) {
      await handleReload();
    }
    setActorPermitIdToInactivate(null);
  }

  async function handleMarkPermitAsReviewed(actorPermitId) {
    let reqObj = {
      actorPermitId: actorPermitId,
    };

    let response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/review`,
      reqObj,
      "POST"
    );

    if (response && response.isSuccessful) {
      await handleReload();
    }
  }

  async function handleSendNextPermitSignEmail(
    actorPermitGroupId,
    actorPermitId
  ) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/email/next-signer?actorPermitGroupId=${actorPermitGroupId}&actorPermitId=${actorPermitId}`,
      false,
      "GET"
    );
    if (response && response.isSuccessful) {
      const successMessage = enqueueSnackbar(
        Translate.get("SignReminderSent"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
      await handleReload();
    }
  }

  let columns = [];
  //let toolbarCss = "stdVisible dgDynIconText";
  /* if (hideFilter) {
    toolbarCss = "dgNoFilter";
  }
  if (cardView) {
    toolbarCss = toolbarCss + " dgNoIconText";
  } */
  /* if (smallScreen) {
    toolbarCss = "dgSmall dgCardHolder";
  } */
  let toolbarCss = "stdVisible dgDynIconText";
  if (smallScreen) {
    toolbarCss = "stdVisible tableAndCard";
  }
  if (cardView) {
    toolbarCss = toolbarCss + "  dgNoFilter";
  }
  columns = [
    {
      field: "machineGroup",
      headerName: Translate.get("MachineGroup"),
      flex: 1,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <ConstructionIcon />
          <div>{Translate.get("MachineGroup")}</div>
        </div>
      ),
    },
    {
      field: "machineTypes",
      headerName: Translate.get("MachineType"),
      hide: !cardView,
      flex: 1,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <BuildIcon />
          <div>{Translate.get("MachineType")}</div>
        </div>
      ),
    },
    {
      field: "validTime",
      headerName: Translate.get("ValidTime"),
      flex: 1,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <DateRangeIcon />
          <div>{Translate.get("ValidTime")}</div>
        </div>
      ),
      valueGetter: (params) =>
        formatDateRange(params.row.validFrom, params.row.validTo),
    },
    ...(smallScreen && isMobile({ tablet: true, featureDetect: true })
      ? []
      : [
          {
            field: "responsibleName",
            headerName: Translate.get("Responsible"),
            flex: 1,
            renderHeader: () => (
              <div className="stdFlexLeft">
                <AccountBoxIcon />
                <div>{Translate.get("Responsible")}</div>
              </div>
            ),
            renderCell: (params) => {
              return (
                <TableAvatar
                  hideImage
                  boldTitle={false}
                  title={params.row.responsibleActorName}
                  {...(!cardView
                    ? { subtitle: [params.row.responsibleActorEmail ?? ""] }
                    : {})}
                />
              );
            },
          },
          {
            field: "customPermitStatus",
            headerName: Translate.get("Status"),
            flex: 0.5,
            minWidth: 100,
            filterable: false,
            valueFormatter: (params) => formatPermitStatus(params.value),
            renderHeader: () => (
              <div className="stdFlexLeft">
                <CheckCircleIcon />
                <div>{Translate.get("Status")}</div>
              </div>
            ),
            renderCell: (params) => renderPermitStatus(params.value),
            // This valueGetter will allow us to sort the status column, without using sortComparator
            valueGetter: (params) => permitStatusToSortableValue(params.row),
            // sortComparator: (v1, v2) => v1.localeCompare(v2),
          },
          {
            field: "reviewIsDue",
            headerName: Translate.get("ReviewNeeded"),
            flex: 1,
            renderHeader: () => (
              <div className="stdFlexLeft">
                <PreviewIcon />
                <div>{Translate.get("ReviewNeeded")}</div>
              </div>
            ),
            type: "boolean",
            renderCell: (params) => {
              if (params.row.reviewIsDue === true) {
                return (
                  <div>
                    <Tooltip title={Translate.get("ReviewNeeded")}>
                      <PendingActionsIcon />
                    </Tooltip>
                  </div>
                );
              }
              return <div></div>;
            },
          },
          {
            field: "reviewDate",
            headerName: Translate.get("ReviewDate"),
            flex: 1,
            type: "date",
            renderHeader: () => (
              <div className="stdFlexLeft">
                <PreviewIcon />
                <div>{Translate.get("ReviewDate")}</div>
              </div>
            ),
            valueGetter: (params) =>
              params.row.reviewDate ? new Date(params.row.reviewDate) : "",
          },
          {
            field: "reviewer",
            headerName: Translate.get("Reviewer"),
            flex: 1,
            renderHeader: () => (
              <div className="stdFlexLeft">
                <PreviewIcon />
                <div>{Translate.get("Reviewer")}</div>
              </div>
            ),
            valueGetter: (params) => params.row.reviewer,
          },
          {
            field: "custom1",
            headerName: Translate.get("Permit"),
            flex: 0,
            sortable: false,
            filterable: false,
            disableExport: true,
            renderHeader: () => <div></div>,
            renderCell: (params) => {
              return (
                <Tooltip title={Translate.get("Download")}>
                  <LoadingButton
                    className="regularButtonWithIcon stdLightLoader"
                    variant="outlined"
                    //tooltip={Translate.get('Download')}
                    loading={params.row.actorPermitId === loading}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (params.row.blobFile === null) {
                        handleDownloadActorPermit(
                          params.row.actorPermitId,
                          params.row.actorName ?? actorName
                        );
                      } else {
                        downloadCustomPermit(
                          params.row.blobFile.blobDocumentId
                        );
                      }
                    }}
                  >
                    <DownloadIcon />
                  </LoadingButton>
                </Tooltip>
              );
            },
          },
          {
            field: "dotview",
            headerName: "Menu",
            flex: 0,
            maxWidth: 50,
            align: "right",
            sortable: false,
            filterable: false,
            hide: false,
            disableExport: true,
            renderHeader: () => <div></div>,
            renderCell: (params) => {
              return (
                <DotMenu
                  content={getDotMenuActions({
                    actorPermit: params.row,
                    onInactivateActorPermit: setActorPermitIdToInactivate,
                    onDeleteActorPermitPermit: setActorPermitIdToDelete,
                    onSendPermitSignReminder: handleSendNextPermitSignEmail,
                    handleMarkPermitAsReviewed: handleMarkPermitAsReviewed,
                  })}
                  horizontal
                />
              );
            },
          },
          {
            field: "validTo",
            headerName: "Tillstånd giltigt t.o.m",
            disableExport: true,
          },
          {
            field: "responsibleActorId",
            headerName: "Systemstatus: Id Ansvarig",
            disableExport: true,
          },
          {
            field: "actorId",
            headerName: "Systemstatus: Id Person",
            disableExport: true,
          },

          {
            field: "fActorPermit",
            headerName: "Systemstatus: Tillstånd snart utgåeende",
            disableExport: true,
            valueGetter: (params) => {
              //const fdate = new Date();
              //fdate.setDate(fdate.getDate() + 90);
              const fdate = formatDateWithMonthOffSet(new Date(), 3);
              let chk = 0;
              if (params.row.validTo) {
                if (
                  params.row.validTo < formatDateRange(fdate) &&
                  params.row.validTo > formatDateRange(new Date())
                ) {
                  chk = 1;
                }
              }
              return chk;
            },
          },
          {
            field: "PermitNotSigned",
            headerName: "Systemstatus: Ej signerat",
            disableExport: true,
            valueGetter: (params) => {
              let chk = 0;
              const chkArr = [-1, 6];
              if (
                (!params.row.signatureId ||
                  !params.row.responsibleSignatureId) &&
                !chkArr.includes(permitStatusToSortableValue(params.row))
              ) {
                chk = 1;
              }
              return chk;
            },
          },
        ]),
  ];
  if (!cardView && !smallScreen) {
    columns.unshift({
      field: "actorName",
      headerName: Translate.get("ActorName"),
      flex: 1,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <PersonIcon />
          <div>{Translate.get("ActorName")}</div>
        </div>
      ),
      renderCell: (params) => {
        return (
          <TableAvatar
            src={params.row.actorPictureUrl}
            title={params.row.actorName}
            subtitle={[
              params.row.actorEmail ?? "",
              params.row.actorOrgNo ?? "",
              params.row.externalActorNo ?? "",
            ]}
          />
        );
      },
      minWidth: 300,
    });
  }
  if (smallScreen) {
    columns.unshift({
      field: "actorName",
      headerName: Translate.get("ActorName"),
      flex: 1,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <PersonIcon />
          <div>{Translate.get("ActorName")}</div>
        </div>
      ),
      renderCell: (params) => {
        return (
          <PermitCard
            loading={loading}
            handleDownloadActorPermit={handleDownloadActorPermit}
            downloadCustomPermit={downloadCustomPermit}
            key={params.row.actorPermitId}
            actorName={actorName}
            cardView={cardView}
            {...params.row}
            extraActions={getDotMenuActions({
              actorPermit: params.row,
              onInactivateActorPermit: setActorPermitIdToInactivate,
              onDeleteActorPermitPermit: setActorPermitIdToDelete,
              onSendPermitSignReminder: handleSendNextPermitSignEmail,
              handleMarkPermitAsReviewed: handleMarkPermitAsReviewed,
            })}
          />
        );
      },
    });
  }
  const hiddenFields = [
    "responsibleActorId",
    "dotview",
    "PermitNotSigned",
    "fActorPermit",
    "actorId",
    "validTo",
    "Menu",
  ];
  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    responsibleActorId: false,
    PermitNotSigned: false,
    fActorPermit: false,
    actorId: false,
    validTo: false,
    reviewIsDue: false,
    reviewDate: false,
    reviewer: false,
  });
  const apiRef = useGridApiRef();
  const setPagination = (apiDg, page) => {
    const paginationModel = gridPaginationModelSelector(apiDg);
    sessionStorage.setItem(
      "paginationModel_" + page,
      JSON.stringify(paginationModel)
    );
  };
  const getPagination = (apiDg, page) => {
    //sessionStorage["paginationModel_" + page] kan raderas i andra komponenter om vi vill nollställa paginering till default
    //Ex: sessionStorage.removeItem("paginationModel_courses");
    if (sessionStorage["paginationModel_" + page]) {
      const paginationModel = gridPaginationModelSelector(apiDg);
      const p = JSON.parse(sessionStorage["paginationModel_" + page]);
      setPaginationModel(p);
    }
  };

  return (
    <>
      <Grid
        className={hideFilter ? "hideStdFilter" : ""}
        container
        justifyContent="flex-start"
        sx={{ ml: 1, mt: 3 }}
      >
        {/*  <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {Translate.get("LicenseStatisticsInfo")}
            </div>
          }
        >
          <IconButton>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip> */}

        <FormControl sx={{ minWidth: "5em" }}>
          <InputLabel id="std-filter">
            {Translate.get("StdFilter") ?? " "}
          </InputLabel>
          <Select
            sx={{ maxWidth: smallScreen ? "10em" : "100%" }}
            labelId="std-filter"
            id="std-filter"
            name="MyCourseParticipantsFilter"
            value={selectedStdFilter}
            label={Translate.get("StdFilter") ?? " "}
            onChange={handleStdFilterChange}
          >
            {stdFilters.map((element) => (
              <MenuItem key={element} value={element} name={element}>
                {Translate.get(element)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => clearFilters()}
          variant="outlined"
          disabled={filt.items?.length === 0}
          startIcon={<DeleteIcon />}
          sx={{ ml: "1em" }}
          size="xlarge"
        >
          {Translate.get("Purge")}
        </Button>
      </Grid>
      <DataGridPremium
        //columnVisibilityModel={columnVisibilityModel}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        className={toolbarCss}
        rows={permits}
        columns={columns}
        disableSelectionOnClick
        getRowId={(row) => row.actorPermitId}
        autoHeight={true}
        localeText={localizedTextsMap}
        pagination={smallScreen ? false : true}
        pageSizeOptions={cardView ? [5] : [10, 20, 30, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        density="comfortable"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        filterModel={filt}
        apiRef={apiRef}
        onFilterModelChange={(newFilterModel, x) =>
          handleFilterModelChange(newFilterModel, x)
        }
        slots={{
          toolbar: () =>
            ToolBar({
              hideFilter: smallScreen,
              showExport: true,
              exportFileName: Translate.get("ActorPermit"),
            }),
          noRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
        }}
        {...(!cardView
          ? {
              onRowClick: (params) => {
                navigateToActor(params.row.actorId);
              },
            }
          : {})}
        slotProps={{
          columnsPanel: {
            getTogglableColumns,
          },
        }}
        disableDensitySelector
        disableColumnSelector={smallScreen}
        getRowHeight={() => (smallScreen ? "auto" : 100)}
      />

      <YesOrNoDialog
        open={!!actorPermitIdToInactivate}
        title={Translate.get("InactivatePermit")}
        text={Translate.get("SureInactivatePermit")}
        onNo={() => setActorPermitIdToInactivate(null)}
        onYes={() => {
          handleInactivateActorPermit();
          setActorPermitIdToInactivate(null);
        }}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Ok")}
      />
      <YesOrNoDialog
        open={!!actorPermitIdToDelete}
        title={Translate.get("DeletePermit")}
        text={Translate.get("SureDeletePermit")}
        onNo={() => setActorPermitIdToDelete(null)}
        onYes={() => {
          handleDeleteActorPermit();
          setActorPermitIdToDelete(null);
        }}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Ok")}
      />
    </>
  );
};
export default Permits;
