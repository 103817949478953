import { Container, Stack, Box, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { TabPanel } from "../components/settings/TabPanel";
import Translate from "../utils/Translate";
import InstructorMaterialList from "../components/instructor-material/InstructorMaterialList";
import OrderRequest from "../components/instructor-material/OrderRequest";
import { useApi } from "../utils/Api";
import Page from "../components/Page";
import Preloader from "../components/Preloader";

export default function InstructorMaterial(props) {
  const [data, setData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const api = useApi();

  useEffect(() => {
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}agreements/instructor-material/`,
      false,
      "GET"
    ).then((data) => {
      setIsDataFetched(true);
      if (data) {
        setData(data);
      }
    });
  }, [api]);

  function handleTabSelected(event, newTabIndex) {
    setCurrentTabIndex(newTabIndex);
  }

  function handleFreeSampleOrdered(externalAgreementPartFreeSampleId) {
    const categoryIndex = data.findIndex((c) =>
      c.items.some(
        (item) =>
          item.isFreeSample &&
          item.externalAgreementPartFreeSampleId ===
            externalAgreementPartFreeSampleId
      )
    );
    const itemIndex = data[categoryIndex].items.findIndex(
      (item) =>
        item.isFreeSample &&
        item.externalAgreementPartFreeSampleId ===
          externalAgreementPartFreeSampleId
    );
    const itemCopy = {
      ...data[categoryIndex].items[itemIndex],
      hasAlreadyBeenOrdered: true,
    };
    const categoryItemsCopy = [...data[categoryIndex].items];
    categoryItemsCopy.splice(itemIndex, 1, itemCopy);
    const categoryCopy = { ...data[categoryIndex], items: categoryItemsCopy };
    const dataCopy = [...data];
    dataCopy.splice(categoryIndex, 1, categoryCopy);
    setData(dataCopy);
  }

  return (
    <Page>
      {!isDataFetched && <Preloader />}
      <Container className="fullWidth" disableGutters={true}>
        <div className="stdFlexBetween"><h2>{Translate.get("InstructorMaterial")}</h2><OrderRequest data={data} /></div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          
            <Tabs value={currentTabIndex} onChange={handleTabSelected} centered>
           
              {data.map((x, idx) => (
                <Tab
                  label={x.description}
                  key={idx}
                  disabled={x.items.length === 0}
                  sx={{
                    "&.Mui-disabled": {
                      opacity: 0.5,
                    },
                  }}
                />
              ))}
            </Tabs>
           
          </Box>
          {data.map((x, idx) => (
            <TabPanel className="tabPanelNoPadding"  value={currentTabIndex} index={idx} key={idx}>
              <Stack>
                {x.items.some(
                  (item) => !item.isDownloadAllowed && !item.isFreeSample
                ) && (
                  <InstructorMaterialList
                    title={Translate.get("Online")}
                    items={x.items.filter(
                      (item) => !item.isDownloadAllowed && !item.isFreeSample
                    )}
                  />
                )}
                {x.items.some((item) => item.isDownloadAllowed) && (
                  <InstructorMaterialList
                    title={Translate.get("Downloadable")}
                    items={x.items.filter((item) => item.isDownloadAllowed)}
                  />
                )}
                {x.items.some((item) => item.isFreeSample) && (
                  <InstructorMaterialList
                    title={Translate.get("FreeSampleMaterial")}
                    items={x.items.filter((item) => item.isFreeSample)}
                    onFreeSampleOrdered={handleFreeSampleOrdered}
                  />
                )}
              </Stack>
            </TabPanel>
          ))}
        </Box>
      </Container>
    </Page>
  );
}
