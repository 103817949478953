export const documentType = {
  orderConfirmation: 1,
  summoning: 2,
  invitation: 3,
  evaluation: 4,
  calendarBooking: 5,
  calendarCancelation: 6,
  eTruckLicense: 7,
  SMS: 8,
  elearningProofOfCompletion: 10,
  freeTextSMS: 11,
  courseSummoning: 12,
  extraExamInvitation: 13,
  deliveryNote: 14, // Följesedel
  deliveryNotification: 15, // Leveransavisering
};

export const mediaType = {
  undefined: 0,
  email: 1,
  sms: 2,
  pdf: 3,
  print: 4,
};
