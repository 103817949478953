import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import { isMobile } from "is-mobile";
import { AlertDialog } from "../AlertDialog";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../../components/DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import DigitalLicenseView from "./DigitalLicenseView";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogTitle, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import {
  addToAppleWallet,
  addToGoogleWallet,
  shareDigitalLicense,
} from "./digitalLicenseHelper";
import { useMemo } from "react";

export default function DigitalLicenseListItem({
  license,
  onClick,
  showDigitalPermitButton,
  skillGroupDescription,
  isRestrictedView,
}) {
  const navigate = useNavigate();
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dotMenuActions = useMemo(
    () => [
      {
        icon: <AddIcon />,
        text: Translate.get("AddToAppleWallet"),
        onClick: () =>
          addToAppleWallet(
            license.digitalLicenseId,
            enqueueSnackbar,
            closeSnackbar
          ),
      },
      {
        icon: <AddIcon />,
        text: Translate.get("AddToGoogleWallet"),
        onClick: () => addToGoogleWallet(api, license.digitalLicenseId),
      },
      {
        icon: <AddShoppingCartIcon />,
        text: Translate.get("PlaceOrder"),
        onClick: () => navigate(`/d/order/${license.digitalLicenseId}`),
      },
      {
        icon: <ShareIcon />,
        text: Translate.get("Share"),
        onClick: () =>
          shareDigitalLicense(
            license.publicShareUrl,
            enqueueSnackbar,
            closeSnackbar
          ),
      },
      /*  {
      icon: <DownloadIcon />,
      text: "Ladda ner",
      onClick: () => {},
    },  */
    ],
    [api, navigate, license, enqueueSnackbar, closeSnackbar]
  );

  return (
    <div className="dlcPreCard">
      <DigitalLicenseView
        displayData={license.displayData}
        animate={false}
        allowSwiper={false}
        showExtraInfo={false}
        isSmallCard={true}
      />

      <div className="stdFlexBetween">
        <div className="dlcHeading2">
          {license.displayData.fieldValues.LicenseGroupDescription}
        </div>
        <div>
          {/*  <DotMenu horizontal content={getDotMenuActions()} /> */}

          <DotMenu horizontal content={dotMenuActions} />
        </div>
      </div>
      <div className="iconTextRows">
        <div>
          <div>
            <EventAvailableIcon />
          </div>
          <div>{tryFormatDate(license.validFrom)}</div>
        </div>
        <div>
          <div>
            <DomainIcon />
          </div>
          <div>{license.courseCoordinatorName}</div>
        </div>
        <div>
          <div>
            <CoPresentIcon />
          </div>
          <div>{license.instructorName}</div>
        </div>
      </div>
      <div>
        <Button
          onClick={() =>
            navigate(`/d/licenses/${license.digitalLicenseId}`, {
              replace: false,
            })
          }
          variant="contained"
          fullWidth={true}
          startIcon={<RemoveRedEyeIcon />}
        >
          VISA
        </Button>
      </div>
    </div>
  );
}
