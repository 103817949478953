import Translate from "../../utils/Translate";
import DomainIcon from "@mui/icons-material/Domain";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import DotMenu from "../DotMenu";
import GdprDialog from "./GdprDialog";

function getDotMenuActions(row) {
  return [
    {
      icon: <PersonSearchIcon />,
      text: Translate.get("RequestData"),
      dialogHeader: Translate.get("RequestData"),
      route: false,
      hide: false,
      component: <GdprDialog companyName={row.name} companyDataId={row.dataId} action="ask" />,
    },
    {
      icon: <PersonRemoveIcon />,
      text:  Translate.get("RequestDelete"),
      dialogHeader:  Translate.get("RequestDelete"),
      route: false,
      hide: false,
      component: <GdprDialog companyName={row.name} companyDataId={row.dataId} action="delete" />,
    },
  ];
}
export default function CompaniesView({ company }) {
  return (
    <>
      <div
        className="dlCard"
        style={{ cursor: "default" }} /* onClick={onClick} */
      >
        <div className="dlCardIcon" style={{ maxWidth: "100%" }}>
          <div>
            <div>
              <DomainIcon />
            </div>
            <div style={{ flexGrow: 1 }}>{company.name}</div>
            <div>
              <DotMenu content={getDotMenuActions(company)} />
            </div>
          </div>
          <div>
            <div>
              <EmailIcon />
            </div>
            <div>{company.email}</div>
          </div>
          <div>
            <div>
              <LocalPhoneIcon />
            </div>
            <div>{company.phone}</div>
          </div>
          <div>
            <div>
              <LanguageIcon />
            </div>
            <div>{company.website}</div>
          </div>
        </div>
        {/*  <div className="_dlCardButtonBox">
      <div>
         <div><DotMenu content={getDotMenuActions(company)}  /></div>
      </div>
       
        
      </div> */}
      </div>
    </>
  );
}
