import { useState } from 'react';
import Translate from '../../utils/Translate';
import { useApi } from '../../utils/Api';
import { useSnackbar } from 'notistack';
import {
  TextField, Button
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PlaceIcon from '@mui/icons-material/Place';
const AddClassRoom = ({
  onClose,
  onCancel,
  onCourseLocationCreated,
}) => {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const closeAndReload = () => {
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };
  const submitForm = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formPairs = new FormData(e.target);
    const outData = Object.fromEntries(formPairs.entries());

    (async () => {
      const ret = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/courselocations/add`,
        outData,
      );
      if (ret.isSuccessful) {
        setSubmitting(false);
        enqueueSnackbar(Translate.get('CourseLocationAdded'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        
        if (onCourseLocationCreated) {
          onCourseLocationCreated(ret.actorAddress)
        }

        if (onClose) {
          onClose();
        } else {
          closeAndReload();
        }

      } else {
        setSubmitting(false);

      }

    })();
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }

    if (onClose) {
      onClose();
    } else {
      closeAndReload();
    }
  }

  return (<>
    <form onSubmit={submitForm}>
      <DialogContent dividers={true} className="stdDialogContent">
        <div className='stdForm'>
          <div>
            <MeetingRoomIcon />
            <TextField
              required
              label={Translate.get('LocationName')}
              defaultValue={''}
              name="actorAddressStreet1"
              fullWidth
            ></TextField>
          </div>
          <div>
            <PlaceIcon />
            <TextField
              label={Translate.get('Address2')}
              defaultValue={''}
              name="actorAddressStreet2"
              fullWidth
            ></TextField>
          </div>
          <div>
            <PlaceIcon />
            <TextField
              label={Translate.get('ZipCode')}
              defaultValue={''}
              name="actorAddressZipCode"
              fullWidth
            ></TextField>
          </div>
          <div>
            <PlaceIcon />
            <TextField
              label={Translate.get('City')}
              defaultValue={''}
              name="actorAddressCity"
              fullWidth
            ></TextField>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()}>
          {Translate.get('Close')}
        </Button>
        <LoadingButton
          className="stdLightLoader"
          loading={submitting}
          type="submit">
          {Translate.get('Save')}
        </LoadingButton>
      </DialogActions>
    </form>

  </>)
};
export default AddClassRoom;