import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
// import Snackbar from '@mui/material/Snackbar';
import { LoadingButton } from '@mui/lab';
import { useApi } from '../../../utils/Api';
import Translate from '../../../utils/Translate';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const api = useApi();

  const RegisterSchema = Yup.object().shape({
    /* firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'), 
    email: Yup.string().email('Email must be a valid email address').required('Email is required'), */
    password: Yup.string().min(8, Translate.get('Min8')).max(30, Translate.get('Max30')).required(Translate.get('PasswordRequired')), 
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], Translate.get('PasswordsMustMatch'))
  });

  const formik = useFormik({
    initialValues: {
      /* firstName: '',
      lastName: '', 
      email: '', */
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const req = {
        NewPassword: formik.values.password
      };

      const cpToken = `Bearer ${sessionStorage.cpToken}`;
      api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/password/change/token/`,
        req,
        'POST',
        cpToken
      ).then((data) => {
        if (data === true) {
          navigate('/login', { replace: true });
        } else {
          window.location.reload(false);
        }
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {/*  <Snackbar open={true} autoHideDuration={3000} message="Updated" /> */}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack> */}

          <TextField
            inputProps={{ maxLength: 30}}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={Translate.get('Password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            inputProps={{ maxLength: 30}}
            fullWidth
            autoComplete="current-password"
            type={showPasswordConfirmation ? 'text' : 'password'}
            label={Translate.get('ConfirmPassword')}
            {...getFieldProps('passwordConfirmation')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPasswordConfirmation((prev) => !prev)}
                  >
                    <Icon icon={showPasswordConfirmation ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {Translate.get('SetPassword')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
