import { Paper, TextField, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useMemo } from "react";
import {
  IconAndLabelColumnHeader,
  getGridColDefForArrayValues,
} from "../../../utils/dataGrid";
import PersonIcon from "@mui/icons-material/Person";
//import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import NumbersIcon from "@mui/icons-material/Numbers";
//import LabelIcon from "@mui/icons-material/Label";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PlaceIcon from "@mui/icons-material/Place";
import LinkIcon from "@mui/icons-material/Link";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
//import ActorAvatar from "../ActorAvatar";
import { TableAvatar } from "../../../utils/dataGrid";
import InstructorCard from "../InstructorCard";
import DotMenu from "../../DotMenu";
import SpeedMenu from "../../SpeedMenu";
import { useSnackbar } from "notistack";
import PersonCrud from "../../actors/crud/PersonCrud";
import { CustomNoRowsOverlay } from "../../../utils/StyledGridOverlay";
import { useNavigate } from "react-router-dom";
import { accessKeys, hasAccess } from "../../../utils/userAccess";
import { dataGridLang } from "../../MemToolBar";
import InstructorFilterGroupsDialog from "../InstructorFilterGroupsDialog";
import YesOrNoDialog from "../../YesOrNoDialog";
import UserCrud from "./UserCrud";

export default function UsersSettings(props) {
  const localizedTextsMap = dataGridLang();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userCrudProps, setUserCrudProps] = useState(null);

  const api = useApi();

  const speedMenuActions = [];
  speedMenuActions.push({
    icon: <AddIcon />,
    text: Translate.get("AddReadonlyUser"),
    onClick: () => setUserCrudProps({ user: {} }),
  });

  const loadUsers = useCallback(async () => {
    setIsLoading(true);
    let response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/readonly/self`,
      false,
      "GET"
    );
    if (response.isSuccessful) {
      setUsers(response.users);
    }
    setIsLoading(false);
  }, [api]);

  async function deleteUser() {
    setUsers((currentUsers) =>
      currentUsers.filter((t) => t.ePortUserId !== userToDelete)
    );
    await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/readonly?ePortUserId=${userToDelete}`,
      true,
      "DELETE"
    );
    setUserToDelete(null);
    await loadUsers();
  }

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const getDotMenuActions = useCallback((user) => {
    const menudata = [];
    menudata.push({
      icon: <EditIcon />,
      text: Translate.get("EditUser"),
      onClick: () => setUserCrudProps({ user }),
    });
    menudata.push({
      icon: <DeleteIcon />,
      text: Translate.get("DeleteUser"),
      onClick: () => setUserToDelete(user.ePortUserId),
    });
    return menudata;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "name",
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={PersonIcon}
            label={Translate.get("Name")}
          />
        ),
        renderCell: (params) => (
          <TableAvatar
            src={params.row.profilePictureUrl + ""}
            //variant="rounded"
            title={`${params.row.firstName ?? ""} ${params.row.lastName ?? ""}`}
            subtitle={params.row.ePortAccountId}
          />
        ),
      },
      {
        field: "customRole",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        sortable: false,
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={AssignmentIndIcon}
            label={Translate.get("Role")}
          />
        ),
        renderCell: (params) =>
          params.row.isReadOnly && Translate.get("Readonly"),
      },
      {
        field: "isLocked",
        flex: 0.1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: "",
        sortable: false,
        align: "right",
        renderCell: (params) => params.row.isLocked && <LockIcon />,
      },
      {
        field: "dotMenu",
        flex: 0.1,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu content={getDotMenuActions(params.row)} horizontal />
        ),
      },
    ],
    [getDotMenuActions]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          {userCrudProps && (
            <UserCrud
              onClose={() => setUserCrudProps(null)}
              onSaved={loadUsers}
              {...userCrudProps}
            />
          )}
          <SpeedMenu content={speedMenuActions} />
          <Paper>
            <DataGrid
              pagination
              pageSizeOptions={[10, 20, 30, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              autoHeight={true}
              localeText={localizedTextsMap}
              // onRowClick={(params) => navToUser(params.id)}
              initialState={{
                sorting: { sortModel: [{ field: "name", sort: "asc" }] },
              }}
              //rowHeight={80}
              density="comfortable"
              //rowsPerPage={-1}
              //hideFooter={true}
              columns={columns}
              rows={users}
              getRowId={(row) => row.ePortUserId}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
              }}
            />
          </Paper>
          <YesOrNoDialog
            open={!!userToDelete}
            text={Translate.get("SureDeleteUserQuestion")}
            onYes={deleteUser}
            onNo={() => setUserToDelete(null)}
          />
        </>
      )}
    </>
  );
}
