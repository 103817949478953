import { Box, Typography, Stack, Avatar } from "@mui/material";
import ConditionalWrapper from "./ConditionalWrapper";
import { stringToColor } from "../utils/formatString";

export default function ActorAvatar({
  name,
  email,
  extraFields,
  profilePictureUrl,
  vertical,
  link,
  align = "center",
  disabled,
  hideImage,
}) {
  // TODO det finns två till ställen so manvänder className="avatarInfo",
  // men sidan krashade så jag kunde inte komma åt dom. Se över om dom
  // också ska använda denna komponenten istället för egen kod

  let avatarSx = {
    bgcolor: stringToColor(name),
    ...(vertical ? { margin: "auto" } : {}),
  };

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={(children) => (
        <a className="RemoveHyperLinkDecorations" href={link}>
          {children}
        </a>
      )}
    >
      <Stack
        sx={disabled ? { opacity: 0.3 } : {}}
        direction={vertical ? "column" : "row"}
        spacing={2}
        alignItems={align}
      >
        {!hideImage && (
          <Avatar alt={name} src={profilePictureUrl} sx={avatarSx}>
            {/* visa intial om det inte finns någon url */}
            {(profilePictureUrl && null) ||
              (name?.length > 0 && name[0]) ||
              null}
          </Avatar>
        )}
        <Box
          className="avatarInfo"
          sx={vertical ? { margin: "auto", textAlign: "center" } : {}}
        >
          <Box>{name}</Box>
          {email && (
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {email}
              </Typography>
            </Box>
          )}
        {/* Borttagen vertical regel mao: vertical && extraFields && */}
          {extraFields &&
            extraFields.map((field, i) => (
              <Box key={i}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {field}
                </Typography>
              </Box>
            ))}
        </Box>
      </Stack>
    </ConditionalWrapper>
  );
}
