import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import YesOrNoDialog from "../YesOrNoDialog";
import AddressMenuItem from "./AddressMenuItem";
import { useSnackbar } from "notistack";

function getPropertyTranslation(propertyName) {
  switch (propertyName) {
    case "ActorRefId":
      return Translate.get("YourReference");
    case "ReceiverActorId":
      return Translate.get("ReceiverCompany");
    case "ReceiverActorRefId":
      return Translate.get("ReceiverRef");
    case "InvoiceActorRefId":
      return Translate.get("InvoiceRef");
    case "SupplierId":
      return Translate.get("ActorType_Supplier");
    case "SupplierRefId":
      return `${Translate.get("ActorType_Supplier")} ${Translate.get(
        "Reference"
      )}`;
    case "DelAdrId":
      return Translate.getActorAddressType(4);
    case "InvAddressId":
      return Translate.getActorAddressType(1);
    case "DeliveryAgreement":
      return Translate.get("DeliveryTerms");
    case "PaymentAgreement":
      return Translate.get("PaymentTerms");
    case "CustomerOrderNo":
      return Translate.get("DefaultCustomerOrderNo");
    case "Currency":
      return Translate.get("CurrencyLabel");
    case "ExtraNotification":
      return Translate.get("ExtraNotificationLabel");
    case "AlterOrderNo":
    case "InvoiceActorId":
    case "ModeOfTransport":
    case "GoodsMarking":
    case "DeliveryNoteNo":
    default:
      return Translate.get(propertyName);
  }
}

export default function MergeOrdersDialog({ orders, onClose, onMerged }) {
  const [propertyOptions, setPropertyOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showMergeQuestion, setShowMergeQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  useEffect(() => {
    async function loadMergeOptions() {
      setIsLoading(true);
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/merge-options`,
        orders.map((o) => o.orderId),
        "POST"
      );
      if (response.isSuccessful) {
        const propertyOptions = Object.entries(response.propertyOptions).map(
          ([property, options]) => ({ property, options })
        );

        // If there's only one available option to select then we select it automatically
        const propertiesWithOneOption = propertyOptions.filter(
          (po) => po.options.length === 1
        );
        const autoSelectedSingleOptions = propertiesWithOneOption.map((po) => [
          po.property,
          po.options[0].orderId,
        ]);
        setSelectedOptions(Object.fromEntries(autoSelectedSingleOptions));

        // Here we exclude the properties which were already auto selected above
        const propertiesWithMultipleOptions = propertyOptions.filter(
          (po) => po.options.length > 1
        );
        setPropertyOptions(propertiesWithMultipleOptions);
      }
      setIsLoading(false);
    }
    loadMergeOptions();
  }, [api, orders]);

  function handlePropertyOptionSelected(propertyName, newOrderId) {
    setSelectedOptions((prev) => ({ ...prev, [propertyName]: newOrderId }));
  }

  async function handleMergeOrders() {
    const selectedOptionsWithoutEmpty = Object.fromEntries(
      Object.entries(selectedOptions).filter(([property, orderId]) => !!orderId)
    );
    const request = {
      orderIds: orders.map((o) => o.orderId),
      selectedPropertyOrderIds: selectedOptionsWithoutEmpty,
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/merge`,
      request,
      "POST"
    );
    if (response.isSuccessful) {
      const successMessage = enqueueSnackbar(
        Translate.get("MergeOrdersDone").replace(
          "{newOrderNo}",
          response.newOrderNo
        ),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
      onMerged();
    }
  }

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <Dialog
          open
          maxWidth={false}
          className="mediumCourseDialog"
          scroll="paper"
          onClose={onClose}
        >
          <DialogTitle>{Translate.get("MergeOrders")}</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={4}>
              <Stack direction="column" spacing={2}>
                <Box>{Translate.get("MergeOrdersInfo")}</Box>
                <Box>{`${Translate.get("SelectedOrders")}: ${orders
                  .map((o) => o.orderNo)
                  .join(", ")}`}</Box>
              </Stack>
              {propertyOptions && (
                <Stack direction="column" spacing={2}>
                  {propertyOptions.map((po) => (
                    <FormControl sx={{ minWidth: "200px" }}>
                      <InputLabel id={"property-select-" + po.property}>
                        {getPropertyTranslation(po.property)}
                      </InputLabel>
                      <Select
                        labelId={"property-select-" + po.property}
                        id={"property-select-" + po.property}
                        value={selectedOptions[po.property] ?? ""}
                        label={getPropertyTranslation(po.property)}
                        onChange={(event) =>
                          handlePropertyOptionSelected(
                            po.property,
                            event.target.value
                          )
                        }
                      >
                        {po.options.map((option) => (
                          <MenuItem key={option.orderId} value={option.orderId}>
                            {option.addressType ? (
                              <AddressMenuItem {...option} maxWidth="552px" />
                            ) : option.label ? (
                              option.label
                            ) : (
                              <Typography sx={{ color: "text.secondary" }}>
                                {Translate.get("EmptyNoValue")}
                              </Typography>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))}{" "}
                </Stack>
              )}
              {propertyOptions && propertyOptions.length === 0 && (
                <Stack direction="row" justifyContent="center">
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      // marginTop: "10px",
                      marginBottom: "40px",
                    }}
                  >
                    {Translate.get("MergeOrdersAllFieldsAuto")}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <Button
              variant="contained"
              onClick={() => setShowMergeQuestion(true)}
            >
              {Translate.get("MergeOrders")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <YesOrNoDialog
        open={showMergeQuestion}
        text={Translate.get("SureMergeOrders").replace(
          "{orderNumbers}",
          orders.map((o) => o.orderNo).join(", ")
        )}
        onNo={() => setShowMergeQuestion(false)}
        onYes={handleMergeOrders}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("MergeOrders")}
      />
    </>
  );
}
