import { Button, Grid, Paper } from "@mui/material";
import PrintTemplateEditDialog from "../actor-license-print/PrintTemplateEditDialog";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridLang } from "../MemToolBar";
import { useApi } from "../../utils/Api";
import EditIcon from "@mui/icons-material/Edit";
import BadgeIcon from "@mui/icons-material/Badge";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback } from "react";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import Translate from "../../utils/Translate";
import DotMenu from "../DotMenu";
import { useMemo } from "react";
import Preloader from "../Preloader";
import AddOrEditLicenseGroupDialog from "./AddOrEditLicenseGroupDialog";
import SpeedMenu from "../SpeedMenu";
import { useNavigate } from "react-router-dom";
import YesOrNoDialog from "../YesOrNoDialog";

const getSpeedMenu = () => {
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("AddLicenseGroup"),
      dialogHeader: Translate.get("AddLicenseGroup"),
      component: <AddOrEditLicenseGroupDialog />,
    },
  ];
  return menudata;
};

export default function LicenseGroupSettings(props) {
  const [licenseGroups, setLicenseGroups] = useState([]);
  const [licenseGroupAndTypeToEdit, setLicenseGroupAndTypeToEdit] =
    useState(null);
  const [licenseGroupIdToDelete, setLicenseGroupIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const localizedTextsMap = dataGridLang();
  const api = useApi();
  // const navigate = useNavigate();

  const loadLicenseGroups = useCallback(() => {
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}licenses/groups`, false, "GET")
      .then((response) => {
        if (response.isSuccessful) {
          setLicenseGroups(response.licenseGroups);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    loadLicenseGroups();
  }, [api, loadLicenseGroups]);

  async function deleteLicenseGroup(licenseGroupId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/groups/${licenseGroupId}`,
      true,
      "DELETE"
    );
    return response.isSuccessful;
  }

  async function handleDeleteLicenseGroup(licenseGroupId) {
    if (await deleteLicenseGroup(licenseGroupId)) {
      loadLicenseGroups();
      setLicenseGroupIdToDelete(null);
    }
  }

  const getDotMenuActions = useCallback(
    (licenseGroupId, onEditLicenseGroup, onDeleteLicenseGroup) => {
      const menudata = [];
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditLicenseGroupCard"),
        onClick: () => onEditLicenseGroup(licenseGroupId, "card"),
      });
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditLicenseGroupDiploma"),
        onClick: () => onEditLicenseGroup(licenseGroupId, "diploma"),
      });
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditLicenseGroupA4"),
        onClick: () => onEditLicenseGroup(licenseGroupId, "a4"),
      });
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditLicenseGroup"),
        dialogHeader: Translate.get("EditLicenseGroup"),
        component: (
          <AddOrEditLicenseGroupDialog licenseGroupId={licenseGroupId} />
        ),
      });
      menudata.push({
        icon: <DeleteIcon />,
        text: Translate.get("DeleteLicenseGroup"),
        onClick: () => onDeleteLicenseGroup(licenseGroupId),
      });
      return menudata;
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "name",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("License"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={BadgeIcon}
            label={Translate.get("License")}
          />
        ),
        sortComparator: (v1, v2) => v1.localeCompare(v2, 'sv'),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu
            content={getDotMenuActions(
              params.row.licenseGroupId,
              (licenseGroupId, printType) =>
                setLicenseGroupAndTypeToEdit({ licenseGroupId, printType }),
              (licenseGroupId) => setLicenseGroupIdToDelete(licenseGroupId)
            )}
            horizontal
          />
        ),
      },
    ],
    [getDotMenuActions]
  );

  return (
    <>
      {!!licenseGroupAndTypeToEdit && (
        <PrintTemplateEditDialog
          {...licenseGroupAndTypeToEdit}
          onClose={() => setLicenseGroupAndTypeToEdit(null)}
        />
      )}
      {isLoading && <Preloader />}
      <Paper>
        <DataGrid
          pagination
          pageSizeOptions={[10, 20, 30, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          autoHeight={true}
          localeText={localizedTextsMap}
          initialState={{
            sorting: { sortModel: [{ field: "name", sort: "asc" }] },
          }}
          density="comfortable"
          columns={columns}
          rows={licenseGroups}
          getRowId={(row) => row.licenseGroupId}
        />
      </Paper>
      <SpeedMenu content={getSpeedMenu()} />
      <YesOrNoDialog
        open={!!licenseGroupIdToDelete}
        yesText={Translate.get("Delete")}
        noText={Translate.get("Cancel")}
        title={Translate.get("DeleteLicenseGroup")}
        text={Translate.get("SureDeleteLicenseGroup")}
        onYes={async () =>
          await handleDeleteLicenseGroup(licenseGroupIdToDelete)
        }
        onNo={() => setLicenseGroupIdToDelete(null)}
      />
    </>
  );
}
