// routes
import Router from './routes';

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

import { useMemo } from 'react';

import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

//Mui-x pro licensuppgifter
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('90ba80d80f637bf7490a81b0b4e8146dTz04ODUyMCxFPTE3NDUwODAyMTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

// ----------------------------------------------------------------------

export default function App() {
  const locale = useMemo(() => {
    switch (localStorage.lang) {
      case '9':
        return enLocale;
      case '12':
        return frLocale;
      case '29':
      default:
        return svLocale;
    }
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
