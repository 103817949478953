import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Tooltip,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../DotMenu";
import { DatePicker } from "@mui/x-date-pickers";
import Translate from "../../utils/Translate";
import ActorAvatar from "../ActorAvatar";
import { useMemo, useState } from "react";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ButtonWithSpinner from "../ButtonWithSpinner";

export default function ID06CompetencesDialogBig({
  onClose,
  bookings,
  ID06Skills,
  externalSkills,
  handleCheckID06Skill,
  handleCheckSkillAllChange,
  canApproveAll,
  handleApproveAllSkills,
  handleApproveAllSkillsForStudent,
  handleOpenAllTrainingsDialog,
  showAllTrainingsDialog,
  handleSave,
  handleReloadData,
  handleWrittenConsentChange,
  hasWrittenConsent,
  hasErrors,
}) {
  const [personCrudActorId, setPersonCrudActorId] = useState(null);
  const getDotMenuActions = (student) => {
    const hasAllSkills = externalSkills.every((es) =>
      ID06Skills.some(
        (x) =>
          x.code === es.code && x.actorId === student.actorId && !x.isDeleted
      )
    );

    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        onClick: () => setPersonCrudActorId(student.actorId),
      },
      {
        icon: <DoneAllIcon />,
        text: Translate.get("ApproveAllSkills"),
        disabled: hasAllSkills,
        onClick: () =>
          handleApproveAllSkillsForStudent(
            student.actorId,
            student.courseParticipantId
          ),
      },
    ];
    return menudata;
  };

  function hasSkill(actorId, code) {
    return ID06Skills.some(
      (x) => x.actorId === actorId && x.code === code && !x.isDeleted
    );
  }

  function allStudentsHaveSkill(code) {
    const actorIds = bookings
      .flatMap((b) => b.courseParticipants)
      .map((p) => p.actorId);
    const selectedSkills = ID06Skills.filter(
      (x) => x.code === code && !x.isDeleted
    );
    return actorIds.every((actorId) =>
      selectedSkills.some((x) => x.actorId === actorId)
    );
  }

  function someStudentsHaveSkill(code) {
    return ID06Skills.some((x) => x.code === code && !x.isDeleted);
  }

  return (
    <>
      {personCrudActorId && (
        <PersonCrudDialog
          open={true}
          personCrudProps={{
            actorId: personCrudActorId,
            isEditOnly: true,
            isMatchingActorsDisabled: true,
            onClose: () => setPersonCrudActorId(null),
            onActorSaved: () => handleReloadData(),
          }}
          zIndexOverride={6000}
        />
      )}
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth="xl"
        sx={
          /* Doing this so that the spinner in ID06TrainingsDialog is over this dialog*/
          showAllTrainingsDialog ? { zIndex: 0 } : {}
        }
      >
        <Box
          sx={{
            position: "absolute",
            right: 8,
            top: 14,
          }}
        >
          <Button onClick={handleOpenAllTrainingsDialog}>
            {Translate.get("EditCourseSkills")}
          </Button>
          <Button
            onClick={handleApproveAllSkills}
            disabled={!canApproveAll || hasErrors()}
          >
            {Translate.get("ApproveAll")}
          </Button>
        </Box>
        <DialogTitle>
          <Stack direction="row" spacing={1}>
            <Box>{Translate.get("ID06Name")}</Box>
            <Tooltip title={Translate.get("ID06CompetenceDialogInfo")}>
              <InfoIcon color="primary" />
            </Tooltip>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }} dividers>
          <TableContainer sx={{ overflowX: "initial" }}>
            <Table
              className="gradesTable id06"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow className="gradesTableHeader">
                  <TableCell>{/* Empty cell on purpose */}</TableCell>
                  <TableCell align="left">
                    <FormControl>
                      <FormGroup
                        sx={{ flexWrap: "nowrap" }}
                        aria-label="position"
                        row
                      >
                        {externalSkills.map((externalSkill) => (
                          <FormControlLabel
                            value="top"
                            className="externalSkillCheckbox"
                            control={
                              <Checkbox
                                disabled={hasErrors()}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                checked={allStudentsHaveSkill(
                                  externalSkill.code
                                )}
                                indeterminate={
                                  someStudentsHaveSkill(externalSkill.code) &&
                                  !allStudentsHaveSkill(externalSkill.code)
                                }
                                onChange={(event) =>
                                  handleCheckSkillAllChange(
                                    event,
                                    externalSkill.code
                                  )
                                }
                              />
                            }
                            label={
                              <Tooltip title={externalSkill.name}>
                                <Typography
                                  variant="body2"
                                  className="externalSkillCheckboxLabel"
                                  sx={{
                                    color: "text.secondary",
                                  }}
                                >
                                  {externalSkill.name}
                                </Typography>
                              </Tooltip>
                            }
                            labelPlacement="top"
                            key={externalSkill.code}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableHead>
              {bookings &&
                bookings.length > 0 &&
                bookings.map((booking) => (
                  <TableBody key={booking.courseBookingId}>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{ padding: "14px" }}
                        component="th"
                        scope="row"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            margin: "1em 10px 2px",
                            textTransform: "uppercase",
                          }}
                        >{`${booking.companyName} (${booking.courseParticipants.length})`}</Typography>
                      </TableCell>
                    </TableRow>
                    {booking.courseParticipants.map((student) => (
                      <TableRow
                        key={student.actorId}
                        className="gradesTableRow"
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="userNameEmail"
                          align="left"
                        >
                          <Stack direction="column" spacing={1}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              gap="10px"
                            >
                              <ActorAvatar
                                name={student.name}
                                email={student.personalNo}
                                profilePictureUrl={student.profilePictureUrl}
                              />
                              <DotMenu
                                content={getDotMenuActions(student)}
                                horizontal
                              />
                            </Box>
                            {student.errors &&
                              Object.values(student.errors)
                                .filter((val) => !!val)
                                .map((val, i) => (
                                  <Stack
                                    key={i}
                                    direction="row"
                                    paddingLeft="8px"
                                    className="smallRedWarning"
                                  >
                                    <WarningIcon />
                                    <Box>
                                      {val.startsWith("ID06:")
                                        ? val
                                        : Translate.get(val)}
                                    </Box>
                                  </Stack>
                                ))}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <FormControl>
                            <FormGroup
                              sx={{ flexWrap: "nowrap" }}
                              aria-label="position"
                              row
                            >
                              {externalSkills.map((externalSkill) => (
                                <FormControlLabel
                                  value="top"
                                  className="externalSkillCheckbox"
                                  control={
                                    <Checkbox
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      checked={hasSkill(
                                        student.actorId,
                                        externalSkill.code
                                      )}
                                      onChange={(event) =>
                                        handleCheckID06Skill(
                                          event,
                                          student.actorId,
                                          student.courseParticipantId,
                                          externalSkill.code
                                        )
                                      }
                                      disabled={Object.values(
                                        student.errors
                                      ).some((val) => !!val)}
                                    />
                                  }
                                  label={
                                    /* Just adding label to make the checkboxes line up with the ones at the top */
                                    <Typography
                                      variant="body2"
                                      className="externalSkillCheckboxLabel"
                                      sx={{
                                        visibility: "hidden",
                                        height: 0,
                                      }}
                                    >
                                      {externalSkill.name}
                                    </Typography>
                                  }
                                  labelPlacement="top"
                                  key={externalSkill.code}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            marginLeft="24px"
            marginRight="24px"
            marginTop="32px"
            marginBottom="32px"
            maxWidth="400px"
          >
            <Checkbox
              disabled={ID06Skills.filter((x) => !x.isDeleted).length === 0}
              checked={hasWrittenConsent()}
              onChange={handleWrittenConsentChange}
            />
            <Box>{Translate.get("ID06WrittenConsentStatement")}</Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <ButtonWithSpinner
            variant="contained"
            onClick={handleSave}
            disabled={
              !hasWrittenConsent() ||
              ID06Skills.filter((x) => !x.isDeleted).length === 0 ||
              hasErrors()
            }
          >
            {Translate.get("Save")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}
