import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import Translate from "../../utils/Translate";
import DigitalLicenseListItem from "../../components/digital-licenses/DigitalLicenseListItem";

export default function MyDigitalLicenses({ isPublishGroup }) {
  const [licenses, setLicenses] = useState([]);
  const api = useApi();
  const navigate = useNavigate();
  const { id: digitalLicensePublishGroupId } = useParams();

  useEffect(() => {
    async function loadLicenses() {
      const url = isPublishGroup
        ? `${process.env.REACT_APP_MAIN_URL}digital/licenses/groups/${digitalLicensePublishGroupId}`
        : `${process.env.REACT_APP_MAIN_URL}digital/licenses`;
      const response = await api.fetch(url, false, "GET");
      if (response.isSuccessful) {
        setLicenses(response.licenses);
      }
    }

    loadLicenses();
  }, [api, isPublishGroup, digitalLicensePublishGroupId]);

  return (
    <div className="dlcPageWrapper">
      <div className="dlcHeading1">
        {isPublishGroup
          ? Translate.get("TODOContactDigitalLicenses")
          : Translate.get("MyDigitalLicenses")}
      </div>
      <div className="dlcPage">
        {licenses &&
          licenses.map((license) => (
            <DigitalLicenseListItem
              key={license.digitalLicenseId}
              license={license}
              showDigitalPermitButton={false}
              isRestrictedView={isPublishGroup}
            />
          ))}
        <div></div>
      </div>
    </div>
  );
}
