import Api from "./Api";

export const integrations = {
  ID06: "ID06",
};

export async function loadActiveIntegrations() {
  let response;
  try {
    response = await Api.fetch(
      `${process.env.REACT_APP_MAIN_URL}settings/integrations/active`,
      false,
      "GET"
    );
  } catch (e) {
    response = { isSuccessful: false };
  }

  const activeIntegrations = response.isSuccessful
    ? response.integrations.map((x) => x.name)
    : [];
  sessionStorage.setItem("integrations", JSON.stringify(activeIntegrations));
}

let parsedIntegrations;
function getActiveIntegrations() {
  if (!parsedIntegrations && sessionStorage.integrations) {
    parsedIntegrations = new Set(JSON.parse(sessionStorage.integrations));
  }
  return parsedIntegrations ?? new Set();
}

export function hasIntegration(integration) {
  const activeIntegrations = getActiveIntegrations();
  return activeIntegrations.has(integration);
}
