import {
  Box,
  Stack,
} from "@mui/material";
import { useState, useCallback } from "react";
import Translate from "../../utils/Translate";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { actorSearch, actorPersonTypes } from "../../utils/actorSearch";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import ActorAvatarBox from "../ActorAvatarBox";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useApi } from "../../utils/Api";
import ErrorMessage from "../ErrorMessage";

function getDotMenuActions({ onRemoveResponsible, onEditResponsible }) {
  return [
    {
      icon: <EditIcon />,
      text: Translate.get("EditPersonalInfo"),
      onClick: onEditResponsible,
    },
    {
      icon: <ClearIcon />,
      text: Translate.get("Delete"),
      onClick: onRemoveResponsible,
    },
  ];
}

export default function SelectActorPermitResponsibleActor({
  responsibleActor,
  onResponsibleActorChange,
  errorMessage,
}) {
  const api = useApi();
  const [showResponsibleDialog, setShowResponsibleDialog] = useState(false);
  const [tmpResponsibleActor, setTmpResponsibleActor] = useState(responsibleActor);
  const [newActorParentActorId, setNewActorParentActorId] = useState(null);

  function handleResponsibleActorSaved(actor) {
    actor = {
      ...actor,
      email: actor.actorEmail,
      phoneNumber: actor.mobilePhone?.actorPhoneNumber,
      companies: actor.childActorGroups ? [actor.childActorGroups.map(x => ({actorId: x.parentActorId}))] : null
    };
    setTmpResponsibleActor(actor);
    handleResponsibleActorSelected(actor);
  }

  async function handleResponsibleActorSelected(actor) {
    setTmpResponsibleActor(actor);
    if (actor && actor.actorId > 0) {
      onResponsibleActorChange(actor);
    } else if (actor) {
      if(!newActorParentActorId)
      {
          const parentActor = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/parent-actor/for-data-id`,
          false,
          "GET"
        );
        setNewActorParentActorId(parentActor?.actorId);
      }
      setShowResponsibleDialog(true);
    }
  }

  const actorNameSearch = useCallback(async (searchText) => {
    var foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName", "OrgNo", "ExternalActorNo"],
      50
    );
    if (foundActors) {
      return foundActors.map((actor) => ({
        actorId: actor.actorId,
        actorName: actor.actorName,
        orgNo: actor.orgNo,
        externalActorNo: actor.externalActorNo,
        email: actor.email,
        phoneNumber: actor.phoneNumbers.find((p) => p.type === 2)?.phoneNumber,
        companies: actor.parentActors
          ?.filter(
            (x, index, allParentActors) =>
              x.parentActor.actorId !== actor.actorId &&
              x.parentActor.actorId > 0 &&
              allParentActors.findIndex(
                (y) => y.parentActor.actorId === x.parentActor.actorId
              ) === index
          )
          .map((x) => ({actorId: x.parentActor.actorId, actorName: x.parentActor.actorName})),
      }));
    }
  }, []);

  return (
    <>
      {showResponsibleDialog && (
        <PersonCrudDialog
          open={showResponsibleDialog}
          personCrudProps={{
            name: tmpResponsibleActor?.actorName ?? "",
            actorId: tmpResponsibleActor?.actorId,
            parentActorId: tmpResponsibleActor?.companies?.[0]?.actorId ?? newActorParentActorId,
            onClose: () => setShowResponsibleDialog(false),
            onActorSaved: (actor) => handleResponsibleActorSaved(actor),
          }}
        />
      )}
      <Stack direction="column" spacing={2}>
        <div className="stdFlexBetween">
          <div className="smallHeader">{Translate.get('Responsible')}</div>
          <Tooltip title={Translate.get('PermitActorSearchTip')}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
        {!responsibleActor && (
          <AutocompleteSearchField
            placeholder={Translate.get("Responsible")}
            label={Translate.get("Responsible")}
            value=""
            onValueChange={handleResponsibleActorSelected}
            getOptionLabel={(option) => option?.actorName ?? ""}
            renderOption={(option) => (
              <ActorAutocompleteOption
                actorName={option?.actorName}
                orgNo={option?.orgNo}
                email={option?.email}
                companies={option?.companies?.map(x => x.actorName)}
                externalActorNo={option?.externalActorNo}
              />
            )}
            keyPropName="actorId"
            requireSelection={true}
            search={actorNameSearch}
            createNewValue={(text) => ({
              actorName: text,
            })}
            textFieldProps={{
              required: true,
            }}
          />
        )}
        {responsibleActor && (
          <Box className="textbox">
            <ActorAvatarBox
              name={responsibleActor.actorName}
              profilePictureUrl={responsibleActor.actorImage}
              rows={[
                responsibleActor.orgNo ?? "-",
                responsibleActor.email ?? "-",
                responsibleActor.phoneNumber ?? "-",
                errorMessage ? <ErrorMessage error={errorMessage} onClick={() => setShowResponsibleDialog(true)} /> : null,
              ]}
              dotMenuActions={getDotMenuActions({
                onRemoveResponsible: () => onResponsibleActorChange(null),
                onEditResponsible: () => setShowResponsibleDialog(true),
              })}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}
