import Translate from '../../utils/Translate';
import Button from '@mui/material/Button';
import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { useApi } from "../../utils/Api";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Preloader from "../Preloader";
import { useMediaQuery } from '@mui/material';


export default function CourseCalendarNoteDialog({ loadData, defaultDate, teachers }) {

    const [isLoading, setIsLoading] = useState(false);
    const api = useApi();

    const [modalFromDate, setModalFromDate] = useState(defaultDate ?? null);
    const [modalToDate, setModalToDate] = useState(defaultDate ?? null);
    const [modalText, setModalText] = useState("");

    const [actorId, setActorId] = useState(null);

    const smallScreen = useMediaQuery('(max-width:700px)');

    function addCalendarNote() {
        setIsLoading(true);
        let req = {
            "FromDate": modalFromDate,
            "ToDate": modalToDate,
            "Text": modalText,
            "TeacherActorId": actorId,
        }

        api.fetch(
            `${process.env.REACT_APP_MAIN_URL}calendars/note`,
            req,
            'POST',
        )
            .then((data) => {
                if (data.isSuccessful) {
                    loadData();
                    window.history.back()
                }
            })
            .finally((f) => {
                setIsLoading(false);
            });
    }

    const handleChange = (event) => {
        setActorId(event.target.value);
      };
    

    return (
        <>
        <div style={ !smallScreen ? { width: "48em"} : {}}>
        {isLoading && <Preloader />}
            <div style={{ padding: "2em"}}>
                <DatePicker
                    label={Translate.get('StartDate')}
                    value={modalFromDate}
                    onChange={(newValue) => {
                        setModalFromDate(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            required
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: Translate.get('DatePlaceholder'),
                            }}
                        />
                    )}
                />
                <DatePicker
                    label={Translate.get('EndDate')}
                    value={modalToDate}
                    onChange={(newValue) => {
                        setModalToDate(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            required
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: Translate.get('DatePlaceholder'),
                            }}
                        />
                    )}
                />

                <TextField
                    sx={{ mt: 2 }}
                    id="outlined-multiline-static"
                    label="Text"
                    multiline
                    rows={4}
                    value={modalText}
                    onChange={(e) => {
                        setModalText(e.target.value);
                    }}
                    fullWidth
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="teacher-label">{Translate.get("Instructor")}</InputLabel>
                    <Select
                        labelId="teacher-label"
                        label={Translate.get("Instructor")}
                        id="demo-simple-select"
                        value={actorId}
                        onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem key={-1} value={null}><b>{Translate.get("None")}</b></MenuItem>
                        {teachers
                            .sort((a, b) => a.actorName.localeCompare(b.actorName))
                            .map((t, index) => {
                                return (
                                    <MenuItem key={index} value={t.actorId}>{t.actorName}</MenuItem>
                                )
                            })}

                    </Select>
                </FormControl>

                <Grid container justifyContent="flex-end">
                    <div style={{ marginTop: "1em" }}>
                        <Button onClick={() => window.history.back()}>
                            {Translate.get("Cancel")}
                        </Button>
                        <Button
                            onClick={addCalendarNote}
                        >
                            {Translate.get("Save")}
                        </Button>
                    </div>
                </Grid>

            </div>
        </div>     
        </>
    )
}