import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import { useSnackbar } from "notistack";
import Box from '@mui/material/Box';


export default function FinalExamUnlockedSelect(props) {
  const [finalExamUnlocked, setFinalExamUnlocked] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [firstRender, setFirstRender] = React.useState(true);
  const api = useApi();

  useEffect(async () => {
    let finalExamUnlocked = await GetFinalExamUnlockedState();
    let b = (finalExamUnlocked === 'True');
    setFinalExamUnlocked(b);
    setFirstRender(false);
  }, []);

  useEffect(async () => {
    if (firstRender) { return; }
    let state = await PostFinalExamUnlockedState(finalExamUnlocked);
  }, [finalExamUnlocked]);

  const handleChange = async (event) => {
    setFinalExamUnlocked(event.target.value);
  };

  async function GetFinalExamUnlockedState() {
    props.setIsLoading(true);
    let response = null;
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/finalExamUnlocked`,
        false,
        'GET'
      );
      props.setIsLoading(false);
    } catch (error) {
      const errorMessage = enqueueSnackbar(
        Translate.get(response.errorMessageTranslationKey || "SomethingFailed"),
        {
          variant: "error",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(errorMessage),
        }
      );
      props.setIsLoading(false);
    }
    return response.settingValue;
  }

  async function PostFinalExamUnlockedState(state) {
    let response = null;
    try {
      let reqObj = { settingValue: state };
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/setFinalExamUnlocked`,
        reqObj,
        'POST',
        false,
        true
      );
      props.setIsLoading(false);
    } catch (error) {
      const errorMessage = enqueueSnackbar(
        Translate.get(response.errorMessageTranslationKey || "SomethingFailed"),
        {
          variant: "error",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(errorMessage),
        }
      );
      props.setIsLoading(false);
    }
    return response.settingValue;
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={finalExamUnlocked}
          onChange={handleChange}
        >
          <MenuItem value={true}>{Translate.get("Open")}</MenuItem>
          <MenuItem value={false}>{Translate.get("LockedState")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
