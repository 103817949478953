import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import Translate from "../../../utils/Translate";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import MoneyIcon from "@mui/icons-material/Money";
import LanguageIcon from "@mui/icons-material/Language";
import NumbersIcon from "@mui/icons-material/Numbers";
import DomainIcon from "@mui/icons-material/Domain";
import GroupIcon from "@mui/icons-material/Group";
import Checkbox from "@mui/material/Checkbox";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import YesOrNoDialog from "../../YesOrNoDialog";

export default function IframeDialog({
  companyActorId,
  hasCourseBookingIframe,
  defaults,
}) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(
    hasCourseBookingIframe ? {} : { ...defaults }
  );
  const [iframeClientUrl, setIframeClientUrl] = useState(null);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = useCallback(() => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  }, [navigate, location]);

  const deleteIframe = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/${companyActorId}/iframe`,
      true,
      "DELETE"
    );
    if (response && response.isSuccessful) {
      closeAndReload();
    }
  }, [api, companyActorId, closeAndReload]);

  const addOrUpdateIframe = useCallback(
    async (data) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/${companyActorId}/iframe`,
        data,
        "POST"
      );
      if (response && response.isSuccessful) {
        closeAndReload();
      }
    },
    [api, companyActorId, closeAndReload]
  );

  useEffect(() => {
    async function loadData() {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/${companyActorId}/iframe`
      );
      setData(data.settings);
      setIframeClientUrl(data.iframeClientUrl);
    }

    if (hasCourseBookingIframe) {
      loadData().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [api, companyActorId, hasCourseBookingIframe]);
  
  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <DialogContent>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={2.5}
        >
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <EmailIcon sx={{ marginLeft: "9px" }} />
            <TextField
              fullWidth
              value={data.courseBookingEmail ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setData((prev) => ({
                  ...prev,
                  courseBookingEmail: event.target.value,
                }))
              }
              label={Translate.get("EmailIframeReceiver")}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <DomainIcon sx={{ marginLeft: "9px" }} />
            <TextField
              fullWidth
              value={data.courseBookingEmailName ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setData((prev) => ({
                  ...prev,
                  courseBookingEmailName: event.target.value,
                }))
              }
              label={Translate.get("Name")}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <GroupIcon sx={{ marginLeft: "9px" }} />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={!!data?.showSlots}
                  onChange={(event) =>
                    setData((prev) => ({
                      ...prev,
                      showSlots: event.target.checked,
                    }))
                  }
                />
              }
              label={Translate.get("IframeAvailableSlots")}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <MoneyIcon sx={{ marginLeft: "9px" }} />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={!!data?.showPrice}
                  onChange={(_) =>
                    setData((prev) => ({
                      ...prev,
                      showPrice: !prev.showPrice,
                    }))
                  }
                />
              }
              label={Translate.get("IframeShowPrice")}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <NumbersIcon sx={{ marginLeft: "9px" }} />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={!!data?.fieldOrgNoIsRequired}
                  onChange={(_) =>
                    setData((prev) => ({
                      ...prev,
                      fieldOrgNoIsRequired: !prev.fieldOrgNoIsRequired,
                    }))
                  }
                />
              }
              label={Translate.get("IframeOrgnumber")}
            />
          </Stack>

          {hasCourseBookingIframe && (
            <Stack direction="row" spacing={1} className="stdFlexLeft">
              <LanguageIcon sx={{ marginLeft: "9px" }} />
              <TextField
                fullWidth
                disabled
                value={iframeClientUrl ?? ""}
                label={Translate.get("IframeClientUrl")}
              />
              <Button
                disabled={!iframeClientUrl}
                onClick={() => navigator.clipboard.writeText(iframeClientUrl)}
              >
                <ContentCopyIcon />
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box marginRight="40px" alignSelf="flex-start">
          <ButtonWithSpinner
            onClick={() => setShowDeleteDialog(true)}
            color="error"
            disabled={!hasCourseBookingIframe}
          >
            {Translate.get("Delete")}
          </ButtonWithSpinner>
        </Box>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          onClick={() => addOrUpdateIframe(data)}
          variant="contained"
        >
          {hasCourseBookingIframe
            ? Translate.get("Save")
            : Translate.get("Add")}
        </ButtonWithSpinner>
      </DialogActions>
      <YesOrNoDialog
        open={showDeleteDialog}
        text={Translate.get("SureDeleteEPortLicenseLine")}
        onYes={deleteIframe}
        onNo={() => setShowDeleteDialog(false)}
      />
    </>
  );
}
