import { Fragment, useState } from 'react';
import {
  useLocation as UseLocation,
  useNavigate as UseNavigate,
} from 'react-router-dom';
// material
import Translate from '../../../utils/Translate';
import Avatar from '@mui/material/Avatar';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: '9',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    value: '12',
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg',
  },
  {
    value: '29',
    label: 'Swedish',
    icon: '/static/icons/ic_flag_se.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguageIcons(props) {
  const navigate = UseNavigate();
  const location = UseLocation();
  let langIndex = 0;
  if (localStorage.lang) {
    langIndex = LANGS.findIndex((x) => x.value === localStorage.lang);
  }

  const [selectedIndex, setSelectedIndex] = useState(langIndex);

  const changeLang = (index, lang) => {
    setSelectedIndex(index);
    const loadTranslations = props.isPreLogin
      ? Translate.loadPreLogin(lang)
      : Translate.loadApp(lang);
    loadTranslations.then((data) => {
      navigate(location.pathname, { replace: true });
    });
  };

  return (
    <div className="langIcons">
      {LANGS.map((option, index) => (
        <Fragment key={index}>
          <Avatar
            className={index === selectedIndex ? 'langSelected' : ''}
            src={option.icon}
            onClick={(event) => changeLang(index, option.value)}
            sx={{ cursor: 'pointer' }}
          />
        </Fragment>
      ))}
    </div>
  );
}
