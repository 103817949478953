import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import MovieIcon from "@mui/icons-material/Movie";
import Translate from "../../utils/Translate";

const knownMimeTypes = {
  "application/pdf": "PDF",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PowerPoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Word",
  "video/mp4": "Video",
};

function mimeTypeToLabel(mimeType) {
  return knownMimeTypes[mimeType] ?? Translate.get("Other");
}

function FileTypeIcon({ mimeType }) {
  switch (mimeType) {
    case "application/pdf":
      return <InsertDriveFileIcon />;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return <PersonalVideoIcon />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <InsertDriveFileIcon />;
    case "video/mp4":
      return <MovieIcon />;
    default:
      return <InsertDriveFileIcon />;
  }
}

function FileTypeLabel({ mimeType }) {
  return <div>{mimeTypeToLabel(mimeType)}</div>;
}

export function fileTypeComparator(contentType1, contentType2) {
  // For some reason localCompare didn't work if I didn't put the return values
  // into strings like this, even though the return values are already strings.
  const a = `${mimeTypeToLabel(contentType1)}`;
  const b = `${mimeTypeToLabel(contentType2)}`;
  return a.localeCompare(b);
}

export default function FileTypeCell(props) {
  return (
    <div className="filetype">
      <FileTypeIcon {...props} />
      <FileTypeLabel {...props} />
    </div>
  );
}
