import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import EPortAvatar from "../EPortAvatar";

export default function CompanyLogotypeCard(props) {
  const [logoUrl, setLogoUrl] = useState(null);
  const api = useApi();

  const loadLogo = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataids/blobs/logo/dataUrl`,
      false,
      "GET"
    );
    !!response && setLogoUrl(response);
  }, [api]);

  const onImageUploaded = async (imageResponse) => {
    if(imageResponse?.imageId) {
      await api.fetch(`${process.env.REACT_APP_IMAGE_SERVICE_URL}UploadLogo`, {imageId: imageResponse.imageId});
      await loadLogo();
    }
  };

  const onDeleteImage = async () => {
    const response = await api.fetch(`${process.env.REACT_APP_IMAGE_SERVICE_URL}DeleteLogo`, {}, "POST")
    if (response?.isSuccessful !== false) {
      setLogoUrl(null);
    }    
  };

  useEffect(() => loadLogo(), [loadLogo]);

  return (
    <Card
      sx={{
        maxWidth: { md: "438px" },
        paddingBottom: "10px",
      }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h4">{Translate.get("Logotype")}</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <Grid item>
                <EPortAvatar avatarWidth={200} avatarHeight={'auto'} variant="rounded" pictureUrl={logoUrl} showBorder={false}
                  displayName={sessionStorage.companyName} onImageUploaded={onImageUploaded} onDeleteImage={onDeleteImage} imageType={"logotype"} />
            </Grid>
            <Grid item textAlign="center">
              <Typography
                variant="body3"
                sx={{
                  color: "text.secondary",
                }}
              >
                {Translate.get("LogoWillBeUsedAs")}.&nbsp;
                {Translate.get("AllowedFileFormats")}
              </Typography>
              <Typography
                variant="body3"
                sx={{
                  color: "text.secondary",
                }}
              >&nbsp;
                {Translate.get("FileSize")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
