import { useMediaQuery } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import CourseSkillsDialog from "./CourseSkillsDialog";
import { useNavigate } from "react-router-dom";
import {
  hasAccess,
  hasSomeAccess,
  accessKeys,
} from "../../../utils/userAccess";
import CourseGradesDialogSmall from "./CourseGradesDialogSmall";
import CourseGradesDialogBig from "./CourseGradesDialogBig";
import { isValidDate } from "../../../utils/dateTimeFormat";
import ActorLicensePrintDialog from "../../actor-license-print/ActorLicensePrintDialog";
import YesOrNoDialog from "../../YesOrNoDialog";
import Translate from "../../../utils/Translate";

// Simple mechanism to avoid sending two requests at the same time.
// We simply block POSTing student grades for 300 ms
let isPostingGrades = false;
function flagIsPostingGrades() {
  if (!isPostingGrades) {
    isPostingGrades = true;
    setTimeout(() => (isPostingGrades = false), 300);
    return true;
  } else {
    return false;
  }
}

export default function CourseGradesDialogBase({ courseId, open, onClose }) {
  const [bookings, setBookings] = useState([]);
  const [studentsByActorId, setStudentsByActorId] = useState({});
  const [skills, setSkills] = useState([]);
  const [skillsByActorId, setSkillsByActorId] = useState({});
  const [licensesByActorId, setLicensesByActorId] = useState({});
  const [isAnyStudentUnlicensed, setIsAnyStudentUnlicensed] = useState(false);
  const [allLicensesSameDate, setAllLicenseSameDate] = useState(false);
  const [isAnyLicenseLocked, setIsAnyLicenseLocked] = useState(false);
  const [canApproveAll, setCanApproveAll] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showCourseSkillsDialog, setShowCourseSkillsDialog] = useState(
    window.location.hash === "#grades#available-skills"
  );
  const [showLicensePrint, setShowLicensePrint] = useState(false);
  const [actorIdToDeleteLicense, setActorIdToDeleteLicense] = useState(null);

  const isSmallScreen = useMediaQuery("(max-width:910px)");

  const api = useApi();
  const navigate = useNavigate();

  const unlicensedStudentActorIds = useMemo(
    () =>
      Object.entries(licensesByActorId)
        .filter(([_, license]) => !license.date)
        .map(([actorId, _]) => actorId),
    [licensesByActorId]
  );

  useEffect(() => {
    if (open && !showCourseSkillsDialog) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/grades/info/`,
          false,
          "GET"
        )
        .then((data) => {
          if (data) {
            const sortedSkills = data.courseSkills;
            if (sortedSkills.length > 0) {
              sortedSkills.sort((a, b) =>
                a.shortCode > b.shortCode
                  ? 1
                  : b.shortCode > a.shortCode
                  ? -1
                  : 0
              );
            }

            setSkills(sortedSkills ?? []);
            //setSkills(data.courseSkills ?? []);

            const courseBookings = data.courseBookings ?? [];
            courseBookings.map((row, index) => {
              if (row.courseParticipants.length > 0) {
                row.courseParticipants.sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                );
              }
            });

            setBookings(courseBookings);

            const allStudents = courseBookings.flatMap(
              (x) => x.courseParticipants
            );

            const mappedStudents = Object.fromEntries(
              allStudents.map((student) => [student.actorId, student])
            );
            setStudentsByActorId(mappedStudents);

            const studentSkills = Object.fromEntries(
              allStudents.map((student) => [
                student.actorId,
                Object.fromEntries(
                  student.grades.map((grade) => [
                    grade.skillId,
                    grade.hasPassed,
                  ])
                ),
              ])
            );
            setSkillsByActorId(studentSkills);

            const studentLicenses = Object.fromEntries(
              allStudents.map((student) => [
                student.actorId,
                {
                  date:
                    student.actorLicenseDate != null
                      ? new Date(student.actorLicenseDate)
                      : null,
                  hasDigitalLicense: student.hasDigitalLicense,
                  // Locked if license was created previously
                  isLocked: student.actorLicenseDate != null,
                },
              ])
            );

            setLicensesByActorId(studentLicenses);
            setIsAnyLicenseLocked(
              allStudents.some((student) => !!student.actorLicenseDate)
            );
            setIsAnyStudentUnlicensed(
              allStudents.every((student) => student.actorLicenseDate === null)
            );
            setIsDataFetched(true);
          }
        });
    }
  }, [courseId, open, showCourseSkillsDialog, api]);

  // Updates the checked status of the top checkboxes
  useEffect(() => {
    setSkills((oldSkills) => {
      const skillsCopy = [...oldSkills];
      for (let i = 0; i < skillsCopy.length; i++) {
        let skillCopy = { ...skillsCopy[i] };

        const unlicensedStudentsSkills = Object.entries(skillsByActorId).filter(
          ([actorId, _]) => unlicensedStudentActorIds.includes(actorId)
        );

        const hasAllUnlicensedStudentsPassed = unlicensedStudentsSkills.every(
          ([_, skills]) => !!skills[skillCopy.skillId]
        );

        const hasSomeUnlicensedStudentsPassed =
          hasAllUnlicensedStudentsPassed ||
          unlicensedStudentsSkills.some(
            ([_, skills]) => !!skills[skillCopy.skillId]
          );

        skillCopy.hasAllUnlicensedStudentsPassed =
          hasAllUnlicensedStudentsPassed;
        skillCopy.hasSomeUnlicensedStudentsPassed =
          hasSomeUnlicensedStudentsPassed;
        skillsCopy[i] = skillCopy;
      }
      return skillsCopy;
    });
  }, [skillsByActorId, unlicensedStudentActorIds]);

  // Updates the disabled status of the "Approve All" button,
  // based on whether there are any skills remaining that are
  // not yet approved, and are not locked by an existing license
  useEffect(() => {
    let hasRemainingSkillsToApprove = false;
    if (unlicensedStudentActorIds.length > 0) {
      const allSkillIds = skills.map((skill) => skill.skillId);
      hasRemainingSkillsToApprove = unlicensedStudentActorIds.some((actorId) =>
        allSkillIds.some((skillId) => !skillsByActorId[actorId][skillId])
      );
    } else {
      hasRemainingSkillsToApprove = false;
    }
    setCanApproveAll(hasRemainingSkillsToApprove);
  }, [skillsByActorId, skills, unlicensedStudentActorIds]);

  useEffect(() => {
    const allLicenseDates = Object.values(licensesByActorId).map(
      (license) => license.date
    );

    const someStudentsDontHaveLicense = allLicenseDates.some(
      (licenseDate) => licenseDate === null
    );
    setIsAnyStudentUnlicensed(someStudentsDontHaveLicense);

    const sameDate =
      allLicenseDates.length > 0 &&
      !!allLicenseDates[0] &&
      allLicenseDates.every((date) => date === allLicenseDates[0])
        ? allLicenseDates[0]
        : null;
    setAllLicenseSameDate(sameDate);

    setIsAnyLicenseLocked(
      Object.values(licensesByActorId).some((license) => license.isLocked)
    );
  }, [licensesByActorId]);

  function createStudentGradeDtos(actorId, skillIds, updatedSkillsByActorId) {
    return skillIds && skillIds.length > 0
      ? skillIds.map((skillId) => ({
          skillId: skillId,
          actorId: actorId,
          hasPassed: updatedSkillsByActorId[actorId][skillId],
        }))
      : null;
  }

  function postUpdatedStudentsGrades(
    actorIds,
    skillIds,
    updatedSkillsByActorId
  ) {
    const dtos = actorIds.flatMap((actorId) =>
      createStudentGradeDtos(actorId, skillIds, updatedSkillsByActorId)
    );

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/grades/`,
      dtos,
      "POST"
    );
  }

  function handleCheckSkillChange(event, actorId, skillId) {
    if (!flagIsPostingGrades()) {
      return;
    }

    // Null means deleting the grade. Currently we have no UI to enable failing a student in a grade
    // and it's not needed. But simply passing false here would achieve that in the backend
    const hasPassed = event.target.checked ? true : null;
    const studentSkillsCopy = { ...skillsByActorId[actorId] };
    studentSkillsCopy[skillId] = hasPassed;
    const skillsByActorIdCopy = { ...skillsByActorId };
    skillsByActorIdCopy[actorId] = studentSkillsCopy;
    setSkillsByActorId(skillsByActorIdCopy);
    postUpdatedStudentsGrades([actorId], [skillId], skillsByActorIdCopy);
  }

  function handleCheckSkillAllChange(event, skillId) {
    if (!flagIsPostingGrades()) {
      return;
    }

    // Null means deleting the grade. Currently we have no UI to enable failing a student in a grade
    // and it's not needed. But simply passing false here would achieve that in the backend
    const hasPassed = event.target.checked ? true : null;
    let skillsByActorIdCopy = { ...skillsByActorId };

    for (const actorId of unlicensedStudentActorIds) {
      const studentSkillsCopy = { ...skillsByActorId[actorId] };
      studentSkillsCopy[skillId] = hasPassed;
      skillsByActorIdCopy[actorId] = studentSkillsCopy;
    }

    setSkillsByActorId(skillsByActorIdCopy);
    postUpdatedStudentsGrades(
      unlicensedStudentActorIds,
      [skillId],
      skillsByActorIdCopy
    );
  }

  function handleApproveAllSkills() {
    if (!flagIsPostingGrades()) {
      return;
    }

    const allSkillIds = skills.map((skill) => skill.skillId);
    let skillsByActorIdCopy = { ...skillsByActorId };

    for (const actorId of unlicensedStudentActorIds) {
      const studentSkillsCopy = { ...skillsByActorId[actorId] };
      for (const skillId of allSkillIds) {
        studentSkillsCopy[skillId] = true;
      }
      skillsByActorIdCopy[actorId] = studentSkillsCopy;
    }

    setSkillsByActorId(skillsByActorIdCopy);
    postUpdatedStudentsGrades(
      unlicensedStudentActorIds,
      allSkillIds,
      skillsByActorIdCopy
    );
  }

  function handleApproveAllSkillsForStudent(actorId) {
    if (!flagIsPostingGrades()) {
      return;
    }

    const allSkillIds = skills.map((skill) => skill.skillId);
    let skillsByActorIdCopy = { ...skillsByActorId };

    const studentSkillsCopy = { ...skillsByActorId[actorId] };
    for (const skillId of allSkillIds) {
      studentSkillsCopy[skillId] = true;
    }
    skillsByActorIdCopy[actorId] = studentSkillsCopy;

    setSkillsByActorId(skillsByActorIdCopy);
    postUpdatedStudentsGrades(
      unlicensedStudentActorIds,
      allSkillIds,
      skillsByActorIdCopy
    );
  }

  function postUpdatedStudentsLicenses(
    actorIds,
    updatedLicenseDate,
    forceDelete
  ) {
    const dtos = actorIds.map((actorId) => ({
      courseParticipantId: studentsByActorId[actorId].courseParticipantId,
      actorLicenseDate: updatedLicenseDate,
    }));

    api.fetch(
      `${
        process.env.REACT_APP_MAIN_URL
      }courses/${courseId}/licenses?forceDelete=${!!forceDelete}`,
      dtos,
      "POST"
    );
  }

  // Here we only post updates to backend if the date is valid,
  // but still update local state to trigger DatePicker validation
  function handleUpdateLicense(
    actorId,
    date,
    isLocked,
    isEditing,
    forceDelete
  ) {
    const licensesByActorIdCopy = { ...licensesByActorId };
    licensesByActorIdCopy[actorId] = {
      date,
      isEditing: isEditing ?? false,
      isLocked: isLocked ?? false,
    };
    setLicensesByActorId(licensesByActorIdCopy);

    if (date === null || isValidDate(date)) {
      postUpdatedStudentsLicenses([actorId], date, forceDelete);
    }
  }

  function handleCreateLicenseClick(actorId) {
    const date = new Date();
    handleUpdateLicense(actorId, date);
  }

  function handleDeleteLicenseClick(actorId, forceDelete) {
    if (
      !actorIdToDeleteLicense &&
      licensesByActorId[actorId].hasDigitalLicense
    ) {
      setActorIdToDeleteLicense(actorId);
    } else {
      handleUpdateLicense(actorId, null, false, false, forceDelete);
      setActorIdToDeleteLicense(null);
    }
  }

  function handleCreateLicenseAllClick() {
    const date = new Date();
    const licensesByActorIdCopy = { ...licensesByActorId };

    for (const actorId of unlicensedStudentActorIds) {
      licensesByActorIdCopy[actorId] = { date };
    }

    setLicensesByActorId(licensesByActorIdCopy);
    postUpdatedStudentsLicenses(unlicensedStudentActorIds, date);
  }

  function handleUpdateAllLicense(date) {
    if (!allLicensesSameDate || isAnyLicenseLocked || !date) {
      throw new Error(
        "Updating all license dates is not allowed in the current state"
      );
    }

    const licensesByActorIdCopy = { ...licensesByActorId };
    const allActorIds = Object.keys(licensesByActorId);

    for (const actorId of allActorIds) {
      licensesByActorIdCopy[actorId] = { date };
    }

    setLicensesByActorId(licensesByActorIdCopy);
    if (isValidDate(date)) {
      postUpdatedStudentsLicenses(allActorIds, date);
    }
  }

  function handleOpenSkillsDialog() {
    window.location.hash = "#grades#available-skills";
    setShowCourseSkillsDialog(true);
  }

  function handleCloseSkillsDialog() {
    window.history.back();
    setShowCourseSkillsDialog(false);
  }

  function handleOrderLicensesClick() {
    setShowLicensePrint(true);
  }

  const commonProps = {
    open,
    onClose,
    bookings,
    skills,
    handleCheckSkillChange,
    skillsByActorId,
    canApproveAll,
    handleApproveAllSkills,
    handleOpenSkillsDialog,
    showCourseSkillsDialog,
    isAnyStudentUnlicensed,
    handleCreateLicenseClick,
    handleDeleteLicenseClick,
    handleCreateLicenseAllClick,
    licensesByActorId,
    handleOrderLicensesClick,
    handleApproveAllSkillsForStudent,
  };
  const bigProps = {
    ...commonProps,
    handleCheckSkillAllChange,
    handleUpdateLicense,
    handleUpdateAllLicense,
    allLicensesSameDate,
    isAnyLicenseLocked,
  };
  const smallProps = {
    ...commonProps,
  };

  function handlePrintDialogClose(isPrintDone, includesDigitalLicenses) {
    setShowLicensePrint(false);
    if (isPrintDone) {
      // Close this dialog too in that case
      onClose(includesDigitalLicenses);
    }
  }

  return (
    <>
      <YesOrNoDialog
        open={!!actorIdToDeleteLicense}
        title={Translate.get("DeleteActorLicenseWithDigitalLicense")}
        text={Translate.get("SureDeleteActorLicenseWithDigitalLicense")}
        onNo={() => setActorIdToDeleteLicense(null)}
        onYes={() => handleDeleteLicenseClick(actorIdToDeleteLicense, true)}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Delete")}
      />
      <CourseSkillsDialog
        courseId={courseId}
        open={showCourseSkillsDialog}
        onClose={handleCloseSkillsDialog}
      />
      {showLicensePrint && (
        <ActorLicensePrintDialog
          courseId={courseId}
          onClose={handlePrintDialogClose}
        />
      )}
      {open && !isDataFetched && <Preloader />}
      {isDataFetched && !isSmallScreen && (
        <CourseGradesDialogBig {...bigProps} />
      )}
      {isDataFetched && isSmallScreen && (
        <CourseGradesDialogSmall {...smallProps} />
      )}
    </>
  );
}
