import { Box, Grid, Card } from "@mui/material";
//import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import NumbersIcon from "@mui/icons-material/Numbers";
//import LabelIcon from "@mui/icons-material/Label";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PlaceIcon from "@mui/icons-material/Place";
import ActorAvatar from "../ActorAvatar";
import DotMenu from "../DotMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
import { accessKeys, hasAccess } from "../../utils/userAccess";

export default function InstructorCard({
  actorName,
  actorEmail,
  externalActorNo,
  orgNo,
  address,
  filterGroups,
  profilePictureUrl,
  extraActions,
}) {
  return (
    <Card
      sx={{ padding: "16px", marginBottom: "16px", transform: "translateZ(0)" }}
    >
      <ActorAvatar
        name={actorName}
        email={actorEmail}
        profilePictureUrl={profilePictureUrl + ""}
      />
      <Box sx={{ position: "fixed", top: "18px", right: "16px" }}>
        <DotMenu content={extraActions} horizontal />
      </Box>
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{ padding: "30px 0px 16px 18px" }}
      >
        <Grid item container alignItems="center">
          <NumbersIcon />
          <Box marginLeft="19px">{externalActorNo}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <FingerprintIcon />
          <Box marginLeft="19px">{orgNo}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <PlaceIcon />
          <Box marginLeft="19px">{address}</Box>
        </Grid>
        {hasAccess(accessKeys.advancedPackage) && (
          <Grid item container alignItems="center">
            <FilterListIcon />
            <Box marginLeft="19px">{filterGroups}</Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
