import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import Translate from "../../utils/Translate";
import SwipeableViews from "react-swipeable-views";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";

export default function LicenseRegistrationGradeStudentDialog({
  open,
  onClose,
  visibleCompetences,
  students,
  selectedStudentId,
  handleStudentAddRemoveCompetence,
}) {
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    const selectedStudentIndex = students.findIndex(
      (student) => student.id === selectedStudentId
    );
    setIndex(selectedStudentIndex);
  }, [selectedStudentId, students]);

  const competencesByStudentId = useMemo(
    () => Object.fromEntries(students.map((s) => [s.id, s.competences])),
    [students]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{Translate.get("ApproveSomeSkills")}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {/* enableMouseEvent enables swiping with a mouse */}
        {index > -1 && (
          <SwipeableViews
            index={index}
            onChangeIndex={(idx) => setIndex(idx)}
            enableMouseEvents
          >
            {students.map((student) => (
              <Box key={student.id} sx={{ overflowX: "hidden" }}>
                <Box className="studentGradesAvatarContainer">
                  <IconButton
                    onClick={() => setIndex(index - 1)}
                    disabled={index === 0}
                  >
                    {/* Setting viewBox due to this icon not being centered */}
                    <ArrowLeftIcon viewBox="-6 0 24 24" />
                  </IconButton>
                  <Box
                    className="avatarInfo"
                    sx={{ margin: "auto", textAlign: "center" }}
                  >
                    <Box>
                      {!!student.name && student.name !== ""
                        ? student.name
                        : Translate.get("FullNamePlaceholder")}
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {student.socialSecurityNumber}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => setIndex(index + 1)}
                    disabled={index === students.length - 1}
                  >
                    <ArrowRightIcon />
                  </IconButton>
                </Box>
                <Divider />
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    margin: "1.5em 24px 0px",
                    textTransform: "uppercase",
                  }}
                >
                  {Translate.get("Competences")}
                </Typography>
                <FormControl>
                  <FormGroup aria-label="position">
                    {visibleCompetences.map((competence) => (
                      <FormControlLabel
                        className="studentGradesSkillCheckbox"
                        control={
                          <Checkbox
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            checked={
                              !!competencesByStudentId[student.id] &&
                              !!competencesByStudentId[student.id][
                                competence.id
                              ]
                            }
                            onChange={(_) =>
                              handleStudentAddRemoveCompetence(
                                student,
                                competence.id
                              )
                            }
                          />
                        }
                        label={
                          <Typography sx={{ marginLeft: "14px" }} noWrap>
                            {`${competence.column} ${competence.description}`}
                          </Typography>
                        }
                        labelPlacement="end"
                        key={competence.id}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </SwipeableViews>
        )}
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
