import { useState, useEffect } from 'react';
import Translate from '../../utils/Translate';
import { useApi } from '../../utils/Api';
import { useSnackbar } from 'notistack';
import Preloader from '../Preloader';
import {
  Checkbox,
  TextField,
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
const Comp = () => {
  /*   const mocdata = {
    settings: {
      courseBookingEmail: 'juri.pyykko@masystem.se',
      showPrice: false,
      fieldOrgNoIsRequired: true,
      showSlots: false,
    },
    isSuccessful: true,
    errorMessageTranslationKey: null,
  }; */
  //const [data, setData] = useState(mocdata);
  const [data, setData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  if (!data) {
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/coursebookingiframe`,
      );
      setData(data);
    })();
  }

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(e.target);
    const jsObj = Object.fromEntries(formData);
    //data.map((row, index) => (<div key={index}></div>))
    const outData = {
      courseBookingEmail: jsObj.courseBookingEmail,
      showPrice: jsObj.showPrice ? true : false,
      fieldOrgNoIsRequired: jsObj.fieldOrgNoIsRequired ? true : false,
      showSlots: jsObj.showSlots ? true : false,
    };
    //console.log(data, outData);
    (async () => {
      const ret = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/coursebookingiframe`,
        outData,
      );
        if (ret.isSuccessful) {
          enqueueSnackbar(Translate.get('IframeBookingComplete'),{variant: 'success',autoHideDuration:3000});

        }
        //console.log('return data',ret)
        setSubmitting(false);
      //WIP Gör saker
    })();
  };
    

  if (!data) {
    return <Preloader />;
  }
  return (
    <>
      <div
        className="stdPage"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1.5em',
        }}
      >
        <form onSubmit={submitForm}>
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">
            {Translate.get('IframeSettings')}
            </div>
            <div className="stdCardBody">
              <div style={{ padding: '9px' }}>
                <div>
                  <TextField
                    type="email"
                    required
                    fullWidth
                    label={Translate.get('EmailIframeReceiver')}
                    name="courseBookingEmail"
                    defaultValue={data.settings.courseBookingEmail || ''}
                  />
                </div>
              </div>
              <div className="stdFlexLeft">
                <Checkbox
                  defaultChecked={data?.settings.showSlots}
                  name="showSlots"
                />
                <div>{Translate.get('IframeAvailableSlots')}</div>
              </div>
              <div className="stdFlexLeft">
                <Checkbox
                  defaultChecked={data?.settings.showPrice}
                  name="showPrice"
                />
                <div>{Translate.get('IframeShowPrice')}</div>
              </div>
              <div className="stdFlexLeft">
                <Checkbox
                  defaultChecked={data?.settings.fieldOrgNoIsRequired}
                  name="fieldOrgNoIsRequired"
                />
                <div>{Translate.get('IframeOrgnumber')}</div>
              </div>
              <div className="stdCardFooter">
                <LoadingButton
                 className="stdLightLoader"
                 loading={submitting}
                type="submit">{Translate.get('Update')}</LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Comp;
