import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../utils/Api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Translate from "../../../utils/Translate";
import { useState } from "react";
import { useEffect } from "react";
import Preloader from "../../Preloader";
import ButtonWithSpinner from "../../ButtonWithSpinner";

export default function AddOrEditSkillGroupDialog({ skillGroupId }) {
  const [skillGroup, setSkillGroup] = useState(skillGroupId ? null : {});
  const [isLoading, setIsLoading] = useState(true);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (skillGroupId) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}skillgroups/1/${skillGroupId}`,
          false,
          "GET"
        )
        .then((response) => {
          if (response.isSuccessful) {
            setSkillGroup(response);
          }
        });
    }
    setIsLoading(false);
  }, [api, skillGroupId]);

  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  async function postSkillGroup() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups`,
      skillGroup,
      "POST"
    );
    return response.isSuccessful;
  }

  async function handleSaveLicenseGroup() {
    if (await postSkillGroup()) {
      closeAndReload();
    }
  }

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          <DialogContent className="stdDialogContent dialogOverlay">
            <br />
            <TextField
              fullWidth
              value={skillGroup?.description ?? ""}
              onChange={(event) =>
                setSkillGroup({
                  ...skillGroup,
                  description: event.target.value,
                })
              }
              label={Translate.get("Name")}
              placeholder={Translate.get("Name")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner
              disabled={!skillGroup || !skillGroup.description}
              onClick={handleSaveLicenseGroup}
            >
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </>
      )}
    </>
  );
}
