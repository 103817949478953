import { useNavigate } from 'react-router-dom';
import { refreshTokenExpirationMinutes } from '../../utils/userAccess';
let timeOutInterval;

const Comp = () => {
  const navigate = useNavigate();
  const action = () => {
    let minuteInterval = refreshTokenExpirationMinutes - 1;
    minuteInterval=minuteInterval * 60000;
    if (!sessionStorage.lastConnection){
      sessionStorage.lastConnection=Date.now();
    }
    const timeOutTime=parseFloat(sessionStorage.lastConnection) + minuteInterval;
    ////console.log( 'TIMER ',timeOutTime,  Date.now());
    ////console.log( 'Minutes left ',(timeOutTime -  Date.now()) / 60000);
    if (timeOutTime < Date.now() ) {
      //console.log('Session ended');
      clearInterval(timeOutInterval);
      // setTimeOut(true);
      navigate('/login?timeout=1', { replace: true });
    }

  };
  
  clearInterval(timeOutInterval);
  timeOutInterval = setInterval(action, 60000);

  return (
    <>
    </>
  );
};
export default Comp;
