import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState, useMemo, useCallback, useEffect } from "react";
import DotMenu from "../DotMenu";
import {
  getGridColDefForSingleSelect,
  IconAndLabelColumnHeader,
} from "../../utils/dataGrid";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { dataGridLang } from "../MemToolBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DomainIcon from "@mui/icons-material/Domain";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import OrderFormBase from "./OrderFormBase";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "../../utils/pagination";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import DataGridEasyFilters from "../DataGridEasyFilters";
import LinkMenu from "../LinkMenu";
import ClearIcon from "@mui/icons-material/Clear";
import { getOrderIconbox, orderStatus } from "../../utils/order";
import {
  Check,
  Flag,
  HourglassBottom,
  HourglassTop,
  Inventory,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Merge,
  Money,
  RequestQuote,
  School,
  Undo,
} from "@mui/icons-material";
import { groupBy } from "lodash";
import { AlertDialog } from "../AlertDialog";
import OrdersGridDetailsPanel from "./OrdersGridDetailsPanel";
import { formatMoney } from "../../utils/formatMoney";
import { getIcon, priority } from "../../utils/priority";
import PickOrderDialog from "../pick/PickOrderDialog";
import {
  isAutoPickPartType,
  isCoursePartType,
  isFreightPartType,
} from "../../utils/part";
import MergeOrdersDialog from "./MergeOrdersDialog";

const getStatusFilterOptions = () =>
  Object.values(orderStatus).map((status) => ({
    value: status,
    label: Translate.getOrderStatus(status),
  }));

export default function OrdersGrid({ actorId, searchText, onLoaded }) {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [pickOrderDetails, setPickOrderDetails] = useState(null);
  const [longErrorMessage, setLongErrorMessage] = useState(null);
  const [ordersToMerge, setOrdersToMerge] = useState([]);
  const localizedTextsMap = dataGridLang();
  const api = useApi();

  const breakpoint = actorId ? 800 : 900;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(breakpoint));
  const gridClassName = isSmallScreen
    ? "dgSmall"
    : "stdVisible dgDynIconText shiftDatagridToolbar";

  const [searchParams] = useSearchParams();
  const filterCourseId = searchParams.get("filterCourseId");

  const navigate = useNavigate();
  const navigateToCompany = useCallback(
    (id) => {
      navigate(`/dashboard/company/${id}`, { replace: false });
    },
    [navigate]
  );
  const navigateToCourse = useCallback(
    (id) => {
      navigate(`/dashboard/course/${id}`, { replace: false });
    },
    [navigate]
  );

  const loadOrders = useCallback(
    async (queryModel) => {
      const url = actorId
        ? `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orders/get-page`
        : `${process.env.REACT_APP_MAIN_URL}orders/get-page`;

      const response = await api.fetch(url, queryModel, "POST");

      if (onLoaded) {
        onLoaded();
      }

      if (response && response.isSuccessful) {
        setSelectedOrderIds([]); // LEt's clear the selection on data load
        return { rows: response.rows, rowCount: response.rowCount };
      }
    },
    [api, onLoaded, actorId]
  );

  function buildDefaultFilter(courseId) {
    if (!courseId) {
      return null;
    }

    return {
      items: [
        {
          id: 1,
          field: "courseId",
          operator: "=",
          value: courseId.toString(),
        },
      ],
      logicOperator: "and",
    };
  }

  const { localUpdateRows, reloadData, resetPagination, ...pagination } =
    usePagination(
      loadOrders,
      searchText,
      [
        {
          field: "regDate",
          sort: "desc",
        },
      ],
      buildDefaultFilter(filterCourseId),
      [25, 50, 100],
      "ORDERS_GRID"
    );

  useEffect(() => {
    if (isSmallScreen) {
      // Most columns will not be available on small screens, so reset
      // the pagination, in case there was any saved state in session
      resetPagination();
    }
  }, [resetPagination, isSmallScreen]);

  const markForInvoice = useCallback(
    async (orderId, isMulti) => {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/mark-for-invoice`,
        true,
        "POST",
        (response) => (isMulti ? true : response.isSuccessful)
      );

      if (response.isSuccessful) {
        localUpdateRows((prev) => {
          const index = prev.findIndex((o) => o.orderId === orderId);
          return index >= 0
            ? prev.with(index, {
                ...prev[index],
                status: response.status,
              })
            : prev;
        });
      }
      return response;
    },
    [api, localUpdateRows]
  );

  const finishOrder = useCallback(
    async (orderId, isMulti) => {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/finish`,
        true,
        "POST",
        (response) => (isMulti ? true : response.isSuccessful)
      );

      if (response.isSuccessful) {
        localUpdateRows((prev) => {
          const index = prev.findIndex((o) => o.orderId === orderId);
          return index >= 0
            ? prev.with(index, {
                ...prev[index],
                status: orderStatus.finished,
              })
            : prev;
        });
      }
      return response;
    },
    [api, localUpdateRows]
  );

  const changeOrderPriority = useCallback(
    async (orderId, priorityParam, isMulti) => {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/priority?priority=${priorityParam}`,
        true,
        "POST",
        (response) => (isMulti ? true : response.isSuccessful)
      );
      if (response.isSuccessful) {
        localUpdateRows((prev) => {
          const index = prev.findIndex((o) => o.orderId === orderId);
          return index >= 0
            ? prev.with(index, {
                ...prev[index],
                priority: priorityParam,
              })
            : prev;
        });
      }
      return response;
    },
    [api, localUpdateRows]
  );

  const undoStartPick = useCallback(
    async (orderId, isMulti) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/undo-start-pick`,
        true,
        "POST"
      );
      if (response.isSuccessful) {
        reloadData();
      }
      return response;
    },
    [api, reloadData]
  );

  const canMergeOrders = useCallback(
    (orders) => {
      const firstOrder = orders[0];
      if (orders.some((o) => o.actorId !== firstOrder.actorId)) {
        return { canMerge: false, error: "NotSameCompany" };
      } else if (
        orders.some(
          (o) =>
            o.status < orderStatus.ordered ||
            o.status >= orderStatus.waitingForInvoice
        )
      ) {
        return { canMerge: false, error: "WrongStatus" };
      } else if (orders.some((o) => o.currency !== firstOrder.currency)) {
        return { canMerge: false, error: "NotSameCurrency" };
      } else if (
        orders.some((o) => o.receiverActorId !== firstOrder.receiverActorId)
      ) {
        return { canMerge: false, error: "NotSameReceiverCompany" };
      }
      return { canMerge: true };
    },
    [pagination.rows]
  );

  const getDotMenuActions = useCallback(
    (order, isMulti) => {
      const menudata = [];
      menudata.push({
        id: "edit-order",
        hide: isMulti,
        icon: <EditIcon />,
        text: Translate.get("EditOrder"),
        customClass: hasAccess(accessKeys.isMASystem)
          ? "bigCourseDialog"
          : "mediumCourseDialog",
        component: <OrderFormBase orderId={order.orderId} />,
      });

      if (hasAccess(accessKeys.isMASystem)) {
        // Order actions based on status
        if (
          false && // TODO Tillfälligt fram tills att kortutskrift är implementerat
          order.status >= orderStatus.ordered &&
          order.status <= orderStatus.underPicking
        ) {
          const hasStartedPick = order.orderLines.some(
            (ol) => ol.status >= orderStatus.underPicking
          );
          menudata.push({
            id: "pick-order",
            hide: isMulti,
            disabled: order.priority === priority.onHold,
            icon: <Inventory />,
            text: hasStartedPick
              ? Translate.get("ContinuePick")
              : Translate.get("StartPick"),
            onClick: () =>
              setPickOrderDetails({ orderId: order.orderId, hasStartedPick }),
          });

          if (hasStartedPick) {
            const canUndoStartPick = order.orderLines.every((ol) =>
              !isAutoPickPartType(ol.part.partType) &&
              !isFreightPartType(ol.part.partType)
                ? ol.status === orderStatus.underPicking && ol.delQty === 0
                : true
            );
            menudata.push({
              id: "undo-pick-order",
              disabled: !canUndoStartPick || order.priority === priority.onHold,
              icon: <Undo />,
              text: Translate.get("UndoStartPick"),
              onClick: async () => await undoStartPick(order.orderId),
            });
          }
        } else if (
          order.status >= orderStatus.waitingForCourseInvoice &&
          order.status <= orderStatus.pickedAutoOrder
        ) {
          // After finishing pick some actions are show in the dialog.
          // But sometimes we might need to perform the actions later,
          // for example if the dialog was accidentally closed.
          const hasCourse = order.orderLines.some((ol) =>
            isCoursePartType(ol.part.partType)
          );
          const isPicked = order.orderLines.every(
            (ol) =>
              ol.status === orderStatus.picked ||
              ol.status == orderStatus.pickedAutoOrder
          );
          if (
            false && // TODO Tillfälligt fram tills att kortutskrift är implementerat
            isPicked &&
            !hasCourse
          ) {
            menudata.push({
              id: "pick-order",
              hide: isMulti,
              disabled: order.priority === priority.onHold,
              icon: <Inventory />,
              text: Translate.get("AfterPick"),
              onClick: () =>
                setPickOrderDetails({
                  orderId: order.orderId,
                  hasStartedPick: true,
                }),
            });
          }

          menudata.push({
            id: "mark-for-invoice",
            icon: <RequestQuote />,
            disabled: order.priority === priority.onHold,
            text: Translate.get("MarkForInvoice"),
            onClick: async () => await markForInvoice(order.orderId, isMulti),
          });
        } else if (order.status === orderStatus.paid) {
          menudata.push({
            id: "finish-order",
            icon: <DoneIcon />,
            disabled: order.priority === priority.onHold,
            text: Translate.get("FinishOrder"),
            onClick: async () => await finishOrder(order.orderId, isMulti),
          });
        }

        // Priority changes
        if (order.priority !== priority.high) {
          menudata.push({
            id: "high-priority",
            icon: getIcon(priority.high),
            disabled: false,
            text: `${Translate.get("Priority")}: ${Translate.getPriority(
              priority.high
            )}`,
            onClick: async () =>
              await changeOrderPriority(order.orderId, priority.high, isMulti),
          });
        }
        if (order.priority && order.priority !== priority.normal) {
          menudata.push({
            id: "normal-priority",
            icon: getIcon(priority.normal),
            disabled: false,
            text: `${Translate.get("Priority")}: ${Translate.getPriority(
              priority.normal
            )}`,
            onClick: async () =>
              await changeOrderPriority(
                order.orderId,
                priority.normal,
                isMulti
              ),
          });
        }
        if (order.priority !== priority.onHold) {
          menudata.push({
            id: "onHold-priority",
            icon: getIcon(priority.onHold),
            disabled: false,
            text: `${Translate.get("Priority")}: ${Translate.getPriority(
              priority.onHold
            )}`,
            onClick: async () =>
              await changeOrderPriority(
                order.orderId,
                priority.onHold,
                isMulti
              ),
          });
        }
      } else {
        menudata.push({
          id: "finish-order",
          icon: <DoneIcon />,
          disabled: order.status === orderStatus.finished,
          text: Translate.get("FinishOrder"),
          onClick: async () => await finishOrder(order.orderId, isMulti),
        });
      }

      if (order.courseId) {
        menudata.push({
          id: `go-to-course-${order.courseId}`,
          icon: <School />,
          text: Translate.get("GoToCourse"),
          onClick: () => navigateToCourse(order.courseId),
        });
      }

      return menudata;
    },
    [
      finishOrder,
      navigateToCourse,
      markForInvoice,
      changeOrderPriority,
      undoStartPick,
    ]
  );

  const getMultiMenuActions = useCallback(
    (orders) => {
      const menudata = [];
      if (hasAccess(accessKeys.isMASystem)) {
        const result = canMergeOrders(orders);
        const disabledTooltip = result.error
          ? `${Translate.get("CannotMergeOrdersReason")} ${Translate.get(
              result.error
            )}`
          : null;
        menudata.push({
          id: "merge-orders",
          icon: <Merge />,
          hide: orders.length < 2,
          text: Translate.get("MergeOrders"),
          disabled: !result.canMerge,
          disabledTooltip: disabledTooltip,
          onClick: () => setOrdersToMerge(orders),
        });
      }

      return menudata;
    },
    [canMergeOrders]
  );

  const columns = useMemo(
    () => [
      {
        field: "regDate",
        type: "date",
        flex: 0.5,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Date"),
        valueGetter: (params) => new Date(params.value),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={CalendarTodayIcon}
            label={Translate.get("Date")}
          />
        ),
        renderCell: (params) => formatDate(params.value),
      },
      ...(!isSmallScreen
        ? [
            {
              field: "orderNo",
              flex: 0.3,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("OrderNo"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={FormatListNumberedIcon}
                  label={Translate.get("OrderNo")}
                />
              ),
            },
            {
              field: "customerOrderNo",
              flex: 0.5,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("PurchaseOrderNo"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={ListAltIcon}
                  label={Translate.get("PurchaseOrderNo")}
                />
              ),
            },
          ]
        : []),
      ...(!actorId
        ? [
            {
              field: "actorName",
              flex: 0.7,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Company"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={DomainIcon}
                  label={Translate.get("Company")}
                />
              ),
              renderCell: (params) => (
                <Box onClick={() => navigateToCompany(params.row.actorId)}>
                  {params.value}
                </Box>
              ),
            },
          ]
        : []),
      {
        field: "partDescription",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Product"),
        valueGetter: (params) =>
          params.row.orderLines &&
          params.row.orderLines
            .map((ol) =>
              hasAccess(accessKeys.isMASystem)
                ? `${ol.qty} x ${ol.part.description.trim()}`
                : ol.part.description.trim()
            )
            .join(", "),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ShoppingBasketIcon}
            label={Translate.get("Product")}
          />
        ),
      },
      ...(!isSmallScreen && hasAccess(accessKeys.isMASystem)
        ? [
            {
              field: "invoicePrice",
              type: "number",
              align: "left",
              headerAlign: "left",
              flex: 0.35,
              filterable: false,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Price"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={Money}
                  label={Translate.get("Price")}
                />
              ),
              renderCell: (params) =>
                params.row.currency
                  ? formatMoney(params.value, null, params.row.currency)
                  : `${params.value} (?)`,
            },
          ]
        : []),
      // !hasAccess(accessKeys.isMASystem) because MA-system will have qty under product col instead
      ...(!isSmallScreen && !hasAccess(accessKeys.isMASystem)
        ? [
            {
              field: "quantity",
              type: "number",
              align: "left",
              headerAlign: "left",
              flex: 0.5,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Quantity"),
              valueGetter: (params) =>
                params.row.orderLines &&
                params.row.orderLines.map((ol) => ol.qty).join(", "),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={ScatterPlotIcon}
                  label={Translate.get("Quantity")}
                />
              ),
            },
          ]
        : []),
      {
        ...getGridColDefForSingleSelect(getStatusFilterOptions(), true),
        field: "status",
        flex: 0.3,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Status"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={CheckCircleIcon}
            label={Translate.get("Status")}
          />
        ),
        renderCell: (params) =>
          getOrderIconbox(params.value, null, false) ?? "",
      },
      ...(hasAccess(accessKeys.isMASystem)
        ? [
            {
              field: "priority",
              type: "singleSelect",
              flex: 0.3,
              hide: true,
              headerName: Translate.get("Priority"),
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={Flag}
                  label={Translate.get("Priority")}
                />
              ),
              renderCell: (params) =>
                params.value &&
                params.value !== priority.normal && (
                  <Tooltip title={Translate.getPriority(params.value)}>
                    {getIcon(params.value)}
                  </Tooltip>
                ),
            },
          ]
        : []),
      ...(!isSmallScreen
        ? [
            {
              field: "regActorName",
              flex: 0.5,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("RegisteredBy"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={AccountCircleIcon}
                  label={Translate.get("RegisteredBy")}
                />
              ),
            },
          ]
        : []),
      {
        field: "courseId",
        type: "number",
        hide: true,
        hideable: false,
        flex: 0.3,
        headerName: Translate.get("Course"),
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={School}
            label={Translate.get("Course")}
          />
        ),
      },
      {
        field: "isCourse",
        type: "boolean",
        flex: 0.3,
        hide: true,
        headerName: Translate.get("CourseBooking") + "?",
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={School}
            label={Translate.get("CourseBooking") + "?"}
          />
        ),
        renderCell: (params) => params.value && <Check />,
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu content={getDotMenuActions(params.row, false)} horizontal />
        ),
      },
    ],
    [getDotMenuActions, navigateToCompany, actorId, isSmallScreen]
  );

  const easyFilters = useMemo(
    () => [
      {
        id: "default-picking",
        name: Translate.get("PickFilterLabel"),
        description: Translate.get("DefaultPickFilterDescription"),
        filterModel: {
          items: [
            {
              id: 1001,
              field: "status",
              operator: "between",
              value: [orderStatus.ordered, orderStatus.underPicking],
            },
          ],
          logicOperator: "and",
        },
        sortModel: [
          {
            field: "priority",
            sort: "desc",
          },
          {
            field: "regDate",
            sort: "asc",
          },
        ],
      },
      {
        id: "default-ready-for-invoice-filter",
        name: Translate.get("ReadyForInvoice"),
        description: Translate.get("DefaultReadyForInvoiceFilterDescription"),
        filterModel: {
          items: [
            {
              id: 1002,
              field: "status",
              operator: "between",
              value: [
                orderStatus.waitingForCourseInvoice,
                orderStatus.pickedAutoOrder,
              ],
            },
          ],
          logicOperator: "and",
        },
      },
      {
        id: "default-is-course-filter",
        name: Translate.get("Courses"),
        description: Translate.get("DefaultCoursesFilterDescription"),
        filterModel: {
          items: [
            {
              id: 1003,
              field: "isCourse",
              operator: "is",
              value: true,
            },
          ],
          logicOperator: "and",
        },
      },
    ],
    []
  );

  async function handleMultiDotMenuActionOnClick(actions) {
    const results = await Promise.all(
      actions.map(async (action) => {
        const result = await action.onClick();
        return { ...result, orderId: action.orderId };
      })
    );

    const failedResults = results.filter((r) => !r.isSuccessful);
    const failedResultsAndOrders = failedResults.map((r) => ({
      result: r,
      order: pagination.rows.find((order) => order.orderId === r.orderId),
    }));
    if (failedResultsAndOrders.length > 0) {
      const errorMessage = failedResultsAndOrders
        .map(
          ({ result, order }) =>
            `${order.orderNo}, ${order.actorName} (${formatDate(
              order.regDate
            )}):\n${Translate.get(result.errorMessageTranslationKey)}`
        )
        .join("\n\n");
      setLongErrorMessage(errorMessage);
    }

    if (results.some((r) => r.isSuccessful)) {
      setSelectedOrderIds([]);
    }
  }

  function getSelectionActions() {
    const selectedOrders = pagination.rows.filter((order) =>
      selectedOrderIds.includes(order.orderId)
    );

    // We get the normal per-order dot menu actions and group them
    // for simultaneous exection of each action individually
    const dotMenuActions = selectedOrders
      .flatMap((order) =>
        getDotMenuActions(order, true).map((action) => ({
          ...action,
          orderId: order.orderId,
        }))
      )
      .filter((action) => !action.hide);
    const groupedActions = groupBy(dotMenuActions, (action) => action.id);
    const mergedActions = Object.entries(groupedActions).map(
      ([id, actions]) => ({
        icon: actions[0].icon,
        text: actions[0].text,
        onClick: async () => await handleMultiDotMenuActionOnClick(actions),
        disabled:
          actions.length !== selectedOrders.length ||
          actions.some((a) => a.disabled),
        disabledTooltip: Translate.get("GroupActionUnavailableForSome"),
      })
    );

    // Then we also have some actions wich are specifically for the multi-order
    // scenario, i.e. these are not available in an individual orders dot menu
    const multiActions = getMultiMenuActions(selectedOrders);
    return [...mergedActions, ...multiActions];
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <OrdersGridDetailsPanel
        orderLines={row.orderLines}
        currency={row.currency}
      />
    ),
    []
  );

  return (
    <>
      {ordersToMerge && ordersToMerge.length > 0 && (
        <MergeOrdersDialog
          orders={ordersToMerge}
          onClose={() => setOrdersToMerge(null)}
          onMerged={() => {
            setOrdersToMerge(null);
            reloadData();
          }}
        />
      )}
      {pickOrderDetails && (
        <PickOrderDialog
          orderId={pickOrderDetails.orderId}
          startPick={!pickOrderDetails.hasStartedPick}
          onClose={() => {
            setPickOrderDetails(null);
            reloadData();
          }}
          markForInvoice={markForInvoice}
        />
      )}
      {hasAccess(accessKeys.isMASystem) && (
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <DataGridEasyFilters
            filters={easyFilters}
            currentFilterModel={pagination.filterModel}
            onSelectFilter={(filter) => {
              pagination.onFilterModelChange(filter?.filterModel);
              pagination.onSortModelChange(filter?.sortModel);
            }}
          />
        </Box>
      )}
      {selectedOrderIds && selectedOrderIds.length > 0 && (
        <Toolbar className="orderToolBar">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
                {`${selectedOrderIds.length} ${Translate.get(
                  "ePort2Selected"
                )}`}
              </Box>
              <LinkMenu content={getSelectionActions()} />
            </Stack>
            <Tooltip title={Translate.get("DeselectAll")}>
              <IconButton onClick={() => setSelectedOrderIds([])}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      )}
      <DataGrid
        className={gridClassName}
        columns={columns}
        disableSelectionOnClick
        getRowId={(row) => row.orderId}
        autoHeight={true}
        componentsProps={{
          columnsPanel: { className: "customColumnPanel" },
          filterPanel: { className: "customfilterPanel" },
          panel: { className: "customPanel" },
        }}
        localeText={localizedTextsMap}
        disableDensitySelector={true}
        disableColumnMenu
        onRowSelectionModelChange={(newSelectedOrderIds) => {
          setSelectedOrderIds(newSelectedOrderIds);
        }}
        rowSelectionModel={selectedOrderIds}
        hideFooterSelectedRowCount
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
        slots={{
          detailPanelExpandIcon: KeyboardArrowRight,
          detailPanelCollapseIcon: KeyboardArrowDown,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
        }}
        initialState={{
          columns: {
            columnVisibilityModel: { courseId: false, isCourse: false },
          },
        }}
        {...pagination}
      />
      <AlertDialog
        titleText={Translate.get("SomethingFailed")}
        bodyText={longErrorMessage}
        buttonText={Translate.get("Ok")}
        open={!!longErrorMessage}
        onClose={() => {
          setLongErrorMessage(null);
          reloadData();
        }}
      />
    </>
  );
}
