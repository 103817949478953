import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState, useEffect } from "react";
import Translate from "../../../utils/Translate";
import { useApi } from "../../../utils/Api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Preloader from "../../Preloader";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import { formatDate } from "../../../utils/dateTimeFormat";

export default function LicenseAgreementApprovalDialog({
  open,
  onClose,
  onComplete,
  user,
  accountLoginToken,
}) {
  if (open && (!user.hasUnapprovedLicenseAgreements || !accountLoginToken))
    throw new Error();

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [licenseAgreements, setLicenseAgreements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const api = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      const url = `${
        process.env.REACT_APP_AUTH_URL
      }licenses/agreements/unapproved?ePortUserId=${user.ePortUserId}`;

      api.fetch(url, false, "GET", `Bearer ${accountLoginToken}`).then(
        (data) => {
          setIsLoading(false);
          if (
            data.isSuccessful &&
            data.licenseAgreements &&
            data.licenseAgreements.length > 0
          ) {
            setLicenseAgreements(data.licenseAgreements);
            setCurrentIndex(0);
          } else {
            const errorMessage = enqueueSnackbar(
              Translate.get(
                data.errorMessageTranslationKey ?? "SomethingFailed"
              ),
              {
                variant: "error",
                autoHideDuration: 6000,
                onClick: () => closeSnackbar(errorMessage),
              }
            );
            navigate("/", { replace: true });
          }
        }
      );
    }
  }, [open, user, accountLoginToken, enqueueSnackbar, closeSnackbar, navigate, api]);

  async function sendApproveCurrentLicenseAgreement() {
    const url = `${
      process.env.REACT_APP_AUTH_URL
    }licenses/agreements/approve?ePortUserId=${user.ePortUserId}`;

    const requestData = {
      licenseAgreementId: licenseAgreements[currentIndex].licenseAgreementId,
      version: licenseAgreements[currentIndex].version,
    };

    await api.fetch(
      url,
      requestData,
      "POST",
      `Bearer ${accountLoginToken}`
    );
  }

  async function handleApproveCurrentLicenseAgreement() {
    await sendApproveCurrentLicenseAgreement();
    if (currentIndex + 1 < licenseAgreements.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      onComplete();
    }
  }

  return (
    <>
      {open && isLoading && <Preloader />}
      {open && !isLoading && (
        <Dialog open={open} onClose={onClose} maxWidth="md">
          {licenseAgreements[currentIndex] && <DialogTitle></DialogTitle>}
          <DialogContent>
            {currentIndex >= 0 && (
              <>
                <p>
                  {`${Translate.get("LatestUpdateDate")}: ${
                    licenseAgreements[currentIndex] &&
                    formatDate(
                      licenseAgreements[currentIndex].licenseAgreementDate
                    )
                  }`}
                </p>
                <br />
                <div
                  /* Vet ej om denna ska vara exakt samma styling som hjälpen, men funkar ok */
                  className="helpContentBox"
                  dangerouslySetInnerHTML={{
                    __html: licenseAgreements[currentIndex].text,
                  }}
                ></div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner
              variant="contained"
              onClick={handleApproveCurrentLicenseAgreement}
            >
              {`${Translate.get("ApproveB")}${
                licenseAgreements.length > 1
                  ? ` ${currentIndex + 1}/${licenseAgreements.length}`
                  : ""
              }`}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
