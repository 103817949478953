import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import SkillItem from "../../skills/SkillItem";
import SkillItemGrid from "../../skills/SkillItemGrid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddOrEditSkillDialog from "./AddOrEditSkillDialog";
import YesOrNoDialog from "../../YesOrNoDialog";

export default function SkillGroupSkillsDialog({ skillGroupId }) {
  const [showCreateSkillDialog, setShowCreateSkillDialog] = useState(false);
  const [skillIdToEdit, setSkillIdToEdit] = useState(null);
  const [skillIdToDelete, setSkillIdToDelete] = useState(null);
  const [skills, setSkills] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const api = useApi();

  const location = useLocation();
  const navigate = useNavigate();

  function close() {
    // navigate(-1);
    window.history.back();
  }

  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  function handleClose() {
    if (isUpdated) {
      closeAndReload();
    } else {
      close();
    }
  }

  const loadSkillGroup = useCallback(
    async (skillGroupId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}skillgroups/1/${skillGroupId}?includeSkills=true`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setSkills(response.skills);
      }
      setIsDataFetched(true);
    },
    [api]
  );

  useEffect(() => {
    loadSkillGroup(skillGroupId);
  }, [api, skillGroupId]);

  const getDotMenuActions = (skillId) => {
    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditSkill"),
        onClick: () => setSkillIdToEdit(skillId),
      },
      {
        icon: <DeleteIcon />,
        text: Translate.get("RemoveSkill"),
        onClick: () => setSkillIdToDelete(skillId),
      },
    ];
    return menudata;
  };

  async function handleDeleteSkill() {
    if (!skillIdToDelete) {
      throw new Error("Missing SkillId!");
    }

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skills/${skillIdToDelete}`,
      true,
      "DELETE"
    );
    if (response.isSuccessful) {
      // Update locally immediately but also reload data
      setSkills((prev) => prev.filter((s) => s.skillId !== skillIdToDelete));
      setSkillIdToDelete(null);
      loadSkillGroup(skillGroupId);
    }
  }

  return (
    <>
      {!isDataFetched && <Preloader />}
      {/* Create and edit use the same dialog */}
      {(showCreateSkillDialog || !!skillIdToEdit) && (
        <AddOrEditSkillDialog
          skillGroupId={skillGroupId}
          existingSkill={
            !!skillIdToEdit
              ? skills.find((s) => s.skillId === skillIdToEdit)
              : null
          }
          onClose={() => {
            setShowCreateSkillDialog(false);
            setSkillIdToEdit(null);
          }}
          onSaved={() => {
            setShowCreateSkillDialog(false);
            setSkillIdToEdit(null);
            loadSkillGroup(skillGroupId);
            setIsUpdated(true);
          }}
        />
      )}
      {isDataFetched && (
        <>
          <Box
            sx={{
              position: "absolute",
              right: 8,
              top: 14,
            }}
          >
            <Button
              onClick={() => setShowCreateSkillDialog(true)}
              startIcon={<AddIcon />}
            >
              {Translate.get("AddSkillGroupSkillLabel")}
            </Button>
          </Box>
          <DialogTitle>{Translate.get("EditSkills")}</DialogTitle>
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {skills.length === 0 && (
                  <Typography
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {Translate.get("NoSkillsAddedYetInfo")}
                  </Typography>
                )}
                {skills.length > 0 && (
                  <SkillItemGrid
                    items={skills.map((skill) => (
                      <SkillItem
                        isSelected={skill.isInCourseTemplate}
                        onClick={() => {}}
                        isSmall={!isSmallScreenOrGreater}
                        imageUrl={skill.imageUrl}
                        label={`${skill.shortCode} ${skill.description}`}
                        extraActions={getDotMenuActions(skill.skillId)}
                      />
                    ))}
                  />
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{Translate.get("Close")}</Button>
          </DialogActions>
        </>
      )}
      <YesOrNoDialog
        open={!!skillIdToDelete}
        yesText={Translate.get("Delete")}
        noText={Translate.get("Cancel")}
        title={Translate.get("RemoveSkill")}
        text={Translate.get("SureRemoveSkill")}
        onYes={handleDeleteSkill}
        onNo={() => setSkillIdToDelete(null)}
      />
    </>
  );
}
