import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
    {/*   <HeaderStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 0.5, sm: 1.5 }}
        ></Stack>
      </HeaderStyle> */}
      <Outlet />
    </>
  );
}
