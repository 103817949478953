import {
  Checkbox,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import { useState } from "react";
import "./ActorLicensePrint.css";
import SearchIcon from "@mui/icons-material/Search";
import ActorAvatar from "../ActorAvatar";
import IconAvatar from "../IconAvatar";
import ActorLicensePreviewDialog from "./ActorLicensePreviewDialog";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import WarningIcon from "@mui/icons-material/Warning";
import Translate from "../../utils/Translate";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import {
  validateDigitalLicenseReceiver,
  validateDigitalLicenseGroupContact,
} from "../actor-permits/validation/ActorValidator";
import DotMenu from "../DotMenu";
import PersonCrud from "../actors/crud/PersonCrud";
import EditIcon from "@mui/icons-material/Edit";
import { licenseFormatTypeEnum } from "../../utils/licenseFormatTypes";
import { Info } from "@mui/icons-material";

export default function ActorLicensePrintList({
  studentDetails,
  digitalLicensePreviews,
  selectedActorLicenseIds,
  onSelect,
  disableSelection,
  printTemplate,
  sidePrintMode,
  printType,
  booking,
  showContact,
  contact,
  licenseFormatType,
  onStudentChange,
  onContactChange,
  onSendPublishGroupContactEmailChange,
}) {
  const [showPreviewDialogId, setShowPreviewDialogId] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editStudentIndex, setEditStudentIndex] = useState(null);

  const getContactDotMenu = () => {
    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        onClick: () => setEditContact(true),
      },
    ];
    return menudata;
  };

  return (
    <>
      {!!showPreviewDialogId && (
        <ActorLicensePreviewDialog
          printTemplate={printTemplate}
          studentDetails={studentDetails.find(
            (s) => s.actorLicenseId === showPreviewDialogId
          )}
          printFront={sidePrintMode === "both" || sidePrintMode === "front"}
          printBack={sidePrintMode === "both" || sidePrintMode === "back"}
          open={!!showPreviewDialogId}
          onClose={() => setShowPreviewDialogId(null)}
          printType={printType}
          licenseFormatType={licenseFormatType}
          digitalLicensePreview={
            digitalLicensePreviews &&
            digitalLicensePreviews.find(
              (d) => d.actorLicenseId === showPreviewDialogId
            )
          }
        />
      )}
      {editStudentIndex !== null && (
        <PersonCrudDialog
          open={true}
          personCrudProps={{
            actorId: studentDetails[editStudentIndex].actorId,
            // actorRole: "student",
            isEditOnly: true,
            isMatchingActorsDisabled: true,
            onClose: () => setEditStudentIndex(null),
            onActorSaved: onStudentChange,
          }}
        />
      )}
      <TableContainer className="licensePrintStudentList">
        <Table>
          <TableBody>
            {!!booking && (
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{ padding: "14px" }}
                  component="th"
                  scope="row"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      margin: "1em 10px 2px 0px",
                      textTransform: "uppercase",
                    }}
                  >{`${booking.companyName} (${studentDetails.length})`}</Typography>
                </TableCell>
              </TableRow>
            )}
            {!!showContact && (
              /*               <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{ padding: "14px" }}
                  component="th"
                  scope="row"
                >
                  <Box fontSize={10}>
                    {`TODO kontakt? ` +
                      (!!contact
                        ? `${contact.actorId}, ${contact.actorName}, ${contact.phone}, ${contact.email}`
                        : "")}
                  </Box>
                </TableCell>
              </TableRow> */

              <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                <TableCell className="shrink" align="left">
                  <Checkbox
                    disabled={
                      !!validateDigitalLicenseGroupContact(contact.email)
                    }
                    checked={booking.sendPublishGroupContactEmail}
                    onChange={onSendPublishGroupContactEmailChange}
                  />
                </TableCell>
                <TableCell align="left" className="hideOverflow">
                  <IconAvatar
                    description={"Intyg till kontaktperson"}
                    name={contact.actorName}
                    email={contact.email}
                    //extraFields={[contact.phone]}
                    icon={<HeadsetMicIcon />}
                    //iconColor='#fafafa'
                    //iconBgColor='C4CDD5'
                  />
                  {!!validateDigitalLicenseGroupContact(contact.email) && (
                    <div
                      style={{
                        marginLeft: "52px",
                      }}
                      className="smallRedWarning"
                    >
                      <WarningIcon fontSize="8" />
                      <div>
                        {validateDigitalLicenseGroupContact(contact.email)}
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell className="shrink" align="right">
                  <DotMenu content={getContactDotMenu()} />
                </TableCell>
              </TableRow>
            )}
            {studentDetails.map((s, i) => (
              <TableRow key={s.actorLicenseId}>
                {!disableSelection && (
                  <TableCell className="shrink" align="left">
                    <Checkbox
                      checked={
                        !!selectedActorLicenseIds.find(
                          (c) => c === s.actorLicenseId
                        )
                      }
                      disabled={
                        !!s?.error ||
                        (s.digitalLicenseId &&
                          licenseFormatType === licenseFormatTypeEnum.digital)
                      }
                      onChange={() => onSelect(s.actorLicenseId)}
                    />
                  </TableCell>
                )}
                <TableCell align="left" className="hideOverflow">
                  <ActorAvatar
                    name={s.actorName}
                    email={s.actorEmail}
                    profilePictureUrl={s.actorImage}
                    extraFields={[s.phone, s.personalNo]}
                  />
                  {!!s?.error && (
                    <div
                      onClick={() => setEditStudentIndex(i)}
                      style={{
                        marginLeft: "52px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      className="smallRedWarning"
                    >
                      <WarningIcon fontSize="8" />
                      <div>{s.error}</div>
                    </div>
                  )}
                  {s.digitalLicenseId &&
                    (licenseFormatType === licenseFormatTypeEnum.digital ||
                      licenseFormatType === licenseFormatTypeEnum.both) && (
                      <div
                        style={{
                          marginLeft: "52px",
                        }}
                        className="smallInfo"
                      >
                        <Info fontSize="8" color="primary" />
                        <div>
                          {licenseFormatType === licenseFormatTypeEnum.both
                            ? Translate.get(
                                "OnlyPhysicalLicenseAlreadyHasDigitalLicense"
                              )
                            : Translate.get("AlreadyHasDigitalLicense")}
                        </div>
                      </div>
                    )}
                </TableCell>
                <TableCell className="shrink" align="right">
                  <IconButton
                    onClick={() => setShowPreviewDialogId(s.actorLicenseId)}
                  >
                    <SearchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
