// material
import { useState,useEffect} from "react";
import { Grid, Container, Button } from "@mui/material";
// components
import Page from "../components/Page";
import Translate from "../utils/Translate";
import OverallStats from "../components/_dashboard/app/OverallStats";
import { useApi } from "../utils/Api";
import Preloader from "../components/Preloader";
import PeopleIcon from "@mui/icons-material/People";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import { accessKeys, hasAccess } from "../utils/userAccess";

import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { Card } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ActorPermitFlowDialogContainer from "../components/actor-permits/ActorPermitFlowDialogContainer";
//import ReactGA from "react-ga4";
const speedMenuActions = [
  {
    icon: <AddIcon />,
    text: Translate.get("CreateActorPermit"),
    dialogHeader: Translate.get("CreateActorPermit"),
    component: <ActorPermitFlowDialogContainer />,
  },
];

// ----------------------------------------------------------------------
//Mock data start

const fixStats = (data) => {
  const overAllStats = {
    nbrOfPersons: data.actorStatistics.totalNumberOfActors,
    actorPermits: data.actorPermitStatistics.actorPermits,
    nonFullySignedPermits: data.actorPermitStatistics.nonFullySignedPermits,
    validPermits:data.actorPermitStatistics.validPermits,
  };

  console.log("overAllStats", data);

  return {
    overAllStats: overAllStats,
  };
};

export default function DashboardApp() {
  const [dataFetched, setDataFetched] = useState(false);
  const [init, setInit] = useState(false);
  const [baseStats, setBaseStats] = useState(false);
  // nyheter visas inte just nu
  //const [newsFeed, setNewsFeed] = useState(null);
  const [open, setOpen] = useState(true);
  const api = useApi();

  useEffect(() => {
    window.global.helpLink = {
      cat: "/dashboard/permits",
      //subCat: "permits_checklist",
    };
  }, []);


  const buttonAction = () => {
    window.global.helpLink = {
      cat: "/dashboard/permits",
      subCat: "permits_checklist",
    };
  window.globalTriggerClick("helpTrigger");
     /*  ReactGA.event({
      category: "eport_xtras",
      action: "reading_permit_help",
      label: "eport_label", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    }); */
    window.analyticsEvent("eport_xtras","eport_help_permit");
  };
  const NoticeBox = ({ link = false, text, target = "_self" }) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={false}
            sx={{
              mb: 2,
              "& .MuiAlert-message": {
                width: "100%",
                textAlign: "center",
                padding: 0,
              },
            }}
          >
            <div className="stdFlexCenter">
              <StarIcon color="success" />
              {text}
              <Button
                variant="contained"
                color="success"
                size="small"
                sx={{ color: "white" }}
                //href={link ? link : null} //Externa länkar
                target={target}
                onClick={() => (!link ? buttonAction() : false)} //
                component={Link}
                to={link ? link : false}
              >
                {Translate.get("LearnMore")}
              </Button>
            </div>
          </Alert>
        </Collapse>
      </Box>
    );
  };
  const ButtonCard = ({ icon, title, text, link, disabled = false, buttonText=false}) => {
    return (
      <Card className="centerCard">
       {/*  <CardActionArea  href={link} disabled={disabled}> */}
          <CardHeader
             titleTypographyProps={{
              fontSize: 24,
            }}
            sx={{ padding: "16px"}}
            avatar={<Avatar  sx={{height:'auto', width:'auto',color:'black',backgroundColor:'transparent'}} >{icon}</Avatar>}
            title={title}
            //subheader={text}
          />

          <CardContent className="centerCardContent">
            <div>{text}</div>
          <div>
           {/*  Hela button historien är ful, borde inte vara hårdkodad */}
          {(buttonText && link) && <Button  href={link}  variant="outlined" size="medium">{buttonText}</Button>}
          {(buttonText && !link) && <Button  onClick={() => window.globalTriggerClick('Actionmenu-action-0-label')}   variant="outlined" size="medium">{buttonText}</Button>}
          {!buttonText && <div></div>}
          </div>
          </CardContent>
          {/*<CardActions>
        <Button size="small">{Translate.get("FindActorPermit")}</Button>
      </CardActions> */}
       {/*  </CardActionArea> */}
      </Card>
    );
  };
  const getBaseStats = () => {
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}dashboard/`, false, "GET")
      .then((data) => {
        /*  setDataFetched(true) */
        if (data) {
          setBaseStats(fixStats(data));
        } else {
          setBaseStats({
            overAllStats: {
              nbrOfPersons: "0",
              coursesTotal: "0",
              totalNumberOfLicenses: "0",
            },
          });
        }
      });
  };

  if (!init) {
    getBaseStats();
    setInit(true);
  }

  if (!init || !baseStats) {
    return <Preloader invisible={true} />;
  }

  return (
    <>
      <Page>
      <h1 style={{color:'rgba(0, 0, 0, 0.26)'}}>{Translate.get("Hello")} {sessionStorage.userFullName}</h1>
        {!init && <Preloader invisible={true} />}
        <Container
          //maxWidth="xl"
          maxWidth={false}
          className="dashboard forceFooter"
          disableGutters={true}
        >
        {/*   <NoticeBox
            link="/dashboard/flyer/"
            text={Translate.get("UppgradeToAndvancedTxt")}
          /> */}
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            sx={{ alignItems: "flex-start" }}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12} sm={6} md={6} xl={4}>
              <ButtonCard
                icon={<AddCircleOutlineIcon sx={{width:'30px',height:'30px'}} />}
                title={Translate.get("CreateActorPermit")}
                text={Translate.get("CreateActorPermitTxt2")}
                link={false}
                //disabled={true}
                buttonText={Translate.get('CreateActorPermit')}

              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={4}>
              <ButtonCard
                icon={<ReceiptLongIcon  sx={{width:'30px',height:'30px'}}  />}
                title={Translate.get("FindActorPermit")}
                text={Translate.get("FindActorPermitTxt")}
                link="/dashboard/permits/"
                buttonText={Translate.get('GoToKs')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={4}>
              <ButtonCard
                icon={<FolderCopyIcon  sx={{width:'30px',height:'30px'}}  />}
                title={Translate.get("FindInRegister")}
                text={Translate.get("FindInRegisterTxt")}
                link="/dashboard/actors/"
                buttonText={Translate.get('goToReg')}
              />
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} sm={6} md={3} xl={3}>
              <OverallStats
                icon={<PeopleIcon />}
                value={baseStats.overAllStats.nbrOfPersons}
                text={Translate.get("NbrOfPersons")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <OverallStats
                icon={<ReceiptLongIcon />}
                value={baseStats.overAllStats.actorPermits}
                text={Translate.get("ActorPermits")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <OverallStats
                icon={<AccessTimeIcon />}
                value={baseStats.overAllStats.nonFullySignedPermits}
                text={Translate.get("NonFullySignedPermits")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <OverallStats
                icon={<DateRangeIcon />}
                value={baseStats.overAllStats.validPermits}
                text={Translate.get("AcivePermits")}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <NoticeBox text={Translate.get("PermitsRulesTxt")} />
            </Grid> */}
            <Grid item xs={12}>
              <div className="permitRules">
                <div></div>
                <div className="prBlurArea">
                  <div>{Translate.get("Guide")}</div>
                  <div>
                  {Translate.get("PermitsHelpText")}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{ color: "white" }}
                      onClick={() => buttonAction()}
                    >
                      {Translate.get("LearnMore")}
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="pageFooter">
          <div>
            <div><a href="https://www.masystemutbildning.se/" target="'_blank"><img src="/static/ma_system.png"  height={'48px'}/></a></div>
            <div>
              <b>kortillstand.se</b> {Translate.get("MaSiteLink")} <a href="https://www.masystemutbildning.se/" target="'_blank">MA-system Utbildning AB</a>.
             <br/>{Translate.get("MoreInfoMail")} <a href="mailto:education@masystem.se">education@masystem.se</a>.
            </div>
          </div>
          <div>
           {/*  <div><a href="https://www.masystemutbildning.se/" target="'_blank">MA-system Utbildning AB</a></div> */}
          </div>
        </div>
      </Page>

      <SpeedMenu content={speedMenuActions} />
    </>
  );
}
