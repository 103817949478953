import { MoreHoriz } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export default function MenuToggleButton({ label, states, value }) {
  if (states && states.some((s) => !s.icon)) {
    throw new Error("Missing state icon");
  }
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
      <LoadingButton
        disabled={!states || states.length === 0}
        loading={isLoading}
        startIcon={
          value ? states.find((s) => s.value === value).icon : <MoreHoriz />
        }
        variant="outlined"
        id="basic-button"
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </LoadingButton>
      <Menu
        open={!!menuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {states &&
          states.map((state) => (
            <MenuItem
              onClick={async () => {
                setAnchorEl(null);
                setIsLoading(true);
                await state.onClick();
                setIsLoading(false);
              }}
            >
              <ListItemIcon>{state.icon}</ListItemIcon>
              <ListItemText>{state.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
