import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import { useSnackbar } from "notistack";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import { actorSearch } from "../../../utils/actorSearch";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import YesOrNoDialog from "../../YesOrNoDialog";
import { useMediaQuery } from "@mui/material";
import DuplicateActorSuggestionsDialog from "./DuplicateActorSuggestionsDialog";

function isSameAddress(address1, address2) {
  return (
    address1.actorAddressType === address2.actorAddressType &&
    address1.actorAddressStreet2 === address2.actorAddressStreet2 &&
    address1.actorAddressZipCode === address2.actorAddressZipCode &&
    address1.actorAddressCity === address2.actorAddressCity &&
    address1.actorAddressCountry === address2.actorAddressCountry
  );
}

// In some cases addresses are added automatically, with nothing but actorAddressStreet1
// set to the actor's name. These will be considered empty here.
function isEmptyAddress(address) {
  if (!address) return true;

  for (const property in address) {
    const isRelevantProperty =
      property !== "actorId" &&
      property !== "actorAddressId" &&
      property !== "actorAddressType" &&
      property !== "actorAddressStreet1";
    if (isRelevantProperty && address[property]) {
      return false;
    }
  }
  return true;
}

function isSamePhone(phone1, phone2) {
  return (
    phone1.actorPhoneType === phone2.actorPhoneType &&
    phone1.actorPhoneNumber === phone2.actorPhoneNumber
  );
}

// In some cases empty phone numbers are added automatically, these will be considered empty here.
function isEmptyPhone(phone) {
  if (!phone) return true;

  for (const property in phone) {
    const isRelevantProperty =
      property !== "actorId" &&
      property !== "actorPhoneId" &&
      property !== "actorPhoneType";
    if (isRelevantProperty && phone[property]) {
      return false;
    }
  }
  return true;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function DuplicateActorMerger({
  actorId1,
  actorId2,
  descriptionText = "",
  showKeepBothButton = false,
}) {
  const [actor1, setActor1] = useState({});
  const [actor2, setActor2] = useState({});
  const [selectedValueActorIds, setSelectedValueActorIds] = useState({});
  const [selectedAddressesByType, setSelectedAddressesByType] = useState({});
  const [selectedPhonesByType, setSelectedPhonesByType] = useState({});
  const [errors, setErrors] = useState({});
  const [isValidatingValue, setIsValidatingValue] = useState(false);
  const [hasValidatedActors, setHasValidatedActors] = useState(false);
  const [showNavigateQuestion, setShowNavigateQuestion] = useState(false);
  const [showSuggestionsDialog, setShowSuggestionsDialog] = useState(false);
  const [duplicateSuggestionsCount, setDuplicateSuggestionsCount] =
    useState(null);
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const smallScreen = useMediaQuery("(max-width:700px)");

  function handleNavigateToResult() {
    if (actor1.actorTypeIds?.some((a) => a === 1)) {
      navigate(`/dashboard/company/${actor1.actorId}`, { replace: false });
    } else {
      navigate(`/dashboard/person/${actor1.actorId}`, { replace: false });
    }
  }

  const loadActor = useCallback(async (actorId) => {
    var result = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${actorId}`,
      false,
      "GET"
    );
    return result;
  }, []);

  useEffect(() => {
    if (actorId1) {
      loadActor(actorId1).then((result) => setActor1(result));
    }
  }, [actorId1, loadActor]);

  useEffect(() => {
    if (actorId2) {
      loadActor(actorId2).then((result) => setActor2(result));
    }
  }, [actorId2, loadActor]);

  useEffect(() => {
    if (actor1.actorId && actor2.actorId) {
      const req = {
        actorId1: actor1.actorId,
        actorId2: actor2.actorId,
      };
      api
        .fetch(`${process.env.REACT_APP_MAIN_URL}actors/merge/validate`, req)
        .then((response) => {
          const validationOk =
            response && response.isSuccessful && response.validationOk;
          setErrors((oldErrors) => ({
            ...oldErrors,
            actors: validationOk
              ? null
              : response.errorMessageTranslationKey ??
                Translate.get("ActorMergeNotPossibleSelectedActors"),
          }));
          setHasValidatedActors(true);
        });
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, actors: null }));
      setHasValidatedActors(false);
    }
  }, [actor1, actor2]);

  useEffect(() => {
    async function countDuplicateSuggestions() {
      const count = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/duplicates/count`,
        false,
        "GET"
      );
      setDuplicateSuggestionsCount(count);
    }
    countDuplicateSuggestions();
  }, [api]);

  function validateSelectedValue(propertyName, actorId) {
    setErrors({ ...errors, [propertyName]: null });
    setIsValidatingValue(true);

    const req = {
      actorId1: actor1.actorId,
      actorId2: actor2.actorId,
      selectedValueActorIds: { [capitalizeFirstLetter(propertyName)]: actorId },
    };
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/merge/validate-values`,
        req
      )
      .then((response) => {
        const validationOk =
          response && response.isSuccessful && response.validationOk;
        setErrors({
          ...errors,
          [propertyName]: validationOk
            ? null
            : response.errorMessageTranslationKey ??
              Translate.get("ActorMergeNotPossibleSelectedValue"),
        });
        setIsValidatingValue(false);
      });
  }

  async function mergeActors() {
    if (!actor1.actorId || !actor2.actorId) throw new Error();

    const req = {
      actorId1: actor1.actorId,
      actorId2: actor2.actorId,
      selectedValueActorIds: Object.fromEntries(
        Object.entries(selectedValueActorIds).map((kv) => [
          capitalizeFirstLetter(kv[0]),
          kv[1],
        ])
      ),
      selectedAddressesByType,
      selectedPhonesByType,
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/merge`,
      req
    );
    if (response && response.isSuccessful) {
      setShowNavigateQuestion(true);
    }
  }

  const getActorTypeToSearch = useCallback(() => {
    if (actor1.actorId) {
      return actor1.actorTypeIds?.some((a) => a === 1) ? [1] : [0, 3, 4, 6, 9];
    } else if (actor2.actorId) {
      return actor2.actorTypeIds?.some((a) => a === 1) ? [1] : [0, 3, 4, 6, 9];
    } else {
      return [0, 1, 3, 4, 6, 9];
    }
  }, [actor1, actor2]);

  const actorNameSearch = useCallback(
    async (searchText) => {
      const foundActors = await actorSearch(
        searchText,
        getActorTypeToSearch(),
        ["ActorName", "OrgNo", "ActorEmail"],
        50
      );
      if (foundActors) {
        return foundActors.map((actor) => ({
          actorId: actor.actorId,
          actorName: actor.actorName,
          orgNo: actor.orgNo,
          email: actor.email,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId > 0 &&
                x.parentActor.actorId !== actor.actorId &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
      }
    },
    [getActorTypeToSearch]
  );

  async function handleActorChange(actor, setActor) {
    if (actor && actor.actorId > 0) {
      // Set the incomplete actor data from search to update
      // UI already before loading of complete actor finishes
      setActor(actor);
      var loadedActor = await loadActor(actor.actorId);
      setActor(loadedActor);
    } else {
      setActor({
        actorName: actor?.actorName,
      });
      setSelectedValueActorIds({});
      setSelectedAddressesByType({});
      setSelectedPhonesByType({});
    }
  }

  async function handleSelectDuplicateSuggestion(duplicate) {
    await handleActorChange(duplicate.actor1, setActor1);
    await handleActorChange(duplicate.actor2, setActor2);
  }

  function selectValue(propertyName, actorId) {
    const selectedValuesCopy = {
      ...selectedValueActorIds,
      [propertyName]: actorId,
    };
    setSelectedValueActorIds(selectedValuesCopy);
    validateSelectedValue(propertyName, actorId);
  }

  function selectAddress(address) {
    const selectedAddressesCopy = {
      ...selectedAddressesByType,
      [address.actorAddressType]: address.actorAddressId,
    };
    setSelectedAddressesByType(selectedAddressesCopy);
  }

  function selectPhone(phone) {
    const selectedPhonesCopy = {
      ...selectedPhonesByType,
      [phone.actorPhoneType]: phone.actorPhoneId,
    };
    setSelectedPhonesByType(selectedPhonesCopy);
  }

  const Address = useCallback(
    ({ address }) => (
      <>
        <Box>{address.actorAddressStreet2}</Box>
        <Box>
          {address.actorAddressZipCode}, {address.actorAddressCity}
        </Box>
      </>
    ),
    []
  );

  function createField(displayName, propertyName) {
    return {
      displayName,
      propertyName,
      isVisible:
        actor1[propertyName] &&
        actor2[propertyName] &&
        actor1[propertyName] !== actor2[propertyName],
      render: (actor) => actor[propertyName],
      isSelected: (actor) =>
        selectedValueActorIds[propertyName] === actor.actorId,
      select: (actor) => selectValue(propertyName, actor.actorId),
    };
  }

  function createAddressField(propertyName) {
    return {
      displayName: Translate.getActorAddressType(
        actor1[propertyName]?.actorAddressType
      ),
      propertyName,
      isVisible:
        !isEmptyAddress(actor1[propertyName]) &&
        !isEmptyAddress(actor2[propertyName]) &&
        !isSameAddress(actor1[propertyName], actor2[propertyName]),
      render: (actor) => <Address address={actor[propertyName]} />,
      isSelected: (actor) =>
        actor[propertyName] &&
        selectedAddressesByType[actor[propertyName].actorAddressType] ===
          actor[propertyName].actorAddressId,
      select: (actor) => selectAddress(actor[propertyName]),
    };
  }

  function createPhoneField(propertyName) {
    return {
      displayName: Translate.getActorPhoneType(
        actor1[propertyName]?.actorPhoneType
      ),
      propertyName,
      isVisible:
        !isEmptyPhone(actor1[propertyName]) &&
        !isEmptyPhone(actor2[propertyName]) &&
        !isSamePhone(actor1[propertyName], actor2[propertyName]),
      render: (actor) => actor[propertyName]?.actorPhoneNumber,
      isSelected: (actor) =>
        actor[propertyName] &&
        selectedPhonesByType[actor[propertyName].actorPhoneType] ===
          actor[propertyName].actorPhoneId,
      select: (actor) => selectPhone(actor[propertyName]),
    };
  }

  var fields =
    actor1.actorId && actor2.actorId && !errors.actors
      ? [
          createField(Translate.get("Name"), "actorName"),
          createField(Translate.get("Email"), "actorEmail"),
          createField(
            getActorTypeToSearch()?.some((t) => t === 1)
              ? Translate.get("OrgNo")
              : Translate.get("PersonalNo"),
            "orgNo"
          ),
          createField(Translate.get("BirthDate"), "birthDate"),
          createField(Translate.get("ExternalActorNo"), "externalActorNo"),
          createField(Translate.get("Website"), "webSite"),
          createField(Translate.get("VatNo"), "vatnr"),
          createField(
            Translate.get("DefaultCustomerOrderNo"),
            "defaultCustomerOrderNo"
          ),
          createField(Translate.get("ObservationNote"), "observationNote"),
          createAddressField("postalAddress"),
          createAddressField("invoiceAddress"),
          createPhoneField("mobilePhone"),
          createPhoneField("businessPhone"),
        ]
      : [];

  function hideOptionsFilter(option) {
    return (
      (actor1.actorId > 0 && option.actorId === actor1.actorId) ||
      (actor2.actorId > 0 && option.actorId === actor2.actorId)
    );
  }

  async function keepBothActors() {
    let response = null;
    let reqObj = { actorId1: actorId2, actorId2: actorId1 };
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/duplicate/delete`,
        reqObj,
        "POST",
        false,
        true
      );
      if (response.isSuccessful) {
        closeAndReload();
      }
    } catch (error) {
      const errorMessage = enqueueSnackbar(
        Translate.get(response.errorMessageTranslationKey || "SomethingFailed"),
        {
          variant: "error",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(errorMessage),
        }
      );
    }
    return response;
  }

  if (showSuggestionsDialog) {
    return (
      <DuplicateActorSuggestionsDialog
        handleSelect={handleSelectDuplicateSuggestion}
        open={true}
        closeDialog={() => setShowSuggestionsDialog(false)}
      />
    );
  }

  // Datorvy
  if (!smallScreen) {
    return (
      <>
        <DialogContent>
          <Typography sx={{ margin: "0 0 24px" }}>
            {descriptionText === ""
              ? Translate.get("ActorMergeInfo")
              : Translate.get(descriptionText)}
          </Typography>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="300px">
                    {/* Empty cell on purpose */}
                  </TableCell>
                  <TableCell>
                    <AutocompleteSearchField
                      placeholder={`${Translate.get("Name")} 1`}
                      label={`${Translate.get("Name")} 1`}
                      value={actor1}
                      onValueChange={(actor) =>
                        handleActorChange(actor, setActor1)
                      }
                      getOptionLabel={(option) => option?.actorName ?? ""}
                      renderOption={(option) => (
                        <ActorAutocompleteOption
                          actorName={option?.actorName}
                          orgNo={option?.orgNo}
                          email={option?.email}
                          companies={option?.companies}
                        />
                      )}
                      keyPropName="actorId"
                      requireSelection={true}
                      disableAddAsNew={true}
                      search={actorNameSearch}
                      createNewValue={(text) => ({
                        actorName: text,
                      })}
                      hideOptionsFilter={hideOptionsFilter}
                      minWidth="300px"
                      textFieldProps={{
                        error: errors.actors,
                        helperText: errors.actors,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <AutocompleteSearchField
                      placeholder={`${Translate.get("Name")} 2`}
                      label={`${Translate.get("Name")} 2`}
                      value={actor2}
                      onValueChange={(actor) =>
                        handleActorChange(actor, setActor2)
                      }
                      getOptionLabel={(option) => option?.actorName ?? ""}
                      renderOption={(option) => (
                        <ActorAutocompleteOption
                          actorName={option?.actorName}
                          orgNo={option?.orgNo}
                          email={option?.email}
                          companies={option?.companies}
                        />
                      )}
                      keyPropName="actorId"
                      requireSelection={true}
                      disableAddAsNew={true}
                      search={actorNameSearch}
                      createNewValue={(text) => ({
                        actorName: text,
                      })}
                      hideOptionsFilter={hideOptionsFilter}
                      minWidth="300px"
                      textFieldProps={{
                        error: errors.actors,
                        helperText: errors.actors && " ",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasValidatedActors &&
                  fields.map(
                    (field, index) =>
                      field.isVisible && (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row" align="left">
                            <Box fontWeight="bold">{field.displayName}</Box>
                          </TableCell>
                          <TableCell align="left">
                            <Grid container direction="column">
                              <Grid
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Radio
                                    color={
                                      errors[field.propertyName]
                                        ? "error"
                                        : "default"
                                    }
                                    checked={field.isSelected(actor1)}
                                    onChange={() => field.select(actor1)}
                                    name={`radio-buttons-${field.propertyName}`}
                                  />
                                </Grid>
                                <Grid item>{field.render(actor1)}</Grid>
                              </Grid>
                              {errors[field.propertyName] && (
                                <Grid item>
                                  <FormHelperText
                                    error
                                    sx={{ marginLeft: "12px" }}
                                  >
                                    {field.isSelected(actor1)
                                      ? errors[field.propertyName]
                                      : " "}
                                  </FormHelperText>
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid container direction="column">
                              <Grid
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Radio
                                    color={
                                      errors[field.propertyName]
                                        ? "error"
                                        : "default"
                                    }
                                    checked={field.isSelected(actor2)}
                                    onChange={() => field.select(actor2)}
                                    name={`radio-buttons-${field.propertyName}`}
                                  />
                                </Grid>
                                <Grid item>{field.render(actor2)}</Grid>
                              </Grid>
                              {errors[field.propertyName] && (
                                <Grid item>
                                  <FormHelperText
                                    error
                                    sx={{ marginLeft: "12px" }}
                                  >
                                    {field.isSelected(actor2)
                                      ? errors[field.propertyName]
                                      : " "}
                                  </FormHelperText>
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowSuggestionsDialog(true)}
            disabled={
              !!actor1?.actorId ||
              !!actor2?.actorId ||
              !duplicateSuggestionsCount
            }
          >
            {`${Translate.get("ShowSuggestions")} (${
              duplicateSuggestionsCount ?? "-"
            })`}
          </Button>
          <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
          {showKeepBothButton == true && (
            <ButtonWithSpinner onClick={keepBothActors}>
              {Translate.get("KeepBoth")}
            </ButtonWithSpinner>
          )}
          <ButtonWithSpinner
            onClick={mergeActors}
            disabled={
              !hasValidatedActors ||
              isValidatingValue ||
              Object.values(errors).some((e) => e) ||
              !actor1.actorId ||
              !actor2.actorId ||
              fields
                .filter((f) => f.isVisible)
                .some((f) => !f.isSelected(actor1) && !f.isSelected(actor2))
            }
          >
            {Translate.get("MergeDuplicates")}
          </ButtonWithSpinner>
        </DialogActions>
        <YesOrNoDialog
          open={showNavigateQuestion}
          title={Translate.get("MergeDuplicatesDone")}
          text={Translate.get("MergeDuplicatesDoneInfo")}
          onNo={() => closeAndReload()}
          onYes={() => handleNavigateToResult()}
          noText={Translate.get("No")}
          yesText={Translate.get("Yes")}
        />
      </>
    );
  }

  // Mobilvy
  return (
    <>
      <DialogContent>
        <Typography sx={{ margin: "0 0 24px" }}>
          {descriptionText === ""
            ? Translate.get("ActorMergeInfo")
            : Translate.get(descriptionText)}
        </Typography>

        <AutocompleteSearchField
          placeholder={`${Translate.get("Name")} 1`}
          label={`${Translate.get("Name")} 1`}
          value={actor1}
          onValueChange={(actor) => handleActorChange(actor, setActor1)}
          getOptionLabel={(option) => option?.actorName ?? ""}
          renderOption={(option) => (
            <ActorAutocompleteOption
              actorName={option?.actorName}
              orgNo={option?.orgNo}
              email={option?.email}
              companies={option?.companies}
            />
          )}
          keyPropName="actorId"
          requireSelection={true}
          disableAddAsNew={true}
          search={actorNameSearch}
          createNewValue={(text) => ({
            actorName: text,
          })}
          hideOptionsFilter={hideOptionsFilter}
          minWidth="300px"
          textFieldProps={{
            error: errors.actors,
            helperText: errors.actors,
          }}
        />

        <br />

        <AutocompleteSearchField
          placeholder={`${Translate.get("Name")} 2`}
          label={`${Translate.get("Name")} 2`}
          value={actor2}
          onValueChange={(actor) => handleActorChange(actor, setActor2)}
          getOptionLabel={(option) => option?.actorName ?? ""}
          renderOption={(option) => (
            <ActorAutocompleteOption
              actorName={option?.actorName}
              orgNo={option?.orgNo}
              email={option?.email}
              companies={option?.companies}
            />
          )}
          keyPropName="actorId"
          requireSelection={true}
          disableAddAsNew={true}
          search={actorNameSearch}
          createNewValue={(text) => ({
            actorName: text,
          })}
          hideOptionsFilter={hideOptionsFilter}
          minWidth="300px"
          textFieldProps={{
            error: errors.actors,
            helperText: errors.actors && " ",
          }}
        />

        <br />

        {hasValidatedActors &&
          fields.map(
            (field, index) =>
              field.isVisible && (
                <div key={index}>
                  <Box fontWeight="bold">{field.displayName}</Box>
                  <Grid container direction="column">
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Radio
                          color={
                            errors[field.propertyName] ? "error" : "default"
                          }
                          checked={field.isSelected(actor1)}
                          onChange={() => field.select(actor1)}
                          name={`radio-buttons-${field.propertyName}`}
                        />
                      </Grid>
                      <Grid item>{field.render(actor1)}</Grid>
                    </Grid>
                    {errors[field.propertyName] && (
                      <Grid item>
                        <FormHelperText error sx={{ marginLeft: "12px" }}>
                          {field.isSelected(actor1)
                            ? errors[field.propertyName]
                            : " "}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container direction="column">
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Radio
                          color={
                            errors[field.propertyName] ? "error" : "default"
                          }
                          checked={field.isSelected(actor2)}
                          onChange={() => field.select(actor2)}
                          name={`radio-buttons-${field.propertyName}`}
                        />
                      </Grid>
                      <Grid item>{field.render(actor2)}</Grid>
                    </Grid>
                    {errors[field.propertyName] && (
                      <Grid item>
                        <FormHelperText error sx={{ marginLeft: "12px" }}>
                          {field.isSelected(actor2)
                            ? errors[field.propertyName]
                            : " "}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )
          )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowSuggestionsDialog(true)}
          disabled={
            !!actor1?.actorId || !!actor2?.actorId || !duplicateSuggestionsCount
          }
        >
          {`${Translate.get("ShowSuggestions")} (${
            duplicateSuggestionsCount ?? "-"
          })`}
        </Button>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        {showKeepBothButton == true && (
          <ButtonWithSpinner onClick={keepBothActors}>
            {Translate.get("KeepBoth")}
          </ButtonWithSpinner>
        )}
        <ButtonWithSpinner
          onClick={mergeActors}
          disabled={
            !hasValidatedActors ||
            isValidatingValue ||
            Object.values(errors).some((e) => e) ||
            !actor1.actorId ||
            !actor2.actorId ||
            fields
              .filter((f) => f.isVisible)
              .some((f) => !f.isSelected(actor1) && !f.isSelected(actor2))
          }
        >
          {Translate.get("MergeDuplicates")}
        </ButtonWithSpinner>
      </DialogActions>
      <YesOrNoDialog
        open={showNavigateQuestion}
        title={Translate.get("MergeDuplicatesDone")}
        text={Translate.get("MergeDuplicatesDoneInfo")}
        onNo={() => closeAndReload()}
        onYes={() => handleNavigateToResult()}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
    </>
  );
}
