import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Translate from "../../utils/Translate";
import {
  toSameDateTimeUTC,
  getCurrentDateMask,
  getCurrentInputFormat
} from "../../utils/dateTimeFormat";
import SkillItem from "../skills/SkillItem";

export default function LicenseRegistrationStep1Card({
  date,
  onDateChange,
  competences,
  visibleCompetences,
  availableCompetences,
  onCompetenceClick,
  selectedCompetenceType,
  onCompetenceTypeChange,
  competenceInformationMessage,
  isLicenseEditLocked,
}) {
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Box marginBottom="16px">
        <h2>
          {Translate.get("Step1Course") +
            (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")}
        </h2>
      </Box>
      <Card elevation={0} sx={{ width: "100%", paddingBottom: "12px" }}>
        <CardContent>
          <Grid container spacing={4} direction="column" alignItems="center">
            <Grid
              item
              container
              spacing={4}
              direction={{ xs: "column", md: "row" }}
              wrap="nowrap"
            >
              <Grid item container direction="column" spacing={1}>
                <Grid item>{Translate.get("CourseTime")}</Grid>
                <Grid item>
                  <DatePicker
                    disabled={isLicenseEditLocked}
                    mask={getCurrentDateMask()}
                    inputFormat={getCurrentInputFormat()}
                    // InputProps={{ sx: { maxWidth: "158px" } }}
                    value={date}
                    onChange={(newDate,realVal) => {
                      if ((realVal  && realVal.length === 10) || (!realVal && newDate)) {
                       onDateChange(toSameDateTimeUTC(newDate))
                      }
                    }
                     
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("ANSIDatePlaceholder"),
                        }}
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item>{Translate.get("ChooseMachineType")}</Grid>
                <Grid item>
                  <div className="stdFlex">
                    <Button
                      variant={
                        selectedCompetenceType === "Truck"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => onCompetenceTypeChange("Truck")}
                      disabled={isLicenseEditLocked || !availableCompetences["Truck"]}
                    >
                      {Translate.get("Truck")}
                    </Button>
                    <Button
                      variant={
                        selectedCompetenceType === "Lift"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => onCompetenceTypeChange("Lift")}
                      disabled={isLicenseEditLocked || !availableCompetences["Lift"]}
                    >
                      {Translate.get("Lift")}
                    </Button>
                    <Button
                      variant={
                        selectedCompetenceType === "Kran"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => onCompetenceTypeChange("Kran")}
                      disabled={isLicenseEditLocked || !availableCompetences["Kran"]}
                    >
                      {Translate.get("Crane")}
                    </Button>
                    <Button
                      variant={
                        selectedCompetenceType === "Fallskydd"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => onCompetenceTypeChange("Fallskydd")}
                      disabled={isLicenseEditLocked || !availableCompetences["Fallskydd"]}
                    >
                      {Translate.get("FallProtection")}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {selectedCompetenceType !== "" && visibleCompetences && (
              <Grid item container direction="column" spacing={1}>
                <Grid item>{Translate.get("ChooseCompetences")}</Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "red",
                    }}
                  >
                    {competenceInformationMessage}
                  </Typography>
                </Grid>
                <Grid item container spacing={2} alignSelf="center">
                  {competences
                    .filter(
                      (competence) =>
                        competence.category === selectedCompetenceType
                    )
                    .map((competence) => (
                      <Grid item key={competence.id}>
                        <SkillItem
                          isSelected={
                            visibleCompetences.indexOf(competence) >= 0
                          }
                          onClick={() => onCompetenceClick(competence)}
                          isSmall={!isSmallScreenOrGreater}
                          imageUrl={competence.imageUrl}
                          label={`${competence.column} ${competence.description}`}
                          disabled={isLicenseEditLocked}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
