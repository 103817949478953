 /**
 * @param {key} key - A string containing the name of the key
 * @param {ttl} value - A value to store
 * @param {ttl} ttl - TTL in milliseconds
 * @return {void}
 */
export function setWithExpiry(key, value, ttl) {
    let now = new Date();

    let item = {
      value: value,
      expiry: now.getTime() + ttl
    }

    sessionStorage.setItem(key, JSON.stringify(item));
  }

/**
 * @param {key} key - A string containing the name of the key
 * @return {string | null}
 */
  export function getWithExpiry(key) {
    let itemStr = sessionStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    let item = JSON.parse(itemStr);
    let now = new Date();

    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }

    return item.value;
  }