import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-premium';


// csv i sverige är inte kommaseparerad, utan semikolonseparerad
const delimiter = localStorage.lang === '29' ? ';' : ',';

const exceljsPreProcess = (sheetName) => ({workbook, worksheet}) => {
  worksheet.name = sheetName;
  // landscape borde väl vara bra till det mesta
  worksheet.pageSetup = {...worksheet.pageSetup, orientation: 'landscape' };
};

const exceljsPostProcess = ({workbook, worksheet}) => {
  // byt ut exceljs default datumformat till iso format 
  for(let i = 0; i < worksheet.columnCount; i++) {
    const columnStyle = worksheet.columns[i].style;
    if(columnStyle.numFmt === 'dd.mm.yyyy') {
      for(let j = 1; j < worksheet.rowCount; j++) {
        const cell = worksheet.getRow(j).getCell(i + 1); 
        let style = cell.style;
        cell.style = {...style, numFmt: 'yyyy-MM-dd'};
      }
    }
  }

  // streck under första raden
  const firstRow = worksheet.getRow(1); 
  firstRow.border = {
    bottom: { style: "thick"}
  };

  // frys första raden
  worksheet.views = [
    {state: 'frozen', ySplit: 1 }
  ];

  // lägg på autofilter på första raden
  worksheet.autoFilter = {
    from: {row:1, column: 1},
    to: {row:worksheet.rowCount, column: worksheet.columnCount}
  };
};

export const ToolBar = ({showExport, hideFilter, exportFileName}) => {
   return (
    <GridToolbarContainer className='memToolBar'>
      <GridToolbarColumnsButton />
      {!hideFilter && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {showExport
      && <GridToolbarExport 
          csvOptions={{
            fileName: exportFileName, 
            delimiter: delimiter,
            utf8WithBom: true,
            allColumns: true // annars tas bara de kolumner som visas med
          }}
          excelOptions={{
            fileName: exportFileName,
            exceljsPreProcess: exceljsPreProcess(exportFileName),
            exceljsPostProcess,
            allColumns: true // annars tas bara de kolumner som visas med
          }} />}
    </GridToolbarContainer>
  );
};
