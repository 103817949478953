import { useState, useEffect } from 'react';
import { useApi } from '../utils/Api';
import Translate from '../utils/Translate';
import { useSearchParams } from 'react-router-dom';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import BankIdAuthentication from '../components/bankId/BankIdAuthentication';
import Alert from '@mui/material/Alert';
import Preloader from '../components/Preloader';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Privacy = (props) => {

  const [searchParams] = useSearchParams();
  const api = useApi();
  const smallScreen = useMediaQuery('(max-width:700px)');

  const titleRef = useRef();

  const columnWidth = !smallScreen ? 6 : 12;
  const divMaxWidth = !smallScreen ? "40em" : "90%";
  const divWidth = !smallScreen ? "70%" : "90%";

  const [showView, setShowView] = useState("init");
  const [showDialog, scrollToBottomAfterDialog] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [email, setEmail] = useState(null);
  const [deleteData, setDeleteData] = useState(false);
  const [copyData, setCopyData] = useState(false);


  useEffect(() => {
    const lang = searchParams.get('lang') ?? localStorage.lang ?? '29';
    Translate.loadPreLogin(lang);
  }, [searchParams]);

  useEffect(() => {
    const handleScroll = e => {
      setShowView("bankId");
      document.removeEventListener("scroll", handleScroll)
    }
    document.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (showDialog !== true) { return }
    titleRef.current.scrollIntoView();
  }, [showDialog]);


  function handleClick() {
    if (showView === "init") {
      setShowView("bankId");
    }

    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function disableSendButton() {
    if (name === "" || email === "" || (copyData === false && deleteData === false)) {
      return true;
    }

    return false;
  }

  function capFirst(str) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  function onAuthenticationSuccess(loginType, response) {
    setShowView("form");

    setOrgNo(response.data.ssn);
    setToken(response.data.token);
    setName(capFirst(response.data.givenName) + " " + capFirst(response.data.surName));
  }

  async function postForm() {
    setIsLoading(true);

    let reqObj = {
        name: name,
        orgNo: orgNo,
        email: email,
        token: token,
        removeData: deleteData,
        copyOfData: copyData,
    }

    try {
      let response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}privacy`,
        reqObj,
        "POST",
        false,
        true
      );
      if (response.isSuccessful) {
        setShowView("success")
        titleRef.current.scrollIntoView();
      } else if (response.errorMessageTranslationKey === "Unauthorized") {
        setShowView("bankId");
      }
    } catch (error) {
    }
    finally {
      setIsLoading(false);
      setOrgNo("");
      setEmail("");
    }
  }

  return (
    <div>
      {isLoading && <Preloader />}

      <div style={{ minHeight: "20em", height: "100vh" }}>
        <div className='lostCardGradient'>
          <img src={!smallScreen ? '/static/gdpr_desktop.png' : '/static/gdpr_mobile.png'} alt="" />
        </div>

        <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "4em" }}>
          <p style={{ fontWeight: "bold" }}>Personuppgifter hos MA-system Utbildning AB</p>
          <p>
          Var och en som har sina personuppgifter hos MA-system Utbildning AB har rätt att hämta dessa uppgifter via ett elektroniskt standardformat eller begära borttagning av informationen.
          </p> <br />

          <p>Om du vill göra detta börjar du med att legitimera dig med BankID och fyller därefter i formuläret.</p> <br />

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="large" sx={{ mt: "2em" }}
              onClick={handleClick}
              className='pulse-button'
            >
              <ArrowCircleDownIcon fontSize='large' />
            </IconButton>

          </Grid>

        </div>
      </div>

      <div ref={titleRef} style={{ minHeight: "20em", height: "100vh" }} className='lostCardGradient' id="returnFromApp">
        {showView === "bankId" &&

          <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "6em" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>

              <BankIdAuthentication startAuthenticationUrl={`${process.env.REACT_APP_MAIN_URL}privacy/bankId/authenticate`}
                //onStatusInitiated={onStatusInitiated}
                onAuthenticationSuccess={onAuthenticationSuccess}
              //onAutheticationFailure={}
              //startQrCodeInMobile={startQrCodeInMobile}
              />
            </div>
          </div>
        }

        {showView === "form" &&
          <div style={{ paddingTop: "3em" }}>
            <Card style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth }}>
              <Grid
                container
                style={{ padding: "2em" }}
                spacing={"12px"}
              >
                <Grid item xs={6}>
                  <b>Personuppgifter</b>
                </Grid>
                <Grid item xs={6}>
                  Personnummer: {orgNo}
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={name} onChange={(event) => setName(event.target.value)} label="Namn" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={email} onChange={(event) => setEmail(event.target.value)} label="Email" variant="outlined" fullWidth required />
                </Grid>



                <Grid item xs={columnWidth}>
                <br />
                  <p>Jag vill:</p> 
                </Grid>

                <Grid item xs={12}>
                <FormControlLabel control={<Checkbox checked={deleteData} onChange={(event) => setDeleteData(event.target.checked)}/>} label="Bli borttagen från MA-systems tjänster." />
                </Grid>

                <Grid item xs={12}>
                <FormControlLabel control={<Checkbox checked={copyData} onChange={(event) => setCopyData(event.target.checked)}/>} label="Få en kopia av min data." />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button variant="contained" onClick={postForm} disabled={disableSendButton()}>Skicka</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </div>
        }

        {showView === "success" &&
          <div style={{ paddingTop: "3em" }}>
            <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, padding: "2em", paddingLeft: 0 }}>
              <Alert severity="success">Tack för din förfrågan. Vi återkommer till dig.</Alert>
            </div>
          </div>
        }

        <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "6em", paddingBottom: "1em" }}>
          <p>Detta är en tjänst som administreras av MA-system Utbildning AB.
            När du skickar en förfrågan via detta formulär godkänner du samtidigt att vi får behandla dina personuppgifter för att kunna utföra åtgärden.
            Om du ej har tillgång till BankId, kontakta oss på education@masystem.se
          </p>
        </div>
      </div>
    </div>
  );
};
export default Privacy;