import { useApi } from "../utils/Api";
import { CircularProgress, TextField } from "@mui/material";
import { useState, useCallback, useRef, useMemo, Fragment } from "react";
import debounce from "lodash.debounce";
import Translate from "../utils/Translate";

export const verdictKeys = {
  undefined: 0,
  valid: 1,
  risky: 2,
  invalid: 3,
};

export async function validateEmail(api, email) {
  const dto = {
    email,
    source: "ePortWebGui", // TODO
  };

  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/validate/email`,
    dto,
    "POST"
  );
}

const simpleEmailRegEx = /.+@.+\..+/;

export default function EmailValidatingTextField({
  value,
  onEmailChange,
  onValidationChange,
  autoComplete,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [suggestion, setSuggestion] = useState(null);
  const [emailValidationError, setEmailValidationError] = useState(null);
  const api = useApi();

  // Need to useRef to avoid updating useCallback, useMemo
  // and the debounce if onValidationChange changes
  const onValidationChangeRef = useRef(onValidationChange);
  onValidationChangeRef.current = onValidationChange;

  const updateIsInvalid = useCallback((newIsInvalid) => {
    setIsInvalid(newIsInvalid);
    onValidationChangeRef.current &&
      onValidationChangeRef.current(!newIsInvalid);
  }, []);

  const checkIsInvalid = useMemo(
    () =>
      debounce(async (emailCandidate) => {
        const simpleCheckOk = simpleEmailRegEx.test(emailCandidate);
        if (!!emailCandidate && simpleCheckOk) {
          const result = await validateEmail(api, emailCandidate);
          setIsLoading(false);
          setSuggestion(result.suggestion);
          updateIsInvalid(result.verdict === verdictKeys.invalid);
          setEmailValidationErrorMsg(result);
        } else {
          setIsLoading(false);
          setSuggestion(null);
          setEmailValidationError(null);
          updateIsInvalid(!!emailCandidate ? true : false);
        }
      }, 700),
    [updateIsInvalid, api]
  );

  function setEmailValidationErrorMsg(result) {
    if (result.verdict === verdictKeys.invalid) {
      if (!result.hasValidAddressSyntax) {
        setEmailValidationError("NoValidAddressSyntax");
      } else if (!result.hasMXAndOrARecordPresent) {
        setEmailValidationError("HasNoMxAndOrARecordPresent");
      } else if (result.hasKnownBounces) {
        setEmailValidationError("HasKnownBounces");
        updateIsInvalid(false); // Ska ej vara blockerande
      } else {
        setEmailValidationError("InvalidEmail");
      }
    } else if (result.verdict === verdictKeys.risky) {
      if (result.hasSuspectedBounces) {
        setEmailValidationError("HasSuspectedBounces");
      } else {
        setEmailValidationError("RiskyEmail");
      }
    } else {
      setEmailValidationError(null);
    }
  }

  async function handleChange(event) {
    const newValue = event.target.value?.trim();
    onEmailChange(newValue);
    newValue && setIsLoading(true);
    await checkIsInvalid(newValue);
  }

  return (
    <TextField
      autoComplete={autoComplete ?? "off"}
      value={value}
      onChange={handleChange}
      error={!!value ? isInvalid : false}
      helperText={
        !!value && (!!suggestion
          ? `${Translate.get("DidYouMean")} ${suggestion}`
          : "" || !!emailValidationError ? Translate.get(emailValidationError) : "")
      }
      {...rest}
      InputProps={{
        ...rest.InputProps,
        spellCheck: false,
        endAdornment: (
          <Fragment>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {rest.InputProps?.endAdornment}
          </Fragment>
        ),
      }}
    />
  );
}
