import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, DialogTitle, useMediaQuery } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DotMenu from "../components/DotMenu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {tryFormatDate} from "../utils/dateTimeFormat"

let moclicens = {
  license: {
    digitalLicenseId: 57,
    displayData: {
      digitalLicenseColor: "#0000ff",
      primaryFontColor: "#ffffff",
      secondaryFontColor: "#ffffff80",
      frontBackgroundImageUrl:
        "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-front.png?sv=2022-11-02&st=2024-09-13T07%3A10%3A00Z&se=2024-09-13T08%3A15%3A00Z&sr=c&sp=r&sig=%2FDLLBLSmidfcj7ADkm%2FYzuAn2UDmAx7qK0UF2zrUJFY%3D",
      backBackgroundImageUrl:
        "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-back.png?sv=2022-11-02&st=2024-09-13T07%3A10%3A00Z&se=2024-09-13T08%3A15%3A00Z&sr=c&sp=r&sig=%2FDLLBLSmidfcj7ADkm%2FYzuAn2UDmAx7qK0UF2zrUJFY%3D",
      frontFields: [
        {
          fieldName: "CompanyLogo",
          seqNo: 0,
          titleTranslationKey: null,
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 10,
          isCapitalized: null,
        },
        {
          fieldName: "LicenseGroupDescription",
          seqNo: 1,
          titleTranslationKey: "ActorLicenseAlt",
          subtitleTranslationKey: "BasedOnEducationPlanLLP",
          fontFamily: "Roboto-Regular",
          fontSize: 48,
          isCapitalized: true,
        },
        {
          fieldName: "gap",
          seqNo: 2,
          titleTranslationKey: null,
          subtitleTranslationKey: null,
          fontFamily: "-",
          fontSize: 0,
          isCapitalized: null,
        },
        {
          fieldName: "StudentName",
          seqNo: 3,
          titleTranslationKey: "BelongingTo",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 24,
          isCapitalized: null,
        },
        {
          fieldName: "StudentPersonalNo",
          seqNo: 4,
          titleTranslationKey: "StudentPersonNo",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 24,
          isCapitalized: null,
        },
      ],
      backFields: [
        {
          fieldName: "ValidFrom",
          seqNo: 5,
          titleTranslationKey: "ActorLicenseIssuedDate",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 16,
          isCapitalized: null,
        },
        {
          fieldName: "ValidTo",
          seqNo: 6,
          titleTranslationKey: "ActorLicenseValidUntil",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 16,
          isCapitalized: null,
        },
        {
          fieldName: "CourseCoordinator",
          seqNo: 7,
          titleTranslationKey: "CourseCoordinator",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 16,
          isCapitalized: null,
        },
        {
          fieldName: "ActorLicenseTheoretical",
          seqNo: 8,
          titleTranslationKey: "TheoryGroup",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 16,
          isCapitalized: null,
        },
        {
          fieldName: "ActorLicensePractical",
          seqNo: 9,
          titleTranslationKey: "Competences",
          subtitleTranslationKey: null,
          fontFamily: "Roboto-Regular",
          fontSize: 16,
          isCapitalized: null,
        },
      ],
      fieldValues: {
        LicenseGroupDescription: "Truck",
        StudentName: "Niklas Ollikainen",
        StudentPersonalNo: "19660309-8937",
        ValidFrom: "2024-05-17T13:41:00Z",
        ValidTo: null,
        CourseCoordinator: "Juri AB",
        ActorLicenseTheoretical: "C",
        ActorLicensePractical: "",
        CompanyLogo:
          "https://dataidstorage.blob.core.windows.net/1015/logotype.png?sv=2022-11-02&se=2024-09-14T07%3A15%3A00Z&sr=b&sp=r&sig=b4IKbliYYzSVjEou4pm44cUBvhaOkM3NSlK4SRLnCkw%3D",
      },
    },
    validTo: null,
    publicShareUrl:
      "https://ecomp-dev.azurewebsites.net?digitalLicenseId=57&key=e1grPbvDuqEZ3vqU1yVUyE27kawTrBJQXJ7d3Zi7h3QQ5qwvu8",
  },
  company: {
    logoUrl:
      "https://dataidstorage.blob.core.windows.net/1015/logotype.png?sv=2022-11-02&se=2024-09-14T07%3A15%3A00Z&sr=b&sp=r&sig=b4IKbliYYzSVjEou4pm44cUBvhaOkM3NSlK4SRLnCkw%3D",
    webPage: "www.google.com",
    companyName: "Juri AB",
    companyInfo: "En företagsbeskrivning....AAAsfagasdgshbsfhs",
  },
  course: {
    courseDescription: null,
  },
  skills: [
    {
      skillId: 722,
      shortCode: "C",
      description: "Truckteori C",
      isMandatory: false,
      imageUrl:
        "https://eportpublic-cdn.azureedge.net/skillicons/Style_Theory.svg?sp=r&st=2022-04-26T06:31:44Z&se=2072-04-26T14:31:44Z&spr=https&sv=2020-08-04&sr=c&sig=Kh6uYXP1Xc60cU3aEQ8hC3DJpfWVSi%2BKYtrOvtW4f44%3D",
    },
    {
      skillId: 715,
      shortCode: "B1",
      description: "Motviktstruck",
      isMandatory: false,
      imageUrl:
        "https://eportpublic-cdn.azureedge.net/skillicons/Truck_B1.svg?sp=r&st=2022-04-26T06:31:44Z&se=2072-04-26T14:31:44Z&spr=https&sv=2020-08-04&sr=c&sig=Kh6uYXP1Xc60cU3aEQ8hC3DJpfWVSi%2BKYtrOvtW4f44%3D",
    },
  ],
  skillGroupDescription: "Truck",
  isSuccessful: true,
  errorMessageTranslationKey: null,
};
function getDotMenuActions() {
  return [
    {
      icon: <AddIcon />,
      text: "Lägg till i Apple Wallet",
      onClick: () => {},
    },
    {
      icon: <AddIcon />,
      text: "Lägg till i Google Wallet",
      onClick: () => {},
    },
    {
      icon: <ContentCopyIcon />,
      text: "Kopiera länk",
      onClick: () => {},
    },
    {
      icon: <DownloadIcon />,
      text: "Ladda ner",
      onClick: () => {},
    },
  ];
}
const Comp = (props) => {
  window.onpopstate = (event) => {
    console.log(window.location.hash);
    if (window.location.hash !== "#modal") {
      /* Radda upp alla dialoger som ska stängas */
      setOpenCompany(false);
      setOpenComp(false);
    }
  };
  const smallScreen = useMediaQuery("(max-width:750px)");
  //const [open, setOpen] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);
  const [openComp, setOpenComp] = useState(false);
  const handleOpenCompany = () => {
    console.log("handleOpenCompany");
    setOpenCompany(true);
    window.location = "#modal";
  };
  const handleOpenComp = () => {
    console.log("handleOpenCompetece");
    setOpenComp(true);
    window.location = "#modal";
  };

  const handleClose = () => {
    console.log("handleClose");
    window.history.back();
  };
  const lic = moclicens.license;

  //Main view
  const bgColor = lic.displayData?.digitalLicenseColor
    ? { backgroundColor: lic.displayData.digitalLicenseColor }
    : {};
  const fontColor = {};
  fontColor.primary = lic.displayData?.primaryFontColor
    ? { color: lic.displayData.primaryFontColor }
    : {};
  fontColor.secondary = lic.displayData?.secondaryFontColor
    ? { color: lic.displayData.secondaryFontColor }
    : {};

  const ff = [];
  lic.displayData.frontFields.map((row) => {
    row.value = lic.displayData.fieldValues[row.fieldName]
      ? lic.displayData.fieldValues[row.fieldName]
      : "-";
    ff.push(row);
  });
  const bf = [];
  lic.displayData.backFields.map((row) => {
    row.value = lic.displayData.fieldValues[row.fieldName]
      ? lic.displayData.fieldValues[row.fieldName]
      : "-";
      if (row.fieldName==='ValidFrom' || row.fieldName==='ValidTo') {
        row.value=tryFormatDate(row.value);
      }
    bf.push(row);
  });
  //Company dialog
  console.log("CompanyDialog", moclicens.company);
  //Competece dialog
  console.log("CompetenceDialog", moclicens.skills);
  const CompanyDialog = () => {
    return (
      <Dialog
        open={openCompany}
        onClose={handleClose}
        fullScreen={false}
        scroll="paper"
        style={{ top: "63px", bottom: "10px" }}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>
          Utbildningssamordnare
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="dlcCompanyDialog">
            <div>
              <div>
                <img
                  src={moclicens.company.logoUrl}
                  className="dlcCompanyLogo"
                />
              </div>
              <div className="dlcHeading1">{moclicens.company.companyName}</div>
              <div>{moclicens.company.description}</div>
            </div>
            <div>
              {/*  <div className="dlcCompanyLogo"></div> */}
              <div className="iconTextRows">
                <div>
                  <div>
                    <PhoneIcon />
                  </div>
                  <div>Telefonnummer?</div>
                </div>
                <div>
                  <div>
                    <EmailIcon />
                  </div>
                  <div>Email?</div>
                </div>
                <div>
                  <div>
                    <LanguageIcon />
                  </div>
                  <div>{moclicens.company.webPage}</div>
                </div>
                <div>
                  <div>
                    <PlaceIcon />
                  </div>
                  <div>
                    Adress?
                    <br />
                    Radbrytningar för adress?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const CompetenceDialog = () => {
    return (
      <Dialog
        open={openComp}
        onClose={handleClose}
        fullScreen={false}
        scroll="paper"
        style={{ top: "63px", bottom: "10px" }}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>
          Kompetenser
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="dlcCompetenceDialog">
            <div className="iconTextRows dlcBlueBoxes">
              <div className="dlcTitle">Godkända kompetenser</div>
              {moclicens.skills.map((row, index) => (
                <div key={index}>
                  <div>
                    <img src={row.imageUrl} />
                  </div>
                  <div>{row.description}</div>
                </div>
              ))}
            </div>
            <div className="iconTextRows dlcGrayBoxes">
              <div className="dlcTitle">Övriga kompetenser</div>
              <div>
                <div>ICO?</div>
                <div>
                 Innehåll övriga kompetenser?
                </div>
              </div>
              <div>
                <div>ICO</div>
                <div>
                Innehåll övriga kompetenser?
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <CompetenceDialog />
      <CompanyDialog />

      {smallScreen && (
        <div className="dlSingleContainer">
          <div className="stdFlexSB">
            <div>
              <IconButton onClick={() => window.history.back()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <DotMenu content={getDotMenuActions()} />
            </div>
          </div>
          {/* <Swiper
            effect={"flip"}
            grabCursor={true}
            loop={true}
            //pagination={true}
            //navigation={true}
            modules={[EffectFlip]}
            className="swiperDL"
          >
            <SwiperSlide className="swiperDLSlide" style={bgColor}>
              <Cardfront
                fields={ff}
                fontColor={fontColor}
                companyLogo={lic.displayData.fieldValues.CompanyLogo || false}
                bgImg={lic.displayData.frontBackgroundImageUrl || false}
              />
            </SwiperSlide>
            <SwiperSlide className="swiperDLSlide" style={bgColor}>
              <CardBack
                fields={bf}
                fontColor={fontColor}
                handleOpenCompany={handleOpenCompany}
                handleOpenComp={handleOpenComp}
                bgImg={lic.displayData.backBackgroundImageUrl || false}
              />
            </SwiperSlide>
          </Swiper> */}
        </div>
      )}
      {!smallScreen && (
        <>
          <div className="dlSingleContainer">
            <div className="stdFlexSB">
              <div>
                <IconButton onClick={() => window.history.back()}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div>
                <DotMenu content={getDotMenuActions()} />
              </div>
            </div>
            {/* <div className="dlcCardWrapper">
              <div className="dlcCard">
                <div style={bgColor}>
                  <Cardfront
                    fields={ff}
                    fontColor={fontColor}
                    bgImg={lic.displayData.frontBackgroundImageUrl || false}
                  />
                </div>
                <div style={bgColor}>
                  <CardBack
                    fields={bf}
                    fontColor={fontColor}
                    handleOpenCompany={handleOpenCompany}
                    handleOpenComp={handleOpenComp}
                    bgImg={lic.displayData.backBackgroundImageUrl || false}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};
export default Comp;
