import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import debounce from "lodash.debounce";
import { useTheme } from "@emotion/react";
import CancelIcon from "@mui/icons-material/Cancel";

const showAllCoursesPseudoFilterGroupId = -1;
const showOwnCoursesPseudoFilterGroupId = 0;
const getPseudoFilterGroups = () => [
  {
    filterGroupId: showAllCoursesPseudoFilterGroupId,
    description: Translate.get("ShowAllCoursesLabel"),
  },
  {
    filterGroupId: showOwnCoursesPseudoFilterGroupId,
    description: Translate.get("ShowOwnCoursesLabel"),
  },
];

function getStyles(filterGroupIds, selectedFilterGroupIds, theme) {
  return {
    fontWeight:
      selectedFilterGroupIds.indexOf(filterGroupIds) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FilterGroupsCard({}) {
  const [filterGroups, setFilterGroups] = useState([]);
  const [selectedFilterGroupIds, setSelectedFilterGroupIds] = useState([]);

  const api = useApi();
  const theme = useTheme();

  useEffect(() => {
    async function loadFilterGroups() {
      const loadedFilterGroups = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/filters/groups`,
        false,
        "GET"
      );
      const selectableFilters = [...getPseudoFilterGroups()];
      selectableFilters.push(...loadedFilterGroups);
      setFilterGroups(selectableFilters);
    }

    async function loadActiveFilters() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/filters/active`,
        false,
        "GET"
      );

      const { showAllCourses, showOwnCourses, showFilterGroupIds } =
        response.activeFilters;
      const activeFilterGroupIds = [];
      if (showAllCourses) {
        activeFilterGroupIds.push(showAllCoursesPseudoFilterGroupId);
      } else {
        if (showOwnCourses) {
          activeFilterGroupIds.push(showOwnCoursesPseudoFilterGroupId);
        }

        if (showFilterGroupIds) {
          activeFilterGroupIds.push(...showFilterGroupIds);
        }
      }
      setSelectedFilterGroupIds(activeFilterGroupIds);
    }

    loadActiveFilters();
    loadFilterGroups();
  }, [api]);

  const postUpdateFilterGroups = useMemo(
    () =>
      debounce(async (selectedFilterGroupIds) => {
        const showAllCourses = selectedFilterGroupIds.includes(
          showAllCoursesPseudoFilterGroupId
        );
        const showOwnCourses =
          !showAllCourses &&
          selectedFilterGroupIds.includes(showOwnCoursesPseudoFilterGroupId);
        const showFilterGroupIds = !showAllCourses
          ? selectedFilterGroupIds.filter(
              (id) =>
                id !== showAllCoursesPseudoFilterGroupId &&
                id !== showOwnCoursesPseudoFilterGroupId
            )
          : [];

        const request = {
          showAllCourses,
          showOwnCourses,
          showFilterGroupIds,
        };

        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/filters/active`,
          request,
          "POST"
        );
      }, 1000),
    [api]
  );

  function updateSelectedFilterGroups(filterGroupIds) {
    setSelectedFilterGroupIds(filterGroupIds);
    postUpdateFilterGroups(filterGroupIds);
  }

  function handleDelete(filterGroupId) {
    const index = selectedFilterGroupIds.findIndex(
      (id) => id === filterGroupId
    );
    if (index !== -1) {
      const selectionCopy = [...selectedFilterGroupIds];
      selectionCopy.splice(index, 1);
      if (selectionCopy.length > 0) {
        updateSelectedFilterGroups(selectionCopy);
      } else {
        updateSelectedFilterGroups([showAllCoursesPseudoFilterGroupId]);
      }
    }
  }

  function handleSelectionChange(event) {
    const newSelection = event?.target?.value ?? [];
    const noneSelected = newSelection.length === 0;
    const isShowAllCourseLatestSelected =
      newSelection[newSelection.length - 1] ==
      showAllCoursesPseudoFilterGroupId;
    if (noneSelected || isShowAllCourseLatestSelected) {
      // Remove all other filters if "show all courses" is selected.
      // And auto select "show all courses" if no filters are selected.
      updateSelectedFilterGroups([showAllCoursesPseudoFilterGroupId]);
    } else {
      // And if another filter has been selected after "show all courses",
      // we remove "show all courses" from the selection
      updateSelectedFilterGroups(
        newSelection.filter((id) => id !== showAllCoursesPseudoFilterGroupId)
      );
    }
  }

  return (
    <Card sx={{ paddingBottom: "10px" }}>
      <CardContent>
        <Grid container spacing={3.5} direction="column">
          <Grid item>
            <Typography variant="h4">
              {Translate.get("FilterSettings")}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="label-select-unit">
                {Translate.get("CourseFilter")}
              </InputLabel>
              {/* Using MenuProps here to make the popup appear above the Select,
              so the user can see the Chips for selected items appear in the input */}
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
                multiple
                value={selectedFilterGroupIds}
                labelId="label-select-unit"
                label={Translate.get("CourseFilter")}
                onChange={handleSelectionChange}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          filterGroups.find((fg) => fg.filterGroupId === value)
                            ?.description
                        }
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Box>
                )}
              >
                {filterGroups.map((fg) => (
                  <MenuItem
                    key={fg.filterGroupId}
                    value={fg.filterGroupId}
                    style={getStyles(
                      fg.filterGroupId,
                      selectedFilterGroupIds,
                      theme
                    )}
                  >
                    {fg.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
