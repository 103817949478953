import { useState, useEffect } from "react";
import { TabPanel } from "../components/settings/TabPanel";
import Translate from "../utils/Translate";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NotificationSettings from "../components/profile-settings/NotificationSettings";
import UserSettings from "../components/profile-settings/UserSettings";
import { getQueryParam } from "../utils/uriHelper";
import { useNavigate, useLocation } from "react-router-dom";
import { hasSomeAccess, accessKeys } from "../utils/userAccess";

export function MyProfileSettings(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const showNotificationSettings = hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage, accessKeys.englishCompany, accessKeys.frenchCompany);

  useEffect(() => {
    const tabParamValue = getQueryParam("tab");
    if (tabParamValue) {
      setValue(parseInt(tabParamValue));
    } else {
      setValue(0);
      navigate(`${location.pathname}?tab=0`, { replace: true });
    }
  }, [navigate, location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`, { replace: true });
  };

  return (
    <div>
      <h1>{Translate.get("MyProfile")}</h1>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label={Translate.get("User")} />
            {showNotificationSettings && <Tab label={Translate.get("Notifications")} />}
          </Tabs>
        </Box>
        <TabPanel className="tabPanelNoPadding"  value={value} index={0}>
          <UserSettings />
        </TabPanel>
        { showNotificationSettings &&
          <Box display="flex" justifyContent="center">
            <TabPanel className="tabPanelNoPadding"  value={value} index={1}>
              <NotificationSettings />
            </TabPanel>
          </Box>}
      </Box>
    </div>
  );
}
export default MyProfileSettings;
