import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Translate from '../../utils/Translate';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { useApi } from '../../utils/Api';
import { formatDate } from '../../utils/dateTimeFormat';

import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from "@mui/lab";

import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from '../../utils/dateTimeFormat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
const Comp = (props) => {
  useEffect(() => {
    window.analyticsEvent("eport_xtras","eport_publish_online");
  }, []);
 
  //console.log('props', props);
  //----------Finns alltid med
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };
  //----------
  const dTime = new Date();
  const now = new Date(
    Date.UTC(dTime.getUTCFullYear(), dTime.getUTCMonth(), dTime.getUTCDate()),
  );
  const [startDate, setStartDate] = useState(props.publishFromDate || now);
  const [endDate, setEndDate] = useState(props.publishToDate || props.fromDate);
  const [data, setData] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  function calculateEndDate(startDate, courseDays) {
    let endDate = addDays(startDate, courseDays);
    return endDate;
  }
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const fromToDateCheck = (from, to) => {
    from = Date.parse(from);
    to = Date.parse(to);
    if (isNaN(from) || isNaN(to)) {
      return false;
    } else if (to < from) {
      return false;
    } else {
      return true;
    }
  };
  const executeTask = () => {
    setSubmitting(true);
    (async () => {
      let task = `${process.env.REACT_APP_MAIN_URL}courses/${props.courseId}/unpublish`;
      if (!props.isPublishActiveNow) {
        task = `${process.env.REACT_APP_MAIN_URL}courses/${
          props.courseId
        }/publish/${formatDate(startDate, 'sv-SE')}/${formatDate(
          endDate,
          'sv-SE',
        )}`;
      }

      const data = await api.fetch(task);
      setData(data);
    })();
  };
  if (data) {
    setData(false);
    setSubmitting(false);
    closeAndReload();
  }

  return (
    <>
      <DialogTitle>
        <div className="stdFlexBetween">
          <div>{props.isPublishActiveNow ? Translate.get('ePort2iFrameUnpublish') : Translate.get('ePort2iFramePublishOnline')}</div>
          <Tooltip title={Translate.get('ePort2iFramePublishTooltip')}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent dividers={true} className="stdDialogContent">
        {/*  params.row.courseId,
            params.row.publishFromDate,
            params.row.publishToDate,
            params.row.isPublishActiveNow, */}
        <div>
            <div className="stdFlexRowTop">
            <CalendarTodayIcon />
            <DatePicker
              disabled={props.isPublishActiveNow}
              inputFormat={getCurrentInputFormat()}
              mask={getCurrentDateMask()}
              label={Translate.get('StartDate')}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                //const newEndDate = calculateEndDate(newValue, 7);
                //setEndDate(newEndDate);
                //updateFormData("endDate", newEndDate);
                //updateFormData("startDate", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  required
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: Translate.get('DatePlaceholder'),
                  }}
                />
              )}
            />
          </div>
          <div className="stdFlexRowTop">
            <CalendarTodayIcon />
            <DatePicker
              disabled={props.isPublishActiveNow}
              inputFormat={getCurrentInputFormat()}
              mask={getCurrentDateMask()}
              label={Translate.get('EndDate')}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                //updateFormData("endDate", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  required
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: Translate.get('DatePlaceholder'),
                  }}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.history.back()}>
          {Translate.get('Cancel')}
        </Button>
        <LoadingButton
                      className="stdLightLoader"
                      disabled={!fromToDateCheck(startDate, endDate)}
                      onClick={() => executeTask()}
                      loading={submitting}
                    >
                      {props.isPublishActiveNow ? Translate.get('ePort2iFrameUnpublish') : Translate.get('ePort2iFramePublish')}
                    </LoadingButton>
      </DialogActions>
    </>
  );
};
export default Comp;
