import { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import { frFR, enUS, svSE, nbNO } from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { ToolBar } from './ToolBar';
import Translate from "../utils/Translate";
export const dataGridLang = () => {
  /* 9=eng, 12 fr 29=swe */
  //if (localStorage.lang) {
    let muiTranslations = null;
    if (localStorage.lang === '9') {
      muiTranslations =  enUS;
    } else if (localStorage.lang === '12') {
      muiTranslations = frFR;
    } else if(localStorage.lang === '29') {
      muiTranslations = svSE;
    } else if(localStorage.lang === '20') {
      muiTranslations = nbNO;
    }

    if(muiTranslations) {
      
      const filter=muiTranslations.components.MuiDataGrid.defaultProps.localeText;
      //Ersätt nycklar med icke standard översättningar
      filter.toolbarFilters=Translate.get('FilterNoun');
      filter.filterPanelOperator=Translate.get('Operator');
      return filter;
    }

  // //}
  //  //Dont translate localizedTextsMap
  // const localizedTextsMap = {
  //   noResultsOverlayLabel: 'Sökningen gav inga träffar',
  //   columnMenuUnsort: 'Sortera inte',
  //   columnMenuSortAsc: 'Sortera stigande',
  //   columnMenuSortDesc: 'Sortera fallande',
  //   columnMenuFilter: 'Filter',
  //   columnMenuHideColumn: 'Dölj',
  //   columnMenuShowColumns: 'Visa',
  //   toolbarFilters: 'Filter',
  //   toolbarColumns: 'Kolumner',

  //   // Filter panel text
  //   filterPanelAddFilter: 'Nytt filter',
  //   filterPanelDeleteIconLabel: 'Radera',
  //   filterPanelLinkOperator: 'Logisk operator',
  //   filterPanelOperators: 'Som...', //Operator TODO v6: rename to filterPanelOperator
  //   filterPanelOperatorAnd: 'Och',
  //   filterPanelOperatorOr: 'Eller',
  //   filterPanelColumns: 'Kolumn',
  //   filterPanelInputLabel: 'Värde',
  //   filterPanelInputPlaceholder: 'Värde',
  //   // Filter operators text
  //   filterOperatorContains: 'innehåller',
  //   filterOperatorEquals: 'är lika med',
  //   filterOperatorStartsWith: 'startar med',
  //   filterOperatorEndsWith: 'slutar med',
  //   filterOperatorIs: 'är',
  //   filterOperatorNot: 'är inte',
  //   filterOperatorAfter: 'is after',
  //   filterOperatorOnOrAfter: 'is on or after',
  //   filterOperatorBefore: 'is before',
  //   filterOperatorOnOrBefore: 'is on or before',
  //   filterOperatorIsEmpty: 'är tom',
  //   filterOperatorIsNotEmpty: 'är inte tom',
  //   filterOperatorIsAnyOf: 'är någon av',

  //   columnsPanelTextFieldLabel: 'Sök kolumnnamn',
  //   columnsPanelTextFieldPlaceholder: 'Kolumn namn',
  //   columnsPanelDragIconLabel: 'Sortera kolumner',
  //   columnsPanelShowAllButton: 'Visa alla',
  //   columnsPanelHideAllButton: 'Dölj alla',
  //   // Filter values text
  //   filterValueAny: 'alla',
  //   filterValueTrue: 'sant',
  //   filterValueFalse: 'falskt',

  //   // Density selector toolbar button text
  //   toolbarDensity: 'Radhöjd',
  //   toolbarDensityLabel: 'Radhöjd',
  //   toolbarDensityCompact: 'Kompakt',
  //   toolbarDensityStandard: 'Standard',
  //   toolbarDensityComfortable: 'Gles',
  //   // Export selector toolbar button text
  //   toolbarExport: 'Export',
  //   toolbarExportLabel: 'Export',
  //   toolbarExportCSV: 'Ladda ner som CSV',
  //   toolbarExportPrint: 'Skriv ut',
  //   toolbarExportExcel: 'Ladda ner som Excel',
  //   MuiTablePagination: {
  //     labelRowsPerPage: 'Visa antal poster',
  //     labelDisplayedRows: ({ from, to, count }) =>
  //       `${from}-${to} av ${count !== -1 ? count : `sammanlagt ${to}`}`,
  //   },
  // };
  // return localizedTextsMap;
};
export const MemToolBar = ({ gridId, showExport, exportFileName }) => {
  const [loadedMTB, setLoadedMTB] = useState(false);
  const apiRefC = useGridApiContext();
  let activate = false;
  if (!loadedMTB && window.global.dataGrid[gridId]) {
    setLoadedMTB(true);
    activate = true;
  }
  setTimeout(() => {
    if (activate && window.global.dataGrid[gridId]) {
      apiRefC.current.restoreState(window.global.dataGrid[gridId]);
    } else {
      window.global.dataGrid[gridId] = apiRefC.current.exportState();
    }
  });
 /*  return <GridToolbar />; */
   return (
    <ToolBar showExport={showExport} exportFileName={exportFileName} />
   );
};

//export default MemToolBar;
