import { Box, Stack, useMediaQuery } from "@mui/material";
import { useCallback, useState, useMemo, Fragment } from "react";
import Translate from "../../utils/Translate";
import Bowser from "bowser";

// A small adjustment needed for textbox height to avoid cutting off the lower part of letters like "g" or "y"
const heightAdjustment = 6;

const browser = Bowser.getParser(window.navigator.userAgent);
// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = browser.isBrowser("Safari");

// Move alpha channel to the back. The values come from WPF, where the color has a different representation
function reformatColor(color) {
  return `#${color.substring(3)}${color.substring(1, 3)}`;
}

function getTextAnchor(textAlignment) {
  switch (textAlignment) {
    case "Left":
      return "start";
    case "Center":
      return "middle";
    case "Right":
      return "end";
    default:
      return "start";
  }
}

function getTextBoxX({ placementLeft, textBoxWidth, textAlignment }) {
  switch (textAlignment) {
    case "Left":
      return placementLeft;
    case "Center":
      return placementLeft + 0.5 * textBoxWidth;
    case "Right":
      return placementLeft + textBoxWidth;
    default:
      return placementLeft;
  }
}

function getNumberOfTextRows(cardDetails, textBox) {
  return cardDetails.textBoxValues[textBox.name]?.split(/\r?\n/g).length ?? 1;
}

function ActorLicenseView({
  backgroundImage,
  printTemplate,
  cardDetails,
  isSelected,
  renderWidth,
  renderHeight,
  xScale,
  yScale,
  isPrint,
  isEditMode,
  hideTextBoxes,
  editModeProps,
  printType,
}) {
  const { onTextBoxChanged, onTextBoxSelected, selectedTextBox } = isEditMode
    ? editModeProps
    : {};

  // Without this the ActorLicensePrintView "display: none" seems to interfere with the non-print use of this component (the rounded corners disappear)
  const [randomId] = useState(window.crypto?.randomUUID() ?? Math.random());
  const [movingTextBox, setMovingTextBox] = useState(null);
  const [xScaleInv] = useState(1 / xScale);
  const [yScaleInv] = useState(1 / yScale);

  const profileImageBox = printTemplate.textBoxes.find(
    (t) => t.name === "txtCardImage"
  );

  const handleStartMoveTextBox = useCallback(
    (mouseEvent, textBox) => {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();

      // The mouseEvents offsets are from svg origin, and not from the selected rect
      // or text, so we have to compute the offsets within the actual selected element
      const { offsetX, offsetY } = mouseEvent.nativeEvent;
      const clickOffsetX = offsetX - textBox.placementLeft * xScale;
      const clickOffsetY = offsetY - textBox.placementTop * yScale;

      // We need to distinguish between moving and selected textbox, even if there is overlap.
      // The moving textbox is handled here in this component. The selected textbox is handled
      // by PrintTemplateEditDialog, where we can edit other properties of the textbox than pos.
      setMovingTextBox({
        ...textBox,
        clickOffsetX,
        clickOffsetY,
      });
      onTextBoxSelected(textBox);
    },
    [xScale, yScale, onTextBoxSelected]
  );

  const handleUpdateTextBoxPosition = useCallback(
    (mouseEvent) => {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();

      const { offsetX, offsetY } = mouseEvent.nativeEvent;
      setMovingTextBox((prevTextBox) =>
        prevTextBox
          ? {
              ...prevTextBox,
              placementLeft: (offsetX - prevTextBox.clickOffsetX) * xScaleInv,
              placementTop: (offsetY - prevTextBox.clickOffsetY) * yScaleInv,
            }
          : null
      );
    },
    [xScaleInv, yScaleInv]
  );

  const handleFinishMoveTextBox = useCallback(
    (mouseEvent) => {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      if (movingTextBox) {
        onTextBoxChanged(movingTextBox);
        setMovingTextBox(null);
      }
    },
    [movingTextBox, onTextBoxChanged]
  );

  const handleCancelMoveTextBox = useCallback((mouseEvent) => {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
    setMovingTextBox(null);
  }, []);

  function getFontSize(textBox) {
    return textBox.fontSize * yScale;
  }

  return (
    <svg
      width={renderWidth ?? printTemplate.width}
      height={renderHeight ?? printTemplate.height}
      xmlns="http://www.w3.org/2000/svg"
      {...(isEditMode
        ? {
            onMouseUp: handleFinishMoveTextBox,
            onMouseLeave: handleCancelMoveTextBox,
            onMouseMove: handleUpdateTextBoxPosition,
          }
        : {})}
      // viewBox={`0 0 ${printTemplate.width} ${printTemplate.height}`}
    >
      <rect
        id={`rect${randomId}`}
        width="100%"
        height="100%"
        rx="6"
        fill="none"
        stroke={isPrint || !!backgroundImage ? "none" : "black"}
      />
      <clipPath id={`clip${randomId}`}>
        <use xlinkHref={`#rect${randomId}`} />
      </clipPath>
      <image
        href={backgroundImage}
        width="100%"
        height="100%"
        clipPath={`url(#clip${randomId})`}
        preserveAspectRatio="xMidYMid slice" // Viktig https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
        opacity={isSelected ? 1 : 0.3}
      />
      {!hideTextBoxes &&
        printTemplate.textBoxes.map((tb) => {
          // If we are moving a textBox, we want to use the position of that
          // one from the local state instead of the original position
          const textBox =
            movingTextBox && tb.name === movingTextBox.name
              ? movingTextBox
              : tb;
          return (
            <Fragment key={textBox.name}>
              <rect
                className={
                  "textBox" +
                  (selectedTextBox && selectedTextBox.name === textBox.name
                    ? " selected"
                    : "")
                }
                id={`rect-${textBox.name}-${randomId}`}
                x={textBox.placementLeft * xScale}
                y={textBox.placementTop * yScale - 15}
                width={textBox.textBoxWidth * xScale}
                height={`${
                  textBox.fontSize *
                    getNumberOfTextRows(cardDetails, textBox) *
                    yScale +
                  heightAdjustment +
                  15
                }px`}
                fill="none"
                {...(isEditMode
                  ? {
                      onMouseDown: (ev) => handleStartMoveTextBox(ev, textBox),
                    }
                  : {})}
              />
              <clipPath id={`clip-${textBox.name}-${randomId}`}>
                <use xlinkHref={`#rect-${textBox.name}-${randomId}`} />
              </clipPath>
              <text
                x={getTextBoxX(textBox) * xScale}
                y={textBox.placementTop * yScale}
                fontFamily={textBox.fontFamily}
                fontSize={getFontSize(textBox)}
                fontStyle={textBox.fontStyle}
                fontWeight={textBox.fontWeights}
                style={{
                  fill: reformatColor(textBox.textColor),
                }}
                dominantBaseline="hanging"
                dy={isSafari ? "0.75em" : ""}
                textAnchor={getTextAnchor(textBox.textAlignment)}
                clipPath={`url(#clip-${textBox.name}-${randomId})`}
                opacity={isSelected ? 1 : 0.3}
              >
                {cardDetails.textBoxValues[textBox.name]
                  ?.split(/\r?\n/g)
                  .map(function (item, i) {
                    const rowDisplacement =
                      i > 0
                        ? {
                            x: getTextBoxX(textBox) * xScale,
                            dy: textBox.fontSize * yScale,
                          }
                        : {};
                    return (
                      <tspan key={textBox.name + i} {...rowDisplacement}>
                        {item}
                      </tspan>
                    );
                  })}
              </text>
            </Fragment>
          );
        })}
      {!hideTextBoxes && profileImageBox && cardDetails.actorImage && (
        <image
          href={cardDetails.actorImage}
          x={profileImageBox.placementLeft * xScale}
          y={profileImageBox.placementTop * yScale}
          width={profileImageBox.textBoxWidth * xScale}
          height={profileImageBox.fontSize * yScale}
          preserveAspectRatio="xMidYMid slice"
          opacity={isSelected ? 1 : 0.3}
        />
      )}
    </svg>
  );
}

export default function ActorLicensePreview({
  printTemplate,
  cardDetails,
  isSelected,
  onSelectedChanged,
  showFront,
  showBack,
  renderWidth,
  renderHeight,
  isPrint,
  isEditMode = false,
  editModeProps,
  printType,
  showPrintSideTitleOverride,
}) {
  if (isPrint && isEditMode) {
    throw new Error("Cannot edit print");
  }
  if (isEditMode && !editModeProps) {
    throw new Error("Missing props");
  }

  const showPrintSideTitle =
    showPrintSideTitleOverride === null ||
    showPrintSideTitleOverride === undefined
      ? !isPrint && !isEditMode && showFront && showBack
      : showPrintSideTitleOverride;

  const isSmall = useMediaQuery(
    `(max-width:${2 * (renderWidth ?? printTemplate.width) + 100}px)`
  );

  const xScale = useMemo(
    () => (renderWidth ? renderWidth / printTemplate.width : 1),
    [renderWidth, printTemplate]
  );
  const yScale = useMemo(
    () => (renderHeight ? renderHeight / printTemplate.height : 1),
    [renderHeight, printTemplate]
  );

  const minWidth = useMemo(() => {
    const width = renderWidth ?? printTemplate.width;
    if (isSmall || isPrint) {
      return width;
    } else {
      return showFront && showBack ? 2 * width + 16 : width;
    }
  }, [renderWidth, printTemplate, isSmall, isPrint, showFront, showBack]);

  return (
    printTemplate && (
      <Box
        onClick={onSelectedChanged}
        className={
          "actorLicensePreview" +
          (isSmall ? " small" : "") +
          (isEditMode ? " edit" : "")
        }
        minWidth={`${minWidth}px`}
      >
        {showFront && (
          <Stack direction="column" alignItems="center" gap="5px">
            {showPrintSideTitle && (
              <Box fontWeight="500">{Translate.get("PrintSideFront")}</Box>
            )}
            <ActorLicenseView
              backgroundImage={printTemplate.imageFrontUrl}
              printTemplate={printTemplate}
              cardDetails={cardDetails}
              isSelected={isSelected}
              renderWidth={renderWidth}
              renderHeight={renderHeight}
              xScale={xScale}
              yScale={yScale}
              isEditMode={isEditMode}
              editModeProps={editModeProps}
              printType={printType}
            />
          </Stack>
        )}
        {showBack && (
          <Stack direction="column" alignItems="center" gap="5px">
            {showPrintSideTitle && (
              <Box fontWeight="500">{Translate.get("PrintSideBack")}</Box>
            )}
            <ActorLicenseView
              backgroundImage={printTemplate.imageBackUrl}
              printTemplate={printTemplate}
              cardDetails={cardDetails}
              isSelected={isSelected}
              renderWidth={renderWidth}
              renderHeight={renderHeight}
              xScale={xScale}
              yScale={yScale}
              isEditMode={isEditMode}
              editModeProps={editModeProps}
              hideTextBoxes
            />
          </Stack>
        )}
      </Box>
    )
  );
}
