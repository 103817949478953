import { Box, Button, IconButton, Stack } from "@mui/material";
import Translate from "../../../utils/Translate";
import { Clear, KeyboardReturn } from "@mui/icons-material";
import ActorAvatar from "../../ActorAvatar";

export default function DuplicateActors({
  duplicate,
  onSelect,
  onMarkAsNotDuplicate,
}) {
  // console.log("DuplicateActors", duplicate);
  return (
    <Stack direction="column" className="duplicateActors">
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onMarkAsNotDuplicate}>
          <Clear />
        </IconButton>
      </Box>
      {/* <Stack direction="row">
        <ActorAvatar
          vertical
          name={duplicate.actor1.actorName}
          email={duplicate.actor1.actorEmail}
          extraFields={[
            duplicate.actor1.orgNo,
            duplicate.actor1.externalActorNo,
            duplicate.actor1.childActorGroups
              ?.map((x) => x.parentActorName)
              .filter((x) => x.trim() !== "ePortMaster")
              .toSorted((a, b) => a.localeCompare(b))
              .join(", "),
          ]}
        />
        <ActorAvatar
          vertical
          name={duplicate.actor2.actorName}
          email={duplicate.actor2.actorEmail}
          extraFields={[
            duplicate.actor2.orgNo,
            duplicate.actor2.externalActorNo,
            duplicate.actor2.childActorGroups
              ?.map((x) => x.parentActorName)
              .filter((x) => x.trim() !== "ePortMaster")
              .toSorted((a, b) => a.localeCompare(b))
              .join(", "),
          ]}
        />
      </Stack> */}
      <Box>
        <table>
          <tr>
            <td>{duplicate.actor1.actorName}</td>
            <td>{duplicate.actor2.actorName}</td>
          </tr>
          <tr>
            <td>{duplicate.actor1.actorEmail}</td>
            <td>{duplicate.actor2.actorEmail}</td>
          </tr>
          <tr>
            <td>{duplicate.actor1.orgNo}</td>
            <td>{duplicate.actor2.orgNo}</td>
          </tr>
          <tr>
            <td>{duplicate.actor1.externalActorNo}</td>
            <td>{duplicate.actor2.externalActorNo}</td>
          </tr>
          <tr>
            <td>
              {duplicate.actor1.childActorGroups
                ?.map((x) => x.parentActorName)
                .filter((x) => x.trim() !== "ePortMaster")
                .toSorted((a, b) => a.localeCompare(b))
                .join(", ")}
            </td>
            <td>
              {duplicate.actor2.childActorGroups
                ?.map((x) => x.parentActorName)
                .filter((x) => x.trim() !== "ePortMaster")
                .toSorted((a, b) => a.localeCompare(b))
                .join(", ")}
            </td>
          </tr>
        </table>
      </Box>
      <Box padding="6px">
        <Button fullWidth onClick={onSelect} variant="outlined">
          {Translate.get("Select")}
        </Button>
      </Box>
    </Stack>
  );
}
