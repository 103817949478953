import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import DomainIcon from "@mui/icons-material/Domain";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { useState, useCallback, useEffect } from "react";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useSnackbar } from "notistack";
import {
  actorSearch,
  actorPersonTypes,
  actorCompanyTypes,
} from "../../utils/actorSearch";
import { hasAccess, accessKeys } from "../../utils/userAccess";
import Preloader from "../Preloader";
import YesOrNoDialog from "../YesOrNoDialog";
import PersonCrudDialog from "./crud/PersonCrudDialog";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format, addHours } from "date-fns";

// When copanyActorId is set, it means that we are going to add a person to that company.
// When personActorId is set, it means that we are going to add that person to a company.
// In other words, in the first case we will search for persons, in the latter companies.
// There is also a third case, when companyActorId and connectedPersonActorId is set.
// This is when we want to edit an existing connection between a company and a person.
export default function ConnectPersonAndCompanyDialog({
  companyActorId,
  companyExternalDataId,
  connectedPersonActorId,
  personActorId,
}) {
  if (
    ((companyActorId || connectedPersonActorId) && personActorId) ||
    (connectedPersonActorId && !companyActorId)
  ) {
    throw new Error("Invalid props");
  }

  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [person, setPerson] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [ePortProfileId, setEPortProfileId] = useState(null);
  const [addedActorTypes, setAddedActorTypes] = useState([]);
  const [actorUserConnections, setActorUserConnections] = useState([]);
  const [accountActorConnections, setAccountActorConnections] = useState([]);
  const [actorHasActiveAgreements, setActorHasActiveAgreements] = useState({});
  const [actorHasInactiveAgreements, setActorHasInactiveAgreements] = useState(
    {}
  );
  const [deleteAgreementUser, setDeleteAgreementUser] = useState(false);
  const [showDeleteAgreementUserQuestion, setShowDeleteAgreementUserQuestion] =
    useState(false);
  const [changedActorUserConnection, setChangedActorUserConnection] =
    useState(null);
  const [enableValidUntil, setEnableValidUntil] = useState(false);
  const [validUntil, setValidUntil] = useState(null);
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const close = () => {
    window.history.back();
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    async function load(externalDataId) {
      const responseProfiles = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles?externalDataId=${externalDataId}`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (responseProfiles && Array.isArray(responseProfiles)) {
        setProfiles(responseProfiles);
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/connections?externalDataId=${externalDataId}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        const courseAdminUsers = response.connections.filter(
          (c) => c.hasCourseAdmin
        );
        const entriesActorUsers = courseAdminUsers.map((c) => [
          c.actorId,
          {
            ePortUserId: c.ePortUserId,
            isReadOnly: c.isReadOnly,
            ePortProfileId: c.ePortProfileId,
            validUntil: c.validUntil
              ? format(new Date(c.validUntil), "yyyy-MM-dd HH:mm:ss")
              : null,
          },
        ]);
        const connectionsByActorId = Object.fromEntries(entriesActorUsers);
        setActorUserConnections(connectionsByActorId);

        const actorId = personActorId ?? connectedPersonActorId;
        if (actorId && connectionsByActorId[actorId]) {
          setEPortProfileId(connectionsByActorId[actorId].ePortProfileId);
          setValidUntil(connectionsByActorId[actorId].validUntil);
          setEnableValidUntil(!!connectionsByActorId[actorId].validUntil);
        }

        const entriesAccountActors = courseAdminUsers.map((c) => [
          c.ePortAccountId,
          c.actorId,
        ]);
        const connectionsByAccountId = Object.fromEntries(entriesAccountActors);
        setAccountActorConnections(connectionsByAccountId);

        const entriesActorActiveAgreements = response.connections.map((c) => [
          c.actorId,
          c.hasActiveAgreement,
        ]);
        const activeAgreementsByActorId = Object.fromEntries(
          entriesActorActiveAgreements
        );
        setActorHasActiveAgreements(activeAgreementsByActorId);

        const entriesActorInactiveAgreements = response.connections.map((c) => [
          c.actorId,
          c.hasInactiveAgreement,
        ]);
        const inactiveAgreementsByActorId = Object.fromEntries(
          entriesActorInactiveAgreements
        );
        setActorHasInactiveAgreements(inactiveAgreementsByActorId);

        setChangedActorUserConnection(null);
      }
    }
    const externalDataId = companyExternalDataId ?? company?.externalDataId;
    if (externalDataId && hasAccess(accessKeys.licenseIssuer)) {
      load(externalDataId);
    } else {
      setActorUserConnections([]);
      setAccountActorConnections([]);
      setActorHasActiveAgreements({});
      setActorHasInactiveAgreements({});
      setChangedActorUserConnection(null);
    }
  }, [
    api,
    company,
    companyActorId,
    companyExternalDataId,
    connectedPersonActorId,
    personActorId,
  ]);

  useEffect(() => {
    const actorId = personActorId ?? connectedPersonActorId;
    if (actorId) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/as-person`,
          false,
          "GET"
        )
        .then((data) => {
          if (data) {
            setPerson({ ...data, email: data.actorEmail });
          }
        });
    }
  }, [api, personActorId, connectedPersonActorId]);

  useEffect(() => {
    setChangedActorUserConnection(null);
    const newProfileId =
      actorUserConnections && person && actorUserConnections[person.actorId]
        ? actorUserConnections[person.actorId].ePortProfileId
        : null;
    const newValidUntil =
      actorUserConnections && person && actorUserConnections[person.actorId]
        ? actorUserConnections[person.actorId].validUntil
        : null;
    setEPortProfileId(newProfileId);
    setValidUntil(newValidUntil);
    setEnableValidUntil(!!newValidUntil);
  }, [api, person, actorUserConnections]);

  useEffect(() => {
    if (isLoading) {
      const personLoaded = !personActorId || (personActorId && person);
      const connectedPersonLoaded =
        !connectedPersonActorId || (connectedPersonActorId && person);
      const finishedLoading = personLoaded && connectedPersonLoaded;
      setIsLoading(!finishedLoading);
    }
  }, [isLoading, personActorId, connectedPersonActorId, person]);

  async function postActor() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/`,
      person,
      "POST"
    );
    return response.actorId;
  }

  async function postStudentConnection(personActorId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${
        companyActorId ?? company.actorId
      }/students`,
      { actorId: personActorId },
      "POST"
    );
    return !!response && !!response.actorId;
  }

  async function postContactConnection(personActorId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/clients/${
        companyActorId ?? company.actorId
      }/contacts`,
      { actorId: personActorId },
      "POST"
    );
    return !!response && !!response.actorId;
  }

  async function postAddUser(personActorId) {
    const externalDataId = companyExternalDataId ?? company?.externalDataId;
    const contactActorId = personActorId;
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users?externalDataId=${externalDataId}&contactActorId=${contactActorId}&ePortProfileId=${ePortProfileId}&validUntil=${validUntil}`,
      true,
      "POST"
    );
    return response.isSuccessful;
  }

  async function postChangeUserProfile(personActorId) {
    const externalDataId = companyExternalDataId ?? company?.externalDataId;
    const contactActorId = personActorId;
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/change-profile?externalDataId=${externalDataId}&contactActorId=${contactActorId}&newEPortProfileId=${ePortProfileId}`,
      true,
      "POST"
    );
    return response.isSuccessful;
  }

  async function postUpdateUserValidUntil(personActorId) {
    const externalDataId = companyExternalDataId ?? company?.externalDataId;
    const contactActorId = personActorId;
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/valid-until?externalDataId=${externalDataId}&contactActorId=${contactActorId}&validUntil=${validUntil}`,
      true,
      "POST"
    );
    return response.isSuccessful;
  }

  async function deleteUser() {
    const contactActorId = person.actorId;
    const ePortUserId = actorUserConnections[contactActorId].ePortUserId;
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users?ePortUserId=${ePortUserId}&deleteAgreementUser=${deleteAgreementUser}`,
      true,
      "DELETE"
    );
    return response.isSuccessful;
  }

  const compareCompanies = useCallback(
    (lhs, rhs) => {
      const lhsIsConnectedToPerson =
        person.studentAtCompanies.some((c) => c.actorId === lhs.actorId) ||
        person.contactForCompanies.some((c) => c.actorId === lhs.actorId);
      const rhsIsConnectedToPerson =
        person.studentAtCompanies.some((c) => c.actorId === rhs.actorId) ||
        person.contactForCompanies.some((c) => c.actorId === rhs.actorId);
      if (lhsIsConnectedToPerson && !rhsIsConnectedToPerson) {
        return -1;
      }
      if (rhsIsConnectedToPerson && !lhsIsConnectedToPerson) {
        return 1;
      }
      // Use the order from backend in this case
      return 0;
    },
    [person]
  );

  const companySearch = useCallback(
    async (searchText) => {
      const foundCompanies = await actorSearch(
        searchText,
        actorCompanyTypes,
        ["ActorName", "OrgNo"],
        150
      );
      if (foundCompanies) {
        return foundCompanies.sort(compareCompanies).map((company) => ({
          actorId: company.actorId,
          actorName: company.actorName,
          orgNo: company.orgNo,
          externalDataId: company.externalDataId,
        }));
      }
    },
    [compareCompanies]
  );

  const compareContacts = useCallback(
    (lhs, rhs) => {
      const lhsIsConnectedToCompany = lhs.parentActors?.some(
        (parent) => parent.parentActor.actorId === companyActorId
      );
      const rhsIsConnectedToCompany = rhs.parentActors?.some(
        (parent) => parent.parentActor.actorId === companyActorId
      );
      if (lhsIsConnectedToCompany && !rhsIsConnectedToCompany) {
        return -1;
      }
      if (rhsIsConnectedToCompany && !lhsIsConnectedToCompany) {
        return 1;
      }
      // Use the order from backend in this case
      return 0;
    },
    [companyActorId]
  );

  const personSearch = useCallback(
    async (searchText) => {
      const foundContacts = await actorSearch(
        searchText,
        actorPersonTypes,
        ["ActorName", "OrgNo", "ActorEmail"],
        150
      );
      if (foundContacts) {
        return foundContacts.sort(compareContacts).map((contact) => ({
          actorId: contact.actorId,
          actorName: contact.actorName,
          orgNo: contact.orgNo,
          email: contact.email,
          contactForCompanies: contact.parentActors
            ?.filter((x) => x.actorType === 3)
            .map((x) => ({ actorId: x.parentActor.actorId })),
          studentAtCompanies: contact.parentActors
            ?.filter((x) => x.actorType === 4)
            .map((x) => ({ actorId: x.parentActor.actorId })),
          companies: contact.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId !== contact.actorId &&
                x.parentActor.actorId > 0 &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
      }
    },
    [compareContacts]
  );

  function handleConnectionChecked(event, actorType) {
    const isChecked = event.target.checked;
    const addedConnectionsCopy = addedActorTypes.filter((a) => a !== actorType);
    if (isChecked) {
      addedConnectionsCopy.push(actorType);
    }
    setAddedActorTypes(addedConnectionsCopy);
  }

  function handleUserAccountChecked(event) {
    const isChecked = event.target.checked;
    const hasExistingConnection =
      !!person && !!actorUserConnections[person.actorId];

    // If it was originally checked and now checked, we set the change tracking state to null
    // to avoid trying to create an already existing user, or deleting a non-existent user.
    const newChangedActorUserConnection =
      isChecked === hasExistingConnection ? null : isChecked;
    setChangedActorUserConnection(newChangedActorUserConnection);

    if (isChecked && !isContactChecked()) {
      // Need contact for user
      setAddedActorTypes([...addedActorTypes, 3]);
    }

    if (!isChecked) {
      setEPortProfileId(null);
    } else if (hasExistingConnection) {
      setEPortProfileId(actorUserConnections[person.actorId].ePortProfileId);
    } else {
      setEPortProfileId(profiles[0].ePortProfileId);
    }

    if (
      !!hasExistingConnection &&
      !newChangedActorUserConnection &&
      (actorHasActiveAgreements[person.actorId] ||
        actorHasInactiveAgreements[person.actorId])
    ) {
      setShowDeleteAgreementUserQuestion(true);
    }
  }

  async function handleDoConnectPerson() {
    let actorId = person.actorId;
    let actorOk = !!actorId;

    if (!person.actorId) {
      actorId = await postActor();
      actorOk = !!actorId;
    }

    if (!actorOk) {
      return;
    }

    if (addedActorTypes.some((a) => a === 4)) {
      await postStudentConnection(actorId);
    }
    if (addedActorTypes.some((a) => a === 3)) {
      await postContactConnection(actorId);
    }

    if (changedActorUserConnection) {
      await postAddUser(actorId);
    } else if (changedActorUserConnection === false) {
      await deleteUser();
    }

    if (changedActorUserConnection === null) {
      const changedProfile =
        ePortProfileId != actorUserConnections[actorId]?.ePortProfileId;
      if (changedProfile) {
        await postChangeUserProfile(actorId);
      }
      const changedValidUntil =
        validUntil != actorUserConnections[actorId]?.validUntil;
      if (changedValidUntil) {
        await postUpdateUserValidUntil(actorId);
      }
    }

    if (actorOk) {
      closeAndReload();
    }
  }

  function companyHasExternalDataId() {
    return companyExternalDataId ?? company?.externalDataId;
  }

  function isContactChecked() {
    return (
      !!person &&
      (person.contactForCompanies?.some(
        (c) => c.actorId === (companyActorId ?? company?.actorId)
      ) ||
        addedActorTypes.some((a) => a === 3))
    );
  }

  function isUserAccountChecked() {
    return changedActorUserConnection !== null
      ? !!person && !!changedActorUserConnection
      : !!person && !!actorUserConnections[person.actorId];
  }

  function isSaveButtonDisabled() {
    const hasUser = !!person && actorUserConnections[person.actorId];
    const profileChanged = hasUser
      ? ePortProfileId != actorUserConnections[person.actorId].ePortProfileId
      : false;
    const validUntilChanged = hasUser
      ? validUntil != actorUserConnections[person.actorId].validUntil
      : false;
    return (
      !person ||
      (!companyActorId && !company) ||
      (addedActorTypes.length === 0 &&
        changedActorUserConnection === null &&
        !profileChanged &&
        !validUntilChanged) ||
      (changedActorUserConnection === true && !ePortProfileId)
    );
  }

  return (
    <>
      {isLoading && <Preloader />}
      {!!person && !person.actorId && (
        <PersonCrudDialog
          open={true}
          personCrudProps={{
            name: person.actorName,
            parentActorId: companyActorId,
            onCancel: () => setPerson(null),
            onClose: () => {},
            onActorSaved: (actor) =>
              setPerson({ ...actor, email: actor.actorEmail }),
          }}
        />
      )}
      <DialogTitle>
        {companyActorId
          ? connectedPersonActorId
            ? Translate.get("EditPersonalConnection")
            : Translate.get("AddAPerson") //AddPersonalConnection
          : Translate.get("AddCompanyConnection")}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "600px" }}>
        <Typography
          variant="body2"
          sx={{
            margin: "10px 0 32px",
            color: "text.secondary",
          }}
        >
          {companyActorId
            ? Translate.get("ChooseConnectCompany")
            : Translate.get("ChooseConnectPerson")}
        </Typography>
        <Stack spacing={4} sx={{ marginLeft: "9px" }}>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            {companyActorId ? <PeopleIcon /> : <DomainIcon />}
            <AutocompleteSearchField
              disabled={!!connectedPersonActorId}
              label={
                companyActorId
                  ? Translate.get("Person")
                  : Translate.get("Company")
              }
              value={companyActorId ? person : company}
              onValueChange={
                companyActorId
                  ? (person) => setPerson(person)
                  : (company) => setCompany(company)
              }
              getOptionLabel={(option) => option?.actorName ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.actorName}
                  orgNo={option?.orgNo}
                  email={companyActorId ? option?.email : ""}
                  companies={companyActorId ? option?.companies : ""}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={companyActorId ? personSearch : companySearch}
              createNewValue={(text) => ({
                actorName: text,
              })}
              minWidth={isSmallScreenOrGreater ? "496px" : "250px"}
              textFieldProps={{ required: true }}
            />
          </Stack>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => handleConnectionChecked(event, 4)}
                  checked={
                    !!person &&
                    (person.studentAtCompanies?.some(
                      (c) => c.actorId === (companyActorId ?? company?.actorId)
                    ) ||
                      addedActorTypes.some((a) => a === 4))
                  }
                  disabled={
                    !person ||
                    (!companyActorId && !company) ||
                    person.studentAtCompanies?.some(
                      (c) => c.actorId === (companyActorId ?? company?.actorId)
                    )
                  }
                />
              }
              label={Translate.get("ActorType_Student")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => handleConnectionChecked(event, 3)}
                  checked={isContactChecked()}
                  disabled={
                    !person ||
                    (!companyActorId && !company) ||
                    person.contactForCompanies?.some(
                      (c) => c.actorId === (companyActorId ?? company?.actorId)
                    ) ||
                    isUserAccountChecked()
                  }
                />
              }
              label={Translate.get("ActorType_Contact")}
            />
            {hasAccess(accessKeys.licenseIssuer) &&
              companyHasExternalDataId() && (
                <>
                  <Stack direction="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleUserAccountChecked}
                          checked={isUserAccountChecked()}
                          disabled={
                            !person ||
                            !person?.email ||
                            (!!accountActorConnections[person.email.trim()] &&
                              accountActorConnections[person.email.trim()] !==
                                person.actorId)
                          }
                        />
                      }
                      label={
                        Translate.get("UserAccount") +
                        (!!person && !person.email
                          ? ` (${Translate.get("EmailRequired").toLowerCase()})`
                          : "") +
                        (!!person &&
                        person.email &&
                        !!accountActorConnections[person.email.trim()] &&
                        accountActorConnections[person.email.trim()] !==
                          person.actorId
                          ? ` (${Translate.get(
                              "OtherCompanyUserAlreadyHasTheEmail"
                            ).toLowerCase()})`
                          : "") +
                        (!!person &&
                        !!actorUserConnections[person.actorId] &&
                        actorUserConnections[person.actorId].isReadOnly &&
                        !(profiles || profiles.length > 1)
                          ? ` (${Translate.get("ReadonlyUser").toLowerCase()})`
                          : "")
                      }
                    />
                    {profiles &&
                      profiles.length > 1 &&
                      person &&
                      person.email &&
                      (!accountActorConnections[person.email.trim()] ||
                        accountActorConnections[person.email.trim()] ===
                          person.actorId) && (
                        <FormControl fullWidth>
                          <InputLabel
                            id="profile-select-label"
                            required={isUserAccountChecked()}
                          >
                            {Translate.get("Profile")}
                          </InputLabel>
                          <Select
                            required={isUserAccountChecked()}
                            disabled={!isUserAccountChecked()}
                            labelId="profile-select-label"
                            id="profile-select"
                            label={Translate.get("Profile")}
                            value={ePortProfileId ?? ""}
                            onChange={(event) =>
                              setEPortProfileId(event.target.value)
                            }
                          >
                            {profiles.map((x) => (
                              <MenuItem
                                key={x.ePortProfileId}
                                value={x.ePortProfileId}
                              >
                                {`${Translate.getEPortLicenseLineType(
                                  x.ePortCloudLicenseLineType
                                )}${
                                  x.roleId
                                    ? ` - ${x.roleShortName}`
                                    : " - STANDARD"
                                }`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      margin: "0 0 0 32px",
                      color: "text.secondary",
                    }}
                  >
                    {Translate.get("UserAccountConnectInfo")}
                  </Typography>
                  {hasAccess(accessKeys.isMASystem) &&
                    isUserAccountChecked() && (
                      <Stack direction="row" marginLeft="30px" marginTop="6px">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) => {
                                setEnableValidUntil(event.target.checked);
                                if (!event.target.checked) {
                                  setValidUntil(null);
                                } else {
                                  setValidUntil(
                                    format(
                                      addHours(new Date(), 24),
                                      "yyyy-MM-dd HH:mm:ss"
                                    )
                                  );
                                }
                              }}
                              checked={enableValidUntil}
                              disabled={
                                !person ||
                                !person?.email ||
                                (!!accountActorConnections[
                                  person.email.trim()
                                ] &&
                                  accountActorConnections[
                                    person.email.trim()
                                  ] !== person.actorId)
                              }
                            />
                          }
                          label="Tillfällig"
                        />
                        <DateTimePicker
                          disabled={!enableValidUntil}
                          value={validUntil}
                          onChange={(newValue) => {
                            setValidUntil(
                              format(new Date(newValue), "yyyy-MM-dd HH:mm:ss")
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Stack>
                    )}
                </>
              )}
            {hasAccess(accessKeys.licenseIssuer) &&
              hasAccess(accessKeys.isMASystem) &&
              companyHasExternalDataId() && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!person &&
                        (actorHasActiveAgreements[person.actorId] ||
                          actorHasInactiveAgreements[person.actorId]) &&
                        !deleteAgreementUser
                      }
                      disabled
                    />
                  }
                  label={
                    Translate.get("ServiceAgreement") +
                    (!!person &&
                    !actorHasActiveAgreements[person.actorId] &&
                    actorHasInactiveAgreements[person.actorId]
                      ? ` (${Translate.get("Inactive").toLowerCase()})`
                      : "")
                  }
                />
              )}
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleDoConnectPerson}
          disabled={isSaveButtonDisabled()}
        >
          {Translate.get("Connect")}
        </ButtonWithSpinner>
      </DialogActions>
      <YesOrNoDialog
        open={!!showDeleteAgreementUserQuestion}
        title={Translate.get("DeleteUserFromAgreement")}
        text={Translate.get("DeleteUserFromAgreementQuestion")}
        onNo={() => {
          setDeleteAgreementUser(false);
          setShowDeleteAgreementUserQuestion(false);
        }}
        onYes={() => {
          setDeleteAgreementUser(true);
          setShowDeleteAgreementUserQuestion(false);
        }}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
    </>
  );
}
