import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../../utils/Translate";
import { useState } from "react";

export default function EditEPortLicenseLine({
  description,
  onSave,
  onClose,
  licenseLine,
}) {
  const [licenseLineData, setLicenseLineData] = useState(licenseLine);

  return (
    <Dialog maxWidth={false} scroll="paper" onClose={onClose} open={true}>
      <DialogTitle>{Translate.get("EditLicenseLine")}</DialogTitle>
      <DialogContent className="stdDialogContent">
        {description}

        <Stack gap="16px" marginTop="16px">
          <TextField
            label={Translate.get("PricePerUnit")}
            value={licenseLineData.price ?? ""}
            onChange={(ev) =>
              setLicenseLineData((prev) => ({
                ...prev,
                price: ev.target.value,
              }))
            }
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <Button
          variant="contained"
          disabled={!licenseLineData.price && licenseLineData.price != 0}
          onClick={() => onSave(licenseLineData)}
        >
          {Translate.get("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
