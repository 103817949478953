import { Grid } from "@mui/material";
import TooltipField from "./TooltipField";

export default function TooltipFields({ fields, width }) {
  return (
    <Grid container width={width ?? "150px"}>
      {fields &&
        fields.map((field, index) => <TooltipField key={index} {...field} />)}
    </Grid>
  );
}
