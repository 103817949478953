import { useState } from "react";
import Preloader from "../components/Preloader";
import OrdersGrid from "../components/orders/OrdersGrid";
import { useCallback } from "react";
import {
  Box,
  Button,
  Card,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Translate from "../utils/Translate";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import OrderFormBase from "../components/orders/OrderFormBase";
import { accessKeys, hasAccess } from "../utils/userAccess";
import { Search } from "@mui/icons-material";

export default function OrdersPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [unsubmittedSearchText, setUnsubmittedSearchText] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const finishedLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const speedMenuActions = [];
  speedMenuActions.push({
    icon: <AddIcon />,
    text: Translate.get("CreateOrder"),
    customClass: hasAccess(accessKeys.isMASystem)
      ? "bigCourseDialog"
      : "mediumCourseDialog",
    component: <OrderFormBase />,
  });

  return (
    <>
      {isLoading && <Preloader />}
      <SpeedMenu content={speedMenuActions} />
      <div className="stdFlexRowTop">
        <h2>{Translate.get("Orders")}</h2>
        <Box width="100%" marginLeft="8px">
          <form
            className="actorsSearchForm"
            onSubmit={(ev) => {
              ev.preventDefault();
              setSearchText(unsubmittedSearchText);
            }}
          >
            <TextField
              inputProps={{ spellCheck: false }}
              fullWidth
              id="outlined-basic"
              label={Translate.get("Search")}
              variant="outlined"
              value={unsubmittedSearchText ?? ""}
              onChange={(ev) => {
                setUnsubmittedSearchText(ev.target.value);
                if (!ev.target.value) {
                  // Normally we will only update the searchText on submit. But if the text
                  // is cleared it might be confusing to only see previous search results
                  setSearchText(null);
                }
              }}
            />
            {!isSmallScreen && (
              <Button
                type="submit"
                className="actorsSearchButton"
                variant="contained"
                endIcon={<Search />}
              >
                {Translate.get("Search")}
              </Button>
            )}
          </form>
        </Box>
      </div>
      <Card>
        <OrdersGrid searchText={searchText} onLoaded={finishedLoading} />
      </Card>
    </>
  );
}
