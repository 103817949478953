import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useMemo } from "react";
import {
  IconAndLabelColumnHeader,
  getGridColDefForArrayValues,
} from "../../utils/dataGrid";
import PersonIcon from "@mui/icons-material/Person";
//import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import NumbersIcon from "@mui/icons-material/Numbers";
//import LabelIcon from "@mui/icons-material/Label";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PlaceIcon from "@mui/icons-material/Place";
import LinkIcon from "@mui/icons-material/Link";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import Translate from "../../utils/Translate";
//import ActorAvatar from "../ActorAvatar";
import { TableAvatar } from "../../utils/dataGrid";
import InstructorCard from "./InstructorCard";
import DotMenu from "../DotMenu";
import SpeedMenu from "../SpeedMenu";
import { useSnackbar } from "notistack";
import PersonCrud from "../actors/crud/PersonCrud";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { useNavigate } from "react-router-dom";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { dataGridLang } from "../MemToolBar";
import InstructorFilterGroupsDialog from "./InstructorFilterGroupsDialog";

export default function InstructorSettings(props) {
  const localizedTextsMap = dataGridLang();
  const [isLoading, setIsLoading] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const showCalendarLinks = hasAccess(accessKeys.advancedPackage);

  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up(700));

  const getOrCreateCalendar = useCallback(
    async (teacherActorId) => {
      const url = !!teacherActorId
        ? `${process.env.REACT_APP_MAIN_URL}calendars/for-teacher/${teacherActorId}`
        : `${process.env.REACT_APP_MAIN_URL}calendars/for-all-teachers`;

      const response = await api.fetchWithOverride(
        url,
        false,
        "GET",
        (response) => !!response
      );
      if (!!response) {
        navigator.clipboard.writeText(response);
        const infoMessage = enqueueSnackbar(
          Translate.get("CopiedToClipboard"),
          {
            variant: "info",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(infoMessage),
          }
        );
      }
    },
    [enqueueSnackbar, closeSnackbar, api]
  );

  const speedMenuActions = [];
  speedMenuActions.push({
    icon: <AddIcon />,
    text: Translate.get("AddInstructor"),
    component: <PersonCrud actorRole="teacher" isEditOnly />,
  });

  if (showCalendarLinks === true) {
    speedMenuActions.push({
      icon: <LinkIcon />,
      text: Translate.get("CalendarLink"),
      onClick: () => getOrCreateCalendar(),
    });
  }

  const loadTeachers = useCallback(async () => {
    let response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/teachers?skipFilter=True`,
      false,
      "GET"
    );
    if (!!response) {
      response = response.map((t) => ({ ...t, actorEmail: t.email }));
      !!response && setTeachers(response);
    }
    setIsLoading(false);
  }, [api]);

  const deleteTeacher = useCallback(
    async (teacherActorId) => {
      setTeachers((currentTeachers) =>
        currentTeachers.filter((t) => t.actorId !== teacherActorId)
      );
      await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/teachers/${teacherActorId}`,
        true,
        "DELETE"
      );
      loadTeachers();
    },
    [loadTeachers, api]
  );

  useEffect(() => {
    loadTeachers();
  }, [loadTeachers]);

  useEffect(() => {
    async function loadFilterGroups() {
      const loadedFilterGroups = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/filters/groups`,
        false,
        "GET"
      );
      setFilterGroups(loadedFilterGroups);
    }
    loadFilterGroups();
  }, [api]);

  const getDotMenuActions = useCallback(
    (actorId, filterGroupIds) => {
      const menudata = [];
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        component: (
          <PersonCrud actorId={actorId} actorRole="teacher" isEditOnly />
        ),
      });
      if (showCalendarLinks === true) {
        menudata.push({
          icon: <LinkIcon />,
          text: Translate.get("CalendarLinkOne"),
          onClick: () => getOrCreateCalendar(actorId),
        });
      }
      if (hasAccess(accessKeys.advancedPackage)) {
        menudata.push({
          icon: <FilterListIcon />,
          text: Translate.get("FilterSettings"),
          dialogHeader: Translate.get("FilterSettings"),
          customClass: "mediumCourseDialog",
          component: (
            <InstructorFilterGroupsDialog
              teacherActorId={actorId}
              allFilterGroups={filterGroups}
              instructorFilterGroupIds={filterGroupIds}
            />
          ),
        });
      }
      menudata.push({
        icon: <DeleteIcon />,
        text: Translate.get("RemoveInstructor"),
        onClick: () => deleteTeacher(actorId),
      });
      return menudata;
    },
    [getOrCreateCalendar, deleteTeacher, showCalendarLinks, filterGroups]
  );

  const columns = useMemo(
    () => [
      {
        field: "actorName",
        flex: 1,
        minWidth: 300,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={PersonIcon}
            label={Translate.get("Name")}
          />
        ),
        renderCell: (params) => (
          <TableAvatar
            src={params.row.profilePictureUrl + ""}
            //variant="rounded"
            title={params.row.actorName}
            subtitle={params.row.email}
          />
        ),
      },
      {
        field: "externalActorNo",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={NumbersIcon}
            label={Translate.get("EmployeeNo")}
          />
        ),
      },
      {
        field: "orgNo",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={FingerprintIcon}
            label={Translate.get("PersonalNo")}
          />
        ),
      },
      {
        field: "addressStreet",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={PlaceIcon}
            label={Translate.get("Address")}
          />
        ),
      },
      ...(hasAccess(accessKeys.advancedPackage)
        ? [
            {
              ...getGridColDefForArrayValues(
                filterGroups.map((fg) => ({
                  value: fg.filterGroupId,
                  label: fg.description,
                }))
              ),
              field: "filterGroupIds",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={FilterListIcon}
                  label={Translate.get("FilterNoun")}
                />
              ),
              renderCell: (params) =>
                params.value && (
                  <div>
                    {params.value
                      .map(
                        (filterGroupId) =>
                          filterGroups.find(
                            (fg) => fg.filterGroupId === filterGroupId
                          )?.description
                      )
                      .join(", ")}
                  </div>
                ),
            },
          ]
        : []),
      // {
      //   field: "calendarSync",
      //   flex: 1,
      //   sortable: false,
      //   disableColumnMenu: true,
      //   headerClassName: "hideSeparator",
      //   renderHeader: () => (
      //     <IconAndLabelColumnHeader
      //       icon={SyncIcon}
      //       label={Translate.get("CalendarSynch")}
      //     />
      //   ),
      // },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu
            content={getDotMenuActions(
              params.row.actorId,
              params.row.filterGroupIds
            )}
            horizontal
          />
        ),
      },
    ],
    [getDotMenuActions, filterGroups]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const navigate = useNavigate();
  const navToUser = (id) => {
    navigate(`/dashboard/person/${id}`, { replace: false });
  };
  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          <SpeedMenu content={speedMenuActions} />
          {!isSmallScreenOrGreater &&
            teachers.map((teacher) => (
              <InstructorCard
                {...teacher}
                key={teacher.actorId}
                extraActions={getDotMenuActions(
                  teacher.actorId,
                  teacher.filterGroupIds
                )}
                filterGroups={teacher.filterGroupIds
                  .map(
                    (filterGroupId) =>
                      filterGroups.find(
                        (fg) => fg.filterGroupId === filterGroupId
                      )?.description
                  )
                  .join(", ")}
              />
            ))}
          {isSmallScreenOrGreater && (
            <Paper>
              <DataGrid
                pagination
                pageSizeOptions={[10, 20, 30, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                autoHeight={true}
                localeText={localizedTextsMap}
                onRowClick={(params) => navToUser(params.id)}
                initialState={{
                  sorting: { sortModel: [{ field: "actorName", sort: "asc" }] },
                }}
                //rowHeight={80}
                density="comfortable"
                //rowsPerPage={-1}
                //hideFooter={true}
                columns={columns}
                rows={teachers}
                getRowId={(row) => row.actorId}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay(Translate.get("NoFiles")),
                }}
              />
            </Paper>
          )}
        </>
      )}
    </>
  );
}
