import {Button, Box, Grid, Card } from "@mui/material";
import DotMenu from "../DotMenu";
import BuildIcon from "@mui/icons-material/Build";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";

export default function LicensCard(props) {
  const menuActions = props.getDotMenuActions({
    onDeleteLicense: () =>
      props.setActorLicenseIdToDelete(props.row.actorLicenseId),
  });

  return (
    <Card
      sx={{ padding: "16px", marginBottom: "16px", transform: "translateZ(0)" }}
    >
  
      <Box sx={{ position: "fixed", top: "18px", right: "16px" }}>
      {menuActions.length > 0 && 
        <DotMenu
              content={menuActions}
              horizontal
        />}
      </Box>
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{ padding: "30px 0px 16px 18px" }}
      >
        <Grid item container alignItems="center">
          <BadgeIcon />
          <Box marginLeft="19px">{props.row.licenseGroupDescription}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <BuildIcon />
          <Box marginLeft="19px">
          {
                <span>
                  {[
                    props.row.licenseGroupPractice,
                    props.row.licenseGroupTheory,
                  ]
                    .filter((s) => !!s)
                    .join(", ")}
                </span>
              }
          </Box>
        </Grid>
        <Grid item container alignItems="center">
          <CalendarTodayIcon />
          <Box marginLeft="19px">{props.row.validFrom ? formatDate(props.row.validFrom) : ""}{props.row.validTo ? ' - ' + formatDate(props.row.validTo) : ""}</Box>
         
        </Grid>
        <Grid item container alignItems="center">
          <CoPresentIcon />
          <Box marginLeft="19px">{props.row.instructorName}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <EventAvailableIcon />
          <Box marginLeft="19px">{props.row.lastPrintDate && formatDate(props.row.lastPrintDate)}</Box>
        </Grid>
        <Grid container  justifyContent="flex-end">
        <Button
              variant="outlined"
              disabled={
                !props.isOrderLicenseAvailable(
                    props.row.licenseGroupDescription?.trim()
                )
              }
              onClick={() =>
                props.handleOrderLicensesClick(props.row.actorLicenseId)
              }
            >
              {Translate.get("OrderLicense")}
            </Button>
        </Grid>
        
      </Grid>
    </Card>
  );
}
