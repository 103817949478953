import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import SkillItem from "../../skills/SkillItem";
import SkillItemGrid from "../../skills/SkillItemGrid";
import LicenseSkillsDialog from "./LicenseSkillsDialog";
import { useCallback } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { hasAccess, accessKeys } from "../../../utils/userAccess";
import { useSnackbar } from 'notistack';

function getSkillCountByGroup(skills) {
  return skills.reduce((acc, val) => {
    acc[val.skillGroupId] = (acc[val.skillGroupId] ?? 0) + 1;
    return acc;
  }, {});
}

function getInitialSelectedSkillGroupId(skills) {
  const skillCountByGroup = getSkillCountByGroup(skills);
  const sortedByCountDec = Object.entries(skillCountByGroup).toSorted(
    (a, b) => b[1] - a[1]
  );
  return sortedByCountDec[0][0];
}

function hasSingleSkillGroup(skills) {
  var ids = new Set(skills.map((s) => s.skillGroupId));
  return ids.size === 1;
}

export default function CourseTypeSkillsDialog({
  courseTemplateId,
  open,
  onClose,
}) {
  const [showLicenseSkillsDialog, setShowLicenseSkillsDialog] = useState(false);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [skillGroups, setSkillGroups] = useState([]);
  const [currentTabValue, setCurrentTabValue] = useState(null);
  const [isLoadingCount, setIsLoadingCount] = useState(2);
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const api = useApi();

  const loadSkills = useCallback(
    async (courseTemplateId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/skills/`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setAvailableSkills(response.skills ?? []);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
      setSelectedSkillsFromServer(response.skills ?? []);

    },
    [api]
  );

  const loadSkillGroups = useCallback(async () => {
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/names`,
      false,
      "GET"
    );
    if (data) {
      setSkillGroups(data);
    }
    setIsLoadingCount((prevCount) => prevCount - 1);
  }, [api]);

  useEffect(() => {
    if (availableSkills && availableSkills.length > 0) {
      setCurrentTabValue(getInitialSelectedSkillGroupId(availableSkills));
    }
  }, [availableSkills]);

  useEffect(() => {
    if (open) {
      loadSkills(courseTemplateId);
      loadSkillGroups();
    }
  }, [loadSkills, loadSkillGroups, open, courseTemplateId]);

  async function postUpdatedSkillState(skillId, isInCourseTemplate) {
    const request = {
      skills: [{ skillId, include: isInCourseTemplate }],
    };
    await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/skills/`,
      request,
      "POST"
    );
  }

  async function postSkills() {
    let skills = [];
    selectedSkills.forEach(element => {
      let s = {
        skillId: element.skillId,
        include: true
      }
      skills.push(s);
    });

    availableSkills.forEach(element => {
      if (!selectedSkills.some(ss => ss.skillId === element.skillId)) {
        let s = {
          skillId: element.skillId,
          include: false
        }
        skills.push(s);
      }
    })

    let req = {
      skills: skills
    }

    try {
      setIsLoading(true);
      let resp = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/skills/`,
        req,
        "POST"
      );

        if (resp.isSuccessful) {
          const successMessage = enqueueSnackbar(
            Translate.get('Saved'),
            {
              variant: 'success',
              autoHideDuration: 6000,
              onClick: () => closeSnackbar(successMessage),
            },
          );
          onClose();
        }

    } catch {

    } finally {
      setIsLoading(false);
    }

  }

  function setSelectedSkillsFromServer(skills) {

    let sks = [];
    skills.forEach(element => {
      if (element.isInCourseTemplate) {
        sks.push(element)
      }
    })

    setSelectedSkills(sks);
  }

  function handleSkillClick(skill) {
    if (selectedSkills.some(item => item.skillId === skill.skillId)) {
      removeSelectedSkill(skill);
    } else {
      addSelectedSkill(skill);
    }
  }

  function addSelectedSkill(skill) {
    let skills = [...selectedSkills];
    skills.push(skill);
    setSelectedSkills(skills)
  }

  function removeSelectedSkill(skill) {
    let skills = selectedSkills.filter(item => item.skillId !== skill.skillId)
    setSelectedSkills(skills)
  }

  function handleLicenseSkillsDialogClosed() {
    setShowLicenseSkillsDialog(false);
    setIsLoadingCount((prev) => prev + 1);
    loadSkills(courseTemplateId);
  }

  const handleTabSelected = (event, newTabIndex) => {
    setCurrentTabValue(newTabIndex.toString());
  };

  function getAvailableSkillsInGroup(skillGroupId) {
    return availableSkills.filter(
      (skill) => skill.skillGroupId === skillGroupId
    );
  }

  function isSelected(skill) {
    let b = selectedSkills.some(s => s.skillId === skill.skillId);
    return b;
  }

  return (
    <>
      {isLoading && <Preloader />}
      {open && isLoadingCount > 0 && <Preloader />}
      {open && showLicenseSkillsDialog && (
        <LicenseSkillsDialog
          courseTemplateId={courseTemplateId}
          onClose={handleLicenseSkillsDialogClosed}
        />
      )}
      {isLoadingCount <= 0 && (
        <Dialog
          open={open}
          onClose={onClose}
          sx={
            /* Doing this so that the spinner in LicenseSkillsDialog is over this dialog*/
            showLicenseSkillsDialog ? { zIndex: 0 } : {}
          }
          PaperProps={{ sx: { maxWidth: { sm: "700px", xs: "343px" } } }}
        >
          {isBigScreen && hasAccess(accessKeys.admin) && (
            <Box
              sx={{
                position: "absolute",
                right: 8,
                top: 14,
              }}
            >
              <Button onClick={() => setShowLicenseSkillsDialog(true)}>
                {Translate.get("AddCourseTemplateSkillsLabel")}
              </Button>
            </Box>
          )}
          <DialogTitle>{Translate.get("SelectSkills")}</DialogTitle>
          <DialogContent>
            <Box>
              {!isBigScreen && hasAccess(accessKeys.admin) && (
                <Button
                  sx={{ marginBottom: "10px", padding: 0 }}
                  onClick={() => setShowLicenseSkillsDialog(true)}
                >
                  {Translate.get("AddCourseTemplateSkillsLabel")}
                </Button>
              )}
              <Typography sx={{ margin: "0 0 24px" }}>
                {Translate.get("EditCourseTemplateSkillsInfo")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {availableSkills.length === 0 && (
                  <Typography
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    <aside className='MuiCallout-root MuiCallout-warning'>{Translate.get("AtLeastOneSkill")}</aside>
                  </Typography>
                )}
                {availableSkills.length > 0 && !!currentTabValue && (
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={currentTabValue}>
                      {!hasSingleSkillGroup(availableSkills) && (
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList onChange={handleTabSelected} centered>
                            {skillGroups.map((skillGroup, idx) => (
                              <Tab
                                disabled={
                                  getAvailableSkillsInGroup(
                                    skillGroup.skillGroupId
                                  ).length === 0
                                }
                                label={`${skillGroup.description} (${getAvailableSkillsInGroup(
                                  skillGroup.skillGroupId
                                ).length
                                  })`}
                                value={skillGroup.skillGroupId.toString()}
                                key={idx}
                              />
                            ))}
                          </TabList>
                        </Box>
                      )}
                      {skillGroups.map((skillGroup, idx) => (
                        <TabPanel
                          value={skillGroup.skillGroupId.toString()}
                          key={idx}
                          sx={{
                            height: "440px",
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            <SkillItemGrid
                              items={getAvailableSkillsInGroup(
                                skillGroup.skillGroupId
                              ).map((skill) => (
                                <SkillItem
                                  isSelected={isSelected(skill)}
                                  onClick={() =>
                                    handleSkillClick(skill)
                                  }
                                  isSmall={!isBigScreen}
                                  imageUrl={skill.imageUrl}
                                  label={`${skill.shortCode} ${skill.description}`}
                                />
                              ))}
                            />
                          </Box>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Close")}</Button>
            <Button onClick={postSkills} disabled={selectedSkills.length < 1} variant="contained">{Translate.get("Save")}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
