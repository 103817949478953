import {
  Card,
  TextField,
  FormControl,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import Translate from "../utils/Translate";
import { useApi } from "../utils/Api";
import Preloader from "../components/Preloader";
import Permits from "../components/actors/Permits";
import AddIcon from "@mui/icons-material/Add";
import ActorPermitFlowDialogContainer from "../components/actor-permits/ActorPermitFlowDialogContainer";
import SpeedMenu from "../components/SpeedMenu";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from "@mui/icons-material/Search";

async function loadActorPermits(api, query) {
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}permits/search?query=${query}`,
    false,
    "GET"
  );

  if (response.isSuccessful) {
    // Note that some keys are remapped here
    return response.actorPermits.map((a) => ({
      ...a,
      parentActorName:
        a.parentActorName !== "ePortMaster" ? a.parentActorName : "",
      responsibleName:
        a.responsibleActorId !== 4 // 4 ePortMaster ActorId
          ? a.responsibleActorName
          : "",
      machineGroup: a.skillGroupDescription,
      machineTypes: a.skills?.map((s) => s.shortCode).join(", "),
      isSignedByResponsible: !!a.responsibleSignatureId,
      isSignedByStudent: !!a.signatureId,
    }));
  } else {
    return [];
  }
}

const SearchField = ({ inputRef, defaultSearch }) => {
  const [query, setQuery] = useState(defaultSearch ?? "");

  return (
    <TextField
      inputRef={inputRef}
      inputProps={{ spellCheck: false }}
      fullWidth
      id="outlined-basic"
      label={Translate.get("Search")}
      variant="outlined"
      name="searchString"
      value={query}
      onChange={(evt) => setQuery(evt.target.value)}
    />
  );
};

export default function ActorPermits() {
  const [isLoading, setIsLoading] = useState(true);
  const [permits, setPermits] = useState([]);
  const [currentSearch, setCurrentSearch] = useState("");
  const searchFieldRef = useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSort = searchParams.get("defaultSort");
  const defaultStdFilter = searchParams.get("std-filter");
  const defaultSearch = searchParams.get("defaultSearch");
  const api = useApi();

  useEffect(() => {
    // autofocus property on textfield did not work, what to do ;)
    const timer = setTimeout(() => searchFieldRef.current.focus(), 300);
    return () => clearTimeout(timer);
  }, []);

  const doSearch = useCallback(
    async (searchText) => {
      if (!!defaultSearch && defaultSearch !== searchText) {
        // Clear the default stuff if the user changes the search
        setSearchParams({});
      }
      
      setCurrentSearch(searchText);
      setIsLoading(true);
      const foundPermits = await loadActorPermits(api, searchText);
      setPermits(foundPermits);
      setIsLoading(false);
    },
    [api, defaultSort]
  );

  // Just load all permits initially (or perform defaultSearch from query params)
  useEffect(() => {
    doSearch(defaultSearch ?? "");
  }, [defaultSearch, doSearch]);

  function handleSubmit(event) {
    doSearch(searchFieldRef.current.value);
    event.preventDefault();
  }

  function handleReloadPermits() {
    doSearch(currentSearch);
  }
  
  const CustomHeader = () => { 
    //CustomHeader med hjälp knapp
    return (  
      <div className="stdFlexBetween">
       <div>{Translate.get("CreateActorPermit")}</div>
       
              <Button variant="text" startIcon={<HelpIcon />} 
              onClick={ () => {
                window.global.helpLink={cat:'/dashboard/permits', subCat:'permits_checklist'};
                window.globalTriggerClick('helpTrigger');
                 
               }}>
              {Translate.get("Checklist")}
              </Button>
      </div>
     
    )
  };

  const speedMenuActions = [
    {
      icon: <AddIcon />,
      text: Translate.get("CreateActorPermit"),
      dialogHeader: CustomHeader, //Translate.get("CreateActorPermit"),
      component: <ActorPermitFlowDialogContainer />,
    },
  ];

  return (
    <>
      {isLoading && <Preloader />}
      <div className="stdFlexRowTop">
        <h2>{Translate.get("ActorPermit")}</h2>
        <FormControl sx={{ marginLeft: "8px" }} fullWidth>
          <form className="actorsSearchForm" onSubmit={handleSubmit}>
            <SearchField
              inputRef={searchFieldRef}
              defaultSearch={defaultSearch}
            />
            {!isSmallScreen && (
              <Button
                type="submit"
                className="actorsSearchButton"
                variant="contained"
                endIcon={<SearchIcon />}
              >
                {Translate.get("Search")}
              </Button>
            )}
          </form>
        </FormControl>
      </div>
      <Card>
        <Permits
          permits={permits}
          smallScreen={isSmallScreen}
          cardView={false}
          hideFilter={false}
          defaultSort={defaultSort}
          defaultStdFilter={defaultStdFilter}
          onReload={handleReloadPermits}
        />
      </Card>
      <SpeedMenu content={speedMenuActions} />
    </>
  );
}
