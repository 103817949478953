import { useState } from 'react';
import Translate from '../../../utils/Translate';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SkillItem from '../../skills/SkillItem';
import { useApi } from '../../../utils/Api';
import { formatDate } from '../../../utils/dateTimeFormat';
import { StaticNoRowsOverlay } from '../../../utils/StyledGridOverlay';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Comp = ({ rows, docId,fileName }) => {
  const api = useApi();
  //console.log('skills', rows);
  ////console.log('showCompetence', showCompetence);
  const [value, setValue] = useState('0');
  const [update, setUpdate] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fixRow = Object.entries(rows.actorSkillsBySkillGroup).map(
    ([skillGroup, skills]) => ({ skillGroup, skills }),
  );
  if (fixRow.length === 0) {
    return <StaticNoRowsOverlay />;
  }

  const connect = async (blobDocumentId, actorSkillId, files) => {
    const indata = {};
    indata.blobDocumentId = blobDocumentId;
    indata.actorSkillId = actorSkillId;
    //console.log('connect', blobDocumentId, actorSkillId, indata);
    const ret = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actorskills/file/link/`,
      indata,
    );
    if (ret.isSuccessful) {
      indata.originalFileName=fileName;
      files = files ?? [];
      files.push(indata);
      return files;
    } else {
      return files;
    }
  };
  const disconnect = async (blobDocumentId, actorSkillId) => {
    const indata = {};
    indata.blobDocumentId = blobDocumentId;
    indata.actorSkillId = actorSkillId;
    //console.log('disconnect', blobDocumentId, actorSkillId, indata);
    const ret = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actorskills/file/remove/link`,
      indata,
    );
    if (ret.isSuccessful) {
      return true;
    } else {
      return false;
    }
  };
  const connectedDoc = (docId, files) => {
    if (files) {
      return files.filter((x) => x.blobDocumentId === docId).length
        ? true
        : false;
    } else {
      return false;
    }
  };

  const connectedDocsStr = (files) => {
    if (files) {
      return files.map((x) => x.originalFileName).join(', ');
    } else {
      return '';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList centered onChange={handleChange}>
              {fixRow.map((row, index) => (
                <Tab
                  key={index}
                  label={`${row.skillGroup} (${row.skills.length})`}
                  value={index + ''}
                />
              ))}
            </TabList>
          </Box>
          <Box>
            {fixRow.map((row, index) => (
              <TabPanel key={index} value={index + ''}>
                <div className="stdFlexCenter skillItems skillItemsConnect">
                  {row.skills.map((skill, index2) => (
                    <SkillItem
                      isSelected={connectedDoc(docId, skill.files)}
                      onClick={() => {
                        if (!connectedDoc(docId, skill.files)) {
                          (async () => {
                            skill.files = await connect(
                              docId,
                              skill.actorSkillId,
                              skill.files,
                            );
                            setUpdate(Date.now());
                          })();
                        } else {
                          (async () => {
                            const success = await disconnect(docId, skill.actorSkillId);
                            if (success) {
                              skill.files = skill.files.filter(
                                (x) => x.blobDocumentId ==! docId,
                              );
                              setUpdate(Date.now());
                            }
                          })();
                        }
                      }}
                      key={index2}
                      isFailed={!skill.hasPassed}
                      imageUrl={skill.imageUrl}
                      label={`${skill.shortCode} ${skill.description}`}
                      gotDoc={skill.files}
                    />
                  ))}
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span> <span></span>
                </div>
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      </Box>
    </>
  );
};
export default Comp;
