import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Translate from "../../../utils/Translate";
import React, { Fragment, useEffect, useRef } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../../components/Preloader";
import { useReactToPrint } from "react-to-print";
import {
  Box,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Comp = ({ eLearningLicenseId }) => {
  const [resultBlob, setResultBlob] = React.useState("");
  const [finalExams, setFinalExams] = React.useState("");
  const [selectedFinalExamIndex, setSelectedFinalExamIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const api = useApi();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    function getDetailedResultExercises() {
      let serviceUrl =
        "licenses/elearning/exam/results/progress/" + eLearningLicenseId;
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
          false,
          "GET",
          false,
          true
        )
        .then((data) => {
          if (data.isSuccessful) {
            setResultBlob(
              data.examProgressBlob ??
                "<div><img style='width:100%' src='/static/no-data-empty-box.svg'/> <p style='text-align:center'>" +
                  Translate.get("NoResults") +
                  "</p></div>"
            );
          }
        })
        .finally((f) => {
          setIsLoading(false);
        });
    }

    function getDetailedResultFinalExam() {
      let serviceUrl =
        "licenses/elearning/exam/results/details/" + eLearningLicenseId;
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
          false,
          "GET",
          false,
          true
        )
        .then((data) => {
          if (data.isSuccessful) {
            // We only show the ones which have been completed (both failed and passed)
            let completedFinalExams =
              data.finalExams?.filter((x) => !!x.endDate) ?? [];
            completedFinalExams.sort(function (a, b) {
              return a.finalExamNumber - b.finalExamNumber;
            });
            setFinalExams(completedFinalExams);
            setSelectedFinalExamIndex(completedFinalExams.length - 1);
          }
        })
        .finally((f) => {
          setIsLoading(false);
        });
    }

    getDetailedResultExercises();
    getDetailedResultFinalExam();
  }, []);

  function removeElementByClassName(htmlString, className) {
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    let elements = div.getElementsByClassName(className);
    elements[0].parentNode.removeChild(elements[0]);
    return div.innerHTML;
  }

  return (
    //Allt innehåll i dialog förutom Dialog + DialogHeader
    <>
      <DialogTitle>
        <div className="stdFlexBetween">
          <div>{Translate.get("DetailedResult")}</div>
          <Tooltip
            PopperProps={{ sx: { whiteSpace: "pre-line" } }}
            title={Translate.get("DetailedResultInfo")}
          >
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent dividers={true} ref={componentRef}>
        <style type="text/css" media="print">
          {
            "@page { size: portrait; } .tabsContainer, .tabPanel { display: none !important; } .finalExamPrint { display: block !important; }"
          }
        </style>
        {isLoading && <Preloader />}
        <div dangerouslySetInnerHTML={{ __html: resultBlob }} />

        {!!finalExams && finalExams.length > 0 && (
          <>
            <Box
              className="tabsContainer"
              marginTop="16px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Tabs
                value={selectedFinalExamIndex}
                onChange={(_, val) => setSelectedFinalExamIndex(val)}
              >
                {finalExams.map((x, i) => (
                  <Tab
                    key={i}
                    label={`${Translate.get("FinalTest")} ${x.finalExamNumber}`}
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
            </Box>
            {finalExams.map((x, i) => (
              <CustomTabPanel key={i} value={selectedFinalExamIndex} index={i}>
                {
                  <div>
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeElementByClassName(
                          x.examResultsBlob,
                          "nma_userinfointest"
                        ),
                      }}
                    />
                  </div>
                }
              </CustomTabPanel>
            ))}
            {!!finalExams && finalExams.length > 0 && (
              <Box className="finalExamPrint" display="none">
                {[...finalExams].reverse().map((x) => (
                  <Fragment key={x.finalExamNumber}>
                    <br />
                    {finalExams.length > 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          breakBefore:
                            x.finalExamNumber < finalExams.length
                              ? "page"
                              : "auto",
                          paddingTop: "30px",
                          fontFamily: "Roboto,Public Sans,sans-serif",
                          fontSize: "22px",
                          letterSpacing: "0.2em",
                          color: "#666666",
                        }}
                      >
                        <div>
                          {`${Translate.get("FinalTest")} ${
                            x.finalExamNumber
                          }`.toUpperCase()}
                        </div>
                      </Box>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeElementByClassName(
                          finalExams[finalExams.length - 1].examResultsBlob,
                          "nma_userinfointest"
                        ),
                      }}
                    />
                  </Fragment>
                ))}
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.history.back()}>
          {Translate.get("Cancel")}
        </Button>
        <Button onClick={handlePrint}>{Translate.get("Print")}</Button>
      </DialogActions>
      {/*  </Dialog> */}
    </>
  );
};
export default Comp;
