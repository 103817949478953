import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Translate from "../utils/Translate";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export function AlertDialog({
  open,
  titleText,
  bodyText,
  buttonText,
  onClose,
  showCopyButton,
  zIndexOverride,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleCopyMessage() {
    navigator.clipboard.writeText(bodyText);
    const infoMessage = enqueueSnackbar(Translate.get("CopiedToClipboard"), {
      variant: "info",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(infoMessage),
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={zIndexOverride ? { zIndex: zIndexOverride } : {}}
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: "pre" }}
        >
          {bodyText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCopyButton && (
          <Button
            onClick={handleCopyMessage}
            variant="outlined"
            startIcon={<ContentCopy />}
          >
            {Translate.get("CopyMessageLabel")}
          </Button>
        )}
        <Button
          variant={showCopyButton ? "contained" : "text"}
          onClick={onClose}
          autoFocus
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
