import * as Yup from "yup";
import { useState } from "react";
//import {memo} from 'react';
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
// import Snackbar from '@mui/material/Snackbar';
import { LoadingButton } from "@mui/lab";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------
const SetPasswordForm = (props) => {
  //export default function SetPasswordForm() {
  //const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [apiState, setApiState] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const api = useApi();
  //console.log("SetPasswordForm");
  const getUrlParameter = function (name) {
    // Unsure about this warning, so I will simply disable it, since the code is working
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? false
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, Translate.get("Min8"))
      .max(30, Translate.get("Max30"))
      .required(Translate.get("PasswordRequired")),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      Translate.get("PasswordsMustMatch")
    ),
  });

  const formik = useFormik({
    initialValues: {
      /* firstName: '',
      lastName: '', 
      email: '', */
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const token = getUrlParameter("token");
      const req = {
        NewPassword: formik.values.password,
      };

      //const cpToken = `Bearer ${sessionStorage.cpToken}`;

      api.fetchWithOverride(
        `${process.env.REACT_APP_AUTH_URL}auth/password/change/token/`,
        req,
        "POST",
        (data) => data === true,
        () => setApiState("fail"),
        () => setApiState("expiredToken"),
        null,
        `Bearer ${token}`
      ).then((data) => {
        if (data === true) {
          setApiState("success");
        }
        formik.setSubmitting(false);
      });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      {/*  <Snackbar open={true} autoHideDuration={3000} message="Updated" /> */}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            inputProps={{ maxLength: 30 }}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label={Translate.get("Password")}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            inputProps={{ maxLength: 30 }}
            fullWidth
            autoComplete="current-password"
            type={showPasswordConfirmation ? "text" : "password"}
            label={Translate.get("ConfirmPassword")}
            {...getFieldProps("passwordConfirmation")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPasswordConfirmation((prev) => !prev)}
                  >
                    <Icon
                      icon={showPasswordConfirmation ? eyeFill : eyeOffFill}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.passwordConfirmation && errors.passwordConfirmation
            )}
            helperText={
              touched.passwordConfirmation && errors.passwordConfirmation
            }
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {Translate.get("SetPassword")}
          </LoadingButton>
          {apiState === "fail" && (
            <Alert severity="error">
              {Translate.get("UnableToChangePassword")}
            </Alert>
          )}
          {apiState === "expiredToken" && (
            <Alert severity="error">
              {Translate.get("ChangePasswordTokenExpired") + " "}
              <RouterLink to="/forgotpw">
                {Translate.get("ForgotYourPassword")}
              </RouterLink>
            </Alert>
          )}
          {apiState === "success" && (
            <Alert severity="success">
              {Translate.get("PasswordRestored")}
            </Alert>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default SetPasswordForm;
