import Personnummer from 'personnummer';
import Api from './Api';

export async function tryCreatePersonnummerWithSettings(personnummer) {
    let s = await getPersonummerFormatSettings();
    if (s !== null) {
        return await tryCreatePersonnummer(personnummer, s.longFormat, s.hyphen, s.dontFormat)
    }
    return null;
}

export async function tryCreatePersonnummer(personnummer, longFormat, hyphen, dontFormat) {
    const result = { isValid: true, isCoordinationNumber: false, formattedPersonalNumber: null };
    if (!Personnummer.valid(personnummer)) {
        result.isValid = false;
        return result
    }
    result.isValid = true
    if (dontFormat) {
        return result;
    }

    const parsed = Personnummer.parse(personnummer);
    if (parsed.isCoordinationNumber()) {
        result.isCoordinationNumber = true;
        return result;
    }

    let formatted = parsed.format(longFormat);
    if (hyphen && longFormat) {
        let res = formatted.slice(0, formatted.length - 4) + "-" + formatted.slice(formatted.length - 4);
        formatted = res;
    } else if (!hyphen && !longFormat) {
        let res = formatted.slice(0, formatted.length - 5) + formatted.slice(formatted.length - 4);
        formatted = res;
    }
    result.formattedPersonalNumber = formatted;
    return result;
}


async function getPersonummerFormatSettings() {
    let result = { longFormat: false, hyphen: false, dontFormat: false }
    
    let value = sessionStorage.getItem("orgNoFormat");
    if (value === null) {
        value = await fetchOrgNoSettingValue();
        if (value !== null) {
            sessionStorage.setItem("orgNoFormat", value);
        }
    }

    switch (value) {
        case "undefined":
            result.dontFormat = true;
            break;
        case "YYYYMMDDXXXX":
            result.longFormat = true;
            break;
        case "YYYYMMDD-XXXX":
            result.longFormat = true;
            result.hyphen = true;
            break;
        case "YYMMDDXXXX":
            break;
        case "YYMMDD-XXXX":
            result.hyphen = true;
            break;
        default:
            return null;
    }
    return result;  
}

export async function getPersonummerFormatSettingsFromString(personnummer) {
    let result = { longFormat: false, hyphen: false, dontFormat: false }
    
    let value = personnummer
    if (value === null) {
        return result
    }

    switch (value) {
        case "Undefined":
            result.dontFormat = true;
            break;
        case "YYYYMMDDXXXX":
            result.longFormat = true;
            break;
        case "YYYYMMDD-XXXX":
            result.longFormat = true;
            result.hyphen = true;
            break;
        case "YYMMDDXXXX":
            break;
        case "YYMMDD-XXXX":
            result.hyphen = true;
            break;
        default:
            return null;
    }
    return result;  
}

async function fetchOrgNoSettingValue() {
    let response = null;
    try {
        response = await Api.fetch(
            `${process.env.REACT_APP_MAIN_URL}dataIdSettings/getOrgNoFormats`,
            false,
            'GET',
        );

        if (!response.isSuccessful) {
            return null; // TODO
        }
    } catch (error) {
        return null; // TODO
    }
    return response?.currentOrgNoFormat;
}

export function clearOrgNoFormatSessionCache() {
    sessionStorage.removeItem("orgNoFormat");
}

export async function getPersonnummerPlaceholderText() {
    let value = sessionStorage.getItem("orgNoFormat");
    if (value === null) {
        value = await fetchOrgNoSettingValue();
        if (value !== null) {
            sessionStorage.setItem("orgNoFormat", value);
        }
    }
    if (value === null || value.toLowerCase() === "undefined") {
        return "PersonalNo";
    }
    return value;
}