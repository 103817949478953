import React, { useCallback, useEffect, useMemo, useState } from "react";
import Preloader from "../Preloader";
import Translate from "../../utils/Translate";
import { accessKeys, hasSomeAccess, hasAccess } from "../../utils/userAccess";
import FinalExamUnlockedSelect from "./FinalExamUnlockedSelect";
import OrgNoFormat from "./OrgNoFormat";
import { SelfRegSettings } from "./SelfRegSettings";
import CourseInformation from "./CourseInformation";
import { ELearningRegSettings } from "./ELearningRegSettings";
import StdOrderConfirmationText from "./StdOrderConfirmationText";
import PaymentAgreements from "./PaymentAgreements";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useApi } from "../../utils/Api";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { loadActiveIntegrations } from "../../utils/integrations";

function IntegrationField({
  integrationName,
  labelTranslationKey,
  name,
  value,
  onValueChange,
}) {
  return (
    <TextField
      fullWidth
      value={value ?? ""}
      InputProps={{ spellCheck: false }}
      onChange={(event) =>
        onValueChange(integrationName, name, event.target.value)
      }
      label={Translate.get(labelTranslationKey)}
    />
  );
}

function IntegrationSecretField({
  integrationName,
  labelTranslationKey,
  name,
  value,
  displaySecret,
  onShowSecret,
  onValueChange,
}) {
  const [showSpinner, setShowSpinner] = useState(false);

  const handleMouseDownPassword = (event) => {
    if (!displaySecret) {
      event.preventDefault();
    }
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={"outlined-adornment-password-" + name}>
        {Translate.get(labelTranslationKey)}
      </InputLabel>
      <OutlinedInput
        id={"outlined-adornment-password-" + name}
        value={value ?? ""}
        onChange={(event) =>
          onValueChange(integrationName, name, event.target.value)
        }
        type={displaySecret ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              disabled={!value}
              aria-label="toggle password visibility"
              onClick={async () => {
                setShowSpinner(true);
                await onShowSecret(integrationName, name);
                setShowSpinner(false);
              }}
              edge="end"
            >
              {displaySecret ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={Translate.get(labelTranslationKey)}
        onMouseDown={handleMouseDownPassword}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        }}
      />
    </FormControl>
  );
}

function Integration({
  labelTranslationKey,
  name,
  onValueChange,
  onShowSecret,
  settings,
}) {
  return (
    <div className="stdCard" style={{ maxWidth: "400px" }}>
      <div className="stdCardHeader">{Translate.get(labelTranslationKey)}</div>
      <div className="stdCardBody">
        <Stack direction="column" spacing={2}>
          {settings.map((setting) =>
            setting.isSecret ? (
              <IntegrationSecretField
                key={setting.name}
                {...setting}
                integrationName={name}
                onShowSecret={onShowSecret}
                onValueChange={onValueChange}
              />
            ) : (
              <IntegrationField
                key={setting.name}
                {...setting}
                integrationName={name}
                onValueChange={onValueChange}
              />
            )
          )}
        </Stack>
      </div>
    </div>
  );
}

function tempShowIntegration(integration) {
  switch (integration.name) {
    case "ID06":
      return hasAccess(accessKeys.digitalLicenses);
    default:
      return true;
  }
}

export function IntegrationSettings(props) {
  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const [integrations, setIntegrations] = useState([]);
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadIntegrations() {
      setIsLoadingCount((i) => i + 1);
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}settings/integrations`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setIntegrations(
          response.integrations
            .filter((x) => tempShowIntegration(x)) // Just temp, can be removed when digital licenses available for all in prod
            .map((x) => ({
              ...x,
              settings: x.settings.map((s) => ({
                ...s,
                displaySecret: s.isSecret && !s.value ? true : false,
              })),
            }))
        );
      }
      setIsLoadingCount((i) => i - 1);
    }
    loadIntegrations();
  }, [api]);

  const handleShowSecret = useCallback(
    async (integrationName, settingName) => {
      const integrationIndex = integrations.findIndex(
        (x) => x.name === integrationName
      );
      const settingIndex = integrations[integrationIndex].settings.findIndex(
        (x) => x.name === settingName
      );

      const integrationsCopy = [...integrations];
      const integrationCopy = { ...integrationsCopy[integrationIndex] };
      const settingsCopy = [...integrationCopy.settings];
      const settingCopy = { ...settingsCopy[settingIndex] };

      const isMaskedValue =
        settingCopy.value &&
        settingCopy.value.split("").every((ch) => ch === "*");
      if (isMaskedValue) {
        const response = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}settings/integrations/secret?secretName=${settingName}`,
          false,
          "GET"
        );
        if (response.isSuccessful) {
          settingCopy.value = response.value;
        }
      }

      settingCopy.displaySecret = !settingCopy.displaySecret;
      settingsCopy.splice(settingIndex, 1, settingCopy);
      integrationCopy.settings = settingsCopy;
      integrationsCopy.splice(integrationIndex, 1, integrationCopy);
      setIntegrations(integrationsCopy);
    },
    [integrations, api]
  );

  const postUpdateValue = useMemo(
    () =>
      debounce(async (integrationName, settingName, settingValue) => {
        const response = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}settings/integrations/${integrationName}`,
          { name: settingName, value: settingValue },
          "POST"
        );
        await loadActiveIntegrations();
        if (!response.isSuccessful) {
          // Just reload page for correct values on fail
          navigate(0);
        }
      }, 300),
    [navigate]
  );

  const handleValueChange = useCallback(
    async (integrationName, settingName, settingValue) => {
      const integrationIndex = integrations.findIndex(
        (x) => x.name === integrationName
      );
      const settingIndex = integrations[integrationIndex].settings.findIndex(
        (x) => x.name === settingName
      );

      const integrationsCopy = [...integrations];
      const integrationCopy = { ...integrationsCopy[integrationIndex] };
      const settingsCopy = [...integrationCopy.settings];
      const settingCopy = { ...settingsCopy[settingIndex] };
      settingCopy.value = settingValue;

      settingsCopy.splice(settingIndex, 1, settingCopy);
      integrationCopy.settings = settingsCopy;
      integrationsCopy.splice(integrationIndex, 1, integrationCopy);
      setIntegrations(integrationsCopy);
      await postUpdateValue(integrationName, settingName, settingValue);
    },
    [postUpdateValue, integrations]
  );

  return (
    <div>
      {isLoadingCount > 0 && <Preloader />}
      <div
        className="stdPage"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1.5em",
        }}
      >
        {integrations.map((integration) => (
          <Integration
            key={integration.name}
            {...integration}
            onShowSecret={handleShowSecret}
            onValueChange={handleValueChange}
          />
        ))}
      </div>
    </div>
  );
}
