import { useRef, useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import moreHorizontalFill from "@iconify/icons-eva/more-horizontal-fill";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Translate from "../utils/Translate";

const DotMenu = ({ content, horizontal = false }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [components, setComponent] = useState(false);
  const [header, setHeader] = useState(false);
  const [customClass, setCustomClass] = useState("stdDialog");
  const [initialHash] = useState(window.location.hash);

  const handleMenuItemAction = (
    route,
    component,
    header,
    onClick,
    addCustomClass
  ) => {
    if (onClick) {
      onClick();
    } else if (route) {
      navigate(route, { replace: false });
    } else {
      if (addCustomClass) {
        setCustomClass(addCustomClass);
      }
      setHeader(header);
      setComponent(component);
      setOpenDialog(true);
      window.history.pushState(false, "", `${initialHash}#dialog`);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const onHashChange = () =>
      setOpenDialog(window.location.hash === `${initialHash}#dialog`);
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, [initialHash]);

  const closeDialog = (event, reason) => {
    if (reason ==='backdropClick'){
      return false;
    }
    window.history.back();
    setOpenDialog(false);
  };

  const DialogHeader = ({ header }) => {
    return (
      <>
        <IconButton
          className="modalCloseButton"
          aria-label={Translate.get("Close")}
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{header}</DialogTitle>
      </>
    );
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        className={customClass}
        scroll="paper"
        onClose={closeDialog}
        open={openDialog}
      >
        {header && <DialogHeader header={header} />}
        {components}
      </Dialog>

      <IconButton
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Icon
          icon={horizontal ? moreHorizontalFill : moreVerticalFill}
          width={20}
          height={20}
        />
      </IconButton>

      <Menu
        className="leftDDMenu"
        open={isOpen}
        anchorEl={ref.current}
        onClose={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
        PaperProps={{
          sx: { maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {content.filter((item) => item.hide !== true).map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            key={index}
            onClick={(event) => {
              event.stopPropagation();
              handleMenuItemAction(
                item.route,
                item.component,
                item.dialogHeader,
                item.onClick,
                item.customClass
              );
            }}
          >
            {item.icon}
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default DotMenu;
