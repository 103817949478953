import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Translate from '../utils/Translate';
import { TextField, FormControl, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import HelpList from '../components/help/HelpList';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useApi } from '../utils/Api';

const SearchField = ({ inputRef, query, setQuery }) => {
  return (
    <TextField
      inputRef={inputRef}
      inputProps={{ spellCheck: false }}
      fullWidth
      id="outlined-basic"
      label={Translate.get('ePort2WhatQuestion')}
      variant="outlined"
      name="searchString"
      value={query}
      onChange={(evt) => setQuery(evt.target.value)}
    />
  );
};

export default function Help() {
  const location = useLocation();
  const searchFieldRef = useRef();
  const [state, setState] = useState(false);
  const [data, setData] = useState(false);
  const [routeData, setRouteData] = useState(false);
  const [showRouteData, setShowRouteData] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [showSearchData, setShowSearchData] = useState(false);
  const [locationPathName, setLocationPathName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [helpCategories, setHelpCategories] = useState(false);
  const [showHelpCategories, setShowHelpCategories] = useState(false);
  const api = useApi();
  const smallScreen = useMediaQuery('(max-width:700px)');
  const [query, setQuery] = useState('');
  const [showImage, setShowImage] = useState('');

  const toggleDrawer = (open) => (event) => {
    /* if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    } */
    if (open) {
      window.analyticsEvent("eport_xtras","eport_help");
      if (window.global.helpLink){
        if (window.global.helpLink.subCat) {
        showHelpPages(window.global.helpLink.cat,true);
        callBack(window.global.helpLink.subCat);
        }
        else{
          showHelpPages(window.global.helpLink.cat);
        }
        delete window.global.helpLink;
      }
      else if (locationPathName !== location.pathname) {
        //console.log('Hämta nya vanliga frågor', location.pathname);
        //Riktigt API call för hjälpavsnitt beroende på route (location.pathname)
        setLocationPathName(location.pathname);
        showHelpPages(location.pathname);
      }
    }

    setState(open);
  };
  const showHelpPages = (route,directLink=false) => {
    //console.log('showHelpPages route', route);
    setIsLoading(true);
    (async () => {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}help/categories/by-reference/${localStorage.lang ?? 29}?reference=${encodeURIComponent(route)}`,
        false,
        "GET",
        (response) => response && typeof response === 'object' && "helpPages" in response
      );
      //console.log('response.helpPages', response);
      setRouteData(response.helpPages ?? []);
      if (directLink){
        setShowRouteData(false);
      }
      else{
        setShowRouteData(true);
        !data && !showSearchData && setShowRouteData(true);
      }
      setShowHelpCategories(false);
      setIsLoading(false);
      if (!response.helpPages) {
        /* Fix om route saknas i backend*/
        showAll();
      }
    })();
  };

  const showAll = () => {
    setIsLoading(true);
    (async () => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}help/categories/${localStorage.lang ?? 29}`,
      );
      //console.log('response.helpCategories', response);
      setHelpCategories(response.helpCategories ?? []);
      setShowHelpCategories(true);
      setShowRouteData(false);
      setIsLoading(false);
    })();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!searchFieldRef.current.value) {
      return;
    }
    setIsLoading(true);
    (async () => {
      const response = await api.fetch(
        `${
          process.env.REACT_APP_MAIN_URL
        }help/pages/search/${localStorage.lang ?? 29}?searchText=${encodeURIComponent(
          searchFieldRef.current.value,
        )}`,
      );
      //console.log('SEARCH----------', response);
      setSearchData(response.helpPages);
      setShowSearchData(true);
      setShowRouteData(false);
      setShowHelpCategories(false);
      setData(false);
      setIsLoading(false);
    })();
  };
  const callBack = (link) => {
    setShowRouteData(false);
    setShowSearchData(false);
    console.log('CALLBACK', link);
    //Test för att visa vad som händer vid klick på länk 1 på sida
    if (link === 'Link1') {
      setData('<div>Det klickades på Link1</div>');
    } else {
      setIsLoading(true);
      (async () => {
        const response = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}help/pages/${link}/${localStorage.lang ?? 29}`,
        );
        //console.log('Page view', response);
        setData(`<header>${response.title}</header>${response.content}`);
        setIsLoading(false);
      })();
    }
  };

  const callBackCategories = (route) => {
    setShowRouteData(false);
    setShowSearchData(false);
    //console.log('showHelpPages', route);
    showHelpPages(route);
  };
  const resetQuery = () => {
    //console.log('resetQuery');
    setShowSearchData(false);
    setShowRouteData(true);
    setQuery('');
  };

  const resetSearch = () => {
    if (query) {
      setShowSearchData(true);
    } else {
      setShowRouteData(true);
    }
    setData(false);
  };

  const linkClick = (e, link) => {
    e.preventDefault();
    if (e.target.tagName === 'A') {
      const href = e.target.getAttribute('href');
      /* console.log(
        'Static html href call, do anything with the href attribute',
        href,
      ); */
      //console.log('linkClick', href,href.indexOf('/'));
      //Gå varhelst i hjäpen beroende på href link:
      if (href.indexOf('/') === 0) {
        setData(false);
        showHelpPages(href);
      }
      else{
        callBack(href);
      }
     
    }
    else if (e.target.tagName === 'IMG') {
      //console.log('imgclick');
      setShowImage(e.target.getAttribute('src'));

    }
  };
  return (
    <>
      <IconButton size="large" onClick={toggleDrawer(true)} id="helpTrigger">
        <HelpIcon /* sx={{ fontSize: '48px' }}  */ />
      </IconButton>
      <Drawer
        className="helpDrawer"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 100}} //Help always on top
        anchor="right"
        hideBackdrop={false}
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 600, maxWidth: '100vw', padding: '24px' }}
          //onClick={toggleDrawer(false)}
          //onKeyDown={toggleDrawer(false)}
          className="helpContainer"
        >
          <IconButton
            aria-label={Translate.get('Close')}
            onClick={toggleDrawer(false)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2>{Translate.get('ePort2HelpCenter')}</h2>

          <div>
            <FormControl fullWidth>
              <form className="actorsSearchForm" onSubmit={handleSubmit}>
                <SearchField
                  inputRef={searchFieldRef}
                  query={query}
                  setQuery={setQuery}
                />
                {!smallScreen && (
                  <Button
                    type="submit"
                    className="actorsSearchButton"
                    variant="contained"
                  >
                    {Translate.get('Search')}
                  </Button>
                )}
              </form>
            </FormControl>
          </div>
          {isLoading && (
            <div className="helpPreloader">
              <Box
                component="img"
                src="/static/Spinner_ePort.svg"
                sx={{ width: 112, height: 112 }}
              />
            </div>
          )}
          {showRouteData && (
            <>
              <div className="stdFlexRow">
                <h3>{Translate.get('ePort2FAQ')}</h3>
                <Button onClick={() => showAll()}>{Translate.get('ePort2CategoriesShow')}</Button>
              </div>
              <List className="helpList">
                {routeData.map((row, index) => (
                  <HelpList
                    key={index}
                    icon={row.iconName}
                    title={row.title}
                    text={row.content}
                    link={row.helpPageId}
                    callBack={callBack}
                  />
                ))}
              </List>
            </>
          )}
          {showHelpCategories && (
            <>
              <div className="stdFlexRow">
                <h3>{Translate.get('ePort2Categories')}</h3>{' '}
                <Button onClick={() => showHelpPages(locationPathName)}>
                  {Translate.get('ePort2FAQShow')}
                </Button>
              </div>
              <List className="helpList">
                {helpCategories.map((row, index) => (
                  <HelpList
                    key={index}
                    icon={row.iconName}
                    title={row.title}
                    text={row.content}
                    link={row.reference}
                    callBack={callBackCategories}
                  />
                ))}
              </List>
              {/*  <div className='stdFlexRow'><div></div> <Button   onClick={() => showHelpPages(locationPathName)}>Länk i botten,Visa vanliga frågor</Button></div> */}
            </>
          )}
          {showSearchData && (
            <>
              <div className="helpCloseRow">
                <h3>{Translate.get('ePort2SearchResults')}</h3>
                <IconButton onClick={() => resetQuery()}>
                  <KeyboardDoubleArrowUpIcon />
                </IconButton>
              </div>
              <List className="helpList">
                {searchData.map((row, index) => (
                  <HelpList
                    key={index}
                    icon={row.iconName}
                    title={row.title}
                    text={row.content}
                    link={row.helpPageId}
                    callBack={callBack}
                  />
                ))}
              </List>
            </>
          )}

          {data && (
            <div>
              <div className="helpCloseRow">
                <div></div>
                <IconButton onClick={() => resetSearch()}>
                  <KeyboardDoubleArrowUpIcon />
                </IconButton>
              </div>
              {/* Mockdata start loop av riktig data här */}
              <div style={{ backgroundColor: 'white' }}>
                <div
                  className="helpContentBox"
                  onClick={(e) => {
                    linkClick(e);
                  }}
                  /* Detta är den riktiga datan för en help page */
                  dangerouslySetInnerHTML={{ __html: data }}
                ></div>
              </div>
              {/* Mockdata end */}
            </div>
          )}
          <div className="flexBottomCenter">
            <div>
            {Translate.get('ePort2FindQuestion') + " "}
              <a className='stdMailToLink' style={{fontSize:'inherit'}} href="mailto:support@eport.se">{Translate.get('ePort2ContactSupport')}</a>
            </div>
          </div>
        </Box>
        {showImage && <div className='fullSizeImage' onClick={() => setShowImage(false)}><img src={showImage} alt=""></img></div>}
      </Drawer>
    
    </>
  );
}
