import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../../utils/Translate";
import { useState } from "react";
import { useApi } from "../../../utils/Api";

export default function UserCrud({ onClose, onSaved, user }) {
  const [title] = useState(
    user?.ePortUserId
      ? Translate.get("EditReadonlyUser")
      : Translate.get("AddReadonlyUser")
  );
  const [userData, setUserData] = useState(user);
  const [isLoading, setIsLoading] = useState(false);

  const api = useApi();

  async function postAddOrUpdateUser() {
    setIsLoading(true);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/readonly`,
      userData,
      "POST"
    );
    setIsLoading(false);

    if (response.isSuccessful) {
      await onSaved();
      onClose();
    }
  }

  return (
    <>
      <Dialog
        maxWidth={false}
        className="stdDialog"
        scroll="paper"
        onClose={onClose}
        open={true}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="stdDialogContent">
          <Stack gap="16px" marginTop="16px">
            <Stack direction="row" gap="16px">
              <TextField
                label={Translate.get("FirstName")}
                value={userData.firstName ?? ""}
                onChange={(ev) =>
                  setUserData((prev) => ({
                    ...prev,
                    firstName: ev.target.value,
                  }))
                }
                required
              />
              <TextField
                label={Translate.get("LastName")}
                value={userData.lastName ?? ""}
                onChange={(ev) =>
                  setUserData((prev) => ({
                    ...prev,
                    lastName: ev.target.value,
                  }))
                }
                required
              />
            </Stack>
            <TextField
              label={Translate.get("Email")}
              value={userData.ePortAccountId ?? ""}
              onChange={(ev) =>
                setUserData((prev) => ({
                  ...prev,
                  ePortAccountId: ev.target.value,
                }))
              }
              required
            />
            {!user.ePortUserId && (
              <Typography
                variant="body2"
                sx={{
                  marginTop: "16px",
                  color: "text.secondary",
                }}
              >
                {Translate.get("ReadOnlyUserPriceInfo")}
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={
              !userData.firstName ||
              !userData.lastName ||
              !userData.ePortAccountId
            }
            onClick={postAddOrUpdateUser}
          >
            {title}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
