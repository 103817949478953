import { useMediaQuery } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import Preloader from "../Preloader";
import { useApi } from "../../utils/Api";
import ID06TrainingsDialog from "./ID06TrainingsDialog";
import { useNavigate } from "react-router-dom";
import ID06CompetencesDialogSmall from "./ID06CompetencesDialogSmall";
import ID06CompetencesDialogBig from "./ID06CompetencesDialogBig";
import Personnummer from "personnummer";
import { AlertDialog } from "../AlertDialog";
import Translate from "../../utils/Translate";
import { groupBy } from "lodash";

export default function ID06CompetencesDialogBase({ courseId, onClose }) {
  const [bookings, setBookings] = useState([]);
  const [externalSkills, setExternalSkills] = useState([]);
  const [ID06Skills, setID06Skills] = useState([]);
  const [canApproveAll, setCanApproveAll] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showAllTrainingsDialog, setShowAllTrainingsDialog] = useState(false);
  const [reloadData, setReloadData] = useState(true); // For initial load
  const [isLocked, setIsLocked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isSmallScreen = useMediaQuery("(max-width:910px)");

  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (reloadData) {
      setReloadData(false);
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}integrations/ID06/courses/${courseId}`,
          false,
          "GET"
        )
        .then((data) => {
          if (data.isSuccessful) {
            setExternalSkills(data.externalSkills ?? []);
            setID06Skills(data.id06Skills ?? []);

            const bookings = data.courseBookings.map((b) => ({
              ...b,
              courseParticipants: b.courseParticipants.map((cp) => ({
                ...cp,
                errors: {
                  // Commented out for now, since the error I usually get is "Person not found",
                  // which doesn't really mean that anything is invalid, and thus shouldn't block.
                  // id06: data.errors.some((e) => e.actorId === cp.actorId)
                  //   ? `ID06: ${
                  //       data.errors.find((e) => e.actorId === cp.actorId)
                  //         .errorMessage
                  //     }`
                  //   : null,
                  nationality: !cp.nationality ? "MissingNationality" : false,
                  personalNo:
                    cp.nationality === "SE" &&
                    !Personnummer.valid(cp.personalNo)
                      ? "InvalidOrgNo"
                      : false,
                },
              })),
            }));
            setBookings(bookings);

            const hasExternalSkills =
              !!data.externalSkills && data.externalSkills.length > 0;
            setShowAllTrainingsDialog(!hasExternalSkills);
          }
          setIsDataFetched(true);
        });
    }
  }, [courseId, reloadData, api]);

  function handleReloadData() {
    setIsDataFetched(false);
    setReloadData(true);
  }

  // Updates the disabled status of the "Approve All" button,
  // based on whether there are any skills remaining that are
  // not yet approved, and are not locked by an existing license
  useEffect(() => {
    const allStudents = bookings.flatMap((b) => b.courseParticipants);
    const someStudentHasNotAllSkills = allStudents.some((student) =>
      externalSkills.some(
        (es) =>
          !ID06Skills.some(
            (x) =>
              x.code === es.code &&
              x.actorId === student.actorId &&
              !x.isDeleted
          )
      )
    );
    setCanApproveAll(someStudentHasNotAllSkills);
  }, [ID06Skills, externalSkills, bookings]);

  async function handleSave() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}integrations/ID06/courses/${courseId}/skills`,
      ID06Skills,
      "PUT"
    );
    if (response.isSuccessful) {
      onClose();
    } else if (response.errors && response.errors.length > 0) {
      const allStudents = bookings.flatMap((b) => b.courseParticipants);
      const studentErrors = Object.entries(
        groupBy(response.errors, "actorId")
      ).map(([actorId, errors]) => ({
        student: allStudents.find((a) => a.actorId == actorId),
        errors: errors.map((e) => ({
          ...e,
          name: externalSkills.find((x) => x.code === e.code)?.name,
        })),
      }));
      const errorText = studentErrors
        .map((ae) => {
          const studentText = `${ae.student.name}, ${ae.student.personalNo} (${ae.student.nationality}):`;
          const errors = [...ae.errors];
          errors.sort((a, b) => a.name.localeCompare(b.name, "sv"));
          const errorsText = errors
            .map((e) => `${e.name}\n${e.errorMessage}`)
            .join("\n\n");
          return `${studentText}\n\n${errorsText}`;
        })
        .join("\n\n\n");
      setErrorMessage(errorText);
      setReloadData(true);
    }
  }

  function handleCheckID06Skill(event, actorId, courseParticipantId, code) {
    const index = ID06Skills.findIndex(
      (x) =>
        x.actorId === actorId &&
        x.courseParticipantId === courseParticipantId &&
        x.code === code
    );
    if (index > -1) {
      setID06Skills(
        (prev) =>
          prev[index].referenceId
            ? prev.with(index, {
                ...prev[index],
                isDeleted: !prev[index].isDeleted, // Toggle isDeleted
              })
            : prev.toSpliced(index, 1) // Remove the entire skill
      );
    } else {
      // Add new skill
      setID06Skills((prev) => [
        ...prev,
        {
          actorId,
          courseParticipantId,
          code,
          hasGivenWrittenConsent: hasWrittenConsent(),
        },
      ]);
    }
  }

  function handleCheckSkillAllChange(event, code) {
    const allStudents = bookings.flatMap((b) => b.courseParticipants);
    const studentsWithoutSkill = allStudents.filter(
      (student) =>
        !ID06Skills.some(
          (x) =>
            x.code === code && x.actorId === student.actorId && !x.isDeleted
        )
    );
    if (studentsWithoutSkill.length > 0) {
      for (let student of studentsWithoutSkill) {
        handleCheckID06Skill(
          null,
          student.actorId,
          student.courseParticipantId,
          code
        );
      }
    } else {
      for (let student of allStudents) {
        handleCheckID06Skill(
          null,
          student.actorId,
          student.courseParticipantId,
          code
        );
      }
    }
  }

  function handleApproveAllSkills() {
    const allStudents = bookings.flatMap((b) => b.courseParticipants);
    for (const externalSkill of externalSkills) {
      const studentsWithoutSkill = allStudents.filter(
        (student) =>
          !ID06Skills.some(
            (x) =>
              x.code === externalSkill.code &&
              x.actorId === student.actorId &&
              !x.isDeleted
          )
      );
      for (let student of studentsWithoutSkill) {
        handleCheckID06Skill(
          null,
          student.actorId,
          student.courseParticipantId,
          externalSkill.code
        );
      }
    }
  }

  function handleApproveAllSkillsForStudent(actorId, courseParticipantId) {
    const missingSkills = externalSkills.filter(
      (es) =>
        !ID06Skills.some(
          (x) => x.code === es.code && x.actorId === actorId && !x.isDeleted
        )
    );

    for (let skill of missingSkills) {
      handleCheckID06Skill(null, actorId, courseParticipantId, skill.code);
    }
  }

  function handleOpenAllTrainingsDialog() {
    setShowAllTrainingsDialog(true);
  }

  function handleCloseAllTrainingsDialog() {
    setShowAllTrainingsDialog(false);
    setReloadData(true);
  }

  function hasErrors() {
    return bookings.some((b) =>
      b.courseParticipants.some((cp) =>
        !!cp.errors ? Object.values(cp.errors).some((val) => !!val) : false
      )
    );
  }

  function hasWrittenConsent() {
    if (!ID06Skills || ID06Skills.filter((x) => !x.isDeleted).length === 0) {
      return false;
    }

    const allStudentIds = bookings
      .flatMap((b) => b.courseParticipants)
      .map((cp) => cp.courseParticipantId);
    return allStudentIds.every((id) => {
      const skills = ID06Skills.filter(
        (s) => s.courseParticipantId === id && !s.isDeleted
      );
      return (
        skills.length === 0 || skills.every((s) => s.hasGivenWrittenConsent)
      );
    });
  }

  function handleWrittenConsentChange(event) {
    setID06Skills((prev) =>
      prev.map((x) => ({
        ...x,
        hasGivenWrittenConsent: prev.referenceId
          ? prev.hasGivenWrittenConsent // Can't change if already registered in ID06
          : event.target.checked,
      }))
    );
  }

  const commonProps = {
    onClose,
    bookings,
    ID06Skills,
    externalSkills,
    handleCheckID06Skill,
    canApproveAll,
    handleApproveAllSkills,
    handleOpenAllTrainingsDialog,
    showAllTrainingsDialog,
    handleApproveAllSkillsForStudent,
    handleSave,
    handleReloadData,
    handleWrittenConsentChange,
    hasWrittenConsent,
    hasErrors,
  };
  const bigProps = {
    ...commonProps,
    handleCheckSkillAllChange,
  };
  const smallProps = {
    ...commonProps,
  };

  console.log("Skills", ID06Skills);
  return (
    <>
      {errorMessage && (
        <AlertDialog
          open
          titleText={Translate.get("SomethingFailed")}
          bodyText={errorMessage}
          buttonText={Translate.get("Close")}
          onClose={() => setErrorMessage(null)}
          showCopyButton
          zIndexOverride={1300}
        />
      )}
      {showAllTrainingsDialog && (
        <ID06TrainingsDialog
          courseId={courseId}
          onClose={handleCloseAllTrainingsDialog}
        />
      )}

      {!isDataFetched && <Preloader zIndexOverride={4000} />}
      {isDataFetched && !isSmallScreen && (
        <ID06CompetencesDialogBig {...bigProps} />
      )}
      {isDataFetched && isSmallScreen && (
        <ID06CompetencesDialogSmall {...smallProps} />
      )}
    </>
  );
}
