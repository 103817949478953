import { Box, Typography, Stack, Avatar } from "@mui/material";
import ConditionalWrapper from "./ConditionalWrapper";

export default function ActorAvatar({
  name,
  email,
  extraFields,
  icon,
  iconColor='#fafafa',
  iconBgColor='C4CDD5',
  link,
  disabled,
  description
}) {

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={(children) => (
        <a className="RemoveHyperLinkDecorations" href={link}>
          {children}
        </a>
      )}
    >
      <Stack
        sx={disabled ? { opacity: 0.3,alignItems:'center'} : {alignItems:'center'}}
        direction="row"
        spacing={2}
        
      >
          <Avatar sx={{color:iconColor, bgcolor:iconBgColor}}>
            {icon}
          </Avatar>
        <Box
          className="avatarInfo"
        >
           {description && ( <Box>{description}</Box>)}
          <Box>{name}</Box>
          {email && (
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {email}
              </Typography>
            </Box>
          )}
          {extraFields &&
            extraFields.map((field, i) => (
              <Box key={i}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {field}
                </Typography>
              </Box>
            ))}
        </Box>
      </Stack>
    </ConditionalWrapper>
  );
}
