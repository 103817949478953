import { Grid } from "@mui/material";
import Translate from "../../utils/Translate";

export default function AddressMenuItem({
  street1,
  street2,
  zipCode,
  city,
  country,
  addressType,
  maxWidth,
}) {
  return (
    <Grid container whiteSpace="pre" maxWidth={maxWidth ?? "100%"}>
      <Grid item xs={9}>
        {street1?.trim() ? street1 : " "}
      </Grid>
      <Grid item xs={3}>
        <span
          style={{
            backgroundColor: "#EDEFF1",
            padding: "3px",
            borderRadius: "3px",
            fontSize: "smaller",
          }}
        >
          {Translate.getActorAddressType(addressType)}
        </span>
      </Grid>
      <Grid item xs={12}>
        {street2?.trim() ? street2 : " "}
      </Grid>
      <Grid item xs={12}>
        {`${zipCode?.trim() ? zipCode : " "} ${city?.trim() ? city : " "}`}
      </Grid>
      <Grid item xs={12}>
        {country?.trim() ? country : " "}
      </Grid>
    </Grid>
  );
}
