import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import Translate from "../../utils/Translate";

export default function LicenseRegistrationStep3Card({
  validationStatus,
  validationMessage,
  gdprIsChecked,
  ssnValidationIsChecked,
  handleSend,
  handleReset,
  handleGdprCheckbox,
  handleSsnValidationCheckbox,
  handlePrint,
  isSmallScreen,
  dataIsInvalidForSubmit
}) {
  return (
    <>
      <Box marginTop="16px" marginBottom="16px">
        <h2>{Translate.get("Step3Submit")}</h2>
      </Box>
      <Card elevation={0} sx={{ width: "100%", paddingBottom: "8px" }}>
        <CardContent>
          <Grid
            container
            spacing={4}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item container direction="column" spacing={2}>
              <Grid item>{Translate.get("ExternalImportGdprInfo")}</Grid>
              <Grid item>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={gdprIsChecked}
                      onChange={handleGdprCheckbox}
                    />
                  }
                  label={Translate.get("ExternalImportGdprCheck")}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            {validationStatus === 1 && (
              <Grid item>
                <Alert severity="success">{validationMessage}</Alert>
              </Grid>
            )}
            {validationStatus === -1 && (
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Alert severity="error">{validationMessage}</Alert>
                </Grid>
                {validationMessage.includes("Personnummer") && (
                  <Grid item>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={ssnValidationIsChecked}
                          onChange={handleSsnValidationCheckbox}
                        />
                      }
                      label={Translate.get("ExternalImportSsnValidation")}
                      labelPlacement="end"
                      sx={{ marginLeft: "6px" }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {
            !isSmallScreen && (
              <Box width="100%" height="36px" marginTop="26px">
                <Box sx={{ float: "left" }}>
                  <Button
                    variant="outlined"
                    onClick={handleSend}
                    disabled={validationStatus === 1 || dataIsInvalidForSubmit()}
                  >
                    {Translate.get("PlaceOrder")}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleReset}
                    sx={{ marginLeft: "16px" }}
                  >
                    {Translate.get("Clear")}
                  </Button>
                </Box>
                <Box sx={{ float: "right" }}>
                  <Button variant="outlined" onClick={handlePrint} disabled={dataIsInvalidForSubmit()}>
                    {Translate.get("Receipt")}
                  </Button>
                </Box>
              </Box>
            )
          }
          {isSmallScreen && (
            <Box width="100%" height="60px" marginTop="26px">
              <Button
                variant="outlined"
                onClick={handleSend}
                disabled={validationStatus === 1 || dataIsInvalidForSubmit()}
                sx={{ marginRight: "1em", marginBottom: "1em" }}
              >
                {Translate.get("PlaceOrder")}
              </Button>
              <Button
                variant="outlined"
                onClick={handleReset}
                sx={{ marginRight: "1em", marginBottom: "1em" }}
              >
                {Translate.get("Clear")}
              </Button>
              <Button variant="outlined" onClick={handlePrint} disabled={dataIsInvalidForSubmit()} sx={{ marginRight: "1em", marginBottom: "1em"}}>
                {Translate.get("Receipt")}
              </Button>
            </Box>
          )}

        </CardContent>
      </Card>
    </>
  );
}
