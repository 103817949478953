import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from "../../utils/dateTimeFormat";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Link,
  FormHelperText,
  Box,
} from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import Translate from "../../utils/Translate";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MoneyIcon from "@mui/icons-material/Money";
import PersonIcon from "@mui/icons-material/Person";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useSnackbar } from "notistack";
import InfoIcon from "@mui/icons-material/Info";

export default function AgreementForm({
  companyActorId,
  agreementId,
  onClose,
}) {
  if (!companyActorId) {
    throw new Error("Missing props");
  }

  const [agreement, setAgreement] = useState({
    actorId: companyActorId,
    startDate: null,
    endDate: null,
    orderCanUpdate: true,
  });
  const [originalTerminateDate, setOriginalTerminateDate] = useState(null);
  const [originalEndDate, setOriginalEndDate] = useState(null);
  const [orderNoRequired, setOrderNoRequired] = useState(false);
  const [companyDefaultCustomerOrderNo, setCompanyDefaultCustomerOrderNo] =
    useState(null);
  const [currency, setCurrency] = useState(null);
  const [agreementParts, setAgreementParts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [company, setCompany] = useState(null);

  const [showEndDateRenewInfo, setShowEndDateRenewInfo] = useState(false);
  const [
    showAutoDeleteExtraDiscountsQuestion,
    setShowAutoDeleteExtraDiscountsQuestion,
  ] = useState(false);
  const [showAutoCancelOrderQuestion, setShowAutoCancelOrderQuestion] =
    useState(false);
  const [
    showAutoOrderCancelNotPossibleQuestion,
    setShowAutoOrderCancelNotPossibleQuestion,
  ] = useState(false);
  const [showAutoCreateOrderQuestion, setShowAutoCreateQuestion] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [longErrorMessage, setLongErrorMessage] = useState(null);
  const [validation, setValidation] = useState({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = useCallback(() => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  }, [navigate]);

  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("sm"));

  const loadCompanyOrderInfo = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orderinfo`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        // We don't replace the currency if already set. Could be from a loaded order for example
        setCurrency((prevCurr) =>
          prevCurr ? prevCurr : response.defaultCurrency
        );
        setOrderNoRequired(response.defaultCustomerOrderNoRequired);
        setCompanyDefaultCustomerOrderNo(response.defaultCustomerOrderNo);
        if (response.defaultCustomerOrderNo && !agreementId) {
          setAgreement((prev) => ({
            ...prev,
            customerOrderNo:
              prev.customerOrderNo ?? response.defaultCustomerOrderNo,
          }));
        }
      }
    },
    [api, agreementId]
  );

  const loadCompany = useCallback(
    async (actorId) => {
      if (actorId <= 0) {
        return;
      }
      // Not using the /as-client endpoint here, as I'm primarily interested in the addresses
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        // There's no field for the company since the agreements are always managed from the company view,
        // so there's not really any proper place to put a validation error message. To make it simple
        // this will simply show an error toast message and close the dialog if addresses are missing.
        if (!response.invoiceAddress || !response.postalAddress) {
          const missingAddressTypes = [
            !response.invoiceAddress ? 1 : null,
            !response.postalAddress ? 3 : null,
          ]
            .filter((a) => !!a)
            .map((a) => Translate.getActorAddressType(a))
            .join(", ");
          const messageText = `${Translate.get(
            "ErrorCompanyMissingAddresses"
          )} ${missingAddressTypes}`;
          const errorMessage = enqueueSnackbar(messageText, {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          });
          closeAndReload();
        }

        setCompany(response);
      }
    },
    [api, closeAndReload, enqueueSnackbar, closeSnackbar]
  );

  const loadContacts = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const loadedContacts = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/clients/${actorId}/contacts`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (loadedContacts && Array.isArray(loadedContacts)) {
        setContacts(loadedContacts);
      } else {
        setContacts([]);
      }
    },
    [api]
  );

  const loadAgreement = useCallback(
    async (agreementId) => {
      if (!agreementId) {
        return;
      }
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}agreements/${agreementId}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setAgreement(response);
        setOriginalTerminateDate(response.terminateDate);
        setOriginalEndDate(response.endDate);

        // The list of parts is loaded separately. If they're already loaded
        // we don't change anything here, otherwise add items from the agreement
        setAgreementParts((prevParts) =>
          prevParts && prevParts.length > 0
            ? prevParts
            : [
                {
                  partId: response.partId,
                  description: response.description,
                },
              ]
        );

        let tempContacts = [];
        if (response.actorContactId) {
          tempContacts.push({
            actorId: response.actorContactId,
            actorName: response.actorContactName,
          });
        }
        if (response.actorReceiverId) {
          tempContacts.push({
            actorId: response.actorReceiverId,
            actorName: response.actorReceiverName,
          });
        }
        // The list of parts is loaded separately. If they're already loaded
        // we don't change anything here, otherwise add items from the agreement
        setContacts((prevContacts) =>
          prevContacts && prevContacts.length > 0 ? prevContacts : tempContacts
        );
        setCurrency(response.orderCurrency);
      }
    },
    [api]
  );

  const loadParts = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}warehouse/parts/for-agreements`,
      false,
      "GET"
    );
    if (response && response.isSuccessful) {
      setAgreementParts(response.parts);
    }
  }, [api]);

  const getPartPrice = useCallback(
    async (partId, actorId) => {
      if (!partId) {
        return;
      }

      const request = {
        partId: partId,
        actorId: actorId,
        qty: 1,
        currency: currency,
      };

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-price`,
        request,
        "POST"
      );
      if (response.isSuccessful) {
        return response.price;
      }
    },
    [api, currency]
  );

  const getPartEndDate = useCallback(
    async (partId, startDate) => {
      if (!partId || !startDate) {
        return;
      }

      const response = await api.fetchWithOverride(
        `${
          process.env.REACT_APP_MAIN_URL
        }warehouse/parts/${partId}/end-date?startDate=${startDate.toISOString()}`,
        false,
        "GET",
        (response) => !!response
      );
      if (!!response) {
        return new Date(response);
      }
    },
    [api]
  );

  useEffect(() => {
    Promise.all([
      loadCompany(companyActorId),
      loadCompanyOrderInfo(companyActorId),
      loadContacts(companyActorId),
      loadParts(),
      loadAgreement(agreementId),
    ]).then(() => {
      setIsLoading(false);
    });
  }, [
    companyActorId,
    agreementId,
    api,
    loadParts,
    loadContacts,
    loadCompanyOrderInfo,
    loadCompany,
    loadAgreement,
  ]);

  // Validation
  useEffect(() => {
    const receiver =
      agreement.actorReceiverId &&
      contacts.find((x) => x.actorId == agreement.actorReceiverId);
    // console.log("###VALIDATION RECEIVER", receiver)
    if (receiver && !receiver.email) {
      setValidation((prev) => ({
        ...prev,
        actorReceiver: Translate.get("EmailRequired"),
      }));
    } else {
      setValidation((prev) => ({ ...prev, actorReceiver: null }));
    }

    const contact =
      agreement.actorContactId &&
      contacts.find((x) => x.actorId == agreement.actorContactId);
    // console.log("###VALIDATION CONTACT", contact)
    if (contact && !contact.email) {
      setValidation((prev) => ({
        ...prev,
        actorContact: Translate.get("EmailRequired"),
      }));
    } else {
      setValidation((prev) => ({ ...prev, actorContact: null }));
    }
  }, [agreement]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  async function saveAgreement(autoCancelOrder) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}agreements`,
      { ...agreement, autoCancelOrder },
      "POST"
    );
    if (response.isSuccessful) {
      closeAndReload();
    }
  }

  function updateTerminateDate(newValue) {
    const isTerminating = !agreement.terminateDate && !!newValue;
    const isUnterminating = !!agreement.terminateDate && !newValue;

    if (isTerminating) {
      setShowAutoDeleteExtraDiscountsQuestion(true);
    } else if (isUnterminating) {
      setAgreement((prevAgr) => ({
        ...prevAgr,
        autoDeleteExtraDiscounts: null,
      }));
    }

    setAgreement((prevAgr) => ({
      ...prevAgr,
      terminateDate: newValue,
    }));
  }

  async function handleSaveAgreement() {
    const isTerminating = !originalTerminateDate && !!agreement.terminateDate;
    const isUnterminating = !!originalTerminateDate && !agreement.terminateDate;
    if (isTerminating) {
      agreement.orderCanUpdate
        ? setShowAutoCancelOrderQuestion(true)
        : setShowAutoOrderCancelNotPossibleQuestion(true);
    } else if (isUnterminating) {
      setShowAutoCreateQuestion(true);
    } else {
      await saveAgreement();
    }
  }

  async function handlePartSelected(partId) {
    if (partId === agreement.partId) {
      return;
    }

    const price = await getPartPrice(partId, companyActorId);

    // Here we will set defaults if not yet set, but avoid changing if already set
    const startDate = agreement.startDate ?? new Date();
    const endDate =
      startDate === agreement.startDate
        ? agreement.endDate
        : await getPartEndDate(partId, startDate);

    setAgreement((prevAgr) => ({
      ...prevAgr,
      price,
      partId,
      startDate,
      endDate,
    }));
  }

  function handleContactSelected(event) {
    const actorId = event.target.value;

    //Only update customerOrderNo automatically if it's a new order, or if it's empty
    if (
      !agreement.agreementId ||
      (agreement.orderCanUpdate && !agreement.customerOrderNo)
    ) {
      if (agreement.actorContactId > 0) {
        const previousContact = contacts.find(
          (c) => c.actorId === agreement.actorContactId
        );
        // Reset to company customerOrderNo if deselecting a contact
        if (
          previousContact.defaultCustomerOrderNo === agreement.customerOrderNo
        ) {
          setAgreement((prevOrder) => ({
            ...prevOrder,
            customerOrderNo: companyDefaultCustomerOrderNo,
          }));
        }
      }
      const contact = contacts.find((c) => c.actorId === actorId);
      if (contact.defaultCustomerOrderNo) {
        setAgreement((prevOrder) => ({
          ...prevOrder,
          customerOrderNo: contact.defaultCustomerOrderNo,
        }));
      }
    }

    setAgreement((prevAgr) => ({ ...prevAgr, actorContactId: actorId }));
  }

  function handleReceiverSelected(event) {
    const actorId = event.target.value;
    setAgreement((prevAgr) => ({ ...prevAgr, actorReceiverId: actorId }));
  }

  async function updateEndDate(startDate) {
    const endDate = await getPartEndDate(agreement.partId, startDate);
    setAgreement((prevAgr) => ({ ...prevAgr, endDate }));

    const now = new Date();
    const renewMaxDate = addDays(now, 30);
    if (originalEndDate != endDate && endDate < renewMaxDate) {
      setShowEndDateRenewInfo(true);
    }
  }
  // console.log("###AGR", agreement)

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <DialogTitle>
        {agreementId
          ? Translate.get("EditServiceAgreement")
          : Translate.get("CreateServiceAgreement")}
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          {!agreement.orderCanUpdate && (
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                gap: 2.3,
                paddingLeft: "9px",
                // paddingRight: "50px",
                paddingBottom: "16px",
                color: "text.secondary",
              }}
            >
              <InfoIcon />
              {Translate.get("AgreementOrderAlreadyInvoiceInfo")}
            </Typography>
          )}

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <CollectionsBookmarkIcon sx={{ marginLeft: "9px" }} />
            <FormControl fullWidth>
              <InputLabel id="part-select-label" required>
                {Translate.get("ServiceAgreementsTitle")}
              </InputLabel>
              <Select
                required
                disabled={agreementId > 0}
                labelId="part-select-label"
                id="part-select"
                label={Translate.get("ServiceAgreementsTitle")}
                value={agreement.partId ?? ""}
                onChange={(event) => handlePartSelected(event.target.value)}
              >
                {agreementParts.map((x) => (
                  <MenuItem key={x.partId} value={x.partId}>
                    {x.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <HeadsetMicIcon sx={{ marginLeft: "9px" }} />
            <FormControl
              fullWidth
              sx={{ marginTop: "10px" }}
              error={!!validation.actorContact}
            >
              <InputLabel
                id="contact-select-label"
                required={!agreement.terminateDate}
              >
                {Translate.get("Contact")}
              </InputLabel>
              <Select
                required={!agreement.terminateDate}
                disabled={
                  !contacts ||
                  contacts.length === 0 ||
                  !!agreement.terminateDate
                }
                labelId="contact-select-label"
                id="contact-select"
                value={agreement?.actorContactId ?? ""}
                label={Translate.get("Contact")}
                onChange={handleContactSelected}
              >
                {contacts &&
                  contacts.map((contact) => (
                    <MenuItem key={contact.actorId} value={contact.actorId}>
                      {contact.actorName}
                    </MenuItem>
                  ))}
              </Select>
              {validation.actorContact && (
                <FormHelperText>{validation.actorContact}</FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <PersonIcon sx={{ marginLeft: "9px" }} />
            <FormControl
              fullWidth
              sx={{ marginTop: "10px" }}
              error={!!validation.actorReceiver}
            >
              <InputLabel
                id="receiver-select-label"
                required={!agreement.terminateDate}
              >
                {Translate.get("User")}
              </InputLabel>
              <Select
                required={!agreement.terminateDate}
                disabled={
                  !contacts ||
                  contacts.length === 0 ||
                  !!agreement.terminateDate
                }
                labelId="receiver-select-label"
                id="receiver-select"
                value={agreement?.actorReceiverId ?? ""}
                label={Translate.get("User")}
                onChange={handleReceiverSelected}
              >
                {contacts &&
                  contacts.map((contact) => (
                    <MenuItem key={contact.actorId} value={contact.actorId}>
                      {contact.actorName}
                    </MenuItem>
                  ))}
              </Select>
              {validation.actorReceiver && (
                <FormHelperText>{validation.actorReceiver}</FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <ListAltIcon sx={{ marginLeft: "9px" }} />
            <TextField
              // Can't require, because sometimes we get the CustomerOrderNo after actually sending the order confirmation
              // required={orderNoRequired}
              fullWidth
              disabled={!!agreement.terminateDate}
              value={agreement?.customerOrderNo ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setAgreement((prevAgr) => ({
                  ...prevAgr,
                  customerOrderNo: event.target.value,
                }))
              }
              label={Translate.get("YourOrderNo")}
              inputProps={{ maxLength: 30 }}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <MoneyIcon sx={{ marginLeft: "9px" }} />
            <TextField
              required={!agreement.terminateDate}
              disabled={!!agreement.terminateDate}
              fullWidth
              value={agreement?.price ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setAgreement((prevAgr) => ({
                  ...prevAgr,
                  price: event.target.value,
                }))
              }
              label={Translate.get("Price")}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                type: "number",
              }}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <CalendarTodayIcon sx={{ marginLeft: "9px" }} />
            <DatePicker
              disabled={!agreement.partId || !!agreement.terminateDate}
              inputFormat={getCurrentInputFormat()}
              mask={getCurrentDateMask()}
              label={Translate.get("StartDate")}
              value={agreement.startDate}
              onChange={(newValue) => {
                updateEndDate(newValue);
                setAgreement((prevAgr) => ({
                  ...prevAgr,
                  startDate: newValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  required={!agreement.terminateDate}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: Translate.get("DatePlaceholder"),
                  }}
                />
              )}
            />
            {/* Always disabled because EndDate is based on StartDate */}
            <DatePicker
              disabled
              inputFormat={getCurrentInputFormat()}
              mask={getCurrentDateMask()}
              label={Translate.get("EndDate")}
              value={agreement.endDate}
              onChange={(newValue) => {
                setAgreement((prevAgr) => ({ ...prevAgr, endDate: newValue }));
              }}
              renderInput={(params) => (
                <TextField
                  required={!agreement.terminateDate}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: Translate.get("DatePlaceholder"),
                  }}
                />
              )}
            />
          </Stack>

          {agreement.agreementId && (
            <Stack direction="row" spacing={1} className="stdFlexLeft">
              <DoNotDisturbIcon sx={{ marginLeft: "9px" }} />
              <DatePicker
                inputFormat={getCurrentInputFormat()}
                mask={getCurrentDateMask()}
                label={Translate.get("TerminateDateLabel")}
                value={agreement.terminateDate ? agreement.terminateDate : null}
                onChange={updateTerminateDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: Translate.get("DatePlaceholder"),
                    }}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose ?? closeAndReload}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleSaveAgreement}
          disabled={
            !agreement ||
            // If we are terminating we can ignore some validation
            (!agreement.terminateDate &&
              (!agreement.actorContactId ||
                !agreement.actorReceiverId ||
                agreement.price === null ||
                agreement.price === undefined ||
                !agreement.startDate ||
                !agreement.endDate ||
                // Can't require, because sometimes we get the CustomerOrderNo after actually sending the order confirmation
                // (orderNoRequired && !agreement.customerOrderNo) ||
                Object.entries(validation).some(([_, v]) => !!v)))
          }
        >
          {agreementId
            ? Translate.get("Save")
            : Translate.get("CreateServiceAgreement")}
        </ButtonWithSpinner>
      </DialogActions>
      <YesOrNoDialog
        open={showAutoDeleteExtraDiscountsQuestion}
        title={Translate.get("DeleteDiscounts")}
        text={Translate.get("DeleteDiscountsQuestion")}
        onNo={() => {
          setShowAutoDeleteExtraDiscountsQuestion(false);
          setAgreement((prevAgr) => ({
            ...prevAgr,
            autoDeleteExtraDiscounts: false,
          }));
        }}
        onYes={() => {
          setShowAutoDeleteExtraDiscountsQuestion(false);
          setAgreement((prevAgr) => ({
            ...prevAgr,
            autoDeleteExtraDiscounts: true,
          }));
        }}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
      <YesOrNoDialog
        open={showAutoCancelOrderQuestion}
        title={Translate.get("CancelOrder")}
        text={Translate.get("CancelAgreementOrderQuestion")}
        onNo={async () => await saveAgreement(false)}
        onYes={async () => await saveAgreement(true)}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
        onCancel={() => setShowAutoCancelOrderQuestion(false)}
        showCancelButton
      />
      <YesOrNoDialog
        open={showAutoOrderCancelNotPossibleQuestion}
        title={Translate.get("CancelOrderNotPossible")}
        text={Translate.get("CancelAgreementOrderNotPossibleInfo")}
        onNo={() => setShowAutoOrderCancelNotPossibleQuestion(false)}
        onYes={async () => await saveAgreement(false)}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
      <YesOrNoDialog
        open={showAutoCreateOrderQuestion}
        title={Translate.get("AutoCreateOrder")}
        text={Translate.get("UnterminateAgreementAutoCreateOrderQuestion")}
        onNo={() => setShowAutoCreateQuestion(false)}
        onYes={saveAgreement}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
      <AlertDialog
        titleText={Translate.get("AutoRenew")}
        bodyText={Translate.get("AutoRenweEndDateInfo")}
        buttonText={Translate.get("Ok")}
        open={!!showEndDateRenewInfo}
        onClose={() => setShowEndDateRenewInfo(false)}
      />
    </>
  );
}
