import {
  Autocomplete,
  CircularProgress,
  TextField,
  ListItem,
} from "@mui/material";
import { useState, useMemo, useRef, Fragment, useEffect } from "react";
import debounce from "lodash.debounce";
import Translate from "../utils/Translate";

export default function AutocompleteSearchField({
  onValueChange,
  value,
  search,
  getOptionLabel,
  renderOption,
  defaultOptions,
  createNewValue,
  keyPropName,
  label,
  placeholder,
  minWidth,
  requireSelection,
  textFieldProps,
  hideOptionsFilter,
  disableAddAsNew,
  autoComplete,
  disabled,
  groupBy,
  disablePortal
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions ?? []);
  const [hasFocus, setHasFocus] = useState(false);

  // Need to useRef to avoid updating useMemo
  // and the debounce if search changes
  const searchRef = useRef(search);
  searchRef.current = search;

  useEffect(() => {
    setOptions(defaultOptions ?? []);
  }, [defaultOptions])

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchText) => {
        const isValidSearchText =
          !!searchText &&
          typeof searchText === "string" &&
          searchText.trim() !== "";
        if (isValidSearchText) {
          const foundOptions = await searchRef.current(searchText);
          setOptions(foundOptions);
          setIsLoading(false);
        } else {
          setOptions(defaultOptions ?? []);
          setIsLoading(false);
        }
      }, 500),
    [defaultOptions]
  );

  function handleValueChange(event, newValue) {
    const isStringValue = typeof newValue === "string";
    const isCurrentValue = isStringValue && getOptionLabel(value) === newValue;
    if (isCurrentValue) {
      // We do this to make Autocomplete's autoSelect work while avoiding the unintentional creation
      // of a new company with the same name in some edge cases related to focus and blur (it seems
      // like there's a bug in Autcomplete when selecting->blurring->focusing->blurring, fixed by this)
      onValueChange({ ...value });
    } else if (!requireSelection && isStringValue) {
      // Entering a new company name by text only
      onValueChange(createNewValue(newValue));
    } else if (requireSelection && !!newValue.inputValue) {
      // Entering a new company, and selecting "Add as new"
      onValueChange(createNewValue(newValue.inputValue));
      setOptions([]);
    } else if (!isStringValue) {
      // Selecting an existing company
      onValueChange(newValue);
      setOptions([]);
    } else {
      onValueChange(null);
      setOptions(defaultOptions ?? []);
    }
  }

  async function handleInputChange(event, newValue) {
    const isChangeEvent = event && event.type === "change";
    if (isChangeEvent) {
      if (!newValue) {
        onValueChange(null);
      }
      if (searchRef.current) {
        newValue && setIsLoading(true);
        await debouncedSearch(newValue);
      }
      if (!requireSelection) {
        onValueChange(createNewValue(newValue));
      }
    }
  }

  function handleFilterOptions(options, params) {
    let result;
    if (
      requireSelection &&
      !!params.inputValue &&
      params.inputValue.trim() !== "" &&
      !disableAddAsNew
    ) {
      // Adds an "Add as new" option to the front
      result = [...options];
      const newValue = createNewValue(
        `${Translate.get("Add")} '${params.inputValue}'`
      );
      newValue.inputValue = params.inputValue;
      result.unshift(newValue);
    } else {
      result = options;
    }

    return hideOptionsFilter
      ? result.filter((o) => !hideOptionsFilter(o))
      : result;
  }

  return (
    <Autocomplete
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      open={hasFocus}
      value={value}
      onChange={handleValueChange}
      onInputChange={handleInputChange}
      fullWidth
      freeSolo
      disabled={disabled}
      forcePopupIcon={!!defaultOptions}
      id={`autocomplete${label ?? placeholder}`}
      isOptionEqualToValue={(option, value) => false}
      blurOnSelect
      selectOnFocus
      clearOnBlur={requireSelection}
      disableClearable
      disablePortal={disablePortal}
      groupBy={groupBy}
      filterOptions={handleFilterOptions}
      options={options}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          key={option[keyPropName]}
          divider
          sx={option.inputValue ? { fontWeight: "bold",backgroundColor:' rgb(0, 110, 200, 0.2)' } : {}}
        >
          {renderOption && !option.inputValue
            ? renderOption(option)
            : getOptionLabel(option)}
        </ListItem>
      )}
      getOptionLabel={getOptionLabel}
      {...(minWidth ? { sx: { minWidth: minWidth } } : {})}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          placeholder={placeholder}
          autoComplete={autoComplete ?? "off"}
          InputProps={{
            ...params.InputProps,
            spellCheck: false,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
