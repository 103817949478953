import { useState, useEffect, Fragment } from 'react';
import {
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Translate from '../utils/Translate';

const Comp = ({ content, horizontal = false }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [components, setComponent] = useState(false);
  const [header, setHeader] = useState(false);
  const [customClass, setCustomClass] = useState('stdDialog');

  const handleMenuItemAction = (route, component, header, onClick,addCustomClass) => {
    if (onClick) {
      onClick();
    } else if (route) {
      navigate(route, { replace: false });
    } else {
      if (addCustomClass){
        setCustomClass(addCustomClass);
      }
      setHeader(header);
      setComponent(component);
      setOpenDialog(true);
      window.history.pushState(false, '', '#dialog');
    }
  };

  useEffect(() => {
    const onHashChange = () =>
      setOpenDialog(window.location.hash === '#dialog');
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  const closeDialog = (event, reason) => {
    if (reason ==='backdropClick'){
      return false;
    }
    window.history.back();
    setOpenDialog(false);
  };

  const DialogHeader = ({ header }) => {
    return (
      <>
        <IconButton
          className="modalCloseButton"
          aria-label={Translate.get('Close')}
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{header}</DialogTitle>
      </>
    );
  };
  return (
    <>
      <Dialog
        maxWidth={false}
        className={customClass}
        scroll="paper"
        onClose={closeDialog}
        open={openDialog}
      >
        {header && <DialogHeader header={header} />}
        {components}
      </Dialog>
      {content.filter((item) => item.hide !== true).map((item, index) => (
        <Fragment key={index}> 
          <Tooltip        
            onClick={() =>
              handleMenuItemAction(
                item.route,
                item.component,
                item.dialogHeader,
                item.onClick,
                item.customClass,
              )
            }
            title={item.disabled ? (item.disabledTooltip ?? "") : item.text}
          >
            <span>{/* Need to wrap with span, to enable tooltip on disabled items */}
              <IconButton disabled={item.disabled}>{item.icon}</IconButton>
            </span>
          </Tooltip>
       </Fragment> 
      ))}
    </>
  );
};
export default Comp;
