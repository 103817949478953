import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../../DotMenu";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from "../../../utils/dateTimeFormat";
import Translate from "../../../utils/Translate";
import ActorAvatar from "../../ActorAvatar";
import { toSameDateTimeUTC } from "../../../utils/dateTimeFormat";
import PersonCrud from "../../actors/crud/PersonCrud";

export default function CourseGradesDialogBig({
  open,
  onClose,
  bookings,
  skills,
  handleCheckSkillChange,
  handleCheckSkillAllChange,
  skillsByActorId,
  canApproveAll,
  handleApproveAllSkills,
  handleApproveAllSkillsForStudent,
  handleOpenSkillsDialog,
  showCourseSkillsDialog,
  isAnyStudentUnlicensed,
  handleUpdateLicense,
  handleCreateLicenseClick,
  handleDeleteLicenseClick,
  handleCreateLicenseAllClick,
  licensesByActorId,
  handleOrderLicensesClick,
  handleUpdateAllLicense,
  allLicensesSameDate,
  isAnyLicenseLocked,
}) {
  const getDotMenuActions = (props) => {
    const passedCount = Object.values(skillsByActorId[props.actorId]).filter(
      (hasPassed) => hasPassed
    ).length;

    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        component: (
          <PersonCrud
            actorId={props.actorId}
            isEditOnly
            isMatchingActorsDisabled
          />
        ),
      },
      {
        icon: <DoneAllIcon />,
        text: Translate.get("ApproveAllSkills"),
        disabled:
          !!licensesByActorId[props.actorId].date ||
          passedCount === skills.length,
        onClick: () => handleApproveAllSkillsForStudent(props.actorId),
      },
    ];
    return menudata;
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="xl"
      sx={
        /* Doing this so that the spinner in CourseSkillsDialog is over this dialog*/
        showCourseSkillsDialog ? { zIndex: 0 } : {}
      }
    >
      <Box
        sx={{
          position: "absolute",
          right: 8,
          top: 14,
        }}
      >
        <Button onClick={handleOpenSkillsDialog}>
          {Translate.get("EditCourseSkills")}
        </Button>
        <Button onClick={handleApproveAllSkills} disabled={!canApproveAll}>
          {Translate.get("ApproveAll")}
        </Button>
      </Box>
      <DialogTitle>{Translate.get("GradeCourse")}</DialogTitle>
      <DialogContent sx={{ padding: 0 }} dividers>
        <TableContainer sx={{ overflowX: "initial" }}>
          <Table className="gradesTable" aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow className="gradesTableHeader">
                <TableCell>{/* Empty cell on purpose */}</TableCell>
                <TableCell align="left">
                  <FormControl>
                    <FormGroup
                      sx={{ flexWrap: "nowrap" }}
                      aria-label="position"
                      row
                    >
                      {skills.map((skill) => (
                        <FormControlLabel
                          value="top"
                          className="gradesTableSkillCheckbox"
                          control={
                            <Checkbox
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                              checked={!!skill.hasAllUnlicensedStudentsPassed}
                              indeterminate={
                                !skill.hasAllUnlicensedStudentsPassed &&
                                !!skill.hasSomeUnlicensedStudentsPassed
                              }
                              onChange={(event) =>
                                handleCheckSkillAllChange(event, skill.skillId)
                              }
                              disabled={!isAnyStudentUnlicensed}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {skill.shortCode}
                            </Typography>
                          }
                          labelPlacement="top"
                          key={skill.skillId}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  {!!allLicensesSameDate ? (
                    <DatePicker
                      disabled={isAnyLicenseLocked}
                      mask={getCurrentDateMask()}
                      inputFormat={getCurrentInputFormat()}
                      InputProps={{ sx: { width: "158px" } }}
                      value={allLicensesSameDate}
                      onChange={(newDate, realVal) => {
                        ////console.log('realVal',realVal)
                        if (
                          (realVal && realVal.length === 10) ||
                          (!realVal && newDate)
                        ) {
                          handleUpdateAllLicense(toSameDateTimeUTC(newDate));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: Translate.get("DatePlaceholder"),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Button
                      onClick={handleCreateLicenseAllClick}
                      variant="contained"
                      disabled={!isAnyStudentUnlicensed}
                    >
                      {Translate.get("GenerateLicenses")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {bookings &&
              bookings.length > 0 &&
              bookings.map((booking) => (
                <TableBody key={booking.courseBookingId}>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      sx={{ padding: "14px" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                          margin: "1em 10px 2px",
                          textTransform: "uppercase",
                        }}
                      >{`${booking.companyName} (${booking.courseParticipants.length})`}</Typography>
                    </TableCell>
                  </TableRow>
                  {booking.courseParticipants.map((student) => (
                    <TableRow key={student.actorId} className="gradesTableRow">
                      <TableCell
                        component="th"
                        scope="row"
                        className="userNameEmail"
                        align="left"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          gap="10px"
                        >
                          <ActorAvatar
                            name={student.name}
                            email={student.email}
                            profilePictureUrl={student.profilePictureUrl}
                          />
                          <DotMenu
                            content={getDotMenuActions({
                              actorId: student.actorId,
                            })}
                            horizontal
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <FormControl>
                          <FormGroup
                            sx={{ flexWrap: "nowrap" }}
                            aria-label="position"
                            row
                          >
                            {skills.map((skill) => (
                              <Checkbox
                                key={skill.skillId}
                                className="gradesTableSkillCheckbox"
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                checked={
                                  !!skillsByActorId[student.actorId] &&
                                  !!skillsByActorId[student.actorId][
                                    skill.skillId
                                  ]
                                }
                                onChange={(event) =>
                                  handleCheckSkillChange(
                                    event,
                                    student.actorId,
                                    skill.skillId
                                  )
                                }
                                disabled={
                                  !!licensesByActorId[student.actorId].date
                                }
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell align="left">
                        {!!licensesByActorId[student.actorId].date ||
                        licensesByActorId[student.actorId].isEditing ? (
                          <Box>
                            <DatePicker
                              disabled={
                                licensesByActorId[student.actorId].isLocked
                              }
                              mask={getCurrentDateMask()}
                              disableMaskedInput={false}
                              inputFormat={getCurrentInputFormat()}
                              InputProps={{ sx: { width: "158px" } }}
                              value={licensesByActorId[student.actorId].date}
                              onChange={(newDate, realVal) => {
                                if (
                                  (realVal && realVal.length === 10) ||
                                  (!realVal && newDate)
                                ) {
                                  handleUpdateLicense(
                                    student.actorId,
                                    toSameDateTimeUTC(newDate),
                                    //newDate,
                                    false,
                                    true
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder:
                                      Translate.get("DatePlaceholder"),
                                  }}
                                />
                              )}
                            />
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleDeleteLicenseClick(student.actorId)
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        ) : (
                          <Button
                            onClick={() =>
                              handleCreateLicenseClick(student.actorId)
                            }
                            disabled={
                              !skillsByActorId[student.actorId] ||
                              !Object.entries(
                                skillsByActorId[student.actorId]
                              ).some(([_, hasPassed]) => hasPassed)
                            }
                            variant="outlined"
                          >
                            {Translate.get("GenerateLicense")}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>{Translate.get("Close")}</Button>
        <Button
          variant="contained"
          onClick={handleOrderLicensesClick}
          disabled={!Object.values(licensesByActorId).some((v) => !!v.date)}
        >
          {Translate.get("OrderMultipleLicenses")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
