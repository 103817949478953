import { useState, useEffect, useRef } from 'react';
import Translate from '../../../utils/Translate';
import { useApi } from '../../../utils/Api';
import { useSnackbar } from 'notistack';
import Preloader from '../../Preloader';
import { Avatar, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DotMenu from '../../../components/DotMenu';
import AttachmentIcon from '@mui/icons-material/Attachment';
import YesOrNoDialog from '../../YesOrNoDialog';
import download from 'downloadjs';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ConnectCompetences from './ConnectCompetences';
import AttachFileIcon from '@mui/icons-material/AttachFile';
let fileExtension = '';
const acceptfileExtensions = '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.pages,.numbers,.key,.csv,.bmp,.gif,.heic,.jpg,.jpeg,.png,.tiff,.webp,.zip,.7z';
const DocUpload = (props) => {
  const [files, setFiles] = useState(props.files);
  const [fileName, setFileName] = useState('');
  //const [fileLink, setFileLink] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  const [showCompetence, setShowCompetence] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const fileInputRef = useRef("");

  if (!props.type) {
    return <>type missing</>;
  }

  let type = 'actors';
  if (props.type === 'course') {
    type = 'courses';
  }
  const showConnect = (props.actorSkills, props.actorSkills?.actorSkillsBySkillGroup ? Object.keys(props.actorSkills.actorSkillsBySkillGroup).length : false);
  //console.log('props.actorSkills',props.actorSkills,props.actorSkills?.actorSkillsBySkillGroup ? Object.keys(props.actorSkills.actorSkillsBySkillGroup).length : false)
  const downLoader = (documentId) => {
    //Denna borde kanske vara egen js,
    //gissar att det alltid är så här man vill göra med av användare uppladdade filer.
    //och kanse även andra filer som sparas i Azure blob storage
    //I sådana fall med full fetch path som parameter istället för documentId
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}${type}/file/get/${documentId}`,
      );
      ////console.log('download', data);
      (async () => {
        const res = await fetch(data.sasURI);
        const contentType = res.headers.get('Content-Type');
        const raw = await res.blob();
        ////console.log('meta', data.filename, contentType);
        download(raw, data.filename, contentType);
      })();
    })();
  };

  const deleteDoc = (documentId) => {
    (async () => {
      const res = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}${type}/file/delete/${documentId}`,
        {},
        'POST',
      );
      if (res.isSuccessful) {
        setFiles(files.filter((x) => x.blobDocumentId !== documentId));
        setShowDeleteQuestion(false);
      } else {
        //showErrorMessage(res.errorMessageTranslationKey);
      }
    })();

    ////console.log('deleteDoc', documentId);
  };
  const submitForm = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append(
      'file',
      e.target.file.files[0],
      e.target.name.value + fileExtension,
    );
    ////console.log('Real form data', formData, e.target.name.value, fileExtension);
    (async () => {
      const ret = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}${type}/${props.actorId}/file/upload`,
        formData,
      );
      if (ret.isSuccessful) {
        enqueueSnackbar(Translate.get('DocumentUploaded'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setFiles([
          ...files,
          {
            blobDocumentId: ret.blobDocumentId,
            originalFileName: e.target.name.value + fileExtension,
          },
        ]);
        setFileName("");
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        //Kommer direkt från API
        //showErrorMessage(ret.errorMessageTranslationKey);
      }
      ////console.log('return data', ret);
      setSubmitting(false);
    })();
  };
  const showErrorMessage = (errorMessageTranslationKey) => {
    const errorMessage = enqueueSnackbar(
      Translate.get(errorMessageTranslationKey),
      {
        variant: 'error',
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      },
    );
  };
  const getFile = (e) => {
    const maxFileSize = 1024 * 1024 * 5;
    const file = e.target.files[0];
    if (file.size > maxFileSize) {
      showErrorMessage('FileIsTooLarge');
      setFileName('');
    } else {
      ////console.log(file.name);
      const lastDot = file.name.lastIndexOf('.');

      const fileNameNoExt = file.name.substring(0, lastDot);
      fileExtension = file.name.substring(lastDot);
      ////console.log('fileExtension', fileExtension);
      const chkValidExt = acceptfileExtensions.split(',');
      if (chkValidExt.includes(fileExtension)) {
        setFileName(fileNameNoExt);
      } else {
        showErrorMessage('FileTypeNotSupported');
      }
    }
  };

  const UploadList = (props) => {
    ////console.log(props.files);
    return (
      <>
        {props.files.map((cp, index) => (
          <div key={index} className="stdList">
            <div className="tableAvatar">
              <Avatar>
                <AttachmentIcon />
              </Avatar>
              <div>
                <div className="tableAvatarTitle bold">
                  {cp.originalFileName}
                </div>
              </div>
            </div>

            <DotMenu
              content={[
                {
                  icon: <FileDownloadIcon />,
                  text: Translate.get('Download'),
                  onClick: () => downLoader(cp.blobDocumentId),
                },
                {
                  icon: <DeleteIcon />,
                  text: Translate.get('Delete'),
                  onClick: () =>
                    setShowDeleteQuestion({
                      id: cp.blobDocumentId,
                      name: cp.originalFileName,
                    }),
                },
                {
                  icon: <AttachFileIcon />,
                  text: Translate.get('ConnectCompToDoc'),
                  onClick: () =>
                    setShowCompetence({ 'blobDocumentId': cp.blobDocumentId, 'originalFileName': cp.originalFileName }),
                  hide: !showConnect
                },
              ]}
            />
          </div>
        ))}
      </>
    );
  };
  //Toddo lista alla uppladdade dokument och delete funktion + förbered för fler kopplingar
  return (
    <>
      <YesOrNoDialog
        open={showDeleteQuestion !== false}
        title={Translate.get('DeleteFile')}
        text={Translate.get('Delete') + ' ' + showDeleteQuestion.name + '?'}
        onNo={() => setShowDeleteQuestion(false)}
        onYes={() => deleteDoc(showDeleteQuestion.id)}
        noText={Translate.get('Cancel')}
        yesText={Translate.get('Delete')}
      />
      {showCompetence && (
        <>
          <DialogTitle>
            <div className="stdFlexBetween">
              <div>{Translate.get('ConnectCompToDoc')}</div>
              {/* <Tooltip title="">
             <IconButton>
               <InfoIcon color="primary" />
             </IconButton>
           </Tooltip> */}
            </div>
          </DialogTitle>
          <DialogContent dividers={true} className="stdDialogContent">
            <div className="topBottom16">
              {Translate.get('ConnectCompToDocDesc')}: <i>{showCompetence.originalFileName}</i>
            </div>
            <ConnectCompetences rows={props.actorSkills} docId={showCompetence.blobDocumentId} fileName={showCompetence.originalFileName} />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setShowCompetence(false)}>
              {Translate.get('Close')}
            </Button>
          </DialogActions>
        </>
      )}
      {(showCompetence === false) && (
        <>
          <DialogTitle>
            <div className="stdFlexBetween">
              <div>{Translate.get('Document')}</div>
              <Tooltip title={Translate.get('ePort2AllowedFileTypesInfo') + ' ' + acceptfileExtensions}>
                <IconButton>
                  <InfoIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </DialogTitle>
          <form onSubmit={submitForm}>
            <DialogContent dividers={true} className="stdDialogContent">
              <div className="topBottom16">
                {Translate.get('ePort2MaxFileSize')}
              </div>
              <UploadList files={files} />
              <div className="stdFlexBetween">
                <Button variant="contained" component="label">
                  {Translate.get('SelectFile')}
                  <input
                    accept={acceptfileExtensions}
                    onChange={getFile}
                    type="file"
                    hidden
                    name="file"
                    ref={fileInputRef}
                  />
                </Button>
                <TextField
                  style={{ flexGrow: 1 }}
                  type="text"
                  required
                  name="name"
                  label={Translate.get('FileName')}
                  value={fileName}
                  onChange={(event) => {
                    setFileName(event.target.value);
                  }}
                  disabled={fileInputRef.current.value === ""}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeAndReload()}>
                {Translate.get('Close')}
              </Button>
              <LoadingButton
                className="stdLightLoader"
                loading={submitting}
                type="submit"
                disabled={fileName === ""}
              >
                {Translate.get('Upload')}
              </LoadingButton>
            </DialogActions>
          </form>
        </>)}
    </>
  );
};
export default DocUpload;
