import { Box, Grid, Stack } from "@mui/material";
import Translate from "../../utils/Translate";
import { getOrderIconbox } from "../../utils/order";
import { formatMoney } from "../../utils/formatMoney";

export default function OrdersGridDetailsPanel({ orderLines, currency }) {
  return (
    <Grid container direction="column" xs={12}>
      {orderLines.map((ol) => (
        <Grid
          item
          container
          key={ol.orderLineId}
          direction="row"
          justifyContent="center"
          alignItems="center"
          paddingTop="4px"
          paddingBottom="4px"
          xs={12}
        >
          <Grid item xs={2}>
            {ol.part.partNo}
          </Grid>
          <Grid
            item
            xs={4}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {ol.part.description.trim()}
          </Grid>
          <Grid item xs={2}>
            {ol.qty}
          </Grid>
          <Grid item xs={1.8}>
            {formatMoney(ol.price, null, currency)}
          </Grid>
          <Grid item xs={0.4} textAlign="right">
            <Stack direction="row" alignItems="center">
              {getOrderIconbox(ol.status, "gray", true)}
            </Stack>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
