import { CustomNoRowsOverlay } from "../../../utils/StyledGridOverlay";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import { Button, DialogContent, DialogActions, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import DotMenu from "../../DotMenu";
import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { IconAndLabelColumnHeader } from "../../../utils/dataGrid";
import { dataGridLang } from "../../MemToolBar";
import { AdminPanelSettings, ScatterPlot, Delete } from "@mui/icons-material";
import EPortProfileDialog from "./EPortProfileDialog";
import YesOrNoDialog from "../../YesOrNoDialog";

export default function EPortProfilesDialog({ externalDataId }) {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [profileToEdit, setProfileToEdit] = useState(null);
  const [profileToDelete, setProfileToDelete] = useState(null);

  const localizedTextsMap = dataGridLang();

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const loadProfiles = useCallback(
    async (externalDataId) => {
      const responseProfiles = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles?externalDataId=${externalDataId}`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (responseProfiles && Array.isArray(responseProfiles)) {
        setProfiles(responseProfiles);
      }
    },
    [api]
  );

  const addOrUpdateProfile = useCallback(
    async (profile, externalDataId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles?externalDataId=${externalDataId}`,
        profile,
        "POST"
      );
      return response && response.isSuccessful;
    },
    [api]
  );

  const deleteProfile = useCallback(
    async (profile, externalDataId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles?ePortProfileId=${profile.ePortProfileId}&externalDataId=${externalDataId}`,
        true,
        "DELETE"
      );
      return response && response.isSuccessful;
    },
    [api]
  );

  useEffect(() => {
    setIsLoading(true);
    loadProfiles(externalDataId).then(() => {
      setIsLoading(false);
    });
  }, [externalDataId, loadProfiles]);

  async function handleSaveProfile(profile) {
    await addOrUpdateProfile(profile, externalDataId);
    await loadProfiles(externalDataId);
  }

  const getProfileDotMenuActions = useCallback((profile) => {
    return [
      {
        icon: <EditIcon />,
        text: Translate.get("EditProfile"),
        dialogTitle: Translate.get("EditProfile"),
        disabled: profile.isDefaultProfile,
        onClick: () => setProfileToEdit(profile),
      },
      {
        icon: <Delete />,
        text: Translate.get("DeleteProfile"),
        disabled: profile.numberOfUsers > 0,
        onClick: () => setProfileToDelete(profile),
      },
    ];
  }, []);

  const profileColumns = useMemo(
    () => [
      {
        field: "ePortCloudLicenseLineType",
        flex: 2,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Type"),
        valueGetter: (params) =>
          Translate.getEPortLicenseLineType(params.value),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={GroupIcon}
            label={Translate.get("EPortProfile")}
          />
        ),
      },
      {
        field: "roleShortName",
        flex: 0.8,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Role"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={AdminPanelSettings}
            label={Translate.get("Role")}
          />
        ),
        renderCell: (params) => params.value ?? "STANDARD",
      },
      {
        field: "numberOfUsers",
        flex: 1.1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Users"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ScatterPlot}
            label={Translate.get("Users")}
          />
        ),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu content={getProfileDotMenuActions(params.row)} horizontal />
        ),
      },
    ],
    [getProfileDotMenuActions]
  );

  return (
    <>
      {profileToEdit && (
        <EPortProfileDialog
          profile={profileToEdit}
          onClose={() => setProfileToEdit(null)}
          onSave={(profile) => handleSaveProfile(profile)}
          externalDataId={externalDataId}
        />
      )}
      <YesOrNoDialog
        open={!!profileToDelete}
        text={Translate.get("SureDeleteEPortProfile")}
        onYes={async () => {
          await deleteProfile(profileToDelete, externalDataId);
          setProfileToDelete(null);
          await loadProfiles(externalDataId);
        }}
        onNo={() => setProfileToDelete(null)}
      />
      {isLoading && (
        <>
          <Preloader />
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {!isLoading && (
        <>
          <DialogContent>
            <Stack spacing={2.5}>
              <DataGrid
                className="dgSmall textbox"
                rows={profiles}
                columns={profileColumns}
                disableSelectionOnClick
                getRowId={(row) => row.ePortProfileId}
                autoHeight={true}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay(
                    Translate.get("EmptyHere")
                  ),
                }}
                componentsProps={{
                  columnsPanel: { className: "customColumnPanel" },
                  filterPanel: { className: "customfilterPanel" },
                  panel: { className: "customPanel" },
                }}
                localeText={localizedTextsMap}
                disableDensitySelector={true}
                disableColumnMenu
              />
              <Stack direction="row-reverse">
                <Button
                  onClick={() => setProfileToEdit({})}
                >{`+ ${Translate.get("AddProfile")}`}</Button>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <ButtonWithSpinner variant="contained" onClick={closeAndReload}>
              {Translate.get("Close")}
            </ButtonWithSpinner>
          </DialogActions>
        </>
      )}
    </>
  );
}
