import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { IconAndLabelColumnHeader } from "../../../utils/dataGrid";
import BuildIcon from "@mui/icons-material/Build";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
import DotMenu from "../../DotMenu";
import { CustomNoRowsOverlay } from "../../../utils/StyledGridOverlay";
import { dataGridLang } from "../../MemToolBar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BadgeIcon from "@mui/icons-material/Badge";
import SpeedMenu from "../../SpeedMenu";
import { hasAccess, accessKeys } from "../../../utils/userAccess";
import YesOrNoDialog from "../../YesOrNoDialog";
import { useCallback } from "react";
import AddOrEditSkillGroupDialog from "./AddOrEditSkillGroupDialog";
import { useMemo } from "react";
import SkillGroupSkillsDialog from "./SkillGroupSkillsDialog";

const getSpeedMenu = (props) => {
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("AddCourseFamily"),
      route: false,
      // dialogHeader: Translate.get("CreateCourseTemplate"),
      // customClass: "bigCourseDialog",
      component: <AddOrEditSkillGroupDialog />,
    },
  ];
  return menudata;
};

export default function SkillGroupSettings(props) {
  const localizedTextsMap = dataGridLang();
  const [isLoading, setIsLoading] = useState(true);
  const [skillGroups, setSkillGroups] = useState([]);
  const [deleteSkillGroupId, setDeleteSkillGroupId] = useState(null);
  const api = useApi();

  const loadSkillGroups = useCallback(async () => {
    const skillGroups = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/names`,
      false,
      "GET"
    );
    setSkillGroups(skillGroups);
    setIsLoading(false);
  }, [api]);

  useEffect(() => {
    loadSkillGroups();
  }, [loadSkillGroups]);

  const getDotMenuActions = useCallback((skillGroupId) => {
    const menudata = [
      {
        icon: <EditIcon />,
        dialogHeader: Translate.get("ChangeSkillGroupName"),
        text: Translate.get("ChangeSkillGroupName"),
        component: <AddOrEditSkillGroupDialog skillGroupId={skillGroupId} />,
        customClass: "mediumCourseDialog",
      },
      {
        icon: <BuildIcon />,
        text: Translate.get("EditSkills"),
        // onClick: () => setEditSkillGroupId(skillGroupId),
        component: <SkillGroupSkillsDialog skillGroupId={skillGroupId} />,
      },
      {
        icon: <DeleteIcon />,
        text: Translate.get("RemoveCourseFamily"),
        onClick: () => setDeleteSkillGroupId(skillGroupId),
      },
    ];
    return menudata;
  }, []);

  async function handleDeleteSkillGroup(skillGroupId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/${skillGroupId}`,
      true,
      "DELETE"
    );
    if (response.isSuccessful) {
      setDeleteSkillGroupId(null);
      setIsLoading(true);
      await loadSkillGroups();
    }
  }

  const columns = useMemo(
    () => [
      {
        field: "description",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("CompetenceGroup"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={BadgeIcon}
            label={Translate.get("CompetenceGroup")}
          />
        ),
        sortComparator: (v1, v2) => v1.localeCompare(v2, "sv"),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu
            content={getDotMenuActions(params.row.skillGroupId)}
            horizontal
          />
        ),
      },
    ],
    [getDotMenuActions]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          <YesOrNoDialog
            open={!!deleteSkillGroupId}
            title={Translate.get("RemoveCourseFamily")}
            text={Translate.get("SureRemoveSkillGroup")}
            onYes={async () => await handleDeleteSkillGroup(deleteSkillGroupId)}
            onNo={() => setDeleteSkillGroupId(null)}
          />
          {hasAccess(accessKeys.advancedPackage) && (
            <SpeedMenu content={getSpeedMenu()} />
          )}

          <Paper>
            <DataGrid
              disableRowSelectionOnClick={true}
              pagination={true}
              pageSizeOptions={[10, 20, 30, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              autoHeight={true}
              localeText={localizedTextsMap}
              initialState={{
                sorting: {
                  sortModel: [{ field: "description", sort: "asc" }],
                },
              }}
              density="comfortable"
              columns={columns}
              rows={skillGroups}
              getRowId={(row) => row.skillGroupId}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
              }}
            />
          </Paper>
        </>
      )}
    </>
  );
}
