import { Grid, Stack } from "@mui/material";
import TooltipField from "./TooltipField";

export default function TooltipFieldsList({ items, width }) {
  return (
    <Stack spacing={2} width={width ?? "150px"}>
      {items &&
        items.map((item, itemIndex) => (
          <Grid key={itemIndex} container>
            {item.fields &&
              item.fields.map((field, fieldIndex) => (
                <TooltipField key={fieldIndex} {...field} />
              ))}
          </Grid>
        ))}
    </Stack>
  );
}
