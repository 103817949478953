import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import Translate from "../../utils/Translate";
import { ScatterPlot } from "@mui/icons-material";

function StUnitField({ label, value }) {
  return (
    <Grid container xs={12}>
      <Grid item xs={6} className="stUnitListItemFieldLabel">
        {label}:
      </Grid>
      <Grid item xs={6}>
        {value}
      </Grid>
    </Grid>
  );
}

export default function StUnitListItem({
  isUnlimitedQty,
  qty,
  revision,
  locationArea,
  bestBeforeDate,
  edition,
  selected,
  onSelect,
  disabled,
}) {
  return (
    <ListItem
      disablePadding
      className={
        "stUnitListItem" +
        (selected ? " selected" : "") +
        (disabled ? " disabled" : "")
      }
    >
      <ListItemButton disabled={disabled} onClick={onSelect}>
        <ListItemText>
          <Grid container item xs={12} direction="column">
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <StUnitField
                  label={Translate.get("Quantity")}
                  value={isUnlimitedQty ? "∞" : qty}
                />
              </Grid>
              <Grid item xs={6}>
                <StUnitField
                  label={Translate.get("StUnitAreaLabel")}
                  value={locationArea ?? "-"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <StUnitField
                  label={Translate.get("StUnitRevisionLabel")}
                  value={revision ?? "-"}
                />
              </Grid>
              <Grid item xs={6}>
                <StUnitField
                  label={Translate.get("StUnitEditionLabel")}
                  value={edition ?? "-"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <StUnitField
                  label={Translate.get("StUnitBestBeforeDate")}
                  value={bestBeforeDate ? formatDate(bestBeforeDate) : "-"}
                />
              </Grid>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItemButton>
    </ListItem>
    // <div onClick={!disabled ? onSelect : null}>
    //   <Grid
    //     container
    //     item
    //     xs={12}
    //     className={
    //       "stUnitPickItem" +
    //       (selected ? " selected" : "") +
    //       (disabled ? " disabled" : "")
    //     }
    //     direction="column"
    //   >
    //     <Grid item container xs={12}>
    //       <Grid item xs={6}>
    //         <StUnitField
    //           label={Translate.get("Quantity")}
    //           value={isUnlimitedQty ? "∞" : qty}
    //         />
    //       </Grid>
    //       <Grid item xs={6}>
    //         <StUnitField
    //           label={Translate.get("StUnitAreaLabel")}
    //           value={locationArea ?? "-"}
    //         />
    //       </Grid>
    //     </Grid>
    //     <Grid item container xs={12}>
    //       <Grid item xs={6}>
    //         <StUnitField
    //           label={Translate.get("StUnitRevisionLabel")}
    //           value={revision ?? "-"}
    //         />
    //       </Grid>
    //       <Grid item xs={6}>
    //         <StUnitField
    //           label={Translate.get("StUnitEditionLabel")}
    //           value={edition ?? "-"}
    //         />
    //       </Grid>
    //     </Grid>
    //     <Grid item container xs={12}>
    //       <Grid item xs={6}>
    //         <StUnitField
    //           label={Translate.get("StUnitBestBeforeDate")}
    //           value={bestBeforeDate ? formatDate(bestBeforeDate) : "-"}
    //         />
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </div>
  );
}
