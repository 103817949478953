import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Translate from "../../utils/Translate";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { formatInsertArguments } from "../../utils/formatString";
import { formatDate } from "../../utils/dateTimeFormat";
import Personnummer from "personnummer";

export function Authenticated(student) {
    if (student.authentication != null) {
      return BankIdAuthenticated(student);
    }

    if (student.manualAuthentications != null) {
      return ManualAuthenticated(student);
    }

    return "";
  }

  function BankIdAuthenticated(student) {
    if (student.authentication == null) {
      return "";
    }

    let ssnsIsSame = IsActorSsnSameAsBankId(student);
    if (!ssnsIsSame) {
      return (
        <Tooltip
          title={formatInsertArguments(
            Translate.get("BankIdSsnDoesNotMatch"),
            capitalizeWords(student.authentication.givenName),
            capitalizeWords(student.authentication.surName),
            student.authentication.personalNumber,
            formatDate(student.authentication.regDate)
          )}
          className="iconbox"
        >
          <HowToRegIcon sx={{ color: "#F4846F" }} />
        </Tooltip>
      );
    }

    let namesIsSame = IsActorNameSameAsBankId(student);
    if (!namesIsSame) {
      return (
        <Tooltip
          className="iconbox"
          title={formatInsertArguments(
            Translate.get("BankIdNameDiff"),
            capitalizeWords(student.authentication.givenName),
            capitalizeWords(student.authentication.surName),
            student.authentication.personalNumber,
            formatDate(student.authentication.regDate)
          )}
        >
          <HowToRegIcon sx={{ color: "orange" }} />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={
          Translate.get("BankIDAuthenticated") +
          " " +
          formatDate(student.authentication.regDate)
        }
        className="iconbox"
      >
        <HowToRegIcon />
      </Tooltip>
    );
  }

  function IsActorNameSameAsBankId(student) {
    let studentName = student.actorName;
    let bankIdName =
      capitalizeWords(student.authentication.givenName) +
      " " +
      capitalizeWords(student.authentication.surName);

    return studentName === bankIdName;
  }

  function capitalizeWords(string) {
    if (!string) return "";

    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function IsActorSsnSameAsBankId(student) {
    let studentSsn = student.orgNo;

    if (!Personnummer.valid(studentSsn)) {
      return false;
    }

    let parsedStudentSsn = Personnummer.parse(studentSsn);
    let bankIdSsn = student.authentication.personalNumber;

    return parsedStudentSsn.format(true) === bankIdSsn;
  }

  function ManualAuthenticated(student) {
    if (student.manualAuthentications == null || student.manualAuthentications.length < 1) {
      return "";
    }

    student.manualAuthentications.sort(
      (a, b) => new Date(b.regDate) - new Date(a.regDate)
    );
    
    let latestEntry = student.manualAuthentications[0];

    return (
      <Tooltip
        title={
          Translate.get("ManuallyAuthenticated") +
          " " +
          formatDate(latestEntry.regDate)
        }
        className="iconbox"
      >
        <HowToRegIcon />
      </Tooltip>
    );
  }