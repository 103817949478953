//import ReactApexChart from 'react-apexcharts';
// material
import { styled } from '@mui/material/styles';
import { Button, Card } from '@mui/material';
// utils
//
import Translate from '../../../utils/Translate';
import NewsCard from './NewsCard';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 'auto';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
}));

// ----------------------------------------------------------------------

const Comp = ({ data }) => {
  return (
    <Card>
      <ChartWrapperStyle sx={{marginTop:'0',textAlign: 'center'}}>
        {data?.map((row, index) => {
          return <NewsCard key={index} image={row.image} date={row.date} headline={row.headline} text={row.text} url={row.url} />;
        })}
        <Button href="https://www.masystemutbildning.se/sv-se/instructor/instruktorsnyheter" target="_blank" sx={{marginTop:'24px',marginBottom:'24px'}} variant='outlined'>{Translate.get('News')}</Button>
      </ChartWrapperStyle>
    </Card>
  );
};
export default Comp;
