import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import EyeFillIcon from "@iconify/icons-eva/eye-fill";
import EyeOffFillIcon from "@iconify/icons-eva/eye-off-fill";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function UserPasswordCard(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    currentPassword: Yup.string().required(Translate.get("PasswordRequired")),
    password: Yup.string()
      .min(8, Translate.get("Min8"))
      .max(30, Translate.get("Max30"))
      .required(Translate.get("PasswordRequired")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password")], Translate.get("PasswordsMustMatch"))
      .required(Translate.get("PasswordsMustMatch")),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const req = {
        currentPassword: formik.values.currentPassword,
        newPassword: formik.values.password,
      };

      api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/password/change/`,
        req,
        "POST",
        false,
        false
      ).then((data) => {
        //console.log("DONE CHANGING:", data)
        if (data === "true") {
          const successMessage = enqueueSnackbar(
            Translate.get("PasswordChanged"),
            {
              variant: "success",
              autoHideDuration: 6000,
              onClick: () => closeSnackbar(successMessage),
            }
          );
          formik.resetForm();
        } else {
          const errorMessage = enqueueSnackbar(
            Translate.get("UnableToChangePassword"),
            {
              variant: "error",
              autoHideDuration: 6000,
              onClick: () => closeSnackbar(errorMessage),
            }
          );
        }
        formik.setSubmitting(false);
      });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Card
      sx={{
        minWidth: { lg: "300px", xl: "350px" },
        maxWidth: { md: "438px" },
        paddingBottom: "10px",
      }}
    >
      <CardContent>
        <Grid container spacing={3.5} direction="column">
          <Grid item>
            <Typography variant="h4">
              {Translate.get("ChangePassword")}
            </Typography>
          </Grid>
          <Grid item>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {Translate.get("NewPasswordRequirements")}
                  </Typography>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showCurrentPassword ? "text" : "password"}
                    label={Translate.get("CurrentPassword")}
                    {...getFieldProps("currentPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowCurrentPassword((prev) => !prev)
                            }
                          >
                            <Icon
                              icon={
                                showCurrentPassword
                                  ? EyeFillIcon
                                  : EyeOffFillIcon
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.currentPassword && errors.currentPassword
                    )}
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                  />
                  <TextField
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    label={Translate.get("Password")}
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <Icon
                              icon={showPassword ? EyeFillIcon : EyeOffFillIcon}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    autoComplete="off"
                    type={showPasswordConfirmation ? "text" : "password"}
                    label={Translate.get("ConfirmPassword")}
                    {...getFieldProps("passwordConfirmation")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowPasswordConfirmation((prev) => !prev)
                            }
                          >
                            <Icon
                              icon={
                                showPasswordConfirmation
                                  ? EyeFillIcon
                                  : EyeOffFillIcon
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.passwordConfirmation &&
                        errors.passwordConfirmation
                    )}
                    helperText={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                    }
                  />
                  <Grid container justifyContent="flex-end">
                    <LoadingButton
                      type="submit"
                      variant="outlined"
                      loading={isSubmitting}
                    >
                      {Translate.get("ChangePassword")}
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Form>
            </FormikProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
