import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import { useSnackbar } from "notistack";
import { clearOrgNoFormatSessionCache } from '../../utils/personnummer';

export default function OrgNoFormat(props) {
    const [availableFormats, setAvailableFormats] = useState([]);
    const [currentFormat, setCurrentFormat] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const api = useApi();

    useEffect(async () => {
        let response = await fetchOrgNoFormat();
        setAvailableFormats(response.orgNoFormats);
        setCurrentFormat(response.currentOrgNoFormat ?? "Undefined");
    }, []);

    async function fetchOrgNoFormat() {
        props.setIsLoading(true);
        let response = null;
        try {
            response = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}dataIdSettings/getOrgNoFormats`,
                false,
                'GET',
            );
            props.setIsLoading(false);
        } catch (error) {
            const errorMessage = enqueueSnackbar(
                Translate.get(response.errorMessageTranslationKey || "SomethingFailed"),
                {
                    variant: "error",
                    autoHideDuration: 6000,
                    onClick: () => closeSnackbar(errorMessage),
                }
            );
            props.setIsLoading(false);
        }
        return { orgNoFormats: response.orgNoFormats, currentOrgNoFormat: response.currentOrgNoFormat ?? "Undefined" };
    }

    function UpdateFormatSetting(event) {
        props.setIsLoading(true);
        let reqObj = {
            settingValue: event.target.value
        }

        api.fetchWithOverride(
            `${process.env.REACT_APP_MAIN_URL}dataIdSettings/setOrgNoFormat`,
            reqObj,
            'POST',
            (data) => !data.error,
            null,
            null,
            null,
            false,
            true,
        )
            .then((data) => {
                if (!data.error) {
                    setCurrentFormat(data.settingValue);
                    clearOrgNoFormatSessionCache();
                }
            })
            .finally((f) => {
                props.setIsLoading(false);
            });
    }

    return (
        <div>
            <aside className='MuiCallout-root MuiCallout-warning'> <b>{Translate.get("BeCareful")}</b> <br /> {Translate.get("PersonalNumberFormatWarning")}</aside>
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentFormat}
                    displayEmpty
                    onChange={UpdateFormatSetting}
                >
                    {availableFormats.map((format, index) => {
                        return (
                            <MenuItem key={index} value={format}>
                                {Translate.get(format)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}