import { Grid } from "@mui/material";
import CompanyDetailsCard from "./CompanyDetailsCard";
import CompanyLogotypeCard from "./CompanyLogotypeCard";

export default function CompanySettings(props) {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={3}
      wrap="nowrap"
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "flex-start", md: "center" }}
    >
      <Grid item>
        <CompanyLogotypeCard />
      </Grid>
      <Grid item>
        <CompanyDetailsCard />
      </Grid>
    </Grid>
  );
}
