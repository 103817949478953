import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ActorLicensePreview from "./ActorLicensePreview";
import "./ActorLicensePrint.css";
import { useApi } from "../../utils/Api";
import { useEffect } from "react";
import { useState } from "react";
import Preloader from "../Preloader";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonWithSpinner from "../ButtonWithSpinner";
import PrintTemplateTextBoxEditor from "./PrintTemplateTextBoxEditor";
import ImageIcon from "@mui/icons-material/Image";
import AddIcon from "@mui/icons-material/Add";
import AddTextBoxDialog from "./AddTextBoxDialog";

const printSideFront = "front";
const printSideBack = "back";

const defaultTextBox = {
  placementTop: 10.0,
  placementLeft: 10.0,
  fontFamily: "Segoe UI",
  fontStyle: "Normal",
  fontWeights: "Normal",
  fontSize: 10,
  textBoxWidth: 100,
  textAlignment: "Left",
  textColor: "#FF000000",
};

function getTitle(printType) {
  if (printType === "card") {
    return Translate.get("EditLicenseGroupCard");
  } else if (printType === "a4") {
    return Translate.get("EditLicenseGroupA4");
  } else if (printType === "diploma") {
    return Translate.get("EditLicenseGroupDiploma");
  }
  throw new Error("Invalid printType " + printType);
}

function getScale(printType) {
  if (printType === "card") {
    return 1.5;
  } else if (printType === "a4") {
    return 1.0;
  } else if (printType === "diploma") {
    return 1.0;
  }
  throw new Error("Invalid printType " + printType);
}

export default function PrintTemplateEditDialog({
  licenseGroupId,
  printType,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [printTemplate, setPrintTemplate] = useState(null);
  const [printTemplateDetails, setPrintTemplateDetails] = useState(null);
  const [availableTextBoxes, setAvailableTextBoxes] = useState(null);
  const [availableTextAlignments, setAvailableTextAlignments] = useState(null);
  const [availableTextColors, setAvailableTextColors] = useState(null);
  const [availableFontWeights, setAvailableFontWeights] = useState(null);
  const [availableFonts, setAvailableFonts] = useState(null);
  const [selectedTextBox, setSelectedTextBox] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedPrintSide, setSelectedPrintSide] = useState(printSideFront);
  const [showAddTextBoxDialog, setShowAddTextBoxDialog] = useState(false);
  const [textBoxesToDelete, setTextBoxesToDelete] = useState([]);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const hasPointer = useMediaQuery("(pointer: fine)");

  const closeAndReload = () => {
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}licenseprint/edit/${licenseGroupId}/${printType}/template`,
        false,
        "GET"
      )
      .then((response) => {
        setIsLoading(false);

        if (!response.isSuccessful) {
          closeAndReload();
        }

        setPrintTemplate(response.printTemplate);

        const translatedAvailableTextBoxes = response.availableTextBoxes.map(
          (tb) => ({
            ...tb,
            displayName: Translate.get(tb.displayNameTranslationKey),
          })
        );
        const availableTextBoxesByName = Object.fromEntries(
          translatedAvailableTextBoxes.map((tb) => [tb.name, tb])
        );
        setAvailableTextBoxes(availableTextBoxesByName);
        setAvailableTextAlignments(response.availableTextAlignments);
        setAvailableTextColors(response.availableTextColors);
        setAvailableFontWeights(response.availableFontWeights);
        setAvailableFonts(response.availableFonts);

        const textBoxValues = Object.fromEntries(
          response.printTemplate.textBoxes.map((tb) => [
            tb.name,
            availableTextBoxesByName[tb.name]?.displayName,
          ])
        );
        const details = { textBoxValues };
        setPrintTemplateDetails(details);
      });
  }, [api, printType, licenseGroupId]);

  async function postUpdatedPrintTemplate() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenseprint/edit/${licenseGroupId}/${printType}/template`,
      { printTemplate, textBoxesToDelete },
      "POST"
    );
    if (response.isSuccessful) {
      closeAndReload();
    }
  }

  const onTextBoxChanged = useCallback((updatedTextBox) => {
    setPrintTemplate((prevPrintTemplate) => {
      const index = prevPrintTemplate.textBoxes.findIndex(
        (tb) => tb.name === updatedTextBox.name
      );
      if (index !== -1) {
        return {
          ...prevPrintTemplate,
          textBoxes: prevPrintTemplate.textBoxes.with(index, updatedTextBox),
        };
      } else {
        return prevPrintTemplate;
      }
    });
    setHasChanged(true);
  }, []);

  function updateSelectedTextBoxValue(propName, newValue) {
    if (selectedTextBox) {
      const updatedSelectedTextBox = {
        ...selectedTextBox,
        [propName]: newValue,
      };
      setSelectedTextBox(updatedSelectedTextBox);
      onTextBoxChanged(updatedSelectedTextBox);
    }
  }

  const onTextBoxSelected = useCallback((textBox) => {
    setSelectedTextBox({ ...textBox });
  }, []);

  function loadImage(file) {
    var reader = new FileReader();
    reader.onload = function (ev) {
      if (!ev?.target?.result) {
        return;
      }

      const propName =
        selectedPrintSide === printSideFront ? "imageFrontUrl" : "imageBackUrl";
      setPrintTemplate((prevPrintTemplate) => ({
        ...prevPrintTemplate,
        [propName]: ev.target.result,
      }));
    };

    reader.readAsDataURL(file);
    // reader.readAsArrayBuffer(file);
  }

  function onSelectedBackgroundImageChange(event) {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    loadImage(file);
    setHasChanged(true);
  }

  function handleAddNewTextBox(textBoxName) {
    const textBox = { ...defaultTextBox, name: textBoxName };
    setPrintTemplate((prevPrintTemplate) => ({
      ...prevPrintTemplate,
      textBoxes: [...prevPrintTemplate.textBoxes, textBox],
    }));
    setHasChanged(true);

    var textBoxValues = {
      ...printTemplateDetails.textBoxValues,
      [textBoxName]: availableTextBoxes[textBoxName]?.displayName,
    };
    setPrintTemplateDetails({ ...printTemplateDetails, textBoxValues });
    setSelectedTextBox(textBox);

    const deletedIndex = textBoxesToDelete.findIndex(
      (tbName) => tbName === textBoxName
    );
    if (deletedIndex !== -1) {
      // Undo a previous delete
      setTextBoxesToDelete(textBoxesToDelete.toSpliced(deletedIndex, 1));
    }
  }

  function handleDeleteTextBox(textBox) {
    const index = printTemplate.textBoxes.findIndex(
      (tb) => tb.name === textBox.name
    );
    if (index !== -1) {
      const textBoxesCopy = printTemplate.textBoxes.toSpliced(index, 1);
      setPrintTemplate({ ...printTemplate, textBoxes: textBoxesCopy });
    }
    setTextBoxesToDelete([...textBoxesToDelete, textBox.name]);
  }
  // //console.log("TEXTBOXES", printTemplate?.textBoxes);
  // //console.log("DETAILS", printTemplateDetails);

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && showAddTextBoxDialog && (
        <AddTextBoxDialog
          alreadyAddedTextBoxes={printTemplate.textBoxes}
          availableTextBoxes={availableTextBoxes}
          onClose={() => setShowAddTextBoxDialog(false)}
          onTextBoxAdded={handleAddNewTextBox}
        />
      )}
      {!isLoading && (
        <Dialog maxWidth={false} scroll="paper" onClose={onClose} open={true}>
          <DialogTitle>{getTitle(printType)}</DialogTitle>
          <DialogContent>
            {!hasPointer && Translate.get("PleaseUseDesktopDevice")}
            {hasPointer && printTemplate && printTemplateDetails && (
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                gap="18px"
              >
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={selectedPrintSide}
                    exclusive
                    onChange={(_, value) => {
                      setSelectedPrintSide(value);
                      setSelectedTextBox(null);
                    }}
                  >
                    <ToggleButton value={printSideFront}>
                      {Translate.get("PrintSideFront")}
                    </ToggleButton>
                    <ToggleButton value={printSideBack}>
                      {Translate.get("PrintSideBack")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  gap="5px"
                >
                  <Grid item>
                    <ActorLicensePreview
                      printTemplate={printTemplate}
                      cardDetails={printTemplateDetails}
                      showFront={selectedPrintSide === printSideFront}
                      showBack={selectedPrintSide === printSideBack}
                      renderWidth={printTemplate.width * getScale(printType)}
                      renderHeight={printTemplate.height * getScale(printType)}
                      isPrint={false}
                      isSelected
                      isEditMode
                      editModeProps={{
                        onTextBoxChanged,
                        selectedTextBox,
                        onTextBoxSelected,
                      }}
                      printType={printType}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    gap="20px"
                  >
                    <Grid item>
                      <Button component="label" startIcon={<ImageIcon />}>
                        {Translate.get("SelecteBackgroundImage")}
                        <input
                          type="file"
                          hidden
                          accept="image/gif, image/jpeg, image/png"
                          onChange={onSelectedBackgroundImageChange}
                        />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={selectedPrintSide == printSideBack}
                        startIcon={<AddIcon />}
                        onClick={() => setShowAddTextBoxDialog(true)}
                      >
                        {Translate.get("AddTextField")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  {...(selectedTextBox && printType !== "card"
                    ? {
                        sx: {
                          position: "sticky",
                          bottom: "-20px",
                          backgroundColor: "#fff",
                        },
                      }
                    : {})}
                >
                  <PrintTemplateTextBoxEditor
                    textBox={selectedTextBox}
                    availableTextBoxes={availableTextBoxes}
                    onChange={updateSelectedTextBoxValue}
                    availableTextAlignments={availableTextAlignments}
                    availableTextColors={availableTextColors}
                    availableFontWeights={availableFontWeights}
                    availableFonts={availableFonts}
                    onDelete={handleDeleteTextBox}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner
              variant="contained"
              disabled={!hasChanged}
              onClick={postUpdatedPrintTemplate}
            >
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
