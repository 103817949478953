import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
import {  useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const SpeedMenu = ({ content }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [components, setComponent] = useState(false);
  const [header, setHeader] = useState(false);
  const [customClass, setCustomClass] = useState('stdDialog');
  const [initialHash] = useState(window.location.hash);

  const handleSpeedMenuAction = (
    route = false,
    component,
    header,
    onClick,
    addCustomClass
  ) => {
    if (onClick) {
      onClick();
    } else if (route) {
      navigate(route, { replace: false });
    } else {
      if (addCustomClass){
        setCustomClass(addCustomClass);
      }
      setHeader(header);
      setComponent(component);
      setOpenDialog(true);

      window.history.pushState(false, '', `${initialHash}#dialog`);
    }
    //setIsOpen(false);
  };

  useEffect(() => {
    const onHashChange = () => setOpenDialog(window.location.hash === `${initialHash}#dialog`);
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, [initialHash]);

  const handleOpen = (event) => {
    setOpen(!open);
  };
  const closeDialog = (event, reason) => {
    if (reason ==='backdropClick'){
      return false;
    }
    window.history.back();
    setOpenDialog(false);
  };
  const DialogHeader = ({ header }) => {
    return (
      <>
        <IconButton
          className="modalCloseButton"
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{header}</DialogTitle>
      </>
    );
  };
  const useStyles = makeStyles((theme) => ({
    staticTooltipLabel: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      pointerEvents: 'none',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Backdrop
        onClick={handleOpen}
        open={open}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
      <Dialog
        maxWidth={false}
        className={customClass}
        scroll="paper"
        onClose={closeDialog}
        open={openDialog}
      >
        {header && <DialogHeader header={header} />}
        {components}
      </Dialog>

      <SpeedDial
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        className="customSpeedDial"
        ariaLabel="Action menu"
        icon={<SpeedDialIcon />}
        onClick={handleOpen}
        open={open}
      >
         {content.filter((item) => item.hide !== true).map((item, index) => (
          <SpeedDialAction
            className={item.disabled ? 'menuItemDisabled' : ''}
            key={index}
            icon={item.icon}
            onClick={() => {
              if (!item.disabled) {
                handleSpeedMenuAction(
                  item.route,
                  item.component,
                  item.dialogHeader,
                  item.onClick,
                  item.customClass,
                );
              }
            }}
            tooltipTitle={item.text}
            classes={item.disabled ? classes : null}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </>
  );
};
export default SpeedMenu;
