export function formatInsertArguments(text, ...argsToInsert) {
    return text.replace(/{(\d+)}/g, function (match, number) {
        return typeof argsToInsert[number] != 'undefined'
            ? argsToInsert[number]
            : match
            ;
    });
};

function toHex(number) {
    const hex = number.toString(16);
    return number < 16 ? '0' + hex : hex;
}

// hasha hela strängen och gör om hashen till en färg ex. #112233
export function stringToColor(string) {
    if (!string) {
        return
    }

    let hash = 0;
  
    /* eslint-disable no-bitwise */
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    const color = `#${toHex(hash & 0xff)}${toHex((hash >> 8) & 0xff)}${toHex((hash >> 16) & 0xff)}`;
    /* eslint-enable no-bitwise */

    return color;
}