import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import UserDetailsCard from "./UserDetailsCard";
import UserProfilePictureCard from "./UserProfilePictureCard";
import UserPasswordCard from "./UserPasswordCard";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import FilterGroupsCard from "./FilterGroupsCard";
import { hasAccess, accessKeys } from "../../utils/userAccess";

export default function UserSettings(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const api = useApi();

  async function loadUserProfile() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}profiles`,
      false,
      "GET"
    );
    !!response && setProfile(response);
    setIsLoading(false);
  }

  useEffect(() => loadUserProfile(), []);

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={3}
      wrap="nowrap"
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "flex-start", md: "center" }}
    >
      {isLoading && <Preloader />}
      <Grid item>
        <UserProfilePictureCard
          url={profile?.actor?.profilePictureUrl}
          name={profile?.name}
          actorId={profile?.actor?.actorId}
          onImageChange={() => loadUserProfile()}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        rowSpacing={2}
        className="userSettingsDetailsCol"
      >
        <Grid item>
          <UserDetailsCard profile={profile} />
        </Grid>
        {hasAccess(accessKeys.advancedPackage, accessKeys.courseAdmin) && (
          <Grid item>
            <FilterGroupsCard />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <UserPasswordCard />
      </Grid>
    </Grid>
  );
}
