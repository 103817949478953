import { useParams } from "react-router-dom";

export default function ConfirmationPage(props) {
  const { id: orderId } = useParams();

  return (
    <div>
      <h2>Tack för din beställning!</h2>
      {orderId && <p>Beställning #123</p>}
      <p>Vi skickar snart en bekräftelse till din email</p>
    </div>
  );
}
