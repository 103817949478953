import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Preloader from "../Preloader";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BuildIcon from "@mui/icons-material/Build";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  isValidDate,
  toSameDateTimeUTC,
} from "../../utils/dateTimeFormat";
import { useSnackbar } from "notistack";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { ListItem } from "@mui/material";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { actorSearch, actorTypes } from "../../utils/actorSearch";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { hasAccess, accessKeys } from "../../utils/userAccess";

export default function GenerateLicenseDialog({ actorId }) {
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [licenseGroupId, setLicenseGroupId] = useState("");
  const [licenseDate, setLicenseDate] = useState(new Date());
  const [instructorActorId, setInstructorActorId] = useState("");
  const [validTimesByLicenseType, setValidTimesByLicenseTypes] = useState({});
  const [validTimeIndex, setValidTimeIndex] = useState("");
  const [isLoadingCount, setIsLoadingCount] = useState(3);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [inhouseInstructorToAdd, setInhouseInstructorToAdd] = useState(null);
  const [inhouseInstructors, setInhouseInstructors] = useState([]);

  const [useInhouseInstructor, setUseInhouseInstructor] = useState(false);

  const smallScreen = useMediaQuery('(max-width:700px)');
  const [hasAdvanced] = useState(hasAccess(accessKeys.advancedPackage));

  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  useEffect(() => {
    async function loadLicenseGroups() {
      const loadedLicenseGroups = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/licensegroups`,
        false,
        "GET"
      );

      setLicenseTypes(loadedLicenseGroups ?? []);
      setIsLoadingCount((prevCount) => prevCount - 1);

      if (loadedLicenseGroups) {
        const dto = {
          licenseGroupIds: loadedLicenseGroups.map((lg) => lg.licenseGroupId),
        };
        const validTimes = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}licenses/groups/valid-times`,
          dto,
          "POST"
        );
        setValidTimesByLicenseTypes(validTimes.licenseGroupValidTimes);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
    }

    async function loadTeachers() {
      const loadedTeachers = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/teachers`,
        false,
        "GET"
      );
      setInstructors(loadedTeachers.sort((a, b) => a.actorName.localeCompare(b.actorName)) ?? []);
      setIsLoadingCount((prevCount) => prevCount - 1);
    }

    loadLicenseGroups();
    loadTeachers();
  }, [actorId]);

  const handleChangeLicenseType = (event) => {
    const newLicenseGroupId = event.target.value;
    setLicenseGroupId(newLicenseGroupId);
    if (
      newLicenseGroupId &&
      newLicenseGroupId !== licenseGroupId &&
      validTimesByLicenseType &&
      validTimesByLicenseType[newLicenseGroupId] &&
      validTimesByLicenseType[newLicenseGroupId].length === 1
    ) {
      setValidTimeIndex(0);
    } else if (newLicenseGroupId !== licenseGroupId) {
      setValidTimeIndex("");
    }
  };

  const handleChangeValidTime = (event) => {
    setValidTimeIndex(event.target.value);
  };

  const handleGenerateLicense = () => {
    let id;
    if (!useInhouseInstructor && instructorActorId) {
      id = instructorActorId;
    } else {
      id = inhouseInstructorToAdd.actorId;
    }

    const dto = {
      licenseGroupId,
      instructorActorId: id,
      licenseDate,
      validTime:
        validTimeIndex >= 0
          ? validTimesByLicenseType[licenseGroupId][validTimeIndex]
          : null,
    };

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/actorlicenses`,
      dto,
      "POST"
    ).then((response) => {
      if (response && response.isSuccessful) {
        closeAndReload();
      }
    });
  };

  function CompareActors(option, value) {
    return option.actorId === value.actorId
  }

  function handleUseInhouseInstructor() {
    setUseInhouseInstructor(!useInhouseInstructor); 
  }

  async function personSearch(searchText) {
    const foundActors = await actorSearch(
      searchText,
      [actorTypes.unknown,
        actorTypes.contact,
        actorTypes.student,
        actorTypes.privateClient],
      ["ActorName", "OrgNo", "ExternalActorNo"],
      50
    );
    if (foundActors) {
      return foundActors
        .filter((actor) => !inhouseInstructors.some((d) => d.actorId === actor.actorId))
        .map((actor) => ({
          actorId: actor.actorId,
          actorName: actor.actorName,
          orgNo: actor.orgNo,
          externalActorNo: actor.externalActorNo,
          email: actor.email,
          profilePictureUrl: actor.profilePictureUrl,
          phoneNumber: actor.phoneNumbers?.find((ph) => ph.type === 2)
            ?.phoneNumber,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId !== actor.actorId &&
                x.parentActor.actorId > 0 &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
    }
  }

  return (
    <>
      {isLoadingCount > 0 && <Preloader />}
      {isLoadingCount <= 0 && (
        <>
          <DialogTitle>{Translate.get("GenerateLicense")}</DialogTitle>
          <DialogContent sx={{ paddingBottom: "24px" }}>
            <Typography
              sx={{
                margin: "0 0 32px",
              }}
            >
              {Translate.get("ChooseCourseGroup")}
            </Typography>
            <Stack spacing={4}>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <BuildIcon />
                <FormControl fullWidth>
                  <InputLabel id="license-type-select-label">
                    {Translate.get("LicenseTypeShort")}
                  </InputLabel>
                  <Select
                    labelId="license-type-select-label"
                    id="license-type-select"
                    value={licenseGroupId}
                    label={Translate.get("LicenseTypeShort")}
                    onChange={handleChangeLicenseType}
                  >
                    {licenseTypes.map((x) => (
                      <MenuItem key={x.licenseGroupId} value={x.licenseGroupId}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <CalendarIcon />
                <DatePicker
                  label={Translate.get("LicenseDate")}
                  inputFormat={getCurrentInputFormat()}
                  mask={getCurrentDateMask()}
                  value={licenseDate}
                  onChange={(newDate, realVal) => {
                    if ((realVal && realVal.length === 10) || (!realVal && newDate)) {
                      setLicenseDate(toSameDateTimeUTC(newDate));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <DateRangeIcon />
                <FormControl fullWidth>
                  <InputLabel id="valid-time-select-label">
                    {Translate.get("ValidTime")}
                  </InputLabel>
                  <Select
                    labelId="valid-time-select-label"
                    id="valid-time-select"
                    value={validTimeIndex}
                    label={Translate.get("ValidTime")}
                    onChange={handleChangeValidTime}
                    disabled={
                      !licenseGroupId ||
                      !validTimesByLicenseType ||
                      !validTimesByLicenseType[licenseGroupId] ||
                      // if the length is 1, we select that item by default
                      validTimesByLicenseType[licenseGroupId].length <= 1
                    }
                  >
                    {licenseGroupId &&
                      validTimesByLicenseType &&
                      validTimesByLicenseType[licenseGroupId] &&
                      validTimesByLicenseType[licenseGroupId].map(
                        (x, index) => (
                          <MenuItem key={index} value={index}>
                            {`${x.validTimeValue
                              } ${Translate.getValidTimeDefinition(
                                x.validTimeDefinition
                              )}`}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Stack>

             {!useInhouseInstructor &&                   
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <CoPresentIcon />
                <Autocomplete
                  fullWidth
                  value={value?.actorId}
                  onChange={(event, newValue) => {
                    setValue(newValue?.actorId ?? null);
                    setInstructorActorId(newValue?.actorId ?? null)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={instructors}
                  getOptionLabel={option => option.actorName}
                  noOptionsText={Translate.get("NoMatches")}

                  renderOption={(props, option) => (
                    <ListItem
                      sx={
                        option.inputValue
                          ? {
                            fontWeight: "bold",
                            backgroundColor: "rgb(0, 110, 200, 0.2)",
                          }
                          : {}
                      }
                      {...props}
                      key={option.actorId}
                    >
                      {option.actorName} <br />
                      {option.email}
                    </ListItem>
                  )}
                  renderInput={(params) => <TextField {...params} label={Translate.get("Instructor")} />}
                  isOptionEqualToValue={(option, value) => CompareActors(option, value)}
                />
              </Stack> }  

              {useInhouseInstructor &&
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <CoPresentIcon />
              <AutocompleteSearchField
                placeholder={Translate.get("Search")}
                label={Translate.get("Others")}
                value={inhouseInstructorToAdd}
                onValueChange={setInhouseInstructorToAdd}
                disableAddAsNew={true}
                
                getOptionLabel={(option) => option?.actorName ?? ""}
                renderOption={(option) => (
                  <ActorAutocompleteOption
                    actorName={option?.actorName}
                    orgNo={option?.orgNo}
                    email={option?.email}
                    companies={option?.companies}
                    externalActorNo={option?.externalActorNo}
                  />
                )}
                keyPropName="actorId"
                requireSelection={true}
                search={personSearch}
                createNewValue={(text) => ({
                  actorName: text,
                })}
              />
              </Stack> }

              {hasAdvanced &&
              <Stack direction="row" spacing={1} className="stdFlexLeft">
              <FormControlLabel 
                control={<Checkbox />} label={Translate.get("UseExtendedInstructorSearch")}
                onClick={handleUseInhouseInstructor}
                checked={useInhouseInstructor}
                sx={{ml: !smallScreen ? 4: ""}}
              />
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{Translate.get('ExtendedInstructorSearchInfo')}</div>}>
                        <IconButton>
                            <InfoIcon color="primary" />
                        </IconButton>
                    </Tooltip>
              </Stack> }

            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
            <Button
              onClick={handleGenerateLicense}
              disabled={
                !licenseGroupId ||
                (!instructorActorId && !useInhouseInstructor) ||
                (!inhouseInstructorToAdd && useInhouseInstructor) ||
                !licenseDate ||
                !isValidDate(licenseDate)
              }
            >
              {Translate.get("Generate")}
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
}
