import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Translate from "../../../utils/Translate";
import DomainIcon from "@mui/icons-material/Domain";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

export default function SelectBookingTypeDialog({
  bookings,
  onContinue,
  selectedCourseBookingId,
  selectedBookingType,
  onSelectBookingType,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  const existingCompanyBooking = useMemo(
    () => bookings.filter((booking) => !booking.isPrivateBooking),
    [bookings]
  );

  return (
    <>
      <DialogTitle>{Translate.get("SelectBookingType")}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "32px", maxWidth: "600px" }}>

        {Boolean(existingCompanyBooking.length)   &&  <div className="smallHeader">{Translate.get("AddToExistingBooking")}</div>}
        <Grid container spacing={2}>
          {existingCompanyBooking.map((booking) => (
            <Grid container item key={booking.courseBookingId} xs={12}>
              <Grid
                container
                item
                xs={12}
                className={
                  "selectable textbox" +
                  (selectedCourseBookingId === booking.courseBookingId &&
                  selectedBookingType === "companyBooking"
                    ? " selected"
                    : "")
                }
                sx={{
                  padding: "16px 24px",
                  height: { xs: "135px", sm: "auto" },
                }}
                onClick={() =>
                  onSelectBookingType("companyBooking", booking.courseBookingId)
                }
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ width: "40px" }}
                  >
                    <Avatar>
                      <DomainIcon />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" sx={{ paddingLeft: "16px" }}>
                      {booking.actor.actorName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  alignItems="center"
                  wrap="nowrap"
                  sx={{ marginTop: { xs: "12px", sm: "0px" } }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ width: "40px" }}
                  >
                    <HeadsetMicIcon />
                  </Grid>
                  <Grid item>
                    <Box className="avatarInfo" sx={{ paddingLeft: "16px",wordBreak: 'break-all'}}>
                      <Box>{booking.contactActorName ?? "-"}</Box>
                      {booking.contactActorEmail && (
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                            }}
                          >
                            {booking.contactActorEmail}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <br/>
       <div className="smallHeader">{Translate.get("CreateNewBooking")}</div>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={4}>
            <Grid
              container
              item
              className={
                "selectable textbox" +
                (selectedBookingType === "internalBooking" ? " selected" : "")
              }
              //sx={{ height: { xs: "120px", sm: "auto" } }}
              //sx={{ backgroundColor: { xs: "red", sm: "yellow" } }}

              direction="column"
              //justifyContent="space-between"
              alignItems="flex-start"
              onClick={() =>
                onSelectBookingType(
                  "internalBooking",
                  bookings.find((booking) => !!booking.isInternalBooking)
                    ?.courseBookingId
                )
              }
            >
              <Grid
                item
                className={
                  "bookingTypeTitleContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
              >
                <Typography variant="h5">
                  {Translate.get(
                    isSmallScreenOrGreater
                      ? "InternalBookingSplit"
                      : "InternalBooking"
                  )}
                </Typography>
              </Grid>
              <Grid
                container
                item
                className={
                  "bookingTypeInfoContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {Translate.get("InternalBookingInfo")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Grid
              container
              item
              className={
                "selectable textbox" +
                (!selectedCourseBookingId &&
                selectedBookingType === "companyBooking"
                  ? " selected"
                  : "")
              }
              //sx={{ height: { xs: "120px", sm: "auto" } }}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              onClick={() => onSelectBookingType("companyBooking")}
            >
              <Grid
                item
                className={
                  "bookingTypeTitleContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
              >
                <Typography variant="h5">
                  {Translate.get(
                    isSmallScreenOrGreater
                      ? "CompanyBookingSplit"
                      : "CompanyBooking"
                  )}
                </Typography>
              </Grid>
              <Grid
                container
                item
                className={
                  "bookingTypeInfoContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {Translate.get("CompanyBookingInfo")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Grid
              container
              item
              className={
                "selectable textbox" +
                (selectedBookingType === "privateBooking" ? " selected" : "")
              }
              //sx={{ height: { xs: "120px", sm: "auto" } }}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              onClick={() => onSelectBookingType("privateBooking")}
            >
              <Grid
                item
                className={
                  "bookingTypeTitleContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
              >
                <Typography variant="h5">
                  {Translate.get(
                    isSmallScreenOrGreater
                      ? "PrivateBookingLabelSplit"
                      : "PrivateBookingLabel"
                  )}
                </Typography>
              </Grid>
              <Grid
                container
                item
                className={
                  "bookingTypeInfoContainer" +
                  (!isSmallScreenOrGreater ? " small" : "")
                }
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {Translate.get("PrivateBookingInfo")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <Button onClick={onContinue} disabled={!selectedBookingType}>
          {Translate.get("Continue")}
        </Button>
      </DialogActions>
    </>
  );
}
