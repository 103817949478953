import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DotMenu from '../../DotMenu';
import Translate from '../../../utils/Translate';
import Avatar from '@mui/material/Avatar';
import {Edit, Clear} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';
import { useApi } from '../../../utils/Api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { stringToColor } from '../../../utils/formatString';
import { validatePhoneNumber } from "../../../utils/phoneNumber";
import PersonCrudDialog from '../../actors/crud/PersonCrudDialog';
import ErrorMessage from '../../ErrorMessage';

function getDotMenuActions(onCrudPerson, onRemoveRecipient) {
  const menuItems = [
    {
      icon: <Edit />,
      text: Translate.get("EditPersonalInfo"),
      onClick: onCrudPerson,
    },
    {
      icon: <Clear />,
      text: Translate.get("RemoveRecipient"),
      onClick: onRemoveRecipient,
    },
  ];
  return menuItems;
}

const SendSms = ({courseId, selectedStudents, companyActorId, companyName, reloadCourse}) => {
  const rawData = selectedStudents;
  rawData.sort((a, b) => a.actorName.localeCompare(b.actorName));

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [showPersonCrudForActorId, setShowPersonCrudForActorId] = useState(null);
  const [anyActorUpdated, setAnyActorUpdated] = useState(false);
  const [smsMess, setSmsMess] = useState({ value: '' });
  const [submitting, setSubmitting] = useState(false);
  let unValidNr = false;
  const [data, setData] = useState(rawData);

  const checkNumber = (numChk) => {
    if (!numChk) {
      unValidNr = true;
      return false;
    }

    let validationResult = validatePhoneNumber(numChk);
    if (validationResult) {
      return true;
    }

    unValidNr = true;
    return false;
  };
  const removeReceiver = (id) => {
    const filter = data.filter((x) => x.actorId !== id);
    setData(filter);
  };
  const sendSms = () => {
    const actors = [];
    data.map((row, index) => {
      if (checkNumber(row?.mobilePhone?.actorPhoneNumber)) {
        actors.push(row.actorId);
      }
    });
    if (actors.length === 0 || !smsMess.value) {
      return false;
    }
    const outData = {
      toActorIds: actors,
      message: smsMess.value,
      courseId: courseId,
    };
    submit(outData);
  };
  const actorSaved = (updatedActor) => {
    setAnyActorUpdated(true);
    setData([updatedActor, ...data.filter((actor) => actor.actorId !== updatedActor.actorId)]);
    setShowPersonCrudForActorId(null);
  }

  const submit = (outData) => {
    setSubmitting(true);
    (async () => {
      const ret = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}email/sms/freetext`,
        outData,
      );
      setSubmitting(false);
      if (ret.isSuccessful) {
        enqueueSnackbar(Translate.get('SmsSentPlural'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reloadCourse();
        window.history.back();
      } else {
        /* enqueueSnackbar('Allt gick illa', {
          variant: 'error',
          autoHideDuration: 3000,
        }); */
      }
    })();
  };

  return (
    <>
      <DialogContent dividers={true}>
        <DialogContentText className="dialogSubtitle">
          {Translate.get('CourseParticipantsValidPhoneNumber')}
        </DialogContentText>
        <div className="smallHeader topBottom16">
        {Translate.get('EmailReceivers')} {companyName} ({data?.length})
        </div>
        {data.map((row, index) => (
          <div key={index} className="stdList">
            <div className="tableAvatar">
              <Avatar alt={row.actorName} src={row.profilePictureUrl}
                sx={{ bgcolor: stringToColor(row.actorName) }}
              >
              </Avatar>
              <div>
                <div className="tableAvatarTitle bold">{row.actorName}</div>
                {row?.mobilePhone?.actorPhoneNumber && checkNumber(row.mobilePhone.actorPhoneNumber) ? (
                  row.mobilePhone.actorPhoneNumber
                ) : (
                  <ErrorMessage 
                    onClick={() => setShowPersonCrudForActorId(row.actorId)}
                    error={Translate.get('MobileMissingOrInvalid') + " " + (row?.mobilePhone?.actorPhoneNumber || "")}
                    />
                )}
              </div>
            </div>
            <DotMenu content={
              getDotMenuActions(
                () => setShowPersonCrudForActorId(row.actorId),
                () => removeReceiver(row.actorId)
              )} />
            {/* <IconButton
              title={Translate.get('RemoveRecipient')}
              onClick={() => removeReceiver(row.actorId)}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
        ))}
        {/*  Om vi ska visa extra mess om inte mottagare utan telnr tagits bort
        {unValidNr ? (
          <div className="smallRedWarning">
            <WarningIcon fontSize="8" />
            <div>En eller flera mottagare saknar mobiltelefonnummer och kommer inte att kunna ta emot meddelandet</div>
          </div>
        ) : (
          <div></div>
        )} 
        */}
        <div className="smallHeader topBottom16">
          {Translate.get('Message')}
        </div>

        <TextField
          //autoFocus={true}
          multiline
          rows={4}
          fullWidth
          label={Translate.get('Message')}
          inputProps={{ maxLength: 300 }}
          onChange={(event, value) => {
            setSmsMess({ value: event.target.value })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if(anyActorUpdated) {
            reloadCourse();
          }
          window.history.back();
        }}>
          {Translate.get('Cancel')}
        </Button>
        <LoadingButton
          className="stdLightLoader"
          loading={submitting}
          disabled={!smsMess || smsMess.value === ""}
          onClick={() => sendSms()}
        >
          {Translate.get('Send')}
        </LoadingButton>
      </DialogActions>
      { showPersonCrudForActorId 
        && <PersonCrudDialog 
              open={true}
              personCrudProps=
                {{
                  actorId:showPersonCrudForActorId,
                  isEditOnly: true,
                  parentActorId: companyActorId,
                  onActorSaved: actorSaved,
                  onClose:() => setShowPersonCrudForActorId(null),
              }}
            />
      }
    </>
  );
};
export default SendSms;
