import {
  Link,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Translate from "../../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import ELearningLicenseCount from "./ELearningLicenseCount";
import { Link as RouterLink } from "react-router-dom";

export default function InternalBookingDialog({
  isELearningCourse,
  onContinue,
  studentCount,
  onStudentCountChange,
  courseId,
}) {
  const [availableLicenseCount, setAvailableLicenseCount] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  const handleAvailableLicenseCountChanged = useCallback(
    (newCount) => setAvailableLicenseCount(newCount),
    []
  );

  const isValidStudentCount = !isNaN(studentCount) && studentCount > 0;

  return (
    <>
      <DialogTitle>{Translate.get("EnterStudentCount")}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "600px" }}>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <PeopleIcon sx={{ marginLeft: "9px" }} />
            <TextField
              sx={{
                minWidth: isSmallScreenOrGreater ? "120px" : "250px",
                width: "100%",
              }}
              value={isNaN(studentCount) ? "" : studentCount}
              onChange={(event) => onStudentCountChange(event.target.value)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" ,type:'number'}}
              error={
                isELearningCourse &&
                !!availableLicenseCount &&
                studentCount > availableLicenseCount
              }
            />
            {isELearningCourse && isSmallScreenOrGreater && (
              <ELearningLicenseCount
                courseId={courseId}
                onAvailableLicenseCountChanged={
                  handleAvailableLicenseCountChanged
                }
              />
            )}
          </Stack>
          {isELearningCourse && !isSmallScreenOrGreater && (
            <Stack direction="row" spacing={1} className="stdFlexLeft">
              <ELearningLicenseCount
                courseId={courseId}
                onAvailableLicenseCountChanged={
                  handleAvailableLicenseCountChanged
                }
              />
            </Stack>
          )}
        </Stack>
        <Typography
          variant="body2"
          sx={{
            margin: "33px 0 20px",
            color: "text.secondary",
          }}
        >
          {isELearningCourse
            ? Translate.get("SelectStudentCountElearningInfo").replace(
                "{0}",
                availableLicenseCount ?? "XX"
              )
            : Translate.get("SelectStudentCountInfo")}
        </Typography>
        {isELearningCourse && (
          <Link to="/dashboard/licenses" component={RouterLink}>
            {Translate.get("ClickHereToOrderELearning")}
          </Link>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <Button
          onClick={onContinue}
          disabled={
            !isValidStudentCount ||
            (isELearningCourse && studentCount > availableLicenseCount)
          }
        >
          {Translate.get("Continue")}
        </Button>
      </DialogActions>
    </>
  );
}
