import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
//const kortillstandSE= (window.location.host.indexOf('kortillstand.se') !== -1);
const Page = forwardRef(({ children, title = (window.global.isKs ? 'Körtillstånd ' : 'ePort ')  + (sessionStorage.companyName || ''), ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Box>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
