// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import {
  hasAccess,
  hasSomeAccess,
  refreshTokenExpirationMinutes,
} from "./utils/userAccess";
import { useLocation } from "react-router-dom";
import Login from "./pages/Login";

const PrivateComponent = ({
  component: Component,
  accessKeysAll,
  accessKeysSome,
  ...rest
}) => {
  if (accessKeysAll && accessKeysSome) {
    throw new Error("Invalid props");
  }

  const loc = useLocation();

  const hasAccessToComponent = useMemo(() => {
    if (!accessKeysAll && !accessKeysSome) {
      return true;
    } else if (accessKeysAll && hasAccess(...accessKeysAll)) {
      return true;
    } else if (accessKeysSome && hasSomeAccess(...accessKeysSome)) {
      return true;
    } else {
      return false;
    }
  }, [accessKeysAll, accessKeysSome]);

  const refreshTokenExpiration = (refreshTokenExpirationMinutes - 1) * 60000;
  const loginExpires =
    parseFloat(sessionStorage.lastConnection) + refreshTokenExpiration;
  const isLoggedIn = loginExpires >= Date.now();

  return isLoggedIn ? (
    hasAccessToComponent ? (
      <Component {...rest} />
    ) : (
      <Navigate to={{ pathname: "/404" }} />
    )
  ) : (
    <Login redirectPath={loc.pathname} />
  );
};

export default PrivateComponent;
