import { useState } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Translate from '../../../utils/Translate';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SwipeableViews from 'react-swipeable-views';
import { formatDate } from '../../../utils/dateTimeFormat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlaceIcon from '@mui/icons-material/Place';
import GroupIcon from '@mui/icons-material/Group';
import CoPresentIcon from '@mui/icons-material/CoPresent';

const Comp = ({ data }) => {
  ////console.log('data', data)
  const GetCourseStatus = ({ from, to }) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today.getFullYear(), now.getMonth(), now.getDate() + 1);
    const parsedFrom = new Date(from);
    const parsedTo = new Date(to);

    let cssClass = '';
    let transKey = '';

    if(parsedTo < today) {
      cssClass = 'avslutad';
      transKey = Translate.get('TimeStatus_Past');
    } else if (parsedFrom < tomorrow) {
      cssClass = 'pagaende';
      transKey = Translate.get('Ongoing');
    } else {
      cssClass = 'planerad';
      transKey = Translate.get('Planned');
    } 
    return <div className={cssClass}>{transKey}</div>;
  };

  const [activeStep, setActiveStep] = useState(0);
  const MobileStep = (props) => {
    const theme = useTheme();
    const handleNext = () => {
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 2){
        setActiveStep(0)
      }
      else{
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };

    const handleBack = () => {
      if (activeStep === 0){
        setActiveStep(2)
      }
      else{
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
      //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{ flexGrow: 1, backgroundColor: 'transparent'}}
        
        nextButton={
          <Button size="small" onClick={handleNext} 
          //disabled={activeStep === 2}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} 
          //disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    );
  };

  function CircularProgressWithIcon(props) {
    let result = 0;
    if (!isNaN(props.value)) {
      result = Math.round(props.value * 100);
    }

    return (
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#00000014',
          }}
          size={228}
          thickness={1}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          size={228}
          thickness={1}
          value={result}
          //color="warning"
          sx={{
            position: 'absolute',
            left: 0,
            color: props.color,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="stdFlexCenterCol">
            <div className="smallHeader"> {props.text}</div>
            <h1>{props.result}</h1>
          </div>
        </Box>
      </Box>
    );
  }
  if (!data) {
    return '';
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Paper className="bookingPaper" sx={{ width: '100%', mb: 2 }}>
        <Card sx={{borderRadius:'8px',paddingBottom:'16px'}} className="bookingCard">
          <CardHeader
            avatar={<Avatar className='squareIcon'  alt={data.name} src={data.imageUrl + ''} />}
            //title={data.name}
            title={<Link  color="inherit"  underline="none"   href={`/dashboard/course/${data.courseId}/`}>{data.name}</Link>}
            /* action={
              <IconButton
                href={`/dashboard/course/${data.courseId}/`}
                aria-label="link"
              >
                <DoubleArrowIcon />
              </IconButton>
            } */
            subheader={data.shortComment || '\u00A0'}
          />
          <Link  color="inherit"  underline="none"   href={`/dashboard/course/${data.courseId}/`}>
          <CardContent  className="bookingBox" style={{paddingBottom:'8px'}}>
            <div>
              <Tooltip title={Translate.get('Status')} arrow>
                <CheckCircleIcon />
              </Tooltip>
              <GetCourseStatus from={data.fromDate} to={data.toDate} />
            </div>
            <div>
              <Tooltip title={Translate.get('Date')} arrow>
                <CalendarTodayIcon />
              </Tooltip>
              <div>
                {formatDate(data.fromDate)} - {formatDate(data.toDate)}
              </div>
            </div>
            <div>
              <Tooltip title={Translate.get('City')} arrow>
                <PlaceIcon />
              </Tooltip>
              <div>{data.city} </div>
            </div>
            <div>
              <Tooltip title={Translate.get('Instructor')} arrow>
                <CoPresentIcon />
              </Tooltip>
              <div>{data.responsibleTeacherActorName}</div>
            </div>
            <div>
              <Tooltip title={Translate.get('NoParticipants')} arrow>
                <GroupIcon />
              </Tooltip>
              <div>{data.numberOfParticipants}</div>
            </div>
            <div className="stdFlexCenter">
              {/* Andel startade e-licenser
Genomsnittligt resultat
Andel kursdeltagare som klarat slutprovet */}
              {data.isELearning && (
                <SwipeableViews
                  slideClassName="stdFlexCenter"
                  onChangeIndex={setActiveStep}
                  enableMouseEvents
                  index={activeStep}
                >
                  <CircularProgressWithIcon
                    color={data.partOfParticipantsWithStartedELearning ? data.partOfParticipantsWithStartedELearning.color  : '#fff'}
                    value={data.partOfParticipantsWithStartedELearning ? data.partOfParticipantsWithStartedELearning.dataNum : 0}
                    text={Translate.get('HasStartedELearningKPI')}
                    result={
                      <div>{data.partOfParticipantsWithStartedELearning ? data.partOfParticipantsWithStartedELearning.dataStr : '0'}</div>
                    }
                  />
                  <CircularProgressWithIcon
                    color={data.meanFinalExamScore ? data.meanFinalExamScore.color : '#fff'}
                    value={data.meanFinalExamScore ? data.meanFinalExamScore.dataNum : 0}
                    //text='Genomsnittligt resultat' //{Translate.get('DaysLeft')}
                    text={Translate.get('AveragePerformanceFinalExam')}
                    result={<div>{data.meanFinalExamScore ? data.meanFinalExamScore.dataStr   : '0'}</div>}
                  />
                  <CircularProgressWithIcon
                   color={data.partOfParticipantsWithFinalExamDoneOk ? data.partOfParticipantsWithFinalExamDoneOk.color  : '#fff'}
                    value={data.partOfParticipantsWithFinalExamDoneOk ? data.partOfParticipantsWithFinalExamDoneOk.dataNum : 0}
                    //
                    text= {Translate.get('HasPassedELearningKPI')}
                    result={<div>{data.partOfParticipantsWithFinalExamDoneOk ? data.partOfParticipantsWithFinalExamDoneOk.dataStr :'0'}</div>}
                  />
                </SwipeableViews>
              )}
              {!data.isELearning && (
                <SwipeableViews
                  slideClassName="stdFlexCenter"
                  onChangeIndex={setActiveStep}
                  enableMouseEvents
                  index={activeStep}
                >
                  <CircularProgressWithIcon
                    value={(data.daysToCourseStart > 0) ? 0 : data.issuedLicenses?.dataNum}
                    text={(data.daysToCourseStart > 0) ? Translate.get('DaysLeft') :  Translate.get('Certificate')}
                    result={<div>{(data.daysToCourseStart > 0) ? data.daysToCourseStart : data.issuedLicenses?.dataStr ?  data.issuedLicenses.dataStr :'0/0'}</div>}
                  />
                  <CircularProgressWithIcon
                    value={0}
                    text={Translate.get('NoBookings')}
                    result={<div>{data.numberOfBookings}</div>}
                  />
                  <CircularProgressWithIcon
                   /*  color={data.partBookedParticipants ? data.partBookedParticipants.color  : '#fff'}
                    value={data.partBookedParticipants ? data.partBookedParticipants.dataNum : 0}
                    text="Andel kursdeltagare" //{Translate.get('AveragePerformance')}
                    result={
                      <div>{data.partBookedParticipants ? data.partBookedParticipants.dataStr : '0'}</div>
                    } */
                    color={data?.numberOfRealParticipants?.color ?? "#006EC8"}
                    value={data?.numberOfRealParticipants?.dataNum ?? 0}
                    text={Translate.get('AddedCourseParticipants')}
                    result={
                      <div>{data?.numberOfRealParticipants?.dataStr ?? 0}</div>
                    } 
                    
                  />
                </SwipeableViews>
              )}
            </div>
           
          </CardContent>
          </Link>
          <MobileStep />
          
        </Card>
      </Paper>
    </Box>
  );
};
export default Comp;
