import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import CompaniesView from "../../components/digital-licenses/CompaniesView";
import Translate from "../../utils/Translate";

export default function MyData(props) {
  const [companies, setCompanies] = useState([]);
  const api = useApi();

  useEffect(() => {
    async function loadMyData() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}mydata`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setCompanies(response.companies);
      }
    }

    loadMyData();
  }, [api]);
  {
    /* <li key={company.orgNo}>
              {company.name}, {company.orgNo}
            </li> */
  }
  return (
    <div className="dlWrapper">
      <div className="dlHeader">{Translate.get("MyData")}</div>
      {companies &&
        companies.map((company, index) => (
          <CompaniesView key={index} company={company} />
        ))}
    </div>
  );
}
