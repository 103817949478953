import React, { useEffect, useState } from 'react';
import { useApi } from '../../utils/Api';
import Paper from '@mui/material/Paper';
import Preloader from '../Preloader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Translate from "../../utils/Translate";
import { createCookie, getCookie, isNullOrEmpty } from './Common';

export function LoginWithId(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const api = useApi();

  useEffect(async () => {
    setIsLoading(true);
    const lang = Translate.getBrowserLanguage();
    Translate.loadSelfReg(lang).then((data) => {
      setDataFetched(true);
      setIsLoading(false);
    });

    let reservationToken = getCookie("selfRegReservationToken")
    if (!isNullOrEmpty(reservationToken)) {
      let token = getCookie("selfRegToken");
      await deleteReservationToken(reservationToken, token);
    }

    if (!isNullOrEmpty(props.id)) {
      setId(props.id);
    }
  }, []);

  async function getQrToken() {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_AUTH_URL}auth/login-from-qr-code/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        null
      );
      setIsLoading(false);
      if (response.isSuccessful) {
        createCookie("selfRegToken", response.token, 10);
        createCookie("selfRegId", id, 10);
        return response.token;
      }
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function getSelfRegSettings(qrToken, id) {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/settings/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        qrToken
      );

      if (response.isSuccessful) {
        props.setSelfRegSettings(response)
        return response;
      }
 
    } catch (error) {
      setErrorMessage("SomethingFailed");
    }
    finally {
      setIsLoading(false);
    }
  }

  async function deleteReservationToken(reservationToken, token) {
    setIsLoading(true);
    if (isNullOrEmpty(token)) {
      return;
    }

    try {
      let reqObj = {
        token: reservationToken
      }

      await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}self/reserve`,
        reqObj,
        'DELETE',
        token  
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleChange(event) {
    setId(event.target.value);
    if (event.target.value.length < 6) {
      setErrorMessage("");
    }
  }

  async function handleClick() {
    let qrToken = await getQrToken();
    if (qrToken === null) {
      return;
    }

    let settings = await getSelfRegSettings(qrToken, id);
    await redirect(settings);
  }

  async function redirect(selfRegSettings) {
    if (!selfRegSettings.isActorSpecific) {
      props.setMode("ChooseCourseBooking");
    }
    else if (selfRegSettings.isActorSpecific && selfRegSettings.bankIdAuthenticate) {
      props.setMode("BankId");
    }

    else {
      props.setMode("Form");
    }
  }

  return (
    <div className="stdPage" style={{ display: 'flex', justifyContent: 'center' }}>
      {(!dataFetched || isLoading) && <Preloader />}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>

          <Paper elevation={3} sx={{ p: 8 }}>
            <img src="/static/instructor_logo.png" alt="ePort-logotype" className='maxWidthImageSelf' />
            <p>{Translate.get("SubmitCode")}</p>
            <TextField id="outlined-basic" variant="outlined" sx={{ pt: 1, pb: 1, width: '100%'}} value={id} onChange={handleChange} type="text" inputProps={{
              maxLength: 20,
            }} />
            <br />
            {errorMessage !== "" && <Alert severity="error">{Translate.get(errorMessage)}</Alert>}
            <Button variant='contained' disabled={id?.length < 1} onClick={handleClick} sx={{ mt: 1 }}>{Translate.get("Continue")}</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

