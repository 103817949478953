import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningIcon from "@mui/icons-material/Warning";
import { isValidDate } from "../../../utils/dateTimeFormat";
import Translate from "../../../utils/Translate";
import EditNoteIcon from '@mui/icons-material/EditNote';

// siffrorna markerar vilken ordning körtillstånden sorteras i
// siffrorna används också för att veta vilka ikoner och översättningar som ska renderas
const permitStatuses = Object.freeze({
  messageToResponsibleBounced: 1,
  signedByResponsibleAndPermitMessageToDriverBounced: 2,
  waitingForResponsibleSignature: 3,
  signedByResponsibleAndPermitWaitingForDriverSignature: 4,
  signedByResponsibleAndDriver: 5,
  manuallySigned: 6,
  expired: 7
});

export const permitStatusToSortableValue = (permit) => {
  
  const validTo = permit.validTo && new Date(permit.validTo);
  const isExpired = isValidDate(validTo) ? validTo < Date.now() : false;
  if (isExpired) {
    // PermitExpired
    return permitStatuses.expired;
  }

  if (permit.blobFile !==null){
    return permitStatuses.manuallySigned; //ManuallySigned
  }

  const isSignedByResponsible = !!permit.responsibleSignatureId;
  const hasResponsibleSignEmailBounced =
    !isSignedByResponsible && permit.responsibleSignCommunicationBounced;
  if (hasResponsibleSignEmailBounced) {
    // PermitMessageToResponsibleBounced
    return permitStatuses.messageToResponsibleBounced;
  }

  const isSignedByResponsibleAndDriver =
    isSignedByResponsible && !!permit.signatureId;
  const hasDriverSignEmailBounced =
    !isSignedByResponsibleAndDriver && permit.signCommunicationBounced;
  if (isSignedByResponsible && hasDriverSignEmailBounced) {
    // PermitSignedByResponsible + PermitMessageToDriverBounced
    return permitStatuses.signedByResponsibleAndPermitMessageToDriverBounced;
  }

  if (isSignedByResponsibleAndDriver) {
    // PermitSignedByResponsibleAndDriver
    return permitStatuses.signedByResponsibleAndDriver;
  }

  if (isSignedByResponsible) {
    // PermitSignedByResponsible + PermitWaitingForDriverSignature
    return permitStatuses.signedByResponsibleAndPermitWaitingForDriverSignature;
  }

  // PermitWaitingForResponsibleSignature
  return permitStatuses.waitingForResponsibleSignature;
};

export const renderPermitStatus = (permitStatus) => {

  const className = "iconbox opaque";
  switch(permitStatus)
  {
    case permitStatuses.manuallySigned:
      return wrapPermitStatusIcon(
        <EditNoteIcon />,
        "ManuallySigned",
        concatClassName(className, "success")
      );
    case permitStatuses.expired:
      return wrapPermitStatusIcon(
        <EventBusyIcon />,
        "PermitExpired",
        className
      );
    case permitStatuses.messageToResponsibleBounced:
      return wrapPermitStatusIcon(
      <WarningIcon />,
      "PermitMessageToResponsibleBounced",
      concatClassName(className, "warning")
    );
    case permitStatuses.signedByResponsibleAndPermitMessageToDriverBounced:
      return <>
          {wrapPermitStatusIcon(
            <DoneIcon />,
            "PermitSignedByResponsible",
            concatClassName(className, "success")
          )}
          {wrapPermitStatusIcon(
            <WarningIcon />,
            "PermitMessageToDriverBounced",
            concatClassName(className, "warning")
          )}
        </>;
    case permitStatuses.signedByResponsibleAndDriver:
      return wrapPermitStatusIcon(
        <DoneAllIcon />,
        "PermitSignedByResponsibleAndDriver",
        concatClassName(className, "success")
      );
    case permitStatuses.signedByResponsibleAndPermitWaitingForDriverSignature:
      return <>
          {wrapPermitStatusIcon(
            <DoneIcon />,
            "PermitSignedByResponsible",
            concatClassName(className, "success")
          )}
          {wrapPermitStatusIcon(
            <AccessTimeIcon />,
            "PermitWaitingForDriverSignature",
            className
          )}
        </>
    case permitStatuses.waitingForResponsibleSignature:
      return wrapPermitStatusIcon(
        <AccessTimeIcon />,
        "PermitWaitingForResponsibleSignature",
        className
      );

    default:
      return <></>;
  }
};


export const formatPermitStatus = (permitStatus) => {
  
  switch(permitStatus)
  {
    case permitStatuses.manuallySigned:
      return Translate.get("ManuallySigned");

    case permitStatuses.expired:
      return Translate.get("PermitExpired");

    case permitStatuses.messageToResponsibleBounced:
      return Translate.get("PermitMessageToResponsibleBounced");

    case permitStatuses.signedByResponsibleAndPermitMessageToDriverBounced:
      return Translate.get("PermitSignedByResponsible") + ", " + Translate.get("PermitMessageToDriverBounced");

    case permitStatuses.signedByResponsibleAndDriver:
      return Translate.get("PermitSignedByResponsibleAndDriver");

    case permitStatuses.signedByResponsibleAndPermitWaitingForDriverSignature:
      return Translate.get("PermitSignedByResponsible") + ", " + Translate.get("PermitWaitingForDriverSignature");
    
      case permitStatuses.waitingForResponsibleSignature:
      return Translate.get("PermitWaitingForResponsibleSignature");

    default:
      return "";
  }
};

const concatClassName = (className, classNameToAdd) => {
  return className + (classNameToAdd ? ` ${classNameToAdd}` : "");
};

const wrapPermitStatusIcon = (icon, tooltipKey, className) => {
  return (
    <Tooltip className={className} title={Translate.get(tooltipKey)}>
      {icon}
    </Tooltip>
  );
};
