import {
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  IconButton,
  TableRow,
  TableCell,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ClearIcon from "@mui/icons-material/Clear";
import { TableAvatar } from "../../utils/dataGrid";
import DotMenu from "../DotMenu";
import EditIcon from "@mui/icons-material/Edit";

export default function LicenseRegistrationStudentRow({
  student,
  visibleCompetences,
  handleStudentAddRemoveCompetence,
  handleRemoveStudent,
  handleEditStudent,
  isLicenseEditLocked,
  isPersonEditLocked,
}) {
  const getDotMenuActions = (student) => {
    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        disabled: isPersonEditLocked,
        onClick: () => handleEditStudent(student),
      },
    ];
    return menudata;
  };

  return (
    <TableRow key={student.id} className="gradesTableRow licenseReg">
      <TableCell component="th" scope="row" align="left">
        <Box display="flex" justifyContent="space-between" gap="10px">
          <TableAvatar
            src={student.profilePictureUrl || null}
            title={student.name}
            subtitle={[student.email, student.socialSecurityNumber]}
          />
          <DotMenu content={getDotMenuActions(student)} horizontal />
        </Box>
      </TableCell>
      <TableCell align="left" width="100%">
        <FormControl>
          <FormGroup sx={{ flexWrap: "nowrap" }} aria-label="position" row>
            {visibleCompetences.map((competence) => (
              <Checkbox
                key={competence.id}
                className="gradesTableSkillCheckbox licenseReg"
                inputProps={{
                  "aria-label": "controlled",
                }}
                checked={!!student.competences[competence.id]}
                onChange={(_) =>
                  handleStudentAddRemoveCompetence(student, competence.id)
                }
                disabled={isLicenseEditLocked}
              />
            ))}
            {/* Just an invisible dummy checkbox for UI */}
            {(!visibleCompetences || visibleCompetences.length === 0) && (
              <Checkbox
                className="gradesTableSkillCheckbox"
                sx={{ visibility: "hidden" }}
              />
            )}
          </FormGroup>
        </FormControl>
      </TableCell>
      <TableCell align="right">
        <IconButton
          sx={{ marginLeft: "6px" }}
          aria-label="delete"
          onClick={() => handleRemoveStudent(student)}
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
