import { DialogContent } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import Preloader from "../Preloader";
import CreateActorPermitDialog from "./CreateActorPermitDialog";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import { validateDriverActor } from "./validation/ActorValidator";
import SelectDriversDialog from "./SelectDriversDialog";

async function loadActor(api, actorId) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/${actorId}`,
    false,
    "GET"
  );
}

async function loadAllActors(api, actorIds) {
  return await Promise.all(
    actorIds.map(async (actorId) => await loadActor(api, actorId))
  );
}

async function sendPermitGroupSignEmailToResponsible(api, actorPermitGroupId) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}permits/groups/email?actorPermitGroupId=${actorPermitGroupId}&ignoreFailResponse=true`,
    false,
    "GET"
  );
}

export default function ActorPermitFlowDialogContainer({
  actorIds,
  skipStep1 = false,
  cloneFromActorPermit
}) {
  const [hasStep1] = useState(!skipStep1);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [currentStep, setCurrentStep] = useState(hasStep1 ? 1 : 2);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = useCallback(() => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  }, [navigate, location]);

  const loadActors = useCallback(
    (actorIds) => {
      setIsLoading(true);
      return loadAllActors(api, actorIds).then((actors) => {
        actors = actors.map((a) => ({
          ...a,
          email: a.actorEmail,
          phoneNumber: a.mobilePhone?.actorPhoneNumber,
        }));
        actors = actors.map((a) => ({ ...a, error: validateDriverActor(a) }));
        // We exit immediately upon error when skipping step1
        const validationError =
          skipStep1 && actors.find((a) => !!a.error)?.error;
        if (validationError) {
          const errorMessage = enqueueSnackbar(validationError, {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          });
          closeAndReload();
        } else {
          setSelectedDrivers(actors);
          setIsLoading(false);
        }
      });
    },
    [enqueueSnackbar, closeSnackbar, closeAndReload, skipStep1]
  );

  useEffect(() => {
    if (
      actorIds &&
      actorIds.length > 0 &&
      (!selectedDrivers || selectedDrivers.length <= 0)
    ) {
      loadActors(actorIds);
    }
  }, [actorIds, selectedDrivers, loadActors]);

  function handleBack() {
    if (currentStep === 1) {
      closeAndReload();
    } else if (currentStep === 2 && !hasStep1) {
      // In this case the actor was selected outside of this dialog,
      // so back shouldn't return to step 1, but rather close the dialog
      closeAndReload();
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  }

  function handlePermitGroupSaved(actorPermitGroupId) {
    sendPermitGroupSignEmailToResponsible(api, actorPermitGroupId).then(() => {
      const successMessage = enqueueSnackbar(
        Translate.get("PermitSignProcessStarted"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
      closeAndReload();
    });
  }

  async function handleDriversSelected(actorIds) {
    await loadActors(actorIds);
    setCurrentStep(2);
  }

  // Klonade skills saknar skillGroupId, lägg till dem
  function copySkillGroupIdToSkills(actorPermit) {
    let ap = actorPermit;
    ap.skills.forEach(skill => {
      skill.skillGroupId = ap.skillGroupId;
    });
  
    return ap;
  }

  return (
    <>
      {isLoading && (
        <>
          <Preloader />
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {!isLoading && (
        <>
          {currentStep === 1 && hasStep1 && (
            <SelectDriversDialog
              selectedDrivers={selectedDrivers}
              onBack={handleBack}
              onDriversSelected={handleDriversSelected}
            />
          )}
          {currentStep === 2 &&
            selectedDrivers &&
            selectedDrivers.length > 0 && (
              <CreateActorPermitDialog
                actors={selectedDrivers}
                onBack={handleBack}
                hasStep1={hasStep1}
                onPermitGroupSaved={handlePermitGroupSaved}
                cloneFromActorPermit={cloneFromActorPermit ? copySkillGroupIdToSkills(cloneFromActorPermit) : null}
              />
            )}
        </>
      )}
    </>
  );
}
