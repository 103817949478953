import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import Translate from "../../utils/Translate";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import { isMobile } from "is-mobile";
import { AlertDialog } from "../AlertDialog";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogTitle, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PreviewCompany from "./PreviewCompany";
import PreviewCompetences from "./PreviewCompetences";

const Frontside = (props) => {
  //console.log("Cardfront", props);
  return (
    <>
      {props.bgImg && (
        <>
          <img className="dlcBgImg" src={props.bgImg} />
        </>
      )}
      {props.bgAnimation && (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      )}
      <div className="dlcFrontText">
        {props.fields.map((row, index) => (
          <Fragment key={index}>
            {row.fieldName === "CompanyLogo" ? (
              <div>
                <img src={row.value} className="dlcCompanyLogo" />
              </div>
            ) : row.fieldName === "gap" ? (
              <div className="dlcFrontTextSpacer"></div>
            ) : (
              <div>
                <div style={props.fontColor.secondary}>
                  {Translate.get(row.titleTranslationKey)}
                </div>
                <div
                  style={{ ...props.fontColor.primary, fontSize: row.fontSize }}
                  className={
                    row.fieldName === "LicenseGroupDescription"
                      ? "dlcMainTitle"
                      : "dlcHeading1"
                  }
                >
                  {row.value}
                </div>
                <div style={props.fontColor.primary}>
                  {row.subtitleTranslationKey &&
                    Translate.get(row.subtitleTranslationKey)}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};
const Backside = (props) => {
  //console.log("CardBack", props);
  return (
    <>
      {props.bgImg && (
        <>
          <img src={props.bgImg} />
        </>
      )}
      {props.bgAnimation && (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      )}
      <div className="dlcBackText">
        {props.fields.map((row, index) => (
          <div key={index}>
            <div style={props.fontColor.secondary}>
              {Translate.get(row.titleTranslationKey)}
            </div>
            {(row.fieldName === "CourseCoordinator" ||
              row.fieldName === "ActorLicensePractical") &&
            props.showExtraInfo ? (
              <div
                className="selectable"
                onClick={
                  row.fieldName === "CourseCoordinator"
                    ? props.handleOpenCompany
                    : props.handleOpenComp
                }
              >
                <div>{row.value}</div>
                <InfoOutlinedIcon className="dlInfoIcon" />
              </div>
            ) : (
              <div style={props.fontColor.primary}>{row.value}</div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default function DigitalLicenseView({
  displayData,
  animate,
  allowSwiper,
  company,
  skills,
  otherSkills,
  showExtraInfo,
  isSmallCard,
}) {
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [showCompetencesDialog, setShowCompetencesDialog] = useState(false);

  const smallScreen = useMediaQuery("(max-width:750px)");

  const bgColor = displayData?.digitalLicenseColor
    ? { backgroundColor: displayData.digitalLicenseColor }
    : {};
  const fontColor = {};
  fontColor.primary = displayData?.primaryFontColor
    ? { color: displayData.primaryFontColor }
    : {};
  fontColor.secondary = displayData?.secondaryFontColor
    ? { color: displayData.secondaryFontColor }
    : {};
  const ff = [];
  displayData.frontFields.map((row) => {
    row.value = displayData.fieldValues[row.fieldName]
      ? displayData.fieldValues[row.fieldName]
      : "-";
    ff.push(row);
  });
  const bf = [];
  displayData.backFields.map((row) => {
    row.value = displayData.fieldValues[row.fieldName]
      ? displayData.fieldValues[row.fieldName]
      : "-";
    if (row.fieldName === "ValidFrom" || row.fieldName === "ValidTo") {
      row.value = tryFormatDate(row.value);
    }
    bf.push(row);
  });

  return (
    <>
      {showCompanyDialog && (
        <PreviewCompany
          company={company}
          onClose={() => setShowCompanyDialog(false)}
        />
      )}
      {showCompetencesDialog && (
        <PreviewCompetences
          skills={skills}
          otherSkills={otherSkills}
          onClose={() => setShowCompetencesDialog(false)}
        />
      )}
      {smallScreen && allowSwiper ? (
        <Swiper
          effect={"flip"}
          grabCursor={true}
          loop={true}
          //pagination={true}
          //navigation={true}
          modules={[EffectFlip]}
          className="swiperDL"
          initialSlide={0}
        >
          <SwiperSlide className="swiperDLSlide" style={bgColor}>
            <Frontside
              fields={ff}
              fontColor={fontColor}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
            />
          </SwiperSlide>
          <SwiperSlide className="swiperDLSlide" style={bgColor}>
            <Backside
              fields={bf}
              fontColor={fontColor}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className={isSmallCard ? "dlcSmallCard" : "dlcCard"}>
          <div style={bgColor}>
            <Frontside
              fields={ff}
              fontColor={fontColor}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
            />
          </div>
          <div style={bgColor}>
            <Backside
              fields={bf}
              fontColor={fontColor}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
            />
          </div>
        </div>
      )}
    </>
  );
}
