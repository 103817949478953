
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { accessKeys, hasAccess } from "../utils/userAccess";

// ----------------------------------------------------------------------

/* Logo.propTypes = {
  sx: PropTypes.object,
  onClick: null
}; */

const InnerLogo = ({ sx, src, width }) => (
  <Box
    component="img"
    src={src}
    sx={{ width: { width }, height: 'auto', ...sx }}
  />
);

export default function Logo({ sx, navigateTo }) {
  const onlyPermits=!hasAccess(accessKeys.courseAdmin) && hasAccess(accessKeys.actorPermit);
  const kortillstandSE= window.global.isKs;
  const smallScreen = useMediaQuery('(max-width:700px)');
  const logoProps = {
    sx: sx,
    src: '/static/Instructor-Portal_App-logo_DESKTOP.png',
    width: '200px',
  };
  if (onlyPermits || kortillstandSE){
    if (smallScreen) {
      logoProps.src = '/static/logo_kortillstand.png';
      logoProps.width ='280px';
      logoProps.margin = '0';
    }
    else{
      logoProps.src = '/static/logo_kortillstand.png';
      logoProps.width ='100%';
      logoProps.margin = '0';
      if (navigateTo) {
        logoProps.width ='335px';
      }
      else{
        logoProps.width ='100%';
     
      }
      
    }
  }
  else{
  if (smallScreen) {
    if (navigateTo) {
      logoProps.src = '/static/Instructor-Portal_App-logo_MOBILE.png';
      logoProps.width ='100px'; 
    } else {
      logoProps.src = '/static/Instructor-Portal_Full-logo_MOBILE.png';
      logoProps.width = '200px';
      logoProps.sx = {margin:'32px'};
    }
  } else {
    if (navigateTo) {
      logoProps.src = '/static/Instructor-Portal_App-logo_DESKTOP.png';
      logoProps.width ='280px;';
    } else {
      logoProps.src = '/static/Instructor-Portal_Full-logo_DESKTOP.png';
      logoProps.width = '100%';
      logoProps.sx = {margin:'32px'};
    }
  }
}
  return navigateTo ? (
    <Link to="/dashboard/app">
      <InnerLogo {...logoProps} />
    </Link>
  ) : (
    <InnerLogo {...logoProps} />
  );
}
