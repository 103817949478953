/* xeslint-disable */
import { useCallback, useEffect, useState } from "react";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import ComputerIcon from "@mui/icons-material/Computer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DotMenu from "../../components/DotMenu";
import Translate from "../../utils/Translate";
import YesOrNoDialog from "../YesOrNoDialog";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { TableAvatar } from "../../utils/dataGrid";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import ConnectPersonAndCompanyDialog from "./ConnectPersonAndCompanyDialog";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { Tooltip } from "@mui/material";
import { format } from "date-fns";

const getDotMenu = ({
  companyActorId,
  companyExternalDataId,
  contactActorId,
  hasUser,
  isLockedOut,
  onDeleteRelation,
  onToggleIsLockedOut,
}) => {
  const menudata = [
    {
      icon: <HeadsetMicIcon />,
      text: Translate.get("GoToPerson"),
      route: `/dashboard/person/${contactActorId}`,
    },
    {
      icon: <EditIcon />,
      text: Translate.get("EditPersonalConnection"),
      component: (
        <ConnectPersonAndCompanyDialog
          companyActorId={companyActorId}
          companyExternalDataId={companyExternalDataId}
          connectedPersonActorId={contactActorId}
        />
      ),
    },
    ...(hasUser
      ? [
          {
            icon: isLockedOut ? <LockOpenIcon /> : <LockIcon />,
            text: isLockedOut
              ? Translate.get("UnlockUser")
              : Translate.get("LockUser"),
            onClick: onToggleIsLockedOut,
          },
        ]
      : []),
    {
      icon: <ClearIcon />,
      text: Translate.get("DeleteConnection"),
      onClick: onDeleteRelation,
    },
  ];
  return menudata;
};

const Comp = ({ companyActorId, externalDataId, contacts, smallScreen }) => {
  const [relationChildActorIdToDelete, setRelationChildActorIdToDelete] =
    useState(false);
  const [actorUserConnections, setActorUserConnections] = useState({});
  const [actorAgreementConnections, setActorAgreementConnections] = useState(
    {}
  );

  const api = useApi();

  const navigate = useNavigate();
  const navigateToContact = (actorId) => {
    navigate(`/dashboard/person/${actorId}`, { replace: false });
  };

  async function updateUserIsLockedOut(actorId) {
    const actorUserConnection = { ...actorUserConnections[actorId] };
    actorUserConnection.isLockedOut = !actorUserConnection.isLockedOut;
    const actorUserConnectionsCopy = { ...actorUserConnections };
    actorUserConnectionsCopy[actorId] = actorUserConnection;
    setActorUserConnections(actorUserConnectionsCopy);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/lock?ePortUserId=${actorUserConnection.ePortUserId}&isLockedOut=${actorUserConnection.isLockedOut}`,
      true,
      "POST"
    );
    if (!response.isSuccessful) {
      actorUserConnection.isLockedOut = !actorUserConnection.isLockedOut;
      setActorUserConnections(actorUserConnectionsCopy);
    }
  }

  useEffect(() => {
    if (externalDataId && hasAccess(accessKeys.licenseIssuer)) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/connections?externalDataId=${externalDataId}`,
          false,
          "GET"
        )
        .then((response) => {
          if (response.isSuccessful) {
            const entries = response.connections
              .filter((c) => c.hasCourseAdmin)
              .map((c) => [
                c.actorId,
                {
                  ePortUserId: c.ePortUserId,
                  isLockedOut: c.isLockedOut,
                  isLockedOutDueToFailedLogins: c.isLockedOutDueToFailedLogins,
                  ePortProfileLineType: c.ePortProfileLineType,
                  roleId: c.roleId,
                  roleShortName: c.roleShortName,
                  validUntil: c.validUntil
                    ? format(new Date(c.validUntil), "yyyy-MM-dd HH:mm:ss")
                    : null,
                },
              ]);
            const connectionsByActorId = Object.fromEntries(entries);
            setActorUserConnections(connectionsByActorId);

            const entriesActorAgreements = response.connections
              .filter((c) => c.hasActiveAgreement || c.hasInactiveAgreement)
              .map((c) => [c.actorId, c.ePortUserId]);
            const agreementsByActorId = Object.fromEntries(
              entriesActorAgreements
            );
            setActorAgreementConnections(agreementsByActorId);
          }
        });
    }
  }, [api, externalDataId]);

  const localizedTextsMap = {
    columnMenuUnsort: Translate.get("DoNotSort"),
    columnMenuSortAsc: Translate.get("SortAscending"),
    columnMenuSortDesc: Translate.get("SortDescending"),
    columnMenuFilter: Translate.get("Filter"),
    columnMenuHideColumn: Translate.get("Hide"),
    columnMenuShowColumns: Translate.get("Show"),
    toolbarDensity: Translate.get("RowHeight"),
    toolbarExport: Translate.get("Export"),
    toolbarFilters: Translate.get("Filter"),
    toolbarColumns: Translate.get("Columns"),
    MuiTablePagination: {
      labelRowsPerPage: Translate.get("ShowNumberEntries"),
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} ${Translate.get("Of")} ${
          count !== -1 ? count : `${Translate.get("Total")} ${to}`
        }`,
    },
  };

  //const smallScreen = useMediaQuery('(max-width:700px)');
  let columns = [];
  let toolbarCss = "stdVisible dgDynIconText";
  if (smallScreen) {
    toolbarCss = "dgSmall";
    columns = [
      {
        field: "actorName",
        headerName: Translate.get("Contacts"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <HeadsetMicIcon />
            <div>{Translate.get("Contacts")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.profilePictureUrl + ""}
              //variant="rounded"
              title={params.row.actorName}
              subtitle={params.row.actorEmail}
            />
            /*  <div>
              <div>{params.row.actorName}</div>
              <div>{params.row.actorEmail}</div>
            </div> */
          );
        },
        //valueGetter: (params) => params.row.postalAddress.actorAddressCity || '',
        //sortComparator: (v1, v2) => v1.localeCompare(v2),
      },
      {
        field: "actorUserConnection",
        headerName: "",
        flex: 0.2,
        align: "right",
        sortable: false,
        renderCell: (params) => {
          return (
            !!actorUserConnections[params.row.actorId] &&
            (actorUserConnections[params.row.actorId].isLockedOut ? (
              <Tooltip title={Translate.get("UserLockedByAdmin")}>
                <LockIcon />
              </Tooltip>
            ) : actorUserConnections[params.row.actorId].isLockedOutDueToFailedLogins ? (
              <Tooltip title={Translate.get("UserLockedTooManyAttempts")}>
                <LockIcon />
              </Tooltip>
            ) :(
              <Tooltip
                title={
                  hasAccess(accessKeys.isMASystem)
                    ? `${Translate.getEPortLicenseLineType(
                        actorUserConnections[params.row.actorId]
                          .ePortProfileLineType
                      )}${
                        actorUserConnections[params.row.actorId].roleId
                          ? ` - ${
                              actorUserConnections[params.row.actorId]
                                .roleShortName
                            }`
                          : " - STANDARD"
                      }${
                        actorUserConnections[params.row.actorId].validUntil
                          ? ` (${
                              actorUserConnections[params.row.actorId]
                                .validUntil
                            })`
                          : ""
                      }`
                    : null
                }
              >
                {hasAccess(accessKeys.isMASystem) &&
                actorUserConnections[params.row.actorId].validUntil ? (
                  <AutoDeleteIcon />
                ) : (
                  <ComputerIcon />
                )}
              </Tooltip>
            ))
          );
        },
      },
      {
        field: "actorId",
        headerName: "",
        flex: 0.2,
        align: "right",
        sortable: false,
        renderHeader: () => <div></div>,
        renderCell: (params) => {
          return (
            <DotMenu
              horizontal
              content={getDotMenu({
                companyActorId: companyActorId,
                companyExternalDataId: externalDataId,
                contactActorId: params.row.actorId,
                hasUser: !!actorUserConnections[params.row.actorId],
                isLockedOut:
                  !!actorUserConnections[params.row.actorId]?.isLockedOut,
                onDeleteRelation: () =>
                  setRelationChildActorIdToDelete(params.row.actorId),
                onToggleIsLockedOut: () =>
                  updateUserIsLockedOut(params.row.actorId),
              })}
            />
          );
        },
      },
    ];
  } else {
    columns = [
      {
        field: "actorName",
        headerName: Translate.get("ActorName"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <HeadsetMicIcon />
            <div>{Translate.get("ActorName")}</div>
          </div>
        ),
      },
      {
        field: "actorEmail",
        headerName: Translate.get("ActorEmail"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <EmailIcon />
            <div>{Translate.get("ActorEmail")}</div>
          </div>
        ),
      },
      {
        field: "mobilePhone",
        headerName: Translate.get("Phone"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <HeadsetMicIcon />
            <div>{Translate.get("Phone")}</div>
          </div>
        ),
      },
    ];
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  //const OpenLink = () => {};

  async function deleteUser(ePortUserId) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users?ePortUserId=${ePortUserId}&deleteAgreementUser=true`,
      true,
      "DELETE"
    );
    return response.isSuccessful;
  }

  async function handleDeleteRelation() {
    let userDeleteOk = true;
    const ePortUserId = !!externalDataId
      ? actorUserConnections[relationChildActorIdToDelete]?.ePortUserId ??
        actorAgreementConnections[relationChildActorIdToDelete]
      : null;
    if (!!ePortUserId) {
      userDeleteOk = await deleteUser(ePortUserId);
    }

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/relations/delete`,
      {
        parentActorId: companyActorId,
        childActorId: relationChildActorIdToDelete,
        actorTypeId: 3, // Contact
      }
    );
    setRelationChildActorIdToDelete(null);
    if (
      (response && response.isSuccessful) ||
      (!!ePortUserId && userDeleteOk)
    ) {
      navigate(0);
    }
  }

  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "actorName", sort: "asc" }],
          },
        }}
        className={toolbarCss}
        rows={contacts}
        columns={columns}
        //checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row.actorId}
        autoHeight={true}
        onRowClick={(params) => navigateToContact(params.row.actorId)}
        // disableColumnMenu
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
        }}
        localeText={localizedTextsMap}
        pagination
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableDensitySelector={true}
        disableColumnMenu
      />
      <YesOrNoDialog
        open={!!relationChildActorIdToDelete}
        title={
          !!actorUserConnections[relationChildActorIdToDelete]
            ? Translate.get("DeleteConnectionAndUser")
            : Translate.get("DeleteConnection")
        }
        text={
          !actorUserConnections[relationChildActorIdToDelete]
            ? actorAgreementConnections[relationChildActorIdToDelete]
              ? Translate.get("SureRemoveRelationWithAgreement")
              : Translate.get("SureRemoveRelation")
            : actorAgreementConnections[relationChildActorIdToDelete]
            ? Translate.get("SureRemoveRelationWithUserAndAgreement")
            : Translate.get("SureRemoveRelationWithUser")
        }
        onNo={() => setRelationChildActorIdToDelete(null)}
        onYes={() => handleDeleteRelation()}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Delete")}
      />
    </>
  );
};
export default Comp;
