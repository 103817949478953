import { useApi } from "../utils/Api";
import Table from "../components/confirmPermits/Table";
import Card from "../components/confirmPermits/Card";
import Translate from "../utils/Translate";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import YesOrNoDialog from "../components/YesOrNoDialog";
import Preloader from "../components/Preloader";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  getPermitGroup,
  getPermit,
  doDeletePermit,
  getPermitPdfBlob,
  getLogoDataUrl,
  getDataIdDetails,
  logout,
  getBackendBaseUrl,
} from "../components/confirmPermits/permitApi";
import BankIdAuthentication from "../components/bankId/BankIdAuthentication";
import download from "downloadjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BankIdIcon from "../components//bankId/BankIdIconWhite";
import LogoutIcon from "@mui/icons-material/Logout";

const ConfirmPermits = () => {
  console.log("re-render");
  const api = useApi();
  const params = useParams();
  const navigate = useNavigate();
  const actorPermitGroupId = Number(params.actorPermitGroupId);
  const actorPermitId = Number(params.actorPermitId);
  const [data, setData] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  //const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSignatureDialog, setShowSignatureDialog] = useState(false);
  const [isAlreadySigned, setIsAlreadySigned] = useState(false);
  const smallScreen = useMediaQuery("(max-width:800px)");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [companyInfo, setCompanyInfo] = useState(false);

  useEffect(async () => {
    /* sessionStorage.setItem("bidURL",window.location.href); */
    Translate.loadPreLogin();
    await apiLoadPermits();
  }, []);

  const loginCheck = (responseData) => {
    if (responseData.errorMessageTranslationKey === "logInAgain") {
      // login cookie timeout
      const encryptedData = sessionStorage.getItem("permitSignEncryptedData");
      if (encryptedData) {
        if (actorPermitId) {
          navigate(`/login/permit-sign/${encryptedData}`);
        }
        if (actorPermitGroupId) {
          navigate(`login/permit-sign/group/${encryptedData}`);
        }
      }
      navigate("/"); // vi hittar ingen krypterad nyckel - gå till vanliga eport.se inloggningssida
    }
  };

  const displayApiError = (responseData) => {
    const translationKey =
    responseData?.errorMessageTranslationKey ?? "SomethingFailed";
    const successMessage = enqueueSnackbar(Translate.get(translationKey), {
      variant: "error",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(successMessage),
    });
  };

  const apiLoadPermits = async () => {
    let ret = [];
    if (actorPermitGroupId) {
      ret = await getPermitGroup(actorPermitGroupId);
    } else if (actorPermitId) {
      ret = await getPermit(actorPermitId);
    } else {
      return; // vare sig permitgroup eller permitid - inget att göra
    }

    if (ret.isSuccessful === false) {
      loginCheck(ret);
      displayApiError(ret);
    }

    const permits =
      ret.actorPermitGroup?.actorPermits ?? // antingen en körtillståndsgrupp
      (ret.actorPermit ? [ret.actorPermit] : []); // eller ett ensamt körtillstånd
    setData(permits);
    const alreadySigned = ret.actorPermitGroup
      ? ret.actorPermitGroup.responsibleSignatureDate
        ? true
        : false
      : ret.actorPermit.signatureDate
      ? true
      : false;

    setIsAlreadySigned(alreadySigned);
    getCompanyInfo();
  };

  const apiDeletePermit = async (id) => {
    setShowDeleteDialog(false);
    setDeleteLoading(true);
    const ret = await doDeletePermit(id);
    //const ret = await performDelete(`${permitsUrl}${id}/for-responsible`);
    console.log("apiDeletePermit", ret, id);
    if (ret.isSuccessful === false) {
      loginCheck(ret);
      displayApiError(ret);
    } else {
      const successMessage = enqueueSnackbar(Translate.get("PermitDeleted"), {
        variant: "success",
        autoHideDuration: 6000,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        onClick: () => closeSnackbar(successMessage),
      });
    }
    apiLoadPermits();
    setDeleteLoading(false);
  };
  if (!data) {
    apiLoadPermits();
  }

  const getSignatureUrl = () =>
    actorPermitGroupId
      ? `${getBackendBaseUrl()}permits/v2/groups/sign`
      : actorPermitId
      ? `${getBackendBaseUrl()}permits/v2/sign`
      : null;

  const getSignaturePostData = () =>
    actorPermitGroupId
      ? { actorPermitGroupId: actorPermitGroupId }
      : actorPermitId
      ? { actorPermitId: actorPermitId }
      : null;

  async function onBankIdSignatureSuccess() {
    await apiLoadPermits();
    setShowSignatureDialog(false);
  }

  async function onBankIdSignatureFailure(_, response) {
    displayApiError(response?.data);
    setShowSignatureDialog(false);
  }

  const validatePermits = () => {
    console.log("Validate permits");
    setShowSignatureDialog(true);
  };

  const permitPdfClick = async (permit) => {
    console.log("permitPdfClick", permit);
    //setPdfLoading(true);
    setPdfLoading(prevState => ([ ...prevState,  permit.actorPermitId]));

    const blob = await getPermitPdfBlob(permit.actorPermitId);
    if(blob.isSuccessful === false) {
      loginCheck(blob);
      displayApiError(blob);
      //setPdfLoading(false);
      setPdfLoading(prevState => (prevState.filter(item => item !== permit.actorPermitId)));
      return;
    }
    const pPrefix = Translate.get("ActorPermit");
    const filename = `${pPrefix}_${permit.skillGroupDescription}_${permit.actorName}.pdf`;
    download(blob, filename, blob.type);
    //setPdfLoading(false);
    setPdfLoading(prevState => (prevState.filter(item => item !== permit.actorPermitId)));
  };
  const deletePermit = (permit) => {
    console.log("deletePermit", permit);
    setShowDeleteDialog(permit);
  };
  const getCompanyInfo = async () => {
    const cData = await getDataIdDetails();
    const cLogo = await getLogoDataUrl();

    const cInfo = {
      logo: cLogo || "data:,",
      companyName: cData.companyName || "", //Ska nog vara addressStreet1, kan vara null
      webPage: cData.webPage || "", //Är alltid en tom stäng om saknas behöver ingen OR
    };
    console.log(cInfo);
    console.log(cData);
    console.log(cLogo);
    setCompanyInfo(cInfo);
  };
  const [showLogOut, setShowLogOut] = useState(false);
  const logOut = async () => {
    await logout();
    setShowLogOut(true);
  };

  const getLoginUrl = () => {             
    const baseGuiUrl = window.global?.isKs
      ? "https://app.kortillstand.se/"
      : "https://eport.se/";

    if(actorPermitGroupId) {
      return `${baseGuiUrl}login/permit-sign/group/${sessionStorage.permitSignEncryptedData}`;
    }
    return `${baseGuiUrl}login/permit-sign/${sessionStorage.permitSignEncryptedData}`;
  };

  if (showLogOut) {
    return (
      <div className="loginPage stdFlexCenter">
        <div className="loginBox">
          <div className="loginLogo">
            <img width={"300px"} src="/static/Kortillstand-se_Logo.svg" />
          </div>
          <div
            className="stdFlexCenterCol"
            style={{ marginTop: "16px", gap: "16px" }}
          >
            <div> {Translate.get("YouAreNowLoggedOut")}</div>
            <LoadingButton
              className="regularButtonWithIcon stdLightLoader"
              loading={isSubmitLoading}
              variant="contained"
              fullWidth={true}
              //disabled={submitDisabled}
              //component={Link}
              href={getLoginUrl()}
              //startIcon={<BankIdIcon width={'30px'}/>}
            >
              {Translate.get("LogIn")}
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  }
  if (!data) {
    return <Preloader />;
  }
  return (
    <>
      {/* {!data && <Preloader />} */}
      <Box className="pageWithFooter">
        {/*  <div className="simpleLogoTop">
         <img
           className="simpleLogoTopImg"
           src="/static/Instructor-Portal_App-logo_DESKTOP.png"
         />
       </div> */}
        <div className="headerSpaceBetween">
          <div>Signering av körtillstånd</div>
          <div></div>
          <div>
            <LoadingButton
              className="regularButtonWithIcon stdLightLoader"
              loading={false}
              variant="text"
              fullWidth={false}
              startIcon={<LogoutIcon width={"30px"} />}
              onClick={() => logOut()}
              //component={Link}
              //to="/about"
            >
              {Translate.get("LogOut")}
            </LoadingButton>
          </div>
        </div>

        {smallScreen && (
          <Card
            data={data}
            permitPdfClick={permitPdfClick}
            pdfLoading={pdfLoading}
            deletePermit={deletePermit}
            deleteLoading={deleteLoading}
            signed={isAlreadySigned}
            issuer={actorPermitGroupId}
          />
        )}
        {!smallScreen && (
          <Table
            data={data}
            permitPdfClick={permitPdfClick}
            pdfLoading={pdfLoading}
            deletePermit={deletePermit}
            deleteLoading={deleteLoading}
            signed={isAlreadySigned}
            issuer={actorPermitGroupId}
          />
        )}
        {/* <LoadingButton
        className="regularButtonWithIcon stdLightLoader"
        loading={isSubmitLoading}
        disabled={isAlreadySigned}
        variant="outlined"
        //disabled={submitDisabled}
        onClick={() => validatePermits(actorData)}
      >
        {Translate.get("SignBankID")}
      </LoadingButton> */}
        <div
          className="stdFlexCenter"
          style={{ display: !isAlreadySigned && "none" }}
        >
          <CheckCircleOutlineIcon color="primary" sx={{ fontSize: "50px" }} />
          <div>{Translate.get("KsSignDone")}</div>
        </div>
        <div className="stdFlexCenter">
          <LoadingButton
            className="regularButtonWithIcon stdLightLoader"
            loading={isSubmitLoading}
            variant="contained"
            fullWidth={false}
            sx={{ display: isAlreadySigned && "none" }}
            startIcon={<BankIdIcon width={"30px"} />}
            //disabled={isAlreadySigned}
            onClick={() => validatePermits()}
          >
            {Translate.get("SignBankID")}
          </LoadingButton>
        </div>
        <div></div>
        <hr />
        <div className="footerSpaceBetween">
          <div className="fsbLeft">
            <img
              style={{ maxWidth: "178px", maxHeight: "70px" }}
              src={companyInfo.logo}
            />
            <div>
              <b>{companyInfo.companyName}</b>
              <br />
              {companyInfo.webPage}
            </div>
          </div>
          <hr />
          <div className="fsbRight">
            <div>{Translate.get("KsCreatedBy")}</div>
            <img
              style={{ width: "178px" }}
              src="/static/Kortillstand-se_Logo.svg"
            />
          </div>
        </div>
      </Box>
      <YesOrNoDialog
        open={showDeleteDialog !== false}
        text={
          Translate.get("DeletePermitFor") + " " + showDeleteDialog.actorName
        }
        onYes={() => apiDeletePermit(showDeleteDialog.actorPermitId)}
        onNo={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
      />
      <Dialog
        open={showSignatureDialog}
        onClose={() => setShowSignatureDialog(false)}
      >
        <DialogTitle textAlign="center">
          {Translate.get("SignBankID")}
        </DialogTitle>
        <DialogContent>
          {/* Det ska vara en egen komponent för signatur */}
          <BankIdAuthentication
            fetchStatusIncludeCredentials={true}
            startAuthenticationUrl={getSignatureUrl()}
            postData={getSignaturePostData()}
            onAuthenticationSuccess={onBankIdSignatureSuccess}
            onAuthenticationFailure={onBankIdSignatureFailure}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ConfirmPermits;
