import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Fragment, useState, useEffect } from "react";
import Translate from "../../../utils/Translate";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../../DotMenu";
import StudentCourseGradesDialog from "./StudentCourseGradesDialog";
import ActorAvatar from "../../ActorAvatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonCrud from "../../actors/crud/PersonCrud";

export default function CourseGradesDialogSmall({
  open,
  onClose,
  bookings,
  skills,
  skillsByActorId,
  handleCheckSkillChange,
  canApproveAll,
  handleApproveAllSkills,
  handleApproveAllSkillsForStudent,
  handleOpenSkillsDialog,
  showCourseSkillsDialog,
  isAnyStudentUnlicensed,
  handleCreateLicenseClick,
  handleDeleteLicenseClick,
  handleCreateLicenseAllClick,
  licensesByActorId,
  handleOrderLicensesClick,
}) {
  const [students, setStudents] = useState([]);
  const [studentPassingGradesCount, setStudentPassingGradesCount] = useState(
    {}
  );
  const [showStudentGradesDialog, setShowStudentGradesDialog] = useState(false);
  const [selectedActorId, setSelectedActorId] = useState(null);

  useEffect(() => {
    const allStudents = bookings.flatMap(
      (booking) => booking.courseParticipants
    );
    setStudents(allStudents);
  }, [bookings]);

  useEffect(() => {
    const countByActorId = {};
    for (const actorId in skillsByActorId) {
      const passedCount = Object.values(skillsByActorId[actorId]).filter(
        (hasPassed) => hasPassed
      ).length;
      countByActorId[actorId] = passedCount;
    }
    setStudentPassingGradesCount(countByActorId);
  }, [skillsByActorId]);

  const getDotMenuActions = (props) => {
    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        component: (
          <PersonCrud
            actorId={props.actorId}
            isEditOnly
            isMatchingActorsDisabled
          />
        ),
      },
      {
        icon: <DoneAllIcon />,
        text: Translate.get("ApproveAllSkills"),
        disabled:
          !!licensesByActorId[props.actorId].date ||
          studentPassingGradesCount[props.actorId] === skills.length,
        onClick: () => handleApproveAllSkillsForStudent(props.actorId),
      },
      {
        icon: <DoneIcon />,
        text: Translate.get("ApproveSomeSkills"),
        disabled: !!licensesByActorId[props.actorId].date,
        onClick: () => handleOpenStudentGradesDialog(props.actorId),
      },
      {
        icon: !licensesByActorId[props.actorId].date ? (
          <CheckCircleIcon />
        ) : (
          <ClearIcon />
        ),
        text: !licensesByActorId[props.actorId].date
          ? Translate.get("GenerateLicense")
          : Translate.get("DeleteLicenseGroup"),
        onClick: !licensesByActorId[props.actorId].date
          ? () => handleCreateLicenseClick(props.actorId)
          : () => handleDeleteLicenseClick(props.actorId),
      },
    ];
    return menudata;
  };

  function handleOpenStudentGradesDialog(actorId) {
    setSelectedActorId(actorId);
    setShowStudentGradesDialog(true);
  }

  function handleCloseStudentGradesDialog() {
    setSelectedActorId(null);
    setShowStudentGradesDialog(false);
  }

  return (
    <>
      <StudentCourseGradesDialog
        open={showStudentGradesDialog}
        onClose={handleCloseStudentGradesDialog}
        skills={skills}
        students={students}
        selectedActorId={selectedActorId}
        skillsByActorId={skillsByActorId}
        licensesByActorId={licensesByActorId}
        handleCheckSkillChange={handleCheckSkillChange}
      />
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        sx={
          /* Doing this so that the spinner in CourseSkillsDialog is over this dialog*/
          showCourseSkillsDialog ? { zIndex: 0 } : {}
        }
      >
        <Box
          sx={{
            position: "absolute",
            right: 8,
            top: 9,
          }}
        >
          <IconButton onClick={handleOpenSkillsDialog}>
            <SettingsIcon />
          </IconButton>
          <IconButton
            onClick={handleApproveAllSkills}
            disabled={!canApproveAll}
          >
            <DoneAllIcon />
          </IconButton>
        </Box>
        <DialogTitle>{Translate.get("GradeCourse")}</DialogTitle>
        <DialogContent sx={{ padding: 0 }} dividers>
          <TableContainer>
            <Table
              className="gradesTable gradesTableSmall"
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#e6f1fa" }}>
                <TableRow className="gradesTableHeader small">
                  <TableCell align="center" colSpan={3}>
                    <Button
                      onClick={handleCreateLicenseAllClick}
                      variant="contained"
                      disabled={!isAnyStudentUnlicensed}
                    >
                      {Translate.get("GenerateLicenses")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              {bookings &&
                bookings.length > 0 &&
                bookings.map((booking) => (
                  <Fragment key={booking.courseBookingId}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3} sx={{ padding: "14px" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              margin: "1em 10px 2px",
                              textTransform: "uppercase",
                            }}
                          >{`${booking.companyName} (${booking.courseParticipants.length})`}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {booking.courseParticipants.map((student) => (
                        <TableRow
                          key={student.actorId}
                          className="gradesTableRow small"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="userNameEmail"
                            align="left"
                          >
                            <ActorAvatar
                              name={student.name}
                              email={student.email}
                              profilePictureUrl={student.profilePictureUrl}
                            />
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{ padding: "2px 8px" }}
                              className={
                                "textbox" +
                                ((studentPassingGradesCount[student.actorId] ??
                                  0) > 0 &&
                                studentPassingGradesCount[student.actorId] ===
                                  skills.length
                                  ? " grades-done"
                                  : "") +
                                ((studentPassingGradesCount[student.actorId] ??
                                  0) > 0 &&
                                studentPassingGradesCount[student.actorId] <
                                  skills.length
                                  ? " grades-ongoing"
                                  : "") +
                                (!!licensesByActorId[student.actorId].date
                                  ? " grades-licensed"
                                  : "")
                              }
                            >
                              {studentPassingGradesCount[student.actorId] ?? 0}/
                              {skills.length}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <DotMenu
                              content={getDotMenuActions({
                                actorId: student.actorId,
                              })}
                              horizontal
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Fragment>
                ))}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>
            {Translate.get("Close")}
          </Button>
          <Button
            variant="contained"
            onClick={handleOrderLicensesClick}
            disabled={!Object.values(licensesByActorId).some((v) => !!v.date)}
          >
            {Translate.get("OrderMultipleLicenses")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
