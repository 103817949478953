import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { hasAccess, accessKeys } from "../../utils/userAccess";

// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import {
  notificationTypeIcon,
  formatDate,
  getRedicrectionLink,
  parseMessage,
} from "../../components/notifications/notificationParser.js";

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
        style={{ whiteSpace: "unset", wordBreak: "break-word" }}
      >
        {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      to={notification.linkTo}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.black", pl: 0.9 }}>
          {notification.avatar}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {notification.createdAt.toString()}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// NotificationsPopover

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessages, setNewMessages] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const records = 5;
  const POLLING_RATE_MS = 300000; // 5 minuter;
  const api = useApi();

  useEffect(() => {
    if (
      hasAccess(accessKeys.courseAdmin) ||
      hasAccess(accessKeys.actorPermit)
    ) {
      setIsLoading(true);
      recalculate();
    }
  }, []);

  useEffect(() => {
    if (
      hasAccess(accessKeys.courseAdmin) ||
      hasAccess(accessKeys.actorPermit)
    ) {
      const interval = setInterval(() => {
        recalculate();
      }, POLLING_RATE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, []);

  function recalculate() {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}notifications/getMessages/${records}`,
        false,
        "GET",
        false,
        true,
        false
      )
      .then((data) => {
        if (!data.error && data?.messages) {
          setMessages(
            data.messages.filter((message) => message.hasBeenRead === false)
          );
          setNewMessages(data.newMessages);
          setIsLoading(false);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  const handleOpen = () => {
    window.analyticsEvent("eport_xtras","eport_notification");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function markAllAsRead() {
    let reqBody = { MessageIds: messages.map((m) => m.id) };
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}notifications/markMessageStatusAsRead`,
        reqBody,
        "POST",
        false,
        true
      )
      .then((data) => {
        if (!data.error) {
          recalculate();
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  function handleOpenAll() {
    setMessages([]);
    setNewMessages(0);
    setOpen(false);
  }

  return (
    <>
      {(hasAccess(accessKeys.courseAdmin) ||
        hasAccess(accessKeys.actorPermit)) && (
        <IconButton
          ref={anchorRef}
          size="large"
          color={open ? "primary" : "default"}
          onClick={handleOpen}
          sx={{
            ...(open && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.focusOpacity
                ),
            }),
          }}
        >
          <Badge badgeContent={newMessages} color="error">
            <Icon icon={bellFill} width={20} height={20} />
          </Badge>
        </IconButton>
      )}

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {Translate.get("Notifications")}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {Translate.get("NewNotifications").replace("{0}", newMessages)}
            </Typography>
          </Box>

          <Tooltip title={Translate.get("Update")}>
            <IconButton color="primary" onClick={recalculate}>
              <RefreshIcon width={20} height={20} />
            </IconButton>
          </Tooltip>

          {newMessages > 0 && (
            <Tooltip title={Translate.get("MarkAllAsRead")}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 500 } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                {Translate.get("New")}
              </ListSubheader>
            }
          >
            {messages.map((row, index) => (
              <NotificationItem
                key={index}
                notification={{
                  id: index,
                  title: null,
                  description: parseMessage(row),
                  avatar: notificationTypeIcon(row.notificationType),
                  type: null,
                  createdAt: formatDate(Date.parse(row.created)),
                  isUnRead: true,
                  linkTo: getRedicrectionLink(row),
                }}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/dashboard/notifications?page=0"
            onClick={handleOpenAll}
          >
            {Translate.get("ShowAll")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
