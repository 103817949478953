import { useState } from "react";
import { Link } from "react-router-dom";
//import { useTheme, styled } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Translate from "../../../utils/Translate";
import YesOrNoDialog from "../../YesOrNoDialog";
import { useApi } from "../../../utils/Api";
import { useSnackbar } from "notistack";

const Comp = ({ data }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDialog, setShowDialog] = useState(false);
  const api = useApi();
  const mailRequext = async () => {
    //Endpoint ska ändras till riktig
    const indata = { licenseType: "Intygsutskift" };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/order`,
      indata
    );

    if (response.isSuccessful) {
      const successMessage = enqueueSnackbar(
        Translate.get("OrderReceivedWillContact"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
    }
    setShowDialog(false);
  };

  return (
    <>
      <YesOrNoDialog
        open={showDialog}
        title={Translate.get("OrderRequest")}
        text={Translate.get("CertificatePrintingRequest")}
        onNo={() => setShowDialog(false)}
        onYes={async () => {
          await mailRequext();
        }}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("SendRequest")}
      />
      <Card>
        <CardHeader
          title={Translate.get("OrderLicense")}
          avatar={<ContactMailIcon />}
        />

        <CardContent sx={{ paddingLeft: "64px" }}>
          <Typography variant="body2" sx={{ marginBottom: "16px" }}>
            {Translate.get("LicenseLostLongText")}
          </Typography>
          <Button
            component={Link}
            to="/dashboard/licenseregistration/"
            variant="outlined"
          >
            {Translate.get("OrderLicense")}{" "}
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
export default Comp;
