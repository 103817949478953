import { useCallback, useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import { useNavigate } from "react-router-dom";
import Translate from "../../utils/Translate";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { isMobile } from "is-mobile";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import {
  paymentProviderIds,
  paymentProviders,
} from "../../utils/paymentProvider";
import { paymentStatuses } from "../../utils/paymentStatus";
import Preloader from "../Preloader";
import ReplayIcon from "@mui/icons-material/Replay";
import { getBrowserTypeForBankId } from "../bankId/bankIdBrowserType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function getInitialStatusMessage(paymentProviderId, isUsingMobile) {
  switch (paymentProviderId) {
    case paymentProviders.swish:
      return isUsingMobile
        ? Translate.get("SwishStartMobileInstruction")
        : Translate.get("SwishStartDesktopInstruction");
    default:
      throw new Error("Invalid payment provider " + paymentProviderId);
  }
}

export default function PaymentDialog({
  orderId,
  paymentId,
  onClose,
  onRestartPayment,
}) {
  const [browserType] = useState(getBrowserTypeForBankId());
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [canRetryPayment, setCanRetryPayment] = useState(null);
  const [qrImage, setQrImage] = useState(null);
  const [showQr, setShowQr] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [isUsingMobile] = useState(
    isMobile({ tablet: true, featureDetect: true })
  );
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const api = useApi();

  const handlePaymentStatusResponse = useCallback(
    (result, intervalId) => {
      setPaymentStatus(result.paymentStatus);
      if (result.paymentStatus == paymentStatuses.waitingForPayment) {
        // Set message and continue polling...
        setStatusMessage((prev) =>
          !!result.messageTranslationKey
            ? Translate.get(result.messageTranslationKey)
            : prev
        );
      } else if (result.paymentStatus == paymentStatuses.canceled) {
        clearInterval(intervalId);
        setStatusMessage(
          Translate.get(
            result.messageTranslationKey ?? "PaymentMessageCancelled"
          )
        );
        setCanRetryPayment(result.canRetryPayment);
      } else if (result.paymentStatus == paymentStatuses.faulted) {
        clearInterval(intervalId);
        setStatusMessage(
          Translate.get(result.messageTranslationKey ?? "PaymentMessageFaulted")
        );
        setCanRetryPayment(result.canRetryPayment);
      } else if (result.paymentStatus == paymentStatuses.paid) {
        clearInterval(intervalId);
        navigate(`/d/confirmation/${orderId}`);
      } else {
        // Shouldn't happen
        clearInterval(intervalId);
        setPaymentStatus(-1);
        setCanRetryPayment(false);
        setStatusMessage(
          Translate.get(
            result.messageTranslationKey ?? "SomethingFailedContactSupport"
          )
        );
      }
    },
    [orderId, navigate]
  );

  const startPollingStatus = useCallback(
    (orderId, paymentId) => {
      console.log("startPollingStatus");
      const intervalId = setInterval(() => {
        console.log("startPollingStatus interval");
        api
          .fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/payment/${paymentId}/status`,
            false,
            "GET"
          )
          .then((result) => {
            if (result.isSuccessful) {
              handlePaymentStatusResponse(result, intervalId);
            } else {
              clearInterval(intervalId);
              setPaymentStatus(-1);
              setCanRetryPayment(false);
              setStatusMessage(
                Translate.get(
                  result.messageTranslationKey ??
                    "SomethingFailedContactSupport"
                )
              );
            }
          });
      }, 3000);
      return intervalId;
    },
    [api, handlePaymentStatusResponse]
  );

  useEffect(() => {
    console.log("Load payment");
    let pollingIntervalId;
    api
      .fetch(
        `${
          process.env.REACT_APP_MAIN_URL
        }orders/${orderId}/payment/${paymentId}?includeQr=${true}&browserType=${browserType}`,
        false,
        "GET"
      )
      .then((response) => {
        if (response.isSuccessful) {
          setPaymentProvider(response.paymentProviderId);
          setStatusMessage(
            getInitialStatusMessage(response.paymentProviderId, isUsingMobile)
          );
          handlePaymentStatusResponse(response.status, null);
          setPaymentUrl(response.paymentUrl);
          setShowQr(!isUsingMobile);
          setQrImage(response.qrImage);
          pollingIntervalId = startPollingStatus(orderId, paymentId);
        }
        setIsLoading(false);
      });

    return () => {
      // Note that pollingIntervalId will be set asynchronously in the then() above
      if (pollingIntervalId) {
        clearInterval(pollingIntervalId);
      }
    };
  }, [
    api,
    orderId,
    paymentId,
    browserType,
    isUsingMobile,
    startPollingStatus,
    handlePaymentStatusResponse,
  ]);

  function handleRestartPayment() {
    setIsLoading(true);
    onRestartPayment().then(() => {
      setPaymentStatus(null);
      setStatusMessage(null);
      setPaymentProvider(null);
      setCanRetryPayment(null);
      setPaymentUrl(null);
      setQrImage(null);
      setShowQr(false);
      setIsLoading(false);
    });
  }

  function openPaymentUrl() {
    // TODO just nu ingen hantering om man inte har swish. Prövade lösningarna här men funkade ej:
    // https://stackoverflow.com/questions/24571548/javascript-how-to-detect-if-the-custom-url-scheme-is-available-or-not-availabl
    // Kanske behöver vi lägga till en knapp "Använd Swish på annan enhet"?
    window.location = paymentUrl;
  }

  function handleShowQRMobile() {
    setStatusMessage(Translate.get("ScanSwishQR"));
    setShowQr(true);
  }

  function handleHideQRMobile() {
    setStatusMessage(getInitialStatusMessage(paymentProvider, isUsingMobile));
    setShowQr(false);
  }

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <Dialog open={true} scroll="paper" onClose={onClose}>
        <DialogContent>
          <Stack direction="column" alignItems="center" spacing={5} padding={2}>
            <Box maxWidth="300px">{statusMessage}</Box>
            {(paymentStatus == paymentStatuses.canceled ||
              paymentStatus == paymentStatuses.faulted) && (
              <Stack width="100%" spacing={2}>
                {canRetryPayment && (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleRestartPayment}
                    endIcon={<ReplayIcon />}
                  >
                    {Translate.get("Retry")}
                  </Button>
                )}
                <Button fullWidth variant="contained" onClick={onClose}>
                  {Translate.get("Cancel")}
                </Button>
              </Stack>
            )}
            {paymentStatus == paymentStatuses.waitingForPayment &&
              paymentProvider == paymentProviders.swish && (
                <Stack width="100%" spacing={isUsingMobile && showQr ? 5 : 2}>
                  {isUsingMobile && !showQr && paymentUrl && (
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={openPaymentUrl}
                    >
                      {Translate.get("OpenSwish")}
                    </Button>
                  )}
                  {isUsingMobile && !showQr && qrImage && (
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleShowQRMobile}
                    >
                      {Translate.get("ShowSwishQr")}
                    </Button>
                  )}
                  {showQr && qrImage && <img src={qrImage} />}
                  {isUsingMobile && showQr && (
                    <Button
                      fullWidth
                      onClick={handleHideQRMobile}
                      startIcon={<ArrowBackIcon />}
                    >
                      {Translate.get("Back")}
                    </Button>
                  )}
                </Stack>
              )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
