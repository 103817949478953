import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import TextField from "@mui/material/TextField";
//import Autocomplete from '@mui/material/Autocomplete';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from "../../utils/dateTimeFormat";
import Translate from "../../utils/Translate";
import PersonCrud from "../actors/crud/PersonCrud";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ActorAutocompleteOption from "../ActorAutocompleteOption";

import SchoolIcon from "@mui/icons-material/School";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlaceIcon from "@mui/icons-material/Place";
import NotesIcon from "@mui/icons-material/Notes";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import { actorSearch, actorPersonTypes } from "../../utils/actorSearch";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { ListItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { formatCurrency } from "../../utils/formatNumber";

import Dialog from "@mui/material/Dialog";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import AddClassRoom from "../settings/AddClassRoom";
import { PriceCheck } from "@mui/icons-material";

const defaultCourse = {
  courseId: 0,
  courseTemplateId: null,
  shortComment: null,
  fromDate: Date.now(),
  toDate: Date.now(),
  courseCityId: null,
  actorId: null,
  courseFreeText: null,
  courseCityName: null,
};
const filter = createFilterOptions();
const noDups = (objArray, key) => {
  return [...new Map(objArray.map((item) => [item[key], item])).values()];
};

const Comp = ({ courseId, freeRoll }) => {
  //console.log('c dialog freeRoll', freeRoll)
  //----------Finns alltid med
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const smallScreen = useMediaQuery("(max-width:700px)");
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //----------

  const [cities, setCities] = useState([]);
  const [courseTemplates, setCourseTemplates] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [startDate, setStartDate] = useState(defaultCourse.fromDate);
  const [startTime, setStartTime] = useState(new Date(null, null, null, 8, 0));
  const [endTime, setEndTime] = useState(new Date(null, null, null, 16, 0));
  const [endDate, setEndDate] = useState(defaultCourse.toDate);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [isCourseInitialized, setIsCourseInitialized] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCourseLocationForm, setShowCourseLocationForm] = useState(false);
  const [noOfDays, setNoOfDays] = useState(0);
  const [teacherName, setTeacherName] = useState("");
  const [saveActionLabel, setSaveActionLabel] = useState("");
  const [formData, setFormData] = useState({});
  const [loading, isLoading] = useState(false);
  const [courseLocations, setCourseLocations] = useState([]);
  const [selectedActorAddressId, setSelectedActorAddressId] = useState("");
  const [advancedExpanded, setAdvancedExpanded] = useState(false);
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    async function initializeCourse() {
      const course =
        courseId > 0
          ? await api.fetch(
              `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/`,
              false,
              "GET"
            )
          : defaultCourse;

      if (course) {
        setFormData({
          courseId: courseId,
          courseTemplateId: course.courseTemplateId,
          shortComment: course.shortComment,
          startDate: course.fromDate,
          endDate: course.toDate,
          courseCityId: course.courseCityId,
          actorId: course.responsibleTeacherActorId,
          courseFreeText: course.courseFreeText,
          courseCityName: null,
        });

        setStartDate(course.fromDate);
        setEndDate(course.toDate);

        setRevenue(course.revenue);

        if (courseId > 0) {
          setStartTime(new Date(course.fromDate));
          setEndTime(new Date(course.toDate));
          setSelectedActorAddressId(course.actorAddressId);
        }
        setIsCourseInitialized(true);
      }

      setSaveActionLabel(
        courseId > 0 ? Translate.get("Save") : Translate.get("CreateNewCourse")
      );
    }

    async function loadDialogData() {
      const [
        loadedCities,
        loadedCourseTemplates,
        loadedTeachers,
        loadedCourseLocations,
      ] = await Promise.all([
        api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/cities/grouped`,
          false,
          "GET"
        ),
        api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/templates/grouped`,
          false,
          "GET"
        ),
        api.fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/teachers/grouped`,
          false,
          "GET"
        ),
        api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/courselocations/grouped`,
          false,
          "GET"
        ),
      ]);

      if (
        loadedCities &&
        loadedCourseTemplates &&
        loadedTeachers &&
        loadedCourseLocations
      ) {
        // skapa en lista som är lätt att gruppera m.h.a. "group" i autocomplete genom att lägga ihop "latest" och "all"
        const latestTranslation = Translate.get("LatestCourseTemplates");
        const allTranslation = Translate.get("AllCourseTemplates");
        const transformedCourseTemplates = loadedCourseTemplates.latest
          .map((x) => ({ group: latestTranslation, courseTemplate: x }))
          .concat(
            loadedCourseTemplates.all.map((x) => ({
              group: allTranslation,
              courseTemplate: x,
            }))
          );

        const latestCities = noDups(loadedCities.latest, "description").map(
          (c) => ({ ...c, group: Translate.get("Latest") })
        );
        const otherCities = noDups(loadedCities.others, "description").map(
          (c) => ({ ...c, group: Translate.get("Others") })
        );
        const groupedCities = latestCities.concat(otherCities);

        const latestTeachers = noDups(loadedTeachers.latest, "actorName").map(
          (a) => ({ ...a, group: Translate.get("Latest") })
        );
        const otherTeachers = noDups(loadedTeachers.others, "actorName").map(
          (a) => ({ ...a, group: Translate.get("Others") })
        );
        const groupedTeachers = latestTeachers.concat(otherTeachers);

        const latestLocations = loadedCourseLocations.latest.map((a) => ({
          ...a,
          group: Translate.get("Latest"),
        }));
        const otherLocations = loadedCourseLocations.others.map((a) => ({
          ...a,
          group: Translate.get("Others"),
        }));
        const groupedLocations = latestLocations.concat(otherLocations);

        setCities(groupedCities);
        setCourseTemplates(transformedCourseTemplates);
        setTeachers(groupedTeachers);
        setCourseLocations(groupedLocations);
        setDataFetched(true);
      }
    }
    loadDialogData();
    initializeCourse();
  }, [courseId, api]);

  useEffect(() => {
    if (
      isCourseInitialized &&
      formData.actorId &&
      teachers &&
      teachers.length > 0
    ) {
      const selectedTeacher = teachers.find(
        (t) => t.actorId === formData.actorId
      );
      setTeacherName(selectedTeacher?.actorName);
    }
  }, [isCourseInitialized, teachers, formData]);

  useEffect(() => {
    const checkFields = [
      formData.courseTemplateId,
      formData.startDate,
      formData.endDate,
      formData.actorId,
    ];
    const hasMissingFields = checkFields.some(
      (el) => el === false || el === undefined || el === null || el === ""
    );
    setSubmitDisabled(hasMissingFields);
  }, [formData]);

  const compareInstructors = useCallback((lhs, rhs) => {
    const lhsIsInstructor = lhs.actorTypes.some((a) => a === 6);
    const rhsIsInstructor = rhs.actorTypes.some((a) => a === 6);
    if (lhsIsInstructor && !rhsIsInstructor) {
      return -1;
    } else if (rhsIsInstructor && !lhsIsInstructor) {
      return 1;
    } else {
      return 0;
    }
  }, []);

  const actorNameSearch = useCallback(
    async (searchText) => {
      var foundActors = await actorSearch(
        searchText,
        actorPersonTypes,
        ["ActorName"],
        50
      );
      if (foundActors) {
        return foundActors.sort(compareInstructors).map((actor) => ({
          actorId: actor.actorId,
          actorName: actor.actorName,
          orgNo: actor.orgNo,
          email: actor.email,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId > 0 &&
                x.parentActor.actorId !== actor.actorId &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
      }
    },
    [compareInstructors]
  );

  const saveCourse = () => {
    //console.log('save course freeRoll', freeRoll)
    isLoading(true);

    let startDateLoc = new Date(startDate);
    let endDateLoc = new Date(endDate);
    if (startTime && endTime) {
      let startHour = startTime.getHours();
      let startMinute = startTime.getMinutes();
      startDateLoc.setHours(startHour);
      startDateLoc.setMinutes(startMinute);
      startDateLoc.setSeconds(0, 0);

      let endHour = endTime.getHours();
      let endMinute = endTime.getMinutes();
      endDateLoc.setHours(endHour);
      endDateLoc.setMinutes(endMinute);
      endDateLoc.setSeconds(0, 0);
    }

    const dataOut = {
      courseId: formData.courseId,
      courseTemplateId: formData.courseTemplateId,
      responsibleTeacherActorId: formData.actorId,
      courseCityName: formData.courseCityName,
      courseCityId: formData.courseCityId,
      fromDate: startDateLoc,
      toDate: endDateLoc,
      shortComment: formData.shortComment,
      courseFreeText: formData.courseFreeText,
      actorAddressId: selectedActorAddressId,
    };
    //return false;
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}courses/`, dataOut)
      .then((data) => {
        if (data) {
          if (data.isSuccessful) {
            if (courseId) {
              isLoading(false);
              closeAndReload();
            } else {
              isLoading(false);
              if (freeRoll && data.course.eLearningType) {
                api
                  .fetch(
                    `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${data.course.eLearningType}/order/free-sample`,
                    false,
                    "GET"
                  )
                  .then((ret) => {
                    if (ret.isSuccessful) {
                      const successMessage = enqueueSnackbar(
                        Translate.get("FreeTryAdded"),
                        {
                          variant: "success",
                          autoHideDuration: null,
                          onClick: () => closeSnackbar(successMessage),
                        }
                      );
                      navigate(`/dashboard/course/${data.course.courseId}`, {
                        replace: false,
                      });
                    } else {
                      closeAndReload();
                    }
                  });
              } else {
                //closeAndReload(); //Går vi inte vidare här?
                navigate(`/dashboard/course/${data.course.courseId}`, {
                  replace: false,
                });
              }
            }
          }
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log(formData);
    saveCourse();
  };

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  function selectNewTeacher(teacher) {
    updateFormData("actorId", teacher.actorId);
    setTeacherName(teacher.actorName);
    setTeachers([...teachers, teacher]);
  }

  function selectExistingActorAsTeacher(actor) {
    if (!actor.actorId) throw new Error();
    // First add the teacher connection
    api.fetch(`${process.env.REACT_APP_MAIN_URL}actors/teachers/`, actor);
    selectNewTeacher(actor);
  }

  function handleActorNameChange(actor) {
    const isExistingActor = actor && actor.actorId > 0;
    const isExistingTeacher =
      isExistingActor && teachers.some((t) => t.actorId === actor.actorId);
    if (isExistingTeacher) {
      setTeacherName(actor.actorName);
      updateFormData("actorId", actor.actorId);
    } else if (isExistingActor) {
      selectExistingActorAsTeacher(actor);
    } else if (actor) {
      setTeacherName(actor?.actorName);
      setShowForm(true);
      updateFormData("actorId", actor.actorId);
    } else {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      clearInstructor();
    }
  }

  function clearInstructor() {
    setTeacherName(null);
    updateFormData("actorId", null);
  }

  function handleCourseLocationChange(courseLocation) {
    if (courseLocation?.actorAddressId < 0) {
      setShowCourseLocationForm(true);
    } else {
      setSelectedActorAddressId(courseLocation?.actorAddressId ?? "");
    }
  }

  function onCourseLocationAdded(actorAddress) {
    let cls = courseLocations;
    cls.push(actorAddress);
    setCourseLocations(cls);

    setSelectedActorAddressId(actorAddress.actorAddressId);
  }

  function calculateEndDate(startDate, courseDays) {
    let endDate = addDays(startDate, courseDays);
    return endDate;
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function fillInTemplateDefaults(courseTemplate) {
    let ct = courseTemplate.courseTemplate;
    if (!ct) {
      return;
    }

    if (ct.instructorActorId) {
      updateFormData("actorId", ct.instructorActorId);
    }

    if (ct.courseCityId) {
      updateFormData("courseCityId", ct.courseCityId);
    }

    if (ct.noDays) {
      let endDate = calculateEndDate(startDate, ct.noDays - 1);
      setEndDate(endDate);
      updateFormData("endDate", endDate);
    }

    if (ct.startTime) {
      setStartTime(toDateWithOutTimeZone(ct.startTime));
    }

    if (ct.endTime) {
      setEndTime(toDateWithOutTimeZone(ct.endTime));
    }

    if (ct.actorAddressId) {
      setSelectedActorAddressId(ct.actorAddressId);
    }
  }

  function toDateWithOutTimeZone(date) {
    if (!date) {
      return date;
    }

    let tempTime = date.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  return (
    <>
      {(!dataFetched || !isCourseInitialized) && (
        <>
          <Preloader />{" "}
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {showForm && (
        <PersonCrud
          name={teacherName}
          onClose={() => setShowForm(false)}
          onCancel={clearInstructor}
          onActorSaved={selectNewTeacher}
          actorRole="teacher"
          hideHeader
        />
      )}

      {showCourseLocationForm && (
        <AddClassRoom
          onClose={() => setShowCourseLocationForm(false)}
          onCancel={() => setShowCourseLocationForm(false)}
          onCourseLocationCreated={onCourseLocationAdded}
          action="add"
        ></AddClassRoom>
      )}

      {dataFetched && !showForm && !showCourseLocationForm && (
        <>
          {/* Allt innehåll */}
          <DialogContent dividers={true} className="stdDialogContent">
            {/* <FormControl fullWidth margin="normal" size='medium' fullWidth={true} onSubmit={handleSubmit}> */}
            <form onSubmit={handleSubmit} className="stdForm">
              <div>
                <SchoolIcon />
                <Autocomplete
                  disabled={!!courseId}
                  fullWidth
                  value={
                    courseTemplates.find(
                      (ob) =>
                        ob.courseTemplate.courseTemplateId ===
                        formData.courseTemplateId
                    ) || null
                  }
                  options={courseTemplates}
                  groupBy={(x) => x.group}
                  getOptionLabel={(option) =>
                    option.courseTemplate.courseShortName
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <div className="twoRowOptions">
                        <div>{option.courseTemplate.courseShortName}</div>
                        <div>{option.courseTemplate.description}</div>
                      </div>
                    </Box>
                  )}
                  onChange={(event, value) => {
                    fillInTemplateDefaults(value);
                    updateFormData(
                      "courseTemplateId",
                      value ? value.courseTemplate.courseTemplateId : null
                    );
                    if (value) {
                      const noDays = value.courseTemplate.noDays
                        ? value.courseTemplate.noDays - 1
                        : 0;
                      setNoOfDays(noDays);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={Translate.get("CourseType")}
                    />
                  )}
                  //disablePortal
                />
              </div>
              <div>
                <DriveFileRenameOutlineSharpIcon />
                <TextField
                  fullWidth
                  //defaultValue={formData.shortComment}
                  value={formData.shortComment || ""}
                  onChange={(event, value) =>
                    updateFormData("shortComment", event.target.value)
                  }
                  label={Translate.get("CourseNameShort")}
                  inputProps={{ maxLength: 30 }}
                />
              </div>
              <div>
                <CalendarTodayIcon />
                <div className="stdFlexSB">
                  <DatePicker
                    inputFormat={getCurrentInputFormat()}
                    mask={getCurrentDateMask()}
                    label={Translate.get("StartDate")}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      const newEndDate = calculateEndDate(newValue, noOfDays);
                      setEndDate(newEndDate);
                      updateFormData("endDate", newEndDate);
                      updateFormData("startDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                      />
                    )}
                  />
                  <DatePicker
                    inputFormat={getCurrentInputFormat()}
                    mask={getCurrentDateMask()}
                    label={Translate.get("EndDate")}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      updateFormData("endDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <PlaceIcon />
                <Autocomplete
                  fullWidth
                  value={
                    formData.courseCityId > 0
                      ? cities.find(
                          (ob) => ob.courseCityId === formData.courseCityId
                        ) || null
                      : formData.courseCityName
                      ? { description: formData.courseCityName }
                      : null
                  }
                  options={cities}
                  groupBy={(x) => x.group}
                  getOptionLabel={(option) => option.description ?? "NoName"}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderOption={(props, option) => (
                    <ListItem
                      sx={
                        option.inputValue
                          ? {
                              fontWeight: "bold",
                              backgroundColor: " rgb(0, 110, 200, 0.2)",
                            }
                          : {}
                      }
                      key={option.courseCityId}
                      {...props}
                    >
                      {option.description}
                    </ListItem>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      if (value.inputValue) {
                        value.description = value.inputValue;
                        updateFormData("courseCityId", 0);
                        updateFormData("courseCityName", value.inputValue);
                        //getRow1();
                        //return false;
                      } else {
                        updateFormData("courseCityId", value.courseCityId);
                      }
                    } else {
                      updateFormData("courseCityId", null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={Translate.get("City")} />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    //const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => params.inputValue === option.description
                    );
                    if (params.inputValue !== "" && !isExisting) {
                      //filtered.push({ tycker sist funkar bättre
                      filtered.unshift({
                        inputValue: params.inputValue,
                        description: `${Translate.get("Add")} "${
                          params.inputValue
                        }"`,
                      });
                    }

                    return filtered;
                  }}
                  //disablePortal
                />
              </div>
              <div>
                <CoPresentIcon />
                <AutocompleteSearchField
                  placeholder={Translate.get("Instructor")}
                  label={Translate.get("Instructor")}
                  value={{ actorName: teacherName ?? "" }}
                  onValueChange={handleActorNameChange}
                  defaultOptions={teachers}
                  groupBy={(x) => x.group}
                  getOptionLabel={(option) => option?.actorName ?? ""}
                  renderOption={(option) => (
                    <ActorAutocompleteOption
                      actorName={option?.actorName}
                      orgNo={option?.orgNo}
                      email={option?.email}
                      companies={option?.companies}
                    />
                  )}
                  keyPropName="actorId"
                  requireSelection={true}
                  search={actorNameSearch}
                  createNewValue={(text) => ({
                    actorName: text,
                  })}
                  textFieldProps={{ required: true }}
                  hideOptionsFilter={
                    formData.actorId > 0 &&
                    ((option) => option.actorId === formData.actorId)
                  }
                />
              </div>
              <div>
                <NotesIcon />
                <TextField
                  multiline
                  fullWidth
                  value={formData.courseFreeText || ""}
                  onChange={(event, value) =>
                    updateFormData("courseFreeText", event.target.value)
                  }
                  label={Translate.get("Notes")}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              {hasAccess(accessKeys.advancedPackage) && (
                <div>
                  <Accordion
                    className="noPaperMargin"
                    expanded={advancedExpanded}
                    onChange={(_) => {
                      setAdvancedExpanded(expanded => !expanded);
                    }}
                    sx={{ padding: 0, width: "100%" }}
                  >
                    <AccordionSummary
                      sx={{ padding: 0 }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="smallHeader">
                        {Translate.get("Advanced")}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <div className="stdForm">
                        <div>
                          <div className="stdFlexBetween timePickerWrap">
                            {!smallScreen && <AccessTimeIcon />}
                            <div style={{ flexGrow: 1 }}>
                              <TimePicker
                                name="startTime"
                                label={Translate.get("StartTime")}
                                value={startTime}
                                inputFormat="HH:mm"
                                onChange={(newValue) => setStartTime(newValue)}
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </div>
                            <div style={{ flexGrow: 1 }}>
                              <TimePicker
                                name="endTime"
                                label={Translate.get("EndTime")}
                                value={endTime}
                                inputFormat="HH:mm"
                                onChange={(newValue) => setEndTime(newValue)}
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <MeetingRoomIcon />
                          <Autocomplete
                            fullWidth
                            value={
                              courseLocations.find(
                                (ob) =>
                                  ob.actorAddressId === selectedActorAddressId
                              ) || null
                            }
                            options={courseLocations}
                            groupBy={(x) => x.group}
                            getOptionLabel={(option) =>
                              option.actorAddressStreet1
                            }
                            onChange={(event, value) =>
                              handleCourseLocationChange(value)
                            }
                            renderOption={(props, option) => (
                              <ListItem
                                sx={
                                  option.inputValue
                                    ? {
                                        fontWeight: "bold",
                                        backgroundColor:
                                          "rgb(0, 110, 200, 0.2)",
                                      }
                                    : {}
                                }
                                {...props}
                                key={option.actorAddressId}
                              >
                                {option.actorAddressStreet1}
                              </ListItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={Translate.get("ClassRoom")}
                              />
                            )}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) =>
                                  params.inputValue ===
                                  option.actorAddressStreet1
                              );

                              if (params.inputValue !== "" && !isExisting) {
                                filtered.unshift({
                                  inputValue: params.inputValue,
                                  actorAddressId: -1,
                                  actorAddressStreet1: Translate.get(
                                    "AddNewCourseLocation"
                                  ),
                                });
                              }

                              return filtered;
                            }}
                          />
                        </div>

                        {courseId > 0 && (
                          <div>
                            <PriceCheck />
                            <TextField
                              disabled={true}
                              value={formatCurrency(revenue) + " kr"}
                              fullWidth={true}
                            ></TextField>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </form>
            {/* </FormControl> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.history.back()}>
              {Translate.get("Cancel")}
            </Button>
            <LoadingButton
              className="stdLightLoader"
              loading={loading}
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              {saveActionLabel}
            </LoadingButton>
          </DialogActions>
          {/* Allt innehåll */}
        </>
      )}
    </>
  );
};
export default Comp;
