import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Card, CardHeader } from "@mui/material";
import Translate from "../../../utils/Translate";
import DotMenu from "../../DotMenu";
import Grid from "@mui/material/Grid";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useState } from "react";
import RecallLicensesDialog from "../../actors/dialogs/RecallLicensesDialog";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 355;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
}));

// ----------------------------------------------------------------------
const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

const Comp = ({ data, onOrder, label, getAsClient, showRecallDotMenu }) => {
  const [showRecallDialog, setShowRecallDialog] = useState(false);
  const [maxRecallQty, setMaxRecallQty] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [eLearningType, setELearningType] = useState(null);

  function getDotMenuActions(row) {
    return [
      {
        icon: <KeyboardReturnIcon />,
        text: Translate.get("Recall"),
        onClick: () => {
          setShowRecallDialog(true);
          setMaxRecallQty(row.value);
          setDataId(row.externalDataId);
          setELearningType(row.eLearningType);
        },
      },
    ];
  }

  return (
    <div>
      {showRecallDialog && (
        <RecallLicensesDialog
          setShowRecallLicenseDialog={setShowRecallDialog}
          setMaxRecallQty={setMaxRecallQty}
          maxRecallQty={maxRecallQty}
          dataId={dataId}
          eLearningType={eLearningType}
          setDataId={setDataId}
          setELearningType={setELearningType}
          getAsClient={getAsClient}
        />
      )}

      <Card>
        <CardHeader title={Translate.get("LicenseBalance")} />
        <ChartWrapperStyle className="passwordBalance" dir="ltr">
          <div>
            {data.map((row, index) => {
              if (row.color) {
                let color = row.color;
                row.color = hexToRGB(color, 0.87);
                row.bgcolor = hexToRGB(color, 0.1);
              } else {
                let color = Math.floor(Math.random() * 256);
                row.color = `rgba(${color}, 15, 11, 0.87)`;
                row.bgcolor = `rgba(${color}, 15, 11, 0.1)`;
              }

              return (
                <div key={index}>
                  <div
                    style={{ color: row.color, backgroundColor: row.bgcolor }}
                  >
                    <Grid container>
                      <Grid item xs={8}></Grid>
                      <Grid item xs={4}>
                        {(showRecallDotMenu === true && getDotMenuActions) && (
                          <DotMenu
                            content={getDotMenuActions(row)}
                            vertical
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={showRecallDotMenu === true ? { transform: "translateY(-0.7em)" } : {}}
                      >
                        {row.value}
                      </Grid>
                    </Grid>
                  </div>
                  {row.name}
                </div>
              );
            })}
          </div>

          <div>
          {/* Override default behavior */}
          {onOrder && label ? (
            <Button 
            onClick={onOrder} 
            variant="outlined"
            >
              {label}
            </Button>
          ) : (
            <Button
              component={Link}
              to="/dashboard/licenses/"
              variant="outlined"
            >
              {Translate.get("OrderLicenses")}
            </Button>
          )}
          </div>
        </ChartWrapperStyle>
      </Card>
    </div>
  );
};
export default Comp;
