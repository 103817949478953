import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useMemo } from "react";
import Translate from "../../../utils/Translate";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import ImportActorsDataGrid from "./ImportActorsDataGrid";

export default function ImportActorsSummaryDialog({
  open,
  processedActors,
  onOk,
  onCancel,
}) {
  const actorsToAdd = useMemo(
    () => processedActors.filter((a) => a.importDetails.action === "add"),
    [processedActors]
  );

  const actorsToUpdate = useMemo(
    () => processedActors.filter((a) => a.importDetails.action === "update"),
    [processedActors]
  );

  const actorsToSkip = useMemo(
    () => processedActors.filter((a) => a.importDetails.action === "skip"),
    [processedActors]
  );

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={false}>
      <DialogTitle>{Translate.get("VerifyImport")}</DialogTitle>
      <DialogContent>
        {actorsToAdd && actorsToAdd.length > 0 && (
          <ImportActorsDataGrid
            actors={actorsToAdd}
            description={Translate.get("ImportAsNewStudents")}
          />
        )}
        {actorsToUpdate && actorsToUpdate.length > 0 && (
          <ImportActorsDataGrid
            actors={actorsToUpdate}
            description={Translate.get("ImportAsUpdateStudents")}
          />
        )}
        {actorsToSkip && actorsToSkip.length > 0 && (
          <ImportActorsDataGrid
            actors={actorsToSkip}
            description={Translate.get("CannotImportStudents")}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={onOk}
          disabled={actorsToAdd.length === 0 && actorsToUpdate.length === 0}
        >
          {Translate.get("StartImport")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
