import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const CourseInformation = () => {
  const api = useApi();
  const [text, setText] = useState('');
   const [submitting, setSubmitting] = useState(false);
   const [loaded, setLoaded] = useState(false);
  let getDefults = false;

  if (!loaded) {
    //Api call här med props.actorId som input
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/course/otherinformation`,
      );

      setLoaded(true);
      setText(data.settingValue);
      
    })();
  }
  const submit = () => {

    //console.log('text', text);
    (async () => {
            const data ={};
            data.settingValue=text;
            const ret = await api.fetch(
              `${process.env.REACT_APP_MAIN_URL}dataIdSettings/course/otherinformation`, data
            );
            if (ret.isSuccessful ===false){
                //console.log('error', ret.errorMessageTranslationKey);
            }
          })();
  };
  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <TextField
            //autoFocus={true}
            multiline
            rows={6}
            fullWidth
            value={text}
            label={Translate.get("TextInCourseSummoning")}
            inputProps={{ onBlur: () => {
                submit();
            } }}
            onChange={(event) => {
              //row.textNote = event.target.value;
              //row.crudState = 'update';
              //setData([...data]);
              setText(event.target.value);
            }}
           
          
          />
      {/*     <div className='stdFlexRow' style={{marginTop:'8px',marginBottom:'0px'}}>
            <div></div>
          <LoadingButton
            className="stdLightLoader"
            loading={submitting}
            onClick={submit}
          >
            {Translate.get('Save')}
          </LoadingButton>
          </div> */}
        </FormControl>
      </Box>
    </>
  );
};
export default CourseInformation;
