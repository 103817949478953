import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import EPortAvatar from "../EPortAvatar";

export default function UserProfilePictureCard({ url, name, actorId, onImageChange }) {
  const [profileUrl, setProfileUrl] = useState();
  const api = useApi();

  useEffect(
    () => setProfileUrl(url),
    [url]);

  const onImageUploaded = async (imageResponse) => {
    //debugger;
    if(imageResponse?.imageId) {
      await api.fetch(`${process.env.REACT_APP_IMAGE_SERVICE_URL}UploadProfileImageDatabase`, {imageId: imageResponse.imageId})
    }
    onImageChange();
  };

  const onDeleteImage = async () => {
    await api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}DeleteActorImageDatabase`,
      { actorId: actorId }
    );
    setProfileUrl(null);
  };

  return (
    <Card
      sx={{
        maxWidth: { md: "438px" },
        paddingBottom: "10px",
      }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h4">
              {Translate.get("ProfilePicture")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <EPortAvatar avatarWidth={128} avatarHeight={128} showBorder={true}
                displayName={name} pictureUrl={profileUrl} onImageUploaded={onImageUploaded} onDeleteImage={onDeleteImage} imageType={"profile"} />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {Translate.get("AllowedFileFormats")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {Translate.get("FileSize")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
