import React, { useEffect, useState } from 'react';
import { useApi } from '../../utils/Api';
import Paper from '@mui/material/Paper';
import Preloader from '../Preloader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Translate from "../../utils/Translate";
import { useMediaQuery } from "@mui/material";
import { createCookie, getCookie } from './Common';

export function ChooseCourseBooking({
  setActorId,
  setMode,
  setCourseBookingId,
  selfRegSettings}) {

  const [isLoading, setIsLoading] = useState(false);
  const [courseBookings, setCourseBookings] = useState(null);
  const [selectedCb, setSelectedCb] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dataFetched, setDataFetched] = useState(true);

  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  const api = useApi();

  const smallScreen = useMediaQuery("(max-width:900px)");

  useEffect(async () => {
      setDataFetched(false);
      const lang = Translate.getBrowserLanguage();
      Translate.loadSelfReg(lang).then((data) => {
        setDataFetched(true);
      });

      // Hämta data från kakor
      let token = getCookie("selfRegToken");
      setToken(token);

      let id = getCookie("selfRegId");
      setId(id);

      let cbs = await getCourseBookings(token, id);
      setCourseBookings(cbs);

      if (cbs?.length === 1) {
        setCourseBookingId(cbs[0].courseBookingId);
        setSelectedCb(cbs[0].courseBookingId);
        reserveSeat(token, id, cbs[0].courseBookingId);
      }

  }, []);

  async function getCourseBookings(qrToken, id) {
    if (id == null) {return}
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/coursebookings/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        qrToken
      );
      if (response.isSuccessful) {
        return response.courseBookings;
      }
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function reserveSeat(token, id, courseBookingId) {
    setIsLoading(true);
  
    let reqObj = {
      id: id,
      courseBookingId: courseBookingId
    };
  
    try {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/reserve`,
        reqObj,
        'POST',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        token
      );
  
      if (response.isSuccessful) {
        setActorId(response.actorId);
        createCookie("selfRegReservationToken", response.reservationToken, 10);
  
        redirect();
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  function handleChange(event) {
    setSelectedCb(event.target.value);
    setCourseBookingId(event.target.value);
    setErrorMessage("");
  }

  function redirect() {
    if (selfRegSettings.bankIdAuthenticate) {
      setMode("BankId");
    } else {
      setMode("Form");
    }
  }

  return (
    <>
    {(!dataFetched || isLoading) && <Preloader />}
    <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', padding: '1em', paddingLeft: 0.1, paddingRight: 0.1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', minWidth: "95%" }}
      >
        <Grid item xs={3}>

          <Paper elevation={3} sx={{ p: 8, width: smallScreen ? "95vw" : 600}}>
            <img src="/static/instructor_logo.png" alt="ePort-logotype" className="maxWidthImageSelf" />

            <p>{Translate.get("ChooseBooking")}</p>

            <Select
              value={selectedCb}
              onChange={handleChange}
              sx={{ minWidth: '100%', maxWidth: '100%', mt: '1em' }}
            >
              {courseBookings?.map(item => (
                <MenuItem value={item.courseBookingId} key={item.courseBookingId}>{item.name}</MenuItem>
              ))}
            </Select>
            {errorMessage !== "" && <Alert severity="error" sx={{ mt: 1, minWidth: '100%', maxWidth: '100%' }}>{Translate.get(errorMessage)}</Alert>}

            <aside className='MuiCallout-root MuiCallout-warning'> {Translate.get("CompletedWithinSession")}</aside>

            <br/>
            <Button variant='outlined' disabled={selectedCb === ""} onClick={() => reserveSeat(token, id, selectedCb)} sx={{ mt: 1 }}>{Translate.get("Continue")}</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
    </>
  );
}
