import Backdrop from '@mui/material/Backdrop';
/* import CircularProgress from '@mui/material/CircularProgress'; */
import Box from '@mui/material/Box';
import { useState} from 'react';

export default function Preloader({ zIndexOverride, invisible=false}) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop className='urban'
        sx={{ color: '#fff', zIndex: (theme) => zIndexOverride ? zIndexOverride : theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
        invisible={invisible}
      >
        {/* <CircularProgress color="inherit" /> */}
        {<Box component="img" src="/static/Spinner_ePort.svg" sx={{ width: 112, height: 112}} />}
      </Backdrop>
    </div>
  );
}
