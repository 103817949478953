import { Grid } from "@mui/material";

export default function SkillItemGrid({ items }) {
  return (
    <Grid container spacing={2} sx={{width: {xs: "311px", sm: "564px"}}}>
      {items.map((item, index) => (
        <Grid item key={index} xs={6} sm={4}>
          {item}
        </Grid>
      ))}
    </Grid>
  );
}
