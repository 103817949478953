import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState, useMemo, useCallback, useEffect } from "react";
import DotMenu from "../DotMenu";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { dataGridLang } from "../MemToolBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListAltIcon from "@mui/icons-material/ListAlt";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DomainIcon from "@mui/icons-material/Domain";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import AgreementForm from "./AgreementForm";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import { useNavigate } from "react-router-dom";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import AgreementHistoryDialog from "./AgreementHistoryDialog";
// import AgreementHistory from "./AgreementHistory";

export default function AgreementsGrid({
  companyActorId,
  personActorId,
  agreementId,
  onLoaded,
}) {
  if (
    [companyActorId, personActorId, agreementId].filter((id) => !!id).length > 1
  ) {
    // companyActorId for a company's agreements, personActorId for a person's agreements, agreementId for an agreement's history
    throw new Error("Invalid props");
  }

  const [agreements, setAgreements] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const localizedTextsMap = dataGridLang();
  const api = useApi();

  const breakpoint = 800;
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(breakpoint));
  const gridClassName = isBigScreen ? "stdVisible dgDynIconText" : "dgSmall";

  useEffect(() => {
    async function loadAgreements(companyActorId, personActorId, agreementId) {
      const url = !!companyActorId
        ? `${process.env.REACT_APP_MAIN_URL}actors/companies/${companyActorId}/agreements`
        : !!personActorId
        ? `${process.env.REACT_APP_MAIN_URL}actors/people/${personActorId}/agreements`
        : `${process.env.REACT_APP_MAIN_URL}agreements/${agreementId}/history`;

      const response = await api.fetchWithOverride(
        url,
        false,
        "GET",
        (response) => Array.isArray(response)
      );

      if (Array.isArray(response)) {
        setAgreements(
          !!companyActorId || !!personActorId
            ? response.map((a, i) => ({ ...a, rowId: a.agreementId }))
            : response.map((a, i) => ({ ...a, rowId: i }))
        );
      }

      if (onLoaded) {
        onLoaded();
      }
    }
    loadAgreements(companyActorId, personActorId, agreementId);
  }, [companyActorId, personActorId, agreementId, api, onLoaded]);

  const getDotMenuActions = useCallback((companyActorId, agreementId) => {
    const menudata = [];
    menudata.push({
      icon: <EditIcon />,
      text: Translate.get("EditServiceAgreement"),
      customClass: "mediumCourseDialog",
      component: (
        <AgreementForm
          companyActorId={companyActorId}
          agreementId={agreementId}
        />
      ),
    });
    menudata.push({
      icon: <HistoryToggleOffIcon />,
      text: Translate.get("ServiceAgreementHistory"),
      dialogHeader: Translate.get("ServiceAgreementHistory"),
      customClass: "bigCourseDialog",
      component: <AgreementHistoryDialog agreementId={agreementId} />,
    });
    return menudata;
  }, []);

  const columns = useMemo(
    () => [
      ...(!!companyActorId || !!personActorId
        ? [
            {
              field: "description",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("ServiceAgreementsTitle"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={CollectionsBookmarkIcon}
                  label={Translate.get("ServiceAgreementsTitle")}
                />
              ),
            },
          ]
        : []),
      ...(!!personActorId
        ? [
            {
              field: "actorCompanyName",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Company"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={DomainIcon}
                  label={Translate.get("Company")}
                />
              ),
            },
          ]
        : []),
      ...(!!companyActorId || !!agreementId
        ? [
            {
              field: "actorReceiverName",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("User"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={PersonIcon}
                  label={Translate.get("User")}
                />
              ),
            },
          ]
        : []),
      {
        field: "orderNo",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("OrderNumber"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={FormatListNumberedIcon}
            label={Translate.get("OrderNumber")}
          />
        ),
      },
      {
        field: "customerOrderNo",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PurchaseOrderNo"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ListAltIcon}
            label={Translate.get("PurchaseOrderNo")}
          />
        ),
      },
      {
        field: "endDate",
        type: "date",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("ExpirationDate"),
        valueGetter: (params) => (params.value ? new Date(params.value) : ""),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={EventBusyIcon}
            label={Translate.get("ExpirationDate")}
          />
        ),
        renderCell: (params) => (params.value ? formatDate(params.value) : ""),
      },
      {
        field: "terminateDate",
        type: "date",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("TerminateDateLabel"),
        valueGetter: (params) => (params.value ? new Date(params.value) : ""),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={DoNotDisturbIcon}
            label={Translate.get("TerminateDateLabel")}
          />
        ),
        renderCell: (params) => (params.value ? formatDate(params.value) : ""),
      },
      ...(!!companyActorId || !!personActorId
        ? [
            {
              field: "dotMenu",
              flex: 0.3,
              headerName: "",
              sortable: false,
              disableColumnMenu: true,
              filterable: false,
              hideable: false,
              headerClassName: "hideSeparator",
              align: "right",
              renderCell: (params) => (
                <DotMenu
                  content={getDotMenuActions(
                    companyActorId ?? params.row.actorId,
                    params.row.agreementId
                  )}
                  horizontal
                />
              ),
            },
          ]
        : []),
    ],
    [getDotMenuActions, companyActorId, personActorId, agreementId, isBigScreen]
  );

  return (
    <DataGrid
    initialState={{
      sorting: {
        sortModel: [{ field: 'terminateDate', sort: 'asc' }],
      },
    }}
      className={gridClassName}
      rows={agreements}
      columns={columns}
      disableSelectionOnClick
      getRowId={(row) => row.rowId}
      autoHeight={true}
      components={{
        Toolbar: GridToolbar,
        NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
      }}
      componentsProps={{
        columnsPanel: { className: "customColumnPanel" },
        filterPanel: { className: "customfilterPanel" },
        panel: { className: "customPanel" },
      }}
      localeText={localizedTextsMap}
      pageSizeOptions={[10, 20, 30]}
      disableDensitySelector={true}
      disableColumnMenu
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
}
