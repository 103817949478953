import { Box, Grid, Card } from "@mui/material";
import ActorAvatar from "../ActorAvatar";
import DotMenu from "../DotMenu";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import SchoolIcon from "@mui/icons-material/School";
import { formatDateRange, formatDate } from "../../utils/dateTimeFormat";
import { TableAvatar } from "../../utils/dataGrid";
import { renderPermitStatus } from "./utils/permitStatus";
import { LoadingButton } from "@mui/lab";
import BuildIcon from "@mui/icons-material/Build";
import PreviewIcon from "@mui/icons-material/Preview";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Tooltip from "@mui/material/Tooltip";
import Translate from "../../utils/Translate";
import ConstructionIcon from '@mui/icons-material/Construction';

export default function PermitCard(permit) {
  const {
    actorId,
    actorName,
    actorEmail,
    actorPictureUrl,
    extraActions,
    machineGroup,
    validFrom,
    validTo,
    responsibleActorName,
    responsibleActorEmail,
    cardView,
    machineTypes,
    reviewDate,
    reviewIsDue,
    reviewer,
  } = permit;

  return (
    <Card
      sx={{ padding: "16px", marginBottom: "16px", transform: "translateZ(0)" }}
    >
      {!cardView && (
        <ActorAvatar
          name={actorName}
          email={actorEmail}
          profilePictureUrl={actorPictureUrl}
          link={"/dashboard/person/" + actorId}
        />
      )}
      <Box sx={{ position: "fixed", top: "18px", right: "16px" }}>
        <DotMenu content={extraActions} horizontal />
      </Box>
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{ padding: "30px 0px 16px 18px" }}
      >
        <Grid item container alignItems="center">
          <ConstructionIcon />
          <Box marginLeft="19px">{machineGroup}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <BuildIcon />
          <Box marginLeft="19px">{machineTypes}</Box>
        </Grid>

        <Grid item container alignItems="center">
          <DateRangeIcon />
          <Box marginLeft="19px">{formatDateRange(validFrom, validTo)}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <AccountBoxIcon />
          <Box marginLeft="19px">
            <TableAvatar
              hideImage
              boldTitle={false}
              title={responsibleActorName}
              subtitle={[responsibleActorEmail ?? ""]}
            />
          </Box>
        </Grid>

        <Grid item container alignItems="center">
          <Tooltip title={Translate.get("Review")}>
            <PreviewIcon />
          </Tooltip>
          <Box marginLeft="19px">
            {reviewer}{" "}
            {reviewIsDue ? (
              <Tooltip title={Translate.get("ReviewNeeded")}><PendingActionsIcon style={{ transform: "translateY(0.15em)" }} /></Tooltip>
            ) : (
              ""
            )}
            <br />
            {reviewDate != null ? formatDate(reviewDate) : ""}
          </Box>
        </Grid>

        <Grid item container alignItems="center">
          <CheckCircleIcon />
          <Box marginLeft="19px" alignItems="center" display="flex">
            {renderPermitStatus(permit, "lower")}
          </Box>
        </Grid>
        <Grid container justifyContent="flex-end">
          <LoadingButton
            className="regularButtonWithIcon stdLightLoader"
            variant="outlined"
            sx={{ width: 80 }}
            loading={permit.actorPermitId === permit.loading}
            onClick={(event) => {
              event.stopPropagation();
              if (permit.blobFile === null) {
                permit.handleDownloadActorPermit(
                  permit.actorPermitId,
                  permit.actorName ?? actorName
                );
              } else {
                permit.downloadCustomPermit(permit.blobFile.blobDocumentId);
              }
            }}
          >
            <DownloadIcon />
          </LoadingButton>
        </Grid>
      </Grid>
    </Card>
  );
}
