import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';
import { LoadingButton } from '@mui/lab';
import { useApi } from '../../../utils/Api';
import Translate from '../../../utils/Translate';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const [mailFound, setmailFound] = useState(false);
  const api = useApi();
  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string()
      .email(Translate.get('InvalidEmail'))
      .required(Translate.get('EmailRequired')),
  });
  const formik = useFormik({
    initialValues: {
      /* firstName: '',
      lastName: '', 
      email: '', */
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const req = {
        username: formik.values.email,
      };
      //console.log('onSubmit',req);
      api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/password/request-reset/`,
        req,'POST',false,false
      ).then((data) => {
        //console.log('DATA', data);
        if (data === '') {
          //Svarar med nolllängssträng när email hittats.
          //navigate('/dashboard', { replace: true });
          setmailFound('ok');
        } else {
          setmailFound('failed');
        }
        formik.setSubmitting(false);
      });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {/*  <Snackbar open={true} autoHideDuration={3000} message="Updated" /> */}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="text" // email
            label={Translate.get('Email')} // Email address
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {Translate.get('SendLink')}
          </LoadingButton>
          {mailFound === 'failed' && (
            <Alert severity="error">{Translate.get('EmailNotFound')}</Alert>
          )}
          {mailFound === 'ok' && (
            <Alert severity="success">{Translate.get('LinkSent')}</Alert>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
