import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState, useRef, useCallback } from "react";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import CardPrintAtMaSystemDialog from "./CardPrintAtMaSystemDialog";
import YesOrNoDialog from "../YesOrNoDialog";
import { useReactToPrint } from "react-to-print";
import "./ActorLicensePrint.css";
import ActorLicensePrintView from "./ActorLicensePrintView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActorLicensePrintList from "./ActorLicensePrintList";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import Bowser from "bowser";
import { licenseFormatTypeEnum } from "../../utils/licenseFormatTypes";
import { validateDigitalLicenseReceiver } from "../actor-permits/validation/ActorValidator";

const cr80CardWidth = 2048;
const cr80cardHeight = 1300;
const a4Width = 2480;
const a4Height = 3508;

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.isBrowser("Safari");

function getPrintTypeLabel(printType) {
  switch (printType) {
    case "card":
      return Translate.get("Licence");
    case "A4":
      return Translate.get("A4Document");
    case "diploma":
      return Translate.get("Diploma");
  }
}

function getCardDataUrl(courseId, actorLicenseId, includeAllSkills, printType) {
  const previewDigitalLicense = hasAccess(accessKeys.digitalLicenses);
  if (actorLicenseId) {
    return includeAllSkills
      ? `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/actorlicenses/${actorLicenseId}/all-skills?previewDigitalLicense=${previewDigitalLicense}`
      : `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/actorlicenses/${actorLicenseId}?previewDigitalLicense=${previewDigitalLicense}`;
  } else {
    return includeAllSkills
      ? `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/courses/${courseId}/all-skills?previewDigitalLicense=${previewDigitalLicense}`
      : `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/courses/${courseId}?previewDigitalLicense=${previewDigitalLicense}`;
  }
}

async function loadActorLicensePreviews(
  api,
  courseId,
  actorLicenseId,
  includeAllSkills,
  printType
) {
  const url = getCardDataUrl(
    courseId,
    actorLicenseId,
    includeAllSkills,
    printType
  );
  return await api.fetch(url, false, "GET");
}

function getAvailablePrintTypesUrl(courseId, actorLicenseId) {
  if (actorLicenseId) {
    return `${process.env.REACT_APP_MAIN_URL}licenseprint/actorlicenses/${actorLicenseId}/print-types`;
  } else {
    return `${process.env.REACT_APP_MAIN_URL}licenseprint/courses/${courseId}/print-types`;
  }
}

async function loadAAvailablePrintTypes(api, courseId, actorLicenseId) {
  const url = getAvailablePrintTypesUrl(courseId, actorLicenseId);
  return await api.fetch(url, false, "GET");
}

async function actorLicensesHaveAllLatestSkills(api, courseId, actorLicenseId) {
  const url = courseId
    ? `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/licenses/has-latest-skills`
    : `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/${actorLicenseId}/has-latest-skills`;
  return await api.fetch(url, false, "GET");
}

async function markAsPrinted(api, actorLicenseIds) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}licenseprint/actorlicenses/mark-as-printed`,
    actorLicenseIds,
    "POST"
  );
}

async function updateCourseCoordinatorName(
  api,
  actorLicenseIds,
  courseCoordinatorName
) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/course-coordinator`,
    { actorLicenseIds, courseCoordinatorName },
    "POST"
  );
}

function loadLicenseFormatTypes() {
  const licenseFormatTypes = localStorage.getItem("licenseFormatTypes");
  return licenseFormatTypes ? JSON.parse(licenseFormatTypes) : {};
}

function saveLicenseFormatType(printType, licenseFormatType) {
  const cachedLicenseFormatTypes = loadLicenseFormatTypes();
  const updatedLicenseFormatTypes = {
    ...cachedLicenseFormatTypes,
    [printType]: licenseFormatType,
  };
  localStorage.setItem(
    "licenseFormatTypes",
    JSON.stringify(updatedLicenseFormatTypes)
  );
}

function getDefaultLicenseFormatType(printType) {
  if (!hasAccess(accessKeys.digitalLicenses)) {
    return licenseFormatTypeEnum.physical;
  }

  const cachedLicenseFormatTypes = loadLicenseFormatTypes();
  return (
    cachedLicenseFormatTypes[printType] ??
    (printType === "card"
      ? licenseFormatTypeEnum.digital
      : licenseFormatTypeEnum.physical)
  );
}

// This dialog can be used either for a courseId (cards for the participants),
// or for an actorLicenseId (printing a single license separately from a course),
export default function ActorLicensePrintDialog({
  courseId,
  actorLicenseId,
  onClose,
}) {
  if (courseId && actorLicenseId) throw new Error("Invalid props");
  if (!courseId && !actorLicenseId) throw new Error("Invalid props");

  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const [studentCardsDetails, setStudentCardsDetails] = useState(null);
  const [digitalLicensePreviews, setDigitalLicensePreviews] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [availablePrintTypes, setAvailablePrintTypes] = useState([]);
  const [licenseFormatType, setLicenseFormatType] = useState(
    licenseFormatTypeEnum.physical
  );
  const [cardPrintTemplate, setCardPrintTemplate] = useState(null);
  const [studentDiplomasDetails, setStudentDiplomasDetails] = useState(null);
  const [diplomaPrintTemplate, setDiplomaPrintTemplate] = useState(null);
  const [studentA4sDetails, setStudentA4sDetails] = useState(null);
  const [A4PrintTemplate, setA4PrintTemplate] = useState(null);
  const [includeAllLatestSkills, setIncludeAllLatestSkills] = useState(null);
  const [showPrintAtMaSystem, setShowPrintAtMaSystem] = useState(false);
  const [selectedActorLicenseIds, setSelectedActorLicenseIds] = useState(null);
  const [
    showIncludeAllLatestSkillsQuestion,
    setShowIncludeAllLatestSkillsQuestion,
  ] = useState(false);
  const [sidePrintMode, setSidePrintMode] = useState("both");
  const [printType, setPrintType] = useState(null);
  const [isPrintingOnOwnPrinterEnabled, setIsPrintingOnOwnPrinterEnabled] =
    useState(false);
  const [printWidth, setPrintWidth] = useState(cr80CardWidth);
  const [printHeight, setPrintHeight] = useState(cr80cardHeight);
  const [newCourseCoordinatorName, setNewCourseCoordinatorName] =
    useState(null);
  const [currentCourseCoordinatorName, setCurrentCourseCoordinatorName] =
    useState(null);
  const [printSideSettings, setPrintSideSettings] = useState([]);

  const [showCourseCoordinatorError, setShowCourseCoordinatorError] =
    useState(false);
  const [courseCoordinatorHelperText, setCourseCoordinatorHelperText] =
    useState("");
  const [doValidate, setDoValidate] = useState(false);

  const api = useApi();

  const printViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    onAfterPrint: () => markAsPrinted(api, selectedActorLicenseIds),
    // I set the width and height of the page explicitly, and then do the same for the license to be printed.
    // This is because I couldn't get the license to size correctly by simply setting width and height 100%.
    // But it seems like it should be possible to make it completely dynamic using onBeforePrint and useRef.
    pageStyle: () =>
      !isSafari
        ? `
    @page {
      margin: 0 !important;
      size: ${printWidth}px ${printHeight}px;
    }
  `
        : `
    @page {
      margin: 0 !important;
      size: ${printWidth}px ${printHeight}px;
    }

    @media print {
      html,
      body {
        transform: scale(0.54);
        translate: -160px -240px;
      }
    }
  `,
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const getSelectedPrintTemplate = useCallback(() => {
    if (printType === "card") {
      return cardPrintTemplate;
    } else if (printType === "diploma") {
      return diplomaPrintTemplate;
    } else if (printType === "A4") {
      return A4PrintTemplate;
    }
  }, [printType, cardPrintTemplate, diplomaPrintTemplate, A4PrintTemplate]);

  const getStudentDetails = useCallback(
    (courseBookingId) => {
      let studentDetails;
      if (printType === "card") {
        studentDetails = studentCardsDetails;
      } else if (printType === "diploma") {
        studentDetails = studentDiplomasDetails;
      } else if (printType === "A4") {
        studentDetails = studentA4sDetails;
      } else {
        throw new Error();
      }

      studentDetails = !!courseBookingId
        ? studentDetails.filter((s) => s.courseBookingId === courseBookingId)
        : studentDetails;

      studentDetails.sort((a, b) => a.actorName.localeCompare(b.actorName));
      return studentDetails;
    },
    [printType, studentCardsDetails, studentDiplomasDetails, studentA4sDetails]
  );

  function handleStudentChanged(actor) {
    //We need to update in card, diploma and A4, since each one will contain the same
    // student's details, loaded separately with the specific printType
    setStudentCardsDetails((prev) => {
      if (!prev) {
        return prev;
      }
      const index = prev.findIndex((p) => p.actorId === actor.actorId);
      return index !== -1
        ? prev.with(index, {
            ...prev[index],
            actorName: actor.actorName,
            actorEmail: actor.actorEmail,
            phone: actor.mobilePhone?.actorPhoneNumber,
            personalNo: actor.orgNo,
          })
        : prev;
    });
    setStudentDiplomasDetails((prev) => {
      if (!prev) {
        return prev;
      }
      const index = prev.findIndex((p) => p.actorId === actor.actorId);
      return index !== -1
        ? prev.with(index, {
            ...prev[index],
            actorName: actor.actorName,
            actorEmail: actor.actorEmail,
            phone: actor.mobilePhone?.actorPhoneNumber,
            personalNo: actor.orgNo,
          })
        : prev;
    });
    setStudentA4sDetails((prev) => {
      if (!prev) {
        return prev;
      }
      const index = prev.findIndex((p) => p.actorId === actor.actorId);
      return index !== -1
        ? prev.with(index, {
            ...prev[index],
            actorName: actor.actorName,
            actorEmail: actor.actorEmail,
            phone: actor.mobilePhone?.actorPhoneNumber,
            personalNo: actor.orgNo,
          })
        : prev;
    });
    setDoValidate(true);
  }

  function handleContactChanged(courseBookingId, name, email) {
    // Note that the contact might be someone who is not in the DB and is not an Actor.
    // We only use the courseBooking contact for default values. But if these are changed
    // we no longer know if we are working with the same person, so we clear the ActorId.
    // These details will not be updated on the CourseBooking in the DB, but only sent
    // with the order request for digital licenses. This contact information is only
    // used for digital licenses at the time of writing.
    setBookings((prev) => {
      const index = prev.findIndex(
        (p) => p.courseBookingId === courseBookingId
      );
      return index !== -1
        ? prev.with(index, {
            ...prev[index],
            contactActorId: null,
            contactName: name,
            contactEmail: email,
          })
        : prev;
    });
  }

  function handleSendPublishGroupContactEmailChange(courseBookingId) {
    setBookings((prev) => {
      const index = prev.findIndex(
        (p) => p.courseBookingId === courseBookingId
      );
      return index !== -1
        ? prev.with(index, {
            ...prev[index],
            sendPublishGroupContactEmail:
              !prev[index].sendPublishGroupContactEmail,
          })
        : prev;
    });
  }

  useEffect(() => {
    setIsLoadingCount((prevCount) => prevCount + 1);
    actorLicensesHaveAllLatestSkills(api, courseId, actorLicenseId).then(
      (response) => {
        if (response === true) {
          // No need to include all if licenses already have all by default
          setIncludeAllLatestSkills(false);
          setShowIncludeAllLatestSkillsQuestion(false);
        } else {
          setShowIncludeAllLatestSkillsQuestion(true);
        }
        setIsLoadingCount((prevCount) => prevCount - 1);
      }
    );
  }, [courseId, actorLicenseId, api]);

  const handleLicenseFormatTypeChange = useCallback(
    (licenseFormatTypeProp, printTypeProp) => {
      setLicenseFormatType(licenseFormatTypeProp);
      saveLicenseFormatType(printTypeProp, licenseFormatTypeProp);

      if (
        printTypeProp === "card" &&
        licenseFormatTypeProp === licenseFormatTypeEnum.digital &&
        studentCardsDetails &&
        studentCardsDetails.length > 0
      ) {
        // Here we deselect the ones who already have digital licenses
        const alreadyHaveDigitalLicenses = studentCardsDetails
          .filter((x) => x.digitalLicenseId)
          .map((x) => x.actorLicenseId);
        setSelectedActorLicenseIds((prev) =>
          prev
            ? prev.filter((id) => !alreadyHaveDigitalLicenses.includes(id))
            : []
        );
      }
      setDoValidate(true);
    },
    [studentCardsDetails]
  );

  useEffect(() => {
    if (availablePrintTypes && availablePrintTypes.length > 0) {
      return;
    }
    setIsLoadingCount((prevCount) => prevCount + 1);
    loadAAvailablePrintTypes(api, courseId, actorLicenseId).then((response) => {
      if (Array.isArray(response)) {
        const sortOrder = ["card", "diploma", "A4"];
        response.sort(
          (a, b) =>
            sortOrder.findIndex((x) => x === a) -
            sortOrder.findIndex((x) => x === b)
        );
        setAvailablePrintTypes(response);
        const printType = response.includes("card") ? "card" : response[0];
        setPrintType(printType);
        setPrintWidth(printType === "card" ? cr80CardWidth : a4Width);
        setPrintHeight(printType === "card" ? cr80cardHeight : a4Height);
        handleLicenseFormatTypeChange(
          getDefaultLicenseFormatType(printType),
          printType
        );
        setDoValidate(true);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
    });
  }, [
    courseId,
    actorLicenseId,
    api,
    handleLicenseFormatTypeChange,
    availablePrintTypes,
  ]);

  useEffect(() => {
    const selectedPrintTemplate = getSelectedPrintTemplate();
    if (
      !selectedPrintTemplate &&
      includeAllLatestSkills !== null &&
      !!printType &&
      availablePrintTypes.length > 0
    ) {
      setIsLoadingCount((prevCount) => prevCount + 1);
      loadActorLicensePreviews(
        api,
        courseId,
        actorLicenseId,
        includeAllLatestSkills,
        printType
      ).then((response) => {
        if (printType === "card") {
          setStudentCardsDetails(response.studentsPrintData);
          setDigitalLicensePreviews(response.digitalLicensePreviews);
          setCardPrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            { printType: "card", printSideSetting: response.printSideSetting },
          ]);
        } else if (printType === "diploma") {
          setStudentDiplomasDetails(response.studentsPrintData);
          setDiplomaPrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            {
              printType: "diploma",
              printSideSetting: response.printSideSetting,
            },
          ]);
        } else if (printType === "A4") {
          setStudentA4sDetails(response.studentsPrintData);
          setA4PrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            { printType: "A4", printSideSetting: response.printSideSetting },
          ]);
        }
        setIsPrintingOnOwnPrinterEnabled(
          response.isPrintingOnOwnPrinterEnabled
        );
        setSelectedActorLicenseIds(
          licenseFormatType === licenseFormatTypeEnum.digital
            ? response.studentsPrintData
                .filter((p) => !p.digitalLicenseId) // Filter out the ones who already have digital licenses
                .map((p) => p.actorLicenseId)
            : response.studentsPrintData.map((p) => p.actorLicenseId)
        );
        setBookings(
          response.bookings
            ? response.bookings.map((b) => ({
                ...b,
                contactName: b.contactActorName,
                contactEmail: b.contactActorEmail,
              }))
            : []
        );
        setNewCourseCoordinatorName(null);
        setCurrentCourseCoordinatorName(response.courseCoordinatorName);
        setSidePrintMode(response.printSideSetting ?? "both");
        setIsLoadingCount((prevCount) => prevCount - 1);
        setDoValidate(true);
      });
    }
  }, [
    includeAllLatestSkills,
    courseId,
    actorLicenseId,
    printType,
    licenseFormatType,
    getSelectedPrintTemplate,
    api,
  ]);

  // Validate studentCardDetails
  useEffect(() => {
    if (
      !doValidate ||
      !studentCardsDetails ||
      !licenseFormatType ||
      !printType ||
      printType != "card"
    ) {
      return;
    }

    setDoValidate(false);
    const needsValidation =
      licenseFormatType === licenseFormatTypeEnum.digital ||
      licenseFormatType === licenseFormatTypeEnum.both;

    if (needsValidation) {
      const validatedStudentCardDetails = studentCardsDetails.map((scd) => ({
        ...scd,
        error: validateDigitalLicenseReceiver(
          scd.actorEmail,
          scd.phone,
          scd.personalNo
        ),
      }));
      setStudentCardsDetails(validatedStudentCardDetails);

      const canSendActorLicenseIds = validatedStudentCardDetails
        .filter((v) => !v.error)
        .map((v) => v.actorLicenseId);
      setSelectedActorLicenseIds((prev) =>
        prev ? prev.filter((id) => canSendActorLicenseIds.includes(id)) : []
      );
    } else {
      setStudentCardsDetails((prev) =>
        prev.map((p) => ({ ...p, error: false }))
      );
    }
  }, [doValidate, studentCardsDetails, printType, licenseFormatType]);

  function handleIncludeAllSkillsChange(value) {
    setIncludeAllLatestSkills(value);
    setShowIncludeAllLatestSkillsQuestion(false);
  }

  function handleSelectStudentCardChanged(actorLicenseId) {
    if (selectedActorLicenseIds.find((c) => c === actorLicenseId)) {
      setSelectedActorLicenseIds(
        selectedActorLicenseIds.filter((c) => c !== actorLicenseId)
      );
    } else {
      setSelectedActorLicenseIds([...selectedActorLicenseIds, actorLicenseId]);
    }
  }

  function handlePrintAtMaSystemClose(isPrintDone) {
    setShowPrintAtMaSystem(false);
    if (isPrintDone) {
      // Close this dialog too in that case
      handleClose(isPrintDone);
    }
  }

  function handleClose(isPrintDone) {
    setIncludeAllLatestSkills(null);
    setShowPrintAtMaSystem(false);
    setShowIncludeAllLatestSkillsQuestion(false);
    const includesDigitalLicenses =
      licenseFormatType === licenseFormatTypeEnum.digital ||
      licenseFormatType === licenseFormatTypeEnum.both;
    onClose(isPrintDone, includesDigitalLicenses);
  }

  function handlePrintTypeChange(event) {
    const newPrintType = event.target.value;
    setPrintType(newPrintType);
    setPrintWidth(newPrintType === "card" ? cr80CardWidth : a4Width);
    setPrintHeight(newPrintType === "card" ? cr80cardHeight : a4Height);
    handleLicenseFormatTypeChange(
      getDefaultLicenseFormatType(newPrintType),
      newPrintType
    );
    setDoValidate(true);

    let printSideMode = printSideSettings.find((obj) => {
      return obj.printType === newPrintType;
    });
    setSidePrintMode(printSideMode?.printSideSetting ?? "both");
  }

  async function handleChangeCourseCoordinator() {
    if (newCourseCoordinatorName.length < 1) {
      setShowCourseCoordinatorError(true);
      setCourseCoordinatorHelperText(Translate.get("NotEmpty"));
      return;
    }

    setShowCourseCoordinatorError(false);
    setCourseCoordinatorHelperText("");

    if (newCourseCoordinatorName !== currentCourseCoordinatorName) {
      const actorLicenseIds = getStudentDetails().map((s) => s.actorLicenseId);
      const response = await updateCourseCoordinatorName(
        api,
        actorLicenseIds,
        newCourseCoordinatorName
      );
      if (response && response.isSuccessful) {
        // Set all of these to null because it will trigger a reload of data,
        // and also because data for other printType will then be reloaded
        setStudentCardsDetails(null);
        setCardPrintTemplate(null);
        setStudentDiplomasDetails(null);
        setDiplomaPrintTemplate(null);
        setStudentA4sDetails(null);
        setA4PrintTemplate(null);
      }
    }
  }

  async function handlePrintSideSettingChange(printMode) {
    let req = { printType: printType, sideSetting: printMode };

    setSidePrintMode(printMode);

    let settings = [...printSideSettings];
    let index = settings.map((e) => e.printType).indexOf(printType);
    if (index >= 0) {
      settings[index] = { printType: printType, printSideSetting: printMode };
      setPrintSideSettings(settings);
    }

    return await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenseprint/printSideSetting`,
      req,
      "POST"
    );
  }

  function getCourseActorLicensePublishGroups() {
    const students = getStudentDetails();
    const activeCourseBookingIds = students
      .filter((s) => selectedActorLicenseIds.includes(s.actorLicenseId))
      .map((s) => s.courseBookingId);
    return bookings
      .filter((b) => activeCourseBookingIds.includes(b.courseBookingId))
      .map((b) => ({
        contactName: b.sendPublishGroupContactEmail ? b.contactName : null,
        contactEmail: b.sendPublishGroupContactEmail ? b.contactEmail : null,
        courseBookingId: b.courseBookingId,
        actorLicenseIds: students
          .filter(
            (s) =>
              s.courseBookingId === b.courseBookingId &&
              selectedActorLicenseIds.includes(s.actorLicenseId)
          )
          .map((s) => s.actorLicenseId),
      }));
  }

  function getSingleActorLicensePublishGroups() {
    return [
      {
        contactName: null, // TODO
        contactEmail: null, // TODO
        courseBookingId: null,
        actorLicenseIds: [actorLicenseId],
      },
    ];
  }

  function getExtraRequestData() {
    const publishGroups = courseId
      ? getCourseActorLicensePublishGroups()
      : getSingleActorLicensePublishGroups();

    return {
      courseId: courseId,
      includeAllLicenseGroupSkills: includeAllLatestSkills,
      actorLicenseIds: selectedActorLicenseIds,
      publishGroups: publishGroups,
    };
  }

  const showDialogContent =
    !showIncludeAllLatestSkillsQuestion &&
    isLoadingCount === 0 &&
    !!getSelectedPrintTemplate() &&
    !!getStudentDetails() &&
    availablePrintTypes.length > 0;

  const selectedTypeIsMissing =
    getSelectedPrintTemplate()?.textBoxes.length <= 0 ?? false;
  //console.log("printType", printType);

  console.log("studentCardDetails", studentCardsDetails);
  console.log("selectedActorLicenseIds", selectedActorLicenseIds);
  return (
    <>
      {isLoadingCount > 0 && <Preloader zIndexOverride={2000} />}
      <YesOrNoDialog
        open={showIncludeAllLatestSkillsQuestion}
        title={Translate.get("SelectSkillsToPrint")}
        text={
          !!courseId
            ? Translate.get("PrintLicensesWhichSkills")
            : Translate.get("PrintLicenseWhichSkills")
        }
        onNo={() => handleIncludeAllSkillsChange(false)}
        onYes={() => handleIncludeAllSkillsChange(true)}
        onCancel={() => handleClose(false)}
        noText={Translate.get("PrintLicenseSpecifiedSkills")}
        yesText={Translate.get("PrintLicenseLatestSkills")}
      />
      {showPrintAtMaSystem && (
        <CardPrintAtMaSystemDialog
          open={true}
          onClose={handlePrintAtMaSystemClose}
          licenseFormatType={licenseFormatType}
          extraRequestData={getExtraRequestData()}
        />
      )}
      {showDialogContent && (
        <>
          <Dialog
            maxWidth={false}
            onClose={() => handleClose(false, false)}
            open={true}
            PaperProps={{
              sx: {
                maxWidth: "100%",
              },
            }}
          >
            <DialogTitle>{Translate.get("OrderLicense")}</DialogTitle>
            <DialogContent>
              <Grid
                container
                direction={{ xs: "column", md: "row" }}
                wrap="nowrap"
                spacing={4}
                className={"licensePrintDialogGrid" + (isSmall ? " small" : "")}
              >
                <Grid item container md={6} direction="column" spacing={4}>
                  <Grid item>
                    <FormControl fullWidth error={selectedTypeIsMissing}>
                      <FormLabel>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.primary",
                            textTransform: "uppercase",
                            marginBottom: "18px",
                          }}
                        >
                          {Translate.get("Document")}
                        </Typography>
                      </FormLabel>
                      <Select
                        value={printType}
                        onChange={handlePrintTypeChange}
                      >
                        {availablePrintTypes.map((pt) => (
                          <MenuItem key={pt} value={pt}>
                            {getPrintTypeLabel(pt)}
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedTypeIsMissing && (
                        <FormHelperText error sx={{ marginLeft: "12px" }}>
                          {Translate.get("MissingPrintTemplate")}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {hasAccess(accessKeys.digitalLicenses) &&
                    printType === "card" && (
                      <Grid item>
                        <FormControl>
                          <FormLabel id="certtype-radioButton">
                            {Translate.get("LicenseFormatType")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="certtype-radioButton"
                            value={licenseFormatType}
                            //name="radio-buttons-group"
                            onChange={(event) =>
                              handleLicenseFormatTypeChange(
                                parseInt(event.target.value),
                                printType
                              )
                            }
                          >
                            <FormControlLabel
                              value={licenseFormatTypeEnum.digital}
                              control={<Radio />}
                              label={Translate.get("DigitalLicenseShortLabel")}
                            />
                            <FormControlLabel
                              value={licenseFormatTypeEnum.physical}
                              control={<Radio />}
                              label={Translate.get("PhysicalLicenseShortLabel")}
                            />
                            <FormControlLabel
                              value={licenseFormatTypeEnum.both}
                              control={<Radio />}
                              label={Translate.get(
                                "DigitalAndPhysicalLicenseShortLabel"
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                  {(isPrintingOnOwnPrinterEnabled ||
                    printType !== "card" ||
                    hasAccess(accessKeys.admin)) && (
                    <Grid item>
                      <Box>
                        {/* Empty Box just to keep Accordion from moving when expanding */}
                      </Box>
                      <Accordion
                        className="advancedSettingsAccordion"
                        disableGutters
                      >
                        <AccordionSummary
                          sx={{ paddingLeft: 0, paddingRight: "6px" }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.primary",
                              textTransform: "uppercase",
                            }}
                          >
                            {Translate.get("AdvancedSettings")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <Stack
                            direction="column"
                            spacing={4}
                            justifyContent="space-evenly"
                          >
                            {(isPrintingOnOwnPrinterEnabled ||
                              printType !== "card") && (
                              <FormControl>
                                <FormLabel id="side-radio-buttons-group">
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "text.secondary",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {Translate.get("PrintOptions")}
                                  </Typography>
                                </FormLabel>
                                <RadioGroup
                                  sx={{ marginLeft: "12px" }}
                                  name="side-radio-buttons-group"
                                  value={sidePrintMode}
                                  onChange={(event) =>
                                    handlePrintSideSettingChange(
                                      event.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value="both"
                                    control={<Radio />}
                                    label={Translate.get("BothFrontAndBack")}
                                  />
                                  <FormControlLabel
                                    value="front"
                                    control={<Radio />}
                                    label={Translate.get("FrontOnly")}
                                  />
                                  <FormControlLabel
                                    value="back"
                                    control={<Radio />}
                                    label={Translate.get("BackOnly")}
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                            {hasAccess(accessKeys.admin) && (
                              <TextField
                                label={Translate.get("CourseCoordinator")}
                                value={
                                  newCourseCoordinatorName ??
                                  currentCourseCoordinatorName
                                }
                                onChange={(event) =>
                                  setNewCourseCoordinatorName(
                                    event.target.value
                                  )
                                }
                                onBlur={handleChangeCourseCoordinator}
                                error={showCourseCoordinatorError}
                                helperText={courseCoordinatorHelperText}
                              />
                            )}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}
                </Grid>
                <Grid item container md={6} direction="column">
                  <Grid item>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.primary",
                        textTransform: "uppercase",
                        marginBottom: "18px",
                      }}
                    >
                      {Translate.get("PrintList")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ marginBottom: "12px" }}>
                      {printType !== "card"
                        ? Translate.get("LicensePrintInfoPrint")
                        : isPrintingOnOwnPrinterEnabled
                        ? Translate.get("LicensePrintInfoPrintOrOrder")
                        : Translate.get("LicensePrintInfoOrder")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {bookings && bookings.length > 0
                      ? getSelectedPrintTemplate().textBoxes.length > 0 &&
                        bookings.map((booking) => (
                          <ActorLicensePrintList
                            key={booking.courseBookingId}
                            booking={booking}
                            showContact={
                              licenseFormatType ===
                                licenseFormatTypeEnum.digital ||
                              licenseFormatType === licenseFormatTypeEnum.both
                            }
                            licenseFormatType={licenseFormatType}
                            contact={{
                              name: booking.contactActorName,
                              email: booking.contactActorEmail,
                            }}
                            studentDetails={getStudentDetails(
                              booking.courseBookingId
                            )}
                            digitalLicensePreviews={digitalLicensePreviews}
                            selectedActorLicenseIds={selectedActorLicenseIds}
                            onSelect={handleSelectStudentCardChanged}
                            disableSelection={!!actorLicenseId} // Disable selection when working with individual actorLicenses
                            printTemplate={getSelectedPrintTemplate()}
                            sidePrintMode={sidePrintMode}
                            printType={printType}
                            onStudentChange={handleStudentChanged}
                            onContactChange={(contact) =>
                              handleContactChanged(
                                booking.courseBookingId,
                                contact.name,
                                contact.email
                              )
                            }
                            onSendPublishGroupContactEmailChange={() =>
                              handleSendPublishGroupContactEmailChange(
                                booking.courseBookingId
                              )
                            }
                          />
                        ))
                      : getSelectedPrintTemplate().textBoxes.length > 0 && (
                          <ActorLicensePrintList
                            studentDetails={getStudentDetails()}
                            digitalLicensePreviews={digitalLicensePreviews}
                            selectedActorLicenseIds={selectedActorLicenseIds}
                            onSelect={handleSelectStudentCardChanged}
                            disableSelection={!!actorLicenseId} // Disable selection when working with individual actorLicenses
                            printTemplate={getSelectedPrintTemplate()}
                            sidePrintMode={sidePrintMode}
                            printType={printType}
                            showContact={false}
                            licenseFormatType={licenseFormatType}
                            onStudentChange={handleStudentChanged}
                          />
                        )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(false)}>
                {Translate.get("Cancel")}
              </Button>
              {(isPrintingOnOwnPrinterEnabled || printType !== "card") && (
                <Button
                  disabled={
                    !selectedActorLicenseIds ||
                    selectedActorLicenseIds?.length === 0
                  }
                  onClick={handlePrint}
                >
                  {Translate.get("Print")}
                </Button>
              )}
              <Button
                variant="contained"
                disabled={
                  printType !== "card" ||
                  !selectedActorLicenseIds ||
                  selectedActorLicenseIds?.length === 0 ||
                  hasAccess(accessKeys.isMASystem) ||
                  newCourseCoordinatorName?.length < 1 ||
                  currentCourseCoordinatorName?.length < 1
                }
                onClick={() => setShowPrintAtMaSystem(true)}
              >
                {Translate.get("PlaceOrder")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* ActorLicensePrintView is only visible when printing, via media query in ActorLicensePrint.css */}
          <ActorLicensePrintView
            ref={printViewRef}
            printTemplate={getSelectedPrintTemplate()}
            studentCardsDetails={getStudentDetails().filter(
              (s) =>
                !!selectedActorLicenseIds.find((c) => c === s.actorLicenseId)
            )}
            printFront={sidePrintMode === "both" || sidePrintMode === "front"}
            printBack={sidePrintMode === "both" || sidePrintMode === "back"}
            printWidth={printWidth}
            printHeight={printHeight}
            printType={printType}
          />
        </>
      )}
    </>
  );
}
