import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import { isMobile } from "is-mobile";
import { AlertDialog } from "../AlertDialog";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../../components/DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogTitle, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CoPresentIcon from "@mui/icons-material/CoPresent";

export default function PreviewCompetences({ skills, otherSkills, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={false}
      scroll="paper"
      style={{ top: "63px", bottom: "10px" }}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle>
        {Translate.get("EducatedOn")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="dlcCompetenceDialog">
          <div className="iconTextRows dlcBlueBoxes">
            <div className="dlcTitle">
              {Translate.get("ApprovedCompetencies")}
            </div>
            {skills &&
              skills.map((row, index) => (
                <div key={index}>
                  <div>
                    <img src={row.imageUrl} style={{ height: "16px" }} />
                  </div>
                  <div>{row.description}</div>
                </div>
              ))}
          </div>
          <div className="iconTextRows dlcGrayBoxes">
            <div className="dlcTitle">{Translate.get("OtherCompetencies")}</div>
            {otherSkills &&
              otherSkills.map((row, index) => (
                <div key={index}>
                  <div>
                    <img src={row.imageUrl} style={{ height: "16px" }} />
                  </div>
                  <div>{row.description}</div>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
