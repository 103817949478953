import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Translate from "../../utils/Translate";
import Api, { useApi } from "../../utils/Api";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Box, Stack, TextField } from "@mui/material";

const GdprDialog = ({ action, companyName, companyDataId }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  //{onClose, row, action }
  //actorData.actorId
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  const requestDataCopy = useCallback(
    async (email, dataId, message) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}mydata/request-copy`,
        { email, dataId, message },
        "POST"
      );
      if (response.isSuccessful) {
        const successSnackbar = enqueueSnackbar(
          Translate.get("RequestSentWeWillContact"),
          {
            variant: "success",
            autoHideDuration: 10000,
            onClick: () => closeSnackbar(successSnackbar),
          }
        );
        window.history.back();
      }
    },
    [api, enqueueSnackbar, closeSnackbar]
  );

  const requestDataDelete = useCallback(
    async (email, dataId, message) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}mydata/request-delete`,
        { email, dataId, message },
        "POST"
      );
      if (response.isSuccessful) {
        const successSnackbar = enqueueSnackbar(
          Translate.get("RequestSentWeWillContact"),
          {
            variant: "success",
            autoHideDuration: 10000,
            onClick: () => closeSnackbar(successSnackbar),
          }
        );
        window.history.back();
      }
    },
    [api, enqueueSnackbar, closeSnackbar]
  );

  function getDescription() {
    switch (action) {
      case "ask":
        return `${Translate.get("RequestDataTxt")} ${companyName}`;
      case "delete":
        return `${Translate.get("RequestDeleteTxt")} ${companyName}`;
      default:
        throw new Error("getTitle");
    }
  }

  function getButtonLabel() {
    switch (action) {
      case "ask":
        return Translate.get("RequestData");
      case "delete":
        return Translate.get("RequestDelete");
      default:
        throw new Error("getTitle");
    }
  }

  async function handleRequest() {
    switch (action) {
      case "ask":
        return await requestDataCopy(email, companyDataId, message);
      case "delete":
        return await requestDataDelete(email, companyDataId, message);
      default:
        throw new Error("getTitle");
    }
  }

  return (
    <>
      <DialogContent className="stdDialogContent">
        <Stack direction="column" spacing={2}>
          <Box>{getDescription()}</Box>
          <TextField
            label={Translate.get("Email")}
            required
            value={email ?? ""}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <TextField
            multiline
            rows={3}
            label={`${Translate.get("Message")} (${Translate.get("Optional")})`}
            value={message ?? ""}
            onChange={(ev) => setMessage(ev.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner onClick={handleRequest} disabled={!email}>
          {getButtonLabel()}
        </ButtonWithSpinner>
        <Button
          onClick={() => {
            window.history.back();
          }}
        >
          {Translate.get("Cancel")}
        </Button>
      </DialogActions>
    </>
  );
};
export default GdprDialog;
