import { useState, useRef } from 'react';
import Translate from '../../../utils/Translate';
import { useApi } from '../../../utils/Api';
import { useSnackbar } from 'notistack';
import { Avatar, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DotMenu from '../../../components/DotMenu';
import AttachmentIcon from '@mui/icons-material/Attachment';
import YesOrNoDialog from '../../YesOrNoDialog';
import download from 'downloadjs';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

let fileExtension = '';
const acceptfileExtensions = '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.pages,.numbers,.key,.csv,.bmp,.gif,.heic,.jpg,.jpeg,.png,.tiff,.webp,.zip,.7z';

const ManualAuthFileUpload = ({ manualAuthFiles, manualAuthenticationId, isUploadAllowed }) => {
    const [files, setFiles] = useState(manualAuthFiles);
    const [fileName, setFileName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate();

    const fileInputRef = useRef("");

    const closeAndReload = () => {
        const loc = encodeURIComponent(location.pathname);
        navigate(`/redirect/${loc}`, { replace: true });
    };

    const downLoader = (documentId) => {
        (async () => {
            const data = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}courses/participants/file/get/${documentId}`,
            );

            (async () => {
                const res = await fetch(data.sasURI);
                const contentType = res.headers.get('Content-Type');
                const raw = await res.blob();

                download(raw, data.filename, contentType);
            })();
        })();
    };

    const submitForm = (e) => {
        e.preventDefault();
        setSubmitting(true);
        const formData = new FormData();
        formData.append(
            'file',
            e.target.file.files[0],
            e.target.name.value + fileExtension,
        );

        (async () => {
            const ret = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}courses/participants/${manualAuthenticationId}/file/upload`,
                formData,
            );
            if (ret.isSuccessful) {
                enqueueSnackbar(Translate.get('DocumentUploaded'), {
                    variant: 'success',
                    autoHideDuration: 3000,
                });
                setFiles([
                    ...files,
                    {
                        blobDocumentId: ret.blobDocumentId,
                        originalFileName: e.target.name.value + fileExtension,
                    },
                ]);
                setFileName("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            } else {
                // Kommer direkt från API
                // showErrorMessage(ret.errorMessageTranslationKey);
            }
            setSubmitting(false);
        })();
    };

    const showErrorMessage = (errorMessageTranslationKey) => {
        const errorMessage = enqueueSnackbar(
            Translate.get(errorMessageTranslationKey),
            {
                variant: 'error',
                autoHideDuration: 6000,
                onClick: () => closeSnackbar(errorMessage),
            },
        );
    };

    const getFile = (e) => {
        const maxFileSize = 1024 * 1024 * 5;
        const file = e.target.files[0];
        if (file.size > maxFileSize) {
            showErrorMessage('FileIsTooLarge');
            setFileName('');
        } else {
            const lastDot = file.name.lastIndexOf('.');

            const fileNameNoExt = file.name.substring(0, lastDot);
            fileExtension = file.name.substring(lastDot);

            const chkValidExt = acceptfileExtensions.split(',');
            if (chkValidExt.includes(fileExtension)) {
                setFileName(fileNameNoExt);
            } else {
                showErrorMessage('FileTypeNotSupported');
            }
        }
    };

    const UploadList = () => {
        return (
            <div style={{ marginTop: "2em" }}>
                {files.map((cp, index) => (
                    <div key={index} className="stdList">
                        <div className="tableAvatar">
                            <Avatar>
                                <AttachmentIcon />
                            </Avatar>
                            <div>
                                <div className="tableAvatarTitle bold">
                                    {cp.originalFileName}
                                </div>
                            </div>
                        </div>

                        <DotMenu
                            content={[
                                {
                                    icon: <FileDownloadIcon />,
                                    text: Translate.get('Download'),
                                    onClick: () => downLoader(cp.blobDocumentId),
                                },
                            ]}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <YesOrNoDialog
                open={showDeleteQuestion !== false}
                title={Translate.get('DeleteFile')}
                text={Translate.get('Delete') + ' ' + showDeleteQuestion.name + '?'}
                onNo={() => setShowDeleteQuestion(false)}

                noText={Translate.get('Cancel')}
                yesText={Translate.get('Delete')}
            />

            {isUploadAllowed && <>
                <DialogTitle>
                    <div className="stdFlexBetween">
                        <div>{Translate.get('Document')}</div>
                        <Tooltip title={Translate.get('ePort2AllowedFileTypesInfo') + ' ' + acceptfileExtensions}>
                            <IconButton>
                                <InfoIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <form onSubmit={submitForm}>
                    <DialogContent dividers={true} className="stdDialogContent">
                        <div className="topBottom16">
                            {Translate.get('ePort2MaxFileSize')}
                        </div>
                        <UploadList files={files} />
                        <div className="stdFlexBetween">
                            <Button variant="contained" component="label">
                                {Translate.get('SelectFile')}
                                <input
                                    accept={acceptfileExtensions}
                                    onChange={getFile}
                                    type="file"
                                    hidden
                                    name="file"
                                    ref={fileInputRef}
                                />
                            </Button>
                            <TextField
                                style={{ flexGrow: 1 }}
                                type="text"
                                required
                                name="name"
                                label={Translate.get('FileName')}
                                value={fileName}
                                onChange={(event) => {
                                    setFileName(event.target.value);
                                }}
                                disabled={fileInputRef.current.value === ""}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeAndReload()}>
                            {Translate.get('Close')}
                        </Button>
                        <LoadingButton
                            className="stdLightLoader"
                            loading={submitting}
                            type="submit"
                            disabled={fileName === ""}
                        >
                            {Translate.get('Upload')}
                        </LoadingButton>
                    </DialogActions>
                </form>
            </>}

            {!isUploadAllowed && <UploadList files={files} />}
        </>
    );
};
export default ManualAuthFileUpload;
