import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../DotMenu";
import Translate from "../../utils/Translate";
import LicenseRegistrationGradeStudentDialog from "./LicenseRegistrationGradeStudentDialog";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import { TableAvatar } from "../../utils/dataGrid";

export default function LicenseRegistrationStep2CardSmall({
  students,
  visibleCompetences,
  handleStudentAddRemoveCompetence,
  handleAddStudent,
  handleUpdateStudent,
  handleRemoveStudent,
  handleApproveAllCompetencesForStudent,
  handleApproveAllCompetences,
  personSearch,
  isLicenseEditLocked,
  isPersonEditLocked,
}) {
  const [showGradeStudentDialog, setShowGradeStudentDialog] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [studentToCrud, setStudentToCrud] = useState(null);

  const studentPassingGradesCount = useMemo(() => {
    const countByStudentId = {};
    for (const student of students) {
      const passedCount = visibleCompetences.filter(
        (c) => !!student.competences[c.id]
      ).length;
      countByStudentId[student.id] = passedCount;
    }
    return countByStudentId;
  }, [visibleCompetences, students]);

  const allCompetencesAreApproved = useMemo(
    () =>
      visibleCompetences.every((c) =>
        students.every((s) => !!s.competences[c.id])
      ),
    [visibleCompetences, students]
  );

  function handleOpenGradeStudentDialog(id) {
    setSelectedStudentId(id);
    setShowGradeStudentDialog(true);
  }

  function handleCloseGradeStudentDialog() {
    setSelectedStudentId(null);
    setShowGradeStudentDialog(false);
  }

  async function handleSaveStudent(savedStudent, oldStudent) {
    if (!savedStudent) {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      setStudentToCrud(null);
      return;
    }

    const isExistingActor = savedStudent.actorId > 0;
    const isUpdatingActor = isExistingActor && oldStudent && oldStudent.id > 0;
    if (isExistingActor) {
      savedStudent = {
        ...oldStudent,
        actorId: savedStudent.actorId,
        name: savedStudent.actorName,
        socialSecurityNumber: savedStudent.orgNo ?? "",
        email: savedStudent.actorEmail,
        id: savedStudent.id ?? oldStudent?.id,
        profilePictureUrl:
          savedStudent.profilePictureUrl ?? oldStudent?.profilePictureUrl,
      };
      isUpdatingActor
        ? handleUpdateStudent(savedStudent)
        : handleAddStudent(savedStudent);
      return;
    } else {
      setStudentToCrud(savedStudent);
    }
  }

  const getDotMenuActions = (student) => {
    const menudata = [
      {
        icon: <DoneAllIcon />,
        text: Translate.get("ApproveAllSkills"),
        disabled:
          visibleCompetences.length <= 0 ||
          studentPassingGradesCount[student.id] === visibleCompetences.length ||
          isLicenseEditLocked,
        onClick: () => handleApproveAllCompetencesForStudent(student.id),
      },
      {
        icon: <DoneIcon />,
        text: Translate.get("ApproveSomeSkills"),
        disabled: visibleCompetences.length <= 0 || isLicenseEditLocked,
        onClick: () => handleOpenGradeStudentDialog(student.id),
      },
      {
        icon: <EditIcon />,
        text: Translate.get("EditStudent"),
        disabled: isPersonEditLocked,
        onClick: () => setStudentToCrud(student),
      },
      {
        icon: <ClearIcon />,
        text: Translate.get("DeleteStudent"),
        onClick: () => handleRemoveStudent(student),
      },
    ];
    return menudata;
  };

  return (
    <>
      {!!studentToCrud && (
        <PersonCrudDialog
          open={!!studentToCrud}
          personCrudProps={{
            name: studentToCrud.actorName ?? "",
            onClose: () => setStudentToCrud(null),
            onActorSaved: (savedStudent) =>
              handleSaveStudent(savedStudent, studentToCrud),
            actorId: studentToCrud.actorId,
            isEditOnly: studentToCrud.actorId > 0,
            isMatchingActorsDisabled: studentToCrud.actorId > 0,
            onCancel: () => setStudentToCrud(null),
          }}
        />
      )}
      <LicenseRegistrationGradeStudentDialog
        open={showGradeStudentDialog}
        onClose={handleCloseGradeStudentDialog}
        visibleCompetences={visibleCompetences}
        students={students}
        selectedStudentId={selectedStudentId}
        handleStudentAddRemoveCompetence={handleStudentAddRemoveCompetence}
      />
      <Box marginTop="16px" marginBottom="16px">
        <h2>
          {Translate.get("Step2Students") +
            (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")}
        </h2>
      </Box>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardContent sx={{ padding: 0 }}>
          <Grid
            container
            className="cardHeader licenseReg"
            justifyContent="space-between"
            alignItems="stretch"
            flexDirection="column"
            gap={2}
          >
            <Grid item alignSelf="flex-end">
              <Button
                onClick={handleApproveAllCompetences}
                disabled={allCompetencesAreApproved}
              >
                {Translate.get("ApproveAll")}
              </Button>
            </Grid>
            <Grid item>
              <AutocompleteSearchField
                placeholder={
                  Translate.get("Name") +
                  (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")
                }
                label={
                  Translate.get("Name") +
                  (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")
                }
                value={{}}
                onValueChange={handleSaveStudent}
                getOptionLabel={(option) => option?.actorName ?? ""}
                renderOption={(option) => (
                  <ActorAutocompleteOption
                    actorName={option?.actorName}
                    orgNo={option?.orgNo}
                    email={option?.actorEmail}
                  />
                )}
                keyPropName="actorId"
                requireSelection={true}
                search={personSearch}
                createNewValue={(text) => ({
                  actorName: text,
                })}
                disabled={isPersonEditLocked}
                // minWidth={isBigScreen ? "220px" : "250px"}
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              className="gradesTable licenseReg gradesTableSmall"
              aria-label="simple table"
            >
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id} className="gradesTableRow small">
                    <TableCell
                      component="th"
                      scope="row"
                      className="userNameEmail"
                      align="left"
                    >
                      <TableAvatar
                        src={student.profilePictureUrl || null}
                        title={student.name}
                        subtitle={[student.email, student.socialSecurityNumber]}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{ padding: "2px 8px" }}
                        className={
                          "textbox" +
                          ((studentPassingGradesCount[student.id] ?? 0) > 0 &&
                          studentPassingGradesCount[student.id] ===
                            visibleCompetences.length
                            ? " grades-done"
                            : "") +
                          ((studentPassingGradesCount[student.id] ?? 0) > 0 &&
                          studentPassingGradesCount[student.id] <
                            visibleCompetences.length
                            ? " grades-ongoing"
                            : "")
                        }
                      >
                        {studentPassingGradesCount[student.id] ?? 0}/
                        {visibleCompetences.length}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <DotMenu
                        content={getDotMenuActions(student)}
                        horizontal
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
