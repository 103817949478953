import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import ButtonWithSpinner from "../ButtonWithSpinner";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { actorSearch, actorPersonTypes } from "../../utils/actorSearch";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { validateDriverActor } from "./validation/ActorValidator";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import ActorAvatarBox from "../ActorAvatarBox";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useApi } from "../../utils/Api";
import { AlertDialog } from "../AlertDialog";
import ErrorMessage from "../ErrorMessage";

const maxDriversBeforeWarn = 10;

export default function SelectDriversDialog({
  selectedDrivers,
  onBack,
  onDriversSelected,
}) {
  const api = useApi();
  const [drivers, setDrivers] = useState(
    !!selectedDrivers && selectedDrivers.length > 0 ? selectedDrivers : []
  );
  const [driverToCrudIndex, setDriverToCrudIndex] = useState(null);
  const [driverToAdd, setDriverToAdd] = useState(null);
  const [newActorParentActorId, setNewActorParentActorId] = useState(null);
  const [showManyPermitsWarning, setShowManyPermitsWarning] = useState(
    !!selectedDrivers && selectedDrivers.length > maxDriversBeforeWarn
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"));

  async function personSearch(searchText) {
    const foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName", "OrgNo", "ExternalActorNo"],
      50
    );
    if (foundActors) {
      return foundActors
        .filter((actor) => !drivers.some((d) => d.actorId === actor.actorId))
        .map((actor) => ({
          actorId: actor.actorId,
          actorName: actor.actorName,
          orgNo: actor.orgNo,
          externalActorNo: actor.externalActorNo,
          email: actor.email,
          profilePictureUrl: actor.profilePictureUrl,
          phoneNumber: actor.phoneNumbers?.find((ph) => ph.type === 2)
            ?.phoneNumber,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId !== actor.actorId &&
                x.parentActor.actorId > 0 &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => ({
              actorId: x.parentActor.actorId,
              actorName: x.parentActor.actorName,
            })),
        }));
    }
  }

  async function handleDriverNameChange(driver) {
    if (!driver) {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      setDriverToAdd(null);
      return;
    }

    const isExistingActor = driver.actorId > 0;
    if (isExistingActor) {
      setNewActorParentActorId(null);
      const validationError = validateDriverActor(driver);
      driver = { ...driver, error: validationError };
    }

    const driversCopy = [...drivers, driver];
    setDrivers(driversCopy);
    if (driversCopy.length > maxDriversBeforeWarn) {
      setShowManyPermitsWarning(true);
    }

    // Automatically open the PersonCrud to edit the new actor
    if (!isExistingActor) {
      if (!newActorParentActorId) {
        // load dataid actorId to use as parent actor for newly created driver actor
        const parentActor = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/parent-actor/for-data-id`,
          false,
          "GET"
        );
        setNewActorParentActorId(parentActor?.actorId);
      }
      setDriverToCrudIndex(driversCopy.length - 1);
    }
  }

  function handleDriverCrudSave(driver, driverIndex) {
    if (!driver || !driver.actorId) {
      throw new Error("Missing data");
    }
    driver = {
      ...driver,
      email: driver.actorEmail,
      phoneNumber: driver.mobilePhone?.actorPhoneNumber,
      companies: driver.childActorGroups
        ? driver.childActorGroups.map((x) => ({ actorId: x.parentActorId }))
        : null,
    };
    const validationError = validateDriverActor(driver);
    driver = { ...driver, error: validationError };
    const driversCopy = [...drivers];
    driversCopy[driverIndex] = driver;
    setDrivers(driversCopy);
  }

  function handleRemoveDriver(index) {
    const driversCopy = [...drivers];
    driversCopy.splice(index, 1);
    setDrivers(driversCopy);
  }

  function getDotMenuActions({ driver, onRemoveDriver, onCrud }) {
    return [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        // disabled: student.isDummyLicense, // TODO bara om man har något ifyllt
        onClick: onCrud,
      },
      {
        icon: <ClearIcon />,
        text: Translate.get("RemoveDriver"),
        onClick: onRemoveDriver,
      },
    ];
  }

  const showDriverCrud = driverToCrudIndex != null;

  return (
    <>
      {showDriverCrud && (
        <PersonCrudDialog
          open={showDriverCrud}
          personCrudProps={{
            name: drivers[driverToCrudIndex]?.actorName ?? "",
            onClose: () => setDriverToCrudIndex(null),
            onActorSaved: (actor) =>
              handleDriverCrudSave(actor, driverToCrudIndex),
            actorId: drivers[driverToCrudIndex]?.actorId,
            parentActorId:
              drivers[driverToCrudIndex]?.companies?.[0]?.actorId ??
              newActorParentActorId,
            actorRole: "permit-holder",
            onCancel: () => setDriverToCrudIndex(null),
          }}
        />
      )}
      <DialogContent className="stdDialogContent">
        <Stack spacing={3}>
          {/* TODO styling */}
          <Stack direction="row">
            <Box
              className="textbox smallHeader blue"
              sx={{ padding: "4px 8px" }}
            >{`${Translate.get("Step")} 1 ${Translate.get("Of")} 2`}</Box>
          </Stack>
          <div className="stdFlexBetween">
            <h2>{Translate.get("AddPerson2")}</h2>
            {/* <div className="smallHeader">{Translate.get('Responsible')}</div> */}
            <Tooltip title={Translate.get("PermitActorSearchTip")}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          </div>
          <AutocompleteSearchField
            placeholder={Translate.get("Name")}
            label={Translate.get("Name")}
            value={driverToAdd}
            onValueChange={handleDriverNameChange}
            getOptionLabel={(option) => option?.actorName ?? ""}
            renderOption={(option) => (
              <ActorAutocompleteOption
                actorName={option?.actorName}
                orgNo={option?.orgNo}
                email={option?.email}
                companies={option?.companies?.map((x) => x.actorName)}
                parentActors={option?.parentActors}
                externalActorNo={option?.externalActorNo}
              />
            )}
            keyPropName="actorId"
            requireSelection={true}
            search={personSearch}
            createNewValue={(text) => ({
              actorName: text,
            })}
            // minWidth={isBigScreen ? "220px" : "250px"}
          />
          <div className="smallHeader">{`${Translate.get("Persons")} (${
            drivers.length
          })`}</div>
          <Stack direction="column" spacing={2}>
            {drivers.map((driver, idx) => (
              <Box key={idx} className="textbox">
                <ActorAvatarBox
                  name={driver.actorName}
                  profilePictureUrl={driver.actorImage}
                  rows={[
                    driver.orgNo ?? "-",
                    driver.email ?? "-",
                    driver.phoneNumber ?? "-",
                    driver.error ? <ErrorMessage error={driver.error} onClick={() => setDriverToCrudIndex(idx)} /> : null,
                  ]}
                  dotMenuActions={getDotMenuActions({
                    driver,
                    onRemoveDriver: () => handleRemoveDriver(idx),
                    onCrud: () => setDriverToCrudIndex(idx),
                  })}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={() => onDriversSelected(drivers.map((s) => s.actorId))}
          disabled={
            drivers.length === 0 || drivers.some((d) => !d.actorId || !!d.error)
          }
        >
          {Translate.get("Continue")}
        </ButtonWithSpinner>
      </DialogActions>
      <AlertDialog
        bodyText={Translate.get("ManyPermitsWarning")}
        buttonText={Translate.get("Ok")}
        open={!!showManyPermitsWarning}
        onClose={() => setShowManyPermitsWarning(false)}
      />
    </>
  );
}
