import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../components/DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
// import { CardBack, Cardfront } from "../components/digital-licenses/DLCards";
import {tryFormatDate} from "../utils/dateTimeFormat"
let moclicenses = {
	"licenses": [
		{
			"digitalLicenseId": 57,
			"displayData": {
				"digitalLicenseColor": "#0000ff",
				"primaryFontColor": "#ffffff",
				"secondaryFontColor": "#ffffff80",
				"frontBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-front.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"backBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-back.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"frontFields": [
					{
						"fieldName": "CompanyLogo",
						"seqNo": 0,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 10,
						"isCapitalized": null
					},
					{
						"fieldName": "LicenseGroupDescription",
						"seqNo": 1,
						"titleTranslationKey": "ActorLicenseAlt",
						"subtitleTranslationKey": "BasedOnEducationPlanLLP",
						"fontFamily": "Roboto-Regular",
						"fontSize": 48,
						"isCapitalized": true
					},
					{
						"fieldName": "gap",
						"seqNo": 2,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "-",
						"fontSize": 0,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentName",
						"seqNo": 3,
						"titleTranslationKey": "BelongingTo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentPersonalNo",
						"seqNo": 4,
						"titleTranslationKey": "StudentPersonNo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					}
				],
				"backFields": [
					{
						"fieldName": "ValidFrom",
						"seqNo": 5,
						"titleTranslationKey": "ActorLicenseIssuedDate",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ValidTo",
						"seqNo": 6,
						"titleTranslationKey": "ActorLicenseValidUntil",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "CourseCoordinator",
						"seqNo": 7,
						"titleTranslationKey": "CourseCoordinator",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicenseTheoretical",
						"seqNo": 8,
						"titleTranslationKey": "TheoryGroup",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicensePractical",
						"seqNo": 9,
						"titleTranslationKey": "Competences",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					}
				],
				"fieldValues": {
					"LicenseGroupDescription": "Truck",
					"StudentName": "Niklas Ollikainen",
					"StudentPersonalNo": "19660309-8937",
					"ValidFrom": "2024-05-17T13:41:00Z",
					"ValidTo": null,
					"CourseCoordinator": "Juri AB",
					"ActorLicenseTheoretical": "C",
					"ActorLicensePractical": "",
					"CompanyLogo": "https://dataidstorage.blob.core.windows.net/1015/logotype.png?sv=2022-11-02&se=2024-09-14T06%3A56%3A42Z&sr=b&sp=r&sig=FWcBae2AAPa4SAGQaYvrImPRW79Eq%2FPLWv%2Fhu%2BP0d8s%3D"
				}
			},
			"validTo": null,
			"publicShareUrl": "https://ecomp-dev.azurewebsites.net?digitalLicenseId=57&key=e1grPbvDuqEZ3vqU1yVUyE27kawTrBJQXJ7d3Zi7h3QQ5qwvu8"
		},
		{
			"digitalLicenseId": 41,
			"displayData": {
				"digitalLicenseColor": "#0000ff",
				"primaryFontColor": "#ffffff",
				"secondaryFontColor": "#ffffff80",
				"frontBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-front.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"backBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-back.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"frontFields": [
					{
						"fieldName": "CompanyLogo",
						"seqNo": 0,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 10,
						"isCapitalized": null
					},
					{
						"fieldName": "LicenseGroupDescription",
						"seqNo": 1,
						"titleTranslationKey": "ActorLicenseAlt",
						"subtitleTranslationKey": "BasedOnEducationPlanLLP",
						"fontFamily": "Roboto-Regular",
						"fontSize": 48,
						"isCapitalized": true
					},
					{
						"fieldName": "gap",
						"seqNo": 2,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "-",
						"fontSize": 0,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentName",
						"seqNo": 3,
						"titleTranslationKey": "BelongingTo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentPersonalNo",
						"seqNo": 4,
						"titleTranslationKey": "StudentPersonNo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					}
				],
				"backFields": [
					{
						"fieldName": "ValidFrom",
						"seqNo": 5,
						"titleTranslationKey": "ActorLicenseIssuedDate",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ValidTo",
						"seqNo": 6,
						"titleTranslationKey": "ActorLicenseValidUntil",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "CourseCoordinator",
						"seqNo": 7,
						"titleTranslationKey": "CourseCoordinator",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicenseTheoretical",
						"seqNo": 8,
						"titleTranslationKey": "TheoryGroup",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicensePractical",
						"seqNo": 9,
						"titleTranslationKey": "Competences",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					}
				],
				"fieldValues": {
					"LicenseGroupDescription": "Truck",
					"StudentName": "Niklas Ollikainen",
					"StudentPersonalNo": "19660309-8937",
					"ValidFrom": "2024-05-23T00:00:00Z",
					"ValidTo": null,
					"CourseCoordinator": "Juri AB",
					"ActorLicenseTheoretical": "",
					"ActorLicensePractical": "B1",
					"CompanyLogo": "https://dataidstorage.blob.core.windows.net/1015/logotype.png?sv=2022-11-02&se=2024-09-14T06%3A56%3A42Z&sr=b&sp=r&sig=FWcBae2AAPa4SAGQaYvrImPRW79Eq%2FPLWv%2Fhu%2BP0d8s%3D"
				}
			},
			"validTo": null,
			"publicShareUrl": "https://ecomp-dev.azurewebsites.net?digitalLicenseId=41&key=AZOVivcMN6qBAHQBNu2FuytBnbc24gTWShZnrydP4tM3Pd7v4R"
		},
		{
			"digitalLicenseId": 39,
			"displayData": {
				"digitalLicenseColor": "#0000ff",
				"primaryFontColor": "#ffffff",
				"secondaryFontColor": "#ffffff80",
				"frontBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-front.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"backBackgroundImageUrl": "https://dataidstorage.blob.core.windows.net/default/digitallicenseimages/masystem%2ftruck-back.png?sv=2022-11-02&st=2024-09-13T06%3A51%3A42Z&se=2024-09-13T07%3A56%3A42Z&sr=c&sp=r&sig=5R2l9gBK5D2NMsaa%2BFpLDz2LarW9FINi8L7zNgUSo9k%3D",
				"frontFields": [
					{
						"fieldName": "CompanyLogo",
						"seqNo": 0,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 10,
						"isCapitalized": null
					},
					{
						"fieldName": "LicenseGroupDescription",
						"seqNo": 1,
						"titleTranslationKey": "ActorLicenseAlt",
						"subtitleTranslationKey": "BasedOnEducationPlanLLP",
						"fontFamily": "Roboto-Regular",
						"fontSize": 48,
						"isCapitalized": true
					},
					{
						"fieldName": "gap",
						"seqNo": 2,
						"titleTranslationKey": null,
						"subtitleTranslationKey": null,
						"fontFamily": "-",
						"fontSize": 0,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentName",
						"seqNo": 3,
						"titleTranslationKey": "BelongingTo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					},
					{
						"fieldName": "StudentPersonalNo",
						"seqNo": 4,
						"titleTranslationKey": "StudentPersonNo",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 24,
						"isCapitalized": null
					}
				],
				"backFields": [
					{
						"fieldName": "ValidFrom",
						"seqNo": 5,
						"titleTranslationKey": "ActorLicenseIssuedDate",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ValidTo",
						"seqNo": 6,
						"titleTranslationKey": "ActorLicenseValidUntil",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "CourseCoordinator",
						"seqNo": 7,
						"titleTranslationKey": "CourseCoordinator",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicenseTheoretical",
						"seqNo": 8,
						"titleTranslationKey": "TheoryGroup",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					},
					{
						"fieldName": "ActorLicensePractical",
						"seqNo": 9,
						"titleTranslationKey": "Competences",
						"subtitleTranslationKey": null,
						"fontFamily": "Roboto-Regular",
						"fontSize": 16,
						"isCapitalized": null
					}
				],
				"fieldValues": {
					"LicenseGroupDescription": "Truck",
					"StudentName": "Niklas Ollikainen",
					"StudentPersonalNo": "19660309-8937",
					"ValidFrom": "2024-05-17T13:41:00Z",
					"ValidTo": null,
					"CourseCoordinator": "Juri AB",
					"ActorLicenseTheoretical": "C",
					"ActorLicensePractical": "",
					"CompanyLogo": "https://dataidstorage.blob.core.windows.net/1015/logotype.png?sv=2022-11-02&se=2024-09-14T06%3A56%3A42Z&sr=b&sp=r&sig=FWcBae2AAPa4SAGQaYvrImPRW79Eq%2FPLWv%2Fhu%2BP0d8s%3D"
				}
			},
			"validTo": null,
			"publicShareUrl": "https://ecomp-dev.azurewebsites.net?digitalLicenseId=39&key=2NWqjbPcDeEuhZmHnEWzhvOkKszgpSJEYJ11Bs76YgmNWzFUS5"
		}
	],
	"isSuccessful": true,
	"errorMessageTranslationKey": null
};

function getDotMenuActions() {
  return [
    {
      icon: <AddIcon />,
      text: "Lägg till i Apple Wallet",
      onClick: () => {},
    },
    {
      icon: <AddIcon />,
      text: "Lägg till i Google Wallet",
      onClick: () => {},
    },
    /*  {
      icon: <ContentCopyIcon />,
      text: "Kopiera länk",
      onClick: () => {},
    }, */
    {
      icon: <DownloadIcon />,
      text: "Ladda ner",
      onClick: () => {},
    },
  ];
}
const PreCard = ({ lic }) => {
  const navigate = useNavigate();

  const bgColor = lic.displayData?.digitalLicenseColor
    ? { backgroundColor: lic.displayData.digitalLicenseColor }
    : {};
  const fontColor = {};
  fontColor.primary = lic.displayData?.primaryFontColor
    ? { color: lic.displayData.primaryFontColor }
    : {};
  fontColor.secondary = lic.displayData?.secondaryFontColor
    ? { color: lic.displayData.secondaryFontColor }
    : {};
  const ff=[];
  lic.displayData.frontFields.map((row) => {
    row.value=lic.displayData.fieldValues[row.fieldName] ? lic.displayData.fieldValues[row.fieldName] : "-";
    ff.push(row);
  });
  const bf=[];
  lic.displayData.backFields.map((row) => {
    row.value=lic.displayData.fieldValues[row.fieldName] ? lic.displayData.fieldValues[row.fieldName] : "-";
    if (row.fieldName==='ValidFrom' || row.fieldName==='ValidTo') {
      row.value=tryFormatDate(row.value);
    }
    bf.push(row);
  });
 console.log('FROM',lic.displayData.fieldValues.ValidFrom);
  /*---------*/
  const openCard = (id) => {
    console.log("digitalLicenseId", id);
    navigate(`/test/2`, { replace: false });
  };
  /* const handleOpenCompany = () => {
    console.log("handleOpenCompany");
    
  };
  const handleOpenComp = () => {
    console.log("handleOpenCompetece");
    
  }; */
  return (
    <>
      <div className="dlcPreCard">
        {/* <div className="dlcSmallCard">
          <div style={bgColor}>
            <Cardfront
              fields={ff}
              fontColor={fontColor}
              bgImg={lic.displayData.frontBackgroundImageUrl || false}
              
             
            />
          </div>
          <div style={bgColor}>
            <CardBack
              fields={bf}
              fontColor={fontColor}
              bgImg={lic.displayData.frontBackgroundImageUrl || false}
              //handleOpenCompany={handleOpenCompany}
              //handleOpenComp={handleOpenComp}
            />
          </div>
        </div> */}
        <div className="stdFlexBetween">
          <div className="dlcHeading2">{lic.displayData.fieldValues.LicenseGroupDescription}</div>
          <div>
            <DotMenu horizontal content={getDotMenuActions()} />
          </div>
        </div>
        <div className="iconTextRows">
          <div>
            <div>
              <EventAvailableIcon />
            </div>
            <div>{tryFormatDate(lic.displayData.fieldValues.ValidFrom)}</div>
          </div>
          <div>
            <div>
              <DomainIcon />
            </div>
            <div>{lic.displayData.fieldValues.CourseCoordinator}</div>
          </div>
          <div>
            <div>
              <AccountBoxIcon />
            </div>
            <div>Instruktör?</div>
          </div>
       
        </div>
        <div>
          <Button
            onClick={() => openCard(lic.digitalLicenseId)}
            variant="contained"
            fullWidth={true}
            startIcon={<RemoveRedEyeIcon />}
          >
            VISA
          </Button>
        </div>
      </div>
    </>
  );
};
const Comp = () => {
  return (
    <>
      <div className="dlcPageWrapper">
        <div className="dlcHeading1">Digitala intyg</div>
        <div className="dlcPage">
          {moclicenses.licenses.map((lic, index) => (
            <PreCard key={index} lic={lic} />
          ))}
       
          <div></div>
        </div>
      </div>
    </>
  );
};
export default Comp;
