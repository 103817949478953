import Translate from "../../utils/Translate";
import { Grid, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import ConstructionIcon from "@mui/icons-material/Construction";
import EmailIcon from "@mui/icons-material/Email";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import BadgeIcon from "@mui/icons-material/Badge";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildIcon from "@mui/icons-material/Build";
import Api, { useApi } from "../../utils/Api";
import download from "downloadjs";

export default function DigitalPermitView({
  permit,
  onClick,
}) {
  const navigate = useNavigate();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  async function handleDownloadActorPermit(actorPermitId, actorName) {
    setLoading(actorPermitId);
    const blob = await Api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}permits/pdf/admin?actorPermitId=${actorPermitId}`,
      false,
      "GET"
    );
    const filename = `permit-${actorName.trim().replace(" ", "_")}.pdf`;
    download(blob, filename, blob.type);
    setLoading(false);
  }

  
  return (
    <div className="dlCard" style={{ cursor: "default" }} /* onClick={onClick} */>
      <div className="dlCardIcon" style={{ maxWidth: "730px" }}>
        <div>
          <div>
            <PersonIcon />
          </div>
          <div>{permit.actorName}</div>
        </div>
        <div>
          <div>
            <EmailIcon />
          </div>
          <div>{permit.actorEmail}</div>
        </div>
        <div>
          <div>
            <DomainIcon />
          </div>
          <div>{permit.parentActorName}</div>
        </div>
        <div>
          <div>
            <HeadsetMicIcon />
          </div>
          <div>{permit.responsibleActorName}</div>
        </div>
        <div>
          <div>
            <EmailIcon />
          </div>
          <div>{permit.responsibleActorEmail}</div>
        </div>
        <div>
          <div>
            <ConstructionIcon />
          </div>
          <div>{permit.skillGroupDescription}</div>
        </div>
        <div>
          <div>
            <BuildIcon />
          </div>
          <div>
            {permit.skills
              .map((s) => `${s.shortCode} ${s.description}`)
              .join(", ")}
          </div>
        </div>

        {/*  <div>{permit.parentActorName}</div>
        <div>
          {permit.responsibleActorName}, {permit.responsibleActorEmail}
        </div>
        <div>{permit.skillGroupDescription}</div>
        <div>
          {permit.skills
            .map((s) => `${s.shortCode} ${s.description}`)
            .join(", ")}
        </div> */}
      </div>
      <div className="dlCardButtonBox">
        <div>
          <Tooltip title={Translate.get("Download")}>
            <LoadingButton
              className="regularButtonWithIcon stdLightLoader"
              variant="contained"
              //tooltip={Translate.get('Download')}
              loading={permit.actorPermitId === loading}
              onClick={(event) => {
                event.stopPropagation();

                handleDownloadActorPermit(
                  permit.actorPermitId,
                  permit.actorName
                );
              }}
            >
              <DownloadIcon />
            </LoadingButton>
          </Tooltip>
        </div>
        <div>
          <Button variant="text" className="walletButton">
            <img src="https://eportpublic.blob.core.windows.net/others/apple/add_to_apple_wallet_EN.svg?sp=r&st=2024-04-12T07:06:58Z&se=2064-04-12T15:06:58Z&spr=https&sv=2022-11-02&sr=c&sig=STfImuoPxA9srWpHhIHlMAaW7w9ST8G1CYcpsinC6PQ%3D" />
          </Button>
        </div>
        <div>
          <Button variant="text" className="walletButton">
            <img src="https://eportpublic.blob.core.windows.net/others/google/add_to_google_wallet_badge_EN.svg?sp=r&st=2024-04-12T07:06:58Z&se=2064-04-12T15:06:58Z&spr=https&sv=2022-11-02&sr=c&sig=STfImuoPxA9srWpHhIHlMAaW7w9ST8G1CYcpsinC6PQ%3D" />
          </Button>
        </div>
      </div>
    </div>
  );
}
