import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import YesOrNoDialog from "../../YesOrNoDialog";
import SkillItem from "../../skills/SkillItem";
import SkillItemGrid from "../../skills/SkillItemGrid";
import ButtonWithSpinner from "../../ButtonWithSpinner";

export default function CourseSkillsDialog({ courseId, open, onClose }) {
  const [availableSkills, setAvailableSkills] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showForceDeleteDialog, setShowForceDeleteDialog] = useState(false);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const api = useApi();

  useEffect(() => {
    if (open) {
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/skills/`,
        false,
        "GET"
      ).then((data) => {
        if (data) {
          setAvailableSkills(data.skills ?? []);
        }
        setIsDataFetched(true);
      });
    }
  }, [courseId, open, api]);

  async function uploadUpdatedSkillSelection(forceDelete) {
    const request = {
      skillIds: availableSkills
        .filter((skill) => skill.isInCourse)
        .map((skill) => skill.skillId),
      forceDelete: forceDelete,
    };

    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/skills/`,
      request,
      "PUT",
      (response) => response.isSuccessful || (response.someSkillBeingRemovedHasActorSkills && !forceDelete)
    );

    if (response.isSuccessful === true) {
      setShowForceDeleteDialog(false);
      onClose(); // Close dialog after saving
    } else if (response.someSkillBeingRemovedHasActorSkills && !forceDelete) {
      setShowForceDeleteDialog(true);
    }
  }

  function handleToggleSkillSelected(skillId) {
    const skillIndex = availableSkills.findIndex(
      (skill) => skill.skillId === skillId
    );
    if (skillIndex >= 0) {
      const availableSkillsCopy = [...availableSkills];
      const skillCopy = { ...availableSkillsCopy[skillIndex] };
      skillCopy.isInCourse = !skillCopy.isInCourse;
      availableSkillsCopy[skillIndex] = skillCopy;
      setAvailableSkills(availableSkillsCopy);
    }
  }

  return (
    <>
      <YesOrNoDialog
        open={showForceDeleteDialog}
        title={Translate.get("DeleteGradedSkillsTitle")}
        text={Translate.get("DeleteGradedSkillsQuestion")}
        onNo={() => setShowForceDeleteDialog(false)}
        onYes={async () => await uploadUpdatedSkillSelection(true)}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Continue")}
      />
      {open && !isDataFetched && <Preloader />}
      {isDataFetched && (
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { maxWidth: { sm: "700px", xs: "343px" } } }}
        >
          <DialogTitle>{Translate.get("EditCourseSkills")}</DialogTitle>
          <DialogContent>
            <Box>
              <Typography sx={{ margin: "0 0 24px" }}>
                {Translate.get("EditCourseSkillsInfo")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <SkillItemGrid
                  items={availableSkills.map((skill) => (
                    <SkillItem
                      isSelected={skill.isInCourse}
                      onClick={() => handleToggleSkillSelected(skill.skillId)}
                      isSmall={!isSmallScreenOrGreater}
                      imageUrl={skill.imageUrl}
                      label={`${skill.shortCode} ${skill.description}`}
                      disabled={skill.isInActorLicense}
                    />
                  ))}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner onClick={async () => await uploadUpdatedSkillSelection(false)}>
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
