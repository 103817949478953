import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Translate from "./Translate";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export function CustomNoRowsOverlay(message) {
  return () =>
    <StyledGridOverlay sx={{ mt: 1}}>
      <Box component="img" src="/static/no-data-empty-box.svg" sx={{width: 48, height: 48}} />
      <Box sx={{ mt: 0, mb: 1, opacity:0.6}}>
        <h3><center>{Translate.get("NoData")}</center></h3>
        {message || Translate.get("EmptyHere")}
        </Box>
    </StyledGridOverlay>
}
export function StaticNoRowsOverlay() {
  return (
    <StyledGridOverlay sx={{ mt: 1}}>
      <Box component="img" src="/static/no-data-empty-box.svg" sx={{width: 48, height: 48}} />
      <Box sx={{ mt: 0, mb: 1, opacity:0.6}}>
        <h3><center>{Translate.get("NoData")}</center></h3>
        {Translate.get("EmptyHere")}
        </Box>
    </StyledGridOverlay>)
}
