import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Translate from "../utils/Translate";
import ButtonWithSpinner from "./ButtonWithSpinner";

export default function YesOrNoDialog({
  open,
  onNo,
  onYes,
  onCancel,
  title,
  text,
  content,
  yesText,
  noText,
  showCancelButton, // An extra button
}) {
  const dialogContent = text ? (
    <DialogContentText>{text}</DialogContentText>
  ) : (
    content
  );

  return (
    <div>
      <Dialog
        className="yesOrNoDialog"
        open={open}
        onClose={async () => (await onCancel()) ?? (await onNo())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{ whiteSpace: "pre-wrap" }}>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <ButtonWithSpinner onClick={async () => await onCancel()}>
              {Translate.get("Cancel")}
            </ButtonWithSpinner>
          )}
          <ButtonWithSpinner onClick={async () => await onNo()}>
            {noText ?? Translate.get("No")}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            onClick={async () => await onYes()}
            variant="contained"
            autoFocus
          >
            {yesText ?? Translate.get("Yes")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </div>
  );
}
