import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import svLocale from '@fullcalendar/core/locales/sv';
import frLocale from '@fullcalendar/core/locales/fr';
import enGbLocale from '@fullcalendar/core/locales/en-gb'
import { useState, useEffect } from "react";
import { useApi } from "../../utils/Api";
import Translate from '../../utils/Translate';
import Button from '@mui/material/Button';
import { accessKeys, hasAccess } from '../../utils/userAccess';
import Preloader from "../Preloader";
import SpeedMenu from "../../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import CourseDialog from './CourseDialog';
import React, { useRef } from "react";
import { formatDate, tryFormatDate } from '../../utils/dateTimeFormat';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseCalendarNoteDialog from './CourseCalendarNoteDialog';
import { UTCToLocal } from '../../utils/dateTimeFormat';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';



export default function CourseCalendar() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const smallScreen = useMediaQuery('(max-width:700px)');
  const api = useApi();
  const calendarRef = useRef();

  const [open, setOpen] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalFromDate, setModalFromDate] = useState(null);
  const [modalToDate, setModalToDate] = useState(null);
  const [modalText, setModalText] = useState("");

  const [teachers, setTeachers] = useState([]);
  const [actorId, setActorId] = useState(null);


  useEffect(async () => {
    await loadData();
  }, []);

  useEffect(() => {
    let hash = window.location.hash.substring(1);
    let date = tryFormatDate(hash, 'sv-SE');
    if (date !== "") {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }
  }, [window.location.hash])

  const jumpToToday = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();

    let d = calendarApi.getDate();
    window.location.hash = formatDate(d, 'sv-SE');
  };

  const getPrev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    let d = calendarApi.getDate();
    window.location.hash = formatDate(d, 'sv-SE');
  }

  const getNext = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    let d = calendarApi.getDate();
    window.location.hash = formatDate(d, 'sv-SE');
  }

  function getMonth() {
    let calendarApi = calendarRef.current.getApi();
    return calendarApi.getDate();
  }

  const getSpeedMenu = (props) => {
    const menudata = [
      {
        icon: <AddIcon />,
        text: Translate.get("AddNote"),
        route: false,
        dialogHeader: Translate.get("AddNote"),
        customClass: "bigCourseDialog noCloseButton",
        component: <CourseCalendarNoteDialog loadData={loadData} defaultDate={getMonth} teachers={teachers} />,
      },
      {
        icon: <AddIcon />,
        text: Translate.get("AddCourse"),
        route: false,
        dialogHeader: Translate.get("AddCourse"),
        customClass: "bigCourseDialog noCloseButton",
        component: <CourseDialog {...props} />,
      },
    ];
    return menudata;
  };

  async function loadData() {
    setIsLoading(true);
    const [courses, holidays, notes, teachers] = await Promise.all([
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/get-filtered`,
        { "page": 0, "pageSize": 10000, "sortFieldName": "CourseId", "sortOrder": "desc" },
        'POST'
      ),
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}calendars/holidays`,
        false,
        "GET"
      ),
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}calendars/notes`,
        false,
        "GET"
      ),
      api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/teachers/`,
        false,
        "GET"
      ),
    ])
      .finally(() => {
        setIsLoading(false);
      });

    setTeachers(teachers);

    let evs1 = toEventDto(courses);
    let evs2 = holidaysToEvents(holidays?.holidays);
    let evs3 = notesToEvents(notes?.notes);

    let evs = evs1.concat(evs2, evs3);

    setEvents(evs);

    let hash = window.location.hash.substring(1);
    let date = tryFormatDate(hash, 'sv-SE');
    if (date !== "") {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }
  }

  function toEventDto(courses) {
    let events = [];
    courses.forEach(element => {
      let e = {
        title: element.name,
        city: element.city, // extendedProps
        instructor: element.responsibleTeacherActorName, // extendedProps
        shortComment: element.shortComment, // extendedProps
        start: UTCToLocal(element.fromDate),
        end: UTCToLocal(element.toDate),
        url: window.location.origin + "/dashboard/course/" + element.courseId,
        color: element.webColor,
        type: "event", // extendedProps
      };
      events.push(e);
    });
    return events;
  }

  function holidaysToEvents(holidays) {
    let events = [];
    holidays.forEach(element => {
      let e = {
        title: element.localName,
        start: UTCToLocal(element.date),
        color: "#ff5050",
        borderColor: "darkred",
        type: "holiday", // extendedProps
      };
      events.push(e);
    });
    return events;
  }

  function notesToEvents(notes) {
    let events = [];
    notes.forEach(element => {
      let e = {
        title: element.text,
        start: UTCToLocal(element.fromDate),
        end: UTCToLocal(element.toDate),
        color: "#FFFBEE",
        borderColor: "#FFDC48",
        textColor: "black",
        type: "note", // extendedProps
        calendarNoteId: element.id, // extendedProps
        actorId: element.actorId, // extendedProps
        actorName: element.actorName, // extendedProps
      }
      events.push(e);
    })
    return events;
  }

  function handleViewChange() {
    localStorage.setItem("courseView", "list")
    window.location.hash = "";
    window.location.reload();
  }

  function eventClick(arg) {
    if (arg.event.extendedProps.type === "note") {
      setModalTitle(Translate.get("Note"));
      setModalFromDate(arg.event._instance.range.start);
      setModalToDate(arg.event._instance.range.end);
      setModalText(arg.event._def.title);
      setModalId(arg.event.extendedProps.calendarNoteId)
      setActorId(arg.event.extendedProps.actorId);
      setOpen(true);
    }
  }

  function deleteCalendarNote(id) {
    setIsLoading(true);
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}calendars/note/${id}`,
      {},
      'DELETE',
    )
      .then(async (data) => {
        if (data.isSuccessful) {
          await loadData();
          setOpen(false);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  function upDateCalendarNote() {
    setIsLoading(true);
    let req = {
      "Id": modalId,
      "FromDate": modalFromDate,
      "ToDate": modalToDate,
      "Text": modalText,
      "TeacherActorId": actorId,
    }

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}calendars/update/note`,
      req,
      'POST',
    )
      .then(async (data) => {
        if (data.isSuccessful) {
          await loadData();
          setOpen(false);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setActorId(event.target.value);
  };

  return (
    <>
      {isLoading && <Preloader />}

      <Dialog open={open}>
        {isLoading && <Preloader />}
        <DialogTitle id="alert-dialog-title">
          {modalTitle}
        </DialogTitle>
        <DialogContent>
          <DatePicker
            label={Translate.get('StartDate')}
            value={modalFromDate}
            onChange={(newValue) => {
              setModalFromDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                required
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: Translate.get('DatePlaceholder'),
                }}
              />
            )}
          />
          <DatePicker
            label={Translate.get('EndDate')}
            value={modalToDate}
            onChange={(newValue) => {
              setModalToDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                required
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: Translate.get('DatePlaceholder'),
                }}
              />
            )}
          />

          <TextField
            sx={{ mt: 2 }}
            id="outlined-multiline-static"
            label="Text"
            multiline
            rows={4}
            value={modalText}
            onChange={(e) => {
              setModalText(e.target.value);
            }}
            fullWidth
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="teacher-label">{Translate.get("Instructor")}</InputLabel>
            <Select
              labelId="teacher-label"
              label={Translate.get("Instructor")}
              id="demo-simple-select"
              value={actorId}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem key={-1} value={null}><b>{Translate.get("None")}</b></MenuItem>
              {teachers
                .sort((a, b) => a.actorName.localeCompare(b.actorName))
                .map((t, index) => {
                  return (
                    <MenuItem key={index} value={t.actorId}>{t.actorName}</MenuItem>
                  )
                })}

            </Select>
          </FormControl>


          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            sx={{ mt: 2 }}
            onClick={() => deleteCalendarNote(modalId)}
          >
            {Translate.get("Delete")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{Translate.get("Close")}</Button>
          <Button
            onClick={upDateCalendarNote}
          >{Translate.get("Save")}
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Stack direction="row" spacing={2}>
          <h2>{Translate.get("Courses")}</h2>

          <Button
            onClick={() => handleViewChange()}
            hidden={!hasAccess(accessKeys.advancedPackage)}
          >
            {Translate.get("ShowList")}
          </Button>
        </Stack>

        <FullCalendar
          customButtons={{
            todayButton: {
              text: Translate.get("Today"),
              click: () => jumpToToday()
            },
            prevButton: {
              text: "<",
              click: () => getPrev()
            },
            nextButton: {
              text: ">",
              click: () => getNext()
            },
          }}
          headerToolbar={{
            left: "title",
            right: "todayButton prevButton nextButton"
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          eventClick={eventClick}
          firstDay={1} // Starta veckan med måndag
          weekends={true}
          locale={getLocation()}
          height={"auto"}
          displayEventEnd="true"
          eventDisplay="block"
          eventContent={renderEventContent}
          events={events}
          weekNumbers={!smallScreen}
          showNonCurrentDates={true}
          fixedWeekCount={true}
          ref={calendarRef}
          timeZone='local'
        />
      </div>

      <SpeedMenu
        content={getSpeedMenu({
          id: 0,
        })}
      />
    </>
  )
}

function renderEventContent(eventInfo) {
  return (
    <>
      <div style={{ overflow: "hidden", cursor: "pointer" }}>
        <p>{eventInfo.event.title}{eventInfo.event.extendedProps.shortComment ? (", " + eventInfo.event.extendedProps.shortComment) : ""}</p>
        <p>{eventInfo.event.extendedProps.instructor}{eventInfo.event.extendedProps.city ? (", " + eventInfo.event.extendedProps.city) : ""}</p>
      </div>
    </>
  )
}

function getLocation() {
  let lang = localStorage.getItem("lang");

  switch (lang) {
    case "29":
      return svLocale;
    case "12":
      return frLocale;
    default:
      return enGbLocale;
  }
}