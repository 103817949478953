export const DocumentType = {
  OrderConfirmation: 1,
  Summoning: 2,
  Invitation: 3,
  Evaluation: 4,
  CalendarBooking: 5,
  CalendarCancelation: 6,
  ETruckLicense: 7,
  SMS: 8,
  ElearningProofOfCompletion: 10,
  FreeTextSMS: 11,
  CourseSummoning: 12,
  ExtraExamInvitation: 13,
};

export const MediaType = {
  Undefined: 0,
  Email: 1,
  Sms: 2,
  Pdf: 3,
};

export const CommunicationRole = {
  Undefined: 0,
  Student: 1,
  ContactPerson: 2,
  Instructor: 3,
};


// Funktion för att skapa en unik nyckel för sentCommunication
function createLookupKey(actorId, mediaType, docType, role) {
  return `${actorId}_${mediaType}_${docType}_${role}`;
}

// Förbehandlar sentCommunications för O(1)-uppslag
export function prepareSentCommunications(sentCommunications) {
  const communicationMap = {};

  for (const key in sentCommunications) {
    if (sentCommunications.hasOwnProperty(key)) {
      const element = sentCommunications[key];
      const lookupKey = createLookupKey(element.actorId, element.media, element.documentType, element.role);
      communicationMap[lookupKey] = element;
    }
  }

  return communicationMap;
}

// Snabb uppslagsfunktion som använder en map som förbehandlats
export function findSentCommunication(communicationMap, actorId, mediaType, docType, role) 
{
  const lookupKey = createLookupKey(actorId, mediaType, docType, role);
  return communicationMap[lookupKey];
}
