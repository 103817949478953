import Button from "@mui/material/Button";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Link,
} from "@mui/material";
import { useState, useCallback, useEffect, Fragment } from "react";
import Translate from "../../utils/Translate";
import { hasSomeAccess, accessKeys } from "../../utils/userAccess";
import AutocompleteSearchField from "../AutocompleteSearchField";
import DomainIcon from "@mui/icons-material/Domain";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ELearningLicenseCount from "../courses/bookings/ELearningLicenseCount";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { actorSearch, actorCompanyTypes } from "../../utils/actorSearch";
import { LoadingButton } from "@mui/lab";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import { Link as RouterLink } from "react-router-dom";
import { partType } from "../../utils/part";

export default function OrderFormSimple({
  order,
  orderLines,
  company,
  contacts,
  parts,
  companySearch,
  handleCompanySelected,
  canChangeCompany,
  isExternalDataIdRequired,
  handleContactSelected,
  isServiceAgreement,
  isEPortLicense,
  handlePartSelected,
  handleQtyChanged,
  isELearningLicense,
  getAvailableLicenseCount,
  getLearningType,
  handleAvailableLicenseCountChanged,
  handleOrderFieldChanged,
  handleSaveOrSendOrder,
  handleClose,
  isBig,
}) {
  return (
    <>
      <DialogTitle>
        {order.orderId
          ? Translate.get("EditOrder")
          : Translate.get("CreateOrder")}
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          <div>{Translate.get("CreateOrderInfo")}</div>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <DomainIcon sx={{ marginLeft: "9px" }} />
            <AutocompleteSearchField
              disabled={!canChangeCompany || order.orderId > 0}
              label={Translate.get("Company")}
              value={company ?? ""}
              onValueChange={handleCompanySelected}
              getOptionLabel={(option) => option?.actorName ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.actorName}
                  orgNo={option?.orgNo}
                  addressStreet={option?.addressStreet}
                  addressZipCode={option?.addressZipCode}
                  addressCity={option?.addressCity}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={companySearch}
              createNewValue={(text) => ({
                actorName: text,
              })}
              minWidth={isBig ? "496px" : "250px"}
              textFieldProps={{
                required: true,
                error:
                  !!company &&
                  isExternalDataIdRequired() &&
                  !company?.externalDataId,
                helperText:
                  !!company &&
                  isExternalDataIdRequired() &&
                  !company?.externalDataId
                    ? Translate.get("CompanyHasNoDataId")
                    : "",
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <AccountCircleIcon sx={{ marginLeft: "9px" }} />
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel id="contact-select-label">
                {Translate.get("Contact")}
              </InputLabel>
              <Select
                disabled={
                  !order.canUpdate ||
                  !contacts ||
                  contacts.length === 0 ||
                  isServiceAgreement ||
                  isEPortLicense
                }
                labelId="contact-select-label"
                id="contact-select"
                value={order?.actorRefId ?? ""}
                label={Translate.get("Contact")}
                onChange={handleContactSelected}
              >
                {contacts &&
                  contacts.map((contact) => (
                    <MenuItem key={contact.actorId} value={contact.actorId}>
                      {contact.actorName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <ListAltIcon sx={{ marginLeft: "9px" }} />
            <TextField
              disabled={!order.canUpdate}
              // Can't require, because some clients need an order confirmation before they can provide order number
              // required={company?.defaultCustomerOrderNoRequired}
              fullWidth
              value={order?.customerOrderNo ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                handleOrderFieldChanged(
                  "customerOrderNo",
                  event.target.value
                )
              }
              label={Translate.get("YourOrderNo")}
              inputProps={{ maxLength: 30 }}
            />
          </Stack>
          {/* We don't support more than one orderLine right now, and there is no way to add more lines. But this dialog
              is still written with the intention of supporting more lines in the future, at least for 9999 */}
          {orderLines.map((ol) => (
            <Fragment key={ol.orderLineId}>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <ShoppingBasketIcon sx={{ marginLeft: "9px" }} />
                <FormControl fullWidth>
                  <InputLabel id="part-select-label" required>
                    {Translate.get("Product")}
                  </InputLabel>
                  <Select
                    disabled={
                      !ol.canUpdate || isServiceAgreement || isEPortLicense
                    }
                    required
                    labelId="part-select-label"
                    id="part-select"
                    label={Translate.get("Product")}
                    value={ol.partId ?? ""}
                    onChange={(event) =>
                      handlePartSelected(
                        ol.orderLineId,
                        event.target.value ? Number(event.target.value) : 0
                      )
                    }
                  >
                    {parts.map((x) => (
                      <MenuItem key={x.partId} value={x.partId}>
                        {x.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <ScatterPlotIcon sx={{ marginLeft: "9px" }} />
                <TextField
                  disabled={
                    !ol.canUpdate || isServiceAgreement || isEPortLicense
                  }
                  required
                  label={Translate.get("Quantity")}
                  sx={{
                    minWidth: isBig ? "120px" : "250px",
                    width: "100%",
                  }}
                  value={isNaN(ol.qty) ? "" : ol.qty}
                  onChange={(event) =>
                    handleQtyChanged(
                      ol.orderLineId,
                      event.target.value ? Number(event.target.value) : 0
                    )
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                  }}
                  error={
                    isELearningLicense(ol.partId) &&
                    ol.qty > getAvailableLicenseCount(ol.partId)
                  }
                />
                {isELearningLicense(ol.partId) && isBig && (
                  <ELearningLicenseCount
                    eLearningType={getLearningType(ol.partId)}
                    onAvailableLicenseCountChanged={
                      handleAvailableLicenseCountChanged
                    }
                  />
                )}
              </Stack>
              {isELearningLicense(ol.partId) && !isBig && (
                <Stack direction="row" spacing={1} className="stdFlexLeft">
                  <ELearningLicenseCount
                    eLearningType={getLearningType(ol.partId)}
                    onAvailableLicenseCountChanged={
                      handleAvailableLicenseCountChanged
                    }
                  />
                </Stack>
              )}
            </Fragment>
          ))}
        </Stack>
        {orderLines.some((ol) => isELearningLicense(ol.partId)) && (
          <>
            <Typography
              variant="body2"
              sx={{
                margin: "33px 0 20px",
                color: "text.secondary",
              }}
            >
              {Translate.get("CreateOrderELearningInfo")}
            </Typography>
            <Link to="/dashboard/licenses" component={RouterLink}>
              {Translate.get("ClickHereToOrderELearning")}
            </Link>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{Translate.get("Cancel")}</Button>
        <LoadingButton
          variant="contained"
          onClick={handleSaveOrSendOrder}
          disabled={
            orderLines.some(
              (ol) =>
                // !ol.canUpdate || <- Can't block whole order because of this
                !ol.partId ||
                !ol.qty ||
                (isELearningLicense(ol.partId) &&
                  ol.qty > getAvailableLicenseCount(ol.partId))
            ) ||
            // Can't require, because some clients need an order confirmation before they can provide order number
            // (company?.defaultCustomerOrderNoRequired &&
            //   !order?.customerOrderNo) ||
            !company ||
            (isExternalDataIdRequired() && !company.externalDataId) ||
            !order.canUpdate
          }
        >
          {order.orderId ? Translate.get("Save") : Translate.get("CreateOrder")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
