import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
//import { DataGrid } from "@mui/x-data-grid";
import {
  DataGridPro as DataGrid,
  useGridApiRef,
  gridPaginationModelSelector,
} from "@mui/x-data-grid-pro";
// components
import SchoolIcon from "@mui/icons-material/School";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlaceIcon from "@mui/icons-material/Place";
//import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import GroupIcon from "@mui/icons-material/Group";
import { formatDate } from "../../utils/dateTimeFormat";
import { MemToolBar, dataGridLang } from "../MemToolBar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../../components/DotMenu";
import Translate from "../../utils/Translate";
import CourseDialog from "./CourseDialog";
import PublishToWeb from "./PublishToWeb";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { TableAvatar } from "../../utils/dataGrid";
import PublicIcon from "@mui/icons-material/Public";
import { hasAccess, accessKeys } from "../../utils/userAccess";

const getCourseDotMenu = (
  courseId,
  numberOfBookings,
  publishFromDate,
  publishToDate,
  isPublishActiveNow,
  fromDate,
  onDeleteCourse
) => {
  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditCourse"),
      route: false,
      dialogHeader: Translate.get("EditCourse"),
      component: <CourseDialog courseId={courseId} />,
    },
    {
      icon: <PublicIcon />,
      text: isPublishActiveNow
        ? Translate.get("ePort2iFrameUnpublish")
        : Translate.get("ePort2iFramePublishOnline"),
      route: false,
      //dialogHeader: isPublishActiveNow ? 'Avpublicera' : 'Publicera online', //Translate.get("PublishToWeb"),
      component: (
        <PublishToWeb
          courseId={courseId}
          publishFromDate={publishFromDate}
          publishToDate={publishToDate}
          isPublishActiveNow={isPublishActiveNow}
          fromDate={fromDate}
        />
      ),
      hide: !hasAccess(accessKeys.iframe),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("RemoveCourse"),
      route: false,
      dialogHeader: Translate.get("RemoveCourse"),
      onClick: () => onDeleteCourse(courseId),
      disabled: numberOfBookings > 0,
    },
  ];
  return menudata;
};

const parseData = (data) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(
    today.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );

  let dateSpan = "Pågående";
  return data.map((row) => {
    const parsedFrom = new Date(row.fromDate);
    const parsedTo = new Date(row.toDate);

    if (parsedTo < today) {
      dateSpan = "Avslutad";
    } else if (parsedFrom < tomorrow) {
      dateSpan = "Pågående";
    } else {
      dateSpan = "Planerad";
    }
    row.dateSpan = dateSpan;
    return row;
  });
};

const Comp = ({
  rows,
  smallScreen,
  onDeleteCourse,
  hideFilter = false,
  etitMenu = true,
  autoSize = false,
  hideDotMenu,
  loadFilters,
}) => {
  const localizedTextsMap = dataGridLang();
  rows = parseData(rows);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [filterModel, setFilterModel] = useState({ items: [] });

  let sizeProps = {
    autoHeight: true,
    paginationModel: paginationModel,
  };
  if (autoSize) {
    sizeProps = {
      autoPageSize: true,
    };
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("savedFilters") !== null && loadFilters === true) {
      let js = localStorage.getItem("savedFilters");
      let f = JSON.parse(js);
      setFilterModel(f);
    }
    getPagination(apiRef, "courses");
  }, []);

  let columns = [];
  let toolbarCss = "stdVisible dgDynIconText";
  if (hideFilter) {
    toolbarCss = "dgNoFilter dgNoIconText";
  }
  if (smallScreen) {
    toolbarCss = "dgSmall";
    columns = [
      {
        field: "name",
        headerName: Translate.get("CourseType"),
        headerClassName: "hideSeparator",
        flex: 2,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <SchoolIcon />
            <div>{Translate.get("CourseType")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.imageUrl}
              variant="rounded"
              title={params.row.name}
              subtitle={[
                params.row.shortComment,
                params.row.fromDate ? formatDate(params.row.fromDate) : "",
              ]}
            />
          );
        },
      },
      {
        field: "fromDate",
        headerName: Translate.get("Date"),
        headerClassName: "hideSeparator",
        type: "date",
        flex: 1,
        align: "left",
        valueGetter: ({ value }) => value && new Date(value),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CalendarTodayIcon />
          </div>
        ),

        renderCell: (params) => {
          return false;
        },
      },
      ...(hideDotMenu
        ? []
        : [
            {
              field: "dotMenu",
              flex: 0.3,
              headerName: "",
              sortable: false,
              disableColumnMenu: true,
              filterable: false,
              headerClassName: "hideSeparator",
              align: "right",
              renderCell: (params) => (
                <DotMenu
                  content={getCourseDotMenu(
                    params.row.courseId,
                    params.row.numberOfBookings,
                    params.row.publishFromDate,
                    params.row.publishToDate,
                    params.row.isPublishActiveNow,
                    params.row.fromDate,
                    onDeleteCourse
                  )}
                  horizontal
                />
              ),
            },
          ]),
    ];
  } else {
    columns = [
      {
        field: "name",
        headerName: Translate.get("CourseType"),
        headerClassName: "hideSeparator",
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <SchoolIcon />

            <div>{Translate.get("CourseType")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.imageUrl}
              variant="rounded"
              title={params.row.name}
              subtitle={params.row.shortComment}
            />
          );
        },
      },
      {
        field: "dateSpan",
        headerName: Translate.get("Status"),
        headerClassName: "hideSeparator",
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CheckCircleIcon />
            <div>{Translate.get("Status")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <div className="stdFlexLeft">
              {params.row.dateSpan === "Planerad" && (
                <div className="planerad">{Translate.get("Planned")}</div>
              )}
              {params.row.dateSpan === "Avslutad" && (
                <div className="avslutad">
                  {Translate.get("TimeStatus_Past")}
                </div>
              )}
              {params.row.dateSpan === "Pågående" && (
                <div className="pagaende">{Translate.get("Ongoing")}</div>
              )}
            </div>
          );
        },
      },
      {
        field: "fromDate",
        headerName: Translate.get("Date"),
        headerClassName: "hideSeparator",
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CalendarTodayIcon />
            <div>{Translate.get("Date")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <div>{params.row.fromDate && formatDate(params.row.fromDate)}</div>
          );
        },
      },
      {
        field: "city",
        headerName: Translate.get("City"),
        headerClassName: "hideSeparator",
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <PlaceIcon />
            <div>{Translate.get("City")}</div>
          </div>
        ),
        renderCell: (params) => {
          return <div>{params.row.city}</div>;
        },
      },

      {
        field: "responsibleTeacherActorName",
        headerName: Translate.get("Instructor"),
        headerClassName: "hideSeparator",
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CoPresentIcon />
            <div>{Translate.get("Instructor")}</div>
          </div>
        ),
        renderCell: (params) => {
          return <div>{params.row.responsibleTeacherActorName}</div>;
        },
      },
      {
        field: "numberOfParticipants",
        headerName: Translate.get("NoParticipants"),
        headerClassName: "hideSeparator",
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <GroupIcon />
            <div>{Translate.get("NoParticipants")}</div>
          </div>
        ),
      },
      ...(hideDotMenu
        ? []
        : [
            {
              field: "dotMenu",
              flex: 0.3,
              headerName: "",
              sortable: false,
              disableColumnMenu: true,
              filterable: false,
              headerClassName: "hideSeparator",
              align: "right",
              hideable: false,
              renderCell: (params) => (
                <DotMenu
                  content={getCourseDotMenu(
                    params.row.courseId,
                    params.row.numberOfBookings,
                    params.row.publishFromDate,
                    params.row.publishToDate,
                    params.row.isPublishActiveNow,
                    params.row.fromDate,
                    onDeleteCourse
                  )}
                  horizontal
                />
              ),
            },
          ]),
    ];
  }
  //const apiRef = useGridApiRef();
  const handleCellClick = (params, events) => {
    if (!hasAccess(accessKeys.courseAdmin)) {
      return;
    }

    if (params.field !== "dotMenu") {
      setPagination(apiRef, "courses");
      navigate(`/dashboard/course/${params.id}`, { replace: false });
      //console.log(apiRef.current.state.pagination)
      //console.log( apiRef.current.state.pagination.paginationModel.pageSize)
      //const paginationModel = gridPaginationModelSelector(apiRef);
      //console.log(paginationModel.pageSize,paginationModel.page);
      //paginationModel.pageSize=30;
      //paginationModel.page=2;
    }
  };
  const apiRef = useGridApiRef();
  const setPagination = (apiDg, page) => {
    const paginationModel = gridPaginationModelSelector(apiDg);
    sessionStorage.setItem(
      "paginationModel_" + page,
      JSON.stringify(paginationModel)
    );
  };
  const getPagination = (apiDg, page) => {
    //sessionStorage["paginationModel_" + page] ska raderas i  andra komponenter för att nollställa paginering till default
    //Ex: sessionStorage.removeItem("paginationModel_courses");
    if (sessionStorage["paginationModel_" + page]) {
      const paginationModel = gridPaginationModelSelector(apiDg);
      const p = JSON.parse(sessionStorage["paginationModel_" + page]);
      setPaginationModel(p);
      /* setTimeout(() => {
        //Nollstäla om page är för stort, dålig kod
        console.log(apiRef.current.getRowsCount(), p.pageSize, p.page);
        if (apiRef.current.getRowsCount() / p.pageSize < p.page) {
          console.log(
            "Mer än tillgängliga sidor, använd inte sparad paginering"
          );
        } else {
          setPaginationModel(p);
          console.log('Sparad paginering')
        }
      }, 1000); */
    }
  };

  const onFilterChange = useCallback((filterModel) => {
    if (!loadFilters) {
      setFilterModel({ items: [] });
      return;
    }

    filterModel = { filterModel };
    localStorage.setItem("savedFilters", JSON.stringify(filterModel));
  }, []);

  return (
    <>
      <DataGrid
        {...sizeProps}
        expandableRowsOnClick={true}
        className={toolbarCss}
        key="courseDatagrid"
        rows={rows}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        pageSizeOptions={[10, 20, 30]}
        onPaginationModelChange={setPaginationModel}
        disableSelectionOnClick
        getRowId={(row) => row.courseId}
        onCellClick={(params, event) => handleCellClick(params, event)}
        localeText={localizedTextsMap}
        components={{
          Toolbar: MemToolBar,
          NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
        }}
        componentsProps={{
          toolbar: { gridId: "CourseGrid" },
          columnsPanel: { className: "customColumnPanel" },
          filterPanel: { className: "customfilterPanel" },
          panel: { className: "customPanel" },
        }}
        density="comfortable"
        disableDensitySelector={true}
        disableColumnMenu={true}
        disableColumnResize={true}
        onFilterModelChange={onFilterChange}
        initialState={{
          pagination: {
            page: 0,
          },
          sorting: { sortModel: [{ field: "fromDate", sort: "desc" }] },
          filter: filterModel,
        }}
        apiRef={apiRef}
      />
    </>
  );
};
export default Comp;
