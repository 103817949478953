import { Sync } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useGridRootProps } from "@mui/x-data-grid-pro";
import { useEffect, useRef, useState } from "react";

export default function SingleSelectInterval(props) {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState(item.value ?? "");
  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, rootProps.filterDebounceMs);
  };

  const handleUpperFilterChange = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Stack direction="row" width="200px">
      <FormControl fullWidth>
        <InputLabel variant="standard">Min</InputLabel>
        <Select
          variant="standard"
          value={filterValueState[0] ?? ""}
          onChange={handleLowerFilterChange}
        >
          {props.valueOptions.map((o) => (
            <MenuItem
              key={o.value}
              value={o.value}
              disabled={
                filterValueState &&
                filterValueState.length === 2 &&
                filterValueState[1] &&
                filterValueState[1] < o.value
              }
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel variant="standard">Max</InputLabel>
        <Select
          variant="standard"
          value={filterValueState[1] ?? ""}
          onChange={handleUpperFilterChange}
          InputProps={applying ? { endAdornment: <Sync /> } : {}}
        >
          {props.valueOptions.map((o) => (
            <MenuItem
              key={o.value}
              value={o.value}
              disabled={
                filterValueState &&
                filterValueState.length === 2 &&
                filterValueState[0] &&
                filterValueState[0] > o.value
              }
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
