/* eslint-disable */
import { useState } from "react";
import CourseGrid from "../components/courses/CourseGrid";

// material
import { Card, useMediaQuery } from "@mui/material";
// components
import { useApi } from "../utils/Api";
import Preloader from "../components/Preloader";
import SpeedMenu from "../components/SpeedMenu";
import Translate from "../utils/Translate";
import AddIcon from "@mui/icons-material/Add";
import CourseDialog from "../components/courses/CourseDialog";
import YesOrNoDialog from "../components/YesOrNoDialog";
import Button from '@mui/material/Button';
import { accessKeys, hasAccess } from '../utils/userAccess';
import Stack from '@mui/material/Stack';

const getSpeedMenu = (props) => {
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("AddCourse"),
      route: false,
      dialogHeader: Translate.get("AddCourse"),
      customClass: "bigCourseDialog noCloseButton",
      component: <CourseDialog {...props} />,
    },
  ];
  return menudata;
};

const Comp = (props) => {
  const smallScreen = useMediaQuery("(max-width:700px)");
  const api = useApi();

  // --------------------------------------
  //const navigate = useNavigate();
  const [courseIdToDelete, setCourseIdToDelete] = useState(null);
  const [courses, setCourses] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  if (!dataFetched) {
    const req = {};
    req.page = 0;
    req.pageSize = 10000;
    req.sortFieldName = "CourseId", // sort by courseId may be more efficient than FromDate ?
      req.sortOrder = "desc"

    ///req.filter={};
    ///req.filter.courseTypes=[1,2,3,4,5,6,7,8,9];
    /*   if (sessionStorage.courses && sessionStorage.courses !== false) {
        setDataFetched(true);
        setData(JSON.parse(sessionStorage.courses));
        //console.log(
          'sessionStorage.courses',
          JSON.parse(sessionStorage.courses),
        );
      } else { */
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/get-filtered/`,
      req
    ).then((data) => {
      setDataFetched(true);
      // const rows=data;
      if (data) {
        //const dataFix = parseData(data);
        //setData(dataFix);
        setCourses(data);
        //sessionStorage.setItem('courses', JSON.stringify(data));
      }
    });
    //}
  }

  async function handleDeleteCourse() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/delete`,
      { courseId: courseIdToDelete },
      "POST"
    );
    if (response && response.isSuccessful) {
      setCourses(courses.filter((c) => c.courseId !== courseIdToDelete));
    }
    setCourseIdToDelete(null);
  }

  function handleViewChange() {
    localStorage.setItem("courseView", "calendar")
    window.location.reload();
  }

  return (
    <>
      {!dataFetched && <Preloader invisible={true} />}
      <Stack direction="row" spacing={2}>
      <h2>{Translate.get("Courses")}</h2>
      {hasAccess(accessKeys.advancedPackage) &&
            <Button
            onClick={() => handleViewChange()}
          >
            {Translate.get("ShowCalendar")}
          </Button>
          }
      </Stack>
      
      <Card> {/* Om autoSize={true} sx={{ height: "100%" }}, om inte ta bort*/}
        {dataFetched && (
          <CourseGrid
            rows={courses}
            smallScreen={smallScreen}
            autoSize={false} //Tycker detta är bättre, speciellt för mobil
            onDeleteCourse={(courseId) => setCourseIdToDelete(courseId)}
            loadFilters={true}
          />
        )}
      </Card>
      <SpeedMenu
        content={getSpeedMenu({
          id: 0,
          someThingElse: "En annan prop till dot menu",
        })}
      />
      <YesOrNoDialog
        open={courseIdToDelete > 0}
        title={Translate.get("RemoveCourse")}
        text={Translate.get("DoUWantToContinueWithDeleteCourse")}
        onNo={() => setCourseIdToDelete(null)}
        onYes={handleDeleteCourse}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Delete")}
      />
    </>
  );
};
export default Comp;
