import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import BarChartIcon from '@mui/icons-material/BarChart';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const SetIcon = {
    ImageIcon: <ImageIcon />,
    InboxIcon: <InboxIcon />,
    MailIcon: <MailIcon />,
    HelpOutlineIcon: <HelpOutlineIcon/>,
    DashboardIcon: <DashboardIcon />,
    SchoolIcon: <SchoolIcon />,
    FolderCopyIcon: <FolderCopyIcon />,
    VpnKeyIcon: <VpnKeyIcon />,
    SettingsIcon: <SettingsIcon />,
    BadgeIcon: <BadgeIcon />,
    DescriptionIcon: <DescriptionIcon />,
    PersonIcon: <PersonIcon />,
    BarChartIcon: <BarChartIcon />,
    AddIcon: <AddIcon />,
    ReceiptLongIcon: <ReceiptLongIcon />
    //Ev Mappa så det passar med backend
  };
export default function FolderList({icon='HelpOutlineIcon',title='', text='' ,link,callBack}) {
  return (
    <ListItem  onClick={() => callBack(link)}>
      <ListItemAvatar>
        <Avatar sx={{backgroundColor:'inherit',color:'inherit'}}>
         {SetIcon[icon]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={
        <span  dangerouslySetInnerHTML={{ __html: text }}></span>
      } />
    </ListItem>
  );
}
