import Api from "./Api";

export async function getLicenseRegistrationCourseTypes() {
  if (!sessionStorage.licenseRegistrationCourseTypes) {
    const data = await Api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups/for-license-registration/available`,
      false,
      "GET"
    );

    if (data && data.length > 0) {
      sessionStorage.setItem(
        "licenseRegistrationCourseTypes",
        JSON.stringify(data)
      );
    }
  }

  // TODO bättre alternativ än sessionStorage?
  return JSON.parse(sessionStorage.licenseRegistrationCourseTypes);
}
