import { Box, Stack, Avatar } from "@mui/material";
import ConditionalWrapper from "./ConditionalWrapper";
import DotMenu from "./DotMenu";

export default function ActorAvatarBox({
  name,
  profilePictureUrl,
  rows,
  dotMenuActions,
  link,
}) {
  return (
    <ConditionalWrapper
      condition={link}
      wrapper={(children) => (
        <a className="RemoveHyperLinkDecorations" href={link}>
          {children}
        </a>
      )}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        sx={{ padding: "8px" }}
      >
        <Stack direction="row" spacing={2.5} sx={{ padding: "8px" }}>
          <Avatar alt={name} src={profilePictureUrl} />
          <Stack
            direction="column"
            spacing={0.4}
            justifyContent="space-between"
            alignItems="start"
            className="avatarInfo"
          >
            <Box>{name}</Box>
            {rows && rows.map((row, i) => <Box key={i}>{row}</Box>)}
          </Stack>
        </Stack>
        {dotMenuActions && <DotMenu horizontal content={dotMenuActions} />}
      </Stack>
    </ConditionalWrapper>
  );
}
