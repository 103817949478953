import React from 'react';
import CourseCalendar from "../components/courses/CourseCalendar";
import Courses from "./Courses";
import { accessKeys, hasAccess } from '../utils/userAccess';

export default function CoursesRoute() {
    if (hasAccess(accessKeys.advancedPackage) && localStorage.getItem("courseView") === "calendar") {
        return (<CourseCalendar />);
    }
    return <Courses />
}