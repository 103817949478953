import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate, useLocation } from 'react-router-dom';
import Translate from '../../../utils/Translate';
import { useApi } from '../../../utils/Api';
import FormControl from '@mui/material/FormControl';
import YesOrNoDialog from '../../YesOrNoDialog';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TextField } from '@mui/material';
import Preloader from '../../Preloader';

let fakeId = 0;
let deletedRows = [];
const Comp = (props) => {
  //----------Finns alltid med
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };
  //----------
  const [data, setData] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  if (!data) {
    //Api call här med props.actorId som input
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${props.actorId}/notes/`,
      );
      // output all data & actions
      setData(data.actorFreeTexts);
      fakeId = 0;
      deletedRows = [];
    })();
  }
  const cofirmRemove = (id) => {
    setShowDelete({ id: id });
  };
  const remove = (id) => {
    setShowDelete(false);
    if (id > 0) {
      deletedRows.push(id);
    }
    const newData = data.filter((x) => x.actorFreeTextId !== id);
    setData(newData);
  };
  const addNew = () => {
    fakeId--;
    setData((data) => [
      ...data,
      { actorFreeTextId: fakeId, textNote: '', actorId: props.actorId },
    ]);
  };
  const submit = () => {
    //Ta bara med sådana som är ändrade/skapade
    let outData = data.filter((x) => x.crudState === 'update');
    //Ta inte med ev tomma
    outData = outData.filter((x) => x.textNote !== '');
    //Sätt alla nya/create till actorFreeTextId=0
    outData = outData.map((obj) =>
      obj.actorFreeTextId < 0 ? { ...obj, actorFreeTextId: 0 } : obj,
    );
    //Ta bort crudState flaggan
    outData = outData.map(({ crudState, ...rest }) => {
      return rest;
    });
    
    //Api C_UD + props.actorId call som input här  +  closeAndReload();
    //Kolla med Juri vad som är mandatory
    //return false;
    const deletePosts = (deletedArray) => {
      (async () => {
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/notes/delete`,
          deletedRows,
          'POST',
        );
        closeAndReload();
      })();
    };

    if (outData.length > 0) {
      (async () => {
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/${props.actorId}/notes/`,
          outData,
          'POST',
        );
        // output all data & actions
        if (deletedRows.length > 0) {
          deletePosts(deletedRows);
        }
        else{
          closeAndReload();
        }
      })();
    }
    else{
      if (deletedRows.length > 0) {
        deletePosts(deletedRows);
      }
      else{
        closeAndReload();
      }
    }
   
  };

  return (
    <>
       {!data && <Preloader />}
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormControl fullWidth>
            {data &&
              data.map((row, index) => (
                <div
                  className="stdFlexLeftCenter"
                  style={{ marginBottom: '32px' }}
                >
                  <TextField
                    autoFocus={true}
                    /*   InputProps={{
                    endAdornment: <InputAdornment position="end"><IconButton onClick={() => cofirmRemove(row.id)}>
                   <DeleteForeverIcon />
                  </IconButton></InputAdornment>, 
                  }}*/
                    key={index}
                    multiline
                    rows={4}
                    fullWidth
                    value={row.textNote}
                    label={Translate.get('Note')}
                    inputProps={{ maxLength: 255 }}
                    onChange={(event, value) => {
                      row.textNote = event.target.value;
                      row.crudState = 'update';
                      setData([...data]);
                    }}
                  />
                  <IconButton onClick={() => cofirmRemove(row.actorFreeTextId)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<AddCircleIcon />} onClick={() => addNew()}>
          {Translate.get('AddNote')}
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        <Button onClick={() => window.history.back()}>
          {Translate.get('Cancel')}
        </Button>
        <Button onClick={submit}>{Translate.get('Save')}</Button>
      </DialogActions>
      <YesOrNoDialog
        open={showDelete ? true : false}
        title={Translate.get('DeleteNote')}
        text={false}
        onNo={() => setShowDelete(false)}
        onYes={() => remove(showDelete.id)}
        noText={Translate.get('Cancel')}
        yesText={Translate.get('Delete')}
      />
    </>
  );
};
export default Comp;
