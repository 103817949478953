import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";

export default function ELearningLicenseCount({
  courseId,
  eLearningType,
  onAvailableLicenseCountChanged,
}) {
  if (courseId && eLearningType) {
    throw new Error("Use either courseId or eLearningType");
  }

  const [freeLicenseCount, setFreeLicenseCount] = useState(null);
  const api = useApi();

  useEffect(() => {
    const url = courseId
      ? `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${courseId}/count`
      : `${process.env.REACT_APP_MAIN_URL}licenses/elearning/count/${eLearningType}`;
    api.fetch(url, false, "GET").then((data) => {
      if (!isNaN(data)) {
        setFreeLicenseCount(data);
        onAvailableLicenseCountChanged && onAvailableLicenseCountChanged(data, eLearningType);
      }
    });
  }, [courseId, eLearningType, onAvailableLicenseCountChanged, api]);

  return (
    <Box
      className="textbox"
      sx={{
        height: "56px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        paddingLeft: "12px",
        paddingRight: "12px",
      }}
    >
      <Typography noWrap>
        {`${Translate.get("LicenseBalance")}: ${freeLicenseCount ?? ""}`}
      </Typography>
      {freeLicenseCount === null ? (
        <CircularProgress
          color="inherit"
          size={20}
          sx={{ marginLeft: "6px" }}
        />
      ) : null}
    </Box>
  );
}
