import Translate from '../../utils/Translate';
import Api from "../../utils/Api";

export function getCourseParticipantListFile(
  courseId,
  toggleLoading,
  enqueueSnackbar,
  closeSnackbar) {
  window.analyticsEvent("eport_xtras","eport_export_participants_pdf");
  toggleLoading(true);
  const fileName = Translate.get("ParticipantList");
  let languageCode = localStorage.getItem('lang') ?? 9;

  let serviceUrl = `courses/excel/courseparticipantlist/${courseId}/${languageCode}`;
  Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
    false,
    'GET',
    false,
    true,
    true
  )
    .then((data) => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    })
    .catch(function () {
      const errorMessage = enqueueSnackbar(Translate.get('SomethingFailed'), {
        variant: 'error',
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    })
    .finally((f) => {
      toggleLoading(false);
    });
}
