import { useRef, useState } from 'react';
import {
  useNavigate as UseNavigate,
} from 'react-router-dom';
// material
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import Translate from '../../utils/Translate';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: '9',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    value: '12',
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg',
  },
  {
    value: '29',
    label: 'Swedish',
    icon: '/static/icons/ic_flag_se.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover(props) {
  const navigate = UseNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  let langIndex = 0;
  if (localStorage.lang) {
    langIndex = LANGS.findIndex((x) => x.value === localStorage.lang);
  }

  const [selectedIndex, setSelectedIndex] = useState(langIndex);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const changeLang = (index, lang) => {
    setOpen(false);
    setSelectedIndex(index);
    const loadTranslations = props.isPreLogin
      ? Translate.loadPreLogin(lang)
      : Translate.loadApp(lang);
    loadTranslations.then((data) => {
      navigate(0);
    });
  };
 if (window.global.isKs===true) {
  return <></>
 }
  return (
    <>
    <div className='stdFlexLeft16 profileActiveNav'  onClick={handleOpen}>
      <IconButton
        ref={anchorRef}
        //onClick={handleOpen}
        sx={{
          padding: 0,
          width: 20,
          height: 20,
          overflow: 'hidden',
         /*  ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity,
              ),
          }), */
        }}
      >
        <img src={LANGS[selectedIndex].icon} alt={LANGS[selectedIndex].label} />
      </IconButton>
      <div>{Translate.get('Language')}</div>
      </div>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option, index) => (
            <MenuItem
              key={option.value}
              selected={index === selectedIndex}
              onClick={(event) => changeLang(index, option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={option.label}
                  src={option.icon}
                  sx={{
                    padding: 0,
                    width: 40,
                    height: 40,
                    overflow: 'hidden',
                    borderRadius:'50%'
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
