import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import Translate from "../../../utils/Translate";
import ActorAvatar from "../../ActorAvatar";
import SwipeableViews from "react-swipeable-views";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import { DatePicker } from "@mui/x-date-pickers";
import { getCurrentDateMask, getCurrentInputFormat } from "../../../utils/dateTimeFormat";

export default function StudentCourseGradesDialog({
  open,
  onClose,
  skills,
  students,
  selectedActorId,
  skillsByActorId,
  licensesByActorId,
  handleCheckSkillChange,
}) {
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    const selectedActorIndex = students.findIndex(
      (student) => student.actorId === selectedActorId
    );
    setIndex(selectedActorIndex);
  }, [selectedActorId, students]);

  // TODO Höger och vänster knapparna hamnar olika högt om man har mail eller inte på actorn
  // Dialog PaperProps={{ sx: { maxWidth: "400px" } }}?
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{Translate.get("ApproveSomeSkills")}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {/* enableMouseEvent enables swiping with a mouse */}
        {index > -1 && (
          <SwipeableViews
            index={index}
            onChangeIndex={(idx) => setIndex(idx)}
            enableMouseEvents
          >
            {students.map((student) => (
              <Box key={student.actorId} sx={{ overflowX: "hidden" }}>
                <Box className="studentGradesAvatarContainer">
                  <IconButton
                    onClick={() => setIndex(index - 1)}
                    disabled={index === 0}
                  >
                    {/* Setting viewBox due to this icon not being centered */}
                    <ArrowLeftIcon viewBox="-6 0 24 24" />
                  </IconButton>
                  <ActorAvatar
                    name={student.name}
                    email={student.email}
                    vertical
                  />
                  <IconButton
                    onClick={() => setIndex(index + 1)}
                    disabled={index === students.length - 1}
                  >
                    <ArrowRightIcon />
                  </IconButton>
                </Box>
                <Divider />
                {!!licensesByActorId[student.actorId].date && (
                  <Box className="studentGradesLicenseDateContainer">
                    <DatePicker
                      disableOpenPicker={true}
                      label={Translate.get("LicenseDate")}
                      mask={getCurrentDateMask()}
                      inputFormat={getCurrentInputFormat()}
                      disabled={true}
                      InputProps={{ sx: { maxWidth: "158px" } }}
                      value={licensesByActorId[student.actorId].date}
                      //onChange={() => console.log("date changed")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: Translate.get("DatePlaceholder"),
                          }}/>
                      )}
                    />
                  </Box>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    margin: "1.5em 24px 0px",
                    textTransform: "uppercase",
                  }}
                >
                  {Translate.get("Competences")}
                </Typography>
                <FormControl>
                  <FormGroup aria-label="position">
                    {skills.map((skill) => (
                      <FormControlLabel
                        className="studentGradesSkillCheckbox"
                        control={
                          <Checkbox
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            checked={
                              !!skillsByActorId[student.actorId] &&
                              !!skillsByActorId[student.actorId][skill.skillId]
                            }
                            onChange={(event) =>
                              handleCheckSkillChange(
                                event,
                                student.actorId,
                                skill.skillId
                              )
                            }
                            disabled={!!licensesByActorId[student.actorId].date}
                          />
                        }
                        label={
                          <Typography sx={{ marginLeft: "14px" }} noWrap>
                            {`${skill.shortCode} ${skill.description}`}
                          </Typography>
                        }
                        labelPlacement="end"
                        key={skill.skillId}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </SwipeableViews>
        )}
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
