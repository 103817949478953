import { isMobile } from "is-mobile";

export function getBrowserTypeForBankId()
{
    let browserType = "desktop";

    if(isMobile({tablet: true, featureDetect: true })) {
        browserType = "mobile";

        if(/iPhone|iPad|iPod/.test(navigator.userAgent)) {
            // in iPhone / iPad it is important to know if it is not the default browser (Safari)
            if(/CriOS/.test(navigator.userAgent)) {
                browserType = "iPhoneChrome"
            } else if(/FxiOS/.test(navigator.userAgent)) {
                browserType = "iPhoneFirefox"
            } else if(/OPT/.test(navigator.userAgent)) {
                browserType = "iPhoneOperaTouch"
            }
        } else if(/Android/.test(navigator.userAgent)) {
            browserType = "android";
        }
    }

    return browserType;
}
