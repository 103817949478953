import { useEffect, useState, useRef } from 'react';
import { FormControl, FormLabel } from '@mui/material';
import { Editor as TinyMceEditor } from "@tinymce/tinymce-react";

let imgSrcs = []; // global imageSrc variable to save original img src attributes
const wrapImages = (html, images) => {
    imgSrcs = [];
    let i = 0;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const imgs = doc.querySelectorAll("img");
    for(let img of imgs) {
        const origSrc = img.src;
        // replace images starting with cid: and replace them with data urls 
        if(origSrc.match(/^cid:/)) {
            imgSrcs[i++] = origSrc;
            const name = origSrc.replace(/^cid:/, ""); // remove cid: at beginning 
            const imgData = images.find(x => JSON.stringify(x.name) === JSON.stringify(name));
            if(imgData) {
                img.setAttribute("data", img.src);
                img.setAttribute("src", imgData.url);
            }
        }

    }

    return doc.documentElement.innerHTML;
};

const unWrapImages = (html) => {
    let i = 0;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const figs = doc.querySelectorAll("img");
    for(let fig of figs) {
        fig.src = imgSrcs[i++];
    }

    return doc.documentElement.innerHTML;
};

const HtmlEditor = ({label, htmlContent, images, onBlur, disabled}) => {
    const [editorHasFocus, setEditorHasFocus] = useState(false);
    const [contentWithImages, setContentWithImages] = useState("");
    const editorRef = useRef();

    useEffect(() => {
        //debugger;
        const html = wrapImages(htmlContent, images);
        setContentWithImages(html);
    }, [htmlContent, images])

    const editorBlur = (evt) =>{
        setEditorHasFocus(false);
        let content = evt.target.getContent();
        content = unWrapImages(content);
        //console.log("email content", content);
        onBlur(content);
    };

    const editorFocus = () =>{
        setEditorHasFocus(true);
    };

    return (
    <>
        {/* this FormControl type"fieldset" will provide the border and the label for the editor */}
        <FormControl  component="fieldset"
            sx={{
                width: "100%", // maybe need to be changed
                padding: 1, 
                borderColor: (theme) =>  (editorHasFocus ? theme.palette.info.main : theme.palette.grey[400]) + " !important",
                "&:hover": {
                    borderColor: (theme) =>  (editorHasFocus ? theme.palette.info.main : "black") + " !important",
                }, 
                border: editorHasFocus ? 2 : 1, 
                borderRadius:1}}
            >
            {label && <FormLabel component="legend" sx={{pl: 1, pr: 1, fontSize: 11, color: (theme) => (editorHasFocus ? theme.palette.info.main  : null)}}>{label}</FormLabel>}
            <TinyMceEditor
              tinymceScriptSrc="/static/tinymce/tinymce.min.js"
              disabled={disabled}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={contentWithImages}
              value={null}
              onBlur={editorBlur}
              onFocus={editorFocus}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist", "autolink", "lists", "link", "image",  "anchor",
                   "visualblocks", "media", "table"
                ],
                toolbar: 'undo redo | styleselect | hr | bold italic underline | link | bullist numlist ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                branding: false,
                elementpath: false
              }}
            />
        </FormControl>
    </>);
};

export default HtmlEditor;