import { CircularProgress, Box } from "@mui/material";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import NotesIcon from "@mui/icons-material/Notes";
import Avatar from "@mui/material/Avatar";
//import HeadsetIcon from '@mui/icons-material/Headset';
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import StudentTable from "./StudentTable";
import StudentCards from "./StudentCards";
import { Fragment } from "react";
import Translate from "../../utils/Translate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DotMenu from "../../components/DotMenu";
import CourseDialog from "../courses/CourseDialog";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import SendPassword from "./dialogs/SendPassword";
import { useApi } from "../../utils/Api";
import LinkIcon from "@mui/icons-material/Link";
import PersonCrud from "../actors/crud/PersonCrud";
import ChangeContactDialog from "./dialogs/ChangeContactDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { hasAccess, hasSomeAccess, accessKeys } from "../../utils/userAccess";
import PublishToWeb from "../courses/PublishToWeb";
import PublicIcon from "@mui/icons-material/Public";
import { Link, useNavigate } from "react-router-dom";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DocUpload from "../actors/dialogs/DocUpload";
import CourseInvitation from "./dialogs/CourseInvitation";
import { getCourseParticipantListFile } from "./getCourseParticipantListFile";
import Preloader from "../Preloader";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ChangeOrderConfirmationDetails from "./dialogs/ChangeOrderConfirmationDetails";
import { Typography } from "@mui/material";
import { formatCurrency } from "../../utils/formatNumber";
import { ShoppingCart } from "@mui/icons-material";
import { CommunicationRole, DocumentType, findSentCommunication, MediaType, prepareSentCommunications } from "./CourseCommunication";

async function updateCourseBookingContact(
  api,
  courseBookingId,
  courseId,
  bookerActorId,
  numberOfParticipants,
  newContactActorId
) {
  if (
    !(
      courseId > 0 &&
      courseBookingId > 0 &&
      numberOfParticipants > 0 &&
      bookerActorId > 0
    )
  ) {
    throw new Error("Cannot change contact. Missing booking data");
  }

  const dto = {
    courseId,
    courseBookingId,
    numberOfParticipants,
    bookerActorId,
    contactActorId: newContactActorId,
  };

  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/bookings/`,
    dto,
    "POST"
  );

  if (response && response.isSuccessful) {
    return true;
  } else {
    // TODO show error
    return false;
  }
}

const getSendPasswordProps = (props, action) => ({
  action: action ?? "sendToContacts",
  courseId: props.courseId,
  eLearningType: props.eLearningType,
  courseBookings:
    props.courseBookings?.map((cb) => ({
      courseBookingId: cb.courseBookingId,
      companyName: cb.companyName,
      contactActorName: cb.contactActorName,
      contactActorEmail: cb.contactActorEmail,
      contactActorId: cb.contactActorId,
      courseParticipants:
        cb.courseParticipants
          ?.filter(
            (cp) => !cp.courseParticipantELearningLicenseId?.includes("*")
          )
          .map((cp) => ({
            name: cp.courseParticipantActorName,
            email: cp.courseParticipantEmail,
            actorId: cp.courseParticipantActorId,
            eLearningLicenseId: cp.courseParticipantELearningLicenseId,
            courseParticipantId: cp.courseParticipantId,
          })) ?? [],
    })) ?? [],
  loadCourse: props.loadCourse,
});

const getBookingDotMenu = (props) => {
  let isELearningCourse = eLearningCourse(props.eLearningType);
  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditContact"),
      route: false,
      // dialogHeader: Translate.get('EditContact'),
      component: (
        <PersonCrud
          actorId={props.contact.actorId}
          actorRole="contact"
          parentActorId={props.bookerActorId}
          onActorSaved={async (newContact) =>
            await updateCourseBookingContact(
              props.api,
              props.courseBookings[0].courseBookingId,
              props.courseId,
              props.bookerActorId,
              props.numberOfParticipants,
              newContact.actorId
            )
          }
        />
      ),
      disabled: props.isInternalBooking || props.isPrivateBooking,
    },
    {
      icon: <HeadsetMicIcon />,
      text: Translate.get("ChangeContact"),
      route: false,
      dialogHeader: Translate.get("ChangeContact"),
      component: (
        <ChangeContactDialog
          {...props}
          currentContact={props.contact}
          onContactChanged={props.loadCourse}
          courseBookingId={props.courseBookings[0].courseBookingId}
        />
      ),
      disabled: props.isInternalBooking || props.isPrivateBooking,
    },
    {
      icon: <VpnKeyIcon />,
      text: Translate.get("SendPassword"),
      dialogHeader: Translate.get("SendPassword"),
      route: false,
      component: <SendPassword {...getSendPasswordProps(props)} />,
      hide: !isELearningCourse,
      customClass: "mediumCourseDialog",
    },
    {
      icon: <AddAlertIcon />,
      text: Translate.get("SendCourseInvitation"),
      dialogHeader: Translate.get("SendCourseInvitation"),
      route: false,
      component: <CourseInvitation {...getSendPasswordProps(props)} />,
      hide: !hasAccess(accessKeys.advancedPackage),
      customClass: "mediumCourseDialog",
    },
    {
      icon: <RequestQuoteIcon />,
      text: Translate.get("OrderConfirmation"),
      dialogHeader: Translate.get("OrderConfirmation"),
      route: false,
      component: (
        <ChangeOrderConfirmationDetails
          orderId={props.courseBookings[0].orderId}
          startDate={props.fromDate}
          loadCourse={props.loadCourse}
        />
      ),
      hide: !hasAccess(accessKeys.advancedPackage),
      customClass: "mediumCourseDialog",
    },
  ];
  return menudata;
};
const getCourseDotMenu = (props) => {
  let isELearningCourse = eLearningCourse(props.eLearningType);
  let hasNoCourseBookings = props.courseBookings?.length < 1;

  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditCourse"),
      route: false,
      dialogHeader: Translate.get("EditCourse"),
      component: <CourseDialog {...props} />,
      hide: false,
    },
    {
      icon: <PublicIcon />,
      text: props.isPublishActiveNow
        ? Translate.get("ePort2iFrameUnpublish")
        : Translate.get("ePort2iFramePublishOnline"),
      route: false,
      //dialogHeader: isPublishActiveNow ? 'Avpublicera' : 'Publicera online', //Translate.get("PublishToWeb"),
      component: (
        <PublishToWeb
          courseId={props.courseId}
          publishFromDate={props.publishFromDate}
          publishToDate={props.publishToDate}
          isPublishActiveNow={props.isPublishActiveNow}
          fromDate={props.fromDate}
        />
      ),
      hide: !hasAccess(accessKeys.iframe),
    },
    {
      icon: <AttachmentIcon />,
      text: Translate.get("Document") + " (" + props.files.length + ")",
      //dialogHeader: Translate.get('Document'),
      customClass: "mediumCourseDialog",
      route: false,
      component: (
        <DocUpload actorId={props.courseId} files={props.files} type="course" />
      ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      ),
    },
    {
      icon: <VpnKeyIcon />,
      text: Translate.get("SendPassword"),
      dialogHeader: Translate.get("SendPassword"),
      route: false,
      component: <SendPassword {...getSendPasswordProps(props)} />,
      hide: !isELearningCourse,
      customClass: "mediumCourseDialog",
      disabled: !props.courseBookings || props.courseBookings.length === 0,
    },
    {
      icon: <AddAlertIcon />,
      text: Translate.get("SendCourseInvitation"),
      route: false,
      dialogHeader: Translate.get("SendCourseInvitation"),
      component: (
        <CourseInvitation {...getSendPasswordProps(props, "sendToAll")} />
      ),
      hide: !hasAccess(accessKeys.advancedPackage) || hasNoCourseBookings,
    },
    {
      icon: <LinkIcon />,
      text: getGoToELearningTitle(props.eLearningType),
      route: false,
      dialogHeader: Translate.get("EditCourse"),
      onClick: () => handleGoToElearningClick(props.api, props.courseId),
      hide: !isELearningCourse,
    },
    {
      icon: <GroupsIcon />,
      text: Translate.get("CreateParticipantList"),
      route: false,
      onClick: () =>
        getCourseParticipantListFile(props.courseId, (isLoading) =>
          props.setIsLoading(isLoading)
        ),
      hide:
        !hasSomeAccess(
          accessKeys.standardPackage,
          accessKeys.advancedPackage
        ) || hasNoCourseBookings,
    },
    {
      icon: <QrCode2Icon />,
      text: Translate.get("SelfReg"),
      route: false,
      dialogHeader: Translate.get("SelfReg"),
      onClick: () =>
        window.open(
          `${window.location.origin}/dashboard/course/${props.courseId}/selfreg`,
          "_blank"
        ),
      hide:
        !hasSomeAccess(
          accessKeys.standardPackage,
          accessKeys.advancedPackage
        ) || hasNoCourseBookings,
    },
    {
      icon: <ShoppingCart />,
      text: Translate.get("GoToOrders"),
      onClick: () =>
        props.navigate(`/dashboard/orders?filterCourseId=${props.courseId}`, {
          replace: false,
        }),
      disabled: props.courseBookings.length === 0,
      hide: !hasAccess(accessKeys.licenseIssuer, accessKeys.courseAdmin),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("RemoveCourse"),
      disabled: props.courseBookings && props.courseBookings.length > 0,
      dialogHeader: Translate.get("RemoveCourse"),
      onClick: props.onDeleteCourse,
      hide: false,
    },
  ];
  return menudata;
};

function getCourseFreeText(courseFreeText) {
  if (courseFreeText?.length > 40) {
    return courseFreeText.slice(0, 40) + "...";
  }
  return courseFreeText;
}

async function getELearningUrl(api, courseId) {
  return await api
    .fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/elearning/url`,
      false,
      "GET"
    )
    .then((data) => {
      if (data.isSuccessful) {
        return data.eLearningUrl;
      } else {
        return "";
      }
    })
    .finally((f) => {
      // setIsLoading(false);
    });
}

async function handleGoToElearningClick(api, courseId) {
  let elearningUrl = await getELearningUrl(api, courseId);
  if (elearningUrl !== "") {
    setTimeout(() => {
      window.open(elearningUrl, "_blank").focus();
    });
  }
}

function getGoToELearningTitle(eLearningType) {
  if (eLearningType === 1 || eLearningType === 3) {
    return Translate.get("OpenETruck");
  } else if (eLearningType === 2) {
    return Translate.get("OpenELift");
  } else if (eLearningType === 4) {
    return Translate.get("OpenEFall");
  }
}

function eLearningCourse(eLearningType) {
  if (
    eLearningType !== undefined &&
    eLearningType !== null &&
    eLearningType > 0
  ) {
    return true;
  }
  return false;
}

const Big = (props) => {
  const course = props.data;
  const api = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  course.courseBookings.sort((a, b) =>
    a.actor.actorName.localeCompare(b.actor.actorName)
  );
  
  let communicationMap = prepareSentCommunications(course.courseCommunications);

  const stats = props.stats;
  function StaticEmptyBox() {
    return (
      <div className="staticEmptyBox">
        <div>
          <img src="/static/no-data-empty-chair.svg" alt="" />
        </div>
        <div>
          <h3> {Translate.get("NoCourseParticipants")}</h3>
        </div>
        <div>
          <span>{Translate.get("ClickOn")}</span>
          <AddCircleIcon sx={{ color: "rgb(0, 77, 140)" }} />{" "}
          <span>{Translate.get("ToAddParticipant")}</span>
        </div>
      </div>
    );
  }
  function CircularProgressWithIcon(props) {
    /*
    Har påbörjat utbildning people 
    Medelresultat övningsuppgifter equalizer
    Har påbörjat slutprovet people
    props result,text,icon
    
    
    */
    //let result= props.value;
    let result = 0;
    if (!isNaN(props.dataNum)) {
      result = Math.round(props.dataNum * 100);
    }
    return (
      <div>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            className="customCP"
            variant="determinate"
            sx={{
              color: "silver",
            }}
            //size={96}
            thickness={2}
            value={100}
          />
          <CircularProgress
            className="customCP"
            variant="determinate"
            //size={96}
            thickness={2}
            value={result}
            color="warning"
            sx={{
              position: "absolute",
              left: 0,
              color: props.color,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.icon}
          </Box>
        </Box>
        <Box>
          <h1>{props.dataStr}</h1>
          {props.text}
        </Box>
      </div>
    );
  }

  return (
    <>
      {isLoading && <Preloader />}
      <div className="courseInfo baseInfo">
        <div>
          <Avatar
            alt={course.name}
            src={course.imageUrl}
            variant="rounded"
            sx={{ width: 60, height: 60, fontSize: 40 }}
          ></Avatar>
          <div>
            <h1>{course.name}</h1>
            <div>{course.description}</div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <DriveFileRenameOutlineSharpIcon />
              <div>{course.shortComment}</div>
            </div>
            <div>
              <CalendarTodayIcon />
              <div>
                {" "}
                {tryFormatDate(course.fromDate)} -{" "}
                {tryFormatDate(course.toDate)}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <PlaceIcon />
              <div>{course.city}</div>
            </div>
            <div>
              <CoPresentIcon />
              <Link
                className="RemoveHyperLinkDecorations"
                to={"/dashboard/person/" + course.responsibleTeacherActorId}
              >
                {" "}
                {course.responsibleTeacherActorName}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <PeopleIcon />
              {course.numberOfParticipants}
            </div>
            {course.courseFreeText ? (
              <Tooltip title={course.courseFreeText}>
                <div>
                  <NotesIcon />
                  {getCourseFreeText(course.courseFreeText)}
                </div>
              </Tooltip>
            ) : (
              <div></div>
            )}
            {/*  {course.files.length && (
              <div>
                <AttachmentIcon />
                {course.files.length}
              </div>
            )} */}
          </div>
        </div>
        <div className="courseInfoDots">
          <DotMenu
            content={getCourseDotMenu({
              courseId: course.courseId,
              id: course.bookingId,
              eLearningType: course.eLearningType,
              publishFromDate: course.publishFromDate,
              publishToDate: course.publishToDate,
              isPublishActiveNow: course.isPublishActiveNow,
              fromDate: course.fromDate,
              files: course.files,
              onDeleteCourse: props.onDeleteCourse,
              courseBookings: course.courseBookings?.map((cb) => ({
                companyName: cb.actor.actorName,
                courseBookingId: cb.courseBookingId,
                contactActorName: cb.contactActorName,
                contactActorEmail: cb.contactActorEmail,
                contactActorId: cb.contactActorId,
                courseBookingPrice: cb.courseBookingPrice,
                courseParticipants: cb.courseParticipants?.map((cp) => ({
                  courseParticipantActorName: cp.actor?.actorName,
                  courseParticipantEmail: cp.actor?.actorEmail,
                  courseParticipantActorId: cp.actor?.actorId,
                  courseParticipantELearningLicenseId:
                    cp.eLearningLicense?.eLearningLicenseId,
                  courseParticipantId: cp.courseParticipantId,
                  selectedELearningLanguageCode:
                    cp.eLearningLicense?.eTruckLanguageCode,
                })),
                allowedLanguages: props.langs,
              })),
              loadCourse: props.loadCourse,
              api: api,
              setIsLoading: setIsLoading,
              navigate: navigate,
            })}
          />
        </div>
      </div>
      {course.isELearningCourse && (
        <div className="statBox">
          <CircularProgressWithIcon
            dataStr={stats.partOfParticipantsWithStartedELearning?.dataStr}
            dataNum={stats.partOfParticipantsWithStartedELearning?.dataNum}
            color={stats.partOfParticipantsWithStartedELearning?.color}
            text={Translate.get("HasStartedELearning")}
            icon={<PeopleIcon />}
          />
          <CircularProgressWithIcon
            dataStr={stats.meanProgressColor?.dataStr}
            dataNum={stats.meanProgressColor?.dataNum}
            color={stats.meanProgressColor?.color}
            text={Translate.get("AveragePerformanceExercises")}
            icon={<EqualizerIcon />}
          />
          <CircularProgressWithIcon
            dataStr={stats.partOfParticipantsWithFinalExamDoneOk?.dataStr}
            dataNum={stats.partOfParticipantsWithFinalExamDoneOk?.dataNum}
            color={stats.partOfParticipantsWithFinalExamDoneOk?.color}
            text={Translate.get("HasPassedELearningKPI")}
            icon={<PeopleIcon />}
          />
          <CircularProgressWithIcon
            dataStr={stats.meanFinalExamScore?.dataStr}
            dataNum={stats.meanFinalExamScore?.dataNum}
            color={stats.meanFinalExamScore?.color}
            text={Translate.get("AveragePerformanceFinalExam")}
            icon={<SchoolIcon />}
          />
        </div>
      )}
      {!course.courseBookings?.length && <StaticEmptyBox />}
      {course.courseBookings?.map((row) => (
        <Fragment key={row.courseBookingId}>
          <div className="courseInfo">
            <div>
              <Avatar
                sx={{ marginLeft: "0" }}
                src={row.actor.profilePictureUrl + ""}
                alt={row.actor.actorName}
              ></Avatar>
              <div>
                <h2>
                  <Link
                    className="RemoveHyperLinkDecorations"
                    to={"/dashboard/company/" + row.actor.actorId}
                  >
                    {row.actor.actorName}
                  </Link>
                </h2>
              </div>
            </div>
            <div>
              <HeadsetMicIcon />
              <div>
                <div>
                  <Link
                    className="RemoveHyperLinkDecorations"
                    to={"/dashboard/person/" + row.contactActorId}
                  >
                    {row.contactActorName}
                  </Link>
                </div>
                <div>
                  <a
                    className="stdMailToLink"
                    href={"mailto:" + row.contactActorEmail}
                  >
                    {row.contactActorEmail}
                  </a>
                </div>
              </div>
            </div>
            {/*  <div>
              <PeopleIcon />
              <div><a className="RemoveHyperLinkDecorations" href={"/dashboard/company/" + row.actor.actorId} >{row.actor.actorName}</a></div>
            </div> */}
            <div>
              {/*   {rows.courseCommunications.filter(
                (x) => x.actorId === row.actor.actorId,
              ).length === 0 && <PeopleIcon />}
              {
                rows.courseCommunications.filter(
                  (x) => x.actorId === row.actor.actorId,
                ).length
              } */}
              {/* <CcState actorId={row.actor.actorId} /> */}
              <PeopleIcon />
              {row.numberOfParticipants}
            </div>

            <div>
              {course.isELearningCourse &&
                findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.ContactPerson) && (
                  <div>
                    <Box>
                      <Tooltip
                        className="iconbox"
                        title={
                          findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.ContactPerson).bounced === false
                            ? `${Translate.get("PasswordSentPlural")} ` +
                            findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.ContactPerson).sendDate.substring(0, 10)
                            : Translate.get("EmailBounce")
                        }
                        arrow
                      >
                        <VpnKeyIcon
                        color={
                          findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.ContactPerson).bounced === true
                            ? "error"
                            : ""
                        }
                      />
                      </Tooltip>
                    </Box>
                  </div>
                )}

              {findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.ContactPerson) && (
                <div>
                  <Box>
                    <Tooltip
                      className="iconbox"
                      title={
                        findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.ContactPerson).bounced === true
                          ? Translate.get("EmailBounce")
                          : `${Translate.get("CourseInvitationSent")} ` +
                          findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.ContactPerson).sendDate.substring(0, 10)
                      }
                      arrow
                    >
                      <AddAlertIcon
                        color={
                          findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.ContactPerson).bounced === true
                            ? "error"
                            : ""
                        }
                      />
                    </Tooltip>
                  </Box>
                </div>
              )}
              {findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.OrderConfirmation, CommunicationRole.ContactPerson) && (
                <div>
                  <Box>
                    <Tooltip
                      className="iconbox"
                      title={
                        <>
                          <Typography
                            style={{
                              fontSize: "inherit",
                              fontFamily: "inherit",
                            }}
                          >
                            {findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.OrderConfirmation, CommunicationRole.ContactPerson).bounced === true
                              ? Translate.get("EmailBounce")
                              : `${Translate.get("OrderConfirmationSent")} ` +
                              findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.OrderConfirmation, CommunicationRole.ContactPerson).sendDate.substring(0, 10)}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "inherit",
                              fontFamily: "inherit",
                            }}
                          >
                            {Translate.get("BookingRevenue")}{" "}
                            {formatCurrency(row.courseBookingPrice)} kr
                          </Typography>
                        </>
                      }
                      arrow
                    >
                      <RequestQuoteIcon
                        color={
                          findSentCommunication(communicationMap, row.contactActorId, MediaType.Email, DocumentType.OrderConfirmation, CommunicationRole.ContactPerson).bounced === true
                            ? "error"
                            : ""
                        }
                      />
                    </Tooltip>
                  </Box>
                </div>
              )}
            </div>

            <div className="courseInfoDots">
              <DotMenu
                content={getBookingDotMenu({
                  id: row.actor.actorId,
                  bookerActorId: row.actorId,
                  courseId: course.courseId,
                  eLearningType: course.eLearningType,
                  isPrivateBooking: row.isPrivateBooking,
                  isInternalBooking: row.isInternalBooking,
                  numberOfParticipants: row.numberOfParticipants,
                  fromDate: course.fromDate,
                  courseBookings: [
                    {
                      companyName: row.actor.actorName,
                      courseBookingId: row.courseBookingId,
                      orderId: row.orderId,
                      contactActorName: row.contactActorName,
                      contactActorEmail: row.contactActorEmail,
                      contactActorId: row.contactActorId,
                      courseParticipants: row.courseParticipants?.map((cp) => ({
                        courseParticipantActorName: cp.actor?.actorName,
                        courseParticipantEmail: cp.actor?.actorEmail,
                        courseParticipantActorId: cp.actor?.actorId,
                        courseParticipantELearningLicenseId:
                          cp.eLearningLicense?.eLearningLicenseId,
                        selectedELearningLanguageCode:
                          cp.eLearningLicense?.eTruckLanguageCode,
                        courseParticipantId: cp.courseParticipantId,
                      })),
                      allowedLanguages: props.langs,
                    },
                  ],
                  contact: {
                    actorId: row.contactActorId,
                    actorName: row.contactActorName,
                    actorEmail: row.contactActorEmail,
                  },
                  loadCourse: props.loadCourse,
                  api: api,
                })}
              />
            </div>
          </div>
          {props.bigScreen ? (
            <StudentTable
              langs={course.allowedLanguages}
              rows={row.courseParticipants}
              sentCommunications={communicationMap}
              eLearningType={course.eLearningType}
              courseId={course.courseId}
              courseFromDate={course.fromDate}
              bookingId={row.courseBookingId}
              companyActorId={row.actorId}
              companyName={row.actor.actorName}
              contactActor={{
                actorId: row.contactActorId,
                name: row.contactActorName,
                email: row.contactActorEmail,
              }}
              loadCourse={props.loadCourse}
              numberOfParticipants={row.numberOfParticipants}
              isPrivateBooking={row.isPrivateBooking}
            />
          ) : (
            <StudentCards
              courseId={course.courseId}
              courseFromDate={course.fromDate}
              langs={course.allowedLanguages}
              rows={row.courseParticipants}
              sentCommunications={communicationMap}
              eLearningType={course.eLearningType}
              bookingId={row.courseBookingId}
              companyActorId={row.actorId}
              companyName={row.actor.actorName}
              contactActor={{
                actorId: row.contactActorId,
                name: row.contactActorName,
                email: row.contactActorEmail,
              }}
              loadCourse={props.loadCourse}
              numberOfParticipants={row.numberOfParticipants}
              isPrivateBooking={row.isPrivateBooking}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
export default Big;
