import { Flag, FlagOutlined, Lock } from "@mui/icons-material";

export const priority = {
  undefined: 0,
  onHold: 1,
  low: 2,
  normal: 3,
  high: 4,
};

export function getIcon(prio) {
  switch (prio) {
    case priority.onHold:
      return <Lock />;
    case priority.high:
      return <Flag className="redIcon" />;
    case priority.normal:
    default:
      return <FlagOutlined />;
  }
}
