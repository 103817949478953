import Api from "./Api";

export const actorTypes = {
  unknown: 0,
  client: 1,
  contact: 3,
  student: 4,
  teacher: 6,
  externalInstructor: 8,
  privateClient: 9,
};

export const actorCompanyTypes = [actorTypes.client];

export const actorPersonTypes = [
  actorTypes.unknown,
  actorTypes.contact,
  actorTypes.student,
  actorTypes.teacher,
  actorTypes.privateClient,
];

export async function actorSearch(
  searchText,
  actorTypes,
  searchFields,
  maxSearchResults,
  onlyExactMatches = false,
  filters
) {
  const req = {
    searchText,
    actorTypes,
    searchFields,
    maxSearchResults,
    onlyExactMatches,
    filters,
  };
  return await Api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/v2/search/full/`,
    req
  );
}

export async function actorMultiSearch(
  searchTextsByField,
  actorTypes,
  maxSearchResults,
  onlyExactMatches = false
) {
  const req = Object.entries(searchTextsByField).map(
    ([searchField, searchText]) => ({
      searchText,
      actorTypes,
      searchFields: [searchField],
      maxSearchResults,
      onlyExactMatches,
    })
  );
  return await Api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/v2/search/full/multi`,
    req
  );
}
