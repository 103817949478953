import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import Translate from "../../../utils/Translate";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import { AdminPanelSettings } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import { accessKeys, hasAccess } from "../../../utils/userAccess";

const lineTypes = {
  undefined: 0,
  license: 1,
  user: 2,
  SMS: 3,
  ePortReportClient: 4,
  iframe: 5,
  courseAdminHTML: 7,
  actorPermit: 8,
  actorPermitUser: 9,
  courseAdminReadOnly: 10,
  userReadOnly: 11,
};

export default function EPortProfileDialog({
  profile,
  onClose,
  onSave,
  externalDataId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(profile ? { ...profile } : {});
  const [profileTypes, setProfileTypes] = useState([]);
  const [roles, setRoles] = useState([]);

  const api = useApi();

  const loadAvailableLineTypes = useCallback(async () => {
    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles/line-types?externalDataId=${externalDataId}`,
      false,
      "GET",
      (response) => response && Array.isArray(response)
    );
    if (response && Array.isArray(response)) {
      setProfileTypes(
        response.map((lineType) => ({
          lineType,
          label: Translate.getEPortLicenseLineType(lineType),
        }))
      );
    }
  }, [api, externalDataId]);

  const loadRoles = useCallback(async () => {
    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/profiles/roles`,
      false,
      "GET",
      (response) => response && Array.isArray(response)
    );
    if (response && Array.isArray(response)) {
      // We display no profile (roleId == null) as "STANDARD"
      // Used -1 because react <Select> wouldn't allow me to use null values.
      setRoles([{ roleId: -1, shortName: "STANDARD" }, ...response]);
    }
  }, [api]);

  useEffect(() => {
    setIsLoading(true);
    loadRoles();
    loadAvailableLineTypes().then(() => setIsLoading(false));
  }, [loadRoles, loadAvailableLineTypes]);

  function handleSelectProfileType(event) {
    var newLineType = event.target.value;
    setData((prev) => ({ ...prev, ePortCloudLicenseLineType: newLineType }));

    if (newLineType == lineTypes.courseAdminReadOnly) {
      const readonlyRole = roles.find((r) => r.shortName === "READONLY");
      if (readonlyRole) {
        setData((prev) => ({ ...prev, roleId: readonlyRole.roleId }));
      }
    } else if (!data.roleId) {
      // We display no profile (roleId == null) as "STANDARD"
      // Used -1 because react <Select> wouldn't allow me to use null values.
      setData((prev) => ({ ...prev, roleId: -1 }));
    }
  }

  async function handleSave() {
    // We display no profile (roleId == null) as "STANDARD"
    // Used -1 because react <Select> wouldn't allow me to use null values.
    await onSave({ ...data, roleId: data.roleId === -1 ? null : data.roleId });
    onClose();
  }

  if (isLoading) {
    <Preloader />;
  }

  return (
    <Dialog open className="stdDialog">
      <DialogContent>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={2.5}
        >
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <GroupIcon sx={{ marginLeft: "9px" }} />
            <FormControl fullWidth>
              <InputLabel id="profile-select-label" required>
                {Translate.get("EPortProfile")}
              </InputLabel>
              <Select
                required
                labelId="profile-select-label"
                id="profile-select"
                label={Translate.get("EPortProfile")}
                value={data.ePortCloudLicenseLineType ?? ""}
                onChange={handleSelectProfileType}
              >
                {profileTypes.map((x) => (
                  <MenuItem key={x.lineType} value={x.lineType}>
                    {x.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <AdminPanelSettings sx={{ marginLeft: "9px" }} />
            <FormControl fullWidth>
              <InputLabel id="role-select-label">
                {Translate.get("Role")}
              </InputLabel>
              <Select
                disabled={data.lineType == lineTypes.courseAdminReadOnly}
                labelId="role-select-label"
                id="role-select"
                label={Translate.get("Role")}
                value={data.roleId ?? ""}
                onChange={(event) =>
                  setData((prev) => ({ ...prev, roleId: event.target.value }))
                }
              >
                {roles.map((x) => (
                  <MenuItem key={x.roleId} value={x.roleId}>
                    {x.shortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner onClick={handleSave} variant="contained">
          {profile.ePortProfileId
            ? Translate.get("Save")
            : Translate.get("Add")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
