import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import { useCallback, useState } from "react";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import { actorSearch, actorPersonTypes } from "../../../utils/actorSearch";
export default function ChangeContactDialog({
  courseId,
  courseBookingId,
  bookerActorId,
  currentContact,
  onContactChanged,
  numberOfParticipants,
}) {
  const [contact, setContact] = useState(currentContact);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const api = useApi();

  const compareContacts = useCallback(
    (lhs, rhs) => {
      const lhsCompanyConnections = lhs.parentActors?.filter(
        (parent) => parent.parentActor.actorId === bookerActorId
      );
      const lhsIsConnectedToCompany =
        !!lhsCompanyConnections && lhsCompanyConnections.length > 0;
      const lhsIsContactToCompany =
        lhsIsConnectedToCompany &&
        lhsCompanyConnections.some((parent) => parent.actorType === 3);
      const rhsCompanyConnections = rhs.parentActors?.filter(
        (parent) => parent.parentActor.actorId === bookerActorId
      );
      const rhsIsConnectedToCompany =
        !!rhsCompanyConnections && rhsCompanyConnections.length > 0;
      const rhsIsContactToCompany =
        rhsIsConnectedToCompany &&
        rhsCompanyConnections.some((parent) => parent.actorType === 3);
      if (
        (lhsIsContactToCompany && !rhsIsContactToCompany) ||
        (lhsIsConnectedToCompany && !rhsIsConnectedToCompany)
      ) {
        return -1;
      }
      if (
        (rhsIsContactToCompany && !lhsIsContactToCompany) ||
        (rhsIsConnectedToCompany && !lhsIsConnectedToCompany)
      ) {
        return 1;
      }
      // Use the order from backend in this case
      return 0;
    },
    [bookerActorId]
  );
  const contactSearch = useCallback(
    async (searchText) => {
      const foundContacts = await actorSearch(
        searchText,
        actorPersonTypes,
        ["ActorName"],
        50
      );
      if (foundContacts) {
        return foundContacts.sort(compareContacts).map((contact) => ({
          actorId: contact.actorId,
          actorName: contact.actorName,
          orgNo: contact.orgNo,
          email: contact.email,
          contactForCompanies: contact.parentActors
            ?.filter((x) => x.actorType === 3)
            .map((x) => x.parentActor.actorId),
          companies: contact.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId > 0 &&
                x.parentActor.actorId !== contact.actorId &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
      }
    },
    [compareContacts]
  );
  async function postNewOrExistingContact() {
    if (contact.contactForCompanies?.some((x) => x === bookerActorId)) {
      // Already a contact for the company, no need to add or update
      return contact.actorId;
    }
    const dto = {
      actorId: contact.actorId ?? 0,
      actorName: contact.actorName,
      actorEmail: contact.email,
    };
    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}actors/clients/${bookerActorId}/contacts/`,
      dto,
      "POST",
      (response) => response
    );
    if (response) {
      setContact({ ...contact, actorId: response.actorId });
      return response.actorId;
    }
  }
  async function postCourseBooking(bookingContactActorId) {
    if (
      !(
        courseId > 0 &&
        courseBookingId > 0 &&
        numberOfParticipants > 0 &&
        bookerActorId > 0
      )
    ) {
      throw new Error("Cannot change contact. Missing booking data");
    }
    const dto = {
      courseId,
      courseBookingId,
      numberOfParticipants,
      bookerActorId,
      bookingContactActorId,
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/bookings/`,
      dto,
      "POST"
    );
    if (response && response.isSuccessful) {
      return true;
    }
  }
  async function handleSave() {
    const bookingContactActorId = await postNewOrExistingContact();
    if (bookingContactActorId > 0) {
      const isOk = await postCourseBooking(bookingContactActorId);
      if (isOk) {
        onContactChanged({ ...contact, actorId: bookingContactActorId });
        window.history.back();
      }
    }
  }
  return (
    <>
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "600px" }}>
        <Stack
          direction="row"
          spacing={1}
          className="stdFlexLeft"
          sx={{ paddingTop: "16px" }}
        >
          <HeadsetMicIcon sx={{ marginLeft: "9px" }} />
          <AutocompleteSearchField
            label={Translate.get("Contact")}
            value={contact}
            onValueChange={(newContact) => setContact(newContact)}
            getOptionLabel={(option) => option?.actorName ?? ""}
            renderOption={(option) => (
              <ActorAutocompleteOption
                actorName={option?.actorName}
                orgNo={option?.orgNo}
                email={option?.email}
                companies={option?.companies}
              />
            )}
            keyPropName="actorId"
            requireSelection={true}
            search={contactSearch}
            createNewValue={(text) => ({
              actorName: text,
            })}
            minWidth={isSmallScreenOrGreater ? "496px" : "250px"}
            textFieldProps={{ required: true }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.history.back()}>
          {Translate.get("Cancel")}
        </Button>
        <Button onClick={handleSave} disabled={!contact || !contact.actorName}>
          {Translate.get("Save")}
        </Button>
      </DialogActions>
    </>
  );
}
