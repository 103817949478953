export const partType = {
  book: 1,
  course: 2,
  serviceAgreement: 3,
  teacher: 4,
  storeGoods: 5,
  freight: 6,
  resourceThing: 7,
  eLearning: 8,
  license: 9,
  plasticCardPrintJob: 10,
  ePortProgamLicense: 11,
  ePortUserLicense: 12,
  SMS: 13,
  ePortReportClient: 14,
  iframe: 15,
  courseAdminHTML: 17,
  drivingPermitsHTML: 18,
  drivingPermitsHTMLUser: 19,
  courseAdminReadOnly: 20,
  userReadOnly: 21,
  extraFinalExam: 22,
  resellableExtraFinalExam: 23,
  freight25Vat: 61,
  freight12Vat: 62,
  freight06Vat: 63,
  freightEUVat: 64,
  freightOutsideEUVat: 65,
  resellableELearningLicense: 88,
  expired: 99,
};

export function isFreightPartType(partTypeParam) {
  if (partTypeParam === undefined || partTypeParam === null) {
    return partTypeParam;
  }

  const partTypeNumber =
    typeof partTypeParam === "number" ? partTypeParam : Number(partTypeParam);
  return [
    partType.freight,
    partType.freight06Vat,
    partType.freight12Vat,
    partType.freight25Vat,
    partType.freightEUVat,
    partType.freightOutsideEUVat,
  ].some((pt) => pt === partTypeNumber);
}

export function isCoursePartType(partTypeParam) {
  if (partTypeParam === undefined || partTypeParam === null) {
    return partTypeParam;
  }

  const partTypeNumber =
    typeof partTypeParam === "number" ? partTypeParam : Number(partTypeParam);
  return [partType.course, partType.eLearning].some(
    (pt) => pt === partTypeNumber
  );
}

export function isPhysicalPickPartType(partTypeParam) {
  if (partTypeParam === undefined || partTypeParam === null) {
    return partTypeParam;
  }

  const partTypeNumber =
    typeof partTypeParam === "number" ? partTypeParam : Number(partTypeParam);
  return [
    partType.book,
    partType.storeGoods,
    partType.plasticCardPrintJob,
  ].some((pt) => pt === partTypeNumber);
}

export function isDigitalPickPartType(partTypeParam) {
  if (partTypeParam === undefined || partTypeParam === null) {
    return partTypeParam;
  }

  const partTypeNumber =
    typeof partTypeParam === "number" ? partTypeParam : Number(partTypeParam);
  return [
    partType.resellableELearningLicense,
    partType.resellableExtraFinalExam,
  ].some((pt) => pt === partTypeNumber);
}

function isNotAutoPickPartType(partTypeParam) {
  if (partTypeParam === undefined || partTypeParam === null) {
    return partTypeParam;
  }

  const partTypeNumber =
    typeof partTypeParam === "number" ? partTypeParam : Number(partTypeParam);

  return [
    partType.book,
    partType.storeGoods,
    partType.license,
    partType.plasticCardPrintJob,
    partType.resellableExtraFinalExam,
    partType.resellableELearningLicense,
  ].some((pt) => pt === partTypeNumber);
}

export function isAutoPickPartType(partTypeParam) {
  return !isNotAutoPickPartType(partTypeParam);
}
