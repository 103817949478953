import { useState, useMemo, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export default function ChangeEPortLicenseTypeDialog({
  currentLicensePartId,
  companyActorId,
  onChanged,
  onClose,
}) {
  const [ePortLicense, setEPortLicense] = useState({
    actorId: companyActorId,
    startDate: null,
    endDate: null,
    orderCanUpdate: true,
  });
  const [isLoading, setIsLoading] = useState();
  const [licenseParts, setLicenseParts] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);

  const api = useApi();

  const loadEPortLicense = useCallback(
    async (actorId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/${actorId}`,
        false,
        "GET"
      );

      if (response && response.isSuccessful) {
        setEPortLicense({ actorId: companyActorId, ...response.license });
      }
    },
    [api]
  );

  const loadParts = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}warehouse/parts/for-eport-license`,
      false,
      "GET"
    );
    if (response && response.isSuccessful) {
      setLicenseParts(response.parts);
      return response.parts;
    }
  }, [api]);

  useEffect(() => {
    async function loadAll() {
      await loadEPortLicense(companyActorId);
      await loadParts();
    }

    setIsLoading(true);
    loadAll().then(() => {
      setIsLoading(false);
    });
  }, [companyActorId, api, loadParts, loadEPortLicense]);

  async function handlePartSelected(partId) {
    if (partId === ePortLicense.partId) {
      return;
    }
    setEPortLicense((prevLic) => ({
      ...prevLic,
      partId,
    }));
  }

  async function postAddOrUpdateEPortLicense() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/change-type`,
      { partId: ePortLicense.partId, actorId: ePortLicense.actorId },
      "POST"
    );

    return response && response.isSuccessful;
  }

  function handleCancel() {
    onClose();
  }

  async function handleSave() {
    const isOk = await postAddOrUpdateEPortLicense();
    if (isOk) {
      await onChanged();
      onClose();
    }
  }

  console.log("####", ePortLicense, licenseParts)

  return (
    <Dialog maxWidth={false} scroll="paper" onClose={handleCancel} open={true}>
      {isLoading && (
        <>
          <Preloader />
          <DialogTitle>{Translate.get("ChangeEPortLicenseType")}</DialogTitle>
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {!isLoading && (
        <>
          <DialogTitle>{Translate.get("ChangeEPortLicenseType")}</DialogTitle>
          <DialogContent className="stdDialogContent">
            <Stack
              direction="row"
              sx={{
                paddingTop: "16px",
              }}
              spacing={1}
              className="stdFlexLeft"
            >
              <VerifiedUserIcon sx={{ marginLeft: "9px" }} />
              <FormControl fullWidth>
                <InputLabel id="part-select-label" required>
                  {Translate.get("EPortLicense")}
                </InputLabel>
                <Select
                  required
                  labelId="part-select-label"
                  id="part-select"
                  label={Translate.get("EPortLicense")}
                  value={ePortLicense.partId ?? ""}
                  onChange={(event) => handlePartSelected(event.target.value)}
                >
                  {licenseParts.map((x) => (
                    <MenuItem key={x.partId} value={x.partId}>
                      {x.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner variant="contained" onClick={handleSave}>
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
