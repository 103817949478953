import Translate from "../../../utils/Translate";
import { Button } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import BankIdAuthentication from "../../bankId/BankIdAuthentication";
import { useApi } from "../../../utils/Api";
import { useState } from "react";

let timeout;
const BankIdLoginForm = ({
  onLoginSuccess,
  startQrCodeOnDesktop,
  onlyBankIdLogin,
}) => {
  const api = useApi();
  const [bankIdCancelUrl, setBankIdCancelUrl] = useState();

  const onAuthenticationSuccess = (loginType, response) => {
    localStorage.setItem("eportLoginMethod", "bankId_" + loginType); // spara för att visa bankidinloggning vid nästa inloggning
    onLoginSuccess(response.data);
  };

  const onStatusInitiated = (bankIdStatusResponse) =>
    setBankIdCancelUrl(bankIdStatusResponse.cancelUrl);

  const cancelBankIdLogin = async () => {
    await api.fetch(bankIdCancelUrl, null, "POST", false);
  };

  function getLoginUrl() {
    return window.global.isEcomp
      ? `${process.env.REACT_APP_AUTH_URL}auth/bankid/login/digital-licenses`
      : `${process.env.REACT_APP_AUTH_URL}auth/bankid/login/courseadmin`;
  }

  return (
    <form className="loginForm">
      <BankIdAuthentication
        startAuthenticationUrl={getLoginUrl()}
        onStatusInitiated={onStatusInitiated}
        onAuthenticationSuccess={onAuthenticationSuccess}
        startQrCodeOnDesktop={startQrCodeOnDesktop}
      />
      {!onlyBankIdLogin && (
        <Button
          href="/login"
          onClick={cancelBankIdLogin}
          startIcon={<KeyIcon />}
          sx={{ marginTop: "2em" }}
        >
          {Translate.get("LogInUsingEmail")}
        </Button>
      )}
    </form>
  );
};

export default BankIdLoginForm;
