import {
  Link,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Translate from "../../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useState, useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useApi } from "../../../utils/Api";
import { pseudoELearningTypeEnum } from "../../../utils/eLearningTypes";
import Preloader from "../../Preloader";
import ButtonWithSpinner from "../../ButtonWithSpinner";

export default function ExtraFinalExamDialog({
  eLearningLicenseId,
  newFinalExamNumber,
  onActivated,
}) {
  const [availableExtraExamCount, setAvailableExtraExamCount] = useState(null);
  const [newExtraFinalExamIsLocked, setNewExtraFinalExamIsLocked] =
    useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    async function loadData() {
      const response1 = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/count/${pseudoELearningTypeEnum.ExtraFinalExam}`,
        false,
        "GET"
      );
      if (!isNaN(response1)) {
        setAvailableExtraExamCount(response1);
      }

      const response2 = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/finalExamUnlocked`,
        false,
        "GET"
      );
      if (response2.isSuccessful) {
        setNewExtraFinalExamIsLocked(response2.settingValue !== "True");
      }
    }

    loadData().then(() => setIsLoading(false));
  }, [api]);

  async function postAddExtraFinalExam() {
    const dto = {
      eLearningLicenseId,
      newFinalExamNumber,
      isFinalExamLocked: newExtraFinalExamIsLocked,
    };

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/exam/add-extra/`,
      dto,
      "POST"
    );
  }

  async function handleActivate() {
    await postAddExtraFinalExam();
    if (!!onActivated) {
      onActivated(newExtraFinalExamIsLocked);
    } else {
      closeAndReload();
    }
  }

  function getInfoText() {
    const text = Translate.get("ExtraFinalExamInfo");

    // This text will be used for dangerouslySetInnerHTML, and I don't know if there is some way someone
    // could insert other malicious stuff other than the expected <b> tags. So checking the text just in case
    const containsOtherTags = (new RegExp('<[^b]>')).test(text);
    if (containsOtherTags) {
      return Translate.get("SomethingFailed");
    }

    return text.replace(
      "{0}",
      availableExtraExamCount ?? ""
    );
  }

  const canContinue = false;

  return (
    <>
      {isLoading && <Preloader />}
      {/* <DialogTitle>{Translate.get("EnterStudentCount")}</DialogTitle> */}
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "600px" }}>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: getInfoText(),
            }}
          ></Typography>
          <Link to="/dashboard/licenses" component={RouterLink}>
            {Translate.get("ClickHereToOrderExtraExam")}
          </Link>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            {newExtraFinalExamIsLocked ? (
              <LockIcon sx={{ marginLeft: "9px" }} />
            ) : (
              <LockOpenIcon sx={{ marginLeft: "9px" }} />
            )}
            <FormControl fullWidth>
              <Select
                value={newExtraFinalExamIsLocked ? "locked" : "unlocked"}
                onChange={(event) =>
                  setNewExtraFinalExamIsLocked(event.target.value === "locked")
                }
              >
                <MenuItem value="locked">
                  {Translate.get("LockedLabel")}
                </MenuItem>
                <MenuItem value="unlocked">
                  {Translate.get("Unlocked")}
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          onClick={handleActivate}
          disabled={!availableExtraExamCount || availableExtraExamCount <= 0}
        >
          {Translate.get("ActivateLabel")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
