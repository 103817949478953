import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Tooltip from '@mui/material/Tooltip';
import Translate from "../../utils/Translate";
import { isValidDate } from "../../utils/dateTimeFormat";
const PermitStatus = ({row}) => {
    const renderPermitStatus = (row) => {
      const validTo = row.validTo && new Date(row.validTo);
      const isExpired = isValidDate(validTo) ? validTo < Date.now() : false;
      if (isExpired) {
        return <Tooltip className="iconbox opaque" title={Translate.get('PermitExpired')}><EventBusyIcon sx={{color:'black'}} /></Tooltip>
      }

        if (row.responsibleSignatureDate && row.signatureDate){
         return <Tooltip  className="iconbox opaque success" title={Translate.get('PermitSignedByResponsibleAndDriver')}><DoneAllIcon sx={{color:'green'}} /></Tooltip>
        }
        else if (row.responsibleSignatureDate){
         return <Tooltip  className="iconbox opaque" title={Translate.get('PermitSignedByResponsible')}><DoneIcon sx={{color:'orange'}} /></Tooltip>
        }
        else{
         return <Tooltip  className="iconbox opaque" title={Translate.get('PermitNotSigned')}><AccessTimeIcon sx={{color:'black'}}  /></Tooltip>
        }
      };
  return <>
  {renderPermitStatus(row)}
  </>;
};
export default PermitStatus;