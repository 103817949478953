import { Tooltip } from "@mui/material";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { Badge } from "@mui/icons-material";

export default function StudentLicenseIconbox({ actorLicense }) {
  return (
    <Tooltip
      className="iconbox"
      title={
        <div>
          <div>{`${Translate.get("LicenseDate")}: ${formatDate(
            actorLicense.validFrom
          )}${
            actorLicense.validTo ? ` - ${formatDate(actorLicense.validTo)}` : ""
          }`}</div>
          <div>{`${Translate.get("LastPrintDate")}: ${
            actorLicense.lastPrintDate
              ? formatDate(actorLicense.lastPrintDate)
              : "-"
          }`}</div>
          {hasAccess(accessKeys.digitalLicenses) && (
            <>
              {actorLicense.digitalLicenseMessageBounce && <br />}
              <div>{`${Translate.get("DigitalLicenseLabel")}: ${
                actorLicense.digitalLicenseId
                  ? Translate.get("Yes")
                  : Translate.get("No")
              }`}</div>
              {actorLicense.digitalLicenseMessageBounce && (
                <div>{`${Translate.get("Error")}: ${Translate.get(
                  "EmailBounce"
                )}`}</div>
              )}
            </>
          )}
        </div>
      }
      arrow
    >
      <Badge color={actorLicense.digitalLicenseMessageBounce ? "error" : ""} />
    </Tooltip>
  );
}
