import { useState, useEffect } from 'react';
import { useApi } from '../utils/Api';
import Translate from '../utils/Translate';
import { useSearchParams } from 'react-router-dom';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useMediaQuery } from '@mui/material';
import BankIdAuthentication from '../components/bankId/BankIdAuthentication';
import Alert from '@mui/material/Alert';
import Preloader from '../components/Preloader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';


const LostCard = (props) => {

  const currentYear = new Date().getFullYear()
  let years = arrayRange(currentYear, 1970, -1);

  function arrayRange(start, stop, step) {
    return (Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    ));
  }

  const [searchParams] = useSearchParams();
  const api = useApi();
  const smallScreen = useMediaQuery('(max-width:700px)');


  const titleRef = useRef();

  const columnWidth = !smallScreen ? 6 : 12;
  const divMaxWidth = !smallScreen ? "40em" : "90%";
  const divWidth = !smallScreen ? "70%" : "90%";

  const [showView, setShowView] = useState("init");
  const [showDialog, scrollToBottomAfterDialog] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [email, setEmail] = useState(null);
  const [education, setEducation] = useState("choose");
  const [freeTextEducation, setFreeTextEducation] = useState("");
  const [yearOfEducation, setYearOfEducation] = useState("choose");
  const [placeOfEducation, setPlaceOfEducation] = useState("");
  const [instructorName, setInstructorName] = useState("");
  const [educationProvider, setEducationProvider] = useState("");
  const [deliveryName, setDeliveryName] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    const lang = searchParams.get('lang') ?? localStorage.lang ?? '29';
    Translate.loadPreLogin(lang);
  }, [searchParams]);

  useEffect(() => {
    const handleScroll = e => {
      setShowView("bankId");
      document.removeEventListener("scroll", handleScroll)
    }
    document.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (showDialog !== true) { return }
    titleRef.current.scrollIntoView();
  }, [showDialog]);


  function handleClick() {
    if (showView === "init") {
      setShowView("bankId");
    }

    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function disableSendButton() {
    if (name === "" || email === "" || education === "choose" || (education === "other" && freeTextEducation === "") || yearOfEducation === "choose" || deliveryName === "" || street === "" || zipCode === "" || city === "") {
      return true;
    }

    return false;
  }

  function capFirst(str) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  function onAuthenticationSuccess(loginType, response) {
    if (response.data.exists) {
      setShowView("form");
    } else {
      setShowView("notFound");
    }

    setOrgNo(response.data.ssn);
    setToken(response.data.token);
    setName(capFirst(response.data.givenName) + " " + capFirst(response.data.surName));
  }

  function handleClose() {
    setShowView("bankId");
  }

  function handleContinue() {
    scrollToBottomAfterDialog(true);
    setShowView("form");
  }

  async function postForm() {
    setIsLoading(true);

    let reqObj = {
      name: name,
      orgNo: orgNo,
      email: email,
      education: education,
      freeTextEducation: freeTextEducation,
      yearOfEducation: yearOfEducation,
      placeOfEducation: placeOfEducation,
      instructorName: instructorName,
      educationProvider: educationProvider,
      deliveryName: deliveryName,
      street: street,
      zipCode: zipCode,
      city: city,
      token: token
    }

    try {
      let response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}lostcard`,
        reqObj,
        "POST",
        false,
        true
      );
      if (response.isSuccessful) {
        setShowView("success")
        titleRef.current.scrollIntoView();
      } else if (response.errorMessageTranslationKey === "Unauthorized") {
        setShowView("bankId");
      }
    } catch (error) {
    }
    finally {
      setIsLoading(false);

      setOrgNo("");
      setEmail("");
      setEducation("");
      setFreeTextEducation("");
      setYearOfEducation("");
      setPlaceOfEducation("");
      setInstructorName("");
      setEducationProvider("");
      setDeliveryName("");
      setStreet("");
      setZipCode("");
      setCity("");
    }
  }

  return (
    <div>
      {isLoading && <Preloader />}

      <div style={{ minHeight: "20em", height: "100vh" }}>
        <div className='lostCardGradient'>
          <img src={!smallScreen ? '/static/lostCard.png' : '/static/lostCard_mobile.png'} alt="" />
        </div>

        <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "4em" }}>
          <p style={{ fontWeight: "bold" }}>Har du förlorat ditt intyg?</p>
          <p>
            För att vi ska kunna hitta dig i registret behöver du legitimera dig med BankID. Fyll därefter i formuläret så fullständigt som möjligt och skicka in.
            Vi återkommer till dig med besked och betalningsinstruktioner.
          </p> <br />

          <p>Ersättningsintyget kostar 350 SEK inkl. moms och frakt. Det skickas när vi mottagit betalningen (via Swish). Vi erbjuder ej faktura.
          </p> <br />

          <p>Tänk på att ditt förarintyg är en värdehandling och bör hanteras som en sådan.</p>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="large" sx={{ mt: "2em" }}
              onClick={handleClick}
              className='pulse-button'
            >
              <ArrowCircleDownIcon fontSize='large' />
            </IconButton>

          </Grid>

        </div>
      </div>

      <div ref={titleRef} style={{ minHeight: "20em", height: "100vh" }} className='lostCardGradient' id="returnFromApp">
        {showView === "bankId" &&

          <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "6em" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>

              <BankIdAuthentication startAuthenticationUrl={`${process.env.REACT_APP_MAIN_URL}lostcard/bankId/authenticate`}
                //onStatusInitiated={onStatusInitiated}
                onAuthenticationSuccess={onAuthenticationSuccess}
              //onAutheticationFailure={}
              //startQrCodeInMobile={startQrCodeInMobile}
              />
            </div>
          </div>
        }

        {showView === "notFound" &&
          <div>
            <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "4em" }}>
              <Card sx={{ width: "300px", height: "350px", margin: "auto" }}></Card>
            </div>

            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Hittades inte
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Vi kan tyvärr inte hitta ditt personnummer i något av våra register. Tänk på att vi endast kan utfärda intyg som utfärdats av någon av tjänstens samarbetspartners.
                  Du kan fortsätta till formuläret och skicka det, men sannolikheten för att vi ska kunna skicka ett nytt intyg är tyvärr låg.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                >
                  Avbryt
                </Button>
                <Button
                  onClick={handleContinue}
                >
                  Fortsätt
                </Button>
              </DialogActions>
            </Dialog>
          </div>}

        {showView === "form" &&
          <div style={{ paddingTop: "3em" }}>
            <Card style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth }}>
              <Grid
                container
                style={{ padding: "2em" }}
                spacing={"12px"}
              >
                <Grid item xs={6}>
                  <b>Personuppgifter</b>
                </Grid>
                <Grid item xs={6}>
                  Personnummer: {orgNo}
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={name} onChange={(event) => setName(event.target.value)} label="Namn" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={email} onChange={(event) => setEmail(event.target.value)} label="Email" variant="outlined" fullWidth required />
                </Grid>

                <Grid item xs={12}><b>Utbildningsinformation</b></Grid>
                <Grid item xs={columnWidth}>
                  <FormControl fullWidth>
                    <InputLabel id="education" required>Utbildning</InputLabel>
                    <Select
                      labelId="Utbildning"
                      id="Utbildning"
                      value={education}
                      label="Education"
                      onChange={(event) => { setEducation(event.target.value); if (event.target.value !== "other") { setFreeTextEducation("") } }}
                    >
                      <MenuItem value={"choose"}>Välj utbildning</MenuItem>
                      <MenuItem value={"truck"}>Truck</MenuItem>
                      <MenuItem value={"lift"}>Lift</MenuItem>
                      <MenuItem value={"fallskydd"}>Fallskydd</MenuItem>
                      <MenuItem value={"kran"}>Kran/Travers</MenuItem>
                      <MenuItem value={"other"}>Övrig</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={freeTextEducation} onChange={(event) => setFreeTextEducation(event.target.value)} label="Utbildning fritext (om övrig)" variant="outlined" fullWidth disabled={education !== "other"} required={education === "other"} />
                </Grid>
                <Grid item xs={columnWidth}>
                  <FormControl fullWidth>
                    <InputLabel id="educationYear" required>Utbildningsår</InputLabel>
                    <Select
                      labelId="Utbildningsår"
                      id="educationYear"
                      value={yearOfEducation}
                      label="EducationYear"
                      onChange={(event) => setYearOfEducation(event.target.value)}
                    >
                      <MenuItem value={"choose"}>Välj utbildningsår (ungefärligt)</MenuItem>
                      {years.map((year, index) => {
                        return (
                          <MenuItem key={index} value={year}>{year}</MenuItem>
                        )
                      })}

                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={placeOfEducation} onChange={(event) => setPlaceOfEducation(event.target.value)} label="Utbildningsort" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={instructorName} onChange={(event) => setInstructorName(event.target.value)} label="Instruktör" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={educationProvider} onChange={(event) => setEducationProvider(event.target.value)} label="Utbildningsanordnare" variant="outlined" fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <b>Leveransuppgifter</b>
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={deliveryName} onChange={(event) => setDeliveryName(event.target.value)} label="Namn/co" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={street} onChange={(event) => setStreet(event.target.value)} label="Gata" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={zipCode} onChange={(event) => setZipCode(event.target.value)} label="Postnummer" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={columnWidth}>
                  <TextField value={city} onChange={(event) => setCity(event.target.value)} label="Ort" variant="outlined" fullWidth required />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button variant="contained" onClick={postForm} disabled={disableSendButton()}>Skicka</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </div>
        }

        {showView === "success" &&
          <div style={{ paddingTop: "3em" }}>
            <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, padding: "2em", paddingLeft: 0 }}>
              <Alert severity="success">Tack för din förfrågan. Vi återkommer så snart vi har hittat ditt intyg.</Alert>
            </div>
          </div>
        }

        <div style={{ maxWidth: divMaxWidth, margin: "auto", width: divWidth, paddingTop: "6em", paddingBottom: "1em" }}>
          <p>Detta är en tjänst som administreras av MA-system Utbildning AB.
            När du skickar en förfrågan om att beställa ett ersättningsintyg via detta formulär godkänner du samtidigt att vi får behandla dina personuppgifter för att kunna genomföra beställningen.
            Om du ej har tillgång till BankId, kontakta oss på education@masystem.se
          </p>
        </div>
      </div>
    </div>
  );
};
export default LostCard;