import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Translate from '../../../utils/Translate';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React from "react";
import { useApi } from '../../../utils/Api';
import translate from '../../../utils/Translate';

const Comp = (props) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(props.selectedELearningLanguageCode || props.allowedLanguages[0].languageCode)
  const [allowedLanguages] = React.useState(props.allowedLanguages.map((a) => a.languageCode))
  const languageCodeEnum = {
    0: 'English (N/A)',
    9: translate.get("LanguageCodeEnum_English"),
    12: translate.get("LanguageCodeEnum_French"),
    20: translate.get("LanguageCodeEnum_Norwegian"),
    29: translate.get("LanguageCodeEnum_Swedish"),
  };

  const api = useApi();
  //----------

  function handleChange(event) {
    setSelectedLanguage(event.target.value)
  }

  const changeLang = () => {
    const reqObj = {
      "eLearningLicenseIds": props.eLearningLicenseIds,
      "eTruckLanguageCode": selectedLanguage
    };

    let serviceUrl = "licenses/elearning/set-language"

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
      reqObj,
      'POST',
      false,
      true,
    ).finally(f => {
      window.history.back();
      props.loadCourse();
    })
  };

return (
  //Allt innehåll i dialog förutom Dialog + DialogHeader
  <>
    <DialogContent dividers={true}>
      <Select
        id="demo-simple-select"
        value={selectedLanguage}
        onChange={handleChange}
        sx={{width:'100%'}}

      >
        {allowedLanguages.map((l, index) => (
          <MenuItem value={l} key={index}>{languageCodeEnum[l]}</MenuItem>
        ))}
      </Select>

    </DialogContent>
    <DialogActions>
    <Button onClick={() => window.history.back()}>{Translate.get('Cancel')}</Button>
    <Button onClick={changeLang}>{Translate.get('Save')}</Button>
    </DialogActions>
    {/*  </Dialog> */}
  </>
);
};
export default Comp;
