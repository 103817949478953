import { Paper, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { IconAndLabelColumnHeader } from '../../utils/dataGrid';
import { useApi } from '../../utils/Api';
import Preloader from '../Preloader';
import Translate from '../../utils/Translate';
import DotMenu from '../DotMenu';
import { CustomNoRowsOverlay } from '../../utils/StyledGridOverlay';
import ClassRoomCard from './ClassRoomCard';
import { dataGridLang } from '../MemToolBar';
import PlaceIcon from '@mui/icons-material/Place';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SpeedMenu from '../SpeedMenu';
import AddIcon from '@mui/icons-material/Add';
import RemoveClassRoom from './RemoveClassRoom';
import AddClassRoom from './AddClassRoom';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ClassRoomSettings(props) {
  const localizedTextsMap = dataGridLang();
  const [isLoading, setIsLoading] = useState(true);
  const [editCourseTemplateId, setEditCourseTemplateId] = useState(null);
  const [courseTemplates, setCourseTemplates] = useState([]);
  const api = useApi();
  const [classRooms, setClassRooms] = useState([]);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up(700));

  const speedMenuActions = [
    {
      icon: <AddIcon />,
      text: Translate.get('AddCourseLocation'),
      dialogHeader: Translate.get('AddCourseLocation'),
      component: <AddClassRoom action="add" />,
      customClass: 'mediumCourseDialog',
    },
   
  ];

  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/courselocations`,
        false,
        'GET',
      )
      .then((data) => {
        setClassRooms(data?.courseLocations ?? []);
        setIsLoading(false);
      });
  }, [api]);


  function getDotMenuActions(row) {
    //console.log(row)
    const menudata = [
      {
        icon: <DeleteIcon />,
        dialogHeader: Translate.get('RemoveCourseLocation'),
        text: Translate.get('RemoveCourseLocation'),
        component: <RemoveClassRoom actorAddressId={row.actorAddressId} />,
        customClass: 'smallCourseDialog',
      },
    ];
    return menudata;
  }

  const columns = [
    {
      field: 'actorAddressStreet1',
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideSeparator',
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={MeetingRoomIcon}
          label={Translate.get('LocationName')}
        />
      ),
    },
    {
      field: 'actorAddressStreet2',
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideSeparator',
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PlaceIcon}
          label={Translate.get('Address2')}
        />
      ),
    },
    {
      field: 'actorAddressZipCode',
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideSeparator',
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PlaceIcon}
          label={Translate.get('ZipCode')}
        />
      ),
    },
    {
      field: 'actorAddressCity',
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideSeparator',
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PlaceIcon}
          label={Translate.get('City')}
        />
      ),
    
    },
    {
      field: 'dotMenu',
      //flex: 0.1,
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'hideSeparator',
      align: 'right',
      renderCell: (params) => (
        <DotMenu content={getDotMenuActions(params.row)} horizontal />
      ),
    },
  ];

  const [pageSize, setPageSize] = useState(10);
   const reload = () => {
      //console.log(reload);
    };

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>

<SpeedMenu reload={reload} content={speedMenuActions} />
          {!isSmallScreenOrGreater &&
            classRooms.map((index,row) => (
              <ClassRoomCard
                {...row}
                key={index}
                extraActions={getDotMenuActions(row)}
              />
            ))}
          {isSmallScreenOrGreater && (
            <Paper>
              <DataGrid
                pagination={true}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pageSize={pageSize}
                autoHeight={true}
                localeText={localizedTextsMap}
                initialState={{
                  pagination: {
                    page: 0,
                  },
                  sorting: {
                    sortModel: [{ field: 'actorAddressStreet1', sort: 'asc' }],
                  },
                }}
                density="comfortable"
                columns={columns}
                rows={classRooms}
                getRowId={(row) => row.actorAddressId}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay(
                    Translate.get('EmptyHere'),
                  ),
                }}
              />
            </Paper>
          )}
        </>
      )}
    </>
  );
}
