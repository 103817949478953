import React, { useState, useRef } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Translate from '../../utils/Translate';

import "react-image-crop/dist/ReactCrop.css";
import "./styles.css";
import IconButton from "@mui/material/IconButton";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import LoadingButton from "@mui/lab/LoadingButton";
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 85,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImgCrop(props) {
  ////console.log("props", props.img);
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(3 / 4);
  const [loaded, setLoaded] = useState(false);
  if (!loaded) {
    init(props.img);
    setLoaded(true);
  }

  function init(file) {
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || "")
    );
    reader.readAsDataURL(file);
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    //if (aspect) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
    /*  setCrop({
      unit: '%', // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    }) */
  }
  //}

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    previewCanvasRef.current.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error("Failed to create blob");
        }
        const newImage = new File([blob], "imgcrop.jpg", {
          type: "image/jpeg",
        });
        props.uploadImage(newImage);
      },
      "image/jpeg",
      0.9
    );
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

/*   function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(1);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 1);
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  } */
  function handleRotate(e) {
    setRotate(rotate === 270 ? 0 : rotate + 90);
  }

  return (
    <div className="imgCrop">
      <div className="Crop-Controls">
        <div className="centerCenter">
          <IconButton onClick={handleRotate}>
            <RotateRightIcon />
          </IconButton>
          <input
            className="scaleslider"
            type="range"
            id="scaleslider"
            name="scaleslider"
            min="0.7"
            max="4"
            value={scale}
            step="0.1"
            onChange={(e) => setScale(Number(e.target.value))}
            /*  
            defaultValue="90"
            min="10"
            max="100"
            step="1"
            onChange={(e) => setCrop({...crop,width:e.target.value,height:e.target.value})} */
          />
          <label htmlFor="scaleslider">
            <SignalCellularAltIcon />
          </label>
        </div>
    {/*     <div className="centerCenter">
          <LoadingButton
            variant="text"
            onClick={onDownloadCropClick}
            loading={props.uploadingImage}
          >
            Beskär bilden
          </LoadingButton>
        </div> */}
      </div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          circularCrop={false}
          // minWidth={400}
          locked={false}
          keepSelection={true}
          //minHeight={200}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <div style={{ display: "none" }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
          <div className="alignRight">
            <LoadingButton
              variant="text"
              onClick={onDownloadCropClick}
              loading={props.uploadingImage}
            >
              {Translate.get("CropImage")}
            </LoadingButton>
            <a
              href="#hidden"
              ref={hiddenAnchorRef}
              download
              style={{
                position: "absolute",
                top: "-200vh",
                visibility: "hidden",
              }}
            >
              Hidden download
            </a>
          </div>
        </>
      )}
    </div>
  );
}
