import { Box, Button, Stack, Typography, Card, Grid, CardContent, fabClasses } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import { getBrowserTypeForBankId } from "./bankIdBrowserType";

const returnUrlHashValue = "#returnFromApp";
const browserType = getBrowserTypeForBankId();
let timeout;

const BankIdAuthentication = ({
    startAuthenticationUrl,
    postData,
    onStatusInitiated,
    onAuthenticationSuccess,
    onAuthenticationFailure = null,
    startQrCodeOnDesktop = true,
    fetchStatusIncludeCredentials = false,
    token = false
}) => {

    sessionStorage.removeItem("courses");
    const [qrCodeButtonClicked, setQrCodeButtonClicked] = useState(false);
    const [bankIdQrUrl, setBankIdQrUrl] = useState(null);
    const [bankIdAutoStartUrl, setBankIdAutoStartUrl] = useState(null);
    const [bankIdCancelUrl, setBankIdCancelUrl] = useState(null);
    const [isIntializing, setIsIntitailizing] = useState(false);
    const [bankIdStatus, setBankIdStatus] = useState(null);
    const [bankIdHintCode, setBankIdHintCode] = useState(null);
    const [tryingToOpenApp, setTryingToOpenApp] = useState(false);
    const [loginTypeAttempt, setLoginTypeAttempt] = useState("qr");
    const [ePortData, setEPortData] = useState(null);
    const api = useApi();

    useEffect(async () =>
        start(), []);

    const start = async () => {
        const hash = window.location.hash;
        const existingSessionStatusUrl = localStorage.getItem("eportBankIdStatusUrl");
        
        if(hash.indexOf(returnUrlHashValue) > -1 && existingSessionStatusUrl) {
            // retur från app
            //alert("pollstatus");
            pollStatus(null, existingSessionStatusUrl);
        } else {
            if(browserType === "desktop" && startQrCodeOnDesktop) {
                await initBankId();
            }
        }
    };

    const initBankId = async () => {
        setIsIntitailizing(true);
        localStorage.removeItem("eportBankIdStatusUrl");
        setEPortData(null);
        // genom att använda exakt samma url kommer iphone att använda samma tab när man kommer tillbaka från bankid-appen
        // det är dock ok att lägga till ett hashvärde (#returnFromApp) för att markera att signering har skett. Däremot är det inte ok att lägga till querystring
        const currentUrl = window.location.href.split("#")[0]; // tabort tidigare hashvärde
        const request = { 
            ...postData, 
            language: Number(localStorage.lang ? localStorage.lang : 29), // 29 - swedish
            browserType: browserType,
            returnUrl: `${currentUrl}${returnUrlHashValue}` // hashvärdet inidkerar att vi kommer tillbaka från bankidappen
        };

        const bankIdInitResponse = await api.fetch(
            startAuthenticationUrl,
            request, 
            "POST",
            token, 
            true,
            false, 
            fetchStatusIncludeCredentials);

        setIsIntitailizing(false);

        if(bankIdInitResponse.isSuccessful !== false) {
            setLoginTypeAttempt("qr");
            const bankIdStatusResponse = await api.fetch(
                bankIdInitResponse.statusUrl,
                null,
                "GET",
                token, 
                true,
                false, 
                fetchStatusIncludeCredentials);
            
            // spara i sessionStorage för att kunna användas om sidan laddas igen användare kommer tillbaka från appen
            localStorage.setItem("eportBankIdStatusUrl", bankIdInitResponse.statusUrl);
            
            // lägg till tidsparameter så att bilden måste laddas om varje gång 
            setBankIdQrUrl(`${bankIdInitResponse.qrCodeUrl}?t=${Date.now()}`);
            
            setBankIdAutoStartUrl(bankIdInitResponse.autoStartUrl);
            setBankIdCancelUrl(bankIdInitResponse.cancelUrl);
            setBankIdStatus(bankIdStatusResponse.status);
            setBankIdHintCode(bankIdStatusResponse.hintCode);
            if(onStatusInitiated) 
            {   
                onStatusInitiated(bankIdInitResponse);
            }
            timeout = setTimeout(() => pollStatus(bankIdInitResponse.qrCodeUrl, bankIdInitResponse.statusUrl), 1000);
            return bankIdInitResponse;
        } else {
            setBankIdStatus("failed");
            return null;
        }
    }

    const pollStatus = async (qrCodeUrl, statusUrl) => {
        if(qrCodeUrl) {
            setBankIdQrUrl(`${qrCodeUrl}?t=${Date.now()}`);
        }
        const bankIdStatusResponse = 
            await api.fetch(
                statusUrl,
                null,
                "GET",
                token, 
                true,
                false, 
                fetchStatusIncludeCredentials);

        setBankIdStatus(bankIdStatusResponse.status);
        setBankIdHintCode(bankIdStatusResponse.hintCode);
        if(bankIdStatusResponse.status === "complete" || bankIdStatusResponse.status === "failed")
        {
            localStorage.removeItem("eportBankIdStatusUrl");
            if(bankIdStatusResponse.status === "complete") {
                setEPortData(bankIdStatusResponse.data);
                if(bankIdStatusResponse.data.isSuccessful) {
                    if(onAuthenticationSuccess) {
                       onAuthenticationSuccess(loginTypeAttempt, bankIdStatusResponse)
                    }
                }
                else {
                    if(onAuthenticationFailure) {
                        onAuthenticationFailure(loginTypeAttempt, bankIdStatusResponse)
                     }
                }
            }    
        } else {
            timeout = setTimeout(() => pollStatus(qrCodeUrl, statusUrl), 1000);
        }
    };

    
    const cancelBankIdLogin = async () => {
        if(timeout) {
            clearTimeout(timeout);
        }
        setTryingToOpenApp(false);
        setQrCodeButtonClicked(false);
        localStorage.removeItem("eportBankIdStatusUrl");
        setBankIdQrUrl(null);
        setBankIdAutoStartUrl(null);
        setBankIdStatus(null);
        setBankIdHintCode(null);

        await api.fetch(bankIdCancelUrl, null, "POST", false);
        setBankIdCancelUrl(null);
    };

    const openBankIdAppClick = async () => {
        const hash = window.location.hash;
        
        if(hash.indexOf(returnUrlHashValue) > -1) {
            window.location.hash = "";
        }

        if(bankIdAutoStartUrl) {
            // vi har redan initierat
            openBankIdApp(bankIdAutoStartUrl);
        } else {
            const response = await initBankId();
            openBankIdApp(response.autoStartUrl);
        }
    };

    const openBankIdApp = (url) => {     
        setTryingToOpenApp(true);
        const autoStartUrl = url; // .split("&redirect=")[0];
            const loginType = browserType === "desktop" ? "desktop" : "app";
            setLoginTypeAttempt(loginType);
     
            window.location.replace(autoStartUrl);

            setTimeout(() => {
                setTryingToOpenApp(false);
                // display error after 10 seconds if app doesn't open
            }, 10000);
    };

    const showQrCodeButtonClick = async () => {
        setQrCodeButtonClicked(true);
        if(!bankIdQrUrl) {
            await initBankId();
        }
    };

    const getUserMessage = () => {
        let tranlsationKey = "";
        if(bankIdStatus === "pending") {
            if(bankIdHintCode === "outstandingTransaction"
            || bankIdHintCode === "started") {
                if(tryingToOpenApp) {
                    tranlsationKey = "BankIdTryingToStartApp";
                }
                tranlsationKey = "BankIdStartAppThenScanQr";
            }
            if (bankIdHintCode === "userSign") {
                // if(browserType === "desktop") {
                //     return "Skriv din säkerhetskod i BankID-programmet och välj Identifiera"; // todo signera
                // }
                tranlsationKey = "BankIdUserSignIdentify"; // todo signera
            }
        }
        
        if(bankIdStatus === "failed"){
            switch(bankIdHintCode)
            {
                case "cancelled":
                case "userCancel":
                    tranlsationKey = "BankIdSignCancelled";
                    break;

                case "startFailed":
                {
                    if(loginTypeAttempt === "qr") {
                        // This is want bankid states: 
                        // tranlsationKey = "BankIdStartFailedQr";
                        
                        // We use our own message
                        tranlsationKey = "BankIdQrScanFailed";
                    }
    
                    tranlsationKey = "BankIdStartFailed";

                    break;
                }

                case "expiredTransaction":
                    tranlsationKey = "BankIdExpiredTransaction";
                    break;
                
                case "certificateErr":
                    tranlsationKey = "BankIdCertificateError";
                    break;
                
                case "alreadyInProgress":
                    tranlsationKey = "BankIdAlreadyInProgress";
                    break;
            
                default:
                    tranlsationKey = "BankIdUnspecifiedError"
                    break;
            }
        }

        if(bankIdStatus === "complete") {
            // bankId identifiering lyckades 

            if(ePortData && ePortData.isSuccessful === false) {
                // resultat från eport (efter lyckad bankid identifiering) visar misslyckande
                tranlsationKey = ePortData.errorMessageTranslationKey;
            } 
        }

        return tranlsationKey ? Translate.get(tranlsationKey) : "";
    };

    const showQrCode = 
        ((browserType === "desktop" && startQrCodeOnDesktop) || qrCodeButtonClicked) 
        && bankIdQrUrl
        && !tryingToOpenApp
        && bankIdStatus === "pending"
        && bankIdHintCode === "outstandingTransaction";


    const showQrCodeButton = 
        !qrCodeButtonClicked && (
            browserType !== "desktop"
            || (browserType === "desktop" && !startQrCodeOnDesktop));

    const showStartAppButton =
        !qrCodeButtonClicked
        && !tryingToOpenApp
        && !bankIdStatus || (bankIdStatus === "pending"
        && bankIdHintCode === "outstandingTransaction");
    
    const showTryAgainButton =
        bankIdStatus === "failed"
        || (ePortData && ePortData.isSuccessful === false);

    const showCancelButton = 
        (tryingToOpenApp || qrCodeButtonClicked)
            && bankIdStatus !== "failed";
    
    const showSpinner = 
        isIntializing
        || tryingToOpenApp
        || (bankIdStatus === "pending"
            && bankIdHintCode === "userSign");

    const showMessageAndQrCard = showQrCode || !showQrCodeButton;


return (
    <Stack spacing={3} alignItems="center">
        {showMessageAndQrCard
            && <Card sx={{width: "300px", height: "350px"}}>
                <CardContent>
                    <Grid direction="column"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        spacing={0}>
                        
                        {showSpinner 
                            && <Box component="img" src="/static/Spinner_ePort.svg" sx={{ margin: "auto", width: "75%", height: "75%"}} /> }
                        <Typography>
                            {getUserMessage()}<br />
                        </Typography>
        
                        {showQrCode
                            && <img src={bankIdQrUrl} alt="Qr-Kod" />}
                    </Grid>
                </CardContent> 
            </Card>
        }
        <Stack
            justifyContent="space-between"
            spacing={1}
        >
            {!showQrCodeButton && showStartAppButton &&
            <Typography sx={{textAlign: "center"}} variant="button">
                {Translate.get("Or")}
            </Typography> }

            {showStartAppButton
                && <LoadingButton
                    className="stdBlueLoader"
                    fullWidth
                    size="large"
                    type="button"
                    onClick={openBankIdAppClick}
                    variant="contained"
                    loading={tryingToOpenApp}
                >
                {(browserType === "desktop" && Translate.get("BankIdStartAppButtonDesktop"))
                    || Translate.get("BankIdStartAppButtonMobile")}
                </LoadingButton>
            }
            {showStartAppButton
            && showQrCodeButton
                && <Typography sx={{textAlign: "center"}} variant="button">
                        {Translate.get("Or")}
                    </Typography>
            }
            {showQrCodeButton
                && <Button 
                    variant="contained"
                    size="large" 
                    onClick={showQrCodeButtonClick} autoFocus={(browserType === "desktop" ? true : false)}>
                        {Translate.get("BankIdLogInUsingOtherDevice")}
                    </Button>
            }
            {showCancelButton
                && <Button fullWidth onClick={cancelBankIdLogin}>{Translate.get("Cancel")}</Button>}
        </Stack>
        {showTryAgainButton
            && <Button fullWidth onClick={initBankId}>{Translate.get("TryAgain")}</Button>}
    </Stack>
 )};

export default BankIdAuthentication;