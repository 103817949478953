// vi behöver använda en annan backend-url för att cookies ska fungera om siten använder adressen kortillstand.se
const getAuthBaseUrl = () => window.global?.isKs
? process.env.REACT_APP_KORTILLSTAND_AUTH_URL
: process.env.REACT_APP_AUTH_URL;

const getPermitsUrl = () => `${getBackendBaseUrl()}permits/`;
const getPermitsSignUrl = () => `${getPermitsUrl()}sign`;
const getPermitsPdfUrl = () => `${getPermitsUrl()}pdf`;
const getPermitsGroupsUrl = () =>`${getPermitsUrl()}groups/`;
const getPermitGroupsSignUrl = () => `${getPermitsGroupsUrl()}sign`;

export function getBackendBaseUrl () {
  return window.global?.isKs 
  ? process.env.REACT_APP_KORTILLSTAND_MAIN_URL
  : process.env.REACT_APP_MAIN_URL;
}

export async function getPermitGroup(actorPermitGroupId) {
  const url =
    `${getPermitsGroupsUrl()}?actorPermitGroupId=${actorPermitGroupId}`;

  return get(url);
}

export async function getPermit(actorPermitId) {
  const url =
  `${getPermitsUrl()}?actorPermitId=${actorPermitId}`;

  return get(url);
}

export async function signPermitGroup(actorPermitGroupId, isMobile) {

  return post(getPermitGroupsSignUrl(), {
    actorPermitGroupId: actorPermitGroupId,
    autoStartUrlFormatForMobileDevice: isMobile
  });
}

export async function signPermit(actorPermitId, isMobile) {

    return post(getPermitsSignUrl(), {
      actorPermitId: actorPermitId,
      autoStartUrlFormatForMobileDevice: isMobile
    });
}

export async function getPermitPdfBlob(actorPermitId) {
  const url = `${getPermitsPdfUrl()}?actorPermitId=${actorPermitId}`

  return await getBlob(url);
}

export async function doDeletePermit(actorPermitId) {
  const url = `${getPermitsUrl()}${actorPermitId}/for-responsible`;
  return await performDelete(url)
}

export async function getDataIdDetails() {
  const url = `${getBackendBaseUrl()}dataId/details/for-permit-sign`;
  return await get(url);
}

export async function getLogoDataUrl() {
  const url = `${getBackendBaseUrl()}dataIds/blobs/logo/dataUrl/for-permit-sign`;
  return await get(url);
}

export async function logout() {
  const url = `${getAuthBaseUrl()}auth/bankid/logout/permit`;
  return await post(url, null);
}

async function get(url) {
  return fetch(url, {
    method: "GET",
    credentials:"include"
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}

async function post(url, requestBodyObject) {
  return fetch(url, {
    method: "POST",
    credentials:"include",
    headers: {
      "Content-Type": "application/json",
    },
    body: requestBodyObject ? JSON.stringify(requestBodyObject) : undefined 
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}

async function performDelete(url, requestBodyObject) {
  return fetch(url, {
    method: "DELETE",
    credentials:"include",
    headers: {
      "Content-Type": "application/json",
    },
    body: requestBodyObject ? JSON.stringify(requestBodyObject) : undefined
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}

async function getBlob(url, options) {
  return fetch(url, {
    method: "GET",
    credentials:"include"
  })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}