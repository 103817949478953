import { Avatar, Dialog, DialogContent } from "@mui/material";
import { useState } from "react"
import { stringToColor } from "../../utils/formatString";

const ActorCardAvatar = ({actorName, profilePictureUrl,variant='circular',avatarWidth=80,avatarHeight=80})  => 
{
    const [showImageDialog, setShowImageDialog] = useState(false);

    return <>
    <Avatar onClick={() => profilePictureUrl && setShowImageDialog(true)}
        alt={actorName}
        src={profilePictureUrl + ""}
        sx={{cursor:'pointer', width: avatarWidth, height: avatarHeight, fontSize: 40, bgcolor:stringToColor(actorName) }}
        variant={variant}
      />

        {showImageDialog && <Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
            <DialogContent>
                <img src={profilePictureUrl} alt="" />
            </DialogContent>
        </Dialog>
        }
  </>
}

export default ActorCardAvatar;