import Translate from "../../../utils/Translate";

export function validateActorPermitSkills(actorPermitSkills, validFrom) {
  if (actorPermitSkills && actorPermitSkills.length <= 0) {
    return Translate.get("PermitLeastOneCompetence");
  }
}

export function validateActorPermitValidFrom(validFrom) {
  if (!validFrom) {
    return Translate.get("PermitMustSelectFromDate");
  }
}
