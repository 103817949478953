import { Grid } from "@mui/material";

export default function TooltipField({ label, value }) {
  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={6} className="tooltipField">{`${label}:`}</Grid>
      <Grid item xs={6} className="tooltipField" textAlign="right">
        {value}
      </Grid>
    </Grid>
  );
}
