import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import SelectBookingTypeDialog from "./SelectBookingTypeDialog";
import InternalBookingDialog from "./InternalBookingDialog";
import CompanyBookingDialog from "./CompanyBookingDialog";
import StudentDetailsDialog from "./StudentsDetailsDialog";

export default function BookingFlowDialogContainer({
  courseId,
  bookings,
  isELearningCourse,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingType, setBookingType] = useState(null);
  const [courseBookingId, setCourseBookingId] = useState(null);
  const [company, setCompany] = useState(null);
  const [contact, setContact] = useState(null);
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [studentCountToAdd, setStudentCountToAdd] = useState(0);
  const api = useApi();

  // Step 1 - SelectBookingTypeDialog
  // Step 2 - InternalBookingDialog / CompanyBookingDialog (PrivateBookingDialog skips step 2)
  // Step 3 - StudentsDetailsDialog / PrivateBookingDialog
  const [currentStep, setCurrentStep] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  async function getActorIdOfDataId() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataIdSettings/actorIdOfDataIdRepresentative`,
      false,
      "GET"
    );
    return response.isSuccessful ? response.settingValue : null;
  }

  async function handleSelectBookingType(newBookingType, courseBookingId) {
    setBookingType(newBookingType);
    setCourseBookingId(courseBookingId);

    // This will be true for existing company or internal bookings
    if (!!courseBookingId && courseBookingId > 0) {
      const booking = bookings.find(
        (booking) => booking.courseBookingId === courseBookingId
      );

      const currentStudentCount = booking.courseParticipants.length;
      const selectedStudentCountToAdd = 1;
      const selectedTotalStudentCount =
        currentStudentCount + selectedStudentCountToAdd;
      const selectedCompany = booking.actor;

      if (booking.contactActorId > 0) {
        const selectedContact = {
          actorId: booking.contactActorId,
          actorName: booking.contactActorName,
          email: booking.contactActorEmail,
        };
        setContact(selectedContact);
      } else {
        setContact(null);
      }

      setCompany(selectedCompany);
      setTotalStudentCount(selectedTotalStudentCount);
      setStudentCountToAdd(selectedStudentCountToAdd);
    } else {
      if (newBookingType === "internalBooking") {
        // For internal booking we don't select the company, so here
        // we just load the correct actorId which is needed later.
        const selfActorId = await getActorIdOfDataId();
        setCompany({ actorId: selfActorId });
      } else {
        setCompany(null);
      }

      setContact(null);
      setTotalStudentCount(1);
      setStudentCountToAdd(1);
    }
  }

  function handleStudentCountChanged(newStudentCount) {
    // Remove non-numeric characters (\D = non-numeric, /g = find all matches)
    newStudentCount =
      typeof newStudentCount === "string"
        ? newStudentCount.replace(/\D/g, "")
        : newStudentCount;
    const parsedStudentCount = parseInt(newStudentCount);
    if (courseBookingId && courseBookingId > 0) {
      setTotalStudentCount(
        (prev) => prev - studentCountToAdd + parsedStudentCount
      );
      setStudentCountToAdd(parsedStudentCount);
    } else {
      setTotalStudentCount(parsedStudentCount);
      setStudentCountToAdd(parsedStudentCount);
    }
  }

  async function postNewCompany(newCompany) {
    if (!!newCompany.actorId) {
      throw new Error(
        "Trying to create a new company when the company already has an actorId"
      );
    }

    const dto = {
      actorName: newCompany.actorName,
    };

    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}actors/clients/`,
      dto,
      "POST",
      (response) => response
    );

    if (response) {
      return response.actorId;
    } else {
      return -1;
    }
  }

  async function postNewOrExistingContact(contactToAddOrUpdate) {
    if (
      contactToAddOrUpdate.contactForCompanies?.some(
        (x) => x === company.actorId
      )
    ) {
      // Already a contact for the company, no need to add or update
      return contactToAddOrUpdate.actorId;
    }

    const dto = {
      actorId: contactToAddOrUpdate.actorId ?? 0,
      actorName: contactToAddOrUpdate.actorName,
      actorEmail: contactToAddOrUpdate.email,
    };

    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}actors/clients/${company.actorId}/contacts/`,
      dto,
      "POST",
      (response) => response
    );

    if (response) {
      return response.actorId;
    } else {
      return -1;
    }
  }

  async function postNewPrivateClient(student) {
    if (!!student.actorId) {
      throw new Error(
        "Trying to create a new private client when the private client already has an actorId"
      );
    }

    const dto = {
      actorName: student.actorName,
      actorEmail: student.email,
    };

    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}actors/clients/private/`,
      dto,
      "POST",
      (response) => response
    );

    if (response) {
      return response.actorId;
    } else {
      return -1;
    }
  }

  async function postCourseBooking(bookerActorId, bookingContactActorId) {
    const dto = {
      courseId,
      courseBookingId: !!courseBookingId ? courseBookingId : 0,
      numberOfParticipants: totalStudentCount,
      bookerActorId: bookerActorId,
      bookingContactActorId: bookingContactActorId,
    };

    const url =
      `${process.env.REACT_APP_MAIN_URL}courses/bookings/` +
      (bookingType === "internalBooking" ? "internal/" : "") +
      (bookingType === "privateBooking" ? "private/" : "");

    const response = await api.fetch(url, dto, "POST");

    if (response && response.isSuccessful) {
      setCourseBookingId(response.courseBooking.courseBookingId);
      return response.courseBooking.courseBookingId;
    } else {
      return -1;
    }
  }

  async function postAddOrUpdateStudents(courseBookingId, students) {
    if (bookingType === "privateBooking") {
      throw new Error("Don't add students for private bookings");
    }

    const dto = {
      courseParticipants: students
        .filter((student) => !!student.actorName)
        .map((student) => ({
          courseBookingId: courseBookingId,
          courseParticipantId: 0,
          actorId: student.actorId ?? 0,
          actor: {
            actorId: student.actorId ?? 0,
            actorName: student.actorName,
            actorEmail: student.email,
          },
        })),
    };

    if (dto.courseParticipants.length === 0) {
      return true;
    }

    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}courses/participants/many/`,
      dto,
      "POST",
      (response) => response
    );

    if (response) {
      return true;
    } else {
      return false;
    }
  }

  async function postMoveStudents(courseBookingId, students) {
    if (bookingType === "privateBooking") {
      throw new Error("Don't move students for private bookings");
    }

    for (const student of students) {
      const dto = {
        courseBookingId: courseBookingId,
        courseParticipantIdToMove: student.activeCourseParticipantId,
      };

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/participants/move/`,
        dto,
        "POST"
      );

      if (!response || !response.isSuccessful) {
        return false;
      }
    }

    return true;
  }

  async function handleCompanySelected(selectedCompany) {
    setCompany(selectedCompany);

    if (!selectedCompany.actorId) {
      const actorId = await postNewCompany(selectedCompany);
      setCompany({ ...selectedCompany, actorId });
    }
  }

  async function handleContactSelected(selectedContact) {
    setContact(selectedContact);
    if (!selectedContact) {
      return;
    }

    // If this is an existing contact the returned actorId will be the same.
    // But we still need to call this method, to connect the person if needed.
    const actorId = await postNewOrExistingContact(selectedContact);
    setContact({ ...selectedContact, actorId });
  }

  async function handleCompleteBooking(students) {
    setIsLoading(true);

    let bookerActorId = null;
    let bookingContactActorId = null;

    if (bookingType === "privateBooking") {
      // For private booking, the student is also the booker and the contact
      bookerActorId =
        students[0].actorId ?? (await postNewPrivateClient(students[0]));
      bookingContactActorId = bookerActorId;
    } else if (bookingType === "companyBooking") {
      bookerActorId = company.actorId;
      // There should be a contact, but some older bookings might not have one.
      bookingContactActorId = !!contact ? contact.actorId : null;
    }

    const courseBookingId = await postCourseBooking(
      bookerActorId,
      bookingContactActorId
    );
    const isBookingOk = courseBookingId > 0;

    if (isBookingOk && bookingType === "privateBooking") {
      // No need to add student separately for private booking
      closeAndReload();
      return;
    }

    if (isBookingOk && bookingType !== "privateBooking") {
      const studentsToMove = students.filter(
        (student) =>
          student.activeCourseParticipantId && student.completeAction === "move"
      );
      const studentsToAdd = students.filter(
        (student) =>
          !student.activeCourseParticipantId ||
          (student.activeCourseParticipantId &&
            student.completeAction === "add")
      );

      const isMoveStudentsOk = await postMoveStudents(
        courseBookingId,
        studentsToMove
      );

      const isAddStudentsOk = await postAddOrUpdateStudents(
        courseBookingId,
        studentsToAdd
      );
      if (isMoveStudentsOk && isAddStudentsOk) {
        closeAndReload();
        return;
      }
    }

    setIsLoading(false);
  }

  function handleContinueStep1() {
    const isExistingBooking = !!courseBookingId && courseBookingId > 0;
    const isPrivateBooking = bookingType === "privateBooking";
    const nextStep = isExistingBooking || isPrivateBooking ? 3 : 2;
    setCurrentStep(nextStep);
  }

  const handleContinueStep2 = () => setCurrentStep((prevStep) => prevStep + 1);

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          {currentStep === 1 && (
            <SelectBookingTypeDialog
              bookings={bookings}
              onContinue={handleContinueStep1}
              selectedCourseBookingId={courseBookingId}
              selectedBookingType={bookingType}
              onSelectBookingType={handleSelectBookingType}
            />
          )}
          {currentStep === 2 && bookingType === "internalBooking" && (
            <InternalBookingDialog
              onContinue={handleContinueStep2}
              studentCount={totalStudentCount}
              onStudentCountChange={handleStudentCountChanged}
              isELearningCourse={isELearningCourse}
              courseId={courseId}
            />
          )}
          {currentStep === 2 && bookingType === "companyBooking" && (
            <CompanyBookingDialog
              onContinue={handleContinueStep2}
              company={company}
              onCompanyChange={handleCompanySelected}
              contact={contact}
              onContactChange={handleContactSelected}
              studentCount={totalStudentCount}
              onStudentCountChange={handleStudentCountChanged}
              isELearningCourse={isELearningCourse}
              courseId={courseId}
            />
          )}
          {currentStep === 3 && (
            <StudentDetailsDialog
              studentCount={studentCountToAdd}
              onStudentCountChange={handleStudentCountChanged}
              onCompleteBooking={handleCompleteBooking}
              isPrivateBooking={bookingType === "privateBooking"}
              courseId={courseId}
              companyActorId={company?.actorId}
            />
          )}
        </>
      )}
    </>
  );
}
