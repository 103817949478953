import {
  Link,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Translate from "../../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import DomainIcon from "@mui/icons-material/Domain";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EmailIcon from "@mui/icons-material/Email";
import ELearningLicenseCount from "./ELearningLicenseCount";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import EmailValidatingTextField from "../../EmailValidatingTextField";
import { useState, useCallback, useEffect } from "react";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import { Link as RouterLink } from "react-router-dom";
import {
  actorSearch,
  actorCompanyTypes,
  actorPersonTypes,
} from "../../../utils/actorSearch";
import { useApi } from "../../../utils/Api";

export default function CompanyBookingDialog({
  company,
  onCompanyChange,
  contact,
  onContactChange,
  isELearningCourse,
  onContinue,
  studentCount,
  onStudentCountChange,
  courseId,
}) {
  const [availableLicenseCount, setAvailableLicenseCount] = useState(null);
  const [companyContacts, setCompanyContacts] = useState([]);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (!!company && company.actorId) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/clients/${company.actorId}/contacts/`,
          false,
          "GET"
        )
        .then((foundContacts) => {
          const contacts = foundContacts.map((actor) => ({
            actorId: actor.actorId,
            actorName: actor.actorName,
            orgNo: actor.orgNo,
            email: actor.email,
            companies: actor.parentActors
              ?.filter(
                (x, index, allParentActors) =>
                  x.parentActor.actorId !== actor.actorId &&
                  x.parentActor.actorName !== "ePortMaster" &&
                  allParentActors.findIndex(
                    (y) => y.parentActor.actorId === x.parentActor.actorId
                  ) === index
              )
              .map((x) => x.parentActor.actorName),
          }));
          setCompanyContacts(contacts);
        });
    } else {
      setCompanyContacts([]);
    }
  }, [company, api]);

  const handleAvailableLicenseCountChanged = useCallback(
    (newCount) => setAvailableLicenseCount(newCount),
    []
  );

  const companySearch = useCallback(async (searchText) => {
    const foundCompanies = await actorSearch(
      searchText,
      actorCompanyTypes,
      ["ActorName"],
      150
    );
    if (foundCompanies) {
      return foundCompanies.map((company) => ({
        actorId: company.actorId,
        actorName: company.actorName,
        orgNo: company.orgNo,
        email: company.email,
        addressStreet: company.postalAddress?.actorAddressStreet2,
        addressZipCode: company.postalAddress?.actorAddressZipCode,
        addressCity: company.postalAddress?.actorAddressCity,
      }));
    }
  }, []);

  const compareContacts = useCallback(
    (lhs, rhs) => {
      const lhsCompanyConnections =
        company &&
        lhs.parentActors?.filter(
          (parent) => parent.parentActor.actorId === company.actorId
        );
      const lhsIsConnectedToCompany =
        !!lhsCompanyConnections && lhsCompanyConnections.length > 0;
      const lhsIsContactToCompany =
        lhsIsConnectedToCompany &&
        lhsCompanyConnections.some((parent) => parent.actorType === 3);

      const rhsCompanyConnections =
        company &&
        rhs.parentActors?.filter(
          (parent) => parent.parentActor.actorId === company.actorId
        );
      const rhsIsConnectedToCompany =
        !!rhsCompanyConnections && rhsCompanyConnections.length > 0;
      const rhsIsContactToCompany =
        rhsIsConnectedToCompany &&
        rhsCompanyConnections.some((parent) => parent.actorType === 3);

      if (
        (lhsIsContactToCompany && !rhsIsContactToCompany) ||
        (lhsIsConnectedToCompany && !rhsIsConnectedToCompany)
      ) {
        return -1;
      }

      if (
        (rhsIsContactToCompany && !lhsIsContactToCompany) ||
        (rhsIsConnectedToCompany && !lhsIsConnectedToCompany)
      ) {
        return 1;
      }

      // Use the order from backend in this case
      return 0;
    },
    [company]
  );

  const contactSearch = useCallback(
    async (searchText) => {
      const foundContacts = await actorSearch(
        searchText,
        actorPersonTypes,
        ["ActorName"],
        150
      );
      if (foundContacts) {
        return foundContacts.sort(compareContacts).map((contact) => ({
          actorId: contact.actorId,
          actorName: contact.actorName,
          orgNo: contact.orgNo,
          email: contact.email,
          contactForCompanies: contact.parentActors
            ?.filter((x) => x.actorType === 3)
            .map((x) => x.parentActor.actorId),
          companies: contact.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId > 0 &&
                x.parentActor.actorId !== contact.actorId &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
      }
    },
    [compareContacts]
  );

  const isValidCompany = !!company && !!company.actorName;
  const isValidContact = !!contact && !!contact.actorName;
  const isValidStudentCount = !isNaN(studentCount) && studentCount > 0;
  const canContinue = isValidCompany && isValidContact && isValidStudentCount;
  const [contactEmail, setContactEmail] = useState("");
  const [validMail, setValidMail] = useState(true);
  return (
    <>
      <DialogTitle>{Translate.get("EnterStudentCount")}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px", maxWidth: "600px" }}>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <DomainIcon sx={{ marginLeft: "9px" }} />
            <AutocompleteSearchField
              label={Translate.get("Company")}
              value={company}
              onValueChange={onCompanyChange}
              getOptionLabel={(option) => option?.actorName ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.actorName}
                  orgNo={option?.orgNo}
                  addressStreet={option?.addressStreet}
                  addressZipCode={option?.addressZipCode}
                  addressCity={option?.addressCity}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={companySearch}
              createNewValue={(text) => ({
                actorName: text,
              })}
              minWidth={isSmallScreenOrGreater ? "496px" : "250px"}
              textFieldProps={{ required: true }}
            />
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <HeadsetMicIcon sx={{ marginLeft: "9px" }} />
            <AutocompleteSearchField
              label={Translate.get("Contact")}
              value={contact}
              onValueChange={(val) => {
                onContactChange(val);
                setContactEmail(val.email || "");
              }}
              defaultOptions={companyContacts}
              getOptionLabel={(option) => option?.actorName ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.actorName}
                  orgNo={option?.orgNo}
                  email={option?.email}
                  companies={option?.companies}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={contactSearch}
              createNewValue={(text) => ({
                actorName: text,
              })}
              minWidth={isSmallScreenOrGreater ? "496px" : "250px"}
              textFieldProps={{ required: true }}
            />
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <EmailIcon sx={{ marginLeft: "9px" }} />
            <EmailValidatingTextField
              disabled={contact?.email ? true : false}
              autoComplete="off"
              placeholder={Translate.get("EmailContactPerson")}
              label={Translate.get("EmailContactPerson")}
              sx={{ minWidth: "270px", width: "100%" }}
              value={contactEmail}
              //value={contact?.email || ""}
              onEmailChange={(newValue) => {
                setContactEmail(newValue);
              }}
              onValidationChange={(isValid) => {
                setValidMail(isValid);
              }}
            />
          </Stack>

          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <PeopleIcon sx={{ marginLeft: "9px" }} />
            <TextField
              sx={{
                minWidth: isSmallScreenOrGreater ? "120px" : "250px",
                width: "100%",
              }}
              value={isNaN(studentCount) ? "" : studentCount}
              onChange={(event) => onStudentCountChange(event.target.value)}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                type: "number",
              }}
              error={
                isELearningCourse &&
                !!availableLicenseCount &&
                studentCount > availableLicenseCount
              }
            />
            {isELearningCourse && isSmallScreenOrGreater && (
              <ELearningLicenseCount
                courseId={courseId}
                onAvailableLicenseCountChanged={
                  handleAvailableLicenseCountChanged
                }
              />
            )}
          </Stack>
          {isELearningCourse && !isSmallScreenOrGreater && (
            <Stack direction="row" spacing={1} className="stdFlexLeft">
              <ELearningLicenseCount
                courseId={courseId}
                onAvailableLicenseCountChanged={
                  handleAvailableLicenseCountChanged
                }
              />
            </Stack>
          )}
        </Stack>
        <Typography
          variant="body2"
          sx={{
            margin: "33px 0 20px",
            color: "text.secondary",
          }}
        >
          {isELearningCourse
            ? Translate.get("SelectStudentCountElearningInfo").replace(
                "{0}",
                availableLicenseCount ?? "XX"
              )
            : Translate.get("SelectStudentCountInfo")}
        </Typography>
        {isELearningCourse && (
          <Link to="/dashboard/licenses" component={RouterLink}>
            {Translate.get("ClickHereToOrderELearning")}
          </Link>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
        <Button
          onClick={() => {
            if (contactEmail) {
              if (contactEmail !== contact?.email) {
                //Uppdatera actor email
                contact.email = contactEmail;
                onContactChange(contact);
              }
            }
            onContinue();
          }}
          disabled={
            !validMail ||
            !canContinue ||
            (isELearningCourse && studentCount > availableLicenseCount)
          }
        >
          {Translate.get("Continue")}
        </Button>
      </DialogActions>
    </>
  );
}
