import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  useTheme,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useNavigate, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { useApi } from "../../utils/Api";
import { LoadingButton } from "@mui/lab";

function getStyles(filterGroupIds, selectedFilterGroupIds, theme) {
  return {
    fontWeight:
      selectedFilterGroupIds.indexOf(filterGroupIds) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function InstructorFilterGroupsDialog({
  teacherActorId,
  allFilterGroups,
  instructorFilterGroupIds,
}) {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [selectedFilterGroupIds, setSelectedFilterGroupIds] = useState(
    instructorFilterGroupIds
  );

  const api = useApi();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname + location.search);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  async function updateFilterGroups() {
    setIsSaveLoading(true);
    const request = {
      filterGroupIds: selectedFilterGroupIds,
    };

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/filters/groups/teachers/${teacherActorId}`,
      request,
      "POST"
    );

    if (response?.isSuccessful) {
      closeAndReload();
    }
    setIsSaveLoading(false);
  }

  function handleDelete(filterGroupId) {
    const index = selectedFilterGroupIds.findIndex(
      (id) => id === filterGroupId
    );
    if (index !== -1) {
      const selectionCopy = [...selectedFilterGroupIds];
      selectionCopy.splice(index, 1);
      setSelectedFilterGroupIds(selectionCopy);
    }
  }

  function handleSelectionChange(event) {
    setSelectedFilterGroupIds(event?.target?.value ?? []);
  }

  return (
    <>
      <DialogContent className="stdDialogContent">
        <DialogContentText>
          <br />
          <FormControl fullWidth>
            <InputLabel id="label-select-unit">
              {Translate.get("FilterGroups")}
            </InputLabel>
            <Select
              multiple
              value={selectedFilterGroupIds}
              labelId="label-select-unit"
              label={Translate.get("FilterGroups")}
              onChange={handleSelectionChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        allFilterGroups.find((fg) => fg.filterGroupId === value)
                          ?.description
                      }
                      clickable
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={() => handleDelete(value)}
                    />
                  ))}
                </Box>
              )}
            >
              {allFilterGroups.map((fg) => (
                <MenuItem
                  key={fg.filterGroupId}
                  value={fg.filterGroupId}
                  style={getStyles(
                    fg.filterGroupId,
                    selectedFilterGroupIds,
                    theme
                  )}
                >
                  {fg.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.history.back()}>
          {Translate.get("Cancel")}
        </Button>
        <LoadingButton
          className="stdLightLoader"
          loading={isSaveLoading}
          onClick={updateFilterGroups}
        >
          {Translate.get("Save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
