import React, { useState } from 'react';
import Preloader from '../Preloader';
import Translate from '../../utils/Translate';
import { accessKeys, hasSomeAccess, hasAccess } from '../../utils/userAccess';
import FinalExamUnlockedSelect from './FinalExamUnlockedSelect';
import OrgNoFormat from './OrgNoFormat';
import { SelfRegSettings } from './SelfRegSettings';
import CourseInformation from './CourseInformation';
import { ELearningRegSettings } from './ELearningRegSettings';
import StdOrderConfirmationText from './StdOrderConfirmationText';
import PaymentAgreements from './PaymentAgreements';

export function GeneralSettings(props) {
  const [isLoading, setIsLoading] = useState(false);
  const hasStandardOrAdvanced = hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage);
  const isSwedishCompany = hasAccess(accessKeys.swedishCompany);

  return (
    <div>
      {
        isSwedishCompany &&
        <><div className="stdPage" style={{ display: 'flex', justifyContent: 'center' }}>
          {isLoading && <Preloader />}
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get('OrgNoFormat')}</div>
            <div className="stdCardBody">
              <OrgNoFormat setIsLoading={setIsLoading} />
            </div>
          </div>
        </div><br /></>
      }


      <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5em' }}>
        <div className="stdCard" style={{ maxWidth: '400px' }}>
          <div className="stdCardHeader">{Translate.get("FinalTest")}</div>
          <div className="stdCardBody"></div>
          <p>{Translate.get("AsStandardFinalExam")}</p>
          <FinalExamUnlockedSelect setIsLoading={setIsLoading} />
        </div>
      </div>

      {
        hasStandardOrAdvanced
        && <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5em' }}>
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get("SelfRegFields")}</div>
            <div className="stdCardBody"></div>
            <SelfRegSettings setIsLoading={setIsLoading} />
          </div>
        </div>
      }
      {hasAccess(accessKeys.advancedPackage)
        && <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5em' }}>
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get("StdTextCourseSummoning")}</div>
            <div className="stdCardBody"></div>
            <CourseInformation setIsLoading={setIsLoading} />
          </div>
        </div>
      }

      {hasAccess(accessKeys.advancedPackage)
        && <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5em' }}>
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get("StdTextOrderConfirmation")}</div>
            <div className="stdCardBody"></div>
            <StdOrderConfirmationText setIsLoading={setIsLoading} />
          </div>
        </div>
      }

      {hasAccess(accessKeys.advancedPackage)
        && <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5em' }}>
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get("PaymentTerms")}</div>
            <div className="stdCardBody"></div>
            <PaymentAgreements setIsLoading={setIsLoading} />
          </div>
        </div>
      }

      {
        // Dölj tillsvidare
        /*
        <>
        <div className="stdPage" style={{ display: 'flex', justifyContent: 'center' }}>
          {isLoading && <Preloader />}
          <div className="stdCard" style={{ maxWidth: '400px' }}>
            <div className="stdCardHeader">{Translate.get('RegFieldsELearning')}</div>
            <div className="stdCardBody">
              <ELearningRegSettings setIsLoading={setIsLoading} />
            </div>
          </div>
        </div><br />
        </>
        */
      }
    </div>
  );
}
