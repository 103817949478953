import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import './stylesheet.css';
import TablePagination from '@mui/material/TablePagination';
import { useSearchParams } from "react-router-dom";
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { Icon } from '@iconify/react';
import { notificationTypeIcon, formatDate, getRedicrectionLink, parseMessage } from "../components/notifications/notificationParser.js"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useApi } from '../utils/Api';
import Translate from '../utils/Translate';
import Preloader from '../components/Preloader';

import { Tooltip, IconButton } from '@mui/material';
export default function Notifications(props) {
    const [messages, setMessages] = useState([])
    const [newMessages, setNewMessages] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
    const [searchParams, setSearchParams] = useSearchParams();
    const smallScreen = useMediaQuery('(max-width:700px)');
    const api = useApi();

    useEffect(() => {
        setIsLoading(true)
        recalculate();
    }, [])

    useEffect(() => {
        const newPage = parseInt(searchParams.get("page"));
        if (isNaN(newPage)) {
            searchParams.set('page', 0);
            setSearchParams(searchParams);
        } else {
            setPage(newPage);
        }        
    }, [searchParams, setSearchParams]);

    function recalculate() {
        api.fetch(
            `${process.env.REACT_APP_MAIN_URL}notifications/getMessages`,
            false,
            'GET',
        ).then((data) => {
            if (!data.error && data?.messages) {
                setMessages(data.messages)
                setNewMessages(data.newMessages);
                setIsLoading(false)
            }
        }).finally(f => {
            setIsLoading(false);
        })
    };

    function markAllAsRead() {
        let reqBody = { MessageIds: messages.map(m => m.id) }
        api.fetch(
            `${process.env.REACT_APP_MAIN_URL}notifications/markMessageStatusAsRead`,
            reqBody,
            'POST',
            false,
            true,
        ).then((data) => {
            if (!data.error) {
                recalculate()
            }
        }).finally(f => {
            setIsLoading(false);
        })
    }


    function handleChangePage(event, newPage) {
        setPage(newPage);
        searchParams.set('page', newPage);
        setSearchParams(searchParams);
    };

    const handleMarkAllAsRead = () => {
        markAllAsRead()
    };

    return (
        <div>
            {isLoading && <Preloader />}
            <h1>{Translate.get('Notifications')}</h1>

            <TableContainer component={Paper} sx={{ padding: 0.15, maxWidth: 1700 }}>
                <Table sx={{ minWidth: smallScreen ? '' : '500px', }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>{Translate.get('Unread')} ({newMessages})  {newMessages > 0 && (
                                <Tooltip title={Translate.get('MarkAllAsRead')}>
                                    <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                        <Icon icon={doneAllFill} width={20} height={20} />
                                    </IconButton>
                                </Tooltip>
                            )}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                hover
                                className={row.hasBeenRead === false ? "border RemoveTextDecoration" : "RemoveTextDecoration"}

                            >
                                <TableCell width='10%'><a className="RemoveHyperLinkDecorations" href={getRedicrectionLink(row)}>{notificationTypeIcon(row.notificationType)}</a> </TableCell>
                                <TableCell width='80%' style={{ 'whiteSpace': 'unset', wordBreak: 'break-word' }}><a className="RemoveHyperLinkDecorations" href={getRedicrectionLink(row)}>{parseMessage(row)}</a></TableCell>
                                <TableCell width='10%'><a className="RemoveHyperLinkDecorations" href={getRedicrectionLink(row)}><QueryBuilderIcon sx={{ pr: 0.5, transform: 'translate(0, 7px)' }} />{formatDate(Date.parse(row.created))}</a></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                            return '' + from + '-' + to + ' ' + Translate.get('Of') + ' ' + count
                        }
                    }
                    component="div"
                    rowsPerPageOptions={[-1]} // -1 fixerar antalet rader per sida
                    count={messages.length}
                    rowsPerPage={10}
                    page={messages.length <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    )
}