import { useState } from "react";
import Translate from "../../utils/Translate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SkillItem from "../skills/SkillItem";
import { formatDate } from "../../utils/dateTimeFormat";
import { StaticNoRowsOverlay } from "../../utils/StyledGridOverlay";

const Comp = ({ rows, externalActorSkillCountsByIntegration, smallScreen }) => {
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fixRow = Object.entries(rows.actorSkillsBySkillGroup).map(
    ([skillGroup, skills]) => ({ skillGroup, skills })
  );
  if (fixRow.length === 0) {
    return <StaticNoRowsOverlay />;
  }
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList centered onChange={handleChange}>
              {fixRow.map((row, index) => (
                <Tab
                  key={index}
                  label={`${row.skillGroup} (${row.skills.length})`}
                  value={index + ""}
                />
              ))}
              {externalActorSkillCountsByIntegration &&
                Object.entries(externalActorSkillCountsByIntegration).map(
                  ([integration, count]) => (
                    <Tab
                      disabled
                      key={integration}
                      label={`${integration} (${count})`}
                      value={integration}
                    />
                  )
                )}
            </TabList>
          </Box>
          <Box>
            {fixRow.map((row, index) => (
              <TabPanel key={index} value={index + ""}>
                <div className="stdFlexCenter skillItems">
                  {row.skills.map((skill, index2) => (
                    <SkillItem
                      key={index2}
                      isFailed={!skill.hasPassed}
                      imageUrl={skill.imageUrl}
                      label={`${skill.shortCode} ${skill.description}`}
                      tooltip={`${Translate.get("GradedBy")}: ${
                        skill.gradedBy
                      }, ${formatDate(skill.gradeDate)}`}
                      gotDoc={skill?.files}
                    />
                  ))}
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span> <span></span>
                </div>
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      </Box>
    </>
  );
};
export default Comp;
