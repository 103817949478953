import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Translate from "../../utils/Translate";
import SkillItem from "../skills/SkillItem";
import SkillItemGrid from "../skills/SkillItemGrid";

export default function SelectActorPermitSkillsDialog({
  availableSkills,
  selectedSkills,
  onClose,
  onSelectedPermitSkillsChanged,
}) {
  const [updatedSelectedSkills, setUpdatedSelectedSkills] = useState([
    ...selectedSkills,
  ]);
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  function handleToggleSkillSelected(skill) {
    const skillIndex = updatedSelectedSkills.findIndex(
      (s) => s.skillId === skill.skillId
    );
    if (skillIndex < 0) {
      setUpdatedSelectedSkills([...updatedSelectedSkills, skill]);
    } else {
      const selectedSkillsCopy = [...updatedSelectedSkills];
      selectedSkillsCopy.splice(skillIndex, 1);
      setUpdatedSelectedSkills(selectedSkillsCopy);
    }
  }

  function handleSaveSkillSelection() {
    onSelectedPermitSkillsChanged(updatedSelectedSkills);
    onClose();
  }

  function isSelected(skill) {
    return updatedSelectedSkills.some((s) => s.skillId === skill.skillId);
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: { sm: "700px", xs: "343px" } } }}
    >
      <DialogTitle>{Translate.get("Competences")}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ margin: "0 0 24px" }}>
            {Translate.get("ChoosePermitCompetences")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SkillItemGrid
              items={availableSkills.map((skill) => (
                <SkillItem
                  isSelected={isSelected(skill)}
                  onClick={() => handleToggleSkillSelected(skill)}
                  isSmall={!isSmallScreenOrGreater}
                  imageUrl={skill.imageUrl}
                  label={`${skill.shortCode} ${skill.description}`}
                />
              ))}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <Button onClick={handleSaveSkillSelection}>
          {Translate.get("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
