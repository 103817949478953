import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import { isMobile } from "is-mobile";
import { AlertDialog } from "../AlertDialog";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../../components/DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogTitle, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CoPresentIcon from "@mui/icons-material/CoPresent";

export default function PreviewCompany({ company, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={false}
      scroll="paper"
      style={{ top: "63px", bottom: "10px" }}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle>
        {Translate.get("TrainingCoordinator")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        {company && (
          <div className="dlcCompanyDialog">
            <div>
              <div>
                <img src={company.logoUrl} className="dlcCompanyLogo" />
              </div>
              <div className="dlcHeading1">{company.companyName}</div>
              <div>{company.companyInfo}</div>
            </div>
            <div>
              {/*  <div className="dlcCompanyLogo"></div> */}
              <div className="iconTextRows">
                <div>
                  <div>
                    <PhoneIcon />
                  </div>
                  <div>{company.phone ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <EmailIcon />
                  </div>
                  <div>{company.emailAddress ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <LanguageIcon />
                  </div>
                  <div>{company.webpage ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <PlaceIcon />
                  </div>
                  <div>
                    {company.addressStreet1 ||
                    company.addressStreet2 ||
                    company.addressZipCode ||
                    company.addressCity ||
                    company.addressCountry ? (
                      <div>
                        <div>{company.addressStreet1}</div>
                        <div>{company.addressStreet2}</div>
                        <div>
                          {company.addressZipCode + " " + company.addressCity}
                        </div>
                        <div>{company.addressCountry}</div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
