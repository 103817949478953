import { Card, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Translate from "../../utils/Translate";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import FileTypeCell, { fileTypeComparator } from "./FileTypeCell";
import download from "downloadjs";
import { useState, useEffect } from "react";
import Api, { useApi } from "../../utils/Api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { useSnackbar } from "notistack";

export default function InstructorMaterialList({ items, title, onFreeSampleOrdered }) {
  const theme = useTheme();
  const mediumScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
    contentType: true,
    version: true,
    lastUpdated: true,
    url: true,
  });

  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (mediumScreenOrSmaller) {
      setColumnVisibility({
        name: true,
        contentType: false,
        version: false,
        lastUpdated: false,
        url: true,
      });
    } else {
      setColumnVisibility({
        name: true,
        contentType: true,
        version: true,
        lastUpdated: true,
        url: true,
      });
    }
  }, [mediumScreenOrSmaller]);

  async function handleDownloadFile({ url, filename, contentType }) {
    const blob = await Api.fetchBlob(url, false, "GET", false, true, false);
    download(blob, filename, contentType);
  }

  async function handleOrderFreeSample({ externalAgreementPartFreeSampleId }) {
    var response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}agreements/instructor-material/free-sample/order`,
      { externalAgreementPartFreeSampleId },
      "POST"
    );

    if (response.isSuccessful) {
      onFreeSampleOrdered(externalAgreementPartFreeSampleId);
      const successMessage = enqueueSnackbar(
        Translate.get("OrderReceivedWillContact"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
    }
  }

  const columns = [
    {
      field: "name",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={InsertDriveFileIcon}
          label={Translate.get("FileName")}
        />
      ),
    },
    {
      field: "contentType",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={FolderIcon}
          label={Translate.get("FileFormat")}
        />
      ),
      renderCell: (props) => <FileTypeCell mimeType={props.row.contentType} />,
      sortComparator: fileTypeComparator,
    },
    {
      field: "version",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={CheckCircleIcon}
          label={Translate.get("Version")}
        />
      ),
    },
    {
      field: "lastUpdated",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={FileUploadIcon}
          label={Translate.get("UploadDate")}
        />
      ),
    },
    {
      field: "url",
      width: 150,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      align: "right",
      renderCell: (props) => (
        <ButtonWithSpinner
          variant="outlined"
          disabled={props.row.isFreeSample && props.row.hasAlreadyBeenOrdered}
          onClick={async () =>
            props.row.isDownloadAllowed
              ? await handleDownloadFile(props.row)
              : props.row.isFreeSample
              ? await handleOrderFreeSample(props.row)
              : window.open(props.row.url, "_blank").focus()
          }
        >
          {props.row.isDownloadAllowed
            ? Translate.get("Download")
            : props.row.isFreeSample
            ? Translate.get("PlaceOrder")
            : Translate.get("Open")}
        </ButtonWithSpinner>
      ),
    },
  ];
  return (
    <>
      <div className="stdFlexRow">
        <h2>{title}</h2>
      </div>
      <Card>
        <DataGrid
          //className="dgDynIconText"
          columnVisibilityModel={columnVisibility}
          autoHeight={true}
          rowHeight={80}
          rowsPerPage={-1}
          hideFooter={true}
          columns={columns}
          rows={items}
          getRowId={(row) => row.name}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay(Translate.get("NoFiles")),
          }}
        />
      </Card>
    </>
  );
}
