import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Translate from "../../../utils/Translate";
import { useApi } from "../../../utils/Api";
import YesOrNoDialog from "../../YesOrNoDialog";
import Preloader from "../../Preloader";
import DuplicateActors from "./DuplicateActors";

export default function DuplicateActorSuggestionsDialog({
  handleSelect,
  closeDialog,
  open,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  // const [toMarkNotDuplicate, setToMarkNotDuplicate] = useState(null);
  const api = useApi();

  const loadDuplicates = useCallback(async () => {
    setIsLoading(true);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/duplicates`,
      false,
      "GET"
    );
    if (response.isSuccessful) {
      setDuplicates(response.duplicates);
    }
    setIsLoading(false);
  }, [api]);

  useEffect(() => {
    loadDuplicates();
  }, [loadDuplicates]);

  async function markAsNotDuplicate(actorId1, actorId2) {
    const request = { actorId1: actorId1, actorId2: actorId2 };
    await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/duplicate/delete`,
      request,
      "POST"
    );
    loadDuplicates();
    // setToMarkNotDuplicate(null);
  }

  const DuplicatesSection = useCallback(({ duplicates, header }) => {
    return (
      duplicates &&
      duplicates.length > 0 && (
        <Box>
          {/* <h2>{header}:</h2> */}
          <Stack direction="column" gap={2}>
            {duplicates.map((d) => (
              <DuplicateActors
                duplicate={d}
                // onMarkAsNotDuplicate={() => setToMarkNotDuplicate(d)}
                onMarkAsNotDuplicate={async () =>
                  await markAsNotDuplicate(d.actor1.actorId, d.actor2.actorId)
                }
                onSelect={async () => {
                  setIsLoading(true);
                  await handleSelect(d);
                  setIsLoading(false);
                  closeDialog();
                }}
              />
            ))}
          </Stack>
        </Box>
      )
    );
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <Dialog
        maxWidth={false}
        className="mediumCourseDialog"
        scroll="paper"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle>{Translate.get("DuplicateSuggestionsTitle")}</DialogTitle>
        <DialogContent>
          <Box>{Translate.get("DuplicateSuggestionsInfo")}</Box>
          <br />
          {duplicates && duplicates.length > 0 && (
            <Box>
              <Stack direction="column" gap={2}>
                {duplicates.map((d) => (
                  <DuplicateActors
                    duplicate={d}
                    // onMarkAsNotDuplicate={() => setToMarkNotDuplicate(d)}
                    onMarkAsNotDuplicate={async () =>
                      await markAsNotDuplicate(
                        d.actor1.actorId,
                        d.actor2.actorId
                      )
                    }
                    onSelect={async () => {
                      setIsLoading(true);
                      await handleSelect(d);
                      setIsLoading(false);
                      closeDialog();
                    }}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{Translate.get("Cancel")}</Button>
        </DialogActions>
      </Dialog>
      {/* <YesOrNoDialog
        open={toMarkNotDuplicate}
        title={Translate.get("MarkAsNotDuplicateTitle")}
        text={Translate.get("MarkAsNotDuplicateBody")}
        onNo={() => setToMarkNotDuplicate(null)}
        onYes={async () =>
          await markAsNotDuplicate(
            toMarkNotDuplicate.actor1.actorId,
            toMarkNotDuplicate.actor2.actorId
          )
        }
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      /> */}
    </>
  );
}
