import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Translate from '../../../utils/Translate';
import { accessKeys, hasAccess } from '../../../utils/userAccess';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { useApi } from '../../../utils/Api';
import { useSnackbar } from 'notistack';

const Comp = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [disabled] = useState(!hasAccess(accessKeys.instructorMaterial));
  const [showDialog, setShowDialog] = useState(false);
  const api = useApi();
  const mailRequext = () => {
    setLoading(true);
    (async () => {
      const indata={licenseType:'Instruktörsmaterial'}
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/order`,
        indata
      );
      setLoading(false);
      if (response.isSuccessful) {
        const successMessage = enqueueSnackbar(
          Translate.get('OrderReceivedWillContact'),
          {
            variant: 'success',
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(successMessage),
          },
        );
      }
      setShowDialog(false);
    })();
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  return (
    <>
      <Dialog
        className="yesOrNoDialog"
        open={showDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get('OrderRequest')}
        </DialogTitle>
        <DialogContent>
          {Translate.get('InstructorMaterialRequest')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {Translate.get('Cancel')}
          </Button>
          <LoadingButton
            className="stdBlueLoader"
            loading={loading}
            onClick={() => mailRequext()}
            variant="contained"
            autoFocus
          >
            {Translate.get('SendRequest')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          title={Translate.get('DownloadInstructorMaterial')}
          avatar={<DownloadIcon />}
        />

        <CardContent sx={{ paddingLeft: '64px' }}>
          <Typography variant="body2" sx={{ marginBottom: '16px' }}>
            {Translate.get('ContentPool')}
          </Typography>
          {disabled ? (
            <Button onClick={() => setShowDialog(true)} variant="outlined">
              {Translate.get('ReadMoreAndOrder')}{' '}
            </Button>
          ) : (
            <Button
              disabled={disabled}
              component={Link}
              to="/dashboard/instructormaterial/"
              variant="outlined"
            >
              {Translate.get('OpenContentPool')}
            </Button>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default Comp;
