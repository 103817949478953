import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import Translate from '../../../utils/Translate';

// ----------------------------------------------------------------------
const setChart = (data) => {
  //Mecka ev med riktiga indatan och få ut värden och datum
  return data;
}


export default function AppConversionRates({data}) {
  const cData=setChart(data);
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true,
    },
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName} deltagare`
        }
      }
    },
    stroke: {
      colors: ["transparent"],
      width: 5
    },
    plotOptions: {
      bar: { horizontal: false, borderRadius: 0,columnWidth: '100%', }
      //bar: { horizontal: false, borderRadius: 2,columnWidth: '50%', }
    },
    labels: cData.labels,
    xaxis: { type: 'datetime' },

  });

  return (
    <Card>
      <CardHeader title={Translate.get('CourseParticipantsMonth')} subheader="" />
      <Box sx={{ mx: 3,mb:2 }} dir="ltr">
        <ReactApexChart type="bar" series={cData.series} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
