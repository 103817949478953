import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import DigitalPermitView from "../../components/digital-licenses/DigitalPermitView";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Translate from "../../utils/Translate";

export default function MyDigitalPermits(props) {
  const { id: machine } = useParams();
  const [permits, setPermits] = useState([]);
  const [allPermits, setAllPermits] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("allItems");
  const [selectedCompany, setSelectedCompany] = useState("allItems");
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadPermits() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}digital/permits`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        
        setAllPermits(response.permits);
        if (machine) {
          setSelectedMachine(machine);
          setFilter(machine, 'machineGroups');
          const filtered = response.permits.filter((x) => x.skillGroupDescription === machine);
          setPermits(filtered);
        }
        else{
          setPermits(response.permits);
        }
      }
    }

    loadPermits();
  }, [api]);
  const setFilter = (filter, target) => {
    let filtered = allPermits;
    if (target === "machineGroups") {
      setSelectedMachine(filter);
      if (filter !== "allItems") {
        filtered = filtered.filter((x) => x.skillGroupDescription === filter);
      }
      if (selectedCompany !== "allItems") {
        filtered = filtered.filter(
          (x) => x.parentActorName === selectedCompany
        );
      }
    }
    if (target === "Companies") {
      setSelectedCompany(filter);
      if (filter !== "allItems") {
        filtered = filtered.filter((x) => x.parentActorName === filter);
      }
      if (selectedMachine !== "allItems") {
        filtered = filtered.filter(
          (x) => x.skillGroupDescription === selectedMachine
        );
      }
    }
    setPermits(filtered);
  };

  return (
    <div className="dlWrapper">
      <div className="dlHeader">{Translate.get("MyPermits")}</div>
        <div className="dlFilter">
        <FormControl sx={{minWidth:200}}>
          <InputLabel>{Translate.get("MachineGroups")}</InputLabel>
          <Select
            value={selectedMachine}
            label={Translate.get("MachineGroups")}
            onChange={(event) => setFilter(event.target.value, "machineGroups")}
          >
            <MenuItem value={"allItems"}>{Translate.get("ShowAll")}</MenuItem>
            {allPermits && [...new Set(allPermits.map(item => item.skillGroupDescription))].map((val,index) => (
              <MenuItem key={index} value={val}>{val}</MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <FormControl sx={{minWidth:200}}>
          <InputLabel>{Translate.get("Company")}</InputLabel>
          <Select
            value={selectedCompany}
            label={Translate.get("Company")}
            onChange={(event) => setFilter(event.target.value, "Companies")}
          >
            <MenuItem value={"allItems"}>{Translate.get("ShowAll")}</MenuItem>
            {allPermits && [...new Set(allPermits.map(item => item.parentActorName))].map((val,index) => (
              <MenuItem key={index} value={val}>{val}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
      {permits &&
        permits.map((permit) => (
          <DigitalPermitView
            key={permit.actorPermitId}
            permit={permit}
            onClick={() => navigate(`/d/permits/${permit.actorPermitId}`)}
          />
        ))}
    </div>
  );
}
