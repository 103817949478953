import { Box, Grid, Card, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PlaceIcon from '@mui/icons-material/Place';
import Translate from "../../../utils/Translate";
import DotMenu from "../../DotMenu";

export default function CourseTypeCard({
  courseShortName,
  noDays,
  maxParticipants,
  validTimeValue,
  validTimeDefinition,
  price,
  extraActions,
}) {
  return (
    <Card
      sx={{ padding: "16px", marginBottom: "16px", transform: "translateZ(0)" }}
    >
      <Typography variant="h6" sx={{ marginTop: "6px", marginLeft: "12px" }}>
        {courseShortName}
      </Typography>
      <Box sx={{ position: "fixed", top: "18px", right: "16px" }}>
        <DotMenu content={extraActions} horizontal />
      </Box>
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{ padding: "30px 0px 16px 18px" }}
      >
        <Grid item container alignItems="center">
          <DateRangeIcon />
          <Box marginLeft="19px">{noDays}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <AccountBoxIcon />
          <Box marginLeft="19px">{maxParticipants}</Box>
        </Grid>
        <Grid item container alignItems="center">
          <PlaceIcon />
          <Box marginLeft="19px">
            {validTimeValue
              ? `${validTimeValue} ${Translate.getValidTimeDefinition(
                  validTimeDefinition
                )}`
              : ""}
          </Box>
        </Grid>
       {/*  {hasAccess(accessKeys.iframe) && (
          <Grid item container alignItems="center">
            <MoneyIcon />
            <Box marginLeft="19px">{formatMoney(price)}</Box>
          </Grid>
        )} */}
      </Grid>
    </Card>
  );
}
