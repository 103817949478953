import { Link } from 'react-router-dom'
import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import Translate from '../../../utils/Translate';

const Comp = ({data}) => {

  return (
    <Card>
      <CardHeader title= {Translate.get('UpgradeEPort')}   avatar={<DevicesIcon />}/>
      
        <CardContent  sx={{paddingLeft:'64px'}}>
        <Typography variant="body2"  sx={{marginBottom:'16px'}}>
        {Translate.get('UpgradeFunctions')} 
        
          </Typography>
          <Button component={Link} to="/dashboard/flyer/" variant="outlined"> {Translate.get('ReadMoreAndOrder')}  </Button>
         </CardContent>
         
    </Card>
  );
}
export default Comp;
