import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import Preloader from "../../Preloader";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import SkillItem from "../../skills/SkillItem";
import SkillItemGrid from "../../skills/SkillItemGrid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function LicenseSkillsDialog({ courseTemplateId, onClose }) {
  const [isLoadingCount, setIsLoadingCount] = useState(2);
  const [allSkillGroups, setAllSkillGroups] = useState([]);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [currentTabValue, setCurrentTabValue] = useState(null);
  const api = useApi();

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}skillgroups?includeImageUrls=true`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          const skillGroups = Object.fromEntries(
            data.map((skillGroup) => [
              skillGroup.description,
              {
                skills: skillGroup.skills,
                skillGroupId: skillGroup.skillGroupId,
              },
            ])
          );
          setAllSkillGroups(skillGroups);
          setCurrentTabValue(Object.keys(skillGroups)[0]);
        }
        setIsLoadingCount((prevCount) => prevCount - 1);
      });

    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/skills/`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          setAvailableSkills(data.skills ?? []);
        }
        setIsLoadingCount((prevCount) => prevCount - 1);
      });
  }, [api, courseTemplateId]);

  async function postUpdatedSkillState(skillId, include) {
    const dto = {
      skills: [{ skillId, include }],
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/license/skills/`,
      dto,
      "POST"
    );
  }

  const handleTabSelected = (event, newTabIndex) => {
    setCurrentTabValue(newTabIndex);
  };

  function handleToggleSkill(skillGroupName, skillId) {
    const index = availableSkills.findIndex(
      (skill) => skill.skillId === skillId
    );

    if (index !== -1) {
      setAvailableSkills((prev) => prev.toSpliced(index, 1));
      postUpdatedSkillState(skillId, false);
    } else {
      const addedSkill = allSkillGroups[skillGroupName].skills.find(
        (s) => s.skillId === skillId
      );
      setAvailableSkills((prev) => [...prev, { ...addedSkill }]);
      postUpdatedSkillState(skillId, true);
    }
  }

  function getAvailableSkillsInGroup(skillGroupId) {
    return availableSkills.filter(
      (skill) => skill.skillGroupId === skillGroupId
    );
  }

  return (
    <>
      {isLoadingCount > 0 && <Preloader />}
      {isLoadingCount <= 0 && (
        <Dialog
          open={true}
          onClose={onClose}
          PaperProps={{ sx: { maxWidth: { sm: "700px", xs: "343px" } } }}
        >
          <DialogTitle>{Translate.get("SelectSkills")}</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Box>
              <Typography
                sx={{
                  margin: "0 0 24px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                }}
              >
                {Translate.get("AddDeleteCourseTemplateSkills")}
              </Typography>
              <Box sx={{ width: "100%" }}>
                <TabContext value={currentTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleTabSelected} centered>
                      {Object.entries(allSkillGroups).map(
                        ([skillGroupName, { skillGroupId }], idx) => (
                          <Tab
                            label={`${skillGroupName} (${
                              getAvailableSkillsInGroup(skillGroupId).length
                            })`}
                            value={skillGroupName}
                            key={idx}
                          />
                        )
                      )}
                    </TabList>
                  </Box>
                  {Object.entries(allSkillGroups).map(
                    ([skillGroupName, { skills }], idx) => (
                      <TabPanel
                        value={skillGroupName}
                        key={idx}
                        sx={{
                          height: "440px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "20px",
                          }}
                        >
                          <SkillItemGrid
                            items={skills.map((skill) => (
                              <SkillItem
                                onClick={() =>
                                  handleToggleSkill(
                                    skillGroupName,
                                    skill.skillId
                                  )
                                }
                                isSelected={availableSkills.some(
                                  (s) => s.skillId === skill.skillId
                                )}
                                isSmall={!isBigScreen}
                                imageUrl={skill.imageUrl}
                                label={`${skill.shortCode} ${skill.description}`}
                              />
                            ))}
                          />
                        </Box>
                      </TabPanel>
                    )
                  )}
                </TabContext>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Close")}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
