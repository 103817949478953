import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import Translate from "./Translate";
import * as Sentry from "@sentry/react";

const Api = {};
/* *
 * Fetch wrapper - sessionStorage.apiToken can be set globaly
 * @param {string} url - url to endpoint.
 * @param {{}} [data=false] - request js or FormData object.
 * @param {string} [method=POST] - GET, POST, PUT, DELETE, etc.
 * @param {string} [token=false] - headers.Authorization overrides sessionStorage.apiToken.
 * @param {string} [json=true] - response true/false json/text.
 * @param {string} [canRefreshToken=true] - canRefreshToken or on false: return {waitRefresh:true}.
 * @returns json/text
 * */
Api.fetch = function apiFetch(
  url,
  inData = false,
  method = 'POST',
  token = false,
  json = true,
  canRefreshToken = true,
  includeCredentials = false
) {

  const fs = {
    /* method: 'POST', // *GET, POST, PUT, DELETE, etc.
                     mode: 'cors', // no-cors, *cors, same-origin
                     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                     credentials: 'same-origin', // include, *same-origin, omit
                     headers: {
                     'Content-Type': 'application/json'
                     // 'Content-Type': 'application/x-www-form-urlencoded',
                     },
                     redirect: 'follow', // manual, *follow, error
                     referrer: 'no-referrer', // no-referrer, *client
                     body: JSON.stringify(data) // body data type must match "Content-Type" header */
  };
  const headers = {};
  let data = inData;
  if (data instanceof FormData) {
    // headers['Content-Type'] = "application/x-www-form-urlencoded";
  } else if (data) {
    headers['Content-Type'] = 'application/json';
    //data = JSON.stringify(data);
    //Allways trim incoming json
    data = JSON.stringify(data, (_, value) =>
      typeof value === 'string' ? value.trim() : value,
    ); // trim any string fields: value);
  }
  let sendMethod = 'POST';
  if (method) {
    sendMethod = method;
  }
  if (token !== null) {
    if (token) {
      if (!token.startsWith("Bearer")) {
        headers.Authorization = "Bearer " + token;
      } else {
        headers.Authorization = token;
      }

    } else if (sessionStorage.apiToken) {
      headers.Authorization = sessionStorage.apiToken;
    }
  }
  //fs.cache = "no-store"; // no-store reload no-cache  Cacha aldrig request,no-cache brukar räcka.
  //Funkar inte i stage (live) live miljö, querystring fix i bookings.js istället
  if (data) {
    fs.method = sendMethod;
    fs.headers = headers;
    fs.body = data;
  } else {
    fs.headers = headers;
  }

  // anväds när man har en token som sparats i cookie (permit sign)
  if (includeCredentials) {
    fs.credentials = "include";
  }

  return fetch(url, fs)
    .then(refreshToken)
    .then(networkError)
    .then(
      json
        ? (response) => {
          if (response.status === 204) {
            // bodyn är tom - gör inte .json() eftersom det krashar
            return Promise.resolve(null);
          }
          if (response.status === 401) {
            // If a token is rejected by auth in backend there will be no content,
            // unlike when we ourselves return 401 from the code in backend.
            const contentType = response.headers.get("content-type");
            const isJson = contentType?.includes("application/json");
            if (!isJson) {
              return Promise.resolve({
                isSuccessful: false,
                errorMessageTranslationKey: 'SomethingFailed',
                status: 401
              });
            }
          }
          if (response.status === 403) {
            return Promise.resolve({
              isSuccessful: false,
              errorMessageTranslationKey: 'UserAccessForbiddden',
              status: 403
            });
          }
          return response.json();
        }
        : (response) => {
          return response.text();
        },
    )
    .catch((response) => onError(response));

  async function refreshToken(response) {
    if (window.waitForToken) {
      //console.log('-------------- waitForToken --------------');
    }
    if (response.status === 401 && sessionStorage.apiRefreshToken) {
      if (inData.refreshToken) {
        logOut();
        throw Error('loginError');
      }
      if (canRefreshToken !== true) {
        throw Error('waitRefresh');
      }

      const rToken = {};
      rToken.refreshToken = {};
      rToken.refreshToken.token = sessionStorage.apiRefreshToken;
      window.waitForToken = true;

      await Api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/relogin/courseadmin`,
        rToken,
      ).then((tokenData) => {
        if (tokenData.identityToken) {
          //Logout mess -  see DashboardTimer.js
          sessionStorage.setItem('lastConnection', Date.now());
          ////console.log('Timer reset', Date.now());
          //---------------------------------------
          sessionStorage.setItem('apiRefreshToken', rToken.refreshToken.token);
          sessionStorage.setItem(
            'apiToken',
            `Bearer ${tokenData.identityToken.token}`,
          );
          sessionStorage.setItem(
            'apiTokenExpires',
            tokenData.identityToken.expires,
          );
          window.waitForToken = false;
          throw Error('refresh');
        } else {
          logOut();
          window.waitForToken = false;
          throw Error('loginError');
        }
      });
    } else {
      //if (canRefreshToken === true) {
      ////console.log('--------------------New timer', Date.now());
      //window.lastConnection = Date.now();
      //}
      return response;
    }
  }

  function networkError(response) {
    if (response.status === 401) {
      logOut();
      return response;
    } else if (response.status === 403) {
      return response;
    } else if (!response.ok) {
      throw Error(response.status, { cause: response.statusText });
    } else {
      return response;
    }
  }

  function onError(e) {
    //console.log('-- Error --', e);
    if (e.message === 'refresh') {
      return Api.fetch(url, inData, method, token, json);
    }
    if (e.message === 'loginError') {
      window.location = '/login';
      const eError = {};
      eError.error = e.message;
      return eError;
    }
    if (e.message === 'waitRefresh') {
      const mess = {
        waitRefresh: true,
      };
      return mess;
    }

    if (e.message === '500') {
      //console.log('500 error', e.message, e.cause);
      if ((e.cause = 'Internal Server Error')) {
        e.cause = 'SomethingFailed';
      }
      const mess = {
        isSuccessful: false,
        errorMessageTranslationKey: e.cause,
      };
      return mess;
    }

    //TODO Tillfälligtvis körs ingen 404 redirect, det kanske vi inte heller ska göra om inte route är felaktig
    /*  const eError = {};
     eError.error = e.message;
     window.location = '/404';
     return eError; */
    const mess = {
      isSuccessful: false,
      errorMessageTranslationKey: 'SomethingFailed',
    };
    //console.log('Some kind of bad response but not 500,401 or any 200-299');
    return mess;
  }

  function logOut() {
    sessionStorage.clear();
    // sessionStorage.removeItem('apiRefreshToken');
    // sessionStorage.removeItem('apiToken');
    // sessionStorage.removeItem('apiTokenExpires');
  }
};

// TODO: merge with above function
Api.fetchBlob = function apiFetchBlob(
  url,
  inData = false,
  method = 'POST',
  token = false,
  canRefreshToken = true,
  useApiToken = true,
) {
  ////console.log('api.fetch', inData);
  const fs = {
    /* method: 'POST', // *GET, POST, PUT, DELETE, etc.
                     mode: 'cors', // no-cors, *cors, same-origin
                     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                     credentials: 'same-origin', // include, *same-origin, omit
                     headers: {
                     'Content-Type': 'application/json'
                     // 'Content-Type': 'application/x-www-form-urlencoded',
                     },
                     redirect: 'follow', // manual, *follow, error
                     referrer: 'no-referrer', // no-referrer, *client
                     body: JSON.stringify(data) // body data type must match "Content-Type" header */
  };
  const headers = {};
  let data = inData;
  if (data instanceof FormData) {
    // headers['Content-Type'] = "application/x-www-form-urlencoded";
  } else if (data) {
    headers['Content-Type'] = 'application/json';
    data = JSON.stringify(data);
  }
  let sendMethod = 'POST';
  if (method) {
    sendMethod = method;
  }
  if (useApiToken) {
    if (token) {
      headers.Authorization = token;
    } else if (sessionStorage.apiToken) {
      headers.Authorization = sessionStorage.apiToken;
      ////console.log(sessionStorage.apiToken);
    }
  }
  if (data) {
    fs.method = sendMethod;
    fs.headers = headers;
    fs.body = data;
  } else {
    fs.headers = headers;
  }
  return fetch(url, fs)
    .then(refreshToken)
    .then(networkError)
    .then((response) => response.blob())
    .catch((response) => onError(response));

  async function refreshToken(response) {
    if (window.waitForToken) {
      //console.log('----------------waitForToken--------------');
    }
    if (response.status === 401 && sessionStorage.apiRefreshToken) {
      if (canRefreshToken !== true) {
        sessionStorage.removeItem('apiRefreshToken');
        sessionStorage.removeItem('apiToken');
        sessionStorage.removeItem('apiTokenExpires');
        throw Error('loginError');
      }

      const rToken = {};
      rToken.refreshToken = {};
      rToken.refreshToken.token = sessionStorage.apiRefreshToken;
      //sessionStorage.removeItem('apiRefreshToken'); //Här var det som fick allt att gå sönder
      window.waitForToken = true;

      await Api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/relogin/courseadmin/`,
        rToken,
      ).then((tokenData) => {
        //console.log('Token Raw token data', tokenData);
        if (tokenData.identityToken) {
          /*   console.log(
              'ISEQUAL',
              tokenData.identityToken.token === sessionStorage.apiToken,
            ); */
          sessionStorage.setItem('apiRefreshToken', rToken.refreshToken.token);
          sessionStorage.setItem(
            'apiToken',
            `Bearer ${tokenData.identityToken.token}`,
          );
          sessionStorage.setItem(
            'apiTokenExpires',
            tokenData.identityToken.expires,
          );
          //console.log('New token loaded');
          window.waitForToken = false;
          throw Error('refresh');
        } else {
          sessionStorage.removeItem('apiRefreshToken');
          sessionStorage.removeItem('apiToken');
          sessionStorage.removeItem('apiTokenExpires');
          //console.log('401 error not renew', response.status);
          window.waitForToken = false;
          throw Error('loginError');
        }
      });
    } else {
      return response;
    }
  }

  function networkError(response) {
    if (response.status === 401) {
      //console.log('Remove sessions storage', response.status);
      sessionStorage.removeItem('apiRefreshToken'); ///HÄR ÄR JAG!
      sessionStorage.removeItem('apiToken');
      sessionStorage.removeItem('apiTokenExpires');
      return response;
    } else if (!response.ok) {
      //console.log('networkError', response.status);
      throw Error(response.status);
      // throw Error('refresh');
    } else {
      //console.log(response);
      return response;
    }
  }

  function onError(e) {
    //console.log('-- Error --', e);
    // //console.log('onError Load error:', e.message, e.name);
    if (e.message === 'refresh') {
      /*const tokenError = {};
      tokenError.error = 'refresh';
      window.location.reload(false);
      return tokenError;*/
      //Testar utan reload av route
      return Api.fetchBlob(url, inData, method, token);
    }
    if (e.message === 'loginError') {
      window.location = '/login';
      const eError = {};
      eError.error = e.message;
      return eError;
    }
    const eError = {};
    eError.error = e.message;
    window.location = '/404';
    return eError;
  }
};
export default Api;

// Creates a version Api that shows snackbar messages for unauthorized, forbidden and failed by default.
// It's possible to override what happens in each of these cases (unauthorized, forbidden, failed)
// by providing arguments to the api.fetch call on the api received as a response from the useApi hook.
// If the override is a string, then this is the default message that will be displayed in the snackbar.
// If the override is a function, that function will be executed instead of showing a snackbar.
export function useApi() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getMessage = useCallback(
    (responseData, defaultMessageKey) =>
      Translate.get(
        responseData?.errorMessageTranslationKey ?? defaultMessageKey
      ),
    []
  );

  const showSnackbarMessage = useCallback(
    (message, variant) => {
      const errorMessage = enqueueSnackbar(message, {
        variant: variant,
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const callbackOrSnackbarMessage = useCallback(
    (responseData, defaultMessageKey, messageVariant, callback = null) => {
      if (callback) {
        callback(responseData);
      } else {
        const message = getMessage(responseData, defaultMessageKey);
        showSnackbarMessage(message, messageVariant);
      }
    },
    [showSnackbarMessage, getMessage]
  );

  const handleResponseData = useCallback(
    (
      url,
      method,
      requestData,
      responseData,
      checkIfResponseIsOk = null,
      onFailedOverride = null,
      onUnauthorizedOverride = null,
      onForbiddenOverride = null
    ) => {
      const isString = (something) => typeof something === 'string' || something instanceof String;
      const isFailed = checkIfResponseIsOk
        ? !checkIfResponseIsOk(responseData)
        : responseData &&
        typeof responseData === 'object' &&
        !Array.isArray(responseData) &&
        "isSuccessful" in responseData &&
        !responseData.isSuccessful;
      if (isFailed) {
        // //console.log("IS FAILED:", responseData, typeof responseData === 'object', !Array.isArray(responseData), !responseData.isSuccessful);
        let callback;
        let defaultMessageKey;
        let messageVariant;
        switch (responseData.status) {
          case 401:
            const isStringOverride1 = isString(onUnauthorizedOverride);
            callback = isStringOverride1 ? null : onUnauthorizedOverride;
            defaultMessageKey = isStringOverride1 ? onUnauthorizedOverride : "SomethingFailed";
            messageVariant = "error";
            break;
          case 403:
            const isStringOverride2 = isString(onForbiddenOverride);
            callback = isStringOverride2 ? null : onForbiddenOverride;
            defaultMessageKey = isStringOverride2 ? onForbiddenOverride : "UserAccessForbiddden";
            messageVariant = "warning";
            break;
          default:
            const isStringOverride3 = isString(onFailedOverride);
            callback = isStringOverride3 ? null : onFailedOverride;
            defaultMessageKey = isStringOverride3 ? onFailedOverride : "SomethingFailed";
            messageVariant = "error";
            // Log to Sentry
            const skipLoggingRequest = url.includes("auth");
            const logMessage = `Api.js: ${getMessage(responseData, defaultMessageKey)} (Url: ${url}. Method: ${method}. Status: ${responseData.status}. Request: ${skipLoggingRequest ? "***" : JSON.stringify(requestData)})`;
            Sentry.captureMessage(logMessage, { level: "error" });           
            break;
        }
        callbackOrSnackbarMessage(
          responseData,
          defaultMessageKey,
          messageVariant,
          callback
        );
      }
      return responseData;
    },
    [callbackOrSnackbarMessage]
  );

  const apiFetchWithSnackbar = useCallback(
    (
      url,
      inData = false,
      method = "POST",
      token = false,
      json = true,
      canRefreshToken = true,
      includeCredentials = false,
    ) =>
      Api.fetch(url, inData, method, token, json, canRefreshToken, includeCredentials).then(
        (responseData) =>
          handleResponseData(
            url,
            method,
            inData,
            responseData,
          )
      ),
    [handleResponseData]
  );

  const apiFetchWithSnackbarAndOverride = useCallback(
    (
      url,
      inData = false,
      method = "POST",
      checkIfResponseIsOk = null,
      onFailedOverride = null,
      onUnauthorizedOverride = null,
      onForbiddenOverride = null,
      token = false,
      json = true,
      canRefreshToken = true,
      includeCredentials = false,
    ) =>
      Api.fetch(url, inData, method, token, json, canRefreshToken, includeCredentials).then(
        (responseData) =>
          handleResponseData(
            url,
            method,
            inData,
            responseData,
            checkIfResponseIsOk,
            onFailedOverride,
            onUnauthorizedOverride,
            onForbiddenOverride
          )
      ),
    [handleResponseData]
  );

  const [api] = useState({
    ...Api,
    fetch: apiFetchWithSnackbar,
    fetchWithOverride: apiFetchWithSnackbarAndOverride
  });
  return api;
}
