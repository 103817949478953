import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Preloader from "../Preloader";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import SkillItem from "../skills/SkillItem";
import SkillItemGrid from "../skills/SkillItemGrid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ClearIcon from "@mui/icons-material/Clear";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import YesOrNoDialog from "../YesOrNoDialog";
import EventIcon from '@mui/icons-material/Event';
import Dialog from '@mui/material/Dialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function EditCompetencesDialog({ actorId }) {
  const [isLoadingCount, setIsLoadingCount] = useState(2);
  const [allSkillGroups, setAllSkillGroups] = useState([]);
  const [actorSkillGroups, setActorSkillGroups] = useState({});
  const [currentTabValue, setCurrentTabValue] = useState(null);
  const [actorSkillToDelete, setActorSkillToDelete] = useState(null);

  const [updateActorSkillGradeDate, setUpdateActorSkillGradeDate] = useState(null);
  const [datePickValue, setDatePickValue] = useState(new Date());

  const api = useApi();

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  const loadActorSkills = useCallback(() => {
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/skills/grouped`,
      false,
      "GET"
    ).then((data) => {
      if (data) {
        setActorSkillGroups(data.actorSkillsBySkillGroup);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
    });
  }, [actorId]);

  useEffect(() => {
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}skillgroups?includeImageUrls=true`,
      false,
      "GET"
    ).then((data) => {
      if (data) {
        const skillGroups = Object.fromEntries(
          data.map((skillGroup) => [skillGroup.description, skillGroup.skills])
        );
        setAllSkillGroups(skillGroups);
        setCurrentTabValue(Object.keys(skillGroups)[0]);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
    });

    loadActorSkills();
  }, [loadActorSkills]);

  const handleTabSelected = (event, newTabIndex) => {
    setCurrentTabValue(newTabIndex);
  };

  function updateLocalActorSkill(skillGroupName, actorSkill) {
    const actorSkillGroupsCopy = { ...actorSkillGroups };
    const actorSkillsListCopy = !!actorSkillGroups[skillGroupName]
      ? actorSkillGroups[skillGroupName].filter(
        (skill) => skill.skillId !== actorSkill.skillId
      )
      : [];
    actorSkillsListCopy.push(actorSkill);
    actorSkillGroupsCopy[skillGroupName] = actorSkillsListCopy;
    setActorSkillGroups(actorSkillGroupsCopy);
  }

  function postNewActorSkill(skillGroupName, actorSkill) {
    const dto = [actorSkill];
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/skills`,
      dto,
      "POST"
    ).then((data) => {
      if (data && data.skills && data.skills.length > 0) {
        const addedSkill = data.skills[0];
        updateLocalActorSkill(skillGroupName, addedSkill);
      }
    });
  }

  function postDeleteActorSkill(actorSkill) {
    const dto = [actorSkill.actorSkillId];
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actorskills/delete/soft`,
      dto,
      "POST"
    ).then((_) => {
      // Reloading all skills to avoid having to create a new endpoint.
      // This won't happen often anyway, since deleting grades isn't very common.
      // The reload is needed because the grade being deleted is only the latest,
      // and the student may have earlier grades on the same skill.
      loadActorSkills();
    });
  }

  function handleGradeStudent(skillGroupName, skillId, hasPassed) {
    const existingActorSkill = actorSkillGroups[skillGroupName]?.find(
      (skill) => skill.skillId === skillId
    );

    // Only do something if the actor doesn't already have the same grade
    if (!existingActorSkill || existingActorSkill.hasPassed !== hasPassed) {
      const actorSkill = existingActorSkill
        ? { ...existingActorSkill, hasPassed }
        : { skillId, hasPassed };

      // The following post will update skill locally as well,
      // but doing it here too just in case of network delays
      updateLocalActorSkill(skillGroupName, actorSkill);
      postNewActorSkill(skillGroupName, actorSkill);
    }
  }

  function handleDeleteActorSkill(skillGroupName, skillId) {
    const existingActorSkill = actorSkillGroups[skillGroupName]?.find(
      (skill) => skill.skillId === skillId && skill.actorSkillId > 0
    );

    if (existingActorSkill) {
      postDeleteActorSkill(existingActorSkill);
      setActorSkillToDelete(null);
    }
  }

  const getDotMenuActions = (skillGroupName, skillId) => {
    const actorSkill =
      actorSkillGroups[skillGroupName] &&
      actorSkillGroups[skillGroupName].find(
        (s) => s.skillId === skillId && s.actorSkillId > 0
      );
    const hasActorSkill = !!actorSkill;
    const hasFailedGrade = hasActorSkill && !actorSkill.hasPassed;

    const menudata = [
      {
        icon: <ClearIcon />,
        text: Translate.get("DeleteLatestGrade"),
        disabled: !hasActorSkill,
        onClick: () => setActorSkillToDelete({ skillGroupName, skillId }),
      },
      {
        icon: <EventIcon />,
        text: Translate.get("UpdateGradeDate"),
        disabled: !hasActorSkill,
        onClick: () => {
          setUpdateActorSkillGradeDate({ "actorSkillId": actorSkill.actorSkillId, gradeDate: actorSkill.gradeDate, "skillGroupName": skillGroupName },
            setDatePickValue(actorSkill.gradeDate)
          )
        }
      },
      {
        icon: <DoNotDisturbIcon />,
        text: Translate.get("GradeFailed"),
        disabled: hasFailedGrade,
        onClick: () => handleGradeStudent(skillGroupName, skillId, false),
      },
    ];
    return menudata;
  };

  function studentHasFailedGrade(skillGroupName, skillId) {
    return (
      actorSkillGroups[skillGroupName] &&
      actorSkillGroups[skillGroupName].some(
        (s) => s.skillId === skillId && !s.hasPassed
      )
    );
  }

  function studentHasGrade(skillGroupName, skillId) {
    return (
      actorSkillGroups[skillGroupName] &&
      actorSkillGroups[skillGroupName].some((s) => s.skillId === skillId)
    );
  }

  function postUpdateGradeDate() {
    var req = {
      "actorSkillId": updateActorSkillGradeDate.actorSkillId,
      "gradeDate": datePickValue
    }

    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/gradedate`,
      req,
      "POST"
    ).then((data) => {
      if (data.isSuccessful) {

        const existingActorSkill = actorSkillGroups[updateActorSkillGradeDate.skillGroupName]?.find(
          (skill) => skill.actorSkillId === updateActorSkillGradeDate.actorSkillId
        );
        existingActorSkill.gradeDate = datePickValue
        updateLocalActorSkill(updateActorSkillGradeDate.skillGroupName, existingActorSkill);
        setUpdateActorSkillGradeDate(null)
      }
    });

  }

  return (
    <>
      <YesOrNoDialog
        open={!!actorSkillToDelete}
        title={Translate.get("DeleteLatestGradeTitle")}
        text={Translate.get("DeleteLatestGradeQuestion")}
        onNo={() => setActorSkillToDelete(null)}
        onYes={() =>
          handleDeleteActorSkill(
            actorSkillToDelete.skillGroupName,
            actorSkillToDelete.skillId
          )
        }
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Delete")}
      />

      <>
        <Dialog
          open={!!updateActorSkillGradeDate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{Translate.get("UpdateGradeDate")}</DialogTitle>
          <DialogContent>

            <DatePicker
              label={Translate.get('GradeDate')}
              value={datePickValue}
              onChange={(newValue) => {
                setDatePickValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  required
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: Translate.get('DatePlaceholder'),
                  }}
                />
              )}
            />


          </DialogContent>
          <DialogActions>
            <Button
              autofocus
              onClick={() => setUpdateActorSkillGradeDate(null)}
            >
              {Translate.get("Cancel")}
            </Button>
            <Button
              onClick={() => postUpdateGradeDate()}
            >
              {Translate.get("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </>



      {isLoadingCount > 0 && <Preloader />}
      {isLoadingCount <= 0 && (
        <>
          <DialogTitle>{Translate.get("EditSkills")}</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Box>
              <Typography
                sx={{
                  margin: "0 0 24px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                }}
              >
                {Translate.get("AddDeleteSkills")}
              </Typography>
              <Box sx={{ width: "100%" }}>
                <TabContext value={currentTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleTabSelected} centered>
                      {Object.keys(allSkillGroups).map(
                        (skillGroupName, idx) => (
                          <Tab
                            label={skillGroupName}
                            value={skillGroupName}
                            key={idx}
                          />
                        )
                      )}
                    </TabList>
                  </Box>
                  {Object.entries(allSkillGroups).map(
                    ([skillGroupName, skills], idx) => (
                      <TabPanel
                        value={skillGroupName}
                        key={idx}
                        sx={{ height: "440px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "20px",
                          }}
                        >
                          <SkillItemGrid
                            items={skills.map((skill) => (
                              <SkillItem
                                onClick={() =>
                                  handleGradeStudent(
                                    skillGroupName,
                                    skill.skillId,
                                    true
                                  )
                                }
                                isSelected={studentHasGrade(
                                  skillGroupName,
                                  skill.skillId
                                )}
                                isFailed={studentHasFailedGrade(
                                  skillGroupName,
                                  skill.skillId
                                )}
                                isSmall={!isSmallScreenOrGreater}
                                imageUrl={skill.imageUrl}
                                label={`${skill.shortCode} ${skill.description}`}
                                extraActions={getDotMenuActions(
                                  skillGroupName,
                                  skill.skillId
                                )}
                              />
                            ))}
                          />
                        </Box>
                      </TabPanel>
                    )
                  )}
                </TabContext>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAndReload}>{Translate.get("Close")}</Button>
          </DialogActions>
        </>
      )}
    </>
  );
}
