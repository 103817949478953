import WarningIcon from "@mui/icons-material/Warning";
import { Stack } from "@mui/material";

const ErrorMessage = ({ error, onClick }) =>
    <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        className="warningColor"
        sx={{cursor: onClick && "pointer", padding:".2rem"}}
        onClick={() => onClick && onClick()}
    >
        <WarningIcon /><div>{error}</div>
    </Stack>;

export default ErrorMessage;