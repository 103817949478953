import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ActorLicensePreview from "./ActorLicensePreview";
import "./ActorLicensePrint.css";
import { licenseFormatTypeEnum } from "../../utils/licenseFormatTypes";
import DigitalLicenseView from "../digital-licenses/DigitalLicenseView";
import { accessKeys, hasAccess } from "../../utils/userAccess";

export default function ActorLicensePreviewDialog({
  printTemplate,
  studentDetails,
  printFront,
  printBack,
  open,
  onClose,
  printType,
  licenseFormatType,
  digitalLicensePreview,
}) {
  const showActorLicense =
    licenseFormatType === licenseFormatTypeEnum.physical ||
    licenseFormatType === licenseFormatTypeEnum.both;
  const showDigitalLicense =
    printType === "card" &&
    (licenseFormatType === licenseFormatTypeEnum.digital ||
      licenseFormatType === licenseFormatTypeEnum.both);
  console.log("preview", showDigitalLicense, digitalLicensePreview);
  return (
    <Dialog maxWidth={false} scroll="paper" onClose={onClose} open={open}>
      <DialogTitle>{Translate.get("PreeView")}</DialogTitle>
      <DialogContent>
        {open &&
          digitalLicensePreview &&
          showDigitalLicense &&
          hasAccess(accessKeys.digitalLicenses) && (
            <DigitalLicenseView
              displayData={digitalLicensePreview.displayData}
              company={digitalLicensePreview.company ?? {}}
              skills={digitalLicensePreview.skills ?? []}
              otherSkills={digitalLicensePreview.otherSkills ?? []}
              animate={true}
              allowSwiper={false}
              showExtraInfo={true}
              isSmallCard={false}
            />
          )}
        {open && studentDetails && showActorLicense && (
          <ActorLicensePreview
            printTemplate={printTemplate}
            cardDetails={studentDetails}
            isSelected={true}
            onSelectedChanged={() => {}}
            showFront={printFront}
            showBack={printBack}
            printType={printType}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
