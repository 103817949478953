import { useState } from 'react';
import Translate from '../../utils/Translate';
import { useApi } from '../../utils/Api';
import { useSnackbar } from 'notistack';
import { Button} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
const RemoveClassRoom = (props) => {
    //console.log(props)
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const closeAndReload = () => {
      const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
      const loc = encodeURIComponent(
        `${location.pathname}${location.search}${hash}`
      );
      navigate(`/redirect/${loc}`, { replace: true });
    };
    const submit = (actorAddressId) => {
      setSubmitting(true);
      const outData={};
      //return;
      (async () => {
        const ret = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/courselocations/delete/${actorAddressId}`,
          outData,'POST'
        );
        if (ret.isSuccessful) {
          setSubmitting(false);
          enqueueSnackbar(Translate.get('ClassRoomRemoved'), {
            variant: 'success',
            autoHideDuration: 3000,
          });
          closeAndReload();
        } else {
          setSubmitting(false);
  
        }
  
      })();
    }
    return (<>
     <DialogContent dividers={true} className="stdDialogContent">
     Vill du ta bort denna kurslokal? Alla eventuellt tidigare bokningar kommer att behålla de tidigare inställningarna för kurslokalen men döljas för dig.

     </DialogContent>
     <DialogActions>
          <Button onClick={() => window.history.back()}>
            {Translate.get('Cancel')}
          </Button>
          <LoadingButton
                 className="stdLightLoader"
                 loading={submitting}
                 onClick={() => submit(props.actorAddressId)}
                >
            {Translate.get('Delete')}
          </LoadingButton>
        </DialogActions>

    
    </>)
};
export default RemoveClassRoom;