import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ActorLicensePreview from "./ActorLicensePreview";
import "./ActorLicensePrint.css";
import { useApi } from "../../utils/Api";
import { useEffect } from "react";
import { useState } from "react";
import Preloader from "../Preloader";
import { useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonWithSpinner from "../ButtonWithSpinner";
import PrintTemplateTextBoxEditor from "./PrintTemplateTextBoxEditor";
import ImageIcon from "@mui/icons-material/Image";
import AddIcon from "@mui/icons-material/Add";
import { compareDates } from "../../utils/dateTimeFormat";

export default function AddTextBoxDialog({
  alreadyAddedTextBoxes,
  availableTextBoxes,
  onTextBoxAdded,
  onClose,
}) {
  const [selectedTextBox, setSelectedTextBox] = useState(null);

  function isAlreadyAdded(textBoxName) {
    return alreadyAddedTextBoxes.some((tb) => tb.name === textBoxName);
  }

  function groupAndSortAvailableTextBoxes(availableTextBoxes) {
    const textBoxes = Object.values(availableTextBoxes);

    const lastUsedTextBoxes = textBoxes.filter((tb) => !!tb.lastUsed);
    lastUsedTextBoxes
      .sort((tb1, tb2) => tb1.lastUsed.localeCompare(tb2.lastUsed))
      .reverse();

    const restTextBoxes = textBoxes.toSorted((tb1, tb2) =>
      tb1.displayName.localeCompare(tb2.displayName)
    );

    if (lastUsedTextBoxes.length > 0) {
      return [
        <ListSubheader>{Translate.get("Latest")}</ListSubheader>,
        lastUsedTextBoxes.map((tb) => (
          <MenuItem
            disabled={!!isAlreadyAdded(tb.name)}
            value={tb.name}
            key={tb.name}
          >
            {tb.displayName}
          </MenuItem>
        )),
        <ListSubheader>{Translate.get("AllLabel")}</ListSubheader>,
        restTextBoxes.map((tb) => (
          <MenuItem
            disabled={!!isAlreadyAdded(tb.name)}
            value={tb.name}
            key={tb.name}
          >
            {tb.displayName}
          </MenuItem>
        )),
      ];
    } else {
      return restTextBoxes.map((tb) => (
        <MenuItem
          disabled={!!isAlreadyAdded(tb.name)}
          value={tb.name}
          key={tb.name}
        >
          {tb.displayName}
        </MenuItem>
      ));
    }
  }

  return (
    <Dialog scroll="paper" onClose={onClose} open={true}>
      <DialogTitle>{Translate.get("AddTextField")}</DialogTitle>
      <DialogContent>
        <br />
        <FormControl fullWidth>
          <InputLabel id="add-textbox-select-label">
            {Translate.get("TextField")}
          </InputLabel>
          <Select
            fullWidth
            labelId="add-textbox-select-label"
            id="add-textbox-select"
            label={Translate.get("TextField")}
            value={selectedTextBox ?? ""}
            onChange={(ev) => setSelectedTextBox(ev.target.value)}
            sx={{ width: "400px" }}
          >
            {availableTextBoxes &&
              groupAndSortAvailableTextBoxes(availableTextBoxes)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <Button
          disabled={!selectedTextBox}
          variant="contained"
          onClick={() => {
            onTextBoxAdded(selectedTextBox);
            onClose();
          }}
        >
          {Translate.get("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
