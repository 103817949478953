import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useApi } from "../../utils/Api";
import { hasAccess, accessKeys } from "../../utils/userAccess";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import Preloader from "../Preloader";
import Translate from "../../utils/Translate";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

const notificationTypes = {
  0: "Undefined",
  1: "NotStartedELearning",
  2: "NotCompletedHalfELearning",
  3: "AllExercisesDone",
  4: "LicenseExpires",
  5: "MinCourseParticipantsNotSatisfied",
  6: "CompletedELearning",
  7: "UndeliverableEmail",
  8: "CompletedELearningFast",
  9: "NotStartedELearningToStudent",
  10: "InstructorMaterialUpdate",
  11: "UndeliverableSms",
  12: "NotSignedActorPermitOrGroup",
  13: "ExpiringActorPermit",
  14: "ExpiringActorPermitToResponsible",
  15: "ExpiringActorPermitToDriver",
  16: "NotSignedActorPermitOrGroupToActor",
};

export default function NotificationSettings(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAdvanced] = useState(hasAccess(accessKeys.advancedPackage));
  const [isEnglishCompany] = useState(accessKeys.englishCompany);
  const [isFrenchCompany] = useState(accessKeys.frenchCompany);
  const api = useApi();

  const [days, setDays] = React.useState({
    LicenseExpires: 30,
    NotStartedELearning: 3,
    NotCompletedHalfELearning: 3,
    MinCourseParticipantsNotSatisfied: 30,
    NotStartedELearningToStudent: 3,
    NotSignedActorPermitOrGroup: 3,
    ExpiringActorPermit: 3,
    ExpiringActorPermitToResponsible: 3,
    ExpiringActorPermitToDriver: 3,
    NotSignedActorPermitOrGroupToActor: 3,
  });

  const [conditions, setConditions] = React.useState({
    MinCourseParticipantsNotSatisfied: 0.5,
  });

  const notificationTypesInverted = {
    Undefined: 0,
    NotStartedELearning: 1,
    NotCompletedHalfELearning: 2,
    AllExercisesDone: 3,
    LicenseExpires: 4,
    MinCourseParticipantsNotSatisfied: 5,
    CompletedELearning: 6,
    UndeliverableEmail: 7,
    CompletedELearningFast: 8,
    NotStartedELearningToStudent: 9,
    InstructorMaterialUpdate: 10,
    UndeliverableSms: 11,
    NotSignedActorPermitOrGroup: 12,
    ExpiringActorPermit: 13,
    ExpiringActorPermitToResponsible: 14,
    ExpiringActorPermitToDriver: 15,
    NotSignedActorPermitOrGroupToActor: 16,
  };

  const [notificationStates, setNotificationStates] = React.useState({
    0: { eport: false, email: false },
    1: { eport: false, email: false },
    2: { eport: false, email: false },
    3: { eport: false, email: false },
    4: { eport: false, email: false },
    5: { eport: false, email: false },
    6: { eport: false, email: false },
    7: { eport: false, email: false },
    8: { eport: false, email: false },
    9: { eport: false, email: false },
    10: { eport: false, email: false },
    11: { eport: false, email: false },
    12: { eport: false, email: false },
    13: { eport: false, email: false },
    14: { eport: false, email: false },
    15: { eport: false, email: false },
    16: { eport: false, email: false },
    lastupdated: -1,
  });

  useEffect(() => {
    function recalculate() {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}notificationSubscriber/getUserSubscriptions`,
          false,
          "GET"
        )
        .then((data) => {
          data.forEach((item) => {
            setNotificationStates((prevState) => ({
              ...prevState,
              [item.notificationType]: {
                eport: item.eportNotice,
                email: item.emailNotice,
              },
            }));
            if (item.noticeInDays !== null) {
              setDays((prevDays) => ({
                ...prevDays,
                [notificationTypes[item.notificationType]]: item.noticeInDays,
              }));
            }
            if (item.condition !== null) {
              setConditions((prevConditions) => ({
                ...prevConditions,
                [notificationTypes[item.notificationType]]: item.condition,
              }));
            }
          });
        })
        .finally((f) => {
          setIsLoading(false);
        });
    }

    setIsLoading(true);
    recalculate();
  }, [api]);

  useEffect(() => {
    // Return early, if this is the first render:
    if (notificationStates["lastupdated"] < 0) {
      return;
    }

    function addOrUpdateSubscription() {
      setIsLoading(true);

      const lastUpdated = notificationStates["lastupdated"];
      let reqObj;
      if (
        lastUpdated === 1 ||
        lastUpdated === 2 ||
        lastUpdated === 4 ||
        lastUpdated === 12 ||
        lastUpdated === 13
      ) {
        reqObj = {
          notificationType: lastUpdated ?? -1,
          eportNotice: notificationStates[lastUpdated].eport,
          emailNotice:
            notificationStates[lastUpdated].eport === false
              ? false
              : notificationStates[lastUpdated].email,
          noticeInDays: days[notificationTypes[lastUpdated]],
        };
      } else if (
        lastUpdated === 9 ||
        lastUpdated === 14 ||
        lastUpdated === 15 ||
        lastUpdated === 16
      ) {
        reqObj = {
          notificationType: lastUpdated ?? -1,
          eportNotice: false,
          emailNotice: notificationStates[lastUpdated].email,
          noticeInDays: days[notificationTypes[lastUpdated]],
        };
      } else if (lastUpdated === 5) {
        reqObj = {
          notificationType: lastUpdated ?? -1,
          eportNotice: notificationStates[lastUpdated].eport,
          emailNotice:
            notificationStates[lastUpdated].eport === false
              ? false
              : notificationStates[lastUpdated].email,
          noticeInDays: days[notificationTypes[lastUpdated]],
          condition: conditions[notificationTypes[lastUpdated]],
        };
      } else {
        reqObj = {
          notificationType: lastUpdated ?? -1,
          eportNotice: notificationStates[lastUpdated].eport,
          emailNotice:
            notificationStates[lastUpdated].eport === false
              ? false
              : notificationStates[lastUpdated].email,
        };
      }

      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}notificationSubscriber/addOrUpdateSubscriber`,
          reqObj,
          "POST",
          false,
          true
        )
        .finally((f) => {
          setIsLoading(false);
        });
    }

    addOrUpdateSubscription();
  }, [notificationStates, conditions, days, api]);

  function handleChange(event) {
    setDays({
      ...days,
      [event.target.name]: event.target.value,
    });
    setNotificationStates((prevState) => ({
      ...prevState,
      lastupdated: notificationTypesInverted[event.target.name],
    }));
  }

  function handleConditionChange(event) {
    setConditions({
      ...conditions,
      [event.target.name]: event.target.value,
    });
    setNotificationStates((prevState) => ({
      ...prevState,
      lastupdated: notificationTypesInverted[event.target.name],
    }));
  }

  function handleToggleChange(event) {
    setNotificationStates((prevState) => ({
      ...prevState,
      [notificationTypesInverted[event.target.name]]:
        event.target.value === "eport"
          ? {
              eport:
                !prevState[notificationTypesInverted[event.target.name]].eport,
              email:
                prevState[notificationTypesInverted[event.target.name]].email,
            }
          : {
              eport:
                prevState[notificationTypesInverted[event.target.name]].eport,
              email:
                !prevState[notificationTypesInverted[event.target.name]].email,
            },
      lastupdated: notificationTypesInverted[event.target.name],
    }));
  }

  // Props: title, notificationType, symbolicNotificationName, tooltipTranslationKey
  function NotificationSettingLine(props) {
    return (
      <tr className="notificationTableRow">
        <td className="notificationTableCell">{props.title}</td>
        <td className="notificationTableCell">
          {props.tooltipTranslationKey && (
            <Tooltip title={Translate.get(props.tooltipTranslationKey)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </td>
        <td className="notificationTableCell">
          <Switch
            checked={notificationStates[props.notificationType].eport ?? false}
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="eport"
          />
        </td>
        <td className="notificationTableCell">
          <Checkbox
            checked={
              (notificationStates[props.notificationType].email &&
                notificationStates[props.notificationType].eport) ??
              false
            }
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="email"
            disabled={notificationStates[props.notificationType].eport !== true}
          />
        </td>
      </tr>
    );
  }

  // Props: title, notificationType, symbolicNotificationName, dayAlternatives, tooltipTranslationKey
  function NotificationSettingLineDays(props) {
    return (
      <tr className="notificationTableRow">
        <td className="notificationTableCell">
          {props.title}
          <br />
          {props.isAfter
            ? Translate.get("SendNotificationAfter")
            : Translate.get("SendNotification")}
          <Select
            className="selectInlineText"
            name={props.symbolicNotificationName}
            value={days[props.symbolicNotificationName]}
            onChange={handleChange}
            size="small"
          >
            <MenuItem value={props.dayAlternatives[0]}>
              {props.dayAlternatives[0]}
            </MenuItem>
            <MenuItem value={props.dayAlternatives[1]}>
              {props.dayAlternatives[1]}
            </MenuItem>
            <MenuItem value={props.dayAlternatives[2]}>
              {props.dayAlternatives[2]}
            </MenuItem>
          </Select>{" "}
          {props.isAfter
            ? Translate.get("Days").toLowerCase()
            : Translate.get("DaysPriorTo").toLowerCase()}
        </td>
        <td className="notificationTableCell">
          {props.tooltipTranslationKey && (
            <Tooltip title={Translate.get(props.tooltipTranslationKey)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </td>
        <td className="notificationTableCell">
          <Switch
            checked={notificationStates[props.notificationType].eport ?? false}
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="eport"
          />
        </td>
        <td className="notificationTableCell">
          <Checkbox
            checked={
              (notificationStates[props.notificationType].email &&
                notificationStates[props.notificationType].eport) ??
              false
            }
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="email"
            disabled={notificationStates[props.notificationType].eport !== true}
          />
        </td>
      </tr>
    );
  }

  function NotificationSettingLineDaysOnlyEmail(props) {
    return (
      <tr className="notificationTableRow">
        <td className="notificationTableCell">
          {props.title}
          <br />
          {props.isAfter
            ? Translate.get("SendNotificationAfter")
            : Translate.get("SendNotification")}
          <Select
            className="selectInlineText"
            name={props.symbolicNotificationName}
            value={days[props.symbolicNotificationName]}
            onChange={handleChange}
            size="small"
          >
            <MenuItem value={props.dayAlternatives[0]}>
              {props.dayAlternatives[0]}
            </MenuItem>
            <MenuItem value={props.dayAlternatives[1]}>
              {props.dayAlternatives[1]}
            </MenuItem>
            <MenuItem value={props.dayAlternatives[2]}>
              {props.dayAlternatives[2]}
            </MenuItem>
          </Select>{" "}
          {props.isAfter
            ? Translate.get("Days").toLowerCase()
            : Translate.get("DaysPriorTo").toLowerCase()}
        </td>
        <td className="notificationTableCell">
          {props.tooltipTranslationKey && (
            <Tooltip title={Translate.get(props.tooltipTranslationKey)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </td>
        <td className="notificationTableCell">
          <Switch
            checked={false}
            name={props.symbolicNotificationName}
            value="eport"
            disabled={true}
          />
        </td>
        <td className="notificationTableCell">
          <Checkbox
            checked={notificationStates[props.notificationType].email ?? false}
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="email"
          />
        </td>
      </tr>
    );
  }

  // Props: title, notificationType, symbolicNotificationName, dayAlternatives, conditionAlternatives, conditionTitle, tooltipTranslationKey
  function NotificationLineWithDaysAndCondition(props) {
    return (
      <tr className="notificationTableRow">
        <td className="notificationTableCell">
          {props.title}
          <br />
          <span>
            {Translate.get("SendNotification")}
            <Select
              className="selectInlineText"
              name={props.symbolicNotificationName}
              value={days[props.symbolicNotificationName]}
              onChange={handleChange}
              size="small"
            >
              <MenuItem value={props.dayAlternatives[0]}>
                {props.dayAlternatives[0]}
              </MenuItem>
              <MenuItem value={props.dayAlternatives[1]}>
                {props.dayAlternatives[1]}
              </MenuItem>
              <MenuItem value={props.dayAlternatives[2]}>
                {props.dayAlternatives[2]}
              </MenuItem>
            </Select>{" "}
            {Translate.get("DaysPriorTo").toLowerCase()}
          </span>

          <br />
          <span>
            {props.conditionTitle}
            <Select
              className="selectInlineText"
              name={props.symbolicNotificationName}
              value={conditions[props.symbolicNotificationName]}
              onChange={handleConditionChange}
              size="small"
            >
              <MenuItem value={props.conditionAlternatives[0]}>
                {props.conditionAlternatives[0] * 100 + " %"}
              </MenuItem>
              <MenuItem value={props.conditionAlternatives[1]}>
                {props.conditionAlternatives[1] * 100 + " %"}
              </MenuItem>
              <MenuItem value={props.conditionAlternatives[2]}>
                {props.conditionAlternatives[2] * 100 + " %"}
              </MenuItem>
            </Select>{" "}
          </span>
        </td>
        <td className="notificationTableCell">
          {props.tooltipTranslationKey && (
            <Tooltip title={Translate.get(props.tooltipTranslationKey)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </td>
        <td className="notificationTableCell">
          <Switch
            checked={notificationStates[props.notificationType].eport ?? false}
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="eport"
          />
        </td>
        <td className="notificationTableCell">
          <Checkbox
            checked={
              (notificationStates[props.notificationType].email &&
                notificationStates[props.notificationType].eport) ??
              false
            }
            onChange={handleToggleChange}
            name={props.symbolicNotificationName}
            value="email"
            disabled={notificationStates[props.notificationType].eport !== true}
          />
        </td>
      </tr>
    );
  }

  function NotificationSettingHeader(props) {
    return (
      <tr className="notificationTableRow">
        <td className="notificationTableCell">
          <h3>{props.title}</h3>
        </td>
      </tr>
    );
  }

  return (
    <div>
      {isLoading && <Preloader />}
      <Card
        sx={{ minWidth: 275, maxWidth: 800, minHeight: 400, maxHeight: 1800 }}
      >
        <div className="notificationSettingsBox" style={{ padding: "2em" }}>
          <table className="notificationTable">
            <thead>
              <tr className="notificationTableRow">
                <th
                  style={{
                    fontSize: "x-large",
                    textAlign: "left",
                    padding: "1em 0 1em 0.2em",
                  }}
                >
                  {Translate.get("NotificationSettings")}
                </th>
                <th className="notificationTableCell"></th>
                <th className="notificationTableCell">
                  <Tooltip title={Translate.get("EPortNotification")}>
                    <NotificationsIcon></NotificationsIcon>
                  </Tooltip>
                </th>
                <th className="notificationTableCell">
                  <Tooltip title={Translate.get("EmailNotification")}>
                    <EmailIcon></EmailIcon>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingHeader title={Translate.get("Courses")} />
              )}

              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLine
                  title={Translate.get("CompletedAllExercises")}
                  notificationType={3}
                  symbolicNotificationName="AllExercisesDone"
                />
              )}
              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLine
                  title={Translate.get("CompletedFinalTest")}
                  notificationType={6}
                  symbolicNotificationName="CompletedELearning"
                />
              )}
              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLine
                  title={Translate.get("CompletedCourseTooFast")}
                  notificationType={8}
                  symbolicNotificationName="CompletedELearningFast"
                  tooltipTranslationKey="ShortTimeInfo"
                />
              )}

              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLineDays
                  title={Translate.get("CompletedHalfShortTime")}
                  notificationType={2}
                  symbolicNotificationName="NotCompletedHalfELearning"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="ImminentInfo"
                />
              )}
              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLineDays
                  title={Translate.get("CourseNotStartedShortTime")}
                  notificationType={1}
                  symbolicNotificationName="NotStartedELearning"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="ImminentInfo"
                />
              )}
              {hasAccess(accessKeys.courseAdmin) && hasAdvanced && (
                <NotificationLineWithDaysAndCondition
                  title={Translate.get("LowFillRate")}
                  notificationType={5}
                  symbolicNotificationName="MinCourseParticipantsNotSatisfied"
                  dayAlternatives={[30, 60, 90]}
                  conditionAlternatives={[0.5, 0.7, 0.85]}
                  conditionTitle={Translate.get("FillRateBelow")}
                  tooltipTranslationKey="LowFillRateInfo"
                />
              )}
              {hasAccess(accessKeys.courseAdmin) && (
                <NotificationSettingLineDaysOnlyEmail
                  title={Translate.get("NotStartedELearningStudent")}
                  notificationType={9}
                  symbolicNotificationName="NotStartedELearningToStudent"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="ImminentStudentInfo"
                />
              )}

              {hasAccess(accessKeys.courseAdmin) && hasAdvanced && (
                <NotificationSettingHeader
                  title={Translate.get("Certificate")}
                />
              )}

              {hasAccess(accessKeys.courseAdmin) && hasAdvanced && (
                <NotificationSettingLineDays
                  title={Translate.get("LicenseSoonExpired")}
                  notificationType={4}
                  symbolicNotificationName="LicenseExpires"
                  dayAlternatives={[30, 60, 90]}
                  tooltipTranslationKey="DueInfo"
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingHeader
                  title={Translate.get("DrivingAuthorisation")}
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingLineDays
                  title={Translate.get("NotSignedActorPermitOrGroupSetting")}
                  notificationType={12}
                  symbolicNotificationName="NotSignedActorPermitOrGroup"
                  dayAlternatives={[2, 3, 4]}
                  isAfter
                  tooltipTranslationKey="PermitSignInfo"
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingLineDays
                  title={Translate.get("ExpiringActorPermitSetting")}
                  notificationType={13}
                  symbolicNotificationName="ExpiringActorPermit"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="PermitExpireInfo"
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingLineDaysOnlyEmail
                  title={Translate.get(
                    "ExpiringActorPermitToResponsibleSetting"
                  )}
                  notificationType={14}
                  symbolicNotificationName="ExpiringActorPermitToResponsible"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="ExpiringActorPermitToResponsibleInfo"
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingLineDaysOnlyEmail
                  title={Translate.get("ExpiringActorPermitToDriverSetting")}
                  notificationType={15}
                  symbolicNotificationName="ExpiringActorPermitToDriver"
                  dayAlternatives={[3, 5, 7]}
                  tooltipTranslationKey="ExpiringActorPermitToDriverInfo"
                />
              )}

              {hasAccess(accessKeys.actorPermit) && (
                <NotificationSettingLineDaysOnlyEmail
                  title={Translate.get(
                    "NotSignedActorPermitOrGroupToActorSetting"
                  )}
                  notificationType={16}
                  symbolicNotificationName="NotSignedActorPermitOrGroupToActor"
                  dayAlternatives={[2, 3, 4]}
                  isAfter
                  tooltipTranslationKey="NotSignedActorPermitOrGroupToActorInfo"
                />
              )}

              {hasAccess(accessKeys.instructorMaterial) && (
                <NotificationSettingHeader
                  title={Translate.get("InstructorMaterials")}
                />
              )}

              {hasAccess(accessKeys.instructorMaterial) && (
                <NotificationSettingLine
                  title={Translate.get("InstructorMaterialChanged")}
                  notificationType={10}
                  symbolicNotificationName="InstructorMaterialUpdate"
                />
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
