import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Translate from "../../../utils/Translate";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import * as React from "react";

const Comp = ({
  courseBookingId,
  students,
  loadCourse,
  resetSelected,
  bookedNumberOfStudents,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = React.useState(null);
  const [studentErrorMessages, setStudentErrorMessages] = React.useState([]);
  const api = useApi();

  function checkResponseErrors(response) {
    if (!response.isSuccessful) {
      setRequestErrorMessage(
        Translate.get(response.errorMessageTranslationKey ?? "SomethingFailed")
      );
    }

    let studentErrors = [];
    if (
      response.courseParticipantResults &&
      response.courseParticipantResults.length === students.length
    ) {
      for (let i = 0; i < students.length; i++) {
        if (!response.courseParticipantResults[i].isSuccessful) {
          const studentName = students[i].actorName;
          const studentErrorKey =
            response.courseParticipantResults[i].errorMessageTranslationKey ??
            "SomethingFailed";
          studentErrors.push(
            `${studentName}: ${Translate.get(studentErrorKey)}`
          );
        }
      }
      setStudentErrorMessages(studentErrors);
    }

    return response.isSuccessful && studentErrors.length === 0;
  }

  async function executeAction(keepLastSlot) {
    setIsLoading(true);

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/participants/delete/many?keepLastSlot=${keepLastSlot}`,
      { courseBookingId, courseParticipants: students },
      "POST"
    );

    const isOk = checkResponseErrors(response);
    setIsLoading(false);
    if (isOk) {
      reloadAndClose();
    }
  }

  function reloadAndClose() {
    window.history.back();
    loadCourse();
    resetSelected();
  }

  function showRemoveBookingQuestion(
    numberOfParticipants,
    nbrOfSelectedParticipants
  ) {
    return nbrOfSelectedParticipants >= numberOfParticipants;
  }

  if (requestErrorMessage === null && studentErrorMessages.length === 0) {
    return (
      <DialogContent dividers={true}>
        {isLoading && <Preloader />}
        {showRemoveBookingQuestion(bookedNumberOfStudents, students.length) ? (
          <p>{Translate.get("DeleteLastStudentAndBooking")}</p>
        ) : (
          <p>{Translate.get("SureDeleteStudent")}</p>
        )}
        <br />
        <p>{Translate.get("DeleteParticipantsClarification")}</p>
        <DialogActions>
          {showRemoveBookingQuestion(
            bookedNumberOfStudents,
            students.length
          ) ? (
            <>
              <Button onClick={() => window.history.back()}>
                {Translate.get("Cancel")}
              </Button>
              <Button variant="outlined" onClick={() => executeAction(true)}>
                {Translate.get("No")}
              </Button>
              <Button variant="contained" onClick={() => executeAction(false)}>
                {Translate.get("Yes")}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => window.history.back()}>
                {Translate.get("Cancel")}
              </Button>
              <Button variant="contained" onClick={() => executeAction(false)}>
                {Translate.get("Ok")}
              </Button>
            </>
          )}
        </DialogActions>
      </DialogContent>
    );
  } else {
    return (
      <DialogContent dividers={true}>
        {requestErrorMessage && (
          <p>
            <b>{requestErrorMessage}</b>
          </p>
        )}
        {studentErrorMessages.length > 0 && (
          <p>
            <b>{Translate.get("UnableToRemoveCourseParticipants")}</b>
          </p>
        )}
        {studentErrorMessages.length > 0 &&
          studentErrorMessages.map((item) => (
            <DialogContent>
              <p>{item}</p>
            </DialogContent>
          ))}
        <DialogActions>
          <Button onClick={() => reloadAndClose()}>
            {Translate.get("Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    );
  }
};
export default Comp;
