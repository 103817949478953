import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Translate from "../utils/Translate";

export function Success(props) {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Paper elevation={3} sx={{ p: 8 }}>
            <div>
              <h1>{Translate.get("RegisteredOnCourse")}</h1>
              <Alert severity="success">{Translate.get("MayCloseTab")}</Alert>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
