import { TableAvatar } from "../../utils/dataGrid";
import Translate from "../../utils/Translate";
import Tooltip from "@mui/material/Tooltip"
import { MemToolBar } from '../MemToolBar';

const commonProps = {
  flex: 1,
  minWidth: 150,
};

export const renderHeader = (translationKey, icon) => {
  const headerText = Translate.get(translationKey);
  // TODO: ska man bara visa tooltip om texten "klipper"?
  return <Tooltip title={headerText}> 
      <div className="stdFlexLeft">
        {icon}
        <div>{headerText}</div>
      </div>
    </Tooltip>;
}
export const textColumn = (dataField, icon, translationKey) =>
({
  ...commonProps,
  field: dataField,
  type: 'text',
  headerName: Translate.get(translationKey),  
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,   
});

export const numberColumn = (dataField, icon, translationKey) =>
({
  ...commonProps,
  field: dataField,
  type: 'number',
  headerName: Translate.get(translationKey),  
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,   
})

export const getterColumn = (columnName, getter, icon, translationKey) =>
({
  ...commonProps,
  field: columnName,
  headerName: Translate.get(translationKey),  
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
  valueGetter: getter,
});

export const dateColumn = (dataField, icon, translationKey) =>
({
  ...commonProps,
  field: dataField,
  type: 'date',
  headerName: Translate.get(translationKey),
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
  valueGetter: ({ value }) => value && new Date(value),
});

export const booleanColumn = (dataField, icon, translationKey) =>
({
  ...commonProps,
  field: dataField,
  type: 'boolean',
  headerName: Translate.get(translationKey),
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
});

export const formattedColumn = (dataField, icon, translationKey, formattingFunction) =>
({
  ...commonProps,
  field: dataField,
  headerName: Translate.get(translationKey),
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
  valueFormatter: params => params.value && formattingFunction(params.value),
});

export const avatarColumn = (dataField, secondaryDataField, avatarUrlField, icon, translationKey, variant = 'circular') =>
({
  ...commonProps,
  field: dataField,
  headerName: Translate.get(translationKey),
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
  renderCell: (params) => 
      <TableAvatar
        src={avatarUrlField ? params.row[avatarUrlField] : undefined}
        title={params.row[dataField]}
        subtitle={secondaryDataField && params.row[secondaryDataField]}
        variant={variant}
      />,
});

export const customCellColumn = (dataField, icon, translationKey, renderCell) =>
({
  ...commonProps,
  field: dataField,
  type: 'text',
  headerName: Translate.get(translationKey),  
  renderHeader: icon ? () => renderHeader(translationKey, icon) : undefined,
  renderCell 
});

export const StatisticsGridToolBar = (gridName, exportFileNameTranslationKey) => {
  const fileName = exportFileNameTranslationKey ? Translate.get(exportFileNameTranslationKey) : undefined;
  return <MemToolBar gridId={gridName} showExport={true} exportFileName={fileName} />;
}