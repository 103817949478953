import {
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect } from "react";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from "../../utils/dateTimeFormat";

async function loadSuggestedValues(api) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}permits/suggested-values`,
    false,
    "GET"
  );
}

const freeSoloCombo = (suggestedValues, value, onChange, maxLength, label, required,multiline=false) => (
  <Autocomplete
    fullWidth
    freeSolo
    options={suggestedValues}
    value={value}
    onChange={(_, value) => onChange(value)}
    renderInput={(params) => (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, maxLength: maxLength }}
        variant="outlined"
        label={label}
        multiline={multiline}
        rows={3}
        onChange={(event) => onChange(event.target.value)}
        required={required}
      />
    )}
  />
);

export default function SelectActorPermitDetails({
  otherEquipment,
  onOtherEquipmentChange,
  task,
  onTaskChange,
  area,
  onAreaChange,
  permitStartDate,
  onPermitStartDateChange,
  permitEndDate,
  onPermitEndDateChange,
  startDateErrorMessage,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedValues, setSuggestedValues] = useState({
    area: [],
    equipment: [],
    task: [],
  });
  const api = useApi();

  useEffect(() => {
    setIsLoading(true);
    loadSuggestedValues(api)
      .then((values) => setSuggestedValues(values))
      .finally(() => setIsLoading(false));
  }, [api]);

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <Grid
          container
          columnSpacing={0}
          rowSpacing={2}
          direction="column"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="smallHeader">{Translate.get("OtherDetails")}</div>
          <Grid item>
            {freeSoloCombo(
              suggestedValues?.task,
              task,
              (newValue) => onTaskChange(newValue),
              250,
              Translate.get("WorkTasks"),
              true,
              true
            )}
          </Grid>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
              {freeSoloCombo(
                suggestedValues?.equipment,
                otherEquipment,
                (newValue) => onOtherEquipmentChange(newValue),
                100,
                Translate.get("OtherEquipment")
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              {freeSoloCombo(
                suggestedValues?.area,
                area,
                (newValue) => onAreaChange(newValue),
                250,
                Translate.get("InAreaOf")
              )}
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <DatePicker
                inputFormat={getCurrentInputFormat()}
                mask={getCurrentDateMask()}
                label={Translate.get("ValidFrom")}
                value={permitStartDate}
                onChange={(newValue) => {
                  onPermitStartDateChange(newValue);
                  if (newValue && permitEndDate && newValue > permitEndDate) {
                    onPermitEndDateChange(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: Translate.get("DatePlaceholder"),
                    }}
                    error={!!startDateErrorMessage}
                    helperText={startDateErrorMessage}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                inputFormat={getCurrentInputFormat()}
                mask={getCurrentDateMask()}
                label={Translate.get("ValidTo")}
                value={permitEndDate}
                onChange={(newValue) => {
                  onPermitEndDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: Translate.get("DatePlaceholder"),
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
