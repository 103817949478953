import { useState } from "react";
import YesOrNoDialog from "../../YesOrNoDialog";
import ExtraFinalExamDialog from "./ExtraFinalExamDialog";
import SendPassword, { messageTypes } from "./SendPassword";
import Translate from "../../../utils/Translate";
import { useLocation, useNavigate } from "react-router-dom";

// This component combines two dialogs into a common flow
export default function ExtraFinalExamAndSendFlow({
  eLearningLicenseId,
  newFinalExamNumber,
  sendPasswordProps,
  loadCourse,
}) {
  const [step, setStep] = useState(1);
  const [isNewExtraFinalExamIsLocked, setIsNewExtraFinalExamIsLocked] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  function onExtraExamActivated(newExtraFinalExamIsLocked) {
    setStep(2);
    setIsNewExtraFinalExamIsLocked(newExtraFinalExamIsLocked);
  }

  return (
    <>
      {step === 1 && (
        <ExtraFinalExamDialog
          eLearningLicenseId={eLearningLicenseId}
          newFinalExamNumber={newFinalExamNumber}
          onActivated={onExtraExamActivated}
        />
      )}
      {step === 2 && (
        <YesOrNoDialog
          open={true}
          title={Translate.get("SendExtraExamEmailLabel") + "?"}
          text={
            Translate.get("SendExtraExamInfoNowQuestion") +
            (isNewExtraFinalExamIsLocked
              ? `\n\n${Translate.get("SendExtraExamInfoNowLockNote")}`
              : "")
          }
          onYes={() => setStep(3)}
          onNo={closeAndReload}
        />
      )}
      {step === 3 && (
        <SendPassword
          {...sendPasswordProps}
          messageType={messageTypes.extraExamInfo}
          loadCourse={loadCourse}
          reloadOnCancel={true}
        />
      )}
    </>
  );
}
