import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import StUnitListItem from "./StUnitListItem";
import { useState } from "react";
import { partType } from "../../utils/part";
import ButtonWithSpinner from "../ButtonWithSpinner";

function disableStUnit({ bestBeforeDate }) {
  return bestBeforeDate ? new Date(bestBeforeDate) < new Date() : false;
}

export default function PickOrderLineDialog({
  orderLine,
  stUnits,
  onPickOrderLine,
  onClose,
}) {
  const [isLicensePrint] = useState(
    orderLine.part.partType === partType.plasticCardPrintJob
  );
  const [pickQty, setPickQty] = useState(null);
  const [selectedStUnitId, setSelectedStUnitId] = useState(
    stUnits && stUnits.filter((s) => !disableStUnit(s)).length > 0
      ? stUnits.filter((s) => !disableStUnit(s))[0].stUnitId
      : null
  );

  return (
    <>
      <Dialog
        open={!!orderLine}
        onClose={onClose}
        maxWidth={false}
        className="mediumCourseDialog"
        scroll="paper"
      >
        <DialogTitle>
          {`${Translate.get("Pick")}: ${orderLine.part.description}`}
        </DialogTitle>
        <DialogContent>
          {isLicensePrint && <Box>TODO License print</Box>}

          {!isLicensePrint && stUnits && stUnits.length > 0 ? (
            <List>
              {stUnits.map((st) => (
                <StUnitListItem
                  key={st.stUnitId}
                  {...st}
                  selected={st.stUnitId === selectedStUnitId}
                  onSelect={() => setSelectedStUnitId(st.stUnitId)}
                  disabled={disableStUnit(st)}
                />
              ))}
            </List>
          ) : (
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop="20px">
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {Translate.get("NoStUnitAvailable")}
              </Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            direction="column"
            spacing={1}
            marginTop="10px"
            marginLeft="0px"
            marginRight="16px"
            marginBottom="8px"
          >
            <Grid
              container
              item
              justifyContent="flex-end"
              alignItems="stretch"
              direction="row"
              spacing={1}
            >
              <Grid item xs={3}>
                <ButtonWithSpinner
                  sx={{ height: "40px" }}
                  variant="outlined"
                  fullWidth
                  disabled={
                    pickQty > orderLine.qty - orderLine.delQty ||
                    !selectedStUnitId
                  }
                  onClick={async () =>
                    pickQty > 0
                      ? await onPickOrderLine(
                          orderLine.orderLineId,
                          pickQty,
                          selectedStUnitId
                        )
                      : null
                  }
                >
                  {Translate.get("Pick")}:{" "}
                </ButtonWithSpinner>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={pickQty ?? ""}
                  disabled={!stUnits || stUnits.length === 0}
                  onChange={(ev) => setPickQty(ev.target.value)}
                  placeholder={Translate.get("Quantity")}
                  fullWidth
                  size="small"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                    min: 1,
                    max: orderLine.qty - orderLine.delQty,
                  }}
                  error={
                    pickQty > orderLine.qty - orderLine.delQty ||
                    (selectedStUnitId &&
                      stUnits.find((st) => st.stUnitId === selectedStUnitId)
                        .qty < pickQty)
                  }
                  helperText={
                    pickQty > orderLine.qty - orderLine.delQty
                      ? `${Translate.get("Max")} ${
                          orderLine.qty - orderLine.delQty
                        }`
                      : selectedStUnitId &&
                        stUnits.find((st) => st.stUnitId === selectedStUnitId)
                          .qty < pickQty
                      ? Translate.get("StUnitQtyNotEnough")
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="flex-end"
              direction="row"
            >
              <Grid item>{/* Empty on purpose */}</Grid>
              <Grid item container xs={6} justifyContent="center">
                <Grid item>{Translate.get("Or").toLowerCase()}</Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="flex-end"
              direction="row"
            >
              <Grid item>
                <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
              </Grid>
              <Grid item xs={6}>
                <ButtonWithSpinner
                  fullWidth
                  disabled={
                    !selectedStUnitId ||
                    stUnits.find((st) => st.stUnitId === selectedStUnitId).qty <
                      pickQty
                  }
                  variant="contained"
                  onClick={async () =>
                    await onPickOrderLine(
                      orderLine.orderLineId,
                      orderLine.qty - orderLine.delQty,
                      selectedStUnitId
                    )
                  }
                >{`${Translate.get("Pick")} ${Translate.get("All")} (${
                  orderLine.qty - orderLine.delQty
                })`}</ButtonWithSpinner>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
