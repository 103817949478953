import { useState } from 'react';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import EditIcon from '@mui/icons-material/Edit';
import DotMenu from "../DotMenu";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';

const PaymentAgreements = () => {
    const api = useApi();
    const [loaded, setLoaded] = useState(false);

    const [paymentAgreements, setPaymenAgreements] = useState([])
    const [open, setOpen] = React.useState(false);

    const [isDefault, setIsDefault] = useState(false);
    const [description, setDescription] = useState("");
    const [ePortCodeId, setEPortCodeId] = useState(null);

    const handleClose = () => {
        setIsDefault(false);
        setDescription("");
        setEPortCodeId(null);
        setOpen(false);
    };

    if (!loaded) {
        (async () => {
            loadPaymentAgreements();
        })();
    }

    async function loadPaymentAgreements() {
        const data = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/paymentagreements`,
        );
        setLoaded(true);
        setPaymenAgreements(data.paymentAgreements);
    }

    async function deletePaymentAgreement(ePortCodeId) {
        let res = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/paymentagreement/${ePortCodeId}`,
            {},
            "DELETE"
        );

        if (res.isSuccessful) {
            await loadPaymentAgreements();
        }
    }

    function getDotMenuActions(ePortCodeId, description, isDefault) {
        return [
            {
                icon: <EditIcon />,
                text: Translate.get("Alter"),
                onClick: () => updatePaymentAgreement(ePortCodeId, description, isDefault),
            },
            {
                icon: <DeleteIcon />,
                text: Translate.get("Delete"),
                onClick: () => deletePaymentAgreement(ePortCodeId),
            },            
        ];
    }

    function updatePaymentAgreement(ePortCodeId, description, isDefault) {
        setDescription(description);
        setIsDefault(isDefault);
        setEPortCodeId(ePortCodeId)
        setOpen(true);
    }

    function addPaymentAgreement() {
        setOpen(true);
    }

    function setAsDefault() {
        setIsDefault(!isDefault);
    }

    const onDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    async function postOrPutAgreement() {
        if (ePortCodeId == null) {
            await postPaymentAgreement();
        } else {
            await putPaymentAgreement();
        }
    }

    async function postPaymentAgreement() {
        let reqObj = {
            "description": description,
            "isDefault": isDefault
        }

        let res = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/paymentagreement`,
            reqObj,
            "POST"
        );

        if (res.isSuccessful) {
            await loadPaymentAgreements();
            handleClose();
        }
    }

    async function putPaymentAgreement() {
        let reqObj = {
            "ePortCodeId": ePortCodeId,
            "description": description,
            "isDefault": isDefault
        }

        let res = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/paymentagreement`,
            reqObj,
            "PUT"
        );

        if (res.isSuccessful) {
            await loadPaymentAgreements();
            handleClose();
        }
    }


    return (
        <>
            <Box sx={{ minWidth: 120 }}>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {Translate.get("PaymentTerms")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                required
                                id="outlined-required"
                                label={Translate.get("Condition")}
                                sx={{ mt: 2 }}
                                value={description}
                                onChange={onDescriptionChange}
                            />
                        </DialogContentText>
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={
                                <Checkbox checked={isDefault} onChange={() => setAsDefault()} />
                            }
                            label={Translate.get("Standard")}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>{Translate.get("Close")}</Button>
                        <Button
                            onClick={postOrPutAgreement}
                        >
                            {Translate.get("Save")}</Button>
                    </DialogActions>

                </Dialog>

                <List>
                    {paymentAgreements.map((agreement) =>
                        <ListItem
                            secondaryAction={
                                <DotMenu content={getDotMenuActions(agreement.ePortCodeId, agreement.description, agreement.isDefaultValue)} horizontal />

                            }
                            key={agreement.ePortCodeId}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <PaymentIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={agreement.description}
                            />
                            {agreement.isDefaultValue ? <CheckIcon /> : ""}
                        </ListItem>,
                    )}

                </List>
                <Grid container justifyContent="flex-end">
                    <Button
                        variant='outlined'
                        onClick={addPaymentAgreement}
                    >
                        {Translate.get("Add")}
                    </Button>
                </Grid>
            </Box>
        </>
    );
};
export default PaymentAgreements;
