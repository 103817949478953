import { useState } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import PersonIcon from "@mui/icons-material/Person";
//import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import NumbersIcon from "@mui/icons-material/Numbers";
//import LabelIcon from '@mui/icons-material/Label';
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PlaceIcon from "@mui/icons-material/Place";
import DomainIcon from "@mui/icons-material/Domain";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useNavigate } from "react-router-dom";
import Translate from "../../utils/Translate";
import { MemToolBar, dataGridLang } from "../MemToolBar";
import DotMenu from "../DotMenu";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { TableAvatar, getGridColDefForArrayValues } from "../../utils/dataGrid";
import { Tooltip } from "@mui/material";

//Translate.get('VAlfri översättningsnyckel'),

/* const localizedTextsMap = {
  noRowsLabel: 'Inget resultat',
  noResultsOverlayLabel: 'Inga resultat funna.',
  errorOverlayDefaultLabel: 'Ett fwl inträffade.',
  columnMenuUnsort: 'Sortera inte',
  columnMenuSortAsc: 'Sortera stigande',
  columnMenuSortDesc: 'Sortera fallande',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Dölj',
  columnMenuShowColumns: 'Visa',
  toolbarDensity: 'Radhöjd',
  toolbarExport: 'Exportera',
  toolbarFilters: 'Filter',
  toolbarColumns: 'Kolumner',
  MuiTablePagination: {
    labelRowsPerPage: 'Visa antal poster',
    labelDisplayedRows: ({ from, to, count }) =>
      `${from}-${to} av ${count !== -1 ? count : `sammanlagt ${to}`}`,
  },
}; */

const getActorIcons = (type) => {
  //1 företag, 3 kontaktperson, 4 elev, 6 instruktör, 9 privatbokare
  switch (type) {
    case 3:
      return <HeadsetMicIcon />;
    case 4:
      return <PersonIcon />;
    case 6:
      return <CoPresentIcon />;
    default:
      return <PersonIcon />;
  }
};

const getRoleFilterOptions = () => [
  { value: 3, label: Translate.getActorType(3) },
  { value: 4, label: Translate.getActorType(4) },
  { value: 6, label: Translate.getActorType(6) },
];

const Comp = ({ rows, smallScreen, getDotMenuActions, cardView = false,noRowsTransKey}) => {
  const localizedTextsMap = dataGridLang();
  //const smallScreen = useMediaQuery('(max-width:700px)');
  let columns = [];
  let toolbarCss = "stdVisible dgDynIconText";
  if (cardView) {
    toolbarCss = toolbarCss + " dgNoIconText";
  }
  if (smallScreen) {
    toolbarCss = "dgSmall";
  }

  if (smallScreen) {
    columns = [
      /*  { field: 'actorId', headerName: 'ID', flex: 1}, */
      {
        field: "actorName",
        headerName: Translate.get("ActorName"),

        renderHeader: () => (
          <div className="stdFlexLeft">
            <PersonIcon />
            <div>{Translate.get("ActorName")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.profilePictureUrl + ""}
              //variant="rounded"
              title={params.row.actorName}
              subtitle={[
                params.row.email ?? "",
                params.row.postalAddress.actorAddressCity ?? "",
              ]}
            />
          );
        },
        flex: 2,
        //editable: true,
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        hide: !getDotMenuActions,
        renderCell: (params) =>
          getDotMenuActions && (
            <DotMenu
              content={getDotMenuActions(params.row.actorId)}
              horizontal
            />
          ),
      },
    ];
  } else {
    columns = [
      /*  { field: 'actorId', headerName: 'ID', flex: 1}, */
      {
        field: "actorName",
        headerName: Translate.get("ActorName"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <PersonIcon />
            <div>{Translate.get("ActorName")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.profilePictureUrl + ""}
              //variant="rounded"
              title={params.row.actorName}
              subtitle={params.row.email ?? ""}
            />
          );
        },
        flex: 1,
        minWidth: 300,
        //editable: true,
      },

      {
        field: "externalActorNo",
        headerName: Translate.get("EmployeeNo"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <NumbersIcon />
            <div>{Translate.get("EmployeeNo")}</div>
          </div>
        ),
        renderCell: (params) => {
          return <div>{params.row.externalActorNo}</div>;
        },

        //editable: true,
      },
      {
        field: "orgNo",
        headerName: Translate.get("PersonalNo"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <FingerprintIcon />
            <div>{Translate.get("PersonalNo")}</div>
          </div>
        ),
        renderCell: (params) => {
          return <div>{params.row.orgNo}</div>;
        },
      },

      {
        field: "Ort",
        headerName: Translate.get("City"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <PlaceIcon />
            <div>{Translate.get("City")}</div>
          </div>
        ),
        valueGetter: (params) =>
          params.row.postalAddress.actorAddressCity || "",
        sortComparator: (v1, v2) => v1.localeCompare(v2),
        renderCell: (params) => {
          return (
            <div className="stdFlexCol">
              {params.row.postalAddress.actorAddressZipCode && (
                <div>{params.row.postalAddress.actorAddressZipCode}</div>
              )}
              {params.row.postalAddress.actorAddressCity && (
                <div>{params.row.postalAddress.actorAddressCity}</div>
              )}
            </div>
          );
        },
      },
      {
        field: "Companies",
        headerName: Translate.get("Companies"),
        flex: 1,
        hide: cardView,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <DomainIcon />
            <div>{Translate.get("Companies")}</div>
          </div>
        ),
        valueGetter: (params) =>
          (params.row.parentActors?.length &&
            params.row.parentActors[0].parentActor.actorName) ||
          "",
        sortComparator: (v1, v2) => v1.localeCompare(v2),
        renderCell: (params) => {
          const act1 = params.row.parentActors?.length
            ? params.row.parentActors[0].parentActor?.actorName
            : "";
          let act2 = "";
          if (
            params.row.parentActors?.length > 1 &&
            params.row.parentActors[1]
          ) {
            act2 = params.row.parentActors[1].parentActor.actorName || "";
          }
          if (
            params.row.parentActors?.length > 2 &&
            params.row.parentActors[2]
          ) {
            act2 += "...";
          }
          return (
            <div className="stdFlexCol">
              {act1 && <div>{act1}</div>}
              {act2 && <div>{act2}</div>}
            </div>
          );
        },
      },
      {
        ...getGridColDefForArrayValues(getRoleFilterOptions()),
        field: "actorTypes",
        headerName: Translate.get("Role"),
        flex: 1,
        hide: cardView,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <AssignmentIndIcon />
            <div>{Translate.get("Role")}</div>
          </div>
        ),
        // Probably only needed in case someone exports the data
        valueFormatter: (params) =>
          params.value &&
          Array.isArray(params.value) &&
          params.value
            .map((actorType) => Translate.getActorType(actorType))
            .join(", "),
        renderCell: (params) => {
          return (
            <div className="stdFlexLeft0">
              {params.row?.actorTypes?.length > 0 &&
                params.row.actorTypes.slice(0, 3).map((actorType, index) => (
                  <Tooltip
                    className="personRoles"
                    key={index}
                    title={Translate.getActorType(actorType)}
                  >
                    {getActorIcons(actorType)}
                  </Tooltip>
                ))}
            </div>
          );
        },
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "hideSeparator",
        align: "right",
        hide: !getDotMenuActions,
        hideable: false,
        renderCell: (params) =>
          getDotMenuActions && (
            <DotMenu
              content={getDotMenuActions(params.row.actorId)}
              horizontal
            />
          ),
      },
    ];
  }

  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const navToUser = (id) => {
    sessionStorage.removeItem("paginationModel_courses");
    navigate(`/dashboard/person/${id}`, { replace: false });
  };

  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "actorName", sort: "asc" }],
          },
        }}
        sx={{ paddingLeft: "8px" }}
        className={toolbarCss}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableColumnResize={true}
        getRowId={(row) => row.actorId}
        autoHeight={true}
        onRowClick={(params) => navToUser(params.id)}
        components={{
          //Toolbar: MemToolBar,
          NoRowsOverlay: CustomNoRowsOverlay(noRowsTransKey || Translate.get('EmptyHere')),
        }}
        componentsProps={{
          toolbar: { gridId: "Users" },
          columnsPanel: { className: "customColumnPanel" },
          filterPanel: { className: "customfilterPanel" },
          panel: { className: "customPanel" },
        }}
        localeText={localizedTextsMap}
        pagination
        pageSizeOptions={[10, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        density="comfortable"
        disableDensitySelector={true}
        disableColumnMenu
      />
    </>
  );
};

export default Comp;
