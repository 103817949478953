import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import Page from '../components/Page';
// import AuthSocial from '../components/authentication/AuthSocial';
import Translate from '../utils/Translate';
import Preloader from '../components/Preloader';
import { getQueryParam } from '../utils/uriHelper';
import Logo from '../components/Logo';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import BankIdAuthentication from '../components/bankId/BankIdAuthentication';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent:'center',
  //padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PermitSignLogin({isPermitGroup = false}) {
  const [dataFetched, setDataFetched] = useState(false);
  const [isTimeout, setIsTimeout] = useState(getQueryParam("timeout"));
  const navigate = useNavigate();
  const encryptedData = useParams().encryptedData;
  const onAuthenticationSuccess = (loginType, response) => {
      const data = response.data;
      if(data.isSuccessful) {
        sessionStorage.setItem("permitSignEncryptedData", encryptedData);
        const permitGroupId = Number(response?.data?.actorPermitGroupId);
        const permitId = Number(response?.data?.actorPermitId);
        if(isPermitGroup && permitGroupId) {
          const url = `/dashboard/permits/groups/${permitGroupId}/sign/`;
          navigate(url);
        }
        else if(permitId) {
          const url = `/dashboard/permits/${permitId}/sign`;
          navigate(url);
        }
      }
  }


  if (!dataFetched && !isTimeout) {
    if (!sessionStorage.translation) {
     /*  let lang=9;
      if (localStorage.lang) {
        lang = localStorage.lang;
      } 
       Translate.loadPreLogin(lang).then
      */
      Translate.loadPreLogin(false).then((data) => {
        setDataFetched(true);
      });
    } else {
      setDataFetched(true);
    }
  }

  function handleTimeoutOk() {
    setIsTimeout(false);
  }

  const getLoginUrl = () =>
  // vi behöver använda en annan backend-url för att cookies ska fungera om siten använder adressen kortillstand.se
  `${(window.global.isKs ? process.env.REACT_APP_KORTILLSTAND_AUTH_URL : process.env.REACT_APP_AUTH_URL)}auth/bankid/v2/login/permit/${(isPermitGroup ? "responsible" : "holder")}`;

  return (
    <RootStyle  className='loginPage'>
      {!dataFetched && !isTimeout && <Preloader />}
      {isTimeout && (
        <Dialog open={true}>
          <DialogContent>{Translate.get("LoggedOutTimeout")}</DialogContent>
          <DialogActions>
            <Button onClick={handleTimeoutOk}>
              {Translate.get("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {!isTimeout && (
        <Container maxWidth="sm">
          <ContentStyle>
            {/* <LoginForm redirectPath={redirectPath} openBankIdLogin={openBankIdLogin} /> */}

            <div className="loginBox">
              <div className="loginLogo">
                <Logo />
              </div>
              <form className="loginForm">
                  <BankIdAuthentication startAuthenticationUrl={getLoginUrl()}
                      postData={{data: encryptedData}}
                      fetchStatusIncludeCredentials={true}
                      // onStatusInitiated={onStatusInitiated}
                      onAuthenticationSuccess={onAuthenticationSuccess}
                      // startQrCodeInMobile={startQrCodeInMobile}
                  />
              </form>
            </div>
          </ContentStyle>
        </Container>
      )}
    </RootStyle>
  );
}
