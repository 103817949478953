import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Translate from "../../../utils/Translate";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";

export default function RecallLicensesDialog({
  setShowRecallLicenseDialog,
  maxRecallQty,
  dataId,
  eLearningType,
  setMaxRecallQty,
  setDataId,
  setELearningType,
  getAsClient,
}) {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showSureDialog, setShowSureDialog] = useState(false);
  const [recallQty, setRecallQty] = useState(null);

  const handleClose = () => {
    setShowRecallLicenseDialog(false);
    setMaxRecallQty(null);
    setDataId(null);
    setELearningType(null);
  };

  async function postRecall() {
    if (eLearningType !== 1000) {
      await postRecallLicenses();
    } else {
      await postRecallFinalExams();
    }
  }

  async function postRecallLicenses() {
    setIsLoading(true);

    let reqObj = {
      dataId: dataId,
      recallQty: recallQty,
      eLearningType: eLearningType,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/recall`,
      reqObj,
      "POST"
    );

    if (res.isSuccessful) {
      await getAsClient();
      handleClose();
    }

    setIsLoading(false);
  }

  async function postRecallFinalExams() {
    setIsLoading(true);

    let reqObj = {
      dataId: dataId,
      recallQty: recallQty,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/recall/extra-exam`,
      reqObj,
      "POST"
    );

    if (res.isSuccessful) {
      getAsClient();
      handleClose();
    }

    setIsLoading(false);
  }

  return (
    <React.Fragment>
      {showSureDialog && 
        <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get("Sure")}
        </DialogTitle>
        <DialogContent>
          {isLoading && <Preloader />}

          <DialogContentText id="alert-dialog-description">
          {Translate.get("RecallLicenses")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSureDialog(false)} autofocus>
            {Translate.get("Close")}
          </Button>
          <Button onClick={postRecall} disabled={recallQty < 1}>
            {Translate.get("Continue")}
          </Button>
        </DialogActions>
      </Dialog>
      }

      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get("Recall")}
        </DialogTitle>
        <DialogContent>
          {isLoading && <Preloader />}

          <DialogContentText id="alert-dialog-description">
            <TextField
              variant="outlined"
              label={Translate.get("Quantity")}
              style={{ marginTop: "1em", minWidth: "15em" }}
              value={recallQty}
              onChange={(event) => setRecallQty(Number(event.target.value))}
              type="number"
              inputProps={{
                min: 0,
                max: maxRecallQty,
              }}
            ></TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autofocus>
            {Translate.get("Close")}
          </Button>
          <Button onClick={() => setShowSureDialog(true)} disabled={recallQty < 1}>
            {Translate.get("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
