
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';


// ----------------------------------------------------------------------

const CHART_HEIGHT = 150;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(0),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
}));

// ----------------------------------------------------------------------

const Comp = ({icon,value,text}) => {
  return (
    <Card>
      {/* <CardHeader title={'Tre olika övergipande stats'} /> */}
      <ChartWrapperStyle className='stdFlexCenterCol'  dir="ltr">
        {icon}
        <div><h1>{value.toLocaleString()}</h1></div>
        <div>{text}</div>
        
      </ChartWrapperStyle>
    </Card>
  );
}
export default Comp;
