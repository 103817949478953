import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import { isMobile } from "is-mobile";
import { AlertDialog } from "../AlertDialog";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DomainIcon from "@mui/icons-material/Domain";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DotMenu from "../DotMenu";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import DigitalLicenseView from "./DigitalLicenseView";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShareIcon from "@mui/icons-material/Share";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogTitle, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import {
  addToAppleWallet,
  addToGoogleWallet,
  shareDigitalLicense,
} from "./digitalLicenseHelper";
import { useMemo } from "react";

export default function DigitalLicenseSingleItem({
  license,
  onClick,
  showDigitalPermitButton,
  skillGroupDescription,
  isRestrictedView,
  company,
  skills,
  otherSkills,
}) {
  const navigate = useNavigate();
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const smallScreen = useMediaQuery("(max-width:750px)"); // Same breakpoint as in DigitalLicenseView

  const dotMenuActions = useMemo(
    () => [
      {
        icon: <AddIcon />,
        text: Translate.get("AddToAppleWallet"),
        onClick: () =>
          addToAppleWallet(
            license.digitalLicenseId,
            enqueueSnackbar,
            closeSnackbar
          ),
      },
      {
        icon: <AddIcon />,
        text: Translate.get("AddToGoogleWallet"),
        onClick: () => addToGoogleWallet(api, license.digitalLicenseId),
      },
      {
        icon: <AddShoppingCartIcon />,
        text: Translate.get("PlaceOrder"),
        onClick: () => navigate(`/d/order/${license.digitalLicenseId}`),
      },
      {
        icon: <ShareIcon />,
        text: Translate.get("Share"),
        onClick: () =>
          shareDigitalLicense(
            license.publicShareUrl,
            enqueueSnackbar,
            closeSnackbar
          ),
      },
      {
        icon: <ListAltIcon />,
        text: Translate.get("ShowActorPermit"),
        onClick: () => navigate(`/d/permits/${skillGroupDescription}`),
      },
    ],
    [api, navigate, license, enqueueSnackbar, closeSnackbar]
  );

  return (
    <div className="dlSingleContainer">
      <div className="stdFlexSB">
        <div>
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div>
          <DotMenu horizontal content={dotMenuActions} />
        </div>
      </div>
      {/* TODO Kan vi göra oss av med dlcCardWrapper och förenkla denna lite? */}
      {smallScreen ? (
        <DigitalLicenseView
          displayData={license.displayData}
          company={company}
          skills={skills}
          otherSkills={otherSkills}
          animate={true}
          allowSwiper={true}
          showExtraInfo={true}
          isSmallCard={false}
        />
      ) : (
        <div className="dlcCardWrapper">
          <DigitalLicenseView
            displayData={license.displayData}
            company={company}
            skills={skills}
            otherSkills={otherSkills}
            animate={true}
            allowSwiper={true}
            showExtraInfo={true}
            isSmallCard={false}
          />
        </div>
      )}
    </div>
  );
}
