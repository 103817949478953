import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { hasSomeAccess, accessKeys } from '../../utils/userAccess';

function OrderFormSection({ title, items }) {
  return (
    <Grid item container spacing={3}>
      <Grid item container>
        <div className="smallHeader">{title}</div>
      </Grid>
      <Grid item container columnSpacing={2} rowSpacing={3}>
        {items.map(({ value, ...rest }, idx) => (
          <Grid item key={idx} xs={12} sm={6}>
            <TextField
              autoComplete="off"
              fullWidth
              value={value ?? ""}
              InputProps={{ spellCheck: false }}
              {...rest}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default function FlyerOrderFormDialog({ open, onClose, onOrder }) {
  const [orderDetails, setOrderDetails] = useState({});
  let showForm=false;
  if (
    !hasSomeAccess(
      accessKeys.courseAdmin,
      accessKeys.instructorMaterial,
    )
  ) {
    showForm=true;
  }

  function handleChangeOrderDetail(event, fieldName) {
    setOrderDetails({ ...orderDetails, [fieldName]: event.target.value });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Intresseanmälan {/* {Translate.get("OrderDetails")} */}</DialogTitle>
      <DialogContent>
      {Translate.get("OrderRequestEPort")}
        {showForm && <Grid container spacing={4}>
          <OrderFormSection
            title={Translate.get("Company")}
            items={[
              {
                value: orderDetails.companyName,
                label: Translate.get("Name"),
                onChange: (event) =>
                  handleChangeOrderDetail(event, "companyName"),
              },
              {
                value: orderDetails.companyOrgNo,
                label: Translate.get("OrgNo"),
                onChange: (event) =>
                  handleChangeOrderDetail(event, "companyOrgNo"),
              },
            ]}
          />
          <OrderFormSection
            title={Translate.get("ContactPerson")}
            items={[
              {
                value: orderDetails.contactName,
                label: Translate.get("Name"),
                required: true,
                onChange: (event) =>
                  handleChangeOrderDetail(event, "contactName"),
              },
              {
                value: orderDetails.contactPhone,
                label: Translate.get("Phone"),
                required: !orderDetails.contactEmail,
                onChange: (event) =>
                  handleChangeOrderDetail(event, "contactPhone"),
              },
              {
                value: orderDetails.contactEmail,
                label: Translate.get("Email"),
                required: !orderDetails.contactPhone,
                onChange: (event) =>
                  handleChangeOrderDetail(event, "contactEmail"),
              },
            ]}
          />
        </Grid>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={async () => {
            await onOrder(orderDetails);
            onClose();
          }}
          disabled={showForm && !orderDetails.contactPhone && !orderDetails.contactEmail}
        >
          Skicka förfrågan {/* {Translate.get("SendRequest")} */}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
