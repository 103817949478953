import React, { useState } from "react";
import "./index.css"
import { Balance } from "../components/licenseManagement/Balance";
import { Order } from "../components/licenseManagement/Order";
import { Recall } from "../components/licenseManagement/Recall";

export default function LicenseManagement(props) {

    const [mode, setMode] = useState("balance");
    const [eLearningType, setELearningType] = useState("");


    if (mode === "balance") {
        return (
            <div>
                <Balance
                    switchMode={switchMode}
                />
            </div>)
    } else if (mode === "order") {
        return (
            <div>
                <Order
                    switchMode={switchMode}
                    eLearningType={eLearningType}
                />
            </div>)
    } else if (mode === "recall") {
        return (
            <div>
                <Recall
                    switchMode={switchMode}
                    eLearningType={eLearningType}
                />
            </div>
        )
    }

    function switchMode(mode, eLearningType) {
        setMode(mode);
        setELearningType(eLearningType)
    }
}
