import Translate from "../../utils/Translate";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import DownloadIcon from "@mui/icons-material/Download";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { dataGridLang } from "../MemToolBar";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import ConstructionIcon from "@mui/icons-material/Construction";
//import { useState} from "react";
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PermitStatus from './PermitStatus';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


let toolbarCss = "stdVisible dgDynIconText textAlignLeft";
//let toolbarCss = "stdVisible";
const localizedTextsMap = dataGridLang();
const Table = (props) => {
  const columns = [
    {
      field: "actorName",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PersonIcon}
          label={Translate.get("ActorName")}
        />
      ),
    },
    {
      field: "parentActorName",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={DomainIcon}
          label={Translate.get("Company")}
        />
      ),
    },
  
    {
      field: "skillGroupDescription",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ConstructionIcon}
          label={Translate.get("MachineGroup")}
        />
      ),
    },
    {
      field: "actorEmail",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={EmailIcon}
          label={Translate.get("Email")}
        />
      ),
    },
    {
      field: "mobilePhone", //actor.mobilePhone?.actorPhoneNumber,
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={LocalPhoneIcon}
          label={Translate.get("Phone")}
        />
      ),
    },
    {
      field: "customPermitStatus",
      headerName: Translate.get("Status"),
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <div className="stdFlexLeft">
          <CheckCircleIcon />
          <div>{Translate.get("Status")}</div>
        </div>
      ),
      renderCell: (params) => <PermitStatus row={params.row} />,
    },
    {
      field: 'custom1',
      //headerName: Translate.get('Permit'),
      flex: 1,
      sortable: false,
      filterable: false,
      disableExport: true,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <div></div>
      ),
      renderCell: (params) => {
        return (
          <span className="stdFlexLeft">
          <Tooltip style={{marginLeft:'0'}} title={Translate.get('Download')}>
            <LoadingButton
              className="regularButtonWithIcon stdLightLoader"
              variant="outlined"
              //tooltip={Translate.get('Download')}
              //loading={props.pdfLoading}
              loading={props.pdfLoading.includes(params.row.actorPermitId)}
              
              
              onClick={(event) => {
                event.stopPropagation();
                props.permitPdfClick(params.row);
              }}
            >
              <DownloadIcon />
            </LoadingButton>
          </Tooltip>
          {(isNaN(props.issuer)===false && props.signed ===false) &&
          <Tooltip style={{marginLeft:'0'}} title={Translate.get('Delete')}>
            <LoadingButton
              className="regularButtonWithIcon stdLightLoader"
              variant="outlined"
              //tooltip={Translate.get('Download')}
              //loading={props.deleteLoading}
              disabled={props.deleteLoading}
              onClick={(event) => {
                event.stopPropagation();
                props.deletePermit(params.row);
              }}
            >
             <DeleteIcon/>
            </LoadingButton>
          </Tooltip>
          }
          </span>
        );
      }},
  ];
   //Translate.loadApp();
  console.log(props);
  //const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  return (
      <div className="simpleCardStyle">
       {/*  simpleCardStyle kan vara lite suspekt då jag inte riktigt vet hur mui-tabellen hanteras, går bra att ta bort hela div:en */}
      <DataGridPremium
        className={toolbarCss }
        rows={props.data}
        columns={columns}
        disableSelectionOnClick
        getRowId={(row) => row.actorId}
        autoHeight={true}
        localeText={localizedTextsMap}
        hideFooter={true}
        //pagination
        //pageSizeOptions={[10, 20, 30, 100]}
        //paginationModel={paginationModel}
        //onPaginationModelChange={setPaginationModel}
        density="comfortable"
      />
</div>

  );
};
export default Table;
