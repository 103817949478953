import Translate from "../../utils/Translate";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ConstructionIcon from "@mui/icons-material/Construction";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PermitStatus from './PermitStatus';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const Card = (props) => {
  return (
    <>
      <div className="simpleCards">
        {props.data.map((row, index) => (
          <div key={index}>
            <div>
              <div>
                <PersonIcon />
              </div>
              <div>{row.actorName}</div>
            </div>
            <div>
              <div>
                <DomainIcon />
              </div>
              <div>{row.parentActorName}</div>
            </div>
            <div>
              <div>
                <ConstructionIcon />
              </div>
              <div>{row.skillGroupDescription}</div>
            </div>
            <div>
              <div>
                <EmailIcon />
              </div>
              <div>{row.actorEmail}</div>
            </div>
            <div>
              <div>
                <LocalPhoneIcon />
              </div>
              <div>{row.mobilePhone}</div>
            </div>
            <div>
              <div>
                <CheckCircleIcon />
              </div>
              <div>{<PermitStatus row={row} />}</div>
            </div>
            <div className="flex_space_Between">
              <div>{/*  <DownloadIcon /> */}</div>
              <div className="stdFlexLeft">
                <Tooltip
                  style={{ marginLeft: "0" }}
                  title={Translate.get("Download")}
                >
                  <LoadingButton
                    className="regularButtonWithIcon stdLightLoader"
                    variant="outlined"
                    //tooltip={Translate.get('Download')}
                    //loading={props.pdfLoading}
                    loading={props.pdfLoading.includes(row.actorPermitId)}
                    onClick={(event) => {
                      event.stopPropagation();
                      props.permitPdfClick(row);
                    }}
                  >
                    <DownloadIcon />
                  </LoadingButton>
                </Tooltip>
                {isNaN(props.issuer) === false && props.signed === false && (
                  <Tooltip
                    style={{ marginLeft: "0" }}
                    title={Translate.get("Delete")}
                  >
                    <LoadingButton
                      className="regularButtonWithIcon stdLightLoader"
                      variant="outlined"
                      //tooltip={Translate.get('Download')}
                      //loading={props.deleteLoading}
                      disabled={props.deleteLoading}
                      onClick={(event) => {
                        event.stopPropagation();
                        props.deletePermit(row);
                      }}
                    >
                      <DeleteIcon />
                    </LoadingButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default Card;
