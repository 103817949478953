import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "../components/settings/TabPanel";
import Translate from "../utils/Translate";
import { hasSomeAccess, accessKeys } from "../utils/userAccess";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getQueryParam } from "../utils/uriHelper";
import ActorLicenseStatistics from "../components/statistics/ActorLicenseStatistics";
import ActorSkillsStatistics from "../components/statistics/ActorSkillsStatistics";
import CourseBookingsStatistics from "../components/statistics/CourseBookingsStatistics";
import CourseParticipantStatistics from "../components/statistics/CourseParticipantStatistics";
import ContactPersonStatistics from "../components/statistics/ContactPersonStatistics";

const Statistics = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const hasStandardOrAdvanced = hasSomeAccess(
    accessKeys.standardPackage,
    accessKeys.advancedPackage
  );

  const tabs = [
    {
      label: Translate.get("Licenses"),
      component: <ActorLicenseStatistics />,
      isVisible: true,
    },
    {
      label: Translate.get("CourseBookings"),
      component: <CourseBookingsStatistics />,
      isVisible: true,
    },
    {
      label: Translate.get("Students"),
      component: <CourseParticipantStatistics />,
      isVisible: true,
    },
    {
      label: Translate.get("Competences"),
      component: <ActorSkillsStatistics />,
      isVisible: true,
    },
    {
      label: Translate.get("ContactPersons"),
      component: <ContactPersonStatistics />,
      isVisible: true,
    },
  ];

  useEffect(() => {
    const tabParamValue = getQueryParam("tab");
    if (tabParamValue) {
      setValue(parseInt(tabParamValue));
    } else {
      setValue(0);
      navigate(`${location.pathname}?tab=0`, { replace: true });
    }
  }, [navigate, location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`, { replace: true });
  };
    
  return (
    <div>
      <h2>{Translate.get("Reports")} {/* {window.innerWidth} x {window.innerHeight} */}</h2>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} centered>
            {tabs
              .filter((t) => t.isVisible)
              .map((t, i) => (
                <Tab label={t.label} key={`statisticsTab${i}`} />
              ))}
          </Tabs>
        </Box>
        {tabs
          .filter((t) => t.isVisible)
          .map((t, i) => (
            <TabPanel className="tabPanelNoPadding" value={value} index={i} key={`statisticsTabBody${i}`}>
              {t.component}
            </TabPanel>
          ))}
      </Box>
    </div>
  );
};

export default Statistics;