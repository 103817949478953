import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../ButtonWithSpinner";
import YesOrNoDialog from "../YesOrNoDialog";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import Preloader from "../Preloader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { groupBy } from "lodash";

function TrainingListItem({
  code,
  name,
  regulatedBy,
  hasExternalSkillInCurrentCourse,
  disabled, // This isn't really used currently
  onToggle,
  groupId,
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        disabled={disabled}
        className={
          "selectListButton" +
          (hasExternalSkillInCurrentCourse ? " selected" : "")
        }
        onClick={() => onToggle(code, groupId)}
      >
        <ListItemText primary={name} secondary={regulatedBy} />
      </ListItemButton>
    </ListItem>
  );
}

export default function ID06TrainingsDialog({ courseId, onClose }) {
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState("");
  const theme = useTheme();
  const isSmallScreenOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const api = useApi();

  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}integrations/ID06/courses/${courseId}/external-skills/all`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          const groupedTrainings = groupBy(data, "groupId");
          const groups = Object.entries(groupedTrainings).map(
            ([groupId, trainings]) => ({
              groupId,
              groupName: trainings[0].group,
              trainings: trainings,
            })
          );
          groups.sort((a, b) => a.groupName.localeCompare(b.groupName));
          setTrainingGroups(groups);
          setSelectedTabValue(groups[0].groupId);
        }
        setIsDataFetched(true);
      });
  }, [courseId, api]);

  async function uploadUpdatedSkillSelection() {
    const request = trainingGroups
      .flatMap((grp) => grp.trainings)
      .filter((t) => !!t.hasExternalSkillInCurrentCourse);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}integrations/ID06/courses/${courseId}/external-skills`,
      request,
      "PUT"
    );
    if (response.isSuccessful === true) {
      onClose(); // Close dialog after saving
    }
  }

  function handleToggle(code, groupId) {
    // console.log("handleToggle", trainingGroups);
    const trainingGroupIndex = trainingGroups.findIndex(
      (tg) => tg.groupId === groupId
    );
    if (trainingGroupIndex < 0) {
      return;
    }

    const trainingIndex = trainingGroups[
      trainingGroupIndex
    ].trainings.findIndex((t) => t.code === code);
    if (trainingIndex < 0) {
      return;
    }

    const trainingGroupsCopy = [...trainingGroups];
    const trainingGroupCopy = { ...trainingGroups[trainingGroupIndex] };
    const trainingsCopy = [...trainingGroupCopy.trainings];
    const trainingCopy = { ...trainingsCopy[trainingIndex] };
    trainingCopy.hasExternalSkillInCurrentCourse =
      !trainingCopy.hasExternalSkillInCurrentCourse;
    trainingsCopy.splice(trainingIndex, 1, trainingCopy);
    trainingGroupCopy.trainings = trainingsCopy;
    trainingGroupsCopy.splice(trainingGroupIndex, 1, trainingGroupCopy);
    setTrainingGroups(trainingGroupsCopy);
  }

  return (
    <>
      {!isDataFetched && <Preloader zIndexOverride={5000} />}
      {isDataFetched && (
        <Dialog open={true} onClose={onClose} className="mediumCourseDialog">
          <DialogTitle>{`${Translate.get("ID06Name")} ${Translate.get(
            "EditCourseSkills"
          )}`}</DialogTitle>
          <DialogContent>
            <Box>
              <Typography sx={{ margin: "0 0 24px" }}>
                {Translate.get("ID06EditCourseSkillsInfo")}
              </Typography>
              <TabContext value={selectedTabValue}>
                <Box>
                  <TabList
                    onChange={(event, value) => setSelectedTabValue(value)}
                    centered
                  >
                    {trainingGroups.map((grp) => (
                      <Tab label={grp.groupName} value={grp.groupId} />
                    ))}
                  </TabList>
                </Box>
                {trainingGroups.map((grp) => (
                  <TabPanel key={grp.groupId} value={grp.groupId}>
                    <List>
                      {grp.trainings.map((training) => (
                        <TrainingListItem
                          key={training.code}
                          {...training}
                          onToggle={handleToggle}
                        />
                      ))}
                    </List>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner onClick={uploadUpdatedSkillSelection}>
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
