import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { useState } from "react";
import Preloader from "../../components/Preloader";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";
import { formatMoney } from "../../utils/formatMoney";

export default function ReceiptPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const { orderRef } = useParams();
  const api = useApi();

  useEffect(() => {
    async function loadReceipt() {
      setIsLoading(true);
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}v1/orders/receipts/${orderRef}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setReceipt(response);
      }
      setIsLoading(false);
    }

    loadReceipt();
  }, [api, orderRef]);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <div>
      Receipt
      {receipt && (
        <div>
          <table>
            <tr>
              <td>{Translate.get("Designation")}</td>
              <td>{Translate.get("Quantity")}</td>
              <td>{Translate.get("PiecePrice")}</td>
              <td>{Translate.get("VATText")}</td>
              <td>{Translate.get("Amount")}</td>
            </tr>
            {receipt.lines.map((line) => (
              <tr>
                <td>{line.partDescription}</td>
                <td>{line.qty}</td>
                <td>{formatMoney(line.pcsPrice)}</td>
                <td>{formatMoney(line.vatPrice)}</td>
                <td>{formatMoney(line.priceWithVat)}</td>
              </tr>
            ))}
          </table>
          <div>
            {Translate.get("TotalSum")}:{formatMoney(receipt.totalPrice)}
          </div>
          <div>
            {Translate.get("Date")}:
            {formatDate(receipt.paymentDate ?? receipt.orderDate)}
          </div>
          <div>
            {Translate.get("OrderNo")}:{receipt.orderNo}
          </div>
          <div>{`${receipt.seller.name}, ${receipt.seller.orgNo}`}</div>
        </div>
      )}
    </div>
  );
}
