import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DuplicateActorMerger from '../actors/duplicates/DuplicateActorMerger';
import { IconButton } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Tooltip from '@mui/material/Tooltip';
import Translate from '../../utils/Translate';


export default function DuplicateSelfRegActorMerge({ actorId1, actorId2, descriptionText }) {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title={Translate.get("Duplicate")}>
                <IconButton variant="outlined" onClick={handleClickOpen} sx={{ backgroundColor: "pink" }}>
                    <PeopleAltIcon sx={{ color: "#f05032" }} />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {Translate.get("Duplicate")}
                </DialogTitle>
                <DuplicateActorMerger actorId1={actorId1} actorId2={actorId2} descriptionText={descriptionText} showKeepBothButton={true} />

            </Dialog>
        </div>
    );
}