import { useState, useEffect } from 'react';
import Translate from '../../../utils/Translate';
import { useApi } from '../../../utils/Api';
import { useSnackbar } from 'notistack';
import Preloader from '../../Preloader';
import { Avatar, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from "@mui/x-date-pickers";
import { getCurrentDateMask, getCurrentInputFormat,tryFormatDate } from "../../../utils/dateTimeFormat";
import SelectActorPermitResponsibleActor from "../../actor-permits/SelectActorPermitResponsibleActor"
import { validateResponsibleActor } from "../../actor-permits/validation/ActorValidator";
//C:\react\ePortWebGui\src\components\actor-permits\SelectActorPermitResponsibleActor.js
let fileExtension = '';
const acceptfileExtensions = '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.pages,.numbers,.key,.csv,.bmp,.gif,.heic,.jpg,.jpeg,.png,.tiff,.webp,.zip,.7z';
function fmtDate(dateStr) {

  let date = new Date(dateStr);
  /* if (!date instanceof Date) {
    return null
  } */
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Januari är 0
  let day = date.getDate()
  let d = year + "-" + month + "-" + day
  return d;
}
const UploadActorPermit = (props) => {
  //const [files, setFiles] = useState(props.files);
  const [fileName, setFileName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };
  const [skillGroups, setSkillGroups] = useState(false);
  if (!skillGroups) {
    (async () => {
      const data = await api.fetch(`${process.env.REACT_APP_MAIN_URL}skillgroups/for-permits?includeImageUrls=true`,
        false,
        "GET");
      setSkillGroups(data);
    })();
  }

  const [errors, setErrors] = useState({});
  const [responsibleActor, setResponsibleActor] = useState(null);
  function handleChangeResponsibleActor(actor) {
    if (actor) {
      const validationError = validateResponsibleActor(actor);
      setErrors({ ...errors, responsibleActor: validationError });
    }
    setResponsibleActor(actor);
  }


  const submitForm = (e) => {
    e.preventDefault();
/*     //console.log('actorData', props.actorData)
    //console.log('skillGroupId', e.target.skillGroupId.value)
    //console.log('validFrom', e.target.validFrom.value)
    //console.log('validTo', e.target.validTo.value,(e.target.validTo.value || null))
    //console.log('ResponsibleActor', responsibleActor) */
    // //console.log('toJSON',fromDate.toJSON());
    //let validFrom=tryFormatDate(fromDate, 'sv-SE');
    //let validTo=toDate ? tryFormatDate(toDate, 'sv-SE'): null;
    let validFrom=fromDate.toJSON();
    let validTo=toDate ? toDate.toJSON():null;
    setSubmitting(true);
    const formData = new FormData();
    formData.append(
      'file',
      e.target.file.files[0],
      e.target.name.value + fileExtension,
    );
    formData.append('skillGroupId', e.target.skillGroupId.value);
    formData.append('validFrom', validFrom);
    validTo && formData.append('validTo', validTo);
    formData.append('actorId', props.actorData.actorId);
    formData.append('actorName', props.actorData.actorName  || '');
    formData.append('actorEmail', props.actorData.actorEmail  || '');
    formData.append('actorOrgno', props.actorData.actorOrgNo || '');
    props.actorData.externalActorNo && formData.append('externalActorNo', props.actorData.externalActorNo || '');
    formData.append('responsibleActorId', responsibleActor.actorId);
    formData.append('responsibleActorName', responsibleActor.actorName || '');
    formData.append('responsibleActorEmail', responsibleActor.email || '');
    (async () => {
    const ret = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/from/file`,
      formData,
    );
    if (ret.isSuccessful) {
      setSubmitting(false);
      enqueueSnackbar(Translate.get('DocumentUploaded'), {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeAndReload();
    } else {
      setSubmitting(false);
      //Kommer direkt från API
      //showErrorMessage(ret.errorMessageTranslationKey);
    }
    ////console.log('return data', ret);
    
  })();
};
const showErrorMessage = (errorMessageTranslationKey) => {
  const errorMessage = enqueueSnackbar(
    Translate.get(errorMessageTranslationKey),
    {
      variant: 'error',
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(errorMessage),
    },
  );
};
const getFile = (e) => {
  const maxFileSize = 1024 * 1024 * 5;
  const file = e.target.files[0];
  if (file.size > maxFileSize) {
    showErrorMessage('FileIsTooLarge');
    setFileName('');
  } else {
    ////console.log(file.name);
    const lastDot = file.name.lastIndexOf('.');

    const fileNameNoExt = file.name.substring(0, lastDot);
    fileExtension = file.name.substring(lastDot);
    ////console.log('fileExtension', fileExtension);
    const chkValidExt = acceptfileExtensions.split(',');
    if (chkValidExt.includes(fileExtension)) {
      setFileName(fileNameNoExt);
    } else {
      showErrorMessage('FileTypeNotSupported');
    }
  }
};

//Toddo lista alla uppladdade dokument och delete funktion + förbered för fler kopplingar
if (skillGroups === false) {
  return (<Preloader />)
}
return (
  <>

    {skillGroups && (
      <>
        <DialogTitle>
          <div className="stdFlexBetween">
            <div>{Translate.get('UploadActorPermit')}</div>
            {/*  <Tooltip title={Translate.get('ePort2AllowedFileTypesInfo') + ' ' + acceptfileExtensions}>
                <IconButton>
                  <InfoIcon color="primary" />
                </IconButton>
              </Tooltip> */}
          </div>
        </DialogTitle>
        <form onSubmit={submitForm}>
          <DialogContent dividers={true} className="stdDialogContent">
            <div className='stdForm'>
              <div className="stdFlexBetween">
                <div className='smallHeader'>{Translate.get('FileUpload')}</div>
                <Tooltip title={Translate.get('ePort2AllowedFileTypesInfo') + ' ' + acceptfileExtensions}>
                  <IconButton>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                {Translate.get('ePort2MaxFileSize')}
              </div>

              {/*  <UploadList files={files} /> */}
              <div className="stdFlexBetween">
                <Button variant="contained" component="label">
                  {Translate.get('SelectFile')}
                  <input
                    accept={acceptfileExtensions}
                    onChange={getFile}
                    type="file"
                    hidden
                    name="file"
                  />
                </Button>
                <TextField
                  style={{ flexGrow: 1 }}
                  type="text"
                  required
                  name="name"
                  label={Translate.get('FileName')}
                  value={fileName}
                  onChange={(event) => {
                    setFileName(event.target.value);
                  }}
                />

              </div>
              <div className="stdFlexBetween">
                <div className='smallHeader'>{Translate.get('OtherDetails')}</div>
                {/*  <Tooltip title={Translate.get('ePort2AllowedFileTypesInfo') + ' ' + acceptfileExtensions}>
                    <IconButton>
                      <InfoIcon color="primary" />
                    </IconButton>
                  </Tooltip> */}
              </div>
              <div className="stdFlexBetween">
                <TextField
                  select
                  label={Translate.get('MachineGroup')}
                  defaultValue={skillGroups[0].skillGroupId}
                  name="skillGroupId"

                  fullWidth
                >
                  {skillGroups.map((row) => (
                    <MenuItem key={row.skillGroupId} value={row.skillGroupId}>
                      {row.description}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="stdFlexBetween">
                <DatePicker
                  sx={{ flexGrow: 1 }}
                  label={Translate.get("ValidFrom")}
                  mask={getCurrentDateMask()}
                  inputFormat={getCurrentInputFormat()}
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      name="validFrom"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }} />
                  )}
                />

                <DatePicker
                  sx={{ flexGrow: 1 }}
                  //disableOpenPicker={true}
                  //fullWidth
                  label={Translate.get("ValidTo")}
                  mask={getCurrentDateMask()}
                  minDate={fromDate}
                  inputFormat={getCurrentInputFormat()}
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="validTo"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }} />
                  )}
                />

              </div>
              <div className="stdFlexRight">
                <div className='smallInfoText'>{Translate.get("NoEndDateValid")}</div>
              </div>
              <div style={{ display: 'block' }}>
                <SelectActorPermitResponsibleActor
                  responsibleActor={responsibleActor}
                  onResponsibleActorChange={handleChangeResponsibleActor}
                  errorMessage={errors.responsibleActor}
                />
              </div>

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeAndReload()}>
              {Translate.get('Close')}
            </Button>
            <LoadingButton
              className="stdLightLoader"
              loading={submitting}
              type="submit"
            >
              {Translate.get('Save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </>)}
  </>
);
};
export default UploadActorPermit;
