import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Tooltip,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Fragment, useState, useEffect } from "react";
import Translate from "../../utils/Translate";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../DotMenu";
import ID06StudentCompetencesDialog from "./ID06StudentCompetencesDialog";
import ActorAvatar from "../ActorAvatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ButtonWithSpinner from "../ButtonWithSpinner";

export default function ID06CompetencesDialogSmall({
  onClose,
  bookings,
  ID06Skills,
  externalSkills,
  handleCheckID06Skill,
  canApproveAll,
  handleApproveAllSkills,
  handleApproveAllSkillsForStudent,
  handleOpenAllTrainingsDialog,
  showAllTrainingsDialog,
  handleSave,
  handleReloadData,
  handleWrittenConsentChange,
  hasWrittenConsent,
  hasErrors,
}) {
  const [students, setStudents] = useState([]);
  const [studentSkillsCount, setStudentSkillsCount] = useState({});
  const [showStudentDialog, setShowStudentDialog] = useState(false);
  const [selectedActorId, setSelectedActorId] = useState(null);
  const [personCrudActorId, setPersonCrudActorId] = useState(null);

  useEffect(() => {
    const allStudents = bookings.flatMap(
      (booking) => booking.courseParticipants
    );
    setStudents(allStudents);
  }, [bookings]);

  useEffect(() => {
    const counts = Object.fromEntries(
      students.map((s) => [
        s.actorId,
        ID06Skills.filter((x) => x.actorId === s.actorId && !x.isDeleted)
          .length,
      ])
    );
    setStudentSkillsCount(counts);
  }, [students, ID06Skills]);

  const getDotMenuActions = (student) => {
    const hasAllSkills = externalSkills.every((es) =>
      ID06Skills.some(
        (x) =>
          x.code === es.code && x.actorId === student.actorId && !x.isDeleted
      )
    );

    const menudata = [
      {
        icon: <EditIcon />,
        text: Translate.get("EditPersonalInfo"),
        onClick: () => setPersonCrudActorId(student.actorId),
      },
      {
        icon: <DoneAllIcon />,
        text: Translate.get("ApproveAllSkills"),
        disabled: hasAllSkills,
        onClick: () =>
          handleApproveAllSkillsForStudent(
            student.actorId,
            student.courseParticipantId
          ),
      },
      {
        icon: <DoneIcon />,
        text: Translate.get("ApproveSomeSkills"),
        disabled: false,
        onClick: () => handleOpenStudentGradesDialog(student.actorId),
      },
    ];
    return menudata;
  };

  function handleOpenStudentGradesDialog(actorId) {
    setSelectedActorId(actorId);
    setShowStudentDialog(true);
  }

  function handleCloseStudentGradesDialog() {
    setSelectedActorId(null);
    setShowStudentDialog(false);
  }

  return (
    <>
      {showStudentDialog && (
        <ID06StudentCompetencesDialog
          onClose={handleCloseStudentGradesDialog}
          ID06Skills={ID06Skills}
          externalSkills={externalSkills}
          students={students}
          selectedActorId={selectedActorId}
          handleCheckID06Skill={handleCheckID06Skill}
        />
      )}
      {personCrudActorId && (
        <PersonCrudDialog
          open={true}
          personCrudProps={{
            actorId: personCrudActorId,
            isEditOnly: true,
            isMatchingActorsDisabled: true,
            onClose: () => setPersonCrudActorId(null),
            onActorSaved: () => handleReloadData(),
          }}
          zIndexOverride={6000}
        />
      )}
      <Dialog
        open={true}
        onClose={onClose}
        sx={
          /* Doing this so that the spinner in CourseSkillsDialog is over this dialog*/
          showAllTrainingsDialog ? { zIndex: 0 } : {}
        }
      >
        <Box
          sx={{
            position: "absolute",
            right: 8,
            top: 9,
          }}
        >
          <IconButton onClick={handleOpenAllTrainingsDialog}>
            <SettingsIcon />
          </IconButton>
          <IconButton
            onClick={handleApproveAllSkills}
            disabled={!canApproveAll || hasErrors()}
          >
            <DoneAllIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          <Stack direction="row" spacing={1}>
            <Box>{Translate.get("ID06Name")}</Box>
            <Tooltip title={Translate.get("ID06CompetenceDialogInfo")}>
              <InfoIcon color="primary" />
            </Tooltip>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }} dividers>
          <TableContainer>
            <Table
              className="gradesTable gradesTableSmall id06"
              aria-label="simple table"
            >
              {bookings &&
                bookings.length > 0 &&
                bookings.map((booking) => (
                  <Fragment key={booking.courseBookingId}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3} sx={{ padding: "14px" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              margin: "1em 10px 2px",
                              textTransform: "uppercase",
                            }}
                          >{`${booking.companyName} (${booking.courseParticipants.length})`}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {booking.courseParticipants.map((student) => (
                        <>
                          <TableRow
                            key={student.actorId}
                            className="gradesTableRow small"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="userNameEmail"
                              align="left"
                            >
                              <ActorAvatar
                                name={student.name}
                                email={student.personalNo}
                                profilePictureUrl={student.profilePictureUrl}
                              />
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{ padding: "2px 8px" }}
                                className={
                                  "textbox" +
                                  ((studentSkillsCount[student.actorId] ?? 0) >
                                    0 &&
                                  studentSkillsCount[student.actorId] ===
                                    externalSkills.length
                                    ? " grades-done"
                                    : "") +
                                  ((studentSkillsCount[student.actorId] ?? 0) >
                                    0 &&
                                  studentSkillsCount[student.actorId] <
                                    externalSkills.length
                                    ? " grades-ongoing"
                                    : "")
                                  /* + (!!licensesByActorId[student.actorId].date
                                  ? " grades-licensed"
                                  : "")*/
                                }
                              >
                                {studentSkillsCount[student.actorId] ?? 0}/
                                {externalSkills.length}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <DotMenu
                                content={getDotMenuActions(student)}
                                horizontal
                              />
                            </TableCell>
                          </TableRow>
                          {student.errors && (
                            <TableRow>
                              {Object.values(student.errors)
                                .filter((val) => !!val)
                                .map((val, i) => (
                                  <Stack
                                    key={i}
                                    direction="row"
                                    marginTop="-8px"
                                    paddingLeft="23px"
                                    paddingBottom="8px"
                                    className="smallRedWarning"
                                  >
                                    <WarningIcon />
                                    <Box>
                                      {val.startsWith("ID06:")
                                        ? val
                                        : Translate.get(val)}
                                    </Box>
                                  </Stack>
                                ))}
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Fragment>
                ))}
            </Table>
          </TableContainer>
          <Stack>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
              marginLeft="12px"
              marginRight="12px"
              marginTop="24px"
              marginBottom="32px"
            >
              <Checkbox
                disabled={ID06Skills.filter((x) => !x.isDeleted).length === 0}
                checked={hasWrittenConsent()}
                onChange={handleWrittenConsentChange}
              />
              <Box>{Translate.get("ID06WrittenConsentStatement")}</Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <ButtonWithSpinner
            variant="contained"
            onClick={handleSave}
            disabled={
              !hasWrittenConsent() ||
              ID06Skills.filter((x) => !x.isDeleted).length === 0 ||
              hasErrors()
            }
          >
            {Translate.get("Save")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}
