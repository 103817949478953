import { useState } from 'react';
import {
  Button
} from '@mui/material';
import Translate from '../../utils/Translate';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useApi } from '../../utils/Api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Comp = (props) => {
let checked=[];
  const data = props.data.filter((x) => x.items.length===0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showDialog, setShowDialog] = useState(false);
  const api = useApi();

  const mailRequext = () => {
    setLoading(true);
    (async () => {
      const indata = { licenseType:'Instruktörsmaterial',materialType: checked.join()};
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/order`,
        indata,
      );
      setLoading(false);
      if (response.isSuccessful) {
        const successMessage = enqueueSnackbar(
          Translate.get('OrderReceivedWillContact'),
          {
            variant: 'success',
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(successMessage),
          },
        );
      }
      setShowDialog(false);
    })();
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  const isChecked = (event) => {
    //console.log(event.target.checked, event.target.value)
    if (event.target.checked){
        checked.push(event.target.value) ;
    }
    else{
        checked = checked.filter(item => item !== event.target.value) ;
    }
    //console.log('checked',checked);

  };
  return (
    <>
      <Dialog
        className="yesOrNoDialog"
        open={showDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get('OrderRequest')}
        </DialogTitle>
        <DialogContent>
          {Translate.get('InstructorMaterialRequest')}
          <FormGroup>
          {data.map((x, idx) => (
            <FormControlLabel key={idx} control={<Checkbox value={x.description} onChange={isChecked} />} label={x.description}/>
              ))}
         </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {Translate.get('Cancel')}
          </Button>
          <LoadingButton
            className="stdBlueLoader"
            loading={loading}
            onClick={() => mailRequext()}
            variant="contained"
            autoFocus
          >
            {Translate.get('SendRequest')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setShowDialog(true)} variant="outlined" style={{ marginRight: '8px' }}>
        {Translate.get('OrderRequest')}
      </Button>
    </>
  );
};
export default Comp;
