import {
  Box,
  Button,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { read, utils } from "xlsx";
import Translate from "../../../utils/Translate";
import CancelIcon from "@mui/icons-material/Cancel";
import { useApi } from "../../../utils/Api";
import { actorMultiSearch, actorPersonTypes } from "../../../utils/actorSearch";
import ImportActorsSummaryDialog from "./ImportActorsSummaryDialog";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";
import { isValidDate } from "../../../utils/dateTimeFormat";
import { useSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function actorMatchesOtherActors(actor, actors) {
  return actors.some(
    (a) =>
      a !== actor &&
      ((actor.actorEmail && a.actorEmail === actor.actorEmail) ||
        (actor.orgNo && a.orgNo === actor.orgNo) ||
        (actor.externalActorNo && a.externalActorNo === actor.externalActorNo))
  );
}

function getActorSearchFieldsAndTexts(actor) {
  const searchFieldsToText = {};
  if (actor.actorEmail) {
    searchFieldsToText["ActorEmail"] = actor.actorEmail;
  }
  if (actor.orgNo) {
    searchFieldsToText["OrgNo"] = actor.orgNo;
  }
  if (actor.externalActorNo) {
    searchFieldsToText["ExternalActorNo"] = actor.externalActorNo;
  }
  return searchFieldsToText;
}

function getImportAction(numberOfMatches, isUpdateAllowed) {
  switch (numberOfMatches) {
    case 0:
      return ["add", null];
    case 1:
      return isUpdateAllowed
        ? ["update", null]
        : ["skip", Translate.get("MatchesUserActor")];
    case undefined:
      throw new Error();
    default:
      return ["skip", Translate.get("MatchesMultipleActors")];
  }
}

async function actorHasConnectedUser(api, actorId) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/has-user`,
    false,
    "GET"
  );
}

async function getImportDetails(api, importActor, searchMatches) {
  const hasUniqueMatch = searchMatches.length === 1;
  if (hasUniqueMatch) {
    const uniqueMatch = searchMatches[0];
    const isUpdatingEmail =
      !!importActor.actorEmail && importActor.actorEmail !== uniqueMatch.email;
    const isUpdateAllowed =
      !isUpdatingEmail ||
      !(await actorHasConnectedUser(api, uniqueMatch.actorId));
    const [action, reason] = getImportAction(
      searchMatches.length,
      isUpdateAllowed
    );
    return {
      action,
      reason,
      match: uniqueMatch,
    };
  } else {
    const [action, reason] = getImportAction(searchMatches.length, false);
    return {
      action,
      reason,
    };
  }
}

function assignActorId(actor, actorId) {
  actor.actorId = actorId;
  if (actor.businessPhone) {
    actor.businessPhone.actorId = actorId;
  }
  if (actor.mobilePhone) {
    actor.mobilePhone.actorId = actorId;
  }
  if (actor.postalAddress) {
    actor.postalAddress.actorId = actorId;
  }
  if (actor.actorFreeText && actor.actorFreeText.length > 0) {
    for (const text of actor.actorFreeText) {
      text.actorId = actorId;
    }
  }
}

// Will try to find matching actors for the provided actors,
// and determine if they can be imported or not.
async function preProcessActorsToImport(api, actors) {
  return await Promise.all(
    actors.map(async (actor) => {
      const matchesOtherImportedActor = actorMatchesOtherActors(actor, actors);
      if (matchesOtherImportedActor || !actor.actorName) {
        return {
          ...actor,
          importDetails: {
            action: "skip",
            reason: matchesOtherImportedActor
              ? Translate.get("DuplicateImportRow")
              : Translate.get("MissingName"),
          },
        };
      }

      const searchFieldsToText = getActorSearchFieldsAndTexts(actor);
      if (Object.entries(searchFieldsToText).length > 0) {
        const matches = await actorMultiSearch(
          searchFieldsToText,
          actorPersonTypes,
          50,
          true
        );
        const importDetails = await getImportDetails(api, actor, matches);
        const actorCopy = { ...actor, importDetails };
        if (importDetails.match) {
          assignActorId(actorCopy, importDetails.match.actorId);
        }
        return actorCopy;
      } else {
        return { ...actor, importDetails: { action: "add" } };
      }
    })
  );
}

function getPrefixedTargetFieldColumns(prefix, selectedColumnsArray) {
  return selectedColumnsArray
    .filter(([targetField, _]) => targetField.startsWith(prefix))
    .map(([targetField, column]) => [
      targetField.substring(prefix.length),
      column,
    ]);
}

function getFieldValuesObjectForRow(fields, row, columnIndices) {
  return Object.fromEntries(
    fields.map(([targetField, column]) => [
      targetField,
      row[columnIndices[column]],
    ])
  );
}

function getFieldValuesForRow(fields, row, columnIndices) {
  return fields.map(([targetField, column]) => [
    targetField,
    row[columnIndices[column]],
  ]);
}

export default function ImportActorsDialog({ companyActorId }) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filename, setFilename] = useState("");
  const [selectedColumns, setSelectedColumns] = useState({});
  const [processedActors, setProcessedActors] = useState([]);
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [skillGroups, setSkillGroups] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const api = useApi();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  useEffect(() => {
    async function loadSkillGroupsWithSkills() {
      var response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}skillgroups`,
        false,
        "GET"
      );
      setSkillGroups(response);
    }

    loadSkillGroupsWithSkills();
  }, []);

  const parseExcelRows = (fileData) => {
    const excelData = read(fileData, {
      // cellDates: true, // Ska vi använda denna istället? Returnerar datumen som Date-objekt
      dateNF: "yyyy-mm-dd",
    });
    const excelSheets = Object.keys(excelData.Sheets).map((name) =>
      // header: 1 - read rows as arrays, with the header as a separate row
      // header: 0 - read rows as js objects keyed by column headers
      utils.sheet_to_json(excelData.Sheets[name], {
        header: 1,
        blankrows: false,
        defval: null,
        // raw: true,
        rawNumbers: false,
      })
    );

    // Support 1 sheet for now, ignore the rest if there are any
    return excelSheets.length > 0 ? excelSheets[0] : [];
  };

  const handleFileUpload = (event) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const excelRows = parseExcelRows(result);
      if (excelRows.length > 1) {
        setColumns(excelRows[0]);
        setRows(excelRows.slice(1));
        setSelectedColumns({});
      } else {
        const errorMessage = enqueueSnackbar(Translate.get("InvalidFormat"), {
          variant: "error",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(errorMessage),
        });
        setFilename("");
        setColumns([]);
        setRows([]);
        setSelectedColumns({});
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Builds a list of actors based on the selected columns and rows from the excel
  function getActorsToImport() {
    const columnIndices = Object.fromEntries(columns.map((c, i) => [c, i]));
    const selectedColumnsArray = Object.entries(selectedColumns);
    const businessPhoneFields = getPrefixedTargetFieldColumns(
      "businessPhone.",
      selectedColumnsArray
    );

    const mobilePhoneFields = getPrefixedTargetFieldColumns(
      "mobilePhone.",
      selectedColumnsArray
    );

    const postalAddressFields = getPrefixedTargetFieldColumns(
      "postalAddress.",
      selectedColumnsArray
    );

    const actorFreeTextFields = getPrefixedTargetFieldColumns(
      "actorFreeText.",
      selectedColumnsArray
    );

    const skillIdFields = getPrefixedTargetFieldColumns(
      "skillIds.",
      selectedColumnsArray
    );

    const actorFields = selectedColumnsArray.filter(
      ([targetField, _]) => !targetField.includes(".")
    );

    const actors = rows.map((row) => {
      const businessPhone = getFieldValuesObjectForRow(
        businessPhoneFields,
        row,
        columnIndices
      );
      const mobilePhone = getFieldValuesObjectForRow(
        mobilePhoneFields,
        row,
        columnIndices
      );
      const postalAddress = getFieldValuesObjectForRow(
        postalAddressFields,
        row,
        columnIndices
      );
      const actorFreeText = getFieldValuesObjectForRow(
        actorFreeTextFields,
        row,
        columnIndices
      );
      const actor = getFieldValuesObjectForRow(actorFields, row, columnIndices);

      const skillIdsAndValues = getFieldValuesForRow(
        skillIdFields,
        row,
        columnIndices
      ).filter(([_, rowVal]) => !!rowVal);
      const actorSkills =
        skillIdsAndValues.length > 0
          ? skillIdsAndValues.map(([skillId, rowVal]) => {
              const gradeDate = new Date(rowVal);
              return {
                skillId: skillId,
                hasPassed: true,
                gradeDate: isValidDate(gradeDate) ? gradeDate : null,
              };
            })
          : null;

      return {
        ...actor,
        businessPhone:
          Object.keys(businessPhone).length > 0 &&
          Object.values(businessPhone).every((v) => !!v)
            ? businessPhone
            : null,
        mobilePhone:
          Object.keys(mobilePhone).length > 0 &&
          Object.values(mobilePhone).every((v) => !!v)
            ? mobilePhone
            : null,
        postalAddress:
          Object.keys(postalAddress).length > 0 ? postalAddress : null,
        actorFreeText:
          Object.keys(actorFreeText).length > 0 &&
          Object.values(actorFreeText).every((v) => !!v)
            ? [actorFreeText]
            : null,
        actorSkills: actorSkills,
      };
    });
    return actors;
  }

  async function handleVerifyImport() {
    const actors = getActorsToImport();
    const processedActors = await preProcessActorsToImport(api, actors);
    setProcessedActors(processedActors);
    setShowSummaryDialog(true);
  }

  async function handleImport() {
    const actorsToImport = processedActors
      .filter((a) => a.importDetails.action !== "skip")
      .map((a) => ({ ...a, importDetails: null }));

    await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${companyActorId}/students/many`,
      actorsToImport
    );

    closeAndReload();
  }

  function someRowFulfillsCondition(targetFieldValue, condition) {
    const column = selectedColumns[targetFieldValue];
    if (column) {
      const columnIndex = columns.findIndex((c) => c === column);
      if (columnIndex >= 0) {
        return rows.some((r) => condition(r[columnIndex]));
      }
    }
  }

  const TargetField = useCallback(
    ({ targetFieldName, label, disabled, error }) => (
      <FormControl fullWidth error={!!error}>
        <InputLabel
          id={`${targetFieldName}-select-label`}
          className="importDialogTargetFieldLabel"
        >
          {label}
        </InputLabel>
        <Select
          disabled={disabled}
          labelId={`${targetFieldName}-select-label`}
          id={`${targetFieldName}-select`}
          value={selectedColumns[targetFieldName] ?? ""}
          label={label}
          onChange={(event) =>
            setSelectedColumns({
              ...selectedColumns,
              [targetFieldName]: event.target.value,
            })
          }
          MenuProps={MenuProps}
          renderValue={(selectedValue) => (
            <Chip
              clickable
              key={selectedValue}
              label={selectedValue}
              deleteIcon={
                <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
              }
              onDelete={() => {
                setSelectedColumns({
                  ...selectedColumns,
                  [targetFieldName]: null,
                });
              }}
            />
          )}
        >
          {columns.map((column) => (
            <MenuItem key={column} value={column}>
              {column}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    ),
    [columns, selectedColumns]
  );

  const TargetFieldsSection = useCallback(
    ({ title, targetFields, disabled, gridItemProps }) => (
      <Grid
        item
        container
        spacing={3}
        className="importDialogTargetFieldSection"
      >
        {title && (
          <Grid item container>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textTransform: "uppercase",
              }}
            >
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item container columnSpacing={2} rowSpacing={3}>
          {targetFields.map((targetFieldProps, idx) => (
            <Grid item key={idx} xs={12} sm={6} {...gridItemProps}>
              <TargetField disabled={disabled} {...targetFieldProps} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    ),
    // OBS!!! React complains that TargetField is "an unnecessary dependency". But that is incorrect.
    // Without it we don't see the columns in TargetFields after a file has been loaded
    // eslint-disable-next-line
    [TargetField]
  );

  return (
    <>
      {processedActors && (
        <ImportActorsSummaryDialog
          open={showSummaryDialog}
          processedActors={processedActors}
          onOk={handleImport}
          onCancel={() => setShowSummaryDialog(false)}
        />
      )}
      <DialogContent
        className={"importDialogContent" + (isSmall ? " small" : "")}
      >
        <Typography sx={{ margin: "0 0 28px" }}>
          {Translate.get("ImportActorsInfo")}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 3 }}
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
          >
            {Translate.get("SelectFile")}
            <input
              type="file"
              accept=".xlsx"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          {filename && <Box>{`${filename} (${rows.length})`}</Box>}
        </Stack>
        <Grid container spacing={5}>
          <TargetFieldsSection
            disabled={!columns || columns.length <= 0}
            title={Translate.get("PersonalInfo")}
            targetFields={[
              {
                targetFieldName: "actorName",
                label: Translate.get("Name"),
              },
              {
                targetFieldName: "orgNo",
                label: Translate.get("PersonalNo"),
              },
              {
                targetFieldName: "birthDate",
                label: Translate.get("BirthDate"),
                error: someRowFulfillsCondition(
                  "birthDate",
                  (cellValue) =>
                    !!cellValue && !isValidDate(new Date(cellValue))
                )
                  ? Translate.get("InvalidDate")
                  : null,
              },
              {
                targetFieldName: "externalActorNo",
                label: Translate.get("EmployeeNo"),
              },
            ]}
          />
          <TargetFieldsSection
            disabled={!columns || columns.length <= 0}
            title={Translate.get("ContactInformation")}
            targetFields={[
              {
                targetFieldName: "actorEmail",
                label: Translate.get("Email"),
              },
              {
                targetFieldName: "businessPhone.actorPhoneNumber",
                label: Translate.get("PhoneNbr"),
              },
              {
                targetFieldName: "webSite",
                label: Translate.get("WebPage"),
              },
              {
                targetFieldName: "mobilePhone.actorPhoneNumber",
                label: Translate.get("MobilePhone"),
              },
            ]}
          />
          <TargetFieldsSection
            disabled={!columns || columns.length <= 0}
            title={Translate.getActorAddressType(3)}
            targetFields={[
              {
                targetFieldName: "postalAddress.actorAddress2",
                label: Translate.get("StreetAddress"),
              },
              {
                targetFieldName: "postalAddress.actorAddressCity",
                label: Translate.get("City"),
              },
              {
                targetFieldName: "postalAddress.actorAddressZipCode",
                label: Translate.get("ZipCode"),
              },
              {
                targetFieldName: "postalAddress.actorAddressCountry",
                label: Translate.get("Country"),
              },
            ]}
          />
          <TargetFieldsSection
            disabled={!columns || columns.length <= 0}
            title={Translate.get("Other")}
            targetFields={[
              {
                targetFieldName: "actorFreeText.textNote",
                label: Translate.get("Notes"),
                error: someRowFulfillsCondition(
                  "actorFreeText.textNote",
                  (cellValue) => cellValue?.length > 1000
                )
                  ? Translate.get("MaxCharacters255")
                  : null,
              },
              {
                targetFieldName: "observationNote",
                label: Translate.get("ObservationNote"),
                error: someRowFulfillsCondition(
                  "observationNote",
                  (cellValue) => cellValue?.length > 255
                )
                  ? Translate.get("MaxCharacters255")
                  : null,
              },
            ]}
          />

          {skillGroups.map((sg) => (
            <Box width="100%">
              <Accordion className="dialogAccordion" disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"content" + sg.description}
                  id={"header" + sg.description}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      textTransform: "uppercase",
                    }}
                  >
                    {`${Translate.get("Competences")} ${sg.description}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TargetFieldsSection
                    disabled={!columns || columns.length <= 0}
                    gridItemProps={{ sm: 12 }}
                    targetFields={sg.skills.map((s) => ({
                      targetFieldName: `skillIds.${s.skillId}`,
                      label: `${
                        s.shortCode && s.shortCode.length > 0
                          ? `${s.shortCode} - `
                          : ""
                      }${s.description}`,
                    }))}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => window.history.back()}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleVerifyImport}
          disabled={Object.entries(selectedColumns).length === 0}
        >
          {Translate.get("VerifyImport")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
