import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { IconAndLabelColumnHeader } from "../../../utils/dataGrid";
import BuildIcon from "@mui/icons-material/Build";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SchoolIcon from "@mui/icons-material/School";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
import DotMenu from "../../DotMenu";
import { CustomNoRowsOverlay } from "../../../utils/StyledGridOverlay";
import CourseTypeSkillsDialog from "./CourseTypeSkillsDialog";
import CourseTypeCard from "./CourseTypeCard";
import { dataGridLang } from "../../MemToolBar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import PlaceIcon from "@mui/icons-material/Place";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CourseTypeForm from "./CourseTypeForm";
import SpeedMenu from "../../SpeedMenu";
import { hasAccess, accessKeys } from "../../../utils/userAccess";
import YesOrNoDialog from "../../YesOrNoDialog";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const getSpeedMenu = (props) => {
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("CreateCourseTemplate"),
      route: false,
      // dialogHeader: Translate.get("CreateCourseTemplate"),
      // customClass: "bigCourseDialog",
      component: <CourseTypeForm courseTemplate={{}} />,
    },
  ];
  return menudata;
};

export default function CourseTemplateSettings(props) {
  const localizedTextsMap = dataGridLang();
  const [isLoading, setIsLoading] = useState(true);
  const [editCourseTemplateId, setEditCourseTemplateId] = useState(null);
  const [deleteCourseTemplateId, setDeleteCourseTemplateId] = useState(null);
  const [courseTemplates, setCourseTemplates] = useState([]);
  const api = useApi();
  const navigate = useNavigate();

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(700));

  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates?includeInactive=true`,
        false,
        "GET"
      )
      .then((data) => {
        setCourseTemplates(data);
        setIsLoading(false);
      });
  }, [api]);

  const deleteCourseTemplate = useCallback(
    async (courseTemplateId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}`,
        true,
        "DELETE"
      );

      setDeleteCourseTemplateId(null);
      if (response.isSuccessful) {
        navigate(0);
      }
    },
    [api, navigate]
  );

  const deactivateCourseTemplate = useCallback(
    async (courseTemplateId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/deactivate`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        navigate(0);
      }
    },
    [api, navigate]
  );

  const activateCourseTemplate = useCallback(
    async (courseTemplateId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/${courseTemplateId}/activate`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        navigate(0);
      }
    },
    [api, navigate]
  );

  function getDotMenuActions(courseTemplate) {
    const menudata = [
      {
        icon: <EditIcon />,
        //dialogHeader: "Redigera kursttypsdetaljer",
        text: Translate.get("EditCourseType"),
        component: <CourseTypeForm courseTemplate={courseTemplate} />,
        customClass: "mediumCourseDialog",
      },
      {
        icon: <BuildIcon />,
        text: Translate.get("SelectSkills"),
        onClick: () => setEditCourseTemplateId(courseTemplate.courseTemplateId),
      },
      {
        icon: <VisibilityOffIcon />,
        text: Translate.get("DeactivateCourseTemplate"),
        hide: courseTemplate.menuSeqNo <= 0,
        onClick: () =>
          deactivateCourseTemplate(courseTemplate.courseTemplateId),
      },
      {
        icon: <VisibilityIcon />,
        text: Translate.get("ActivateCourseTemplate"),
        hide: courseTemplate.menuSeqNo > 0,
        onClick: () => activateCourseTemplate(courseTemplate.courseTemplateId),
      },
      {
        icon: <DeleteIcon />,
        text: Translate.get("RemoveCourseTemplate"),
        hide: !hasAccess(accessKeys.admin),
        onClick: () =>
          setDeleteCourseTemplateId(courseTemplate.courseTemplateId),
      },
    ];
    return menudata;
  }

  const columns = [
    {
      field: "courseShortName",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={SchoolIcon}
          label={Translate.get("CourseType")}
        />
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'sv'),
    },
    {
      field: "noDays",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={DateRangeIcon}
          label={Translate.get("NumberOfDays")}
        />
      ),
    },
    {
      field: "instuctorName",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={AccountBoxIcon}
          label={Translate.get("DefaultInstructor")}
        />
      ),
    },
    {
      field: "courseCity",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PlaceIcon}
          label={Translate.get("DefaultCity")}
        />
      ),
    },
    {
      field: "activeState",
      flex: 0.2,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      align: "left",
      renderCell: (params) =>
        params.row.menuSeqNo <= 0 && <VisibilityOffIcon />,
    },
    {
      field: "dotMenu",
      flex: 0.3,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      align: "right",
      renderCell: (params) => (
        <DotMenu content={getDotMenuActions(params.row)} horizontal />
      ),
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          <YesOrNoDialog
            open={!!deleteCourseTemplateId}
            title={Translate.get("RemoveCourseTemplate")}
            text={Translate.get("SureRemoveCourseTemplate")}
            onYes={async () =>
              await deleteCourseTemplate(deleteCourseTemplateId)
            }
            onNo={() => setDeleteCourseTemplateId(null)}
          />
          {hasAccess(accessKeys.advancedPackage) && (
            <SpeedMenu content={getSpeedMenu()} />
          )}
          {editCourseTemplateId && (
            <CourseTypeSkillsDialog
              open={true}
              courseTemplateId={editCourseTemplateId}
              onClose={() => setEditCourseTemplateId(null)}
            />
          )}
          {!isBigScreen &&
            courseTemplates.map((courseTemplate) => (
              <CourseTypeCard
                {...courseTemplate}
                key={courseTemplate.courseTemplateId}
                extraActions={getDotMenuActions(courseTemplate)}
              />
            ))}
          {isBigScreen && (
            <Paper>
              <DataGrid
                disableRowSelectionOnClick={true}
                pagination={true}
                pageSizeOptions={[10, 20, 30, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                autoHeight={true}
                localeText={localizedTextsMap}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "courseShortName", sort: "asc" }],
                  },
                }}
                density="comfortable"
                columns={columns}
                rows={courseTemplates}
                getRowId={(row) => row.courseTemplateId}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay(
                    Translate.get("EmptyHere")
                  ),
                }}
              />
            </Paper>
          )}
        </>
      )}
    </>
  );
}
