import React, { useState, useEffect } from 'react';
import './stylesheet.css';
import { LoginWithId } from '../components/selfReg/LoginWithId';
import { ChooseCourseBooking } from '../components/selfReg/ChooseCourseBooking';
import { Form } from '../components/selfReg/Form';
import { Success } from './Success';
import { BankId } from '../components/selfReg/BankId';
import { useParams } from 'react-router-dom';

export default function SelfRegStudent(props) {
  const [mode, setMode] = useState(props.mode ? props.mode : "LoginWithId");
  const [qrToken, setQrToken] = useState(null);
  const [id, setId] = useState(null);
  const [actorId, setActorId] = useState(null);
  const [courseBookingId, setCourseBookingId] = useState(-1);
  const [selfRegSettings, setSelfRegSettings] = useState({});
  const [existingActor, setExistingActor] = useState(null);
  const [bankIdToken, setBankIdToken] = useState("");
  const [givenName, setGivenName] = useState("");
  const [surName, setSurName] = useState("");
  const [ssn, setSsn] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const { selfRegId } = useParams();
  const { selfRegCourseBookingId } = useParams();
  const { selfRegActorId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    if (selfRegId != null) {
      setId(selfRegId);
    }

    if (selfRegCourseBookingId != null) {
      setCourseBookingId(selfRegCourseBookingId);
    }

    if (selfRegActorId != null) {
      setActorId(selfRegActorId);
    }

    setIsLoading(false);
    
  }, [selfRegId, selfRegCourseBookingId, selfRegActorId]);

  if (isLoading) {
    return <div></div>
  }

  switch (mode) {
    case "LoginWithId":
      return (
        <LoginWithId 
          setMode={setMode} 
          id={id}
          setId={setId}
          setActorId={setActorId}
          setSelfRegSettings={setSelfRegSettings}
        />
      );
    
    case "ChooseCourseBooking":
      return (
        <ChooseCourseBooking 
          qrToken={qrToken} 
          id={id} 
          setActorId={setActorId} 
          setToken={setQrToken} 
          setId={setId} 
          setMode={setMode} 
          setCourseBookingId={setCourseBookingId} 
          selfRegSettings={selfRegSettings}
        />
      );

    case "BankId":
      return (
        <BankId 
          id={id}
          qrToken={qrToken}
          setBankIdToken={setBankIdToken}
          setExistingActor={setExistingActor}
          setGivenName={setGivenName}
          setSurName={setSurName}
          setSsn={setSsn}
          setMode={setMode}
        />
      );
    
    case "Form":
      return (
        <Form 
          id={id} 
          actorId={actorId} 
          setActorId={setActorId}
          qrToken={qrToken} 
          courseBookingId={courseBookingId} 
          setMode={setMode} 
          bankIdToken={bankIdToken}
          givenName={givenName}
          surName={surName}
          ssn={ssn}
          existingActor={existingActor}
          selfRegSettings={selfRegSettings}
        />
      );
  
    case "Success":
      return (
        <Success />
      );
  
    default:
      return null;
  }
}
