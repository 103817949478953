import { Delete, Info, Star } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import Translate from "../utils/Translate";
import { useEffect, useState } from "react";

export default function DataGridEasyFilters({
  filters,
  currentFilterModel,
  onSelectFilter,
}) {
  const [selectedFilterId, setSelectedFilterId] = useState(null);

  useEffect(() => {
    if (selectedFilterId) {
      const selectedFilter = filters.find((f) => f.id === selectedFilterId);
      const selectedFilterModel = selectedFilter.filterModel;
      if (
        selectedFilterModel.items.length !== currentFilterModel.items.length
      ) {
        setSelectedFilterId(null);
      }
      const hasFilterModelChanged = selectedFilterModel.items.some(
        (x) =>
          !currentFilterModel.items.some(
            (y) =>
              y.field === x.field &&
              y.operator === x.operator &&
              (Array.isArray(y.value) &&
              Array.isArray(x.value) &&
              y.value.length === x.value.length
                ? y.value
                    .map((yval, index) => yval === x.value[index])
                    .every((result) => !!result)
                : y.value === x.value)
          )
      );
      if (hasFilterModelChanged) {
        setSelectedFilterId(null);
      }
    }
  }, [currentFilterModel]);

  function handleFilterSelected(event) {
    const newFilterId = event.target.value;
    setSelectedFilterId(newFilterId);
    const selectedFilter = filters.find((f) => f.id === newFilterId);
    onSelectFilter(selectedFilter);
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        title={
          <div style={{ whiteSpace: "pre-line" }}>
            {filters &&
              filters.length > 0 &&
              filters.map((f) => `${f.name}: ${f.description}`).join("\n\n")}
          </div>
        }
      >
        <IconButton>
          <Info color="primary" />
        </IconButton>
      </Tooltip>

      <FormControl sx={{ minWidth: "200px" }}>
        <InputLabel id="easy-filter">{Translate.get("StdFilter")}</InputLabel>
        <Select
          labelId="easy-filter"
          id="easy-filter"
          value={selectedFilterId ?? ""}
          label={Translate.get("StdFilter")}
          onChange={handleFilterSelected}
        >
          {filters.map((filter) => (
            <MenuItem key={filter.id} value={filter.id}>
              {filter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        onClick={() => onSelectFilter(null)}
        variant="outlined"
        disabled={currentFilterModel?.items?.length === 0}
        startIcon={<Delete />}
      >
        {Translate.get("Purge")}
      </Button>

      {/* <Button
        variant="contained"
        onClick={() => setOpenMyFilters(true)}
        startIcon={<Star />}
        style={{ marginLeft: "1em" }}
      >
        {Translate.get("MyFilters")}
      </Button> */}
    </Stack>
  );
}
