import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import PeopleIcon from "@mui/icons-material/People";
import DomainIcon from "@mui/icons-material/Domain";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { useState, useCallback, useEffect } from "react";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useSnackbar } from "notistack";
import {
  actorSearch,
  actorPersonTypes,
  actorCompanyTypes,
} from "../../utils/actorSearch";
import { hasAccess, accessKeys } from "../../utils/userAccess";
import Preloader from "../Preloader";
import AgreementsGrid from "./AgreementsGrid";

export default function AgreementHistoryDialog({
  agreementId
}) {
  const close = () => {
    window.history.back();
  };
  return (
    <>
      <DialogContent>
        <AgreementsGrid agreementId={agreementId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{Translate.get("Close")}</Button>
      </DialogActions>
    </>
  );
}
