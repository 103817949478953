import DevicesIcon from '@mui/icons-material/Devices';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import SchoolIcon from '@mui/icons-material/School';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DoneIcon from '@mui/icons-material/Done';
import { hasAccess, hasSomeAccess, accessKeys } from '../utils/userAccess';
import { useState, useEffect } from 'react';
import FlyerOrderFormDialog from '../components/orders/FlyerOrderFormDialog';
import { useApi } from '../utils/Api';
import { useSnackbar } from 'notistack';
import Translate from '../utils/Translate';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import { useSearchParams } from 'react-router-dom';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PrintIcon from '@mui/icons-material/Print';
//import DevicesIcon from '@mui/icons-material/Devices';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
//import CloudDoneIcon from '@mui/icons-material/CloudDone';
import LockPersonIcon from '@mui/icons-material/LockPerson';



const Comp = (props) => {
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [licenseType, setLicenseType] = useState(null);
  const [searchParams] = useSearchParams();
  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const iFramed = searchParams.get('iframe') ? true : false;
  useEffect(() => {
    const lang = searchParams.get('lang') ?? localStorage.lang ?? '29';
    Translate.loadFlyer(lang);
  }, [searchParams]);

  async function handleOrder(licenseType) {
    setLicenseType(licenseType);
    setShowOrderForm(true);
  }

  async function handleSendOrder(orderDetails, licenseType) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/order`,
      { ...orderDetails, licenseType },
    );

    if (response.isSuccessful) {
      const successMessage = enqueueSnackbar(
        Translate.get('ThanksRequestWeWillContact'),
        {
          variant: 'success',
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        },
      );
    }
  }

  return (
    <>
      <FlyerOrderFormDialog
        open={showOrderForm}
        onClose={() => setShowOrderForm(false)}
        onOrder={async (orderDetails) =>
          await handleSendOrder(orderDetails, licenseType)
        }
      />
      <div className={iFramed ? "flyer iFramed" : "flyer"}>
        <div className="topbox">
          {/* <div className="topboxEport">ePort Avancerad</div> */}
          <div className="topboxTitle">En helt ny värld av funktioner.</div>
          {/* <DevicesIcon sx={{ fontSize: 240, color: 'orange' }} /> */}
          <img src='/static/Hero.png' alt=""/>

        </div>
        <div className="bodyBox">
          <div className="boldBodyBox">
            Instructor Portal är vår lösning där alla digitala tjänster,
            inloggningar och serviceavtal finns samlade på ett ställe.
          </div>
          <div className="smallBodyBox">
            Tänk dig en smidig molntjänst där du snabbt och enkelt kan beställa
            utbildningsintyg, samtidigt som du får absolut koll på dina
            utbildningar – pågående, kommande och avslutade. En tjänst där du
            också kan boka och betygssätta eleverna. Där du laddar ner
            instruktörsmaterial och hanterar e-lösenord. Där du minimerar din
            administration och samtidigt uppfyller myndigheternas krav på
            dokumentation.
          </div>
        </div>
        <div className="cardHolder">
          <div>
            <div>
              <PeopleAltIcon />
            </div>
            <div style={{letterSpacing: '-0.3px'}}>Full kontroll på rätt kompetens</div>
            <div>
              Få kontroll över alla utbildningsintyg inom till exempel truck,
              kran och lift och uppfyll myndighetskraven om att kunna visa
              dokumentation på rätt utbildning.
            </div>
          </div>
          <div>
            <div>
              <PrintIcon />
            </div>
            <div>Intygsutskrift</div>
            <div>
              Skriv ut intyg direkt efter genomförd kurs - du får automatiskt
              med dig elevdata, inklusive de kompetenser som eleven fått
              tidigare.
            </div>
          </div>
          <div>
            <div>
              <DevicesIcon />
            </div>
            <div>Alltid tillgängligt</div>
            <div>
              Arbeta snabbt och papperslöst genom hela utbildningsprocessen –
              från planering till intyg och register. På datorn eller i mobilen.
            </div>
          </div>
          <div>
            <div>
              <LightbulbIcon />
            </div>
            <div>Enkelt att använda</div>
            <div>
              En inloggning till alla tjänster och ett användargränssnitt som är
              intuitivt och logiskt. Du lär dig grunderna på ett par minuter.
            </div>
          </div>
          <div>
            <div>
              <CloudDoneIcon />
            </div>
            <div>Molnbaserat</div>
            <div>
              Samarbeta och planera online med dina kollegor. Kursuppdateringar
              och registerförändringar sker i realtid. Säker backup inom EU är
              garanterad.
            </div>
          </div>
          <div>
            <div>
              <LockPersonIcon />
            </div>
            <div>Säkert</div>
            <div>
              Med smart datahantering slipper du fylla i samma uppgifter många
              gånger. Med Instructor Portal vet du dessutom att du följer GDPR.
            </div>
          </div>
        </div>
        <div className="infoRow">
          <div>
            <div>
              <SchoolIcon />
            </div>
            <div>Moderna instruktörer ska ha moderna hjälpmedel!</div>
          </div>
          <div>
            <div></div>
            <div>
              Administration är tråkigt men nödvändigt, det tycker de flesta.
              Med Instructor Portal strömlinjeformas de uppgifterna så effektivt
              att du nästan kan glömma bort dem. I stället kan du ägna dig åt
              det du verkligen är bäst på: att utbilda. Så ska en modern
              instruktör kunna arbeta. Och det skadar ju inte att ha roligare på
              jobbet, eller hur?
            </div>
          </div>
        </div>
        <div className="infoRow" style={{ display: 'none' }}>
          <div>
            <div>
              <ThumbUpAltIcon />
            </div>
            <div>
              Tincidunt lobortis feugiat vivamus. Adipiscing bibendum est
              ultricies integer.
            </div>
          </div>
          <div>
            <div></div>
            <div>
              Leo in vitae turpis massa sed elementum tempus. Amet nisl purus in
              mollis. Facilisis magna etiam tempor orci eu. Eu mi bibendum neque
              egestas congue quisque. Hac habitasse platea dictumst vestibulum
              rhoncus est pellentesque. Elit eget gravida cum sociis natoque
              penatibus et magnis dis. Risus ultricies tristique nulla aliquet
              enim tortor at auctor. Vitae auctor eu augue ut.
            </div>
          </div>
        </div>
        <div className="priceCards">
          <div className="priceCard priceCardColorA">
            <div className="priceCardTop">
              <div className="temaColor">Nivå 1</div>
              <div>
                För dig som undervisar med elärande, beställer e-lösenord och
                skriver ut intyg.
              </div>
            </div>
            <div className="priceCardList">
              <div>
                <DoneIcon />
                <div>E-lärande​</div>
              </div>
                 <div>
                <DoneIcon />
                <div>Intygsutskrift​​</div>
              </div>
               <div>
                <DoneIcon />
                <div>Saldoöversikt och beställning lösenord​</div>
              </div>
               <div>
                <DoneIcon />
                <div>Egen logo i eLift</div>
              </div>
              <div>
                <DoneIcon />
                <div>Elevregister​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Person- och företagskort​</div>
              </div>
             
            </div>
            <div className="priceCardPrice">
              <div>Kostnadsfri</div>
              <div></div>
            </div>
            <div className="priceCardButton">
              <ButtonWithSpinner
                disabled={hasSomeAccess(
                  accessKeys.courseAdmin,
                  accessKeys.standardPackage,
                  accessKeys.advancedPackage,
                )}
                variant="contained"
                onClick={async () => await handleOrder('Level1')}
              >
                Intresseanmälan
              </ButtonWithSpinner>
            </div>
          </div>

          <div className="priceCard priceCardColorB">
            <div className="priceCardTop">
              <div className="temaColor">Nivå 2</div>
              <div>
                För dig som undervisar både med elärande och lärarledd teori. Du
                får full kontroll på kompetenser och betygssättning.
              </div>
            </div>
            <div className="priceCardList">
              <div>
                <DoneIcon />
                <div>Allt som ingår i Nivå 1</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kurser lärarledd teori​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Betygsättning​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kompetensregister</div>
              </div>
              <div>
                <DoneIcon />
                <div>Notiser e-lärande</div>
              </div>
               <div>
                <DoneIcon />
                <div>Intygsutskrift från registret​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Registrera elever med QR-kod​</div>   
              </div>
              <div>
                <DoneIcon />
                <div>Egna kursinställningar​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Dubbletthantering​</div>
              </div>
               <div>
                <DoneIcon />
                <div>Bifoga dokument​</div>
              </div>
              <div>
                <DoneIcon />
                <div>Importfunktion</div>
              </div>
              
            </div>
            <div className="priceCardPrice">
              <div>Från 600 kr/månad</div>
              <div>
               {/*  Amet mauris commodo quis imperdiet massa tincidunt nunc. */}
              </div>
            </div>
            <div className="priceCardButton">
              <ButtonWithSpinner
                disabled={hasSomeAccess(
                  accessKeys.standardPackage,
                  accessKeys.advancedPackage,
                )}
                variant="contained"
                onClick={async () => await handleOrder('Level2')}
              >
                 Intresseanmälan
              </ButtonWithSpinner>
            </div>
          </div>

          <div
            className="priceCard priceCardColorC"
            //style={{ display: 'none' }}
          >
            <div className="priceCardTop">
              <div className="temaColor">Nivå 3</div>
              <div>
              För dig som önskar en ännu mer komplett och personlig kurshantering - utöver funktionerna i nivå 1 och 2.
              </div>
            </div>
            <div className="priceCardList">
              <div>
                <DoneIcon />
                <div>
                 Allt som ingår i Nivå 1 och Nivå 2
                </div>
              </div>
              <div>
                <DoneIcon />
                <div>Intyg med egen logo</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kalendersynkronisering</div>
              </div>
              <div>
                <DoneIcon />
                <div>Notiser alla kurser</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kursbokning online</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kurskallelse</div>
              </div>
              <div>
                <DoneIcon />
                <div>Rapporter</div>
              </div>
              <div>
                <DoneIcon />
                <div>Behörighet "read-only"</div>
              </div>
              <div>
                <DoneIcon />
                <div>Sms-tjänst</div>
              </div>
              <div>
                <DoneIcon />
                <div>Digital närvarolista</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kalendervy för kurser och anteckningar</div>
              </div>
              <div>
                <DoneIcon />
                <div>Orderbekräftelse för kursbokning</div>
              </div>
            </div>
            <div className="priceCardPrice">
              <div>Från 900 kr/månad</div>
              <div>
                
              </div>
            </div>
            <div className="priceCardButton">
            <ButtonWithSpinner
                disabled={hasSomeAccess(
                  accessKeys.advancedPackage,
                )}
                variant="contained"
                onClick={async () => await handleOrder('Level3')}
              >
                 Intresseanmälan
              </ButtonWithSpinner>
            </div>
          </div>

          <div
            className="priceCard priceCardColorD"
            //style={{ display: 'none' }}
          >
             <div className="priceCardTop">
              <div className="temaColor" style={{color: '#ee7203'}}>Digitala körtillstånd</div>
              <div>
              Tjänst för att hantera digitala körtillstånd för dig med Nivå 2 eller 3.
              </div>
              <div>
              </div>
            </div>
            <div className="priceCardList">
            <div>
                <DoneIcon />
                <div>
                Digitalt körtillstånd
                </div>
              </div>
            </div>
            <div className="priceCardPrice">
              <div>Från 300kr/månad</div>
              <div>
               
              </div>
            </div>
            <div className="priceCardButton" style={{marginBottom:'200px'}}>
            <ButtonWithSpinner
                style={{backgroundColor: '#ee7203'}}
                disabled={hasSomeAccess(
                  accessKeys.permits,
                )}
                // accessKeys.advancedPackage,
                variant="contained"
                onClick={async () => await handleOrder('DrivingLicense')}
              >
                 Intresseanmälan
              </ButtonWithSpinner>
            </div>

            <div className="priceCardTop">
              <div className="temaColor">Extra tjänster</div>
              <div>
              För dig som har nivå 3 erbjuder vi också följande extra tjänster.
              </div>
            </div>
            <div className="priceCardList">
              <div>
                <DoneIcon />
                <div>Skapa specialkurs</div>
              </div>
              <div>
                <DoneIcon />
                <div>Kundunikt intyg</div>
              </div>
              
            </div>
            <div className="priceCardPrice">
              <div>Pris enligt offert</div>
              <div>
               
              </div>
            </div>
            <div className="priceCardButton">
            <ButtonWithSpinner
                /* disabled={hasSomeAccess(
                  accessKeys.advancedPackage,
                )} */
                variant="contained"
                onClick={async () => await handleOrder('Extras')}
              >
                 Intresseanmälan
              </ButtonWithSpinner>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
export default Comp;
