import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useCallback, useMemo, useState } from "react";
import Preloader from "../Preloader";
import { useEffect } from "react";
import { useApi } from "../../utils/Api";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { Money, Scale } from "@mui/icons-material";
import DotMenu from "../DotMenu";
import { dataGridLang } from "../MemToolBar";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";

export default function OrderSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [autoFinishOrders, setAutoFinishOrders] = useState(null);
  const [freights, setFreights] = useState([]);
  const [activeFreightId, setActiveFreightId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const api = useApi();
  const localizedTextsMap = dataGridLang();

  useEffect(() => {
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}orders/settings`, false, "GET")
      .then((response) => {
        if (response.isSuccessful) {
          setAutoFinishOrders(response.autoFinishOrders ?? false);
          setFreights(response.freights);
          if (response.freights.length > 0) {
            setActiveFreightId(response.freights[0].freightId);
          }
        }
        setIsLoading(false);
      });
  }, [api]);

  function postUpdateSettings(autoFinishOrders, freights) {
    const request = {
      autoFinishOrders,
      freights,
    };
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/settings`,
      request,
      "POST"
    );
  }

  const columns = useMemo(
    () => [
      {
        field: "weight",
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Weight"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={Scale}
            label={Translate.get("Weight")}
          />
        ),
      },
      {
        field: "price",
        editable: true,
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Price"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={Money}
            label={Translate.get("Price")}
          />
        ),
      },
      // {
      //   field: "dotMenu",
      //   flex: 0.3,
      //   headerName: "",
      //   sortable: false,
      //   disableColumnMenu: true,
      //   filterable: false,
      //   hideable: false,
      //   headerClassName: "hideSeparator",
      //   align: "right",
      //   renderCell: (params) => (
      //     <DotMenu content={getDotMenuActions(params.row)} horizontal />
      //   ),
      // },
    ],
    []
  );

  function handleFreightPriceChange(newFreightPrice) {
    const freightIndex = freights.findIndex(
      (f) => f.freightId === activeFreightId
    );
    const freightPriceIndex = freights[freightIndex].prices.findIndex(
      (p) => p.freightPriceId === newFreightPrice.freightPriceId
    );
    const updatedFreights = freights.with(freightIndex, {
      ...freights[freightIndex],
      prices: freights[freightIndex].prices.with(
        freightPriceIndex,
        newFreightPrice
      ),
    });
    setFreights(updatedFreights);
    postUpdateSettings(autoFinishOrders, updatedFreights);
    return newFreightPrice;
  }

  console.log("prices", freights);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Stack direction="column" gap={4}>
      <div
        className="stdPage"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="stdCard"
          style={{ minWidth: "400px", maxWidth: "500px" }}
        >
          <div className="stdCardHeader">{Translate.get("Orders")}</div>
          <div className="stdCardBody">
            <Grid container direction="column">
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <div>{Translate.get("AutoFinishOrdersSettingsLabel")}</div>
                </Grid>
                <Grid item>
                  <Switch
                    checked={autoFinishOrders}
                    onChange={(event) => {
                      setAutoFinishOrders(event.target.checked);
                      postUpdateSettings(event.target.checked, freights);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div
        className="stdPage"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="stdCard"
          style={{ minWidth: "400px", maxWidth: "500px" }}
        >
          <div className="stdCardHeader">{Translate.get("Freight")}</div>
          <div className="stdCardBody">
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                {Translate.get("FreightSettingsInstructions")}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    value={activeFreightId}
                    onChange={(ev) => setActiveFreightId(ev.target.value)}
                  >
                    {freights.map((f) => (
                      <MenuItem value={f.freightId}>{f.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  className="dgSmall"
                  columns={columns}
                  rows={
                    activeFreightId
                      ? freights.find((f) => f.freightId === activeFreightId)
                          .prices
                      : []
                  }
                  disableSelectionOnClick
                  getRowId={(row) => row.freightPriceId}
                  autoHeight={true}
                  components={{
                    Toolbar: GridToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay(
                      Translate.get("EmptyHere")
                    ),
                  }}
                  componentsProps={{
                    columnsPanel: { className: "customColumnPanel" },
                    filterPanel: { className: "customfilterPanel" },
                    panel: { className: "customPanel" },
                  }}
                  localeText={localizedTextsMap}
                  disableDensitySelector={true}
                  disableColumnMenu
                  pagination
                  pageSizeOptions={[10, 50, 100]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  processRowUpdate={handleFreightPriceChange}
                  // experimentalFeatures={{ newEditingApi: true }}
                  // onCellEditStop={(params, event) => {
                  //   console.log("params", params);
                  //   console.log("event", event);
                  //   // if (params.reason === "cellFocusOut") {
                  //   //   event.defaultMuiPrevented = true;
                  //   // }
                  // }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Stack>
  );
}
