import { useState } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import DomainIcon from "@mui/icons-material/Domain";
import ComputerIcon from "@mui/icons-material/Computer";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SchoolIcon from "@mui/icons-material/School";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useNavigate } from "react-router-dom";
import Translate from "../../utils/Translate";
import { MemToolBar, dataGridLang } from "../MemToolBar";
import DotMenu from "../DotMenu";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { TableAvatar } from "../../utils/dataGrid";
import { Tooltip } from "@mui/material";

//Translate.get('VAlfri översättningsnyckel'),

/* const localizedTextsMap = {
  noRowsLabel: 'Inget resultat',
  noResultsOverlayLabel: 'Inga resultat funna.',
  errorOverlayDefaultLabel: 'Ett fwl inträffade.',
  columnMenuUnsort: 'Sortera inte',
  columnMenuSortAsc: 'Sortera stigande',
  columnMenuSortDesc: 'Sortera fallande',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Dölj',
  columnMenuShowColumns: 'Visa',
  toolbarDensity: 'Radhöjd',
  toolbarExport: 'Exportera',
  toolbarFilters: 'Filter',
  toolbarColumns: 'Kolumner',
  MuiTablePagination: {
    labelRowsPerPage: 'Visa antal poster',
    labelDisplayedRows: ({ from, to, count }) =>
      `${from}-${to} av ${count !== -1 ? count : `sammanlagt ${to}`}`,
  },
}; */

const Comp = ({
  rows,
  personConnectionTypesByCompanyId,
  smallScreen,
  getDotMenuActions,
  noRowsTransKey
}) => {
  const localizedTextsMap = dataGridLang();
  //const smallScreen = useMediaQuery('(max-width:700px)');
  let columns = [];
  let toolbarCss = "stdVisible dgDynIconText";
  //let density = "standard";
  if (smallScreen) {
    toolbarCss = "dgSmall";
    //density = "comfortable";
    columns = [
      {
        field: "actorName",
        headerName: Translate.get("Companies"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <DomainIcon />
            <div>{Translate.get("Companies")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            /* <div>
              <div>{params.row.actorName}</div>
              <div></div>
              {params.row.postalAddress?.actorAddressZipCode && (
                <div>{params.row.postalAddress.actorAddressZipCode}</div>
              )}
              {params.row.postalAddress?.actorAddressCity && (
                <div>{params.row.postalAddress.actorAddressCity}</div>
              )}
            </div> */
            <TableAvatar
              src={params.row.profilePictureUrl + ""}
              //variant="circular"
              title={params.row.actorName}
              subtitle={[
                params.row.postalAddress?.actorAddressZipCode,
                params.row.postalAddress?.actorAddressCity,
              ]}
            />
          );
        },
        //valueGetter: (params) => params.row.postalAddress.actorAddressCity || '',
        //sortComparator: (v1, v2) => v1.localeCompare(v2),
      },
      {
        field: "custom",
        headerName: "",
        hide: !personConnectionTypesByCompanyId,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <AssignmentIndIcon />
            <div>{Translate.get("Role")}</div>
          </div>
        ),
        renderCell: (params) =>
          personConnectionTypesByCompanyId && (
            <div className="personRoles">
              {personConnectionTypesByCompanyId[params.row.actorId]
                .isContactConnection && (
                <Tooltip title={Translate.getActorType(3)}>
                  <HeadsetMicIcon />
                </Tooltip>
              )}
              {personConnectionTypesByCompanyId[params.row.actorId]
                .isStudentConnection && (
                <Tooltip title={Translate.getActorType(4)}>
                  <PersonIcon />
                </Tooltip>
              )}
            </div>
          ),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        hide: !getDotMenuActions,
        renderCell: (params) =>
          getDotMenuActions && (
            <DotMenu
              content={getDotMenuActions(params.row.actorId)}
              horizontal
            />
          ),
      },
    ];
  } else {
    columns = [
      {
        field: "actorName",
        headerName: Translate.get("Companies"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <DomainIcon />
            <div>{Translate.get("Companies")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <TableAvatar
              src={params.row.profilePictureUrl + ""}
              //variant="circular"
              title={params.row.actorName}
              //subtitle={params.row.shortComment}
              subtitle={params.row.externalDataId}
            />

            /* <Stack direction="row" spacing={2}>
              <Avatar
                alt={params.row.actorName}
                src={params.row.profilePictureUrl + ""}
              />
              <div className="avatarInfo">
                <div>{params.row.actorName}</div>
              </div>
            </Stack> */
          );
        },
      },
      {
        field: "custom2",
        headerName: Translate.get("City"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <PlaceIcon />
            <div>{Translate.get("City")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <div>
              {params.row.postalAddress?.actorAddressZipCode && (
                <div>{params.row.postalAddress.actorAddressZipCode}</div>
              )}
              {params.row.postalAddress?.actorAddressCity && (
                <div>{params.row.postalAddress.actorAddressCity}</div>
              )}
            </div>
          );
        },
        valueGetter: (params) =>
          params.row.postalAddress.actorAddressCity || "",
        sortComparator: (v1, v2) => v1.localeCompare(v2),
        flex: 1,
      },
      {
        field: "custom1",
        headerName: Translate.get("Address"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <PlaceIcon />
            <div>{Translate.get("Address")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <div>
              <div>{params.row.postalAddress.actorAddressStreet2}</div>
            </div>
          );
        },
        //Fält att sortera efter
        valueGetter: (params) =>
          params.row.postalAddress.actorAddressStreet1 || "",
        sortComparator: (v1, v2) => v1.localeCompare(v2),
        flex: 1,
      },
      {
        field: "custom",
        headerName: "",
        hide: !personConnectionTypesByCompanyId,
        hideable: false,
        filterable: false,
        renderCell: (params) =>
          personConnectionTypesByCompanyId && (
            <div>
              {personConnectionTypesByCompanyId[params.row.actorId]
                .isContactConnection && <HeadsetMicIcon />}
              {personConnectionTypesByCompanyId[params.row.actorId]
                .isStudentConnection && <SchoolIcon />}
            </div>
          ),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "hideSeparator",
        align: "right",
        hide: !getDotMenuActions,
        hideable: false,
        renderCell: (params) =>
          getDotMenuActions && (
            <DotMenu
              content={getDotMenuActions(params.row.actorId)}
              horizontal
            />
          ),
      },
    ];
  }

  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })

  const navToCompany = (id) => {
    sessionStorage.removeItem("paginationModel_courses");
    navigate(`/dashboard/company/${id}`, { replace: false });
  };

  //----------------------------
  /* const [loaded, setLoaded] = useState(false);
   //const apiRef = useGridApiRef();
   const MemToolBar = () => {
     const apiRefC = useGridApiContext();
     if (!loaded && window.global.dataGrid.companyGrid) {
       setLoaded(true);
       apiRefC.current.restoreState(window.global.dataGrid.companyGrid);
     } else {
       window.global.dataGrid.companyGrid = apiRefC.current.exportState();
     }
     return <GridToolbar />;
   }; */
  //----------------------------
  return (
    <>
      {/*      <Dialog
          hideBackdrop
          BackdropProps={{ invisible: true }}
          PaperProps={{
            style: {
              backgroundColor: '#fafafa',
              boxShadow: 'none',
            },
          }}
          fullScreen
          sx={{ top: '64px',zIndex:'1100' }}
          open={open}
          onClose={handleClose}
        >
          {actor.type === 'company' && <Company id={actor.id} />}
        </Dialog> */}
      <DataGrid
        className={toolbarCss}
        initialState={{
          sorting: {
            sortModel: [{ field: 'actorName', sort: 'asc' }],
          },
        }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        getRowId={(row) => row.actorId}
        autoHeight={true}
        onRowClick={(params) => navToCompany(params.id)}
        localeText={localizedTextsMap}
        components={{
          //Toolbar: MemToolBar,
          NoRowsOverlay: CustomNoRowsOverlay(noRowsTransKey || Translate.get('EmptyHere')),
        }}
        componentsProps={{
          toolbar: { gridId: "Companies" },
          columnsPanel: { className: "customColumnPanel" },
          filterPanel: { className: "customfilterPanel" },
          panel: { className: "customPanel" },
        }}
        disableColumnMenu
        disableDensitySelector={true}
        density="comfortable"
        pagination
        pageSizeOptions={[10, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        //rowHeight='150'
      />
    </>
  );
};
export default Comp;
