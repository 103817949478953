import { Stack } from "@mui/material";
import { forwardRef } from "react";
import ActorLicensePreview from "./ActorLicensePreview";

const ActorLicensePrintView = forwardRef(
  ({ studentCardsDetails, printTemplate, printFront, printBack, printWidth, printHeight, printType }, ref) => {
    return (
      <Stack id="actorLicensePrintView" direction="column" ref={ref}>
        {studentCardsDetails.map((s) => (
          <ActorLicensePreview
            key={s.actorLicenseId}
            printTemplate={printTemplate}
            cardDetails={s}
            isSelected={true}
            onSelectedChanged={() => {}}
            showFront={printFront}
            showBack={printBack}
            renderWidth={printWidth}
            renderHeight={printHeight}
            isPrint
            isEditMode={false}
            printType={printType}
          />
        ))}
      </Stack>
    );
  }
);

export default ActorLicensePrintView;
